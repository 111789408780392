<template>
  <v-dialog v-if="!loading" class="ui-kit__dialog" :value="true" persistent max-width="408px">
    <div class="ui-kit__dialog__header">
      <h3 class="ui-kit__dialog__title ma-0">
        Вход в MoreCargo
      </h3>
    </div>
    <div class="ui-kit__dialog__content">
      <v-form ref="form" v-model="valid" @submit.prevent="submit">
        <custom-input
          v-model="email"
          label="E-mail"
          :disabled="!!token"
          :rules="emailRules"
          @input="updateValidationStatus"
        />
        <custom-input
          v-model="password"
          label="Пароль"
          :rules="passwordRules"
          :append-icon="e1 ? 'visibility_off' : 'visibility'"
          :type="e1 ? 'text' : 'password'"
          @input="updateValidationStatus"
          @click:append="() => (e1 = !e1)"
        />
        <custom-input
          v-if="token"
          v-model="confirmPassword"
          label="Подтверждение пароля"
          :rules="confirmPasswordRules"
          :append-icon="e1 ? 'visibility_off' : 'visibility'"
          :type="e1 ? 'text' : 'password'"
          @input="updateValidationStatus"
          @click:append="() => (e1 = !e1)"
        />
        <div
          v-show="alert"
          class="alert-block"
          :alert="alert"
          type="danger"
          message="Неправильный логин или пароль"
        />
        <custom-button
          class="login_button"
          type="submit"
          :disabled="!valid"
          color="primary"
          round="round"
          large="large"
        >
          {{ token ? 'Зарегистрироваться' : 'Войти' }}
        </custom-button>
        <div v-if="loading" class="loading">
          <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
        </div>
      </v-form>
      <v-spacer />
    </div>
  </v-dialog>
  <v-dialog v-else :value="true" hide-overlay="hide-overlay" persistent width="300">
    <v-card color="primary" dark="dark">
      <v-card-text>
        Загрузка...
        <v-progress-linear class="mb-0" indeterminate="indeterminate" color="white" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { extractCookies } from 'common/helpers/cookieHelper';
import { setUserLoggedOut } from 'common/service/loginService';
import axios from 'axios';

export default {
  name: 'Login',
  data() {
    return {
      loading: true,
      valid: false,
      email: '',
      e1: false,
      alert: false,
      password: '',
      confirmPassword: '',
      emailRules: [
        v => !!v || 'E-mail обязателен для ввода',
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail некорректен'
      ],
      passwordRules: [v => !!v || '', v => v.length > 5 || ''],
      confirmPasswordRules: [v => v === this.password || 'Пароли не совпадают'],
      token: null
    };
  },
  computed: {
    ...mapState({
      userLogin: state => state.login,
      defaultURL: state => state.defaultURL
    })
  },
  mounted() {
    const { query } = this.$route;
    if (query.signup_admin_user_token) this.activateEmail(query.signup_admin_user_token);
    if (query.change_password_admin_user_token)
      this.activateEmail(query.change_password_admin_user_token);
    if (query.new_user) {
      setUserLoggedOut();
    } else {
      this.checkToken();
    }
  },
  methods: {
    ...mapActions('connections', ['setClient']),
    ...mapActions('login', ['login', 'loginWithToken', 'logout', 'processLogin']),
    async activateEmail(userToken) {
      setUserLoggedOut();
      this.token = userToken;
      const postData = `token=${userToken}&state=admin-activation-stage-1`;
      const {
        data: { email }
      } = await axios.post(`${this.defaultURL}/admin/activate_email`, postData);
      this.email = email;
    },
    showAlert() {
      this.alert = true;
    },
    async checkToken() {
      const { sessionKey } = extractCookies();
      if (sessionKey) await this.loginWithToken(sessionKey);
      this.checkPermissions();
      this.loading = false;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          if (this.token) await this.signUpFromEmail();
          else
            await this.login({
              email: this.email,
              password: this.password
            });
          this.checkPermissions();
        } catch (error) {
          this.showAlert();
        } finally {
          this.loading = false;
        }
      }
    },
    checkPermissions() {
      const { sections } = this.userLogin;
      if (!sections.find(_ => _.path.name === this.$route.matched[0].name))
        this.$router.push(sections.length ? { name: sections[0].path.name } : '/');
    },
    updateValidationStatus() {
      this.alert = false;
    },
    async signUpFromEmail() {
      const topic = `${this.defaultURL}/admin/activate_password`;
      const postData = `&email=${this.email}&password=${this.password}&token=${this.token}&state=admin-activation-stage-2`;
      try {
        const { data } = await axios.post(topic, postData);
        this.processLogin(data);
      } catch ({ message: error }) {
        this.errorsFromServer({ error, type: 'user' });
      } finally {
        this.token = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main';
@import '~@/assets/scss/variables';
.alert-block {
  width: 100%;
  margin-bottom: 20px;
}

.login-info {
  color: $gray;
  display: inline-block;
  align-self: left;
}

button.link {
  background: none;
  text-decoration: none;
  display: inline;
  box-shadow: none;
  color: $color-main;
  outline: none;
}

.login_button {
  margin: 12px 0 0 0;
  align-self: left;
  text-transform: none;
}

.forgot_pass {
  background: none;
  color: $color-main;
  display: block;
  align-self: left;
  outline: none;
  margin-right: auto;
}
</style>
