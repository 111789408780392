import { setField } from 'common/store/helpers';
import moment from 'common/helpers/date';

const state = {
  list: []
};

const actions = {
  async getAlerts({ dispatch, commit }, { enduser }) {
    const topic = 'admin/endusers/contractor/warnings/list';
    commit('setField', { field: 'list', value: [] });
    try {
      const response = await dispatch(
        'common/createController',
        { topic, json: { enduser } },
        { root: true }
      );
      const list = response.list.map(item => ({
        ...item,
        date: moment(item.issued).format('DD.MM.YY , HH:mm')
      }));
      commit('setField', { field: 'list', value: list });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  }
};
const mutations = {
  setField
};
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
