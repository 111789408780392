<template>
  <div v-if="!value" />
  <viewer
    v-else
    ref="viewer"
    class="viewerSmall"
    :options="options"
    :images="value"
    @inited="inited"
  >
    <div v-for="img in value" :key="img" class="imgTop">
      <img class="image" :src="img" :data-source="img" />
    </div>
  </viewer>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, Boolean],
      default: false
    }
  },
  data() {
    return {
      options: {
        inline: false,
        button: true,
        navbar: true,
        title: false,
        toolbar: true,
        tooltip: false,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: false,
        transition: false,
        fullscreen: true,
        keyboard: false,
        url: 'data-source'
      }
    };
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    },
    view() {
      if (this.form.view >= 0 && this.form.view < this.value.length) {
        this.$viewer.view(this.form.view);
      }
    },
    zoom(value) {
      this.$viewer.zoom(value || this.form.zoom);
    },
    rotate(value) {
      this.$viewer.rotate(value || this.form.rotate);
    },
    scaleX(value) {
      if (value) {
        this.$viewer.scaleX(value);
      } else {
        this.form.scaleX = -this.form.scaleX;
        this.$viewer.scaleX(this.form.scaleX);
      }
    },
    scaleY(value) {
      if (value) {
        this.$viewer.scaleY(value);
      } else {
        this.form.scaleY = -this.form.scaleY;
        this.$viewer.scaleY(this.form.scaleY);
      }
    },
    move(x, y) {
      this.$viewer.move(x, y);
    },
    prev() {
      this.$viewer.prev();
    },
    next() {
      this.$viewer.next();
    },
    play() {
      this.$viewer.play();
    },
    stop() {
      this.$viewer.stop();
    },
    show() {
      this.$viewer.show();
    },
    tooltip() {
      this.$viewer.tooltip();
    },
    reset() {
      this.$viewer.reset();
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/scss">
.viewer-container {
  .viewer-play.viewer-large {
    display: none;
  }
  .viewer-footer {
    .viewer-toolbar {
      ul {
        li {
          height: 36px;
          width: 36px;
          &::before {
            margin: 8px;
          }
        }
      }
    }
  }
}
.viewerSmall {
  height: 34px;
  .imgTop {
    margin-right: 10px;
    height: 90%;
    cursor: pointer;
    display: inline-block;

    img {
      height: 100%;
    }
  }
  .imgTop:hover {
    transform: scale(1.05);
  }
}
</style>
