<template>
  <!-- TODO: need refactor -->
  <div class="payments">
    <div class="payments_filters">
      <div class="payments_filters__field">
        <v-text-field
          label="Период c"
          :value="input.from"
          :readonly="true"
          @focus="datePickerToggle('from')"
        />
        <v-icon class="row__date_picker" @click="datePickerToggle('from')">
          date_range
        </v-icon>
        <div class="payments_filters__date_picker">
          <v-date-picker
            v-if="openPicker.from"
            v-model="picker.from"
            :landscape="true"
            :reactive="true"
            :max="pickerPeriod.from.max"
          />
        </div>
      </div>
      <div class="payments_filters__field">
        <v-text-field
          label="По"
          :value="input.to"
          :readonly="true"
          @focus="datePickerToggle('to')"
        />
        <v-icon class="row__date_picker" @click="datePickerToggle('to')">
          date_range
        </v-icon>
        <div class="payments_filters__date_picker">
          <v-date-picker
            v-if="openPicker.to"
            v-model="picker.to"
            :landscape="true"
            :reactive="true"
            :min="pickerPeriod.to.min"
            :max="pickerPeriod.to.max"
          />
        </div>
      </div>
      <div class="payments_filters__field">
        <div class="payment_options">
          <div class="payment_options__title">
            Статус
          </div>
          <select v-model="status" class="payment_options__select" @change="getData">
            <option v-for="(option, index) in statuses" :key="index" :value="option.value">
              {{ option.title }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="list.length" class="payments__table">
      <TableList :columns="columns" :values="list" @clickItem="openWindow" />
    </div>
    <div v-else class="loading">
      <v-progress-circular
        v-if="loading('adm_buch/payments')"
        indeterminate="indeterminate"
        color="primary"
        :size="20"
      />
    </div>
    <div v-if="dialogDenied" class="payments__modal">
      <v-toolbar dark="dark" color="primary">
        <v-toolbar-title>{{ item.date }}</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon="icon" dark="dark" @click="dialogDenied = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="modal__content">
        <div class="content__row">
          <div class="row__field width_80">
            <v-text-field v-model="item.type" label="Операция" :readonly="true" />
          </div>
          <div class="row__field width_20">
            <v-text-field v-model="item.amount" label="Сумма" :readonly="true" />
          </div>
        </div>
        <div class="content__row">
          <div class="row__field width_100">
            <v-textarea
              v-model="item.purpose"
              no-resize
              rows="3"
              label="Назначение платежа"
              :readonly="true"
            />
          </div>
        </div>
        <div class="content__row">
          <div class="row__field width_20">
            <v-text-field v-model="item.inn" label="ИНН контрагента" :readonly="true" />
          </div>
          <div v-if="false" class="row__field width_20">
            <v-text-field v-model="item.code" label="Код контрагента" :readonly="true" />
          </div>
          <div class="row__field width_20">
            <v-text-field
              v-model="item.organization_type"
              label="Категория контрагента"
              :readonly="true"
            />
          </div>
          <div class="row__field width_40">
            <v-text-field v-model="item.name_by_bank" label="Название" :readonly="true" />
          </div>
        </div>
        <div class="content__row">
          <div class="row__field width_20">
            <v-text-field v-model="item.rs_number" label="Номер р/с" :readonly="true" />
          </div>
          <div class="row__field width_20">
            <v-text-field v-model="item.bik" label="БИК" :readonly="true" />
          </div>
          <div class="row__field width_20">
            <v-text-field v-model="item.bank" label="Банк" :readonly="true" />
          </div>
          <div class="row__field width_20">
            <v-text-field v-model="item.korr_number" label="Номер корр. счета" :readonly="true" />
          </div>
          <div class="row__field width_20">
            <v-text-field
              v-model="item.number"
              label="№ операции по выписке клиента"
              :readonly="true"
            />
          </div>
        </div>
        <div class="content__row">
          <div class="row__field width_20">
            <v-text-field v-model="item.phone" label="Телефон регистрации" :readonly="true" />
          </div>
        </div>
        <div class="content__row">
          <v-btn large="large" color="primary" @click="dialogDenied = false">
            Закрыть
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';
import TableList from '../Layout/TableList.vue';

export default {
  components: {
    TableList
  },
  data() {
    return {
      moment,
      openPicker: {
        from: false,
        to: false
      },
      picker: {
        from: moment()
          .subtract('days', 7)
          .format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      },
      input: {
        from: moment()
          .subtract('days', 7)
          .format('DD.MM.YYYY'),
        to: moment().format('DD.MM.YYYY')
      },
      payments: ['Все', 'Входящие', 'Исходящие'],
      payment: 'Все',
      statuses: [
        {
          title: 'Все',
          value: 'all'
        },
        {
          title: 'Исполнена',
          value: 'executed'
        },
        {
          title: 'Не исполнена по таймауту',
          value: 'timeout'
        }
      ],
      status: 'all',
      columns: [
        {
          title: 'ID',
          name: 'number',
          width: '5',
          alignLeft: true
        },
        {
          title: 'Дата заявки',
          name: 'date',
          width: '15',
          alignLeft: true
        },
        {
          title: 'Клиент',
          name: 'name',
          width: '30',
          alignLeft: true
        },
        {
          title: 'Операция',
          name: 'type',
          width: '15',
          alignLeft: true
        },
        {
          title: 'Телефон регистрации',
          name: 'phone',
          width: '15',
          alignLeft: true
        },
        {
          title: 'Сумма, руб.',
          name: 'amount',
          width: '10',
          alignLeft: true
        },
        {
          title: 'Статус',
          name: 'status',
          width: '10',
          alignLeft: true
        }
      ],
      dialogDenied: false,
      item: {}
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('counting', ['list']),
    pickerPeriod() {
      return {
        from: {
          max: this.picker.to
        },
        to: {
          min: this.picker.from,
          max: moment().format('YYYY-MM-DD')
        }
      };
    }
  },
  watch: {
    'picker.from': {
      handler(val) {
        this.updateInput(val, 'from');
      }
    },
    'picker.to': {
      handler(val) {
        this.updateInput(val, 'to');
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions('counting', ['getList']),
    updateInput(val, type) {
      this.input[type] = moment(val).format('DD.MM.YYYY');
      if (this.input.from && this.input.to) {
        this.getData();
        return;
      }
      this.openPicker[type] = false;
    },
    datePickerToggle(type) {
      if (type === 'to') {
        this.openPicker.from = false;
      } else {
        this.openPicker.to = false;
      }
      this.openPicker[type] = !this.openPicker[type];
    },
    openWindow(data) {
      this.item = data;
      this.dialogDenied = !this.dialogDenied;
    },
    getData() {
      this.openPicker = {
        from: false,
        to: false
      };
      const json = {
        from: this.picker.from
          ? new Date(this.picker.from)
          : new Date(new Date() - 180 * 24 * 60 * 60 * 1000),
        to: this.picker.to ? new Date(this.picker.to) : new Date()
      };
      if (this.status !== 'all') {
        json.status = this.status;
      }
      this.getList(json);
    }
  }
};
</script>

<style lang="scss">
.payments {
  height: 100%;
  padding: 10px;

  .payments__modal {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    position: absolute;
    height: auto;
    width: 100%;
    background-color: #fafafa;
    top: 0;
    left: 0;

    .modal__content {
      .content__row {
        display: flex;

        .row__field {
          margin: 0 10px;
        }
      }
    }

    .content__row {
      display: flex;
    }
  }

  .payments__table {
    width: 100%;
    height: calc(100% - 60px);
  }

  .payments_filters {
    position: relative;
    display: flex;
    height: 60px;

    .payments_filters__field {
      display: flex;
      position: relative;
      width: 20%;
      margin-left: 20px;

      .payments_filters__date_picker {
        position: absolute;
        z-index: 1;
        top: 50px;
      }
    }
  }
}
</style>
