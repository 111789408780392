<template>
  <v-layout wrap class="payment">
    <v-flex v-if="isAccepted" md3 xs12 sm6>
      <pseudo-input label="Принято" :content="acceptDatetime" />
    </v-flex>
    <v-flex v-else md3 xs12 sm6>
      <pseudo-input label="Предложение действительно до" :content="deadlineText" />
    </v-flex>
    <v-flex md3 xs12 sm6>
      <pseudo-input label="Стоимость перевозки" :content="cost" />
    </v-flex>
    <v-flex md3 xs12 sm6>
      <pseudo-input label="Предоплата">
        <span :class="{ 'is-different': offer.prepaid > order.prepaid }">
          {{ prepayment }}
        </span>
        {{ offer.prepaid > 0 ? ',' : '' }}
        <span :class="['payment-type', { 'is-different': isPrepaymentTypeDifferent }]">
          {{ getType(offer.prepayment_type) }}
        </span>
      </pseudo-input>
    </v-flex>
    <v-flex md3 xs12 sm6>
      <pseudo-input label="Остаток платежа">
        {{ remainedCost }}
        <span :class="['payment-type', { 'is-different': isPaymentTypeDifferent }]">
          {{ getType(offer.payment_type) }}
        </span>
      </pseudo-input>
    </v-flex>
  </v-layout>
</template>

<script>
import lightFormat from 'date-fns/lightFormat';
import { getRemainedTime } from 'common/helpers/dateHelper';
import PseudoInput from 'common/components/PseudoInput';
import { paymentTypeItems } from 'common/components/Orders/enums';

let interval;

export default {
  name: 'OfferItemPayment',
  components: { PseudoInput },
  props: {
    offer: {
      type: Object,
      default: () => ({})
    },
    order: {
      type: Object,
      default: () => ({})
    },
    isAccepted: Boolean
  },
  data: () => ({
    remainedTime: null
  }),
  computed: {
    deadlineDate() {
      return this.offer.express ? this.offer.express_datetime : this.offer.offers_deadline;
    },

    deadlineText() {
      return `${lightFormat(new Date(this.deadlineDate), 'dd.MM.yyyy, HH:mm')} (${
        this.remainedTime
      })`;
    },

    cost() {
      const nds = this.offer.nds ? 'с НДС' : 'без НДС';
      return `${this.offer.cost.toLocaleString('ru-RU')} руб. ${nds}`;
    },

    prepayment() {
      if (!this.offer.prepaid) return 'Не задана';
      return `${this.offer.prepaid?.toLocaleString('ru-RU')} руб.`;
    },

    remainedCost() {
      const remainedCost =
        Number(this.offer.cost - this.offer?.prepaid)?.toLocaleString('ru-RU') ?? '';
      return `${remainedCost} руб.,`;
    },

    isPaymentTypeDifferent() {
      return !this.order.payment_type?.includes(this.offer.payment_type);
    },

    isPrepaymentTypeDifferent() {
      return !this.order.prepayment_type?.includes(this.offer.prepayment_type);
    },

    acceptDatetime() {
      return lightFormat(new Date(this.order.accept_datetime), 'dd.MM.yyyy, HH:mm');
    }
  },
  created() {
    this.remainedTime = this.getTime();
    interval = setInterval(() => {
      this.remainedTime = this.getTime();
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(interval);
  },
  methods: {
    getTime() {
      const { days, hours, minutes } = getRemainedTime(new Date(this.deadlineDate), Date.now());
      return [days, hours, minutes].filter(_ => _).join(' ');
    },

    getType(type) {
      return paymentTypeItems.find(_ => _.value === type)?.label.toLowerCase();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';
.payment {
  margin: 0 -10px !important;
  @media screen and (max-width: breakpoint(md)) {
    margin: 0 !important;
  }
}
.payment-type {
  margin-left: 6px;
}

.is-different {
  color: red;
}
/deep/ .pseudo-input__content span {
  margin-right: 5px;
}
</style>
