import Vue from 'vue';
import Vuex from 'vuex';

// common modules
import common from 'common/store/common';
import connections from 'common/store/connections';
import feedbacks from 'common/store/feedbacks';
import orders from 'common/store/orders';
import settings from 'common/store/settings';
import { getUUID } from 'common/helpers';
import { setField } from 'common/store/helpers';

// admin modules
import adminNotifications from './modules/administration/notifications';
import adminSettings from './modules/administration/settings';
import administration from './modules/administration';
import carcasses from './modules/administration/carcasses';
import circular from './modules/circular';
import clientOrders from './modules/clients/orders';
import clientPayments from './modules/clients/payments';
import clientSupport from './modules/clients/support';
import clients from './modules/clients';
import clientsAlerts from './modules/clients/alerts';
import clientsContractors from './modules/clients/contractor';
import clientsDrivers from './modules/clients/drivers';
import clientsNotifications from './modules/clients/notifications';
import clientsProfile from './modules/clients/profile';
import clientsTransports from './modules/clients/transports';
import contentManagement from './modules/content_management';
import counting from './modules/counting';
import login from './modules/login';
import ordersFind from './modules/clients/ordersFind';
import rating from './modules/clients/rating';
import support from './modules/support';
import transportData from './modules/transportData';
import users from './modules/administration/users';
import verifications from './modules/verifications';

Vue.use(Vuex);

const { VUE_APP_WEB_PROTOCOL, VUE_APP_WEB_HOST, VUE_APP_WEB_PORT } = process.env;
const url = `${VUE_APP_WEB_PROTOCOL}://${VUE_APP_WEB_HOST}${(VUE_APP_WEB_PORT &&
  `:${VUE_APP_WEB_PORT}`) ||
  ''}`;

export default new Vuex.Store({
  state: {
    snackbars: [],
    defaultURL: url,
    errors: []
  },
  mutations: {
    setField,
    errorPush(state, text) {
      state.errors.push(text);
    }
  },
  actions: {
    addSnackbar({ state, commit }, { message = '', type = 'error' } = {}) {
      const value = [...state.snackbars, { message, type, uid: getUUID() }];
      commit('setField', { field: 'snackbars', value });
    },
    removeSnackbar({ state, commit }, uid) {
      const value = state.snackbars.filter(_ => _.uid !== uid);
      commit('setField', { field: 'snackbars', value });
    }
  },
  modules: {
    adminNotifications,
    adminSettings,
    administration,
    carcasses,
    circular,
    clientOrders,
    clientPayments,
    clientSupport,
    clients,
    clientsAlerts,
    clientsContractors,
    clientsDrivers,
    clientsNotifications,
    clientsProfile,
    clientsTransports,
    common,
    connections,
    contentManagement,
    counting,
    feedbacks,
    login,
    orders,
    ordersFind,
    rating,
    settings,
    support,
    transportData,
    users,
    verifications
  }
});
