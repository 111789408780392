export const listPaymentEvent = [
  { text: 'Факт выгрузки', value: 'unloading_fact' },
  { text: 'Предоставление скан-копий первичных документов', value: 'scan_source_documents' },
  { text: 'Предоставление оригиналов первичных документов', value: 'original_source_documents' }
];
export const paymentTypeItems = [
  { value: 'cash', label: 'Наличные' },
  { value: 'card_to_card', label: 'Перевод с карты на карту' },
  { value: 'cashless', label: 'Безналичный' }
];
export const typeBiddings = {
  bidding: 'Торги',
  blitz: 'Торги с блиц-ценой',
  biddingDescription:
    'Заказ типа "Торги" предполагает, что вы самостоятельно выбираете исполнителя по заказу, ориентируясь на предложения, поступающие к вам от перевозчиков. Все параметры стоимости заказа за исключением "Оплата по событию" и "Рабочих дней для оплаты" носят рекомендательный характер, предложения перевозчиков могут не соответствовать им',
  blitzDescription:
    'Заказ этого типа может быть взят перевозчиком с высокими рейтингом и оценкой надежности без торгов в случае его полного согласия с условиями заказа. Перевозчики с недостаточно высокими рейтингом и оценкой надежности, или не согласные с условиями заказа, могут направлять вам встречные предложения'
};

export const listPaymentEventForDoc = [
  { text: 'по факту выгрузки', value: 'unloading_fact' },
  { text: 'по предоставление скан-копий первичных документов', value: 'scan_source_documents' },
  { text: 'по предоставление оригиналов первичных документов', value: 'original_source_documents' }
];
export const paymentTypeItemsForDoc = [
  { value: 'cash', label: 'наличными' },
  { value: 'card_to_card', label: 'переводом с карты на карту' },
  { value: 'cashless', label: 'безналичный перевод' }
];
