var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('v-flex',{attrs:{"xs12":""}},[_c('h2',[_c('b',[_vm._v("Водительское удостоверение")])])]),_c('v-flex',{attrs:{"xs5":""}},[_c('custom-input',{attrs:{"value":_vm.licenseSeries,"label":"Серия","rules":[
        function (v) { return !!v || 'Поле обязательно для ввода'; },
        function (v) { return v.length === 4 || 'Серия должна быть из 4 символов'; }
      ]},on:{"input":function($event){_vm.$emit('changed', {
          field: 'licenseSeries',
          value: $event.toUpperCase()
        })}}})],1),_c('v-flex',{attrs:{"xs5":""}},[_c('custom-input',{attrs:{"value":_vm.licenseNumber,"label":"Номер","rules":[
        function (v) { return !!v || 'Поле обязательно для ввода'; },
        function (v) { return v.length === 6 || 'Номер должен быть из 6 символов'; }
      ]},on:{"input":function($event){return _vm.$emit('changed', {
          field: 'licenseNumber',
          value: $event
        })}}})],1),_c('v-flex',{attrs:{"xs2":"","d-flex":"","align-center":""}},[_c('custom-button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:((_vm.licenseSeries + " " + _vm.licenseNumber)),expression:"`${licenseSeries} ${licenseNumber}`",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(function (e) { return _vm.$emit('copy', e); }),expression:"e => $emit('copy', e)",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(function (e) { return _vm.$emit('copy-error', e); }),expression:"e => $emit('copy-error', e)",arg:"error"}],attrs:{"icon":"","color":"primary"}},[_c('v-icon',[_vm._v("file_copy")])],1)],1),_c('v-flex',{attrs:{"xs6":""}},[_c('datepicker',{attrs:{"value":_vm.licenseDate,"label":"Дата выдачи","max-date":_vm.maxLicenseDate,"rules":[function (v) { return !!v || 'Поле обязательно для ввода'; }]},on:{"input":function($event){return _vm.$emit('changed', {
          field: 'licenseDate',
          value: $event
        })}}},[_c('template',{slot:"icon"},[_c('custom-button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.copyDate),expression:"copyDate",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onSuccess),expression:"onSuccess",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(function (e) { return _vm.$emit('copy-error', e); }),expression:"e => $emit('copy-error', e)",arg:"error"}],attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return (function () { return false; })($event)}}},[_c('v-icon',[_vm._v("file_copy")])],1)],1)],2)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-checkbox',{attrs:{"value":_vm.checkLicenseGibdd,"false-value":false,"true-value":true,"rules":[function (v) { return !!v || 'Поле обязательно для ввода'; }]},on:{"change":function($event){return _vm.$emit('changed', {
          field: 'checkLicenseGibdd',
          value: $event
        })}}},[_c('template',{slot:"prepend"},[_c('a',{attrs:{"href":"https://гибдд.рф/check/driver","target":"_blank"}},[_vm._v(" Проверка на сайте ГИБДД ")])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }