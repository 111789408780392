<template>
  <div v-if="isLoading" class="text-xs-center pt-3">
    <v-progress-circular :size="40" color="primary" indeterminate />
  </div>
  <div v-else class="carrier">
    <information-tabs
      :tabs-data="tabsData"
      :set-component="setComponent"
      :active="component"
      :carrier-info="true"
    >
      <div slot="ts" class="ts-icons">
        <icon :name="carcassIcon" size="40" />
        <carrying-icon :value="capacity" />
      </div>
      <rating-count slot="rating" :rating="tabsData.rating" />
    </information-tabs>
    <keep-alive>
      <component :is="component" disable-todo-list-height :order="order" v-bind="infoData" />
    </keep-alive>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getCarcassIcon } from 'common/helpers';
import CarryingIcon from 'common/components/Orders/СarryingIcon.vue';
import Safety from 'common/components/Orders/Safety.vue';
import InformationTabs from './OrderView/InformationTabs.vue';
import Info from './OrderView/Carrier/Info.vue';
import Trucker from './OrderView/Carrier/Trucker.vue';
import Transport from './OrderView/Carrier/TS.vue';
import Rating from 'common/components/Ratings.vue';
import RatingCount from 'common/components/RatingCount';

export default {
  name: 'OrderViewCarrier',
  components: {
    InformationTabs,
    Info,
    Trucker,
    Safety,
    Transport,
    CarryingIcon,
    Rating,
    RatingCount
  },
  data: () => ({
    component: 'Info',
    isLoading: false,
    tabsData: {},
    capacity: 0,
    carcassIcon: '',
    infoData: {}
  }),
  computed: {
    ...mapState(['defaultURL']),
    ...mapState('login', ['cid']),
    ...mapState('orders', {
      order: state => state.orderInfo.info
    })
  },
  async mounted() {
    this.isLoading = true;
    try {
      const [
        {
          offer: [data]
        },
        { agent, photos }
      ] = await Promise.all([
        this.createController({
          topic: 'order/offers/accepted',
          json: { cid: this.order.cid }
        }),
        this.createController({
          topic: 'profile/organization/get_user_info',
          json: { cid: this.order.ccontact_carrier_user_id }
        })
      ]);

      const ts = data?.transport?.find(_ => _.carcass !== 'none');
      this.tabsData = {
        ...data,
        contact_name: this.order.contact_carrier_name,
        contact_phone: this.order.contact_carrier_phone,
        contact_email: this.order.contact_carrier_email,
        avatar: `${this.defaultURL}/files/${this.cid}/${this.order.ctrucker_id}/${data.photo.small_id}`
      };
      this.capacity = ts.carrying_capacity;
      this.carcassIcon = getCarcassIcon(ts.carcass);
      this.infoData = { agent, photos };
    } catch (error) {
      console.error('Ошибка получения данных для табов', error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('common', ['createController']),
    setComponent(name) {
      this.component = name;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

/deep/ .rating {
  padding: 0 12px !important;
}
/deep/ .ts-icons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
/deep/ .safety,
/deep/ .info-trucker,
/deep/ .ts {
  margin: 0 -12px !important;
}

@media only screen and (max-width: breakpoint(sm)) {
  /deep/ .safety,
  /deep/ .info-trucker,
  /deep/ .ts {
    margin: 0 !important;
  }
}
</style>
