<template>
  <div v-if="ordersList.length" class="orders">
    <view-window v-if="isWindowShowed" :cid="selectedOrderCid" @close="isWindowShowed = false" />
    <template v-else>
      <v-select v-model="filteredValue" :items="filterItems" />
      <list-loader :cursor="cursor" @load-more="loadMore">
        <OrdersListItem
          v-for="item in filteredList"
          :key="item.id"
          is-operator
          :item="item"
          @click="onClick(item.cid)"
        />
      </list-loader>
    </template>
  </div>
  <stub v-else message="Нет заказов" />
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Stub from 'common/components/Stub';
import ViewWindow from '@/components/common/ViewWindow';
import OrdersListItem from 'common/components/Orders/OrdersListItem.vue';
import ListLoader from 'common/components/ListLoader';

const filterItems = [
  {
    text: 'Все заказы',
    value: null
  },
  {
    text: 'Неразмещенные',
    value: 'editing'
  },
  {
    text: 'Размещенные',
    value: 'waiting_for_offer'
  },
  {
    text: 'Подтвержденные',
    value: ['accepted', 'in_process']
  },
  {
    text: 'Отмененные',
    value: ['canceled', 'on_dispute', 'dispute_is_done']
  },
  {
    text: 'Завершенные',
    value: 'done'
  }
];

export default {
  name: 'OrdersShipper',
  components: {
    Stub,
    OrdersListItem,
    ViewWindow,
    ListLoader
  },
  data() {
    return {
      filteredValue: null,
      selectedOrderCid: null,
      isWindowShowed: false
    };
  },
  computed: {
    ...mapState('clientOrders', ['ordersList', 'cursor']),
    filteredList() {
      return this.filteredValue
        ? this.ordersList?.filter(_ => this.filteredValue.includes(_.status))
        : this.ordersList;
    }
  },
  created() {
    this.filterItems = filterItems;
    this.getOrdersList();
  },
  beforeDestroy() {
    this.clearFeed();
  },
  methods: {
    ...mapActions('clientOrders', ['getOrdersList']),
    ...mapMutations('clientOrders', ['clearFeed']),
    onClick(cid) {
      this.selectedOrderCid = cid;
      this.isWindowShowed = true;
    },
    async loadMore() {
      this.getOrdersList(this.cursor);
    }
  }
};
</script>

<style lang="scss" scoped>
.orders {
  margin: 0 12px;
  /deep/ .v-select {
    max-width: 168px !important;
    padding: 0 !important;
  }
}
</style>
