var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-flex',{attrs:{"xs12":""}},[_c('h2',[_c('b',[_vm._v("Качество фотографий")])])]),_c('v-flex',{attrs:{"xs12":""}},[_c('v-checkbox',{attrs:{"value":_vm.isCorrectAvatar,"false-value":false,"true-value":true,"rules":[function (v) { return !!v || 'Поле обязательно для ввода'; }]},on:{"change":function($event){return _vm.$emit('changed', {
          field: 'isCorrectAvatar',
          value: $event
        })}}},[_c('span',{attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v(" Аватар ")])])],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-checkbox',{attrs:{"value":_vm.isCorrectTurnPassportPhoto,"false-value":false,"true-value":true,"rules":[function (v) { return !!v || 'Поле обязательно для ввода'; }]},on:{"change":function($event){return _vm.$emit('changed', {
          field: 'isCorrectTurnPassportPhoto',
          value: $event
        })}}},[_c('span',{attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v(" Разворот паспорта с фотографией ")])])],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-checkbox',{attrs:{"value":_vm.isCorrectTurnPassportRegistrationPhoto,"false-value":false,"true-value":true,"rules":[function (v) { return !!v || 'Поле обязательно для ввода'; }]},on:{"change":function($event){return _vm.$emit('changed', {
          field: 'isCorrectTurnPassportRegistrationPhoto',
          value: $event
        })}}},[_c('span',{attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v(" Разворот паспорта с регистрацией ")])])],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-checkbox',{attrs:{"value":_vm.isCorrectDriverLicensePhoto,"false-value":false,"true-value":true,"rules":[function (v) { return !!v || 'Поле обязательно для ввода'; }]},on:{"change":function($event){return _vm.$emit('changed', {
          field: 'isCorrectDriverLicensePhoto',
          value: $event
        })}}},[_vm._t("default",[_vm._v(" Водительское удостоверение с фотографией ")],{"slot":"prepend"})],2)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-checkbox',{attrs:{"value":_vm.isCorrectTurnDriverLicensePhoto,"false-value":false,"true-value":true,"rules":[function (v) { return !!v || 'Поле обязательно для ввода'; }]},on:{"change":function($event){return _vm.$emit('changed', {
          field: 'isCorrectTurnDriverLicensePhoto',
          value: $event
        })}}},[_c('span',{attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v(" Обратная сторона водительского удостоверения ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }