export const TYPES = {
  general: 'Одиночное ТС',
  tractor: 'Тягач',
  trailer: 'Прицеп',
  'semi-trailer': 'Полуприцеп' // TODO: semitrailer?
};

export const COLORS = {
  incomplete_data: 'verify',
  unverified: 'verify',
  waiting_for_verification: 'wait',
  denied: 'error',
  changed_by_user: 'verify',
  verified: 'verified',
  is_on_verification: 'wait',
  default: 'verify'
};

export const STATUSES = {
  incomplete_data: 'Неполные данные',
  unverified: 'Не верифицирован',
  waiting_for_verification: 'Ожидает верификации',
  denied: 'Отказ в верификации %date%',
  changed_by_user: 'Изменен пользователем',
  verified: 'Верифицирован до %date%',
  is_on_verification: 'На верификации',
  default: 'Неопределенный статус'
};

export const RIGHTUSE = [
  { text: 'Право собственности', value: 'proprietary' },
  { text: 'Лизинг', value: 'leasing' },
  { text: 'Договор аренды', value: 'lease-contract' }
];

export const SHORTRIGHTUSE = [
  { text: 'собственность', value: 'proprietary' },
  { text: 'лизинг', value: 'leasing' },
  { text: 'аренда', value: 'lease-contract' }
];
