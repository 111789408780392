<template>
  <v-container v-if="isAccepted">
    <payment :offer="offer" :order="order" is-accepted class="negative-margins" />
  </v-container>
  <v-container v-else class="offer-item">
    <v-layout wrap>
      <v-flex
        :class="['tab bordered', { 'is-active': currentTab === 'Payment' }]"
        xs12
        md5
        lg6
        @click="onClick('Payment')"
      >
        {{ cost }}{{ offer.prepaid ? ',' : '' }}
        <span v-if="offer.prepaid">
          предоплата -
          <span :style="`color: ${offer.prepaid > order.prepaid ? 'red' : 'black'};`">
            {{ offer.prepaid.toLocaleString('ru') }}
          </span>
          руб.
        </span>
        <div class="icons icons--status">
          <icon v-if="offer.new_offer" name="newIcon" size="21" class="new" />
          <icon v-if="offer.express" name="lightning" size="20" class="lightning" />
        </div>
      </v-flex>
      <v-flex
        :class="['tab bordered', { 'is-active': currentTab === 'TS' }]"
        xs3
        sm2
        md1
        lg1
        @click="onClick('TS')"
      >
        <div class="icons icons--ts">
          <icon :name="carcassIcon" size="40" class="carcass-icon" />
          <carrying-icon :value="tsData.carrying_capacity" />
        </div>
      </v-flex>
      <v-flex
        :class="[
          'tab bordered',
          { 'is-active': currentTab === 'Safety', disabled: offer.yur_type === 'FL' },
          `color-${offer.safety}`
        ]"
        xs3
        sm2
        md1
        lg1
        @click="onClick('Safety')"
      >
        <div class="icons icons--center">
          <icon name="verifiedUser" size="20" />
        </div>
      </v-flex>
      <v-flex
        :class="['tab bordered text-center', { 'is-active': currentTab === 'Rating' }]"
        xs3
        sm2
        md1
        lg1
        @click="onClick('Rating')"
      >
        <rating-count :rating="offer.rating" />
      </v-flex>
      <v-flex xs3 sm2 md2 lg1 class="tab text-center">{{ types[offer.yur_type] }}</v-flex>
      <v-flex v-if="!isOperator" xs12 sm4 md2 class="button-tab">
        <custom-button :loading="isLoading" round small class="submit-button" @click="onSubmit">
          Принять
        </custom-button>
      </v-flex>
    </v-layout>
    <keep-alive>
      <component
        :is="currentTab"
        v-if="isActive"
        :offer="offer"
        :order="order"
        class="component-view"
      />
    </keep-alive>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import { getCarcassIcon } from 'common/helpers';
import CarryingIcon from 'common/components/Orders/СarryingIcon.vue';
import Safety from 'common/components/Orders/Safety.vue';
import TS from 'common/components/Orders/TS.vue';
import Payment from './OfferItem/Payment.vue';
import Rating from 'common/components/Ratings.vue';
import RatingCount from 'common/components/RatingCount';

export default {
  name: 'OfferItem',
  components: {
    Payment,
    Rating,
    Safety,
    TS,
    CarryingIcon,
    RatingCount
  },
  props: {
    offer: {
      type: Object,
      default: () => ({})
    },
    order: {
      type: Object,
      default: () => ({})
    },
    isActive: Boolean,
    isAccepted: Boolean,
    isOperator: Boolean
  },
  data: () => ({ currentTab: null }),
  computed: {
    ...mapGetters('common', ['loading']),

    isLoading() {
      return this.isActive && this.loading('order/offers/accept');
    },

    cost() {
      const cost = `${this.offer.cost.toLocaleString('ru-RU')} руб.`;
      const nds = `${this.offer.nds ? 'c' : 'без'} НДС`;

      return `${cost} ${nds}`;
    }
  },
  watch: {
    isActive(val) {
      if (val) {
        return;
      }

      this.currentTab = null;
    }
  },
  created() {
    this.tsData = this.offer.transport.find(_ => _.carcass !== 'none') || {};
    this.carcassIcon = getCarcassIcon(this.tsData.carcass);
    this.types = {
      UL: 'ЮЛ',
      FL: 'ФЛ',
      IP: 'ИП'
    };
  },
  methods: {
    onClick(tab) {
      if (this.currentTab === tab) this.$emit('click');
      else {
        this.currentTab = tab;
        this.$emit('click', this.offer.cid);
      }
    },

    onSubmit() {
      this.$emit('click', this.offer.cid);
      this.$emit('submit', this.offer.cid);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

/deep/ .rating {
  padding: 16px 12px !important;
  &__info {
    margin: 0 -12px 0;
  }
}
.offer-item {
  background: $gray-lightest;

  &:not(:first-child) {
    margin-top: 12px;
  }

  /deep/ .flex {
    &.tab {
      padding: 12px !important;
      position: relative;

      &:not(:first-child) {
        text-align: center;
      }
    }

    &.bordered {
      cursor: pointer;

      &::after {
        content: '';
        width: calc(100% - 24px);
        height: 2px;
        background-color: #d0d3d7;
        position: absolute;
        bottom: 0;
        left: 12px;
      }

      &.is-active::after {
        background-color: $color-main;
      }
    }

    &.button-tab {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.disabled {
  pointer-events: none;

  /deep/ svg {
    fill: $gray-light;
  }
}

.color-green {
  /deep/ svg {
    fill: $color-success;
  }
}

.color-red {
  /deep/ svg {
    fill: $color-danger;
  }
}

.color-yellow {
  /deep/ svg {
    fill: $color-warning;
  }
}

.icons {
  display: inline-flex;
  align-items: center;
  height: 100%;
  margin: -5px 0 -5px 10px;
  position: relative;
  top: 3px;

  &--ts {
    margin: -20px 0;
    top: 18px;
  }

  &--center {
    width: 100%;
    margin: -5px 0;
    justify-content: center;
  }

  &--status {
    top: 5px;
  }

  .icon {
    display: inline-flex;
    align-items: center;

    /deep/ svg {
      width: 100%;
    }

    &.carcass-icon {
      margin-right: 6px;
    }

    &.lightning {
      margin-left: -10px;
    }

    &.new {
      margin-right: 10px;
    }
  }
}

.submit-button {
  margin: 0;

  @media screen and (max-width: breakpoint(sm)) {
    margin: 16px 0;
    width: 100%;
  }
}

.component-view {
  background-color: white;
  padding: 16px 0 0;
}
</style>
