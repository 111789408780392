<template>
  <div v-if="info && (info.props || info.type !== 'vehicle')" class="modal">
    <div class="vehicle_window">
      <v-toolbar dark="dark" color="primary">
        <v-toolbar-title v-if="info">
          {{ verify_title }}
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            v-if="false"
            class="block-user"
            color="news"
            fab="fab"
            small="small"
            dark="dark"
            @click="dialogBlock = true"
          >
            <v-icon>lock</v-icon>
          </v-btn>
          <v-btn icon="icon" dark="dark" @click="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div
        v-if="loading('admin/verification/choose') || !info"
        class="vehicle_window__content loading"
      >
        <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
      </div>
      <v-card v-else class="vehicle_window__content">
        <div class="vehicle_window__top">
          <div class="vehicle_window__left">
            <verifications-carrier v-if="vehicle_type === 'agents'" :data-from-server.sync="info" />
            <verifications-t-s
              v-if="vehicle_type === 'vehicles' && info.props"
              :data-from-server.sync="info"
              :chars-array.sync="info.props.characteristics"
              :opt-chars-array.sync="info.props.options_chars"
              :lifting-chars.sync="info.props.lifting"
            />
            <verifications-trucker v-if="vehicle_type === 'truckers_db'" :info.sync="info" />
          </div>
          <div class="vehicle_window__right">
            <verifications-passport
              v-if="vehicle_type === 'agents' || vehicle_type === 'truckers_db'"
            />
            <verifications-transport v-if="vehicle_type === 'vehicles' && info.photos" />
          </div>
        </div>
        <div class="vehicle_window__buttons">
          <div class="vehicle_window__left">
            <v-btn
              color="primary"
              large="large"
              :disabled="!verifyAgentIsActive && !verifyVehicleIsActive && !verifyTruckerIsActive"
              @click="checksVerify"
            >
              Верифицировать
            </v-btn>
            <v-btn color="primary" large="large" @click="dialogDenied = true">
              Отказать
            </v-btn>
          </div>
          <div class="vehicle_window__right">
            <v-btn color="primary" large="large" @click="save">
              Сохранить изменения и закрыть
            </v-btn>
            <v-btn large="large" outline="outline" color="primary" @click="closeDialog">
              Отменить
            </v-btn>
          </div>
        </div>
      </v-card>
    </div>
    <v-dialog v-model="dialogBlock" class="ui-kit__dialog" persistent max-width="500px">
      <div class="ui-kit__dialog__header">
        <div class="ui-kit__dialog__title">
          Заблокировать транспортное средство
        </div>
        <v-spacer />
        <v-btn fab="fab" small="small" color="primary" @click.stop="dialogBlock = false">
          <v-icon dark="dark">
            close
          </v-icon>
        </v-btn>
      </div>
      <div class="ui-kit__dialog__content">
        <v-textarea class="ui-kit__input" label="Причина блокировки" />
        <div class="block-btns">
          <v-btn color="primary" large="large">
            Заблокировать
          </v-btn>
          <v-btn large="large" outline="outline" color="primary" @click.stop="dialogBlock = false">
            Оменить
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="dialogDenied" class="ui-kit__dialog" persistent max-width="500px">
      <div class="ui-kit__dialog__header">
        <div class="ui-kit__dialog__title">
          Отказать в верификации
        </div>
        <v-spacer />
        <v-btn
          class="ui-kit__dialog__close"
          fab="fab"
          small="small"
          color="primary"
          @click.stop="dialogDenied = false"
        >
          <v-icon dark="dark">
            close
          </v-icon>
        </v-btn>
      </div>
      <div class="ui-kit__dialog__content">
        <v-textarea v-model="deniedText" class="ui-kit__input" label="Причина отказа" />
        <div class="block-btns">
          <v-btn color="primary" large="large" @click="deniedVerification">
            Отказать
          </v-btn>
          <v-btn large="" outline="" color="primary" @click.stop="dialogDenied = false">
            Оменить
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import utils from '@/helpers/utils';
import VerificationsCarrier from './VerificationsCarrier.vue'; // TODO old legacy
import VerificationsPassport from './VerificationsPassport.vue'; // TODO old legacy
import VerificationsTransport from './VerificationsTransport.vue'; // TODO old legacy
import VerificationsTS from './VerificationsTS.vue'; // TODO old legacy

import VerificationsTrucker from './VerificationsTrucker';

const timer = null;
export default {
  name: 'VerificationWindow',
  components: {
    VerificationsTrucker,
    VerificationsCarrier,
    VerificationsPassport,
    VerificationsTransport,
    VerificationsTS
  },
  data() {
    return {
      item: false,
      dialogBlock: false,
      dialogDenied: false,
      deniedText: ''
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('adminSettings', ['list', 'groups']),
    ...mapState('verifications', ['info', 'lastInsertedItems']),
    ...mapState('settings', ['deallocationTimeVerification']),
    verify_title() {
      const { info } = this;
      if (this.info.type !== 'vehicle') {
        const types = {
          truckers: 'водителя',
          contractor: 'перевозчика',
          agent: 'перевозчика'
        };
        return `Верификация ${types[info.type]}: ${info.lastname} ${info.name[0]}.${
          info.patronymic ? info.patronymic[0] : ''
        }`;
      }
      const { vehicle } = info;
      return `Верификация ТС: ${utils.transportTypes[vehicle.vehicle_type]} ${
        vehicle.brand_and_model
      }, ${vehicle.registration_plate}`;
    },
    vehicle_id() {
      return this.$route.params.vehicle_id;
    },
    vehicle_type() {
      return this.$route.params.type || '';
    },
    modalIsActive() {
      return this.modalShow === true && this.info !== null;
    },
    verifyAgentIsActive() {
      const fields = [
        'check_photo',
        'check_inn',
        'check_lastname',
        'check_name',
        'check_birthday_date',
        'check_passport_serie',
        'check_passport_number',
        'check_passport_fms',
        'check_passport_tax',
        'check_passport_date'
      ];
      return fields.filter(field => this.info[field] !== true).length === 0;
    },
    verifyVehicleIsActive() {
      const fields = [
        'check_vin',
        'check_brand_and_model',
        'check_registration_plate',
        'check_release_year',
        'check_type_right_use',
        'check_gibdd'
      ];
      if (!this.info.vehicle) {
        return false;
      }
      if (this.info.vehicle.vehicle_type !== 'tractor') {
        fields.push('check_wagon_type');
      }
      if (this.info.vehicle.type_right_use !== 'proprietary') {
        fields.push('check_valid_until');
        fields.push('check_name_holder_docs');
        fields.push('check_name_carier_docs');
      } else {
        fields.push('check_name_carier_holder');
      }
      return fields.filter(field => this.info.vehicle[field] !== true).length === 0;
    },
    verifyTruckerIsActive() {
      const fields = [
        'check_birthday_date',
        'check_birthday_date_pas_lic',
        'check_birthday_place_pas_lic',
        'check_lastname',
        'check_license_date',
        'check_license_gibdd',
        'check_license_number',
        'check_license_serie',
        'check_name',
        'check_passport_date',
        'check_passport_fms',
        'check_passport_number',
        'check_passport_serie',
        'check_patronymic',
        'check_photo'
      ];
      return fields.filter(field => this.info[field] !== true).length === 0;
    }
  },
  watch: {
    vehicle_id() {
      this.load();
    },
    lastInsertedItems(items) {
      const isExpiredCard = !!items.find(_ => _.cparent_id === this.vehicle_id);
      if (isExpiredCard) this.closeCards();
    }
  },
  mounted() {
    this.load();
  },
  beforeDestroy() {
    clearTimeout(timer);
  },
  methods: {
    ...mapActions('verifications', [
      'getInfo',
      'denialVerification',
      'checkVerify',
      'afterVerify',
      'verifyCancel',
      'getItems'
    ]),
    closeCards() {
      this.$router.push({ name: 'Verifications', query: { showMessageTimer: true } });
    },
    closeDialog(isCancel = true) {
      if (isCancel) {
        this.verifyCancel(this.vehicle_id);
      }
      this.$router.push({ name: 'Verifications' });
    },
    load() {
      this.getInfo(this.vehicle_id);
    },
    deniedVerification() {
      this.denialVerification({ info: this.info, deniedText: this.deniedText });
      this.dialogDenied = false;
      this.closeDialog(false);
    },
    checksVerify() {
      this.checkVerify({ info: this.info, isSave: false });
      this.closeDialog(false);
    },
    async save() {
      this.checkVerify({ info: this.info, isSave: true });
      this.getItems();
      this.closeDialog(false);
    }
  }
};
</script>

<style lang="scss">
.vehicle_window {
  height: 100%;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;

  input {
    color: black !important;
    font-size: 15px;
  }

  .vehicle_window__content {
    height: calc(100% - 65px);
    background-color: #fff;

    .vehicle_window__top {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      height: calc(100% - 65px);
    }

    .vehicle_window__content__line {
      display: flex;
      align-items: center;
      height: 44px;
      padding-left: 15px;
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      background-color: #169bd5;
    }

    .vehicle_window__left {
      min-width: 40%;
      max-width: 40%;
      overflow-y: auto;
      padding: 10px;

      .vehicle_window__left__actions {
        height: calc(100% - 130px);
        padding: 10px 15px;
      }
    }

    .vehicle_window__right {
      background-color: #f2f2f2;
      flex-grow: 1;
      overflow: hidden;
    }

    .vehicle_window__buttons {
      display: flex;
      height: 65px;
      padding: 0 5px 10px 5px;
      .vehicle_window__left {
        padding: 0;
        display: flex;
        overflow: hidden;
      }
      button {
        width: 46%;
      }
    }
  }
}
</style>
