<template>
  <div class="carrier">
    <div class="carrier__status">
      <img class="carrier__avatar" alt="Avatar" :src="AvatarURL" />
      <v-checkbox v-model="dataFromServer.check_photo" />
      <span :style="{ color: getVerifyColor }">{{ verify_title }}</span>
    </div>
    <h2 class="ui-kit__heading">
      Паспорт
    </h2>
    <div class="carrier__row">
      <v-text-field label="Фамилия" readonly="readonly" :value="dataFromServer.lastname" />
      <v-btn
        v-clipboard:copy="dataFromServer.lastname"
        v-clipboard:success="onCopy"
        v-clipboard:error="onCopyError"
        class="copy-btn"
        icon=""
        flat=""
        color="news"
      >
        <v-icon>file_copy</v-icon>
      </v-btn>
      <v-checkbox v-model="dataFromServer.check_lastname" class="checkbox" />
    </div>
    <div class="carrier__row">
      <v-text-field label="Имя" readonly="readonly" :value="dataFromServer.name" />
      <v-btn
        v-clipboard:copy="dataFromServer.name"
        v-clipboard:success="onCopy"
        v-clipboard:error="onCopyError"
        class="copy-btn"
        icon=""
        flat=""
        color="news"
      >
        <v-icon>file_copy</v-icon>
      </v-btn>
      <v-checkbox v-model="dataFromServer.check_name" class="checkbox" />
    </div>
    <div class="carrier__row">
      <v-text-field label="Отчество" readonly="readonly" :value="dataFromServer.patronymic" />
      <v-btn
        v-clipboard:copy="dataFromServer.patronymic"
        v-clipboard:success="onCopy"
        v-clipboard:error="onCopyError"
        class="copy-btn"
        icon=""
        flat=""
        color="news"
      >
        <v-icon>file_copy</v-icon>
      </v-btn>
      <v-checkbox class="checkbox" />
    </div>
    <div class="carrier__split">
      <div class="carrier__row">
        <v-text-field label="Серия" readonly="readonly" :value="dataFromServer.passport_serie" />
        <v-checkbox v-model="dataFromServer.check_passport_serie" class="checkbox" />
        <v-btn
          v-clipboard:copy="dataFromServer.passport_serie"
          v-clipboard:success="onCopy"
          v-clipboard:error="onCopyError"
          class="copy-btn"
          icon=""
          flat=""
          color="news"
        >
          <v-icon>file_copy</v-icon>
        </v-btn>
      </div>
      <div class="carrier__row">
        <v-text-field label="Номер" readonly="readonly" :value="dataFromServer.passport_number" />
        <v-btn
          v-clipboard:copy="dataFromServer.passport_number"
          v-clipboard:success="onCopy"
          v-clipboard:error="onCopyError"
          class="copy-btn"
          icon=""
          flat=""
          color="news"
        >
          <v-icon>file_copy</v-icon>
        </v-btn>
        <v-checkbox v-model="dataFromServer.check_passport_number" class="checkbox" />
      </div>
    </div>
    <div class="carrier__split">
      <div class="carrier__row">
        <v-text-field
          label="Дата выдачи"
          :value="dataFromServer.passport_date"
          :disabled="dataFromServer.overdue.passport"
          :class="{ field_error: dataFromServer.overdue.passport }"
        />
        <v-btn
          v-clipboard:copy="dataFromServer.passport_date"
          v-clipboard:success="onCopy"
          v-clipboard:error="onCopyError"
          class="copy-btn"
          icon=""
          flat=""
          color="news"
        >
          <v-icon>file_copy</v-icon>
        </v-btn>
        <v-checkbox
          v-model="dataFromServer.check_passport_date"
          class="checkbox"
          :disabled="dataFromServer.overdue.passport"
        />
      </div>
      <div class="carrier__row">
        <v-text-field
          label="Дата рождения"
          readonly="readonly"
          :value="dataFromServer.birthday_date"
        />
        <v-btn
          v-clipboard:copy="dataFromServer.birthday_date"
          v-clipboard:success="onCopy"
          v-clipboard:error="onCopyError"
          class="copy-btn"
          icon=""
          flat=""
          color="news"
        >
          <v-icon>file_copy</v-icon>
        </v-btn>
        <v-checkbox v-model="dataFromServer.check_birthday_date" class="checkbox" />
      </div>
    </div>
    <div class="carrier__row">
      <a href="http://services.fms.gov.ru/info-service.htm?sid=2000" target="_blank">
        Проверка на сайте ФМС
      </a>
      <v-checkbox v-model="dataFromServer.check_passport_fms" class="checkbox" />
    </div>
    <h2 class="ui-kit__heading">
      Налоговые данные
    </h2>
    <div class="carrier__split">
      <div class="carrier__row">
        <v-text-field label="ИНН" readonly="readonly" :value="dataFromServer.inn" />
        <v-btn
          v-clipboard:copy="dataFromServer.inn"
          v-clipboard:success="onCopy"
          v-clipboard:error="onCopyError"
          class="copy-btn"
          icon=""
          flat=""
          color="news"
        >
          <v-icon>file_copy</v-icon>
        </v-btn>
        <v-checkbox v-model="dataFromServer.check_inn" class="checkbox" />
      </div>
      <div class="carrier__row">
        <a href="https://service.nalog.ru/inn.do" target="_blank">Проверка на сайте налоговой</a>
        <v-checkbox v-model="dataFromServer.check_passport_tax" class="checkbox" />
      </div>
    </div>
    <v-snackbar v-model="snackbar" bottom="" left="" :timeout="2000">
      {{ snackbarMessage }}
      <v-btn color="news" flat="" @click="snackbar = false">
        Закрыть
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import utils from '@/helpers/utils';

export default {
  name: 'VerificationsCarrier',
  props: {
    dataFromServer: Object
  },
  data() {
    return {
      snackbar: false,
      snackbarMessage: ''
    };
  },
  computed: {
    ...mapState('login', ['cid']),
    defaultURL() {
      return this.$store.state.defaultURL;
    },
    /**
     * @return {string}
     */
    AvatarURL() {
      const avatar = this.dataFromServer.photos.find(item => item.type === 'my_photo');
      if (!avatar) {
        return 'http://hotchillitri.co.uk/wp-content/uploads/2016/10/empty-avatar.jpg';
      }
      return `${this.defaultURL}/files/${this.cid}/${this.dataFromServer.cid}/${avatar.medium}`;
    },
    verify_title() {
      return utils.statuses[this.dataFromServer.verif_status];
    },
    getVerifyColor() {
      return utils.verifyColors[this.dataFromServer.verif_status];
    }
  },
  methods: {
    onCopy(e) {
      this.snackbar = true;
      this.snackbarMessage = `${'Скопировано: '}${e.text}`;
    },
    onCopyError: () => {
      this.snackbar = true;
      this.snackbarMessage = 'Ошибка: не скопировано!';
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';
.vehicle_window {
  .carrier {
    &__split {
      display: flex;
      .carrier__row:first-child {
        margin-right: 20px;
      }
    }
    &__row {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
    }
    .ui-kit__heading {
      font-weight: bold;
    }
    &__status {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      img {
        max-width: 110px;
        min-width: 110px;
        height: 110px;
        border-radius: 50%;
        margin-right: 20px;
      }
      span {
        align-self: flex-start;
        margin: 10px 0 0 0;
      }
    }
    .carrier__avatar {
      max-width: 200px;
      max-height: 200px;
    }
    .not-verified {
      color: $color-danger;
    }
    .verified {
      color: $color-success;
    }
    .checkbox {
      max-width: 30px;
      margin-left: 15px;
    }
  }

  .copy-btn {
    color: $color-main;
    margin: 0;
    position: absolute;
    top: 6px;
    right: 50px;
    .material-icons {
      color: $color-main;
    }
  }
}
</style>
