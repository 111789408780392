const noop = () => {};

let leftHandlerFn = noop;
let rightHandlerFn = noop;
let topHandlerFn = noop;
let bottomHandlerFn = noop;
let prevLeftHandlerFn = noop;
let prevRightHandlerFn = noop;

let xDown = null;
let yDown = null;

function handleTouchMove(evt) {
  if (!xDown || !yDown) {
    return;
  }

  const xUp = evt.touches[0].clientX;
  const yUp = evt.touches[0].clientY;

  const xDiff = xDown - xUp;
  const yDiff = yDown - yUp;

  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    /* most significant */
    if (xDiff > 0) {
      leftHandlerFn(evt);
    } else {
      rightHandlerFn(evt);
    }
  } else if (yDiff > 0) {
    topHandlerFn(evt);
  } else {
    bottomHandlerFn(evt);
  }

  /* reset values */
  xDown = null;
  yDown = null;
}

export function handleSwipe({ leftHandler, rightHandler, topHandler, bottomHandler }) {
  if (leftHandler) leftHandlerFn = leftHandler;
  if (rightHandler) rightHandlerFn = rightHandler;
  if (topHandler) topHandlerFn = topHandler;
  if (bottomHandler) bottomHandlerFn = bottomHandler;

  function handleTouchStart(evt) {
    const firstTouch = evt.touches[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  document.addEventListener('touchstart', handleTouchStart, false);
  document.addEventListener('touchmove', handleTouchMove, false);
}

export function replaceHandlers({ leftHandler, rightHandler }) {
  document.removeEventListener('touchmove', handleTouchMove, false);

  prevLeftHandlerFn = leftHandlerFn;
  prevRightHandlerFn = rightHandlerFn;

  leftHandlerFn = leftHandler;
  rightHandlerFn = rightHandler;

  document.addEventListener('touchmove', handleTouchMove, false);
}

export function revertHandlers() {
  document.removeEventListener('touchmove', handleTouchMove, false);

  leftHandlerFn = prevLeftHandlerFn;
  rightHandlerFn = prevRightHandlerFn;

  document.addEventListener('touchmove', handleTouchMove, false);
}
