<template>
  <Tabs :tabs="tabs" :active="activeTab" @change="changeTab" />
</template>

<script>
import Tabs from '../Layout/Tabs.vue';
import Actions from './Actions.vue';
import Projects from './Projects.vue';
import Groups from './Groups.vue';

export default {
  name: 'Marketing',
  components: {
    Tabs
  },
  data() {
    return {
      tabs: [
        {
          name: 'actions',
          title: 'Акции',
          component: Actions,
          disabled: true
        },
        {
          name: 'circularProjects',
          title: 'Проекты уведомлений',
          component: Projects
        },
        {
          name: 'circularGroups',
          title: 'Группы рассылок',
          component: Groups
        }
      ],
      activeTab: this.$route.params.stage || 'circularProjects'
    };
  },
  methods: {
    changeTab(stage) {
      this.$router.push({ name: 'MarketingStage', params: { stage } });
    }
  }
};
</script>
