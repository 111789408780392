<template>
  <div
    v-tooltip="{
      content: tooltipText,
      placement: tooltipPlacement
    }"
    style="display: inline-block;"
  >
    <slot />
  </div>
</template>

<script>
const TOOLTIP_POSITIONS = [
  'auto',
  'auto-start',
  'auto-end',
  'top',
  'top-start',
  'top-end',
  'right',
  'right-start',
  'right-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'left',
  'left-start',
  'left-end'
];

export default {
  name: 'Tooltip',
  props: {
    tooltip: {
      type: String,
      default: ''
    },
    tooltipPlacement: {
      type: String,
      default: 'bottom',
      validator(value) {
        return TOOLTIP_POSITIONS.includes(value);
      }
    },
    hide: Boolean
  },
  computed: {
    tooltipText() {
      return !this.hide && this.tooltip ? this.tooltip : null;
    }
  }
};
</script>
