<template>
  <div v-if="isLoading" class="loading">
    <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
  </div>
  <div v-else class="invite_statistics">
    <div class="statistics__box">
      <div class="box__row">
        <div class="box__row__text bold">
          Количество перевозчиков, прошедших первичную регистрацию с использованием вашей
          реферальной ссылки или вашего промокода
        </div>
        <div class="box__row__text bold count">
          {{ bonuses.total || 0 }}
          <span class="count_green">(+{{ bonuses.total_growth || 0 }})</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'InviteStatistics',
  data() {
    return {
      bonuses: {}
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    isLoading() {
      return this.loading(this.getStatisticsUrl);
    },
    enduser_id() {
      return parseInt(this.$route.params.enduser);
    }
  },
  created() {
    this.getStatisticsUrl = 'admin/endusers/contractor/bonuses/promotions/get';
  },
  mounted() {
    this.getStatistics(this.enduser_id);
  },
  methods: {
    ...mapActions('common', ['createController']),

    async getStatistics(enduser) {
      const topic = this.getStatisticsUrl;
      try {
        this.bonuses = await this.createController({ topic, json: { enduser } });
      } catch (e) {
        console.error(`Ошибка в запросе ${topic}`);
      }
    }
  }
};
</script>

<style lang="scss">
.invite_statistics {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);

  .statistics__box {
    padding: 15px;

    .box__row {
      display: flex;
      padding: 15px 0;
      &.short {
        width: 60%;
      }
      .box__row__text {
        &.count {
          margin-left: auto;
        }
        .count {
          &_orange {
            color: orange;
          }
          &_green {
            color: green;
          }
          &_red {
            color: red;
          }
        }
      }
    }
  }
}
</style>
