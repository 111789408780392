<template>
  <div class="autotrain-chr">
    <h4 v-if="!forOffer" class="ui-kit__heading">
      Технические характеристики кузова
    </h4>

    <v-layout row wrap class="autotrain-panel__row">
      <v-flex xs12 sm4 lg4>
        <custom-input :value="type" label="Тип кузова " disabled />
      </v-flex>
    </v-layout>
    <v-layout row wrap class="autotrain-panel__row">
      <v-flex v-for="(block, j) in characteristics" :key="j" xs12 md6 lg4>
        <custom-input
          :value="data.properties[block.id]"
          :label="block.carrier.name + ', ' + block.unit_measure"
          disabled
        />
      </v-flex>
      <v-flex v-if="options" xs12>
        <div class="text-field-resize">
          <div class="text-field-resize__label">
            Имеющиеся опции
          </div>
          <div class="text-field-resize__text">
            <div class="py-11px">
              {{ options }}
            </div>
          </div>
        </div>
      </v-flex>
      <v-flex xs12>
        <div class="text-field-resize">
          <div class="text-field-resize__label">
            Допустимые способы погрузки/разгрузки
          </div>
          <div class="text-field-resize__text">
            <div class="py-11px">
              {{ lifting }}
            </div>
          </div>
        </div>
      </v-flex>
      <v-flex v-if="smallSliderImages.length" xs12>
        <swiper
          :images="smallSliderImages"
          :limit="3"
          url-field="tinySrc"
          @image-click="openFullImage"
        />
      </v-flex>
    </v-layout>
    <swiper-dialog
      v-if="isShow"
      :images="smallSliderImages"
      :slide-index="slideIndex"
      @close="isShow = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { swiperDialog, swiper } from 'common/components/swiper';

const mapHandler = arr => _ => arr.find(el => el.id === _);

export default {
  name: 'AutoTrainChr',
  components: {
    swiper,
    swiperDialog
  },
  props: {
    isOperator: Boolean,
    forOffer: Boolean,
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      slideIndex: 0,
      isShow: false
    };
  },
  computed: {
    ...mapState({
      scheme: state => state.transportData.scheme,
      cid: state => state.login.cid,
      defaultURL: state => state.defaultURL
    }),
    type() {
      const type = this.scheme.carcasses.find(
        element => element.id === this.data.tail.vehicle?.carcass
      );
      return type && type.name;
    },
    options() {
      const {
        opt_chr_head: optChrHead,
        opt_chr_tail: optChrTail,
        opt_head: optHead,
        opt_tail: optTail
      } = this.data.properties;
      const getChars = (arr, name) =>
        arr
          .map(_ => this.scheme.options_chars.find(chr => chr.id === _))
          .map(_ => ({
            id: _.id,
            value: this.data.properties[name][_.id],
            name: _.carrier.name,
            measure: _.unit_measure
          }));

      const headChars = optChrHead ? getChars(Object.keys(optChrHead), 'opt_chr_head') : [];
      const tailChars = optChrTail ? getChars(Object.keys(optChrTail), 'opt_chr_tail') : [];

      const getCharString = (arr, charsArr) =>
        arr
          .map(mapHandler(this.scheme.options))
          .filter(_ => _)
          .map(_ => {
            const chars = _.characteristics
              .map(chr => charsArr.find(hChr => hChr.id === chr))
              .map(chr => (chr ? `${chr.name}: ${chr.value} ${chr.measure}` : ''))
              .filter(chr => chr)
              .join(', ');

            return `${_.name}${chars ? ` (${chars})` : ''}`;
          })
          .join(', ');

      const headOptions = optHead ? getCharString(optHead, headChars) : '';
      const tailOptions = optTail ? getCharString(optTail, tailChars) : '';

      const bothIsNotEmpty = headOptions.length && tailOptions.length;
      return `${headOptions}${bothIsNotEmpty ? ' | ' : ''}${tailOptions}`;
    },
    lifting() {
      const { lift_tail: liftTail, lift_head: liftHead } = this.data.properties;
      const headLifting = liftHead
        ? liftHead
            .map(mapHandler(this.scheme.lifting))
            .map(_ => _.name)
            .join(', ')
        : '';

      const tailLifting = liftTail
        ? liftTail
            .map(mapHandler(this.scheme.lifting))
            .map(_ => _.name)
            .join(', ')
        : '';
      const bothIsNotEmpty = headLifting.length && tailLifting.length;
      return `${headLifting}${bothIsNotEmpty ? ' / ' : ''}${tailLifting}`;
    },
    characteristics() {
      return this.scheme.characteristics.filter(item => this.data.properties[item.id]);
    },
    smallSliderImages() {
      if (this.forOffer) return this.data?.wagon.photos ?? [];
      return (
        this.data?.wagon.photos?.map(element => ({
          ...element,
          tinySrc: `${this.routeSrc}/${element.tiny}`,
          src: `${this.routeSrc}/${element.file}`
        })) ?? []
      );
    }
  },
  created() {
    if (this.isOperator) {
      const cidClients = this.$store.state.clients.info.cid;
      this.routeSrc = `${this.defaultURL}/files/${this.cid}/${cidClients}`;
    } else this.routeSrc = `${this.defaultURL}/files/${this.cid}`;
  },
  methods: {
    openFullImage(index) {
      this.isShow = true;
      this.slideIndex = index;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.autotrain-chr {
  padding: 0 12px;
}

.text-field-resize {
  display: flex;
  flex-direction: column;
  &__label {
    color: black;
    font-size: 14px;
    font-family: Roboto;
    font-weight: bold;
  }
  &__text {
    border-bottom: 1px solid #acacac;
    margin-bottom: 16px;
  }
}

.py-11px {
  padding: 11px 0;
}
.ui-kit__heading {
  margin-left: 0;
  margin-bottom: 24px;
}
.autotrain-slider {
  margin: 0 0 25px 0;
}

.container.grid-list-lg .layout .flex {
  padding: 0 12px;
}

.img-responsive {
  max-width: 100%;
  width: auto;
  height: 100%;
}

.autotrain-panel {
  margin-top: 40px;
  &__photolabel {
    display: block;
    font-size: 12px;
    color: $gray;
    margin: 0 0 6px 0;
  }
  &__row {
    margin: auto -12px !important;
  }
  &__content {
    padding: 0 12px;
  }
  &__head {
    display: flex;
    align-items: center;
    height: 44px;
    background-color: $gray-lightest;
    border-bottom: 1px solid #eceff3;
    padding-left: 12px;
    &:first-child {
      border-top: 1px solid #eceff3;
    }
  }
  &__item {
    &__label {
      color: $gray-darken;
      font-size: 14px;
      font-weight: 600;
      flex-basis: 58.24%;
    }

    &__status {
      font-size: 14px;
      font-weight: normal;
    }
    &__buttons {
      margin-left: auto;
    }
    &__toggle {
      margin-left: 0;
    }
    &__edit,
    &__delete {
      margin: 0 5px 0 0;
      .material-icons {
        color: $color-main;
        font-size: 19px;
      }
    }
    &__status.verify {
      color: $color-main;
    }
    &__status.verified {
      color: #1eb282;
    }
    &__status.refusal {
      color: $color-error;
    }
    &__status.wait {
      color: $color-warning;
    }
  }
}
</style>
