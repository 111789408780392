<template>
  <div>
    <div v-if="isLoading" class="preloader">
      <v-progress-circular :size="40" color="primary" indeterminate />
    </div>
    <div ref="rating" class="rating">
      <header
        id="rating-header"
        :style="{ top: todoListHeight() }"
        :class="['header-wrapper', { 'pt-0': !isOwner }, { unsticky: disableTodoListHeight }]"
      >
        <div v-if="!about || isAboutMe" id="rating-info" class="rating__info">
          <h2 class="rating__info-title">Общий рейтинг - {{ rating }}% положительных отзывов</h2>
          <div class="rating__info-block">
            <span>Отзывов - {{ totalFeedbacks }}</span>
            <span>Заказов - {{ totalOrders }}</span>
          </div>
        </div>
        <h2 v-if="about" id="rating-title" :class="['rating__h2', { 'mt-0': !isAboutMe }]">
          {{ isAboutMe ? 'Отзывы обо мне' : 'Мои отзывы' }}
        </h2>
        <v-layout id="rating-tabs" wrap>
          <!-- TODO Create tabs slider component -->
          <v-flex ref="tabs" :class="['rating__tabs', { 'is-owner': isOwner }]" xs12>
            <p
              v-for="(tab, index) in tabs"
              :key="index"
              :class="['cargo-tab', { 'is-active': currentTab === tab.type }]"
              @click="currentTab = tab.type"
            >
              {{ tab.title }}
            </p>
            <div :style="{ width: `${sliderWidth}px`, left: `${sliderOffset}px` }" class="slider" />
          </v-flex>
        </v-layout>
      </header>
      <div v-if="feedbacks.length" class="rating__items">
        <list-loader
          ref="componetsListLoader"
          :cursor="cursor"
          @load-more="about ? getFeedbacks(requestOptions) : getFeedbacksAgent(requestOptions)"
        >
          <FeedbackBlock
            v-for="feedback in showedFeedbacks"
            :key="feedback.id"
            :feedback="feedback"
            :class="{ pointer: isOwner }"
            @click="$emit('click', $event)"
          />
        </list-loader>
      </div>
      <stub v-else-if="!feedbacks.length && !isLoading" message="Нет отзывов" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import FeedbackBlock from 'common/components/Orders/FeedbackBlock';
import Stub from 'common/components/Stub';
import ListLoader from 'common/components/ListLoader';

const getElementRectsById = ({ id, rect }) => {
  try {
    return document.getElementById(id)?.getBoundingClientRect()?.[rect] || 0;
  } catch (error) {
    console.error(error);
  }
};

export default {
  name: 'Rating',
  components: { FeedbackBlock, Stub, ListLoader },
  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    offer: {
      type: Object,
      default: () => ({})
    },
    about: String,
    isOwner: Boolean,
    isOperator: Boolean,
    disableTodoListHeight: Boolean
  },
  data: () => ({
    currentTab: 'all',
    sliderOffset: 0,
    sliderWidth: 0,
    isLoading: false
  }),
  computed: {
    ...mapState(['isMobile']),
    ...mapState('feedbacks', ['feedbacks', 'cursor', 'rating', 'totalFeedbacks', 'totalOrders']),
    ...mapGetters('common', ['loading']),
    ...mapGetters('login', ['isShipper']),
    isAboutMe() {
      return this.about === 'me';
    },
    whoseCid() {
      return this.isShipper ? 'ccarrier_id' : 'cshipper_id';
    },
    contragentCid() {
      return this.offer[this.whoseCid] || this.order[this.whoseCid];
    },
    showedFeedbacks() {
      return this.isOwner ? this.feedbacks : this.feedbacks.filter(_ => !_.hidden);
    },
    requestOptions() {
      return {
        about: this.about,
        evaluation: this.currentTab,
        isOperator: this.isOperator,
        agent_cid: this.contragentCid
      };
    }
  },
  watch: {
    currentTab: {
      immediate: true,
      async handler(oldValue, value) {
        if (oldValue && value) this.$refs.componetsListLoader?.toTop();
        this.isLoading = true;
        if (this.about) {
          await this.getFeedbacks(this.requestOptions);
        } else {
          await this.getFeedbacksAgent(this.requestOptions);
        }
        this.updateSlider();
        this.isLoading = false;
      }
    }
  },
  created() {
    this.tabs = [
      {
        title: 'Все',
        type: 'all'
      },
      {
        title: 'Положительные',
        type: 'positive'
      },
      {
        title: 'Нейтральные',
        type: 'neutral'
      },
      {
        title: 'Отрицательные',
        type: 'negative'
      }
    ];
    this.updateSlider();
  },
  beforeDestroy() {
    this.clearFeedbacks();
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions('feedbacks', ['getFeedbacks', 'getFeedbacksAgent']),
    ...mapMutations('feedbacks', ['clearFeedbacks']),

    updateSlider() {
      this.$nextTick(() => {
        const el = this.$refs.tabs?.querySelector('.is-active');
        if (!el) return;
        this.sliderOffset = el.offsetLeft;
        this.sliderWidth = el.clientWidth;
        if (window.matchMedia('(max-width: 600px)').matches) {
          el.scrollIntoView({ inline: 'center', block: 'end' });
        }
      });
    },

    todoListHeight() {
      const todoListHeight = Number(this.$store.state.todo.todoListHeight);

      if (this.disableTodoListHeight) {
        return '0px';
      }

      if (this.about !== 'me') {
        return `${getElementRectsById({ id: 'app-header', rect: 'height' }) +
          todoListHeight +
          getElementRectsById({ id: 'cargo-tabs', rect: 'height' })}px`;
      }

      return `${getElementRectsById({ id: 'app-header', rect: 'height' }) +
        todoListHeight +
        getElementRectsById({ id: 'cargo-tabs', rect: 'height' })}px`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

/deep/.content-block {
  margin: 0 0 16px;
}
/deep/ .content-block-container {
  padding: 12px 12px 2px 12px;
  margin: 0;
}

/deep/.preloader {
  display: flex;
  justify-content: center;
  margin: 17px 0;
  position: absolute;
  margin: auto;
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  z-index: 1;
}
.header-wrapper {
  position: sticky;
  background: #fff;
  z-index: 1;
  margin: 0 -24px;
  width: calc(100% + 48px);
  padding: 16px 24px 0;
  &.unsticky {
    position: relative;
  }
  @media screen and (max-width: breakpoint(lg)) {
    position: relative;
    top: 0 !important;
    margin: 0 -12px;
    width: calc(100% + 24px);
  }
  @media screen and (max-width: breakpoint(sm)) {
    margin: 0 -16px;
    padding: 16px 16px 0;
    width: 100%;
    width: calc(100% + 32px);
  }
}

.pointer {
  cursor: pointer;
}

.rating {
  position: relative;
  &__info {
    margin: 0 -12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.15);
    &-title {
      margin: 8px 0;
    }
    &-block {
      display: flex;
      > span {
        margin-right: 8px;
      }
    }
  }
  &__items {
    margin: 16px 0 0;
    @media screen and (min-width: breakpoint(lg)) {
      margin: 16px -12px 0;
    }
  }
  &__tabs {
    cursor: pointer;
    display: flex;
    position: relative;
    padding: 0 !important;
    height: 52px;
    max-height: 52px;
    min-height: 52px;
    padding: 0 12px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-bottom: 1px solid $gray-lighten;

    &::-webkit-scrollbar {
      display: none;
    }

    &:after {
      content: '';
      width: 4px;
      flex-shrink: 0;
    }
  }
  .is-owner {
    margin: 0 -36px;
    max-width: calc(100% + 72px) !important;
    min-width: calc(100% + 72px) !important;
    padding: 0 24px !important;
    @media screen and (max-width: breakpoint(sm)) {
      margin: 0 -12px;
      max-width: 100% !important;
      min-width: 100% !important;
      padding: 0 !important;
    }
  }
}
.cargo-tab {
  display: flex;
  align-items: center;
  margin: 0 12px;
  text-decoration: none;
  color: black;
  white-space: nowrap;
}

.slider {
  position: absolute;
  z-index: 1;
  bottom: -1px;
  height: 2px;
  background-color: $color-main;
  transition: left 0.2s;
}
.raiting-actions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: breakpoint(md)) {
  .raiting-actions {
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  .m-mobile_v {
    margin-top: 12px;
  }
}

@media screen and (max-width: breakpoint(sm)) {
  .rating {
    padding: 0 12px;

    &__info {
      flex-direction: column;
      align-items: end;
      padding: 0 12px;
      overflow: hidden;
      &-title {
        margin: 8px 0;
      }
      &-block {
        margin-bottom: 12px;
      }
    }
    &__items {
      margin: 16px -16px 0;
    }
    &__tabs {
      margin: 0 -16px;
      padding: 0 4px !important;
      overflow-x: auto;
      max-width: calc(100% + 32px) !important;
      min-width: calc(100% + 32px) !important;
    }
  }

  .slider {
    padding: 0 4px;
    overflow-x: auto;
    bottom: 0;
  }
}
</style>
