<template>
  <div class="table">
    <v-alert
      v-model="showMessageTimer"
      border="left"
      tile
      class="title"
      close-text="Close Alert"
      color="blue"
      dismissible
      light
    >
      Время на верификацию истекло. Карточка автоматически закрыта
    </v-alert>
    <div
      v-scroll="bottomScroll"
      class="table_content"
      :class="{ table_pagination: isShowPagination }"
    >
      <table v-if="values.length">
        <slot class="table_header" name="header" />
        <tr v-if="showColumns" class="table_columns">
          <th
            v-for="(column, i) in columns"
            :key="`${i}_table_columns`"
            class="table_column"
            :class="[
              `width${column.width || '-auto'}`,
              { alignLeft: column.alignLeft, alignRight: column.alignRight }
            ]"
          >
            {{ column.title || column.name }}
          </th>
        </tr>
        <tbody class="table_body">
          <template v-for="(row, index) in values">
            <tr v-if="row.header" :key="`${index}_table_body`" @click="clickItem(row)">
              <td :colspan="columns.length">
                <slot name="header" :row="row" />
              </td>
            </tr>
            <tr :key="`${index}_table_row`" class="table_row" @click="clickItem(row)">
              <td
                v-for="(column, i) in columns"
                :key="`${i}_table_row_column`"
                class="table_column"
                :class="[`width${column.width || '-auto'}`]"
              >
                <div
                  class="table_value"
                  :class="{
                    alignLeft: column.alignLeft,
                    alignRight: column.alignRight,
                    nowrap: column.nowrap
                  }"
                >
                  <div v-if="!column.slot">
                    {{ findValue(row, column) }}
                  </div>
                  <slot v-else :name="column.name" :row="row" />
                </div>
              </td>
            </tr>
            <tr v-if="row.footer" :key="index" @click="clickItem(row)">
              <td :colspan="columns.length">
                <slot name="footer" :row="row" />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-if="!loading && !values.length" class="table_empty">
        <slot name="empty" />
      </div>
      <div v-if="loading" class="table_loading">
        <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
      </div>
      <slot class="table_footer" name="footer" />
    </div>
    <div v-if="isShowPagination" class="table_pagination">
      <v-pagination
        :length="pages"
        :total-visible="pagination.visible || 20"
        :value="pagination.page"
        @input="changePage"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pagination: {
      type: [Object, Boolean],
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    showColumns: {
      type: Boolean,
      default: true
    },
    values: {
      type: Array,
      default() {
        return [];
      }
    },
    columns: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      page: 1,
      show: false,
      showMessageTimer: false,
      currentScrollPosition: 0
    };
  },
  computed: {
    isShowPagination() {
      return !!this.pagination && this.pagination.type === 'pages' && this.length > 1;
    },
    pages() {
      return Math.ceil((this.pagination.total || 1) / (this.pagination.limit || 15));
    }
  },
  watch: {
    '$route.query': {
      handler() {
        this.showMessageTimer = !!this.$route.query.showMessageTimer;
      }
    }
  },
  methods: {
    findValue(row, column) {
      if (!row[column.name] && column.name === 'name') return row.name_by_bank;
      return row[column.name] || column.default;
    },
    bottomScroll(e, el) {
      if (this.currentScrollPosition === el.scrollTop) return;
      if (el.scrollTop === el.scrollHeight - el.clientHeight) {
        this.currentScrollPosition = el.scrollTop;
        this.page++;
        this.$emit('scrolled-bottom', this.page);
      }
    },
    changePage(page) {
      this.pagination.page = page;
      this.$emit('changePage', page);
    },
    clickItem(item) {
      this.$emit('clickItem', item);
    }
  }
};
</script>
<style lang="scss">
@import '~@/assets/scss/main';
@import '~@/assets/scss/variables';

.table {
  font-size: 14px;
  height: 100%;
  .table_content {
    height: 100%;
    &.table_pagination {
      height: calc(100% - 60px);
    }
    overflow: auto;
    table {
      min-width: 100%;
    }
  }
  .table_header {
    border-bottom: 1px solid rgba(204, 204, 204, 1);
  }
  .table_empty {
    text-align: center;
    padding: 30px;
    line-height: 16px;
    font-size: 20px;
    color: $gray-light;
  }
  .table_columns {
    border-radius: 4px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    font-size: 14px;
    text-align: center;
    border-top: 1px solid #cccccc;
    .table_column {
      padding: 10px 2px 10px 2px;
    }
  }
  .table_row {
    min-height: 12px;
    border-top: 1px solid rgba(204, 204, 204, 1);
    &:hover {
      background: lightgray;
      cursor: pointer;
    }
    .table_value {
      height: 100%;
      min-width: 40px;
      line-height: 26px;
      text-align: center;
      padding: 4px;
      &.nowrap {
        white-space: nowrap;
      }
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .table_loading {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    background: transparent;
  }
  .table_row,
  .table_columns {
    display: flex;
    padding: 0;
    .alignLeft {
      text-align: left;
    }
    .alignRight {
      text-align: right;
    }
    .table_column {
      flex: 1;
      line-height: 16px;
      vertical-align: middle;
      &:nth-of-type(odd) {
        background: rgba(250, 250, 250, 0.1);
      }

      &.width5 {
        flex: 0 0 5%;
        max-width: 5%;
      }
      &.width10 {
        flex: 0 0 10%;
        max-width: 10%;
      }
      &.width15 {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &.width20 {
        flex: 0 0 20%;
        max-width: 20%;
      }
      &.width25 {
        flex: 0 0 25%;
        max-width: 25%;
      }
      &.width30 {
        flex: 0 0 30%;
        max-width: 30%;
      }
      &.width35 {
        flex: 0 0 35%;
        max-width: 35%;
      }
      &.width40 {
        flex: 0 0 40%;
        max-width: 40%;
      }
      &.width45 {
        flex: 0 0 45%;
        max-width: 45%;
      }
      &.width50 {
        flex: 0 0 50%;
        max-width: 50%;
      }
      &.width55 {
        flex: 0 0 55%;
        max-width: 55%;
      }
      &.width60 {
        flex: 0 0 60%;
        max-width: 60%;
      }
      &.width65 {
        flex: 0 0 65%;
        max-width: 65%;
      }
      &.width70 {
        flex: 0 0 70%;
        max-width: 70%;
      }
      &.width75 {
        flex: 0 0 75%;
        max-width: 75%;
      }
      &.width80 {
        flex: 0 0 80%;
        max-width: 80%;
      }
      &.width85 {
        flex: 0 0 85%;
        max-width: 85%;
      }
      &.width90 {
        flex: 0 0 90%;
        max-width: 90%;
      }
      &.width95 {
        flex: 0 0 95%;
        max-width: 95%;
      }
    }
  }
}
</style>
