<template>
  <button
    :type="type"
    v-bind="$attrs"
    :class="{ disabled: loading }"
    class="button"
    v-on="$listeners"
  >
    <PlainCircular v-if="loading" class="spinner" />
    <span :class="{ hidden: loading }">
      <slot />
    </span>
  </button>
</template>

<script>
import PlainCircular from './PlainCircular';

export default {
  name: 'PlainButton',
  components: {
    PlainCircular
  },
  props: {
    type: {
      type: String,
      default: 'button'
    },
    loading: Boolean
  }
};
</script>

<style lang="scss" scoped>
.hidden {
  opacity: 0;
}
.spinner {
  position: absolute !important;
}
.button {
  display: inline-flex;
  outline: 0;
  user-select: none;
  position: relative;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  text-decoration: none;
  justify-content: center;
  font-size: 14px;
  align-items: center;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;

  & .icon {
    display: inline-block;
  }
  &:not([outline]):not(.outline) {
    border: 0;
  }

  &[large] {
    min-width: 168px;
    padding: 0 32px;
    height: 44px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25);
    &[color='primary'] {
      background-color: #009ce9;
      color: #fff;
      &:hover {
        background-color: #027fbd;
      }
      &:active {
        background-color: #009ce9;
      }
      &[disabled],
      &.disabled {
        pointer-events: none;
        color: #7fcdf4;
      }
    }
  }
  &[round] {
    border-radius: 28px;
  }
  &[flat] {
    line-height: 1;
    height: auto;
    &[color='primary'] {
      color: #009ce9;
      &:hover {
        color: #027fbd;
      }
      &:active {
        color: #009ce9;
      }
      &[disabled],
      &.disabled {
        pointer-events: none;
        color: #7fcdf4;
      }
    }
  }
  &[outline] {
    box-shadow: none;
    border: 1px solid #009ce9;
    color: #009ce9;

    &:hover {
      color: #fff;
      background-color: #027fbd;
      border-color: #027fbd;
    }
    &[disabled],
    &.disabled {
      color: #7fcdf4;
      border-color: #7fcdf4;
      pointer-events: none;
    }
  }

  &.primary {
    color: #009ce9 !important;
    border-color: #009ce9;

    &:hover {
      color: #fff !important;
    }
  }
}
</style>
