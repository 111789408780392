<template>
  <CarcassesFormAbstraction
    ref="loadUnloadMethodsForm"
    :is-edit-form="isEditForm"
    :is-add-form="isAddForm"
    @close="$emit('close')"
    @remove="removeLoadUnloadMethod"
    @submit="addLoadUnloadMethod"
  >
    <v-flex xs12>
      <custom-input
        v-model="form.name"
        label="Название способа погрузки/разгрузки"
        :rules="[v => !!v || 'Поле обязательно для заполнения']"
        required
      />
    </v-flex>
    <v-flex xs12>
      <custom-input v-model="form.tooltipCarrier" label="Подсказка ГП" />
    </v-flex>
    <v-flex xs12>
      <custom-input v-model="form.tooltipSender" label="Подсказка ГВ" />
    </v-flex>
  </CarcassesFormAbstraction>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CarcassesFormAbstraction from './CarcassesFormAbstraction';

const createForm = (info = {}) => ({
  name: info?.name ?? '',
  // eslint-disable-next-line camelcase
  tooltipCarrier: info?.comment_carrier ?? '',
  // eslint-disable-next-line camelcase
  tooltipSender: info?.comment_sender ?? '',
  default: info?.default ?? false,
  id: info?.id ?? ''
});

export default {
  name: 'LoadUnloadMethodsForm',
  components: {
    CarcassesFormAbstraction
  },
  props: {
    isEditForm: Boolean,
    isAddForm: Boolean,
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: createForm()
    };
  },
  computed: {
    ...mapState('carcasses', ['currentVersionId'])
  },
  watch: {
    info: {
      deep: true,
      immediate: true,
      handler(value) {
        this.form = createForm(value);
      }
    }
  },
  methods: {
    ...mapActions('common', ['createController']),
    async addLoadUnloadMethod() {
      if (!this.$refs.loadUnloadMethodsForm.validate()) return;
      const topic = 'admin/administration/carcasses/loading_unloading_methods/update';
      try {
        const json = {
          id: this.currentVersionId,
          record: {
            name: this.form.name,
            comment_carrier: this.form.tooltipCarrier,
            comment_sender: this.form.tooltipSender,
            default: this.form.default
          }
        };
        if (this.form.id.length) json.gid = this.form.id;
        await this.createController({ topic, json });
        this.resetForm();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async removeLoadUnloadMethod() {
      const topic = 'admin/administration/carcasses/loading_unloading_methods/remove';
      try {
        const json = {
          id: this.currentVersionId,
          gid: this.form.id
        };
        await this.createController({ topic, json });
        this.resetForm();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    resetForm() {
      this.$refs.loadUnloadMethodsForm.reset();
      this.$emit('close');
      this.$emit('update');
    }
  }
};
</script>
