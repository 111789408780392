var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showForm || _vm.isEdit)?_c('v-layout',{staticClass:"form",attrs:{"row":"","wrap":"","tag":"v-form"},on:{"submit":function($event){$event.preventDefault();return _vm.$emit('submit')}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-flex',{attrs:{"xs4":""}},[_c('custom-input',{attrs:{"rules":[function (v) { return !!v || 'Поле обязательно для заполнения'; }],"label":"Фамилия","value":_vm.lastname},on:{"input":function($event){return _vm.$emit('input', {
          field: 'lastname',
          value: $event
        })}}})],1),_c('v-flex',{attrs:{"xs4":""}},[_c('custom-input',{attrs:{"rules":[function (v) { return !!v || 'Поле обязательно для заполнения'; }],"label":"Имя","value":_vm.firstname},on:{"input":function($event){return _vm.$emit('input', {
          field: 'firstname',
          value: $event
        })}}})],1),_c('v-flex',{attrs:{"xs4":""}},[_c('custom-input',{attrs:{"rules":[function (v) { return !!v || 'Поле обязательно для заполнения'; }],"label":"Отчество","value":_vm.midname},on:{"input":function($event){return _vm.$emit('input', {
          field: 'midname',
          value: $event
        })}}})],1),_c('v-flex',{attrs:{"xs4":""}},[_c('custom-input',{attrs:{"rules":[function (v) { return !!v || 'Поле обязательно для заполнения'; }],"label":"E-mail","suffix":_vm.mailSuffix,"value":_vm.email,"readonly":_vm.isEdit},on:{"input":function($event){return _vm.$emit('input', {
          field: 'email',
          value: $event
        })}}})],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-select',{attrs:{"rules":[function (v) { return !!v || 'Поле обязательно для заполнения'; }],"label":"Группа","placeholder":" ","items":_vm.groupsList,"item-text":"name","item-value":"name","return-object":"","disabled":_vm.group === 'root',"value":_vm.group},on:{"change":function($event){return _vm.$emit('input', {
          field: 'group',
          value: $event
        })}}})],1),_c('v-flex',{attrs:{"xs12":""}},[(!_vm.isEdit)?_c('custom-button',{staticClass:"ml-0",attrs:{"large":"","disabled":!_vm.formValid,"color":"primary"},on:{"click":function($event){return _vm.$emit('submit', 'add')}}},[_vm._v(" Добавить ")]):_vm._e(),(_vm.isEdit)?_c('custom-button',{staticClass:"ml-0",attrs:{"disabled":!_vm.formValid || _vm.disabledButton,"large":"","color":"primary"},on:{"click":function($event){return _vm.$emit('submit', 'update')}}},[_vm._v(" Сохранить ")]):_vm._e(),(_vm.isEdit)?_c('custom-button',{staticClass:"ml-0",attrs:{"disabled":!_vm.formValid || _vm.disabledButton,"large":"","color":"primary"},on:{"click":function($event){return _vm.$emit('submit', 'password')}}},[_vm._v(" Новый пароль ")]):_vm._e(),(_vm.isEdit)?_c('custom-button',{staticClass:"ml-0",attrs:{"large":"","color":"primary","disabled":_vm.group === 'root' || !_vm.isSuper},on:{"click":function($event){return _vm.$emit('submit', 'delete')}}},[_vm._v(" Удалить ")]):_vm._e(),_c('custom-button',{staticClass:"ml-0",attrs:{"large":"","outline":"","color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Отмена ")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }