<template>
  <div>
    <h2 :class="['ui-kit__heading', { 'mt-0': !isShipper }]">
      Документы
    </h2>

    <ItemCard v-if="shipper.yur_type !== 'FL'">
      <v-layout wrap align-center fill-height>
        <v-flex xs12>Договор</v-flex>
      </v-layout>
      <custom-button slot="buttons" flat color="primary" class="my-0" @click="downloadContract">
        <icon name="pdf" size="18" />
      </custom-button>
    </ItemCard>

    <ItemCard v-if="shipper.yur_type !== 'FL'">
      <v-layout wrap align-center fill-height>
        <v-flex xs12>Заявка</v-flex>
      </v-layout>
      <custom-button slot="buttons" flat color="primary" class="my-0" @click="downloadRequest">
        <icon name="pdf" size="18" />
      </custom-button>
    </ItemCard>

    <ItemCard v-if="isShipper">
      <v-layout wrap align-center fill-height>
        <v-flex xs12>Чек-лист</v-flex>
      </v-layout>
      <custom-button slot="buttons" flat color="primary" class="my-0" @click="downloadCheckList">
        <icon name="pdf" size="18" />
      </custom-button>
    </ItemCard>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { contract, request, checkFl, checkUl } from 'common/helpers/pdf';
import lightFormat from 'date-fns/lightFormat';
import { getUTCDate, formattingDate, createDatetimeWithOffset } from 'common/helpers/dateHelper';
import { TYPES, RIGHTUSE } from '@/utils/transportEnums';
import { getCityName } from 'common/utils';
import { DATE_INFINITE } from 'common/helpers/dateEnums';
import { listPaymentEventForDoc, paymentTypeItemsForDoc } from 'common/components/Orders/enums.js';

import ItemCard from 'common/components/Orders/ItemCard';

import { transportApi } from 'common/api/TransportApi';

export default {
  name: 'DocumentsList',
  components: {
    ItemCard
  },
  props: {
    shipper: {
      type: Object,
      required: true,
      validator(value) {
        return !!Object.keys(value).length;
      }
    },
    carrier: {
      type: Object,
      required: true,
      validator(value) {
        return !!Object.keys(value).length;
      }
    }
  },
  data() {
    return {
      vehicle: {}
    };
  },
  computed: {
    ...mapGetters('orders', [
      'isRangeDate',
      'getListProperties',
      'getListCharacteristics',
      'getListCarcasses',
      'getFullRoute'
    ]),
    ...mapState('orders', {
      info: state => state.orderInfo.info,
      trucker: state => state.orderInfo.trucker
    }),
    ...mapGetters('login', ['isShipper']),

    isFl() {
      return this.carrier.yur_type === 'FL';
    }
  },
  methods: {
    ...mapActions('common', ['createController']),

    async getAcceptedTransportInfo() {
      const json = { cid: this.info.ctransport_id };
      try {
        const { type = '', vehicles = {}, vehicle = {} } = await transportApi(
          this.$store.dispatch
        ).transportGet({ json });
        const tsInfo = type === 'autotrain' ? { ...vehicles } : { ...vehicle };
        this.vehicle = {
          ...tsInfo,
          type
        };
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },

    downloadContract() {
      const pdf = contract({
        date: formattingDate(this.info.accept_datetime),
        shipper: this.getDataContract(this.shipper),
        carrier: this.getDataContract(this.carrier)
      });
      pdf.download(`Договор_${this.info.id}.pdf`);
    },

    async downloadRequest() {
      await this.getAcceptedTransportInfo();

      const pdf = request({
        number: this.info.id,
        date: formattingDate(this.info.accept_datetime),
        shipper: this.getDataRequest('shipper'),
        carrier: this.getDataRequest('carrier'),
        firstPoint: this.getPointInfo(this.info.route[0].point),
        nameCargo: this.info.name,
        requirements: this.getRequirements(),
        paymentInfo: this.getInfoPayment(),
        corouting: this.info.corouting ? `да` : `нет`,
        route: this.getFullRouteWithDate(),
        pointsDate: this.getPointsDate(),
        pointsUnloading: this.getPointsUnloading(),
        trucker: {
          ...this.trucker,
          passport_date: formattingDate(this.trucker.passport_date),
          license_date: formattingDate(this.trucker.license_date)
        },
        transport: this.getTransportInfo()
      });
      pdf.download(`Заявка_${this.info.id}.pdf`);
    },

    getFullRouteWithDate() {
      let route = '';
      this.info.route.forEach(({ point }, index) => {
        const getPointDate = ({ datetime, gmtOffset }) => {
          if (!datetime || !gmtOffset) return '';
          return `${lightFormat(
            createDatetimeWithOffset({ date: datetime, offset: gmtOffset }),
            'dd.MM.yyyy в HH:mm'
          )}`;
        };
        route = route + `${index ? '—' : ''} ${getCityName(point)} (${getPointDate(point)}) `;
      });
      return route;
    },

    async downloadCheckList() {
      await this.getAcceptedTransportInfo();
      let pdf;
      const dataPdf = {
        number: this.info.id,
        route: this.getFullRoute,
        nameCargo: this.info.name,
        nameCarrier: this.carrier.name,
        trucker: {
          ...this.trucker,
          passport_date: formattingDate(this.trucker.passport_date),
          license_date: formattingDate(this.trucker.license_date)
        },
        transport: ''
      };
      if (this.vehicle.type === 'autotrain') {
        const head = `${this.getTSType(this.vehicle.head.vehicle.vehicle_type)} ${
          this.vehicle.head.vehicle.brand_and_model
        } регистрационный номер ${this.vehicle.head.vehicle.registration_plate}`;
        const tail = `${this.getTSType(this.vehicle.tail.vehicle.vehicle_type)} ${
          this.vehicle.tail.vehicle.brand_and_model
        } регистрационный номер ${this.vehicle.tail.vehicle.registration_plate}`;
        dataPdf.transport = `${head}, ${tail}`;
      } else
        dataPdf.transport = `${this.getTSType(this.vehicle.vehicle_type)} ${
          this.vehicle.brand_and_model
        } регистрационный номер ${this.vehicle.registration_plate}`;
      if (this.isFl) {
        pdf = checkFl(dataPdf);
      } else {
        pdf = checkUl(dataPdf);
      }

      pdf.download(`Чек-лист_${this.info.id}.pdf`);
    },

    getDataContract(data) {
      const info = {};
      if (data.yur_type === 'FL') {
        info.headerText = data.name;
        info.footerText = [
          { text: `${data.name}\n`, bold: true },
          { text: `дата рождения: ${formattingDate(data.birthday_date)}\n` },
          { text: `Паспорт серия ${data.passport_serie} номер ${data.passport_number}\n` },
          { text: `Дата выдачи: ${formattingDate(data.passport_date)}\n` },
          { text: `ИНН: ${data.inn}\n` },
          { text: `тел.: ${data.phone}` }
        ];
      } else if (data.yur_type === 'IP') {
        info.headerText = `${
          data.name
        }, действующий на основании государственной регистрации от ${formattingDate(
          data.foundation_date
        )}, номер ОГРНИП ${data.ogrn}`;
        info.footerText = [
          { text: `${data.name}\n`, bold: true },
          {
            text: `Почтовый адрес: ${data.post_zip || '________'} ${data.post_address ||
              '___________________________'}\n`
          },
          { text: `ОГРНИП: ${data.ogrn}\n` },
          { text: `ИНН: ${data.inn}\n` },
          {
            // eslint-disable-next-line camelcase
            text: `${data.accounts[0]?.bank}, р\\с ${data.accounts[0]?.number}\n, к\\с ${data.accounts[0]?.korr_number}\n`
          },
          { text: `тел.: ${data.phone}` }
        ];
      } else {
        info.headerText = `${data.name}, в лице генерального директора ${data.ceo ||
          '____________________________'}, действующего на основании Устава`;
        info.footerText = [
          { text: `${data.name}\n`, bold: true },
          {
            text: `Почтовый адрес: ${data.post_zip || '________'} ${data.post_address ||
              '___________________________'}\n`
          },
          {
            text: `Юридический адрес: ${data.legal_zip || '________'} ${data.legal_address ||
              '___________________________'}\n`
          },
          { text: `ОГРН: ${data.ogrn}\n` },
          { text: `ИНН: ${data.inn}\n` },
          { text: `${data.number_bank || '_____________________________________'}\n` },
          { text: `тел.: ${data.phone}` }
        ];
      }
      return { ...info };
    },

    getDataRequest(field) {
      const data = this[field];
      const info = {
        name: data.name,
        fullDescription: `${data.name}, тел. ${data.phone}`
      };
      if (data.yur_type === 'IP') {
        info.name = data.name;
        info.fullDescription = `${data.name}, ${data.legal_address}, ${data.phone}`;
      } else if (data.yur_type === 'UL') {
        const isShipper = data.type === 'shipper';
        const contactLeadName = isShipper ? this.info.contact_name : this.info.contact_carrier_name;
        const contactLeadPhone = isShipper
          ? this.info.contact_phone
          : this.info.contact_carrier_phone;

        info.name = data.name;
        info.fullDescription = `${data.name}, ${data.legal_address}, к/л ${contactLeadName ||
          '____________________________'}, ${contactLeadPhone}`;
      }
      return { ...info };
    },

    getRequirements() {
      return [
        this.getListCarcasses(this.info.carcasses),
        this.getListCharacteristics(this.info.properties),
        this.getListProperties({ field: 'options', properties: this.info.properties }),
        this.getListProperties({ field: 'lifting', properties: this.info.properties })
      ]
        .filter(_ => !!_)
        .join(', ');
    },

    getInfoPayment() {
      const paymentEvent = listPaymentEventForDoc.find(
        item => item.value === this.info.payment_event
      )?.text;
      const paymentType = this.info.payment_type
        .map(item => paymentTypeItemsForDoc.find(el => el.value === item)?.label)
        .join(', ');
      if (this.info.prepaid) {
        const prepaymentType = this.info.prepayment_type
          .map(item => paymentTypeItemsForDoc.find(el => el.value === item)?.label)
          .join(', ');
        return `${this.info.final_cost.toLocaleString(
          'ru'
        )} руб, ${paymentType} ${paymentEvent}. Предоплата ${this.info.prepaid.toLocaleString(
          'ru'
        )} руб, ${prepaymentType}.`;
      }
      return `${this.info.final_cost.toLocaleString('ru')} руб, ${paymentType} ${paymentEvent}.`;
    },

    getPointInfo(point) {
      return `${point.organizationName}, г. ${point.text}, ${point.contacts.name}, ${point.contacts.phone}`;
    },

    getPointsDate() {
      if (this.isRangeDate)
        return [
          [
            'Дата и время подачи под загрузку',
            lightFormat(getUTCDate(this.info.loading_date), 'dd.MM.yyyy, HH:mm')
          ]
        ];
      const pointsDate = [];
      this.info.route.forEach((point, index) => {
        const text = !index
          ? 'Дата и время подачи под загрузку'
          : `Дата и время подачи под выгрузку в ${index} пункте следования`;
        pointsDate.push([
          { text },
          { text: lightFormat(getUTCDate(point.point.datetime), 'dd.MM.yyyy, HH:mm') }
        ]);
      });
      return [...pointsDate];
    },

    getPointsUnloading() {
      let pointsUnloading = '';
      const otherPointsUnloading = [...this.info.route];
      otherPointsUnloading.shift();
      otherPointsUnloading.forEach((point, index) => {
        pointsUnloading = pointsUnloading + this.getPointInfo(point.point) + `; `;
      });
      return pointsUnloading;
    },

    getTSType(type) {
      return TYPES[type];
    },

    getTransportInfo() {
      if (this.vehicle.type === 'autotrain') {
        const typeTS = this.getTSType(this.vehicle.head.vehicle.vehicle_type);
        const modelTS = `${this.vehicle.head.vehicle.brand_and_model}, ${lightFormat(
          getUTCDate(this.vehicle.head.vehicle.release_year),
          'yyyy'
        )}`;
        const numberTS = `Гос. номер ${this.vehicle.head.vehicle.registration_plate}`;
        const trailerType = this.getTSType(this.vehicle.tail.vehicle.vehicle_type);
        const modelTrailer = `${this.vehicle.tail.vehicle.brand_and_model}, ${lightFormat(
          getUTCDate(this.vehicle.tail.vehicle.release_year),
          'yyyy'
        )}`;
        const numberTrailer = `Гос. номер ${this.vehicle.tail.vehicle.registration_plate}`;

        return {
          name: `${typeTS} ${modelTS}, ${numberTS}. ${trailerType} ${modelTrailer}, ${numberTrailer}`,
          info: `${typeTS} - ${this.getTSRight(this.vehicle.head.vehicle)}, ${this.getTSType(
            this.vehicle.tail.vehicle.vehicle_type
          )} - ${this.getTSRight(this.vehicle.tail.vehicle)}`
        };
      }

      const typeTS = this.getTSType(this.vehicle.vehicle_type);
      const modelTS = `${this.vehicle.brand_and_model}, ${lightFormat(
        getUTCDate(this.vehicle.release_year),
        'yyyy'
      )}`;
      const numberTS = `Гос. номер ${this.vehicle.registration_plate}`;

      return {
        name: `${typeTS} ${modelTS}, ${numberTS}`,
        info: `${typeTS} - ${this.getTSRight(this.vehicle)}`
      };
    },

    getTSRight(item) {
      const type = RIGHTUSE.find(el => el.value === item.type_right_use)?.text;
      const date =
        item.valid_until !== DATE_INFINITE
          ? `до ${lightFormat(getUTCDate(item.valid_until), 'dd.MM.yyyy')}`
          : '';
      return `${type} ${date}`;
    }
  }
};
</script>
