<template>
  <div v-if="!loading(routeBalance) && !loading(routeBalanceCard)" class="cash_move">
    <div class="cash_move__column width_70">
      <div v-if="info.yur_type !== 'FL'" class="column__title">
        Внутренний счет
      </div>
      <div v-if="info.yur_type !== 'FL'" class="column__row">
        <div class="column__row__field">
          <span>Баланс:</span>
          {{ Number(balance_payments.total || 0).toFixed(2) }} руб.
        </div>
        <div class="column__row__field">
          <span>Незавершенные операции:</span>
          {{ Number(balance_payments.blocked || 0).toFixed(2) }} руб.
        </div>
        <div class="column__row__field">
          <span>Доступно:</span>
          {{ Number(balance_payments.available || 0).toFixed(2) }} руб.
        </div>
      </div>
      <div class="column__title">
        Бонусный счет
      </div>
      <div class="column__row">
        <div class="column__row__field">
          <span>Баланс:</span>
          {{ Number(balance_bonuses.total || 0).toFixed(2) }} руб.
        </div>
        <div class="column__row__field">
          <span>Незавершенные операции:</span>
          {{ Number(balance_bonuses.blocked || 0).toFixed(2) }} руб.
        </div>
        <div class="column__row__field">
          <span>Доступно:</span>
          {{ Number(balance_bonuses.available || 0).toFixed(2) }} руб.
        </div>
      </div>
    </div>
    <div class="cash_move__column width_30">
      <div class="column__title">
        Банковские карты
      </div>
      <div class="column__row">
        <div class="column__row__field">
          <span>Незавершенные операции:</span>
          {{ Number(balance_card).toFixed(2) }} руб.
        </div>
      </div>
    </div>
    <div class="cash_move__column width_100">
      <div class="column__title">
        Операции
      </div>
      <div class="column__row">
        <div class="column__row__field">
          <v-text-field
            ref="from"
            label="Период c"
            :value="input.from"
            :readonly="true"
            append-icon="date_range"
            @focus="datePickerToggle('from')"
          />
          <div class="field__date_picker">
            <v-date-picker
              v-if="openPicker.from"
              v-model="picker.from"
              :landscape="true"
              :reactive="true"
            />
          </div>
        </div>
        <div class="column__row__field">
          <v-text-field
            ref="to"
            label="По"
            :value="input.to"
            :readonly="true"
            append-icon="date_range"
            @focus="datePickerToggle('to')"
          />
          <div class="field__date_picker">
            <v-date-picker
              v-if="openPicker.to"
              v-model="picker.to"
              :landscape="true"
              :reactive="true"
            />
          </div>
        </div>
        <div class="column__row__field">
          <div class="payment_options">
            <div class="payment_options__title">
              Средства платежа
            </div>
            <select v-model="payment" class="payment_options__select" @change="getData">
              <option v-for="(option, index) in paymentOptions" :key="index" :value="option.value">
                {{ option.title }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="column__row">
        <div class="column__row__field">
          <v-radio-group v-model="balanceShowType" :column="false">
            <v-radio
              v-for="(type, key) in balanceShowTypes"
              :key="key"
              :label="type.title"
              :value="type.value"
            />
          </v-radio-group>
        </div>
      </div>
      <div v-if="balanceShowType !== 'all' && list.balance" class="column__row width_70">
        <div v-if="list.balance[balanceShowType].total" class="column__row__field">
          <span>Баланс:</span>
          {{ Number(list.balance[balanceShowType].total).toFixed(2) }} руб.
        </div>
        <div class="column__row__field">
          <span>Незавершенные операции:</span>
          {{ Number(list.balance[balanceShowType].blocked).toFixed(2) }} руб.
        </div>
        <div v-if="list.balance[balanceShowType].available" class="column__row__field">
          <span>Доступно:</span>
          {{ Number(list.balance[balanceShowType].available).toFixed(2) }} руб.
        </div>
      </div>
      <div v-else-if="list.turnovers" class="column__row width_70">
        <div class="column__row__field">
          <span>Приход:</span>
          {{ Math.abs(list.turnovers.in).toFixed(2) }} руб.
        </div>
        <div class="column__row__field">
          <span>Расход:</span>
          {{ Math.abs(list.turnovers.out).toFixed(2) }} руб.
        </div>
        <div class="column__row__field" />
      </div>
      <div class="column__row">
        <div class="column__row__table">
          <TableList
            :columns="columns"
            :values="list.operations"
            :loading="loading(routeOperations)"
          >
            <template slot="id" slot-scope="{ row }">
              {{ '#' + row.id }}
            </template>
            <template slot="operation" slot-scope="{ row }">
              <div class="operation">
                <div class="operation__title">
                  {{ row.comment }}
                </div>
                <div
                  v-for="(item, index) in row.movements"
                  :key="index"
                  class="operation__action"
                  :class="{ faded: item.hidden }"
                >
                  <div class="action__date">
                    {{ item.date }}
                  </div>
                  <div class="action__text">
                    {{ item.comment }}
                  </div>
                </div>
              </div>
            </template>
            <template slot="status" slot-scope="{ row }">
              <div class="icon">
                <v-icon :style="{ color: iconName[row.status].color }">
                  {{ iconName[row.status].icon }}
                </v-icon>
              </div>
            </template>
          </TableList>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="loading">
    <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import TableList from '../../Layout/TableList.vue';

// TODO банковске карты не приходят. backend
export default {
  components: {
    TableList
  },
  data() {
    return {
      balanceShowType: 'from',
      balanceShowTypes: [
        {
          title: 'На начало периода',
          value: 'from'
        },
        {
          title: 'На конец периода',
          value: 'to'
        },
        {
          title: 'За период',
          value: 'all'
        }
      ],
      paymentOptions: [
        {
          title: 'Банковские карты',
          value: 'card'
        },
        {
          title: 'Бонусный счет',
          value: 'bonus'
        }
      ],
      payment: 'card',
      picker: {
        from: moment()
          .startOf('month')
          .format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      },
      input: {
        from: moment()
          .startOf('month')
          .format('DD.MM.YYYY'),
        to: moment().format('DD.MM.YYYY')
      },
      openPicker: {
        from: false,
        to: false
      },
      columns: [
        {
          title: 'ID',
          name: 'id',
          width: '10',
          alignLeft: true,
          slot: true
        },
        {
          title: 'Операция',
          name: 'operation',
          width: '60',
          alignLeft: true,
          slot: true
        },
        //          {title: ' ', name: 'type', width: '20', alignLeft: true},
        {
          title: 'Приход',
          name: 'coming',
          width: '10',
          alignLeft: true
        },
        {
          title: 'Расход',
          name: 'cost',
          width: '10',
          alignLeft: true
        },
        {
          title: 'Статус',
          name: 'status',
          width: '10',
          alignLeft: true,
          slot: true
        }
      ],
      iconName: {
        active: {
          icon: 'check_circle_outline',
          color: 'green'
        },
        ok: {
          icon: 'check_circle_outline',
          color: 'green'
        },
        error: {
          icon: 'error_outline',
          color: 'red'
        }
      }
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('clients', ['info']),
    ...mapState('clientPayments', [
      'list',
      'balance_payments',
      'balance_bonuses',
      'balance_card',
      'routeBalanceCard',
      'routeBalance',
      'routeOperations'
    ]),
    enduser_id() {
      return this.$route.params.enduser * 1;
    }
  },
  watch: {
    'picker.from': {
      handler(val) {
        this.updateInput(val, 'from');
      }
    },
    'picker.to': {
      handler(val) {
        this.updateInput(val, 'to');
      }
    }
  },
  mounted() {
    if (this.info.yur_type !== 'FL') {
      this.paymentOptions.unshift({
        title: 'Внутренний счет',
        value: 'main'
      });
    }
    this.getData();
    this.getBalance({
      enduser: this.enduser_id,
      type: 'payments'
    });
    this.getBalance({
      enduser: this.enduser_id,
      type: 'bonuses'
    });
    this.getBalanceCard(this.enduser_id);
  },
  methods: {
    ...mapActions('clientPayments', ['getList', 'getBalance', 'getBalanceCard']),
    updateInput(val, type) {
      this.input[type] = moment(val).format('DD.MM.YYYY');
      if (this.input.from && this.input.to) {
        this.getData();
        return;
      }
      this.openPicker[type] = false;
    },
    datePickerToggle(type) {
      if (type === 'to') {
        this.openPicker.from = false;
      } else {
        this.openPicker.to = false;
      }
      this.openPicker[type] = !this.openPicker[type];
    },
    getData() {
      this.openPicker = {
        from: false,
        to: false
      };
      this.getList({
        enduser: this.enduser_id,
        from: this.picker.from,
        to: this.picker.to,
        account: this.payment
      });
    }
  }
};
</script>

<style lang="scss">
.cash_move {
  overflow: auto;
  height: 100%;
  padding: 0 30px;

  .cash_move__column {
    display: inline-block;
    vertical-align: top;

    .column__title {
      font-size: 25px;
      padding: 20px 0;
    }
    .column__row {
      display: flex;

      .column__row__field {
        position: relative;
        width: 100%;
        margin: 10px 10px 10px 0;

        span {
          font-weight: bold;
        }

        .field__date_picker {
          position: absolute;
          z-index: 1;
          top: 50px;
        }

        .v-radio {
          .v-label.theme--light {
            color: black;
          }
        }
      }

      .column__row__table {
        margin-top: 20px;
        width: 100%;
        height: 100%;

        .operation {
          .operation__action {
            display: flex;
            padding: 20px 0;

            .action__date {
              width: 25%;
              margin-right: 20px;
              border-bottom: solid 1px gray;
            }
            .action__text {
              width: 75%;
              border-bottom: solid 1px gray;
            }

            &.faded {
              color: #b9b9b9;
            }
          }
        }
      }
    }
  }
}

.payment_options {
  width: 60%;
  display: flex;
  flex-direction: column;
  .payment_options__title {
    color: #969696;
    font-size: 13px;
  }
  .payment_options__select {
    border: 0.5px solid #969696;
    width: 100%;
    padding: 5px 35px 5px 5px;
    border-radius: 5px;
    outline: none;
  }
}
</style>
