<template>
  <table class="table">
    <thead>
      <th>ФИО</th>
      <th>Группа</th>
      <th>E-mail</th>
      <th>Дата последнего входа</th>
    </thead>
    <tbody>
      <template v-for="(item, i) in usersList">
        <tr :key="i" @click="$emit('get-user', item)">
          <td>{{ parsedName(item) }}</td>
          <td>{{ item.group }}</td>
          <td>{{ item.email }}</td>
          <td v-if="item.phase === 'active'">
            {{ parsedLastVisitDate(item.lastlogin) }}
          </td>
          <td v-else>
            <custom-button
              grey
              heavy
              class="ml-0"
              :disabled="!isSuper"
              @click.stop="$emit('delete-user', { method: 'delete', email: item.email })"
            >
              Удалить запрос
            </custom-button>
          </td>
        </tr>
        <tr v-if="item.email === selectedEmail" :key="i">
          <td colspan="4"><slot /></td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import moment from 'common/helpers/date';
import { mapState } from 'vuex';

export default {
  name: 'UsersTable',
  props: {
    selectedEmail: String
  },
  computed: {
    ...mapState('users', ['usersList']),
    ...mapState('login', ['isSuper'])
  },
  methods: {
    parsedName(item) {
      return `${item.lastname} ${item.firstname[0]}. ${item.midname[0]}.`;
    },
    parsedLastVisitDate(date) {
      return moment(date).format('DD.MM.YYYY, H:mm');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';
@include table(25%, $hover: true);
</style>
