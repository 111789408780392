<template>
  <div>
    <v-flex xs12>
      <h2>
        <b>Качество фотографий</b>
      </h2>
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        :value="isCorrectAvatar"
        :false-value="false"
        :true-value="true"
        :rules="[v => !!v || 'Поле обязательно для ввода']"
        @change="
          $emit('changed', {
            field: 'isCorrectAvatar',
            value: $event
          })
        "
      >
        <span slot="prepend">
          Аватар
        </span>
      </v-checkbox>
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        :value="isCorrectTurnPassportPhoto"
        :false-value="false"
        :true-value="true"
        :rules="[v => !!v || 'Поле обязательно для ввода']"
        @change="
          $emit('changed', {
            field: 'isCorrectTurnPassportPhoto',
            value: $event
          })
        "
      >
        <span slot="prepend">
          Разворот паспорта с фотографией
        </span>
      </v-checkbox>
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        :value="isCorrectTurnPassportRegistrationPhoto"
        :false-value="false"
        :true-value="true"
        :rules="[v => !!v || 'Поле обязательно для ввода']"
        @change="
          $emit('changed', {
            field: 'isCorrectTurnPassportRegistrationPhoto',
            value: $event
          })
        "
      >
        <span slot="prepend">
          Разворот паспорта с регистрацией
        </span>
      </v-checkbox>
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        :value="isCorrectDriverLicensePhoto"
        :false-value="false"
        :true-value="true"
        :rules="[v => !!v || 'Поле обязательно для ввода']"
        @change="
          $emit('changed', {
            field: 'isCorrectDriverLicensePhoto',
            value: $event
          })
        "
      >
        <slot slot="prepend">
          Водительское удостоверение с фотографией
        </slot>
      </v-checkbox>
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        :value="isCorrectTurnDriverLicensePhoto"
        :false-value="false"
        :true-value="true"
        :rules="[v => !!v || 'Поле обязательно для ввода']"
        @change="
          $emit('changed', {
            field: 'isCorrectTurnDriverLicensePhoto',
            value: $event
          })
        "
      >
        <span slot="prepend">
          Обратная сторона водительского удостоверения
        </span>
      </v-checkbox>
    </v-flex>
  </div>
</template>

<script>
export default {
  name: 'PhotosQuality',
  props: {
    isCorrectAvatar: Boolean,
    isCorrectTurnPassportPhoto: Boolean,
    isCorrectTurnPassportRegistrationPhoto: Boolean,
    isCorrectDriverLicensePhoto: Boolean,
    isCorrectTurnDriverLicensePhoto: Boolean
  }
};
</script>
