import { setField } from 'common/store/helpers';

export default {
  namespaced: true,
  state: {
    carcasses: [],
    characteristics: [],
    lifting: [],
    options: [],
    optionsCharacteristics: [],
    typeCarcasses: [],
    currentVersionId: '',
    versions: []
  },
  getters: {
    getCurrentVersion(state) {
      return state.versions.find(_ => _.id === state.currentVersionId)?.version ?? '';
    },
    getCurrentType(state) {
      return state.versions.find(_ => _.id === state.currentVersionId)?.type ?? '';
    }
  },
  actions: {
    async getScheme({ commit, dispatch, state }, value) {
      const id = value || state.currentVersionId;
      const topic = 'adm_carcasses/get_scheme';
      try {
        const { json } = await dispatch(
          'common/createController',
          { topic, json: { id } },
          { root: true }
        );
        const { settings } = JSON.parse(json);
        if (value) commit('setField', { field: 'currentVersionId', value });
        commit('setSettings', settings);
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async getSchemes({ commit, dispatch }) {
      const topic = 'adm_carcasses/get_version';
      try {
        const { versions } = await dispatch('common/createController', { topic }, { root: true });
        commit('setField', { field: 'versions', value: versions });
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async publishScheme({ dispatch, state }) {
      const topic = 'adm_carcasses/publish';
      const json = { id: Number(state.currentVersionId) };
      try {
        await dispatch('common/createController', { topic, json });
        await dispatch('getSchemes');
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async deleteScheme({ dispatch, state }) {
      const topic = 'adm_carcasses/delete';
      const json = { id: Number(state.currentVersionId) };
      try {
        await dispatch('common/createController', { topic, json }, { root: true });
        await dispatch('getSchemes');
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    }
  },
  mutations: {
    setSettings(state, settings = {}) {
      state.carcasses = settings?.carcasses ?? [];
      state.characteristics = settings?.characteristics ?? [];
      state.lifting = settings?.lifting ?? [];
      state.options = settings?.options ?? [];
      state.optionsCharacteristics = settings?.options_chars ?? [];
      state.typeCarcasses = settings?.type_carcasses ?? [];

      if (!Object.keys(settings).length) state.currentVersionId = '';
    },
    setField
  }
};
