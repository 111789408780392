import { render, staticRenderFns } from "./Disputes.vue?vue&type=template&id=281d1e23&scoped=true&"
import script from "./Disputes.vue?vue&type=script&lang=js&"
export * from "./Disputes.vue?vue&type=script&lang=js&"
import style0 from "./Disputes.vue?vue&type=style&index=0&id=281d1e23&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../cargo-newadmin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "281d1e23",
  null
  
)

export default component.exports