<template>
  <ContentBlock :subtitle="routeDescription" subtitle-tag="h2">
    <ul class="routes">
      <li v-for="(route, index) in routes" :key="index">
        <b>
          {{ getShortDiscription(route.point, index) }}
        </b>
        <div class="routes__description">
          <span>
            <b>{{ organizationName(route.point) }}</b>
            {{ getFullDescription(route.point) }}.
          </span>
          <span v-if="route.point.lift.length">
            <b>Cпособы погрузки/разгрузки, осуществимые в этом пункте:</b>
            {{ route.point.liftNames }}.
          </span>
          <span v-if="showContacts">{{ contactsData(route.point.contacts) }}</span>
        </div>
      </li>
    </ul>
    <custom-button flat class="button-map" @click="showMap = true">
      Посмотреть маршрут на карте
      <icon name="map" size="18" class="map-icon" />
    </custom-button>
    <geocoder-map
      :show-map="showMap"
      :coordinates="firstAddress.coordinates"
      :address-text="firstAddress.text"
      :route-points="routePoints"
      read-only
      @close="showMap = false"
    />
  </ContentBlock>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getUTCDate, createDatetimeWithOffset } from 'common/helpers/dateHelper';
import lightFormat from 'date-fns/lightFormat';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { plural } from '@/components/helpers/index.js';
import { getCityAndProvince, getFullAddress } from 'common/utils';
import ContentBlock from 'common/components/Orders/ContentBlock.vue';
import GeocoderMap from 'common/components/Orders/GeocoderMap';

export default {
  name: 'RouteData',
  components: {
    ContentBlock,
    GeocoderMap
  },
  data: () => ({
    showMap: false
  }),
  computed: {
    ...mapGetters('login', ['isShipper']),
    ...mapState('orders', {
      info: state => state.orderInfo.info,
      routes: state => state.orderInfo.info.route
    }),
    ...mapGetters('orders', ['getFullRoute', 'routePoints', 'firstAddress', 'isRangeDate']),

    isAcceptedOffer() {
      return [
        'accepted',
        'in_process',
        'on_dispute',
        'done',
        'canceled',
        'dispute_is_done'
      ].includes(this.info.status);
    },

    showContacts() {
      return this.isShipper || this.info.status !== 'waiting_for_offer';
    },

    routeDescription() {
      if (!this.info.route) {
        return;
      }

      const [firstPoint] = this.info.route;
      const lastPoint = [...this.info.route].pop();

      const firstPointDatetime = createDatetimeWithOffset({
        date: firstPoint.point.datetime,
        offset: firstPoint.point.gmtOffset
      });

      const lastPointDatetime = createDatetimeWithOffset({
        date: lastPoint.point.datetime,
        offset: lastPoint.point.gmtOffset
      });

      let travelTime = '';
      const allDistance = this.routes.reduce((acc, currentValue) => {
        const { distance = 0 } = currentValue.point;
        return acc + distance;
      }, 0);

      if (!this.isRangeDate) {
        travelTime = ', время в пути \u2012';
        const differenceDays = differenceInDays(
          getUTCDate(lastPointDatetime),
          getUTCDate(firstPointDatetime)
        );
        const differenceHours =
          differenceInHours(getUTCDate(lastPointDatetime), getUTCDate(firstPointDatetime)) % 24;
        const differenceMinutes =
          differenceInMinutes(getUTCDate(lastPointDatetime), getUTCDate(firstPointDatetime)) % 60;
        travelTime += differenceDays
          ? `\u00A0${plural(differenceDays, 'день', 'дня', 'дней')}`
          : '';
        travelTime += differenceHours
          ? `\u00A0${plural(differenceHours, 'час', 'часа', 'часов')}`
          : '';
        travelTime += differenceMinutes
          ? `\u00A0${plural(differenceMinutes, 'минута', 'минуты', 'минут')}`
          : '';
      }

      return `Маршрут: ${this.getFullRoute}, ${allDistance}км${travelTime}`;
    }
  },
  methods: {
    organizationName(point) {
      if (this.isAcceptedOffer || this.isShipper) {
        return `${point.organizationName}.`;
      }

      return 'Полный адрес:';
    },

    getFullDescription(point) {
      return getFullAddress(point);
    },

    contactsData({ name, phone }) {
      return [name, phone].filter(_ => _).join(', ');
    },

    getShortDiscription(point, indexPoint) {
      const isFirstPoint = indexPoint === 0;

      const getPointDate = ({ datetime, gmtOffset }) => {
        if (!datetime || !gmtOffset) return '';

        const date = createDatetimeWithOffset({ date: datetime, offset: gmtOffset });
        return `, ${indexPoint ? 'прибытие' : 'подача'} ${
          lightFormat(date, 'dd.MM.yyyy в HH:mm')
          // if order was interval
        } ${this.isRangeDate && indexPoint ? '(рассчитано по нормативам)' : ''}`;
      };

      const getRangeDate = () => {
        const [firstPoint] = this.routes;
        const startDate = createDatetimeWithOffset({
          date: this.info.begin_datetime,
          offset: firstPoint.point.gmtOffset
        });
        const endDate = createDatetimeWithOffset({
          date: this.info.end_datetime,
          offset: firstPoint.point.gmtOffset
        });
        return `, подача c ${lightFormat(startDate, 'dd.MM.yy')} по ${lightFormat(
          endDate,
          'dd.MM.yy в HH:mm'
        )}`;
      };

      let shortDescription = `${getCityAndProvince(point)}`;
      if (!this.isAcceptedOffer && this.isRangeDate && isFirstPoint) {
        shortDescription = `${shortDescription}${getRangeDate()}`;
      } else shortDescription = `${shortDescription}${getPointDate(point)}`;
      return shortDescription;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.map-icon {
  margin-left: 10px !important;
}

.button-map {
  margin: 0 0 -8px;
}

.routes {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    position: relative;
    padding-bottom: 16px;
    padding-left: 32px;
    line-height: 24px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      min-width: 24px;
      max-width: 24px;
      height: 100%;
      background: url('~common/assets/img/svg/gradient-circle.svg') 50% 0 no-repeat,
        url('~common/assets/img/svg/ellipse.svg') 50% repeat-y;
      background-size: auto, auto;
    }
  }
  li:last-child {
    padding-bottom: 0;
    &:after {
      background: url('~common/assets/img/svg/gradient-location.svg') no-repeat;
    }
    &:before {
      display: none;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    margin: 6px 0 0 12px;
  }
}
</style>
