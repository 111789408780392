import { render, staticRenderFns } from "./Stub.vue?vue&type=template&id=62e5af6b&functional=true&"
var script = {}


/* normalize component */
import normalizer from "!../../cargo-newadmin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports