<template>
  <section class="chat-section">
    <chat-section-list
      class="chat-section-list"
      :items="chatList"
      :class="{ chat_is_opened: info.chat_id }"
      @select-chat="joinChat"
    />
    <chat-section-dialogue v-if="info.chat_id" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ChatSectionList from './ChatList';
import ChatSectionDialogue from './ChatDialogue';

export default {
  components: {
    ChatSectionList,
    ChatSectionDialogue
  },
  computed: {
    ...mapGetters('support', ['chatList', 'info'])
  },
  mounted() {
    this.initOperator();
  },
  methods: {
    ...mapActions('support', ['initOperator', 'joinChat'])
  }
};
</script>

<style lang="scss">
.chat-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: calc(100vh - 90px);
  .chat-section-list {
    flex: 1;
    overflow: auto;
    padding: 10px;
  }
  .chat-section-dialogue {
    height: 60%;
  }
}
</style>
