<template>
  <span v-html-safe="icon" :style="iconStyle" class="icon" v-on="$listeners" />
</template>

<script>
import icons from 'common/helpers/icons';

export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: [String, Number],
      default: 24
    }
  },
  computed: {
    iconStyle() {
      return {
        'min-width': `${this.size}px`,
        'max-width': `${this.size}px`,
        width: `${this.size}px`,
        height: `${this.size}px`
      };
    },
    icon() {
      return icons[this.name];
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  /deep/ svg {
    width: inherit;
    height: inherit;
    min-width: inherit;
    max-width: inherit;
  }
}
</style>
