<template>
  <div v-if="!isLoading" class="chat-section-dialogue">
    <div class="chat-section-user">
      <v-icon class="chat-section-user__profile" @click="openProfile">
        account_box
      </v-icon>
      <div class="chat-section-user__name">
        {{ info.owner ? info.owner.name : '[Без имени]' }}
      </div>
      <div class="chat-section-user__close">
        <v-btn color="news" icon="icon" dark="dark" @click.native="finishChat">
          <v-icon>close</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="chat-section-dialogue__messages">
      <infinite-loading direction="top" @infinite="infiniteHandler">
        <div slot="no-more" />
        <div slot="no-results" />
        <div slot="spinner" />
      </infinite-loading>
      <chat-item v-for="msg in messages" :key="msg.message_id" :message="msg" type="operators" />
    </div>
    <chat-reply class="chat-section-dialogue__reply" @send-message="sendMessage" />
  </div>
  <div v-else class="loading">
    <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import ChatItem from 'common/components/chat/ChatItem.vue';
import ChatReply from 'common/components/chat/ChatReply.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    ChatItem,
    ChatReply,
    InfiniteLoading
  },
  data() {
    return {
      scrollBottom: true
    };
  },
  computed: {
    ...mapGetters('support', ['messages', 'info']),
    ...mapGetters('common', ['loading']),
    isLoading() {
      return this.loading('chat/join');
    }
  },
  watch: {
    messages: 'setScroll'
  },
  beforeDestroy() {
    this.$store.commit('support/clearChat');
  },
  methods: {
    ...mapActions('support', ['sendMessage', 'closeChat', 'finishChat', 'getHistory']),
    async infiniteHandler($state) {
      let data = {};
      if (this.messages.length) {
        this.scrollBottom = false;
        data = { from: this.messages[0].id, limit: 10, chat_id: this.info.chat_id };
      } else {
        data = { from: 0, limit: 0, chat_id: this.info.chat_id };
      }
      try {
        const messages = await this.getHistory(data);
        if (messages.length) {
          $state.loaded();
        } else {
          $state.complete();
        }
      } catch (error) {
        $state.complete();
      }
    },
    openProfile() {
      if (this.info.enduser || this.info.ownerId) {
        this.$router.push({
          name: 'ClientInfo',
          params: {
            // TODO fix это говно with backend
            // category: this.info.category,
            category: this.info.category || 'contractor',
            enduser: this.info.user_type === 'truckers_db' ? this.info.ownerId : this.info.enduser
          }
        });
      }
    },
    setScroll() {
      this.$nextTick(() => {
        if (this.scrollBottom) {
          const el = document.querySelector('.chat-section-dialogue__messages');
          el.scrollTop = el.scrollHeight;
        }
        this.scrollBottom = true;
      });
    }
  }
};
</script>

<style lang="scss">
.chat-section-dialogue {
  box-shadow: 0 -4px 5px 0 rgba(0, 0, 0, 0.15);
  padding: 0 0 120px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;

  .chat-section-user {
    display: flex;
    min-height: 48px;
    align-items: center;
    &__name {
      flex: 1;
      padding: 10px;
      font-weight: bold;
    }
    &__profile {
      cursor: pointer;
    }
    &__close .v-btn__content {
      color: black;
    }
  }

  &__messages {
    overflow: auto;
    flex-direction: column;
    display: flex;
    align-items: stretch;

    .chat-item {
      max-width: 90%;
    }
  }
  .chat-reply {
    height: 115px;
    background: transparent;
    box-shadow: 0px -6px 10px -8px black;
    padding: 0 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
