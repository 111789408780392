<template>
  <div class="verification-window">
    <v-toolbar dark="dark" color="primary">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <custom-button
        icon="icon"
        color="white"
        class="verification-window__close"
        @click="$emit('close')"
      >
        <v-icon>close</v-icon>
      </custom-button>
    </v-toolbar>
    <div class="verification-window__content">
      <div class="verification-window__left">
        <div class="verification-window__wrap">
          <div class="verification-window__scrolled-block">
            <slot name="content" />
          </div>
          <div class="verification-window__actions">
            <slot name="alert" />
            <custom-button
              color="primary"
              type="submit"
              large
              :disabled="isDisabledVerifButton"
              @click="$emit('submit')"
            >
              Верифицировать
            </custom-button>
            <custom-button color="primary" large @click="isActiveVerificationDialog = true">
              Отказать
            </custom-button>
            <custom-button color="primary" outline large @click="$emit('close')">
              Отменить
            </custom-button>
          </div>
        </div>
      </div>
      <div class="verification-window__right">
        <slot name="slider" />
      </div>
    </div>
    <DeniedVerificationDialog
      :is-active="isActiveVerificationDialog"
      @close="isActiveVerificationDialog = false"
      @denied-verification="deniedVerification"
    />
  </div>
</template>

<script>
import DeniedVerificationDialog from './DeniedVerificationDialog';

export default {
  name: 'VerificationWindowAbstraction',
  components: {
    DeniedVerificationDialog
  },
  props: {
    title: String,
    isDisabledVerifButton: Boolean
  },
  data() {
    return {
      isActiveVerificationDialog: false
    };
  },
  methods: {
    deniedVerification(value) {
      this.$emit('denied-verification', value);
      this.isActiveVerificationDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.verification-window {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 18px;
  background-color: #fff;
  height: 100%;
  /deep/.verification-window__close {
    margin-left: auto;
  }
  &__content {
    display: flex;
    height: calc(100% - 70px);
    flex-grow: 1;
  }
  &__right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
  }
  &__left {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 500px;
    max-width: 500px;
    max-height: 100%;
    min-height: 100%;
    padding: 10px 10px 0px 10px;
    position: relative;
  }
  &__wrap {
    display: flex;
    height: 100%;
    flex-direction: column;
    flex-grow: 1;
  }
  &__scrolled-block {
    height: 100%;
    overflow-y: auto;
    margin: 0 -8px;
    padding: 0 8px;
  }
  &__actions {
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background: #fff;
    margin: 0 -8px 0 -8px;
    /deep/.custom-button {
      flex-grow: 1;
      min-width: unset !important;
      padding: 0;
    }
  }
}
</style>
