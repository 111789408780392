const state = {
  list: [],
  groups: []
};

const actions = {
  async getSettings({ dispatch, commit }) {
    commit('setGroups', []);
    try {
      const data = await dispatch(
        'common/createController',
        {
          topic: 'admin/administration/settings/groups/list'
        },
        { root: true }
      );
      commit('setGroups', data.list);
    } catch (error) {
      console.error(error);
    }
  },
  async getGroupSettings({ dispatch, commit }, { cgid, gid }) {
    try {
      const data = await dispatch(
        'common/createController',
        {
          topic: 'admin/administration/settings/groups/get',
          json: { cgid, gid }
        },
        { root: true }
      );
      const list = data.details.map(item => ({
        ...item,
        readonly: true
      }));
      commit('setList', list);
    } catch (error) {
      console.error(error);
    }
  },
  async setSetting({ dispatch }, item) {
    await dispatch(
      'common/createController',
      {
        topic: 'admin/administration/settings/groups/set',
        json: {
          settings_group: item.settings_group,
          settings_subgroup: item.settings_subgroup,
          name: item.name,
          type: item.type,
          value: item.value,
          comment: item.comment,
          measure: item.measure
        }
      },
      { root: true }
    );
  }
};
const mutations = {
  setList(state, data) {
    state.list = data;
  },
  setGroups(state, data) {
    state.groups = data;
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
