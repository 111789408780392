<template>
  <div class="stage_bonuses">
    <Tabs :tabs="tabs" :active="currentTab" @change="changeTab">
      <template />
    </Tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Tabs from '../../Layout/Tabs.vue';
import Invite from './Invite';

export default {
  components: {
    Tabs
  },
  data() {
    return {
      items: [],
      currentTab: this.$route.params.tab || 'invite'
    };
  },
  computed: {
    ...mapState('clients', ['info']),
    stage() {
      return this.$route.params.stage;
    },
    tabs() {
      return [
        {
          name: 'invite',
          title: 'Приведи друга',
          show: this.info.category !== 'shipper',
          component: Invite
        }
      ];
    }
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ name: 'ClientInfoStageTab', params: { stage: this.stage, tab } });
    }
  }
};
</script>

<style lang="scss">
.stage_bonuses {
  height: 100%;
}
</style>
