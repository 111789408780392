<template>
  <v-dialog :value="isActive" class="ui-kit__dialog" persistent max-width="500px">
    <div class="ui-kit__dialog__header">
      <div class="ui-kit__dialog__title ma-0">
        Отказать в верификации
      </div>
      <v-spacer />
      <custom-button icon class="ma-0" color="white" @click="$emit('close')">
        <v-icon>close</v-icon>
      </custom-button>
    </div>
    <div class="ui-kit__dialog__content">
      <v-textarea
        v-model="deniedText"
        class="ui-kit__input"
        placeholder=" "
        label="Причина отказа"
      />
      <div>
        <custom-button
          color="primary"
          large="large"
          class="ml-0"
          @click="$emit('denied-verification', deniedText)"
        >
          Отказать
        </custom-button>
        <custom-button large="" outline="" color="primary" @click="$emit('close')">
          Оменить
        </custom-button>
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: 'DeniedVerificationDialog',
  props: {
    isActive: Boolean
  },
  data() {
    return {
      deniedText: ''
    };
  }
};
</script>
