<template>
  <ContentBlock>
    <div class="wrapper-title">
      <h2 v-if="isShipper">
        Ваши условия
      </h2>
      <h2 v-else>
        Условия грузовладельца {{ shipper.nds ? '(является плательщиком НДС)' : '' }}
        <TooltipAnswer v-if="shipper.nds" :message="getHint('platelshchik_nds')" />
      </h2>
      <span v-if="isBlitzBidding" class="wrapper-title__status">
        <icon name="fire" size="21" />
        заказ с блиц-ценой
      </span>
    </div>
    <v-layout wrap>
      <v-flex md3 sm4 xs12>
        <custom-input :value="getCost" :label="`Стоимость перевозки (${typeBidding})`" disabled />
      </v-flex>
      <v-flex md4 sm8 xs12>
        <custom-input :value="paymentEvent" label="Оплата по событию" disabled />
      </v-flex>
      <v-flex md2 sm4 xs12>
        <custom-input
          :value="order.working_days_for_payment"
          label="Рабочих дней для оплаты"
          disabled
        />
      </v-flex>
      <v-flex md3 sm8 xs12>
        <custom-input label="Способ оплаты" disabled>
          <div slot="append" class="input-message">{{ paymentType }}</div>
        </custom-input>
      </v-flex>
      <v-flex md3 sm4 xs12>
        <custom-input :value="prepaid" label="Предоплата" disabled />
      </v-flex>
      <v-flex v-if="!!order.prepaid" md4 sm8 xs12>
        <custom-input :value="prepaymentType" label="Способ оплаты по предоплате" disabled />
      </v-flex>
    </v-layout>
  </ContentBlock>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import TooltipAnswer from 'common/components/TooltipAnswer.vue';
import ContentBlock from 'common/components/Orders/ContentBlock';
import {
  listPaymentEvent,
  paymentTypeItems,
  typeBiddings
} from 'common/components/Orders/enums.js';

export default {
  name: 'CargoOwnerConditions',
  components: {
    ContentBlock,
    TooltipAnswer
  },
  props: {
    order: Object
  },
  computed: {
    ...mapGetters('login', ['isShipper']),
    ...mapGetters('hints', ['getHint']),
    ...mapState('orders', {
      shipper: state => state.orderInfo.shipper
    }),
    getCost() {
      return this.order.cost ? `${this.order.cost.toLocaleString('ru')} руб.` : 'Не задано';
    },
    isBlitzBidding() {
      return this.order.bidding === 'blitz';
    },
    prepaid() {
      return this.order.prepaid
        ? `${this.order.prepaid && this.order.prepaid.toLocaleString('ru')} руб.`
        : 'Заказ без предоплаты';
    },
    typeBidding() {
      return typeBiddings[this.order.bidding]?.toLowerCase();
    },
    paymentEvent() {
      return listPaymentEvent.find(item => item.value === this.order.payment_event)?.text;
    },
    paymentType() {
      return this.order.payment_type
        ?.map(item => paymentTypeItems.find(el => el.value === item)?.label)
        .join(', ');
    },
    prepaymentType() {
      return this.order.prepayment_type
        ?.map(item => paymentTypeItems.find(el => el.value === item)?.label)
        .join(', ');
    }
  },
  created() {
    this.getInfo('shipper');
  },
  methods: {
    ...mapActions('orders', ['getInfo'])
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';
.input-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 44px;
  display: flex;
  align-items: center;
}
.wrapper-title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 12px;
  margin-bottom: 12px;

  h2 {
    display: inline-flex;
    align-items: center;
    flex-grow: 1;
    margin: 0;
    align-items: center;
  }

  &__status {
    display: inline-flex;
    color: $color-danger;
  }
}

@media screen and (max-width: breakpoint(sm)) {
  .wrapper-title {
    display: block;
    margin-bottom: 12px;

    h2 {
      display: inline;
    }

    &__status {
      color: $color-danger;
      vertical-align: sub;
    }
  }
}
</style>
