<template>
  <div class="circulars">
    <h2>Группы рассылок</h2>
    <div class="group">
      <div v-for="group in groups" :key="group.name" class="group-item">
        {{ group.title }}
        <v-icon class="icon" @click="getCsv(group.name)">
          insert_drive_file
        </v-icon>
      </div>
    </div>
    <a v-show="false" ref="link" download />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'GroupsSends',
  computed: {
    ...mapState('circular', ['groups']),
    defaultURL() {
      return this.$store.state.defaultURL;
    }
  },
  mounted() {
    this.getGroups();
  },
  methods: {
    ...mapActions('circular', ['getGroups']),
    ...mapActions('common', ['createController']),
    async getCsv(group) {
      try {
        const data = await this.createController({
          topic: 'adm_circular/get_csv',
          json: { group }
        });
        this.$refs.link.href = `${this.defaultURL}/files/temp/${data.file_id}`;
        this.$refs.link.click();
      } catch (error) {
        console.error('Ошибка в запросе adm_circular/get_csv');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.circulars {
  position: relative;
}

.group-item {
  height: 44px;
  border-top: 1px solid rgba(204, 204, 204, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  .icon {
    opacity: 0;
    transition: opacity 0.1s;
    color: $color-main;
  }

  &:last-child {
    border-bottom: 1px solid rgba(204, 204, 204, 1);
  }

  &:hover {
    background-color: rgb(242, 242, 242);
    cursor: pointer;

    .icon {
      opacity: 1;
    }
  }
}
</style>
