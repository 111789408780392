<template>
  <div class="modal driver_info">
    <v-toolbar dark="dark" color="primary">
      <v-toolbar-title>{{ data.fullname }}</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn icon="icon" dark="dark" @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <Tabs
      class="driver_info__tabs"
      :tabs="tabs"
      :active="currentTab"
      @change="changeTab"
      @verifyDenied="$emit('verifyDenied')"
    />
  </div>
</template>

<script>
import Tabs from '../../Layout/Tabs.vue';
import PersonalTab from './DriverPersonInfoTab.vue';
import TransportTab from './DriverTransportInfoTab.vue';

export default {
  components: {
    Tabs
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      tabs: [
        {
          name: 'info',
          component: PersonalTab,
          title: 'Водитель',
          options: { truckerId: this.data.trucker },
          event: 'verifyDenied'
        },
        {
          name: 'transports',
          component: TransportTab,
          title: 'Авторизованные транспортные средства',
          options: { truckerId: this.data.trucker }
        }
      ]
    };
  },
  computed: {
    currentTab() {
      return this.$route.params.tab || 'info';
    }
  },
  methods: {
    closeDialog() {
      this.$router.push({ name: 'ClientInfoStage', params: { stage: 'drivers' } });
    },
    changeTab(val) {
      this.$router.push({ name: 'ClientInfoStageTab', params: { stage: 'drivers', tab: val } });
    }
  }
};
</script>

<style lang="scss">
.driver_info {
  margin: 10px;
  box-shadow: 0 0 15px -10px black;
  .driver_info__tabs {
    height: calc(100% - 60px);
  }
}
</style>
