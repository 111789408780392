import mqtt from 'mqtt';

const { VUE_APP_CONSOLE_ENABLED } = process.env;
let isConsoleEnable = VUE_APP_CONSOLE_ENABLED === 'true';

let client,
  disconnectCounter = 0;

const mqttConnection = {
  connect(loginData, logger) {
    if (!isConsoleEnable) isConsoleEnable = loginData.isTester;
    if (client) {
      return Promise.reject('reconnection error');
    }
    return new Promise((res, rej) => {
      let url = process.env.VUE_APP_MQTT_PROTOCOL + '://' + process.env.VUE_APP_MQTT_HOST + '/mqtt';
      let port = process.env.VUE_APP_MQTT_PORT;
      client = mqtt.connect(url, {
        username: loginData.phone.replace(/^[ ]/, '').replace(/^([78])/, '+7'),
        password: loginData.mqttPassword,
        port: port,
        keepalive: 3600,
        clientId: loginData.clientId
      });
      client.on('connect', function() {
        client.subscribe('/' + loginData.cid + '/res/#', {}, err => {
          if (err) return rej(err);
          return res(client);
        });
      });
      client.on('error', err => {
        if (logger) logger(`Disconnect. Count - ${disconnectCounter}`);
        disconnectCounter++;
        if (disconnectCounter > 3) {
          client.end(true);
          window.location.assign(`${process.env.VUE_APP_LANDING_URL}?logout=true`);
        }
        client.reconnect();
        isConsoleEnable && console.log(err);
      });
      client.on('message', function(topic, message) {
        const croppedTopic = topic.split('/res').join('');
        responseMessage(croppedTopic, message);
      });
    });
  },
  publish(topic, json) {
    if (client) {
      client.publish(topic, json);
    }
  }
};

let requestList = [];
const responseMessage = (topic, message) => {
  const request = requestList.find(item => item.topic === topic);
  if (request) {
    const index = requestList.indexOf(request);
    const { callback } = request;
    try {
      const response = JSON.parse(message);
      if (callback && typeof callback === 'function') {
        isConsoleEnable &&
          console.log({ log: 'SUBSCR_RESPONSE', time: new Date().toJSON() }, { topic }, response);
        return callback(response);
      }
      requestList.splice(index, 1);
      if (response.status && response.status.result === 'rejected') {
        isConsoleEnable &&
          console.log({ log: 'ERROR', time: new Date().toJSON() }, { topic }, response);
        return request.reject(response);
      } else if (response.status && response.status.result === 'accepted') {
        isConsoleEnable &&
          console.log(
            { log: 'RESPONSE', time: new Date().toJSON() },
            { topic, response },
            request.data
          );
        return request.resolve(response);
      }
    } catch (e) {
      isConsoleEnable && console.log('Error: ', e);
      // request.reject({ error: e });
    }
  } else {
    isConsoleEnable && console.log({ log: 'NOT FOUND', time: new Date().toJSON() }, { topic });
  }
};

export const request = (topic, data) => {
  isConsoleEnable && console.log({ log: 'REQUEST', time: new Date().toJSON() }, { topic, data });
  return new Promise((resolve, reject) => {
    requestList.push({
      topic,
      data,
      resolve,
      reject
    });
    mqttConnection.publish(topic, JSON.stringify(data));
  });
};

export const subscribe = (topic, callback) => {
  if (requestList.some(_ => _.topic === topic)) return; // prevent double subscribe
  isConsoleEnable && console.log({ log: 'SUBSCRIBE', time: new Date().toJSON() }, { topic });
  requestList.push({
    topic,
    callback
  });
  client.subscribe(topic, null, err => {
    if (err) throw new Error(err);
  });
};

export const unsubscribe = topic => {
  if (requestList.every(_ => _.topic !== topic)) return;
  isConsoleEnable && console.log({ log: 'UNSUBSCRIBE', time: new Date().toJSON() }, { topic });
  requestList = requestList.filter(_ => _.topic !== topic);
  client.unsubscribe(topic, null, err => {
    if (err) throw new Error(err);
  });
};

export default mqttConnection;
