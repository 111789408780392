var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-checkbox',{attrs:{"value":_vm.checkBirthdayPlacePasLic,"reverse":"","false-value":false,"true-value":true,"rules":[function (v) { return !!v || 'Поле обязательно для ввода'; }]},on:{"change":function($event){return _vm.$emit('changed', {
          field: 'checkBirthdayPlacePasLic',
          value: $event
        })}}},[_c('span',{attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v(" Совпадение места рождения по паспорту и водительскому удостоверению ")])])],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-checkbox',{attrs:{"value":_vm.checkBirthdayDatePasLic,"false-value":false,"true-value":true,"rules":[function (v) { return !!v || 'Поле обязательно для ввода'; }]},on:{"change":function($event){return _vm.$emit('changed', {
          field: 'checkBirthdayDatePasLic',
          value: $event
        })}}},[_c('span',{attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v(" Совпадение даты рождения по паспорту и водительскому удостоверению ")])])],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-checkbox',{attrs:{"value":_vm.checkPhoto,"false-value":false,"true-value":true,"rules":[function (v) { return !!v || 'Поле обязательно для ввода'; }]},on:{"change":function($event){return _vm.$emit('changed', {
          field: 'checkPhoto',
          value: $event
        })}}},[_c('span',{attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v(" Фотографии пользователя совпадают во всех документах ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }