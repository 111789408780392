var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"transport-form",attrs:{"grid-list-lg":""}},_vm._l((_vm.columns),function(column,index){return _c('v-layout',{key:index,attrs:{"row":"","wrap":""}},_vm._l((column),function(field,fieldIndex){return _c('v-flex',{key:fieldIndex,class:field.class,attrs:{"sm3":!field.size || field.size === 3,"sm4":field.size === 4,"sm6":field.size === 6,"sm12":field.size === 12,"xs12":""}},[(field.caption && field.show !== false)?_c('div',{staticClass:"transport-caption",class:{
          'pa-special': field.caption !== 'Характеристики кузова',
          'pb-13px': field.caption === 'Кузов'
        }},[_vm._v(" "+_vm._s(field.caption)+" ")]):_c('div',{staticClass:"transport-field"},[(field.component)?_c('div',{staticClass:"transport-value",class:{ 'm-phoroTS': field.label === 'Фото транспортного средства' }},[(_vm.checkFieldOnEmpty(field))?_c('i',{class:{
              'text-scan_sts': field.label === 'Скан/фото СТС',
              'text-scan_doc': field.label === 'Скан/фото документа',
              'text-photo_ts': field.label === 'Фото транспортного средства'
            }},[_vm._v(" "+_vm._s(field.label)+" ")]):_vm._e(),_c(field.component,{tag:"component",attrs:{"value":field.value}})],1):(field.show !== false)?_c('div',{staticClass:"transport-value"},[(field.html)?_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(field.html),expression:"field.html"}],staticClass:"transport-info-html-content"}):_c('custom-input',{class:{ 'with-label': field.label },attrs:{"label":field.label,"value":field.value || field.value === 0 ? field.value : 'Не задано',"disabled":""}})],1):_vm._e()])])}),1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }