export function getUUID() {
  return Math.random()
    .toString(36)
    .substr(2, 9);
}

export function plural(number, one, two, five) {
  // plural(10, 'носок', 'носка', 'носков') --> '10 носков'
  let n = Math.abs(number);

  n %= 100;
  if (n >= 5 && n <= 20) {
    return `${number}\u00A0${five}`;
  }

  n %= 10;
  if (n === 1) {
    return `${number}\u00A0${one}`;
  }

  if (n >= 2 && n <= 4) {
    return `${number}\u00A0${two}`;
  }

  return `${number}\u00A0${five}`;
}

export const ICONS = {
  autoTs: ['carc11'],
  bortTs: ['carc12'],
  containerTs: ['carc10'],
  platformTs: ['carc13'],
  refrigeratorTs: ['carc4', 'carc5', 'carc6', 'carc7', 'carc8', 'carc9'],
  vanAwningTs: ['carc2'],
  vanIsotermTs: ['carc3'],
  vanTs: ['carc1']
};

export function getCarcassIcon(id) {
  return Object.keys(ICONS).find(_ => ICONS[_].includes(id)) || 'defaultCarcIcon';
}

export const isCorrectEmail = v => {
  if (!v) {
    return true;
  }
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v);
};

export const isCorrectPhone = v => {
  if (!v) {
    return true;
  }
  return /^(([DZ])?(8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{4,15}$/.test(v);
};
