<template>
  <div
    v-if="loading('admin/endusers/profile/organization/get') || !organization.org_info"
    class="loading"
  >
    <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
  </div>
  <div v-else class="organization_personal">
    <div class="organization_personal__title">
      <div class="title__text">
        {{ showTrucker ? 'Перевозчик' : 'Данные' }}
      </div>
      <div
        class="title__verify"
        :style="{
          color:
            utils.verifyColors[organization.org_info.verification_status] ||
            utils.verifyColors.default
        }"
      >
        {{ status }}
      </div>
    </div>
    <div class="organization_personal__table">
      <div class="organization_personal__table__column">
        <div class="organization_personal__column__row">
          <div class="organization_personal__column__cell">
            <v-text-field
              label="ИНН"
              :value="organization.org_info.inn"
              placeholder=" "
              :readonly="true"
            />
          </div>
          <div class="organization_personal__column__cell">
            <img class="avatar" :src="photos.avatar" alt="Avatar" />
          </div>
          <div class="organization_personal__column__cell" />
        </div>
        <template v-if="!isShipper">
          Паспорт
          <div class="organization_personal__column__row">
            <div class="organization_personal__column__cell">
              <v-text-field
                label="Имя"
                :value="organization.org_info.name"
                placeholder=" "
                :readonly="true"
              />
            </div>
            <div class="organization_personal__column__cell">
              <v-text-field
                label="Фамилия"
                :value="organization.org_info.lastname"
                placeholder=" "
                :readonly="true"
              />
            </div>
            <div class="organization_personal__column__cell">
              <v-text-field
                label="Отчество"
                :value="organization.org_info.patronymic"
                placeholder=" "
                :readonly="true"
              />
            </div>
          </div>
          <div class="organization_personal__column__row">
            <div class="organization_personal__column__cell">
              <v-text-field
                label="Серия"
                :value="organization.org_info.passport_serie"
                placeholder=" "
                :readonly="true"
              />
            </div>
            <div class="organization_personal__column__cell">
              <v-text-field
                label="Номер"
                :value="organization.org_info.passport_number"
                placeholder=" "
                :readonly="true"
              />
            </div>
            <div class="organization_personal__column__cell">
              <v-text-field
                label="Дата выдачи"
                :value="OrganizationDate('passport_date')"
                placeholder=" "
                :readonly="true"
              />
            </div>
            <div class="organization_personal__column__cell">
              <v-text-field
                label="Дата рождения"
                :value="OrganizationDate('birthday_date')"
                placeholder=" "
                :readonly="true"
              />
            </div>
          </div>
          <div class="organization_personal__column__row">
            <div
              v-for="(item, index) in passport_titles"
              :key="index"
              class="organization_personal__column__cell"
            >
              <span class="photo_title">{{ item }}</span>
              <image-slider-small :value="photos[index]" />
            </div>
          </div>
        </template>
      </div>
    </div>
    <v-btn
      v-if="!showTrucker"
      :disabled="isDisabled(organization.org_info.verification_status)"
      color="primary"
      large="large"
      @click="verificationDone"
    >
      Отказать в верификации
    </v-btn>
    <v-dialog v-model="dialogDenied" class="ui-kit__dialog" persistent max-width="500px">
      <div class="ui-kit__dialog__header">
        <div class="ui-kit__dialog__title">
          Отказать в верификации
        </div>
        <v-spacer />
        <v-btn
          class="ui-kit__dialog__close"
          fab="fab"
          small="small"
          color="primary"
          @click.stop="dialogDenied = false"
        >
          <v-icon dark="dark">
            close
          </v-icon>
        </v-btn>
      </div>
      <div class="ui-kit__dialog__content">
        <v-textarea v-model="deniedText" class="ui-kit__input" label="Причина отказа" />
        <div class="block-btns">
          <v-btn color="primary" large="large" @click="deniedVerification">
            Отказать
          </v-btn>
          <v-btn large="large" outline="outline" color="primary" @click.stop="dialogDenied = false">
            Отменить
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { getStatus } from '@/helpers';
import utils from '@/helpers/utils';
import moment from '../../../../../common/helpers/date';
import ImageSliderSmall from '../../common/ImageSliderSmall';

export default {
  name: 'ProfileTabPersonal',
  components: {
    ImageSliderSmall
  },
  props: {
    showTrucker: Boolean
  },
  data() {
    return {
      dialogDenied: false,
      deniedText: '',
      passport_titles: {
        passport: 'Разворот с фото',
        registration: 'Разворот с регистрацией',
        numbers: 'Разворот с 18-ой и 19-ой страницами'
      },
      utils
    };
  },
  computed: {
    ...mapGetters('common', ['loading', 'cid']),
    ...mapState('login', ['isSuper']),
    ...mapState('clientsProfile', ['organization']),
    ...mapState('clients', ['info']),
    isShipper() {
      return this.info.category === 'shipper';
    },
    status() {
      const organization = this.organization.org_info;
      return getStatus(utils.statuses, {
        status: organization.verification_status,
        date: organization.verification_date,
        until: organization.verification_until
      });
    },
    defaultURL() {
      return this.$store.state.defaultURL;
    },
    photos() {
      return {
        avatar: this.organization.photos
          .filter(item => item.type === 'my_photo')
          .map(item => this.getUrl(item)),
        passport: this.organization.photos
          .filter(item => item.type === 'passport_photo')
          .map(item => this.getUrl(item)),
        registration: this.organization.photos
          .filter(item => item.type === 'passport_registration')
          .map(item => this.getUrl(item)),
        numbers: this.organization.photos
          .filter(item => item.type === 'passport_previous_number')
          .map(item => this.getUrl(item))
      };
    }
  },
  mounted() {
    if (!this.showTrucker) {
      this.getOrganization();
    }
  },
  methods: {
    ...mapActions('clientsProfile', ['getOrganization', 'verifyDenial']),
    /**
     * @return {string}
     */
    isDisabled(status) {
      return !this.isSuper || status !== 'verified';
    },
    OrganizationDate(key) {
      return moment(this.organization.org_info[key]).format('DD.MM.YYYY');
    },
    getUrl(photo) {
      let infoCid = this.info.cid;
      if (this.showTrucker) {
        infoCid = this.organization.org_info.cid;
      }
      return `${this.defaultURL}/files/${this.cid}/${infoCid}/${photo.medium}`;
    },
    verificationDone() {
      this.dialogDenied = true;
    },
    async deniedVerification() {
      await this.verifyDenial({
        enduser: this.info.id,
        comment: this.deniedText
      });
      this.dialogDenied = false;
      if (!this.showTrucker) {
        this.getOrganization();
      }
    }
  }
};
</script>
<style lang="scss">
.organization_personal {
  .organization_personal__title {
    display: flex;
    .title__text {
      font-size: 24px;
      padding: 20px 0;
    }
    .title__verify {
      margin-left: auto;
      margin-right: 30%;
    }
  }

  .organization_personal__table {
    display: flex;

    .organization_personal__table__column {
      width: 70%;

      .organization_personal__column__row {
        display: flex;

        .organization_personal__column__cell {
          padding: 15px 5px;
          width: 100%;

          span {
            display: block;
            margin-bottom: 3px;
            font-style: normal;
            color: #8b8b8b;
            line-height: 11px;
            font-size: 12px;
          }

          .avatar {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>
