import { setField } from 'common/store/helpers';

const state = {
  total: 0,
  items: [],
  info: null,
  cards: {},
  organization: {},
  semaphores: {},
  address: {},
  region_list: [],
  truckerProfile: {}
};

const actions = {
  async getItems({ dispatch, commit, rootGetters: { 'common/loading': loading } }, { enduser_id }) {
    if (loading('admin/endusers/profile/users/get') || !enduser_id) {
      return;
    }
    const topic = 'admin/endusers/profile/users/get';
    commit('setField', { field: 'items', value: [] });
    commit('setField', { field: 'total', value: 0 });
    commit('setField', { field: 'info', value: {} });
    try {
      const { confidants, registrant, count } = await dispatch(
        'common/createController',
        { topic, json: { enduser: enduser_id } },
        { root: true }
      );
      commit('setField', { field: 'items', value: confidants });
      commit('setField', { field: 'total', value: count || confidants.length });
      commit('setField', { field: 'info', value: registrant });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async getOrganization({
    dispatch,
    commit,
    rootGetters: { 'common/loading': loading },
    rootState: {
      clients: { info }
    }
  }) {
    if (loading('admin/endusers/profile/organization/get') || !info.enduser) {
      return;
    }
    const topic = 'admin/endusers/profile/organization/get';
    try {
      const data = await dispatch(
        'common/createController',
        { topic, json: { enduser: info.enduser } },
        { root: true }
      );
      commit('setOrganization', data);
    } catch (error) {
      console.error(error);
    }
  },
  async getSemaphore({
    dispatch,
    commit,
    rootState: {
      clients: { info }
    }
  }) {
    const { semaphores } = await dispatch(
      'common/createController',
      { topic: 'organization/get_safety_info', json: { cid: info.cid } },
      { root: true }
    );
    const value = {
      red: semaphores.semaphore_red,
      green: semaphores.semaphore_green,
      yellow: semaphores.semaphore_yellow,
      recomendations: semaphores.semaphore_recommendations
    };
    commit('setField', { field: 'semaphores', value });
  },
  async getAddress({
    dispatch,
    commit,
    rootGetters: { 'common/loading': loading },
    rootState: {
      clients: { info }
    }
  }) {
    if (loading('admin/endusers/profile/address/get') || !info) {
      return;
    }
    commit('setField', { field: 'address', value: {} });
    const topic = 'admin/endusers/profile/address/get';
    try {
      const data = await dispatch(
        'common/createController',
        { topic, json: { enduser: info.enduser } },
        { root: true }
      );
      commit('setField', { field: 'address', value: data });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async getCards({
    dispatch,
    commit,
    rootGetters: { 'common/loading': loading },
    rootState: {
      clients: { info }
    }
  }) {
    if (loading('admin/endusers/profile/cards_and_banking/get') || !info) {
      return;
    }
    commit('setField', { field: 'cards', value: {} });
    const topic = 'admin/endusers/profile/cards_and_banking/get';
    try {
      const data = await dispatch(
        'common/createController',
        { topic, json: { enduser: info.enduser } },
        { root: true }
      );
      commit('setField', { field: 'cards', value: data });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async getRegionList({ dispatch, commit }, enduser) {
    commit('setField', { field: 'region_list', value: [] });

    const topic = 'admin/endusers/contractor/profile/loadregions/list';
    try {
      const { list } = await dispatch(
        'common/createController',
        { topic, json: { enduser } },
        { root: true }
      );
      commit('setField', { field: 'region_list', value: list });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async getTruckerProfile({ dispatch, commit }, enduser) {
    commit('setField', { field: 'truckerProfile', value: {} });
    const topic = 'admin/endusers/trucker/profile/details/get';
    try {
      const data = await dispatch(
        'common/createController',
        { topic, json: { enduser } },
        { root: true }
      );
      commit('setField', { field: 'truckerProfile', value: data });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async verifyDenial({ dispatch, commit }, json) {
    const topic = 'admin/endusers/contractor/profile/organization/deny';
    try {
      await dispatch('common/createController', { topic, json }, { root: true });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
      commit('errorPush', 'Ошибка запроса на сервер', { root: true });
    }
  },
  async truckerVerifyDenial({ dispatch, commit }, json) {
    const topic = 'admin/endusers/trucker/profile/details/deny';
    try {
      await dispatch('common/createController', { topic, json }, { root: true });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
      commit('errorPush', 'Ошибка запроса на сервер', { root: true });
    }
  }
};

const mutations = {
  setField,
  setOrganization(state, organization) {
    state.organization = organization;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
