import lightFormat from 'date-fns/lightFormat';

export const getDisputeStatus = ({ dispute, isShipper, personal = false }) => {
  const turnSides = {
    shipper: personal && isShipper ? 'вашего ответа' : 'ответа грузовладельца',
    carrier: personal && !isShipper ? 'вашего ответа' : 'ответа перевозчика',
    operator: 'ответа оператора'
  };
  const winnerString = {
    initiator_won: 'в пользу инициатора',
    responder_won: 'в пользу ответчика',
    neutral: 'нейтрально',
    you_won: 'в вашу пользу',
    you_lost: 'в пользу контрагента'
  };
  const {
    whose_turn_category: whoseTurn,
    dispute_turn_end: turnDate,
    dispute_status: disputeStatus,
    dispute_close: disputeClose
  } = dispute;
  if (disputeStatus.startsWith('finalized')) {
    const [winner] = disputeStatus.split('finalized_').filter(_ => _);
    return `Завершен ${lightFormat(new Date(disputeClose), 'dd.MM.yyyy в HH:mm')} ${
      winnerString[winner]
    }`;
  }
  return `Ожидание ${turnSides[whoseTurn]} до ${lightFormat(
    new Date(turnDate),
    'dd.MM.yyyy в HH:mm'
  )}`;
};
