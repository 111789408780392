<template>
  <div ref="circular" class="circulars">
    <h2 class="projectsCircular">
      Проекты уведомлений
      <custom-button flat color="primary" class="ma-0" @click="addCircular">
        <v-icon>add_circle_outline</v-icon>
        Добавить проект уведомления
      </custom-button>
    </h2>
    <circular-table :circulars="current" :set-circular="setCircular" :is-loading="isLoading" />
    <h2>Архив уведомлений</h2>
    <circular-table :circulars="archived" :set-circular="setCircular" :is-loading="isLoading" />
    <circular-form
      v-if="showedCircular"
      :groups="groups"
      :circular="showedCircular"
      @close="closeForm"
      @save="saveCircular"
      @action="actionButtons"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CircularForm from './circulars/CircularForm.vue';
import CircularTable from './circulars/CircularTable.vue';

const getCircularInfo = groups => circular => ({
  parametrs: circular.parametrs,
  date: new Date(circular.create_date).toLocaleString('ru'),
  group: groups.find(_ => _.name === circular.recipient_group),
  archived: circular.archived,
  id: circular.id,
  send_by_sms: circular.send_by_sms,
  send_by_email: circular.send_by_email,
  send_by_web: circular.send_by_web,
  send_by_push: circular.send_by_push,
  sending_date: circular.sending_date,
  operator_sender_name: circular.operator_sender_name,
  operator_creator_name: circular.operator_creator_name,
  started: circular.started,
  title: circular.title,
  freezed: circular.freezed
});

export default {
  name: 'ProjectsCirculars',
  components: {
    CircularForm,
    CircularTable
  },
  data: () => ({
    current: [],
    archived: [],
    showedCircular: null,
    isLoading: false
  }),
  computed: {
    ...mapState('circular', ['groups']),
    defaultURL() {
      return this.$store.state.defaultURL;
    }
  },
  mounted() {
    this.getLists();
    this.getGroups();
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions('circular', ['getGroups']),
    setCircular(circular) {
      this.showedCircular = circular;
      this.$refs.circular.scrollIntoView();
    },
    closeForm() {
      this.showedCircular = null;
    },
    async getLists() {
      this.isLoading = true;
      const topic = 'adm_circular/list';
      try {
        const { circular: current } = await this.createController({
          topic,
          json: { archived: false }
        });
        const { circular: archived } = await this.createController({
          topic,
          json: { archived: true }
        });
        this.current = current.map(getCircularInfo(this.groups));
        this.archived = archived.map(getCircularInfo(this.groups));
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`);
      } finally {
        this.isLoading = false;
      }
    },
    addCircular() {
      this.showedCircular = {
        parametrs: {
          tags: {
            user_name: {
              name: 'Имя пользователя',
              value: '#user_name#'
            },
            user_surname: {
              name: 'Фамилия пользователя',
              value: '#user_surname#'
            }
          }
        }
      };
    },
    async saveCircular(editedCircular) {
      const topic = 'adm_circular/save';
      try {
        await this.createController({ topic, json: editedCircular });
        this.closeForm();
        this.getLists();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`);
      }
    },
    async actionButtons(action) {
      try {
        await this.createController({
          topic: `adm_circular/${action}`,
          json: { id: this.showedCircular.id }
        });
        this.closeForm();
        this.getLists();
      } catch (error) {
        console.error(`Ошибка в запросе adm_circular/${action}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.circulars {
  position: relative;
}

.projectsCircular {
  display: flex;
  justify-content: space-between;
}
</style>
