<template>
  <div>
    <DisputesList :type="type" />
    <DisputesList v-if="type === 'devoid_and_my'" type="monitored" />
  </div>
</template>

<script>
import DisputesList from './DisputesList.vue';

export default {
  name: 'Disputes',
  components: {
    DisputesList
  },
  props: {
    type: {
      type: String
    }
  }
};
</script>
