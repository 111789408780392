import moment from 'moment';

export function getStatus(
  statuses,
  { status, date = new Date(), until = new Date(), declined },
  withoutDate = false
) {
  switch (status) {
    case 'verified': {
      const year = new Date(until).getFullYear();
      if (year > 2200) {
        return 'Верифицирован';
      }
      return statuses[status].replace('%date%', moment(until).format('D.MM.YYYY, HH:mm'));
    }
    case 'detached':
      return `Открепился ${moment(declined).format('D.MM.YYYY, HH:mm')}`;
    case 'declined':
      return `Отклонен ${moment(declined).format('D.MM.YYYY, HH:mm')}`;
    case 'denied':
      if (withoutDate) {
        return 'Отказ в верификации';
      }
      return statuses[status].replace('%date%', moment(date).format('D.MM.YYYY, HH:mm'));
    default:
      return (statuses[status] || statuses.default).replace(
        '%date%',
        moment(until).format('D.MM.YYYY, HH:mm')
      );
  }
}

export function insertTag(myField, myValue) {
  if (myField.selectionStart || myField.selectionStart === 0) {
    const startPos = myField.selectionStart;
    const endPos = myField.selectionEnd;
    myField.value =
      myField.value.substring(0, startPos) +
      myValue +
      myField.value.substring(endPos, myField.value.length);
    myField.selectionStart = startPos + myValue.length;
    myField.selectionEnd = startPos + myValue.length;
  } else {
    myField.value += myValue;
  }
  return myField.value;
}
