<template>
  <table class="table-hints">
    <thead>
      <tr>
        <th>Категория</th>
        <th>Раздел</th>
        <th>Описание подсказки</th>
        <th>Ключ подсказки</th>
        <th>Текст подсказки</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(item, key) in filteredHints">
        <tr :key="key" @click="$emit('edit', item)">
          <td>{{ item.type }}</td>
          <td>{{ item.group }}</td>
          <td>{{ item.info }}</td>
          <td>{{ item.key }}</td>
          <td>{{ item.value }}</td>
        </tr>
        <tr v-if="item.key === currentKey" :key="item.key">
          <td colspan="5" class="pa-0"><slot /></td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'TooltipsTableVue',
  props: {
    hints: {
      type: Array,
      default: () => []
    },
    currentGroup: String,
    currentCategory: String,
    currentKey: String
  },
  computed: {
    filteredHints() {
      return this.hints.filter(_ => {
        const matchType = _.type === this.currentCategory || this.currentCategory === 'Общая';
        const matchGroup = _.group === this.currentGroup || this.currentGroup === 'Все';
        return matchType && matchGroup;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.table-hints {
  width: 100%;
  border-spacing: unset;
  font-size: 14px;
  th,
  td {
    text-align: left;
    border-bottom: 1px solid rgba(204, 204, 204, 1);
    height: 44px;
    padding-left: 16px;
    width: auto;
  }
  tbody tr {
    cursor: pointer;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 140px;
  }
  th {
    border-top: 1px solid rgba(204, 204, 204, 1);
  }
  tbody tr:hover {
    background-color: rgba(242, 242, 242, 1);
  }
}
</style>
