import mqttConnection from 'common/actions/mqttConnection';

const state = {
  client: {
    connected: false
  }
};
const actions = {
  async createClient({ state, rootState, commit }, logger) {
    if (state.client.connected) return;
    const client = await mqttConnection.connect(rootState.login, logger);
    commit('setClient', client);
    return client;
  }
};
const mutations = {
  setClient(state, client) {
    state.client = client;
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
