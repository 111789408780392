<template>
  <div class="bodyworks">
    <custom-button
      class="bodyworks__add"
      flat
      color="primary"
      :disabled="!isDraft || !isSuper"
      @click="$emit('add')"
    >
      <v-icon>add_circle_outline</v-icon>
      Добавить
    </custom-button>
    <slot />
    <div v-if="isLoading" class="text-center">
      <v-progress-circular indeterminate="indeterminate" color="primary" />
    </div>
    <table
      v-else
      class="bodyworks__table"
      :class="{ 'bodyworks__table--relative': isRelativeTable }"
    >
      <tr v-if="isAddForm">
        <slot name="form" />
      </tr>
      <thead>
        <tr>
          <slot name="head" />
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, i) in list">
          <tr :key="i" @click="edit(item)">
            <slot name="body" :item="item" />
            <td class="bodyworks__table__order">
              <custom-button
                flat
                icon
                color="primary"
                :disabled="i === 0 || !isDraft || !isSuper"
                @click.stop="$emit('toggle-order', { gid: item.id, direction: 'up' })"
              >
                <icon name="orderUp" size="13.5" />
              </custom-button>
              <custom-button
                flat
                icon
                color="primary"
                :disabled="i === list.length - 1 || !isDraft || !isSuper"
                @click.stop="$emit('toggle-order', { gid: item.id, direction: 'down' })"
              >
                <icon name="orderDown" size="13.5" />
              </custom-button>
            </td>
          </tr>
          <tr v-if="item.id === currentId" :key="item.id">
            <slot name="form" />
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'BodyWorksContentAbstraction',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    currentId: String,
    isAddForm: Boolean,
    isRelativeTable: Boolean
  },
  computed: {
    ...mapState('login', ['isSuper']),
    ...mapGetters('common', ['loading']),
    ...mapGetters('carcasses', ['getCurrentType']),
    isLoading() {
      return this.loading('adm_carcasses/get_scheme');
    },
    isDraft() {
      return this.getCurrentType === 'draft';
    }
  },
  methods: {
    edit(item) {
      if (!this.isDraft) return;
      this.$emit('edit', item);
    }
  }
};
</script>

<style lang="scss" scoped>
.bodyworks {
  min-height: 488px;
  max-height: 550px;
  &__table {
    width: 100%;
    border-spacing: unset;
    font-size: 14px;
    th {
      text-align: left;
      border-top: 1px solid rgba(204, 204, 204, 1);
      border-bottom: 1px solid rgba(204, 204, 204, 1);
      height: 44px;
      padding-left: 16px;
    }
    td {
      text-align: left;
      border-bottom: 1px solid rgba(204, 204, 204, 1);
      height: 44px;
      padding-left: 16px;
      &:first-child {
        /deep/.icon {
          display: inline-block;
          width: 60px !important;
          height: 30px !important;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        &:hover {
          background-color: rgba(242, 242, 242, 1);
        }
      }
    }
    &__order {
      width: 88px;
      /deep/.v-btn {
        margin: 0;
        float: right;
      }
    }
  }
  &__add {
    margin: 8px 0 16px 0;
    /deep/.v-icon {
      margin-right: 8px;
    }
  }
}

table {
  width: 100%;
}

table,
td {
  border-collapse: collapse;
}

thead {
  display: table;
  width: calc(100% - 17px);
}

tbody {
  display: block;
  max-height: 360px;
  overflow-y: scroll;
}

th,
td {
  word-break: break-all;
  &:first-child {
    width: 150px;
  }
}

tr {
  display: table;
  width: 100%;
  box-sizing: border-box;
}

td {
  text-align: center;
  border-bottom: none;
  border-left: none;
}

.bodyworks__table.bodyworks__table--relative {
  table-layout: fixed;
  th:nth-child(1),
  th:nth-child(2),
  td:nth-child(1),
  td:nth-child(2) {
    width: 20%;
    text-align: left;
  }
  th,
  td {
    width: 5%;
    text-align: left;
  }
  th:last-child,
  td:last-child {
    min-width: 60px;
    max-width: 60px;
  }
}
</style>
