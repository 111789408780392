export class BaseApi {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  mqttController({ topic, json = {} }) {
    try {
      return this.dispatch('common/createController', { topic, json }, { root: true });
    } catch (error) {
      console.error(`Ошибка в запросе ${topic}`, error);
    }
  }

  mqttSubscribe({ topic, callback }) {
    try {
      this.dispatch('common/subscribe', { topic, callback }, { root: true });
    } catch (error) {
      console.error(`Ошибка добавления подписки ${topic}`, error);
    }
  }

  mqttUnsubscribe({ topic }) {
    try {
      this.dispatch('common/unsubscribe', { topic }, { root: true });
    } catch (error) {
      console.error(`Ошибка удаления подписки ${topic}`, error);
    }
  }
}
