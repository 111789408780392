<template>
  <v-layout ref="changePassForm" v-model="valid" wrap class="replica" tag="v-form">
    <v-flex md2 class="mt-3">
      <custom-input
        v-model="deadlineTime"
        :rules="[
          v => !!v || 'Время является обязательным',
          v => v <= dispute.user_response_time || 'Время указано неверно',
          v => v > 0 || 'Время не может быть меньше 0'
        ]"
        label="Дать ответ в течение (в сутках)"
      />
    </v-flex>
    <v-flex md10 class="mt-3">
      <custom-input
        v-model="replica"
        :rules="[v => !!v || 'Текст является обязательным']"
        input-type="textarea"
        rows="1"
        label="Введите текст ответа"
      />
    </v-flex>
    <v-flex md4 class="relative">
      <span class="label">Передать слово</span>
      <v-radio-group v-model="nextTurn" :rules="[v => !!v || 'Выберите пользователя']" row>
        <v-radio :ripple="false" :label="initiatorName()" value="initiator" />
        <v-radio :ripple="false" :label="responderName()" value="responder" />
      </v-radio-group>
    </v-flex>
    <v-flex md8>
      <custom-button :disabled="isDisabled" color="primary" round large @click="sendReplica">
        Ответить и передать слово
      </custom-button>
      <custom-button flat @click="showForm = true">Завершить диспут</custom-button>
    </v-flex>
    <dispute-finish-modal :show-form="showForm" @close="$emit('success')" />
  </v-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import set from 'date-fns/set';
import lightFormat from 'date-fns/lightFormat';
import DisputeFinishModal from './DisputeFinishModal';

export default {
  name: 'DisputeOperatorForm',
  components: {
    DisputeFinishModal
  },
  props: {
    dispute: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    valid: false,
    deadlineTime: null,
    replica: '',
    files: [],
    nextTurn: null,
    showForm: false
  }),
  computed: {
    ...mapState({
      info: state => state.orders.orderInfo.info
    }),
    deadline() {
      const hours = new Date().getHours();
      return set(new Date(), { hours: hours + this.deadlineTime * 24 });
    },
    isDisabled() {
      return !this.valid;
    },
    reversedReplicasList() {
      const list = this.dispute.replicas;
      return list.reverse();
    }
  },
  created() {
    this.deadlineTime = this.dispute.user_response_time;
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions(['addSnackbar']),
    initiatorName() {
      const isInitiatorCarrier = this.info.who_canceled !== 'carrier';
      if (isInitiatorCarrier) {
        const { name } = this.getLastReplicaName('carrier');
        return `Инициатору ГП (${name || this.info.contact_carrier_name})`;
      } else {
        const { name } = this.getLastReplicaName('shipper');
        return `Инициатору ГВ (${name || this.info.contact_name})`;
      }
    },
    responderName() {
      const isResponderCarrier = this.info.who_canceled === 'carrier';
      if (isResponderCarrier) {
        const { name } = this.getLastReplicaName('carrier');
        return `Ответчику ГП (${name || this.info.contact_carrier_name})`;
      } else {
        const { name } = this.getLastReplicaName('shipper');
        return `Ответчику ГВ (${name || this.info.contact_name})`;
      }
    },
    getLastReplicaName(type) {
      const findLastReplica = this.reversedReplicasList.find(
        ({ user_category }) => user_category === type
      );
      return { name: findLastReplica ? findLastReplica.name : '' };
    },
    async sendReplica() {
      const topic = 'disputes/do_operator_replica';
      const json = {
        order_cid: this.info.cid,
        text: this.replica,
        files: this.files,
        whose_turn: this.nextTurn,
        turn_end: this.deadline.toISOString()
      };
      try {
        await this.createController({ topic, json });
        this.addSnackbar({ message: 'Ответ отправлен', type: 'success' });
        this.$emit('success');
      } catch ({ status: { reason } }) {
        let message = 'Ошибка отправки ответа';
        if (reason === 'E_NOT_YOUR_TURN') message = 'Сейчас не ваша очередь';
        this.addSnackbar({ message, type: 'error' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.relative {
  position: relative;
}
</style>
