import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import set from 'date-fns/set';
import fnsFormat from 'date-fns/format';
import lightFormat from 'date-fns/lightFormat';
import { DATE_NULL, YEAR_NULL } from 'common/helpers/dateEnums';

export const getUTCDate = (date = Date.now()) => {
  const dateObj = new Date(date);
  const year = dateObj.getUTCFullYear();
  const month = dateObj.getUTCMonth();
  const day = dateObj.getUTCDate();
  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();

  return new Date(year, month, day, hours, minutes);
};

export const isDateNullish = date => new Date(date).getFullYear() === YEAR_NULL;

export const formatISO = (date, format) => {
  if (!date) return DATE_NULL;
  const dateObj = typeof date === 'string' ? getUTCDate(date) : new Date(date);
  const ISOString = `${format(dateObj, "yyyy-MM-dd'T'HH:mm:ss").substring(0, 19)}.000Z`;
  return ISOString;
};

export const getRemainedTime = (start, end) => {
  const dateStart = new Date(start);
  const dateEnd = new Date(end);
  const days = differenceInDays(dateStart, dateEnd);
  const hours = differenceInHours(dateStart, dateEnd) % 24;
  const minutes = differenceInMinutes(dateStart, dateEnd) % 60;
  return {
    days: days ? `${days} д.` : '',
    hours: hours ? `${hours} ч.` : '',
    minutes: minutes ? `${minutes} мин.` : ''
  };
};

export const formattingDate = date => lightFormat(getUTCDate(date), 'dd.MM.yyyy');

export const getDepartureTime = item => {
  const {
    begin_datetime: begin,
    end_datetime: end,
    begin_end_offset: offset,
    loading_date: loadindDate
  } = item;
  if (!offset) return '';
  const endDate = getUTCDate(end);
  const hours = endDate.getHours() + offset;
  const minutes = endDate.getMinutes();
  const formatDate = endDate.getSeconds() ? 'dd.MM.yy' : 'dd.MM.yy, HH:mm';
  const isEqual = begin === end;
  const localDate =
    !!loadindDate && !isDateNullish(loadindDate)
      ? set(getUTCDate(loadindDate), { hours, minutes })
      : set(endDate, { hours, minutes });
  if (isEqual || !isDateNullish(loadindDate)) return lightFormat(localDate, formatDate);
  return `${lightFormat(getUTCDate(begin), 'dd.MM.yy')} - ${lightFormat(localDate, formatDate)}`;
};

export const getHrsMints = time =>
  time?.split(':').map(_ => (Number.isNaN(Number(_)) ? 0 : Number(_))) ?? [0, 0];

export const createDatetimeWithOffset = ({ date, offset }) => {
  try {
    const hours = getUTCDate(date).getHours() + offset;
    const minutes = getUTCDate(date).getMinutes();
    const datetime = set(getUTCDate(date), { hours, minutes });
    return datetime;
  } catch (error) {
    console.error('Ошибка добавления offset к datetime', error);
  }
};

export const timeValidationRule = (time, errText = '') => {
  return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time) || errText;
};
