<template>
  <v-progress-circular
    v-if="[undefined, null].includes(rating)"
    size="25"
    color="primary"
    indeterminate
  />
  <span v-else :class="ratingColor">{{ rating }}%</span>
</template>

<script>
import { mapState } from 'vuex';

const RED_RATING = 30;

export default {
  name: 'RatingCount',
  props: {
    rating: [String, Number]
  },
  computed: {
    ...mapState('settings', ['greenRating']),
    ratingColor() {
      if (Number(this.rating) > Number(this.greenRating)) return 'rating-green';
      else if (Number(this.rating) < RED_RATING) return 'rating-red';
      return 'rating-yellow';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.rating {
  &-green {
    color: $color-success;
  }

  &-red {
    color: $color-danger;
  }

  &-yellow {
    color: $color-warning;
  }
}
</style>
