<template>
  <span>
    <img v-if="images.carc1.indexOf(value.id) !== -1" src="../../../assets/img/trucks/carc1.png" />
    <img
      v-else-if="images.carc2.indexOf(value.id) !== -1"
      src="../../../assets/img/trucks/carc2.png"
    />
    <img
      v-else-if="images.carc3.indexOf(value.id) !== -1"
      src="../../../assets/img/trucks/carc3.png"
    />
    <img
      v-else-if="images.carc4.indexOf(value.id) !== -1"
      src="../../../assets/img/trucks/carc4.png"
      alt=" "
    />
    <img
      v-else-if="images.carc5.indexOf(value.id) !== -1"
      src="../../../assets/img/trucks/carc5.png"
    />
    <img v-else src="../../../assets/img/trucks/carc1.png" />
  </span>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      images: {
        carc1: ['carc1'],
        carc2: ['carc2'],
        carc3: ['carc4', 'carc5', 'carc6', 'carc7', 'carc8', 'carc9'],
        carc4: ['carc10', 'carc11', 'carc12', 'carc13'],
        carc5: ['carc3']
      }
    };
  }
};
</script>
