import { render, staticRenderFns } from "./ShipperDocumentsPinCode.vue?vue&type=template&id=5f1aa864&"
import script from "./ShipperDocumentsPinCode.vue?vue&type=script&lang=js&"
export * from "./ShipperDocumentsPinCode.vue?vue&type=script&lang=js&"
import style0 from "./ShipperDocumentsPinCode.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../cargo-newadmin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports