<template>
  <div v-if="isLoading" class="text-center">
    <v-progress-circular indeterminate="indeterminate" color="primary" />
  </div>
  <div v-else class="executor">
    <ContentBlock subtitle="ТС и водитель" subtitle-tag="h2">
      <v-layout row wrap>
        <v-flex xs9 class="position-relative">
          <custom-input :value="trucker.fullname" readonly label="Водитель" />
          <span class="select__status">{{ getVerifiedDate(trucker.verif_until) }}</span>
        </v-flex>
        <v-flex xs3>
          <custom-input class="no-border" value="Не ознакомлен" label="Статус" disabled />
        </v-flex>
        <v-flex xs12 class="position-relative">
          <custom-input :value="infoTS.name" readonly label="Транспортное средство" />
          <span class="select__status">{{ getVerifiedDate(infoTS.date) }}</span>
        </v-flex>
      </v-layout>
    </ContentBlock>

    <ContentBlock subtitle="Контакты" subtitle-tag="h2">
      <v-layout row wrap>
        <v-flex xs12 class="position-relative">
          <custom-input :value="contactInfo" readonly />
        </v-flex>
      </v-layout>
    </ContentBlock>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { YEAR_INFINITE } from 'common/helpers/dateEnums';
import lightFormat from 'date-fns/lightFormat';
import ContentBlock from 'common/components/Orders/ContentBlock';

import { transportApi } from 'common/api/TransportApi';

export default {
  name: 'DriverTS',
  components: {
    ContentBlock
  },
  data: () => ({
    isLoading: false,
    trucker: {},
    transport: {},
    userContact: {}
  }),
  computed: {
    ...mapState('orders', {
      info: state => state.orderInfo.info
    }),
    ...mapGetters('orders', ['contactInfo']),

    contactInfo() {
      return `${this.userContact.lastName || this.userContact.lastname} ${this.userContact
        .firstName || this.userContact.name} ${this.userContact.phone}, ${this.userContact.email}`;
    },

    infoTS() {
      return this.transport.type === 'autotrain'
        ? { name: this.transport.wagon.name, date: this.transport.wagon.verif_until }
        : {
            name: `Одиночное ТС ${this.transport.vehicle.brand_and_model} ${this.transport.vehicle.registration_plate}`,
            date: this.transport.vehicle.verif_until
          };
    }
  },
  async created() {
    this.isLoading = true;
    await this.getTsInfo();
    await this.getTruckerInfo();
    await this.getContact();
    this.isLoading = false;
  },
  methods: {
    ...mapActions('common', ['createController']),

    async getTsInfo() {
      try {
        const json = { cid: this.info.ctransport_id };
        this.transport = await transportApi(this.$store.dispatch).transportGet({ json });
      } catch (error) {
        console.error(error);
      }
    },

    async getTruckerInfo() {
      const topic = 'truckers/trucker/get';
      const json = { cid: this.info.ctrucker_id };
      try {
        const { trucker } = await this.createController({ topic, json });
        this.trucker = trucker;
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },

    async getContact() {
      const topic = 'profile/user/take';
      const json = { cid: this.info.ccontact_carrier_user_id };
      try {
        const { user } = await this.createController({ topic, json });
        this.userContact = user;
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },

    getVerifiedDate(date) {
      const isInfinite = new Date(date).getFullYear() === YEAR_INFINITE;
      if (isInfinite) return 'Верифицирован';
      return lightFormat(new Date(date), 'Верифицирован до dd.MM.yy');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';
.position-relative {
  position: relative;
}
.no-border {
  /deep/ .v-input__control > .v-input__slot:before {
    display: none !important;
  }
}

.select__status {
  color: $color-verified;
  padding: 0 12px 0 0;
  position: absolute;
  top: 27px;
  right: 30px;
}
</style>
