<template>
  <div class="transports_tab">
    <TableList
      v-if="isViewTableList"
      :loading="loading('admin/endusers/contractor/transport_means/freight_transport/details/get')"
      :values="freightTransportList"
      :show-columns="false"
      :columns="columns"
      @clickItem="getFullWagon"
    >
      <template slot="verify" slot-scope="{ row }">
        <div :class="[row.verifyClass]">
          {{ row.verify_auto_train }}
        </div>
      </template>
      <template slot="empty">
        <div>Нет автопоездов для отображения</div>
      </template>
    </TableList>
    <div v-else>
      <v-toolbar dark="dark" color="primary">
        <v-toolbar-title>
          {{ head.vehicle.brand_and_model }} {{ head.vehicle.registration_plate }},
          {{ tail.vehicle.brand_and_model }} {{ tail.vehicle.registration_plate }}
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon="icon" dark="dark" @click="isViewTableList = true">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <auto-train-part :data="head" is-operator />
      <auto-train-part :data="tail" is-operator />
      <auto-train-chr :data="{ tail, properties, wagon }" is-operator />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import AutoTrainChr from 'common/components/transport/AutoTrainChr.vue';
import AutoTrainPart from 'common/components/transport/AutoTrainPart.vue';
import TableList from '../../Layout/TableList.vue';

export default {
  components: {
    TableList,
    AutoTrainChr,
    AutoTrainPart
  },
  data() {
    return {
      isViewTableList: true,
      head: null,
      tail: null,
      properties: null,
      wagon: null
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('clientsTransports', ['freightTransportList']),
    enduser_id() {
      return this.$route.params.enduser || false;
    }
  },
  created() {
    this.columns = [
      {
        title: ' ',
        name: 'name',
        width: '70',
        alignLeft: true
      },
      {
        title: ' ',
        name: 'verify',
        width: '30',
        slot: true
      }
    ];
  },
  mounted() {
    this.getTrainList({
      enduser: this.enduser_id
    });
  },
  methods: {
    ...mapActions('clientsTransports', ['getTrainList']),
    ...mapActions('common', ['createController']),
    ...mapActions(['addSnackbar']),
    async getFullWagon(trainData) {
      try {
        const topic = 'admin/endusers/contractor/transport_means/freight_transport/details/get';
        const json = {
          id: trainData.vehicle
        };
        const response = await this.createController({ topic, json });
        this.wagon = response.wagon;
        this.properties = response.properties;
        this.tail = response.vehicles.tail;
        this.head = response.vehicles.head;
        this.isViewTableList = false;
      } catch ({ status: { reason, info } }) {
        this.isViewTableList = true;
        this.addSnackbar({ message: info || this.getError({ error: reason }), type: 'error' });
      }
    }
  }
};
</script>
