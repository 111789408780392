import { setField } from 'common/store/helpers';
import moment from 'common/helpers/date';

const state = {
  info: {},
  list: []
};

const actions = {
  async getInfo({ dispatch, commit }, json) {
    const topic = 'adm_buch/statement';
    commit('setField', { field: 'info', value: {} });
    const types = {
      verification: 'Верификация',
      refill: 'Пополнение',
      refund: 'Возврат',
      other: 'Прочее',
      carrier: 'Грузоперевозчик',
      sender: 'Грузовладелец'
    };
    try {
      const res = await dispatch('common/createController', { topic, json }, { root: true });
      res.data = res.data
        .map(item => {
          item.date = moment(item.creating_date).format('DD.MM.YYYY, HH:mm');
          item.type = types[item.type];
          item.category = types[item.organization_type];
          return item;
        })
        .sort((a, b) => new Date(b.creating_date) - new Date(a.creating_date));
      commit('setField', { field: 'info', value: res });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async getList({ dispatch, commit }, json) {
    const topic = 'adm_buch/payments';
    commit('setField', { field: 'list', value: [] });
    const types = {
      executed: 'Исполнена',
      created: 'Принята',
      canceled: 'Отменена',
      rejected: 'Не исполнена',
      timeout: 'Не исполнена по таймауту',

      refund: 'Возврат денежных средств',
      refill: 'Пополнение денежных средств',
      verification: 'Верификация',
      other: 'Прочее',

      carrier: 'Грузоперевозчик',
      sender: 'Грузовладелец'
    };
    try {
      const res = await dispatch('common/createController', { topic, json }, { root: true });
      const list = res.data
        .map(item => {
          item.status = types[item.status] || 'Неизвестно';
          item.type = types[item.type];
          item.organization_type = types[item.organization_type];
          item.date = moment(item.creating_date).format('DD.MM.YY, HH:mm');
          return item;
        })
        .sort((a, b) => new Date(b.creating_date) - new Date(a.creating_date));
      commit('setField', { field: 'list', value: list });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  }
};
const mutations = {
  setField
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
