<template>
  <div class="settings">
    <div class="settings__title">
      Настройка уведомлений
    </div>
    <div class="settings__items">
      <v-checkbox
        v-for="(value, key) in settings"
        :key="key"
        :input-value="value"
        :label="getLabel(key)"
        :name="key"
        disabled
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

const LABELSMAP = {
  push_notifications_enabled: 'Разрешить пуш-уведомления для водителя',
  email_notifications_enabled: 'Дублировать по e-mail',
  sms_notifications_enabled: 'Дублировать по СМС'
};

export default {
  name: 'TabSettings',
  computed: {
    ...mapState('clientsNotifications', ['settings'])
  },
  created() {
    this.getSettings();
  },
  methods: {
    ...mapActions('clientsNotifications', ['getSettings']),
    getLabel(key) {
      return LABELSMAP[key];
    }
  }
};
</script>

<style lang="scss" scoped>
.settings {
  &__title {
    font-weight: 400;
    font-size: 20px;
    padding: 10px 0;
  }

  &__items {
    display: flex;
    /deep/.v-input--checkbox .v-label {
      margin-top: 0;
    }
  }
}
</style>
