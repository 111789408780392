<template>
  <div class="window">
    <v-tabs v-model="tab">
      <v-tab href="#passport">
        Паспорт
      </v-tab>
      <v-tab href="#license">
        Водительское удостоверение
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="passport">
        <ImageSlider
          v-if="tab === 'passport'"
          :images="[passportPhoto, passportRegistrationPhoto]"
        />
      </v-tab-item>
      <v-tab-item value="license">
        <ImageSlider v-if="tab === 'license'" :images="[licensePhoto, licenseBackPhoto]" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ImageSlider from '@/components/common/ImageSlider';

export default {
  name: 'TruckerImages',
  components: {
    ImageSlider
  },
  props: {
    photos: {
      type: Array,
      default: () => []
    },
    cid: String
  },
  data() {
    return {
      tab: null,
      passportPhoto: '',
      passportRegistrationPhoto: '',
      licensePhoto: '',
      licenseBackPhoto: ''
    };
  },
  computed: {
    ...mapState({
      adminCid: state => state.login.cid,
      defaultURL: state => state.defaultURL
    })
  },
  watch: {
    photos() {
      this.filterAndSetPhotos();
    }
  },
  methods: {
    filterAndSetPhotos() {
      this.photos.forEach(_ => {
        if (_.type === 'passport_photo')
          this.passportPhoto = `${this.defaultURL}/files/${this.adminCid}/${this.cid}/${_.file}`;
        if (_.type === 'passport_registration')
          this.passportRegistrationPhoto = `${this.defaultURL}/files/${this.adminCid}/${this.cid}/${_.file}`;
        if (_.type === 'driver_license_photo')
          this.licensePhoto = `${this.defaultURL}/files/${this.adminCid}/${this.cid}/${_.file}`;
        if (_.type === 'driver_license_back')
          this.licenseBackPhoto = `${this.defaultURL}/files/${this.adminCid}/${this.cid}/${_.file}`;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.window {
  flex-grow: 1;
  height: 100%;
}
</style>
