import { request, subscribe, unsubscribe } from 'common/actions/mqttConnection';

const CONNECTED_ERROR = 'Client is not connected';

const state = {
  statuses: {},
  subscribes: []
};

const getters = {
  client(state, getters, rootState) {
    return rootState.connections.client;
  },

  cid(state, getters, rootState) {
    return rootState.login.cid;
  },

  loading: state => name => name && state.statuses[name] && state.statuses[name] === 'loading'
};

const actions = {
  async createController(
    { getters: { client, cid: userCid, loading }, state, commit },
    { topic, json = {}, cid = null, commitName = false }
  ) {
    if (!client?.connected) {
      throw new Error(CONNECTED_ERROR);
    }

    try {
      if (loading(topic)) {
        /* Prevent duplicate requests */
        await new Promise((resolve, reject) => {
          this.subscribe(() => {
            if (state.statuses[topic] === 'accepted') {
              resolve();
            } else if (state.statuses[topic] === 'error') {
              reject();
            }
          });
        });
      }

      commit('setResponseStatus', { topic, status: 'loading' });

      const response = await request(`/${cid || userCid}/${topic}`, json);

      commit('setResponseStatus', { topic, status: response.status.result });
      if (commitName) {
        commit(commitName, response, { root: true });
      }
      return response;
    } catch (error) {
      commit('setResponseStatus', { topic, status: 'error' });
      return Promise.reject(error);
    }
  },

  async subscribe(
    { getters: { client, cid }, commit, state },
    { topic, callback, withCid = true }
  ) {
    if (!client?.connected) {
      throw new Error(CONNECTED_ERROR);
    }

    const endpoint = withCid ? `/${cid}/${topic}` : `/${topic}`;

    try {
      await subscribe(endpoint, callback);
    } catch (error) {
      console.error(`Подписка ${topic} не удалась`, error);
    }
  },

  async unsubscribe(
    { getters: { client, cid }, commit, state },
    { topic, callback, withCid = true }
  ) {
    if (!client?.connected) {
      throw new Error(CONNECTED_ERROR);
    }

    const endpoint = withCid ? `/${cid}/${topic}` : `/${topic}`;

    try {
      await unsubscribe(endpoint);
    } catch (error) {
      console.error(`Отписка ${topic} не удалась`, error);
    }
  },

  closeSession({ getters: { client } }) {
    return client?.end();
  }
};

const mutations = {
  logout(state) {
    state.loading = {};
    state.subscribes = [];
  },

  setResponseStatus(state, { topic, status }) {
    if (topic && status) {
      const statuses = {
        I_SUCCESS: 'accepted',
        I_REJECT: 'error'
      };
      state.statuses = { ...state.statuses, [topic]: statuses[status] || status };
    }
  },

  setSubscribeTopic(state, { topic }) {
    state.subscribes = [...state.subscribes, topic];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
