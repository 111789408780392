<template>
  <span class="carcass-field">
    <icon :name="iconName" size="35" class="icon" />
    {{ value.name }}
    <TooltipAnswer v-if="value.comment_carrier" :message="value.comment_carrier" class="tooltip" />
  </span>
</template>
<script>
import { getCarcassIcon } from 'common/helpers';
import TooltipAnswer from 'common/components/TooltipAnswer.vue';

export default {
  name: 'CarcassField',
  components: { TooltipAnswer },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  created() {
    this.iconName = getCarcassIcon(this.value.id);
  }
};
</script>

<style lang="scss" scoped>
.carcass-field {
  display: flex;
  align-items: center;
}

.icon {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;

  /deep/ svg {
    flex-flow: 1;
    height: 100%;
  }
}
</style>
