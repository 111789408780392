<template>
  <div>
    <TableList
      v-if="isListType"
      :loading="loading(routeAuthorizeTS)"
      :values="TS_list"
      :columns="columns"
      :show-columns="false"
    >
      <template slot="verify" slot-scope="{ row }">
        <div :style="{ color: row.verifyColor }">
          {{ row.verify }}
        </div>
      </template>
      <template slot="empty">
        <div>Нет транспортных средств для отображения</div>
      </template>
    </TableList>
    <div v-else>
      {{ title }}
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import TableList from '../../Layout/TableList.vue';

export default {
  components: {
    TableList
  },
  props: {
    truckerId: Number
  },
  data() {
    return {
      columns: [
        {
          title: ' ',
          name: 'name',
          width: '70',
          alignLeft: true
        },
        {
          title: ' ',
          name: 'verify',
          width: '30',
          slot: true
        }
      ]
    };
  },
  computed: {
    ...mapState('clientsDrivers', ['TS_list', 'TS_type', 'routeAuthorizeTS']),
    ...mapGetters('common', ['loading']),

    enduser_id() {
      return this.$route.params.enduser;
    },

    isListType() {
      return this.TS_type === 'list';
    },

    title() {
      const statuses = {
        all: 'Все ТС',
        nothing: 'Нет авторизованных ТС'
      };

      return statuses[this.TS_type];
    }
  },
  mounted() {
    if (!this.isListType) {
      return;
    }

    this.getAuthorizeTS({
      trucker: this.truckerId,
      enduser: this.enduser_id
    });
  },
  methods: {
    ...mapActions('clientsDrivers', ['getAuthorizeTS'])
  }
};
</script>
