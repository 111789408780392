import { render, staticRenderFns } from "./TooltipsTable.vue?vue&type=template&id=4fcf1236&scoped=true&"
import script from "./TooltipsTable.vue?vue&type=script&lang=js&"
export * from "./TooltipsTable.vue?vue&type=script&lang=js&"
import style0 from "./TooltipsTable.vue?vue&type=style&index=0&id=4fcf1236&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fcf1236",
  null
  
)

export default component.exports