<template>
  <BodyWorksContentAbstraction
    :list="carcasses"
    :current-id="currentItem.id"
    :is-add-form="isAddForm"
    @add="addItem"
    @edit="editItem"
    @toggle-order="toggleOrder"
  >
    <template v-slot:head>
      <th>Иконка кузова</th>
      <th>Наименование кузова</th>
    </template>
    <template v-slot:body="{ item }">
      <td>
        <icon :name="getIcon(item.id)" />
      </td>
      <td>{{ item.name }}</td>
    </template>
    <template v-slot:form>
      <BodyWorksForm
        :is-edit-form="isEditForm"
        :is-add-form="isAddForm"
        :info="currentItem"
        @close="closeForm"
        @update="getScheme"
      />
    </template>
  </BodyWorksContentAbstraction>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getCarcassIcon } from 'common/helpers';
import BodyWorksForm from './forms/BodyWorksForm';
import BodyWorksContentAbstraction from './BodyWorksContentAbstraction';

export default {
  name: 'BodyWorks',
  components: {
    BodyWorksForm,
    BodyWorksContentAbstraction
  },
  data() {
    return {
      isAddForm: false,
      isEditForm: false,
      bodyWorksList: [],
      currentItem: {}
    };
  },
  computed: {
    ...mapState('carcasses', ['carcasses', 'currentVersionId'])
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions('carcasses', ['getScheme']),
    closeForm() {
      this.isAddForm = false;
      this.isEditForm = false;
      this.currentItem = {};
    },
    addItem() {
      this.currentItem = {};
      this.isAddForm = true;
    },
    editItem(item) {
      this.isAddForm = false;
      this.isEditForm = true;
      this.currentItem = item;
    },
    getIcon(id) {
      return getCarcassIcon(id);
    },
    async toggleOrder({ gid, direction }) {
      const topic = 'admin/administration/carcasses/types/pull';
      const json = { gid, direction, id: this.currentVersionId };
      try {
        await this.createController({ topic, json });
        this.getScheme();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    }
  }
};
</script>
