<template>
  <div :style="`width: ${width}px; height: ${height}px`" class="circular">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="22.857142857142858 22.857142857142858 45.714285714285715 45.714285714285715"
      style="transform: rotate(0deg);"
    >
      <circle
        :stroke-width="`${strokeWidth}`"
        fill="transparent"
        cx="45.714285714285715"
        cy="45.714285714285715"
        r="20"
        stroke-dasharray="125.664"
        stroke-dashoffset="125.66370614359172px"
        class="circular__overlay"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'PlainCircular',
  props: {
    width: {
      type: [String, Number],
      default: 23
    },
    height: {
      type: [String, Number],
      default: 23
    },
    strokeWidth: {
      type: [String, Number],
      default: 3.5
    }
  }
};
</script>

<style lang="scss" scoped>
.circular {
  position: relative;
  display: inline-flex;
  color: #fff;
  svg {
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    animation: progress-circular-rotate 1.4s linear infinite;
    transform-origin: center center;
    transition: all 0.2s ease-in-out;
  }
  &__overlay {
    animation: progress-circular-dash 1.4s ease-in-out infinite;
    stroke-linecap: round;
    stroke-dasharray: 80, 200;
    stroke-dashoffset: 0px;
    stroke: currentColor;
    z-index: 2;
    transition: all 0.6s ease-in-out;
  }
}

@keyframes progress-circular-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}
</style>
