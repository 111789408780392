<template>
  <div class="verification_view">
    <div class="verification_view__info">
      <v-text-field v-model="searchValue" label="Найти" />
      <v-icon class="verification_view__info__search">
        search
      </v-icon>
    </div>
    <TableList
      class="verification_view__table"
      :loading="loading('admin/verification/get_list')"
      :values="filteredList"
      :columns="columns"
      @clickItem="openWindow"
    />
    <VerificationWindow v-if="isVehicleWindow" />
    <VerificationWindowTrucker
      v-if="currentWindow.type === 'Водитель'"
      :parent-id="currentWindow.cparent_id"
      @close="closeWindow"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import TableList from '../Layout/TableList.vue';
import VerificationWindow from './window/VerificationWindow.vue';
import VerificationWindowTrucker from './VerificationWindow/VerificationWindowTrucker';

export default {
  components: {
    TableList,
    VerificationWindow,
    VerificationWindowTrucker
  },
  data() {
    return {
      searchValue: '',
      columns: [
        {
          title: 'Наименование',
          name: 'name',
          width: '55',
          alignLeft: true
        },
        { title: 'Тип аккаунта', name: 'type', width: '15' },
        { title: 'Дата и время запроса верификации', name: 'date', width: '15' },
        { title: 'Тип верификации', name: 'status', width: '15' }
      ],
      currentWindow: {}
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('verifications', ['items']),
    filteredList() {
      if (this.searchValue.trim()) {
        return this.items.filter(item => new RegExp(this.searchValue, 'ig').test(item.name));
      }
      return this.items;
    },
    isVehicleWindow() {
      return !!this.$route.params.vehicle_id;
    }
  },
  mounted() {
    this.subscribeVerification();
    this.getItems();
  },
  methods: {
    ...mapActions('verifications', ['getItems', 'nextItems', 'subscribeVerification']),
    openWindow(item) {
      if (item.type === 'Водитель') {
        this.currentWindow = item;
        return;
      }
      this.$router.push({
        name: 'VerificationInfo',
        params: { vehicle_id: item.cparent_id, type: item.parent_table }
      }); // TODO: need id not cid
    },
    closeWindow() {
      this.currentWindow = {};
      this.getItems();
    }
    // TODO врменное решение для водителя
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';
.verification_view {
  position: relative;
  height: 100%;
  .verification_view__info {
    height: 70px;
    display: flex;
    width: 30%;
    padding: 5px 0;
    input {
      padding-right: 25px;
    }
    .verification_view__info__search {
      height: 20px;
      margin-top: 23px;
      margin-left: -25px;
      cursor: pointer;
      &:hover {
        color: $color-main;
      }
    }
  }
  .verification_view__table {
    height: calc(100% - 70px);
  }
}
</style>
