<template>
  <v-layout
    v-if="showForm"
    v-model="validForm"
    row
    wrap
    tag="v-form"
    class="form"
    @submit.prevent="
      $emit('submit', {
        name: form.name,
        gid: form.gid,
        view: form.selectedSectionToView,
        modify: form.selectedSectionToModify
      })
    "
  >
    <v-flex xs12>
      <custom-input
        v-model="form.name"
        label="Название группы"
        :rules="checkNameGroup"
        @input="changeData()"
      />
    </v-flex>
    <v-flex xs12>
      <table class="table">
        <thead>
          <tr>
            <th>Раздел</th>
            <th>Доступ к разделу</th>
            <!-- <th>Модификация</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="section in sectionsGroupList" :key="section.sid">
            <td>{{ section.name }}</td>
            <td>
              <v-checkbox
                v-model="form.selectedSectionToView"
                :name="section.name"
                :value="`${section.name}_1`"
                :disabled="!isSuper || editableItem.name === 'root'"
                @change="changeData()"
              />
            </td>
            <!-- <td>
              <v-checkbox
                v-model="form.selectedSectionToModify"
                :name="section.name"
                :disabled="!isSuper || editableItem.name === 'root'"
                :value="`${section.name}_2`"
              />
            </td> -->
          </tr>
        </tbody>
      </table>
    </v-flex>
    <v-flex xs12>
      <custom-button :disabled="disabledButton" large color="primary" type="submit">
        {{ isEdit ? 'Сохранить' : 'Добавить' }}
      </custom-button>
      <custom-button
        v-if="isEdit"
        large
        color="primary"
        type="button"
        :disabled="!isSuper"
        @click="$emit('delete', form.gid)"
      >
        Удалить
      </custom-button>
      <custom-button outline large color="primary" @click="$emit('close')">
        Отмена
      </custom-button>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';

const setSectionsSettings = (items = [], type) => {
  if (!items.length) return items;
  const reducedItems = items.reduce((acc, item) => {
    if ((item.permission === 1 || item.permission === 3) && type === 'view') {
      acc.push(`${item.name}_1`);
    }
    if ((item.permission === 2 || item.permission === 3) && type === 'modify') {
      acc.push(`${item.name}_2`);
    }
    return acc;
  }, []);
  return reducedItems;
};
const createForm = (info = {}) => ({
  name: info?.name ?? '',
  gid: info?.gid ?? '',
  selectedSectionToView: setSectionsSettings(info.sections, 'view'),
  selectedSectionToModify: setSectionsSettings(info.sections, 'modify')
});
export default {
  name: 'GroupsForm',
  props: {
    showForm: Boolean,
    sectionsGroupList: {
      type: Array,
      default: () => []
    },
    editableItem: {
      type: Object,
      default: () => ({})
    },
    listNameGroups: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      validForm: false,
      form: createForm(),
      isChanged: false,
      checkNameGroup: [
        v => !!v || 'Поле должно быть заполненно.',
        v =>
          !this.listNameGroups.find(name => {
            if (this.isEdit && v.toLowerCase() === this.editableItem.name.toLowerCase()) {
              return false;
            }
            return name.toLowerCase() === v.toLowerCase();
          }) || 'Группа с таким именем уже есть.'
      ]
    };
  },
  computed: {
    ...mapState('login', ['isSuper']),
    isEdit() {
      return !!Object.keys(this.editableItem).length;
    },
    valueCheck() {
      return !Object.keys(this.form.selectedSectionToView).length;
    },
    disabledButton() {
      if (this.isEdit) {
        return !this.isSuper || !this.isChanged;
      }
      return !this.isSuper || !this.isChanged || !this.validForm;
    }
  },
  watch: {
    isEdit(value) {
      if (value) this.form = createForm(this.editableItem);
      else this.form = createForm();
    }
  },
  methods: {
    changeData() {
      this.isChanged = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';
@include table(33.33%);

.form {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}
/deep/.v-input--checkbox {
  margin: 10px 0 0 0;
  padding: 0;
}
</style>
