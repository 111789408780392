<template>
  <tabs class="stage_transports" :tabs="tabs" :active="currentTab" />
</template>

<script>
import { mapActions } from 'vuex';
import Tabs from '../../Layout/Tabs.vue';
import TransportsTab from './TransportsTab.vue';
import AutoTrainsTab from './AutoTrainsTab.vue';

export default {
  name: 'Tranports',
  components: {
    Tabs
  },
  data() {
    return {
      currentTab: this.$route.params.tab || 'means',
      tabs: [
        { name: 'means', component: TransportsTab, title: 'Транспортные средства' },
        { name: 'trains', component: AutoTrainsTab, title: 'ТС-автопоезда' }
      ]
    };
  },
  computed: {
    stage() {
      return this.$route.params.stage;
    }
  },
  mounted() {
    this.getScheme();
  },
  methods: {
    ...mapActions('transportData', ['getScheme'])
  }
};
</script>

<style lang="scss">
.stage_transports {
  background-color: white;
  position: relative;
  .v-text-field__slot {
    input {
      font-size: 15px;
    }
  }
}
</style>
