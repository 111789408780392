<template>
  <div class="snackbars">
    <v-snackbar
      v-for="(snackbar, index) in snackbars"
      ref="snackbars"
      :key="snackbar.uid"
      :value="!!snackbar.message"
      :color="getCurrent(snackbar.type).color"
      :class="`snackbar snackbar--${snackbar.type}`"
      :style="{ bottom: `${getBottomPosition(index)}px` }"
      bottom
      right
      @input="removeSnackbar(snackbar.uid)"
    >
      <icon :name="getCurrent(snackbar.type).icon" size="24" />
      <span class="snackbar-message">
        {{ snackbar.message }}
      </span>
      <icon class="close-icon" name="close" size="24" @click="removeSnackbar(snackbar.uid)" />
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

const SNACKBAR_COLORS = {
  error: {
    color: '#ffebee',
    icon: 'alertCircleOutline'
  },
  success: {
    color: '#e8f5e9',
    icon: 'checkboxMarkedCircleOutline'
  },
  news: {
    color: '#e1f5fe',
    icon: 'informationOutline'
  },
  warning: {
    color: '#fff3e0',
    icon: 'alertCircleOutline'
  },
  info: {
    color: '#f6f6f6',
    icon: 'informationOutline'
  }
};

export default {
  name: 'Snackbar',
  computed: {
    ...mapState(['showSnackbar', 'snackbars'])
  },
  methods: {
    ...mapActions(['removeSnackbar']),
    getCurrent(type) {
      if (!type) return SNACKBAR_COLORS.error;
      if (!Object.keys(SNACKBAR_COLORS).includes(type)) {
        console.warn('Wrong snackbar type');
        return SNACKBAR_COLORS.error;
      }
      return SNACKBAR_COLORS[type];
    },
    getBottomPosition(index) {
      if (!index) return 0;
      return (
        this.$refs.snackbars[index - 1].$el.offsetHeight + this.getBottomPosition(index - 1) + 10
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.snackbars {
  position: relative;
  z-index: 999999;
}

/deep/ .v-snack__content {
  height: auto;
}

.icon {
  flex-shrink: 0;
}

.close-icon {
  cursor: pointer;
}

.snackbar {
  &--error {
    color: $color-error;
  }

  &--success {
    color: $color-success;
  }

  &--news {
    color: $color-secondary;
  }

  &--warning {
    color: $color-warning;
  }

  &--info {
    color: $gray-darken;
  }

  &-message {
    flex-grow: 1;
    padding: 0 12px;
  }
}

.v-snack-transition {
  &-enter-active,
  &-leave-active {
    transition: transform 0.4s opacity 0.2s;
    .v-snack__content {
      transition: opacity 0.3s linear 0.1s;
    }
  }
  &-enter {
    .v-snack__content {
      opacity: 0;
    }
  }
  &-enter-to,
  &-leave {
    .v-snack__content {
      opacity: 1;
    }
  }
  &-enter,
  &-leave-to {
    &.v-snack.v-snack--bottom {
      transform: translateX(40%);
      opacity: 0.3;
    }
  }
}
</style>
