<template>
  <table class="table">
    <thead>
      <tr>
        <th>Имя группы</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="group in groups" :key="group.gid" @click.stop="$emit('edit', group.gid)">
        <td>{{ group.name }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'GroupsTable',
  props: {
    groups: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';
@include table(100%, $hover: true);
</style>
