<template>
  <icon :class="[{ colorMain: !disabled }, iconClass]" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  name: 'IconTooltip',
  props: {
    disabled: Boolean,
    iconClass: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.icon {
  margin: 0 0 0 10px;
  color: $gray-light;
}

.colorMain {
  color: $color-main;
}
</style>
