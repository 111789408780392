<template>
  <div ref="swiper" tabindex="0" class="swiper-dialog" @keyup="handleKeys">
    <icon name="close" size="40" class="close-icon" @click="$emit('close', false)" />
    <div class="slide-wrapper">
      <div v-if="imagesArray.length > 1" class="arrows">
        <icon
          name="chevronLeft"
          size="48"
          :disabled="!imageIndex"
          class="arrows__prev"
          icon
          @click="--imageIndex"
        />
        <icon
          :disabled="imageIndex === imagesArray.length - 1"
          class="arrows__next"
          icon
          name="chevronRight"
          size="48"
          @click="++imageIndex"
        />
      </div>
      <img :src="imagesArray[imageIndex][urlField]" alt="" class="slide" />
    </div>
    <footer class="footer">
      <div class="preview-wrapper">
        <img
          v-for="(item, i) in imagesArray"
          :key="item[urlField]"
          :class="['preview', { active: i === imageIndex }]"
          :src="item[urlField]"
          @click="imageIndex = i"
        />
      </div>
    </footer>
  </div>
</template>

<script>
import { replaceHandlers, revertHandlers } from 'common/helpers/swipeHandler';

let windowComponent = null;
let headerLine = null;

export default {
  name: 'SwiperDialog',
  props: {
    images: {
      type: Array,
      default: () => []
    },
    urlField: {
      type: String,
      default: 'src'
    },
    urlArray: {
      type: Array,
      default: () => []
    },
    slideIndex: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    imageIndex: null
  }),
  computed: {
    imagesArray() {
      return this.urlArray.length ? this.urlArray.map(_ => ({ [this.urlField]: _ })) : this.images;
    }
  },
  watch: {
    slideIndex: {
      immediate: true,
      handler(val) {
        this.imageIndex = val;
      }
    }
  },
  mounted() {
    windowComponent = document.getElementById('windowComponent');
    headerLine = document.getElementById('header-line');
    replaceHandlers({
      rightHandler: e => {
        if (e.target.closest('.footer')) return;
        if (this.imageIndex) this.imageIndex -= 1;
      },
      leftHandler: e => {
        if (e.target.closest('.footer')) return;
        if (this.imageIndex < this.imagesArray.length - 1) this.imageIndex += 1;
      }
    });
    if (headerLine) headerLine.classList.add('hidden');
    if (windowComponent) windowComponent.classList.add('hidden-y');
    else document.documentElement.classList.add('hide-overflow');
    this.$refs.swiper.focus();
  },
  beforeDestroy() {
    revertHandlers();
    if (windowComponent) windowComponent.classList.remove('hidden-y');
    else document.documentElement.classList.remove('hide-overflow');
    if (headerLine) headerLine.classList.remove('hidden');
  },
  methods: {
    handleKeys({ keyCode }) {
      if (keyCode === 37 && this.imageIndex) --this.imageIndex;
      else if (keyCode === 39 && this.imageIndex < this.imagesArray.length - 1) ++this.imageIndex;
      else if (keyCode === 27) this.$emit('close', false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.swiper-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  z-index: 99999999999;
  display: flex;
  flex-direction: column;
  outline: none;

  .arrows {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /deep/ .icon {
      position: relative;

      svg {
        cursor: pointer;
        position: relative;
        z-index: 1;
        &:hover {
          fill: $color-main;
        }
      }
    }
    &__next[disabled],
    &__prev[disabled] {
      pointer-events: none;
    }
  }

  .slide {
    margin: auto;
    max-height: calc(100vh - 200px);
    max-width: 100%;
    object-fit: contain;

    &-wrapper {
      flex-grow: 1;
      display: flex;
      position: relative;
    }
  }

  .close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
    cursor: pointer;
    &:hover {
      fill: $color-main;
    }
  }

  .footer {
    @include create-custom-scroll();

    width: 100%;
    padding: 10px 0;
    display: flex;
    overflow: auto;

    .preview {
      object-fit: cover;
      width: 100px;
      height: 100px;
      margin: 0 5px;
      cursor: pointer;

      &-wrapper {
        display: flex;
        margin: 0 auto;
        justify-content: center;
      }

      &.active {
        border: 2px solid white;
        pointer-events: none;
      }
    }
  }
}
</style>
