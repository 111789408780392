<template>
  <div>
    <v-toolbar dark="dark" color="primary">
      <v-toolbar-title>
        Поиск заказа
      </v-toolbar-title>
      <v-spacer />
      <custom-button
        icon="icon"
        color="white"
        class="verification-window__close"
        @click="$emit('close')"
      >
        <v-icon>close</v-icon>
      </custom-button>
    </v-toolbar>
    <find-order-item :item="currentOrder(currentOrderFindID)" inactive is-open is-operator />
    <div>
      <custom-button
        v-if="(searchOrders.length && !favor) || favor"
        round
        flat
        color="primary"
        class="toggle-favors"
        @click="toggleFavors"
      >
        <icon :name="favor ? 'starOutline' : 'star'" size="24" />
        {{ favor ? 'Показать все заказы' : 'Показать избранные заказы' }}
      </custom-button>
      <v-progress-circular
        v-if="isLoading && !searchOrders.length"
        indeterminate
        color="primary"
        :size="40"
        class="spinner"
      />
      <template v-else-if="searchOrders.length">
        <OfferOrderItem
          v-for="(order, index) in searchOrders"
          :key="index"
          :item="order"
          @click="$emit('getCid', order.cid)"
        />
      </template>
      <template v-else-if="favor">
        <alert message="Нет избранных заказов" class="alert" type="neutral" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Alert from 'common/components/Alert.vue';
import FindOrderItem from 'common/components/Orders/FindOrderItem';
import OfferOrderItem from 'common/views/OrderView/OfferOrder/OfferOrderItem.vue';

import { SEARCH_ORDER_ORDERS_LIST } from 'common/api/SearchOrderApi';
import { TRANSPORT_SCHEME, TRANSPORT_VERSION } from 'common/api/TransportApi';

export default {
  name: 'FindOrderViewAdmin',
  components: {
    FindOrderItem,
    OfferOrderItem,
    Alert
  },
  computed: {
    ...mapState('ordersFind', ['searchOrders', 'favor', 'currentOrderFindID']),
    ...mapGetters('common', ['loading']),
    ...mapGetters('ordersFind', ['currentOrder', 'isInactiveOrder']),
    isLoading() {
      return (
        this.loading(SEARCH_ORDER_ORDERS_LIST) ||
        this.loading(TRANSPORT_VERSION) ||
        this.loading(TRANSPORT_SCHEME)
      );
    }
  },
  async mounted() {
    await this.getOrdersList();
    this.getSerachOrdersList();
  },
  methods: {
    ...mapActions('ordersFind', ['getOrdersList', 'getSerachOrdersList', 'toggleFavors'])
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.alert {
  width: 100%;
  margin: 0 0 16px 0;
}

.sms {
  margin-left: auto;
  svg {
    fill: currentColor;
  }
}

.icon {
  margin-right: 0;
}
.toggle-favors {
  width: auto;
  align-self: flex-start;
  margin: 0 0 16px 8px;
}

.spinner {
  align-self: center;
  margin: 24px 0;
}

.header-wrapper {
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: breakpoint(md)) {
    padding: 0 4px;
  }
  @media only screen and (max-width: breakpoint(sm)) {
    padding: 0 7px 0 16px;
  }
}

@media only screen and (max-width: breakpoint(sm)) {
  .add-find {
    margin: 8px 0;
  }
  .cancel-add-find {
    margin: 8px 12px;
  }
}
</style>
