<template>
  <Tabs :tabs="tabsList" active="Opened" />
</template>

<script>
import Tabs from '@/components/Layout/Tabs';
import DisputeWrapper from './DisputeWrapper.vue';

export default {
  name: 'Disputes',
  components: {
    Tabs
  },
  created() {
    this.tabsList = [
      { name: 'Opened', component: DisputeWrapper, title: 'Открытые', options: { type: 'opened' } },
      {
        name: 'Active',
        component: DisputeWrapper,
        title: 'Активные',
        options: { type: 'devoid_and_my' }
      },
      {
        name: 'Closed',
        component: DisputeWrapper,
        title: 'Завершенные',
        options: { type: 'closed' }
      }
    ];
  }
};
</script>
