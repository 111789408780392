<template>
  <div
    v-if="loading('admin/endusers/profile/organization/get') || !organization.org_info"
    class="loading"
  >
    <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
  </div>
  <safety
    v-else
    class="stage-safety"
    :organization-data="organization.org_info"
    :semaphores="semaphores"
  />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Safety from 'common/components/Safety';
import utils from '@/helpers/utils';

export default {
  components: { Safety },
  data: () => ({
    utils
  }),
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('clientsProfile', ['organization', 'semaphores'])
  },
  mounted() {
    this.getOrganization();
    this.getSemaphore();
  },
  methods: {
    ...mapActions('clientsProfile', ['getOrganization', 'getSemaphore'])
  }
};
</script>

<style lang="scss" scoped>
.stage-safety.container-svetofor {
  padding: 30px !important;
  overflow-y: auto;
  height: 100%;
}
</style>
