import { setField } from 'common/store/helpers';

const state = {
  prices: null
};

const actions = {
  async getPrices({ dispatch, commit }) {
    const topic = 'tariffs/prices';
    try {
      const { prices } = await dispatch('common/createController', { topic }, { root: true });
      commit('setField', { field: 'prices', value: prices });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async updatePricesData({ state, dispatch, commit }, { field, value }) {
    try {
      const topic = 'tariffs/prices/save';
      const json = { prices: { ...state.prices } };
      json.prices[field].value = value;
      await dispatch('common/createController', { topic, json }, { root: true });
      commit('updatePricesData', { field, value });
    } catch (error) {
      console.error(error);
    }
  },
  async updateTariffs({ state, dispatch, commit }, { tariffs }) {
    try {
      const topic = 'tariffs/prices/save';
      const json = { prices: { ...state.prices, tariffs } };
      await dispatch('common/createController', { topic, json }, { root: true });
      commit('updateTariffs', { tariffs });
    } catch (error) {
      console.error(error);
    }
  }
};

const mutations = {
  setField,
  updatePricesData(state, { field, value }) {
    state.prices[field].value = value;
  },
  updateTariffs(state, { tariffs }) {
    state.prices.tariffs = tariffs.map(tariff => ({ ...tariff }));
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
