<template>
  <div class="">
    <custom-button
      v-if="!(isOperator || isPinView) && !isShipperFl"
      :disabled="isOrderEnded"
      round
      flat
      color="primary"
      class="button-pin"
      @click="$emit('toggle-form', true)"
    >
      <icon name="fileSend" size="24" />
      Отправить PIN-код
    </custom-button>

    <content-block
      v-if="showFormPin"
      :subtitle-tooltip="getHint('send_pin')"
      class="form-pin"
      subtitle-tag="h2"
      subtitle="Отправить PIN-коды"
    >
      <template v-for="(point, i) in slicedPointsList">
        <ShipperDocumentsFormPin
          :key="i"
          :index="i"
          :is-open="currentPinForm === i"
          :info-point="point.point"
          :show-alert="showAlert"
          :class="{ hidden: currentPinForm !== null && currentPinForm !== i }"
          @update-data-pin="pointsPin = $event"
          @no-errors="showAlert = false"
          @open="currentPinForm = $event"
        />
      </template>

      <div class="send-pin">
        <custom-button
          :loading="loading('order/pin/new')"
          :disabled="currentPinForm === null"
          large
          round
          class="ml-0"
          color="white"
          @click="sendPin"
        >
          Отправить PIN-код
        </custom-button>

        <custom-button flat color="primary" @click="closeFormPin">
          {{ currentPinForm === null ? 'Закрыть' : 'Отмена' }}
        </custom-button>
      </div>
    </content-block>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import ShipperDocumentsFormPin from 'common/views/OrderView/OrderDocuments/ShipperDocuments/ShipperDocumentsFormPin';
import ContentBlock from 'common/components/Orders/ContentBlock';

export default {
  name: 'ShipperDocumentsPinCode',
  components: {
    ContentBlock,
    ShipperDocumentsFormPin
  },
  props: {
    isOperator: Boolean,
    isPinView: Boolean,
    isShipperFl: Boolean,
    isOrderEnded: Boolean,
    showFormPin: Boolean
  },
  data() {
    return {
      currentPinForm: null,
      showAlert: false,
      pointsPin: {}
    };
  },
  computed: {
    ...mapState('orders', {
      currentOrderCid: state => state.orderInfo.info.cid,
      pointsList: state => state.orderInfo.info.route
    }),
    ...mapGetters('common', ['loading']),
    ...mapGetters('hints', ['getHint']),

    slicedPointsList() {
      return this.pointsList.slice(0, this.pointsList.length - 1);
    }
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions(['addSnackbar']),

    async sendPin() {
      if (this.pointsPin.error) {
        this.showAlert = true;
        return;
      }

      const topic = 'order/pin/new';

      const json = {
        ...this.pointsPin,
        point: this.currentPinForm + 1,
        cid: this.currentOrderCid
      };

      try {
        await this.createController({ topic, json });
        this.currentPinForm = null;
        this.closeFormPin();
        this.addSnackbar({ message: 'PIN-код отправлен', type: 'success' });
        this.$emit('update-pin-list');
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
        this.addSnackbar({ message: 'Ошибка на сервере' });
      }
    },

    closeFormPin() {
      if (this.currentPinForm === null) {
        this.$emit('toggle-form', false);
        return;
      }

      this.currentPinForm = null;
      this.pointsPin = {};
    }
  }
};
</script>

<style lang="scss">
@import '~common/assets/styles/variables.scss';

.button-pin {
  margin: 0 0 0 -2px;

  @media only screen and (max-width: breakpoint(sm)) {
    margin: 0 0 0 12px;
  }
}

.form-pin {
  margin-top: 8px;
  margin-bottom: 0;
}

.send-pin {
  padding: 0 12px;
}
</style>
