<template>
  <div
    class="item"
    :class="{
      suggestion: isInactiveOrder(item.id) ? false : item.amount_orders_new,
      inactive: isInactiveOrder(item.id),
      'is-open': isOpen,
      disabled
    }"
  >
    <div class="item__wrapper" @click="onClick">
      <div class="item__actions" :class="{ 'pt-8px': isInactiveOrder(item.id) }">
        <div class="item__info">
          <b class="item__create-date">Создан {{ createdDate }}</b>
          <b class="item__name">
            {{ item.name }}
          </b>
        </div>
        <div class="item__actions__buttons" :class="{ hide: isOpen }">
          <icon-tooltip
            v-if="!isInactiveOrder(item.id)"
            :name="item.sms ? 'sms' : 'smsInActive'"
            size="18"
            message="СМС уведомления"
            class="sms ma-0 ml-1"
            @click="onClickSms"
          />
          <icon-tooltip
            name="pencil"
            size="16"
            class="edit ma-0 ml-1"
            message="Редактировать поиск"
            @click="clickEdit"
          />
          <icon-tooltip
            name="deleteIcon"
            size="18"
            message="Удалить поиск"
            class="delete-btn ma-0 ml-1"
            @click="onDelete"
          />
        </div>
      </div>
      <v-layout row wrap>
        <v-flex xs12 sm2>
          <div class="item__field">
            <span class="item__field__label">
              Дата погрузки
            </span>
            <p class="item__field__text">
              {{ shippingDate }}
            </p>
          </div>
        </v-flex>
        <v-flex xs12 sm4>
          <div class="item__field">
            <span class="item__field__label">
              Пункт отправления
            </span>
            <p class="item__field__text">
              {{ getPath(item.region_first && item.region_first[0]) }}
            </p>
          </div>
        </v-flex>
        <v-flex xs12 sm4>
          <div class="item__field">
            <span class="item__field__label">
              Пункт назначения
            </span>
            <p class="item__field__text">
              {{ getPath(item.region_last && item.region_last[0]) }}
            </p>
          </div>
        </v-flex>
        <v-flex xs12 sm2>
          <div class="item__field right">
            <span class="item__field__label">
              Попутный груз
            </span>
            <p class="item__field__text">
              <span v-show="item.multiple === 'yes'">
                Да
              </span>
              <span v-show="item.multiple === 'no'">
                Нет
              </span>
              <span v-show="item.multiple === 'no matter'">
                Неважно
              </span>
            </p>
          </div>
        </v-flex>
        <v-flex xs12 sm6>
          <div class="item__field">
            <span class="item__field__label">
              Транспортное средство
            </span>
            <p class="item__field__text">
              {{ item.transport_name }}
            </p>
          </div>
        </v-flex>
        <v-flex xs12 sm6>
          <div class="item__field right">
            <span class="item__field__label">
              Заказы
            </span>
            <p class="item__field__text">
              {{ isFeatured ? item.amount_orders_current : 0 }}
              <span v-if="item.amount_orders_new && isFeatured" class="item__field__new-orders">
                (+{{ item.amount_orders_new }})
              </span>
            </p>
          </div>
        </v-flex>
      </v-layout>
    </div>

    <transition name="small-fade">
      <DisableSmsNotificationModal
        v-if="showDisabledSmsDialog"
        @close="showDisabledSmsDialog = false"
        @submit="toggleSms"
      />
    </transition>
  </div>
</template>

<script>
import lightFormat from 'date-fns/lightFormat';
import { mapState, mapGetters, mapActions } from 'vuex';
import IconTooltip from '@/components/common/IconTooltip';
import states from 'common/helpers/states';
import ROUTES from '@/utils/routes';

const DisableSmsNotificationModal = () =>
  import('common/components/Orders/FindOrderModals/DisableSmsNotificationModal');

export default {
  components: {
    IconTooltip,
    DisableSmsNotificationModal
  },
  props: {
    isOperator: Boolean,
    item: {
      type: Object,
      default: () => ({})
    },
    isOpen: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      showDisabledSmsDialog: false
    };
  },
  computed: {
    ...mapState('login', ['isTester']),
    ...mapGetters('ordersFind', ['isInactiveOrder']),

    createdDate() {
      return lightFormat(new Date(this.item?.date_created || null), 'dd.MM.yyyy');
    },

    shippingDate() {
      return `
        ${lightFormat(new Date(this.item?.date_begin || null), 'dd.MM.yyyy')} - 
        ${lightFormat(new Date(this.item?.date_end || null), 'dd.MM.yyyy')}`;
    },

    isFeatured() {
      return this.$featured || this.isTester || this.isOperator;
    }
  },
  created() {
    this.viewRoute = { name: ROUTES.ORDERS_FINDORDER_VIEW, params: { id: this.item.id } };
    this.editRoute = { name: ROUTES.ORDERS_FINDORDER_EDIT, params: { id: this.item.id } };
  },
  methods: {
    ...mapActions('ordersFind', ['toggleSmsItem', 'deleteSavedItem']),
    ...mapActions(['addSnackbar']),

    async toggleSms() {
      const sms = !this.item.sms;

      try {
        await this.toggleSmsItem({ sms, cid: this.item.cid });
        this.addSnackbar({
          message: `СМС уведомления по поиску ${sms ? 'включены' : 'выключены'}`,
          type: 'success'
        });
        this.$emit('update-list');
      } catch (error) {
        this.addSnackbar({
          message: `Ошибка ${sms ? 'включения' : 'выключения'} СМС уведомлений`,
          type: 'error'
        });
      }
    },

    async onDelete() {
      await this.deleteSavedItem(this.item.cid);
      this.$emit('update-list');
    },

    onClick() {
      if (this.isOperator) {
        return;
      }

      this.$router.push(this.viewRoute).catch(() => {});
    },

    clickEdit() {
      if (this.isOperator) {
        return;
      }

      this.$router.push(this.editRoute).catch(() => {});
    },

    getPath(id) {
      return states.find(_ => _.regioncode === id)?.fullname ?? '';
    },

    onClickSms() {
      if (this.item.sms) {
        this.showDisabledSmsDialog = true;
        return;
      }

      this.toggleSms();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.item {
  margin: 0 -12px 16px;
  padding: 16px 0 0;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 16px 0px rgba(17, 17, 17, 0.16);
  }

  &.disabled {
    &:hover {
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    }

    .item__wrapper {
      cursor: default;
    }
  }

  &__wrapper {
    cursor: pointer;
  }

  &.is-open {
    margin: 0 0 16px;
  }
  &.completed {
    background-color: #f2fff2;
  }
  &.inactive {
    p,
    span:not(.icon) {
      color: #b9b9b9;
    }
  }
  &__field {
    &__label {
      display: block;
      margin: 0 0 4px 0;
      font-size: 12px;
      color: #8b8b8b;
    }
    &__new-orders {
      color: #009ce9;
    }
    &.right {
      p,
      span {
        text-align: right;
      }
    }
  }
  &__name {
    margin-left: 20px;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    min-height: 30px;
    margin: 0 12px;

    &__buttons {
      &.hide {
        display: none;
      }
      i {
        font-size: 18px;
      }
    }
  }
}

.delete-btn {
  margin-right: 0;
}

.sms {
  margin-left: 0;
  svg {
    fill: #009ce9;
  }
  &.disabled {
    svg {
      fill: #b9b9b9;
    }
  }
}

.sms,
.delete-btn,
.edit {
  margin-top: -8px;
}

@media screen and (max-width: breakpoint(md)) {
  .item {
    padding-top: 8px;
    &.is-open {
      margin: 0 -8px 16px;
    }
  }
}

@media screen and (max-width: breakpoint(sm)) {
  .pt-8px {
    padding-top: 8px;
  }
  .item {
    margin: 0 0 16px;
    &.is-open {
      margin: 0 0 16px;
    }
    &__info {
      display: flex;
      flex-direction: column;
    }
    &__name {
      margin: 6px 0 12px;
    }
    &__field {
      &__text {
        margin-bottom: 20px;
      }
      &.right {
        float: left !important;
        p,
        span {
          text-align: left;
        }
      }
    }
    &__actions__buttons {
      margin-top: 8px;
    }
  }
}
</style>
