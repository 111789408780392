<template>
  <div :class="`chat-item chat-item--${message.type === 'system' ? 'system' : messageType}`">
    <div class="chat-item__author">
      <v-avatar v-if="message.author && message.author.avatar" size="28px">
        <img src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460" alt="" />
      </v-avatar>
      <span v-if="message.author" class="chat-item-author__name">
        {{ message.type !== 'users' ? message.author.name : '' }}
      </span>
      <span v-if="message.date && message.type !== 'system'" class="chat-item-author__timestamp">
        {{ message.date.format('DD MMM[.,] HH:mm') }}
      </span>
    </div>
    <div class="chat-item__message">{{ message.text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: [Object, String],
      default: ''
    },
    type: {
      type: String,
      default: 'users'
    }
  },
  computed: {
    messageType() {
      return this.message.type === this.type || this.message.type === 'me' ? 'left' : 'right';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/main.scss';

.chat-item {
  &__message {
    background: #e9ecf2;
    padding: 10px 16px;
    border-radius: 0 24px 24px;
    word-wrap: break-word;
    margin: 5px 0 10px;
  }

  &--system {
    font-size: 14px;
    font-weight: 400;
    min-height: 33px;
    margin: 16px 0;

    .chat-item__message {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border-radius: 0;
      padding: 0;
      color: #8b8b8b;
      margin: 0;
      text-align: center;
      border-top: 1px solid #e9ecf2;
      border-bottom: 1px solid #e9ecf2;
      line-height: 1.2;
    }
  }
  .chat-item-author__timestamp {
    font-size: 12px;
    margin: 0 3px;
    color: #8b8b8b;
  }
  &--right {
    align-self: flex-start;

    .chat-item__message {
      border-radius: 0px 24px 24px 24px;
    }

    .chat-item__author {
      text-align: left;
      line-height: 12px;
      .chat-item-author__name {
        color: #000000;
        font-size: 12px;
      }
    }
  }
  &--left {
    align-self: flex-end;

    .chat-item__message {
      background: #009ce9;
      color: white;
      border-radius: 24px 0 24px 24px;
    }

    .chat-item__author {
      text-align: right;
      line-height: 12px;
    }
  }
}
</style>
