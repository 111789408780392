<template>
  <v-app>
    <div class="admin">
      <div v-if="isShowError" class="admin__alert">
        <v-alert type="error" :value="true">
          {{ errors[index] }}
        </v-alert>
      </div>
      <div v-if="isConnected" class="admin__layout">
        <div class="admin__toolbar">
          <v-toolbar clipped-left="clipped-left">
            <v-toolbar-side-icon @click.stop="toggleMenu" />
            <v-toolbar-title>CarGoAdmin</v-toolbar-title>
            <v-spacer />
            <p class="header-email">{{ emailUser }}</p>
            <v-toolbar-side-icon @click="logout">
              Выход
            </v-toolbar-side-icon>
          </v-toolbar>
        </div>
        <div class="admin__container">
          <div class="admin__navigation" :class="{ drawer: isShowMenu }">
            <app-menu :is-show-menu="isShowMenu">
              <v-footer><span>&copy; 2017-2018</span></v-footer>
            </app-menu>
            <div class="navigation__icons" />
          </div>
          <div class="admin__content">
            <router-view />
          </div>
          <div v-if="showChat" class="admin__chat">
            <app-chat />
          </div>
        </div>
      </div>
      <div v-else class="admin__layout">
        <app-login />
      </div>
      <snackbar />
    </div>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Snackbar from 'common/components/Snackbar';
import AppMenu from './Layout/Menu';
import AppChat from './Layout/Chat/ChatView';
import AppLogin from './Auth/Login';

export default {
  name: 'App',
  components: {
    AppMenu,
    AppChat,
    AppLogin,
    Snackbar
  },
  data() {
    return {
      isShowMenu: true,
      index: -1,
      isShowError: false,
      timeout: null
    };
  },
  computed: {
    ...mapState({
      menuIsLoaded: state => state.login.sections.length !== 0,
      isConnected: state => state.login.authorized && state.connections.client.connected,
      errors: state => state.errors,
      emailUser: state => state.login.emailUser,
      showChat: state => state.login.showChat
    })
  },
  watch: {
    errors() {
      this.showError();
    }
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    ...mapActions('login', ['logout']),
    ...mapActions('settings', ['getSettings']),
    toggleMenu() {
      this.isShowMenu = !this.isShowMenu;
    },
    showError() {
      if (this.timeout) {
        return;
      }
      this.isShowError = true;
      this.index++;
      this.timeout = setTimeout(() => {
        this.timeout = null;
        if (this.errors[this.index + 1]) {
          this.showError();
          return;
        }
        this.isShowError = false;
      }, 3000);
    }
  }
};
</script>

<style lang="scss">
@import '~common/assets/styles/corrects';
html,
body {
  overflow: hidden !important;
}
.admin {
  height: 100vh;
  background: #fafafa;
  .admin__alert {
    width: 300px;
    position: absolute;
    top: 50px;
    left: calc(50% - 150px);
    text-align: center;
    z-index: 999;
  }
  .admin__layout {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .admin__toolbar {
    flex: 0 0 52px;

    .v-toolbar__content {
      height: 52px !important;
    }

    .header-email {
      margin: 0 15px 0;
      font-weight: 600;
      font-size: 15px;
    }
  }
  .admin__container {
    flex: 1;
    display: flex;
    height: calc(100vh - 65px);

    .v-list {
      .v-list__tile {
        height: 48px;
        font-size: 14px;
        font-family: 'Roboto', 'Arial', sans-serif;
        font-weight: 500;
      }
    }
    .v-toolbar {
      .v-toolbar__content {
        height: 52px !important;
      }
    }

    .admin__navigation {
      background: $white;
      flex: 0 0 55px;
      width: 55px;

      &.drawer {
        flex: 0 0 300px;
        width: 300px;
      }
    }
    .admin__content {
      height: 93vh;
      flex: 1;
      padding: 5px 5px 5px 15px;
      overflow: hidden;
    }
    .admin__chat {
      flex: 0 0 300px;
      padding: 5px;
      max-width: 300px;
    }

    .badge {
      font-size: 13px;
      background-color: #f2f2f2;
      border-radius: 5px;
      width: 44px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      color: black;

      &.badge__red {
        color: red;
      }
      &.badge__red_bg {
        background-color: red;
        color: white;
      }
    }
  }
}
.modal {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: white;
}
.v-input.field_error {
  label {
    color: red !important;
  }
  input {
    border-bottom: 1px solid red !important;
  }
}
.text-center {
  text-align: center;
}
</style>
