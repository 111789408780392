<template>
  <div v-if="isLoading" class="spinner-wrapper">
    <v-progress-circular :size="40" color="primary" indeterminate />
  </div>
  <div v-else class="gps">
    <custom-button
      v-if="isShipper && !(isOperator || isPinView) && !isGPSEmpty"
      flat
      class="mt-0"
      :disabled="isDisabledGPSTracking"
      @click="showForm = !showForm"
    >
      <icon name="timer" size="20" />
      Продлить GPS-трекинг
    </custom-button>
    <content-block v-if="showForm" subtitle="Продление GPS-трекинга" subtitle-tag="h2">
      <v-container>
        <v-layout row wrap class="negative-margins">
          <v-flex xs12 md3>
            <custom-input v-model="prolongation.hours" label="На сколько часов продлить?" />
          </v-flex>
          <v-flex xs12 md3>
            <custom-input v-model="prolongation.minutes" label="На сколько минут продлить?" />
          </v-flex>
        </v-layout>
        <v-flex xs12>
          <custom-button
            :loading="isLoading"
            round
            large
            color="primary"
            class="submit"
            @click="onSubmit"
          >
            Продлить
          </custom-button>
          <custom-button flat @click="showForm = false">Отмена</custom-button>
        </v-flex>
      </v-container>
    </content-block>
    <content-block v-if="!isGPSEmpty" subtitle="GPS-трек" subtitle-tag="h2">
      <v-container>
        <v-layout class="negative-margins" wrap>
          <v-flex xs12 md6>
            <custom-input :value="gpsStart" label="Начало записи" disabled />
          </v-flex>
          <v-flex xs12 md6>
            <custom-input :value="gpsEnd" label="Окончание записи" disabled />
          </v-flex>
        </v-layout>
        <v-flex xs12>
          <v-container>
            <v-layout hidden-sm-and-down class="gray-background">
              <v-flex md4>Пункт маршрута</v-flex>
              <v-flex md4>Ожидаемое прибытие*</v-flex>
              <v-flex md4>Фактическое прибытие*</v-flex>
            </v-layout>
            <v-layout v-for="(point, index) in route" :key="point.uid" class="point">
              <v-flex xs12 md4>
                <div class="icon-and-name">
                  <icon :name="getIconName(point, index)" size="20" />
                  {{ point.name }}
                </div>
                <div class="mobile-info hidden-md-and-up">
                  {{ point.datetime }}
                  <span class="description">(Ожидаемое прибытие)</span>
                  <div
                    :class="{ 'is-after': point.isAfter, 'is-empty': arrivedDateIsNull(point) }"
                    class="real"
                  >
                    {{ arrivedDateIsNull(point) ? '\u2012' : point.arrived }}
                    <span v-if="point.arrived" class="description">(Фактическое прибытие)</span>
                  </div>
                </div>
              </v-flex>
              <v-flex xs12 md4 hidden-sm-and-down>
                {{
                  getExpectedDateTime({ date: point.expected_datetime, offset: point.gmt_offset })
                }}
              </v-flex>
              <v-flex
                :class="{ 'is-after': point.isAfter, 'is-empty': arrivedDateIsNull(point) }"
                xs12
                md4
                hidden-sm-and-down
              >
                {{ arrivedDateIsNull(point) ? '\u2012' : point.arrived }}
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
        <v-flex tag="p" xs12 class="required-notify mt-3 mb-0">
          * - Время указано в часовых поясах точек маршрута
        </v-flex>
      </v-container>
    </content-block>
    <div class="map-wrapper">
      <div v-if="!gpsPoints.length" class="overlay">
        Нет данных GPS трекинга
      </div>
      <light-map :route-points="routePoints" :coordinates="coords" :markers="gpsMarkers" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { isDateNullish } from 'common/helpers/dateHelper';
import { YEAR_NULL } from 'common/helpers/dateEnums';
import LightMap from './OrderView/LightMap';
import { lightFormat, set } from 'date-fns';
import ContentBlock from 'common/components/Orders/ContentBlock.vue';

const createProlongation = () => ({
  hours: null,
  minutes: null
});

export default {
  name: 'OrderViewGPS',
  components: {
    ContentBlock,
    LightMap
  },
  props: {
    isOperator: Boolean,
    isPinView: Boolean
  },
  data: () => ({
    prolongation: createProlongation(),
    isLoading: false,
    showForm: false
  }),
  computed: {
    ...mapState('orders', {
      gpsStart: state => state.gps.gpsStart,
      gpsEnd: state => state.gps.gpsEnd,
      route: state => state.gps.route,
      lastCoordinate: state => state.gps.lastCoordinate,
      info: state => state.orderInfo.info,
      coordinates: state => state.gps.coordinates
    }),
    ...mapGetters('errors', ['getError']),
    ...mapGetters('orders', ['routePoints', 'gpsPoints', 'isGPSEmpty']),
    ...mapGetters('login', ['isShipper']),
    isDisabledGPSTracking() {
      return ['on_dispute', 'done', 'canceled', 'dispute_is_done'].includes(this.info.status);
    },
    coords() {
      const { latitude, longitude } = this.lastCoordinate;
      return (latitude && longitude && [latitude, longitude]) || this.routePoints[0];
    },
    gpsMarkers() {
      const data = [];
      if (this.gpsPoints.length)
        data.push({
          id: 'gps-route',
          type: 'Polyline',
          coords: this.gpsPoints,
          options: {
            strokeWidth: 4,
            strokeColor: 'c738c7'
          }
        });
      if (this.lastCoordinate.latitude)
        data.push({
          id: 'last-position',
          type: 'Placemark',
          coords: this.coords
        });
      return data;
    }
  },
  async created() {
    this.isLoading = true;
    await Promise.all([this.getGPSInfo(), this.getCoordinates(), this.getLastCoordinate()]);
    this.isLoading = false;
  },
  methods: {
    ...mapActions('orders', ['getGPSInfo', 'getCoordinates', 'getLastCoordinate', 'prolongateGPS']),
    ...mapActions(['addSnackbar']),
    getExpectedDateTime({ date, offset }) {
      const newDate = set(new Date(date || Date.now()), {
        hours: new Date(date).getUTCHours() + offset,
        minutes: new Date(date).getUTCMinutes()
      });
      return lightFormat(newDate, 'dd.MM.yyyy в HH:mm');
    },
    getIconName(point, index) {
      if (point.notified) return 'roundedCheckGradient';
      return index === this.route.length - 1 ? 'markerGradient' : 'circleGradient';
    },
    arrivedDateIsNull(point) {
      return new Date(point.arrived_date).getFullYear() === YEAR_NULL;
    },
    async onSubmit() {
      try {
        await this.prolongateGPS(this.prolongation);
        this.addSnackbar({ message: 'GPS успешно продлен', type: 'success' });
        this.showForm = false;
        this.prolongation = createProlongation();
        await this.getGPSInfo();
      } catch (err) {
        if (err.status?.reason) {
          this.addSnackbar({
            message: this.getError({ error: err.status.reason }),
            type: 'error'
          });
          return;
        }

        this.addSnackbar({ message: 'Ошибка продления GPS', type: 'error' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.spinner-wrapper {
  display: flex;
  justify-content: center;
}

.required-notify {
  font-size: 14px;
  color: #8b8b8b;
  font-style: italic;
  margin-bottom: 8px;
}

.gps {
  padding-bottom: 40px;
}

.submit {
  margin: 0 16px 0 0 !important;
}

.gray-background {
  background: $gray-lightest;
  padding: 10px 0;
  font-weight: 500;
  border-bottom: 1px solid $gray-lighten;
  margin-bottom: 22px !important;
}

.icon-and-name {
  display: flex;
  align-items: center;
}

.mobile-info {
  font-size: 14px;
  margin-left: 28px;

  .description {
    color: $gray;
  }

  .real {
    color: $color-success;
  }
}

.is-after {
  color: $color-danger !important;
}

.is-empty {
  color: black !important;
}

.map-wrapper {
  position: relative;

  .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

.point {
  position: relative;

  .flex {
    .icon {
      margin-right: 8px;
    }

    &:last-child {
      color: $color-success;
    }
  }

  &:not(:last-child) {
    padding-bottom: 22px;

    &:before {
      content: '';
      position: absolute;
      left: 20px;
      border-left: 3px dotted $color-main;
      bottom: 0;
      top: 17px;
    }
  }
}

/deep/.content-block__content {
  padding: 0 12px;
}
</style>
