<template>
  <div class="container">
    <v-flex xs12>
      <h2><b>Паспорт</b></h2>
    </v-flex>
    <v-flex xs12>
      <custom-input
        :value="lastname"
        :rules="[v => !!v || 'Поле обязательно для ввода']"
        label="Фамилия"
        name-mask
        @input="
          $emit('changed', {
            field: 'lastname',
            value: $event
          })
        "
      >
        <template slot="append">
          <custom-button
            v-clipboard:copy="lastname"
            v-clipboard:success="e => $emit('copy', e)"
            v-clipboard:error="e => $emit('copy-error', e)"
            icon
            color="primary"
          >
            <v-icon>file_copy</v-icon>
          </custom-button>
        </template>
      </custom-input>
    </v-flex>
    <v-flex xs12>
      <custom-input
        :value="name"
        label="Имя"
        :rules="[v => !!v || 'Поле обязательно для ввода']"
        name-mask
        @input="
          $emit('changed', {
            field: 'name',
            value: $event
          })
        "
      >
        <template slot="append">
          <custom-button
            v-clipboard:copy="name"
            v-clipboard:success="e => $emit('copy', e)"
            v-clipboard:error="e => $emit('copy-error', e)"
            icon
            color="primary"
          >
            <v-icon>file_copy</v-icon>
          </custom-button>
        </template>
      </custom-input>
    </v-flex>
    <v-flex xs12>
      <custom-input
        :value="patronymic"
        :readonly="checkPatronymic"
        :rules="!checkPatronymic ? [v => !!v || 'Поле обязательно для ввода'] : []"
        label="Отчество"
        name-mask
        @input="
          $emit('changed', {
            field: 'patronymic',
            value: $event
          })
        "
      >
        <template slot="append">
          <custom-button
            v-clipboard:copy="patronymic"
            v-clipboard:success="e => $emit('copy', e)"
            v-clipboard:error="e => $emit('copy-error', e)"
            icon
            color="primary"
          >
            <v-icon>file_copy</v-icon>
          </custom-button>
          <v-checkbox
            :value="checkPatronymic"
            :false-value="false"
            :true-value="true"
            label="Нет отчества"
            @change="
              $emit('changed', {
                field: 'checkPatronymic',
                value: $event
              })
            "
          />
        </template>
      </custom-input>
    </v-flex>
    <v-flex xs6>
      <custom-input
        :value="passportSeries"
        label="Серия"
        :rules="[
          v => !!v || 'Поле обязательно для ввода',
          v => v.length === 4 || 'Серия должна быть из 4 символов'
        ]"
        mask="####"
        @input="
          $emit('changed', {
            field: 'passportSeries',
            value: $event
          })
        "
      >
        <template slot="append">
          <custom-button
            v-clipboard:copy="passportSeries"
            v-clipboard:success="e => $emit('copy', e)"
            v-clipboard:error="e => $emit('copy-error', e)"
            icon
            color="primary"
          >
            <v-icon>file_copy</v-icon>
          </custom-button>
        </template>
      </custom-input>
    </v-flex>
    <v-flex xs6>
      <custom-input
        :value="passportNumber"
        label="Номер"
        mask="######"
        :rules="[
          v => !!v || 'Поле обязательно для ввода',
          v => v.length === 6 || 'Номер должен быть из 6 символов'
        ]"
        @input="
          $emit('changed', {
            field: 'passportNumber',
            value: $event
          })
        "
      >
        <template slot="append">
          <custom-button
            v-clipboard:copy="passportNumber"
            v-clipboard:success="e => $emit('copy', e)"
            v-clipboard:error="e => $emit('copy-error', e)"
            icon
            color="primary"
          >
            <v-icon>file_copy</v-icon>
          </custom-button>
        </template>
      </custom-input>
    </v-flex>
    <v-flex xs6>
      <datepicker
        :value="passportDate"
        label="Дата выдачи"
        :rules="[v => !!v || 'Поле обязательно для ввода']"
        :max-date="maxPassportDate"
        @input="
          $emit('changed', {
            field: 'passportDate',
            value: $event
          })
        "
      />
    </v-flex>
    <v-flex xs6>
      <datepicker
        :disabled="isDisabledBirthday"
        :value="birthdayDate"
        label="Дата рождения"
        :rules="[v => !!v || 'Поле обязательно для ввода']"
        :max-date="maxBirthdayDate"
        @input="
          $emit('changed', {
            field: 'birthdayDate',
            value: $event
          })
        "
      />
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        :value="checkPassportFms"
        :false-value="false"
        :true-value="true"
        :rules="[v => !!v || 'Поле обязательно для ввода']"
        @change="
          $emit('changed', {
            field: 'checkPassportFms',
            value: $event
          })
        "
      >
        <template slot="prepend">
          <a href="http://services.fms.gov.ru/info-service.htm?sid=2000" target="_blank">
            Проверка на сайте ФМС
          </a>
        </template>
      </v-checkbox>
    </v-flex>
  </div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'common/components/form/Datepicker.vue';

export default {
  name: 'Passport',
  components: {
    Datepicker
  },
  props: {
    name: String,
    patronymic: String,
    lastname: String,
    checkPatronymic: Boolean,
    passportSeries: String,
    passportNumber: String,
    passportDate: String,
    birthdayDate: String,
    checkPassportFms: Boolean,
    isDisabledBirthday: Boolean
  },
  computed: {
    maxBirthdayDate() {
      const year = new Date().getFullYear() - 14;
      return moment().format(`${year}-MM-DD`);
    },
    maxPassportDate() {
      return moment(new Date()).format('YYYY-MM-DD');
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
</style>
