import moment from 'common/helpers/date';
import { getStatus } from '@/helpers';
import utils from '@/helpers/utils';
import { setField } from 'common/store/helpers';

const state = {
  list: [],
  invitations: [],
  trucker: {},
  photos: [],
  TS_list: [],
  TS_type: []
};

const actions = {
  async getDrivers({ dispatch, commit }, { enduser }) {
    commit('setField', { field: 'list', value: [] });
    const topic = 'admin/endusers/contractor/truckers/persons/list';
    try {
      const data = await dispatch(
        'common/createController',
        { topic, json: { enduser } },
        { root: true }
      );
      data.list = data.list.map(item => ({
        ...item,
        name: `${item.lname} ${item.fname} ${item.mname}`,
        date: moment(item.registration_date).format('DD.MM.YY, HH:MM'),
        verify: getStatus(utils.statuses, {
          status: item.verification_status,
          date: item.verification_date,
          until: item.verification_until
        }),
        verifyClass: utils.colors[item.verification_status] || utils.colors.default
      }));
      commit('setField', { field: 'list', value: data.list });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async getInvitations({ dispatch, commit }, { enduser }) {
    commit('setField', { field: 'invitations', value: [] });
    const topic = 'admin/endusers/contractor/truckers/invitations/list';
    try {
      const data = await dispatch(
        'common/createController',
        { topic, json: { enduser } },
        { root: true }
      );
      data.list = data.list.map(item => ({
        ...item,
        date: moment(item.date).format('DD.MM.YY, HH:MM'),
        verify: getStatus(utils.statuses, {
          status: item.status,
          declined: item.declined_date
        }),
        verifyClass: utils.colors[item.status] || utils.colors.default
      }));
      commit('setField', { field: 'invitations', value: data.list });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async getDriver({ dispatch, commit }, { trucker }) {
    commit('setField', { field: 'trucker', value: {} });
    commit('setField', { field: 'photos', value: [] });
    const topic = 'admin/endusers/contractor/truckers/persons/details/get';
    try {
      const data = await dispatch(
        'common/createController',
        { topic, json: { trucker } },
        { root: true }
      );
      const driver = data.trucker;
      driver.passport_date = moment(driver.passport_date).format('DD.MM.YYYY');
      driver.passport_birthday = moment(driver.passport_birthday).format('DD.MM.YYYY');
      driver.license_date = moment(driver.license_date).format('DD.MM.YYYY');
      driver.status = getStatus(utils.statuses, {
        status: driver.verification_status,
        date: driver.verification_date,
        until: driver.verification_until
      });
      driver.status_color =
        utils.verifyColors[driver.verification_status] || utils.verifyColors.default;
      if (driver.phone.length === 12) {
        const pref = driver.phone.substr(0, 2);
        const code = driver.phone.substr(2, 3);
        const number_1 = driver.phone.substr(5, 3);
        const number_2 = driver.phone.substr(8, 2);
        const number_3 = driver.phone.substr(10, 2);
        driver.phone = `${pref} (${code}) ${number_1}-${number_2}-${number_3}`;
      }
      commit('setField', { field: 'trucker', value: driver });
      commit('setField', { field: 'photos', value: data.photos });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async getAuthorizeTS({ dispatch, commit }, { trucker, enduser }) {
    const topic = 'admin/endusers/contractor/truckers/persons/vehicles/authorized/get';
    try {
      const data = await dispatch(
        'common/createController',
        { topic, json: { trucker, enduser } },
        { root: true }
      );
      commit('setTsType', data.type);
      const list = data.list
        .filter(item => item.id && item.name && item.verif_status && item.verif_until)
        .map(item => ({
          ...item,
          verify: getStatus(utils.statuses, {
            status: item.verif_status,
            until: item.verif_until,
            date: item.verif_date
          }),
          verifyColor: utils.verifyColors[item.verif_status] || utils.verifyColors.default
        }));
      commit('setTSList', list);
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async verifyDenial({ dispatch, commit }, json) {
    const topic = 'admin/endusers/trucker/profile/details/deny';
    try {
      await dispatch('common/createController', { topic, json }, { root: true });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
      commit('errorPush', 'Ошибка запроса на сервер', { root: true });
    }
  }
};

const mutations = {
  setField,
  setTsType(state, data) {
    state.TS_type = data;
  },
  setTSList(state, data) {
    state.TS_list = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
