<template>
  <v-container grid-list-xl fluid class="users">
    <custom-button flat color="primary" class="ml-0" :disabled="!isSuper" @click="showForm = true">
      <v-icon>add_circle_outline</v-icon>
      Добавить пользователя
    </custom-button>

    <UsersForm
      :firstname="form.firstname"
      :lastname="form.lastname"
      :midname="form.midname"
      :email="form.email"
      :group="form.group"
      :gid="form.gid"
      :show-form="showForm"
      :mail-suffix="form.emailSuffix"
      @close="close"
      @input="setField"
      @submit="submit"
    />
    <UsersTable :selected-email="selectedEmail" @delete-user="deleteUser" @get-user="edit">
      <slot>
        <UsersForm
          :firstname="form.firstname"
          :lastname="form.lastname"
          :midname="form.midname"
          :email="form.email"
          :group="form.group"
          :gid="form.gid"
          :is-edit="isEdit"
          :mail-suffix="form.emailSuffix"
          :phase="form.phase"
          class="userDataForm"
          @close="close"
          @input="setField"
          @submit="submit"
        />
      </slot>
    </UsersTable>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import UsersForm from './users/UsersForm';
import UsersTable from './users/UsersTable';

const createUserFields = (info = {}) => ({
  firstname: info?.firstname ?? '',
  lastname: info?.lastname ?? '',
  midname: info?.midname ?? '',
  email: info.email ? info.email.replace(/@.*/, '') : '',
  emailSuffix: info.email ? `@${info.email.replace(/.*@/, '')}` : '@gmail.com',
  group: info?.group ?? '',
  gid: info?.gid ?? '',
  phase: info.phase
});

export default {
  name: 'Users',
  components: {
    UsersForm,
    UsersTable
  },
  data() {
    return {
      form: createUserFields(),
      showForm: false,
      idGroup: '',
      isEdit: false,
      selectedEmail: ''
    };
  },
  computed: {
    ...mapState('login', ['isSuper']),
    ...mapState('users', ['usersList'])
  },
  mounted() {
    this.getGroupsList();
    this.getUsersList();
  },
  methods: {
    ...mapActions('users', ['getUsersList', 'getGroupsList']),
    ...mapActions('common', ['createController']),
    setField({ field, value }) {
      if (typeof value === 'object') {
        this.form.group = value.name;
        this.form.gid = value.gid;
      } else {
        this.form[field] = value;
      }
    },
    async submit(method) {
      const topic = {
        update: 'admin/administration/users/update',
        add: 'admin/administration/users/add',
        delete: 'admin/administration/users/delete',
        password: 'admin/administration/users/update/password'
      };
      const baseJSON = {
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        midname: this.form.midname,
        email: `${this.form.email}${this.form.emailSuffix}`,
        group: this.form.group,
        gid: this.form.gid
      };
      const json = {
        update: { ...baseJSON },
        add: { ...baseJSON },
        delete: { email: `${this.form.email}${this.form.emailSuffix}` },
        password: { email: `${this.form.email}${this.form.emailSuffix}` }
      };
      try {
        await this.createController({ topic: topic[method], json: json[method] });
        this.getUsersList();
        this.close();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic[method]}`, error);
      }
    },
    edit(info) {
      this.selectedEmail = info.email;
      this.isEdit = true;
      this.form = createUserFields(info);
    },
    deleteUser(data) {
      this.isEdit = false;
      this.form.email = data.email;
      this.form.emailSuffix = '';
      this.submit(data.method);
    },
    close() {
      this.selectedEmail = null;
      this.showForm = false;
      this.isEdit = false;
      this.form = createUserFields();
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.userDataForm {
  padding: 0;
  margin: 0 -12px !important;
}
</style>
