<template>
  <ContentBlock subtitle-tag="h2" subtitle="Требования к транспортному средству">
    <lineWithDots :description="getListCarcasses()" title="Тип кузова" />
    <lineWithDots
      v-if="getListCharacteristics()"
      :description="getListCharacteristics()"
      title="Характеристики кузова"
    />
    <lineWithDots
      v-if="getListProperties({ field: 'options' })"
      :description="getListProperties({ field: 'options' })"
      title="Требуемые опции"
    />
    <lineWithDots :description="info.corouting ? `да` : `нет`" title="Перевозка попутным грузом" />
  </ContentBlock>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ContentBlock from 'common/components/Orders/ContentBlock.vue';
import lineWithDots from 'common/components/Orders/lineWithDots.vue';

export default {
  name: 'VehicleRequirements',
  components: {
    ContentBlock,
    lineWithDots
  },
  computed: {
    ...mapState('orders', {
      info: state => state.orderInfo.info
    }),
    ...mapGetters('orders', ['getListProperties', 'getListCharacteristics', 'getListCarcasses'])
  }
};
</script>
