<template>
  <div class="docs">
    <custom-button color="primary" flat @click="getDocuments('mail')">
      <v-icon>sync</v-icon>
      Сформировать документацию для отправки по почте
    </custom-button>
    <custom-button color="primary" flat @click="getDocuments('diadoc')">
      <v-icon>sync</v-icon>
      Сформировать документацию для отправки через ЭДО Диадок
    </custom-button>
    <table class="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Дата формирования</th>
          <th>Способ доставки</th>
          <th>Статус</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in list" :key="i" @click="currentItem = item">
          <td>{{ i + 1 }}</td>
          <td>{{ getAppliedDate(item) }}</td>
          <td>{{ getDeliveryType(item) }}</td>
          <td v-if="item.type_delivery === 'diadoc'" :class="getStatus(item).status">
            {{ getStatus(item).text }}
          </td>
          <td v-else>
            <span v-if="isCompleted(item)">Выполнено {{ getCompletedDate(item) }}</span>
            <custom-button
              v-else
              color="primary"
              small
              primary
              class="ma-0"
              @click.stop="makeMailsCompleted(item)"
            >
              Выполнено
            </custom-button>
          </td>
        </tr>
      </tbody>
    </table>
    <TabDocsModal v-if="isNotEmpty" :info="currentItem" @close="currentItem = {}" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { lightFormat, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { primaryDocument } from 'common/helpers/pdf';
import axios from 'common/helpers/httpService';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { getUUID } from 'common/helpers';
import { YEAR_NULL } from 'common/helpers/dateEnums';
import TabDocsModal from './TabDocsModal';

const deliveryMap = {
  mail: 'По почте',
  diadoc: 'Через ЭДО Диадок'
};

export default {
  name: 'TabDocs',
  components: {
    TabDocsModal
  },
  data() {
    return {
      currentItem: {},
      list: [],
      uploadedDiadocs: []
    };
  },
  computed: {
    ...mapState('login', ['cid']),
    isNotEmpty() {
      return Object.keys(this.currentItem).length;
    }
  },
  created() {
    this.getList();
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions(['addSnackbar']),
    async savePdf(list) {
      try {
        const zip = new JSZip();
        const docs = await Promise.all(
          list.map(document => {
            return primaryDocument(document);
          })
        );
        const bases = await Promise.all(
          docs.map(
            doc =>
              new Promise(resolve => {
                doc.getBase64(resolve);
              })
          )
        );
        const lastPack = this.list[this.list.length - 1];
        const date = lightFormat(new Date(lastPack.date_applied), 'dd.MM.yyyy');

        const archiveName = `Архив документов за ${date}, ${this.list.length} в списке`;

        bases.forEach((base64, index) => {
          const period = list[index].number.replace(/^.+?-/, '');
          const formattedPeriod = format(new Date(period), 'LLLL yyyy', {
            locale: ru
          }).replace(/^./, str => str.toUpperCase());
          const { name } = list[index].receiver;
          zip.folder('pdfs').file(`${name}, ${formattedPeriod}.pdf`, base64, { base64: true });
        });
        const content = await zip.generateAsync({ type: 'blob' });
        saveAs(content, `${archiveName}.zip`);
        this.addSnackbar({
          message: 'Сформирован новый пакет документов для отправки по Почте',
          type: 'success'
        });
      } catch (error) {
        console.error(`Ошибка в сохранении PDF файлов`, error);
      }
    },
    async getList() {
      const topic = 'adm_buch/documents/list';
      try {
        const { list } = await this.createController({ topic });
        this.list = list;
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async getDocuments(type) {
      const topic = 'adm_buch/documents/get';
      const json = { type };
      try {
        const { list } = await this.createController({ topic, json });
        await this.getList();
        if (type === 'mail') this.savePdf(list);
        if (type === 'diadoc') this.submitPDF(list);
      } catch (error) {
        if (error.status.reason === 'E_NO_NEW_REQUESTS')
          this.addSnackbar({ message: 'Нет новых заказов на документы', type: 'error' });
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async makeMailsCompleted({ acts }) {
      const ids = acts.map(_ => _.id);
      const topic = 'adm_buch/documents/approve';
      const json = { ids };
      try {
        await this.createController({ topic, json });
        this.getList();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async submitPDF(list) {
      const formData = new FormData();
      formData.append('cid', this.cid);
      formData.append('multipage', true);
      formData.append('type', 'global');
      try {
        const docs = await Promise.all(
          list.map(document => {
            return primaryDocument(document);
          })
        );
        const blobs = await Promise.all(
          docs.map(
            doc =>
              new Promise(resolve => {
                doc.getBlob(resolve);
              })
          )
        );
        blobs.forEach((blob, index) => {
          const { date } = list[index];
          const monthName = new Date(date).toLocaleString('en', { month: 'short' });
          const fileName = `MoreCargo_${monthName}-${new Date(date).getFullYear()}_${getUUID()}`;
          const file = new File([blob], `${fileName}.pdf`);
          formData.append(`inputfile${index + 1}`, file);
          this.uploadedDiadocs.push({
            id: list[index].id,
            filename: `${fileName}.pdf`,
            inn: list[index].receiver.inn,
            kpp: list[index].receiver.kpp
          });
        });

        const { photos } = await axios.post('/upload', formData);
        this.uploadedDiadocs = this.uploadedDiadocs
          .map(_ => ({
            ..._,
            file: photos.find(item => item.filename === _.filename)?.file_id || ''
          }))
          .filter(photo => Boolean(photo.file));

        this.sendDiadocsInfo();
      } catch (error) {
        console.error(`Ошибка в отправке PDF файлов`, error);
      }
    },
    async sendDiadocsInfo() {
      const topic = 'adm_buch/documents/send';
      const json = this.uploadedDiadocs;
      try {
        await this.createController({ topic, json });
        this.addSnackbar({
          message: 'Документы отправлены на сервер. Подвердите подпись в приложении myDSS',
          type: 'success'
        });
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      } finally {
        this.getList();
        this.uploadedDiadocs = [];
      }
    },
    getAppliedDate({ date_applied }) {
      return lightFormat(new Date(date_applied), 'dd.MM.yyyy, HH:mm');
    },
    getDeliveryType({ type_delivery }) {
      return deliveryMap[type_delivery];
    },
    getStatus({ acts }) {
      if (acts.every(({ status }) => status === 'applied'))
        return {
          status: 'applied',
          text: 'Принято в работу'
        };
      if (acts.every(({ status }) => status === 'done'))
        return {
          status: 'done',
          text: 'Выполнено'
        };
      return {
        status: 'error',
        text: 'Ошибка отправки'
      };
    },
    isCompleted({ acts }) {
      return new Date(acts[0].date_complited).getFullYear() !== YEAR_NULL;
    },
    getCompletedDate({ acts }) {
      const date = acts[0].date_complited;
      return lightFormat(new Date(date), 'dd.MM.yy в HH:mm');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';
@include table(25%, $hover: true);
@import '~common/assets/styles/variables.scss';
.applied {
  color: $color-main;
}
.done {
  color: $color-success;
}
.error {
  color: $color-error;
  background: transparent !important;
  border-bottom: 1px solid #cccccc !important;
}
</style>
