import { setField } from 'common/store/helpers';

const state = {
  offersList: [],
  ordersList: [],
  listAddresses: [],
  feed: null,
  cursor: null
};

const actions = {
  async getOffersList({ commit, dispatch, rootState }) {
    const topic = 'admin/order/offers/my';
    try {
      const { list } = await dispatch(
        'common/createController',
        { topic, json: { enduser: rootState.clients.info.id } },
        { root: true }
      );
      commit('setField', { field: 'offersList', value: list });
    } catch (error) {
      console.error(`Ошибка в запросе ${topic}`, error);
    }
  },
  async getOrdersList({ commit, dispatch, rootState }) {
    const topic = 'order/list';
    const json = state.feed
      ? { feed: state.feed, cid: rootState.clients.info.cid }
      : { cid: rootState.clients.info.cid };
    try {
      const { orders, feed } = await dispatch(
        'common/createController',
        { topic, json },
        { root: true }
      );
      commit('setField', {
        field: 'ordersList',
        value: state.feed ? [...state.ordersList, ...orders] : orders
      });
      commit('setField', { field: 'feed', value: feed });
      commit('setField', { field: 'cursor', value: feed.cursor });
    } catch (error) {
      console.error(`Ошибка в запросе ${topic}`, error);
    }
  },
  async getAddresses({ dispatch, commit, rootState }) {
    const topic = 'admin/my_addresses/list';
    try {
      const { list } = await dispatch(
        'common/createController',
        { topic, json: { enduser: rootState.clients.info.id } },
        { root: true }
      );
      commit('setField', { field: 'listAddresses', value: list });
    } catch (error) {
      console.error(error);
    }
  }
};
const mutations = {
  setField,
  clearFeed(state) {
    state.ordersList = [];
    state.feed = null;
    state.cursor = null;
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
