import { setField } from 'common/store/helpers';

const state = {
  myRating: '',
  myFeedbacksCount: '',
  myTotalOrders: ''
};

const actions = {
  async getRaiting({ dispatch, commit, rootState }) {
    const topic = 'feedbacks/get_rating';
    try {
      const { rating, feedbacks_count: feedbacksCount, total_orders: totalOrders } = await dispatch(
        'common/createController',
        { topic, json: { agent_cid: rootState.clients.info.cid } },
        { root: true }
      );
      commit('setField', { field: 'myRating', value: rating });
      commit('setField', { field: 'myFeedbacksCount', value: feedbacksCount });
      commit('setField', { field: 'myTotalOrders', value: totalOrders });
    } catch (error) {
      console.error(`Ошибка в запросе ${topic}`, error);
    }
  },
  updRatingSubscribe({ dispatch, commit }) {
    dispatch(
      'common/subscribe',
      {
        topic: 'upd/rating',
        callback({ data }) {
          commit('setField', { field: 'myRating', value: data.rating });
          commit('setField', { field: 'myFeedbacksCount', value: data.feedbacks_count });
        }
      },
      { root: true }
    );
  },
  updRatingUnsubscribe({ dispatch }) {
    dispatch(
      'common/unsubscribe',
      {
        topic: 'upd/rating'
      },
      { root: true }
    );
  }
};

const mutations = {
  setField
};

export default {
  state,
  actions,
  mutations,
  namespaced: true
};
