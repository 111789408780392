<template>
  <div :class="{ suggestion: isGreen }" class="item" @click="$emit('click', item.cid)">
    <div class="item__header">
      <b class="item__id">#{{ item.id }}</b>
      <b class="item__status right">
        {{ status }}
        <icon-tooltip
          v-if="isBlitzBidding"
          name="fire"
          size="21"
          icon-class="fire"
          message="Заказ с блиц-ценой"
        />
        <icon-tooltip
          v-if="showIconDispute(item)"
          :icon-class="`dispute ${item.dispute_status} ${getResultDispute}`"
          name="hat"
          size="18"
          message="Диспут"
          @click="$emit('to-dispute', item.cid)"
        />
      </b>
      <div class="item__action">
        <!-- Temporary disable sms -->
        <!-- <icon-tooltip
          :name="item.sms ? 'sms' : 'smsInActive'"
          :disabled="item.status !== 'waiting_for_offer'"
          size="18"
          message="Отправка уведомлений по СМС"
          @click="$emit('toggle-sms', { cid: item.cid, value: item.sms })"
        /> -->
        <icon-tooltip
          :name="item.status === 'editing' ? 'eyeOff' : 'eye'"
          :disabled="!['editing', 'waiting_for_offer'].includes(item.status)"
          size="18"
          message="Разместить/снять с размещения"
          @click="$emit('toggle-publish', { cid: item.cid, status: item.status })"
        />
        <icon-tooltip
          :disabled="
            ['accepted', 'editing', 'waiting_for_offer', 'in_process'].includes(item.status)
          "
          name="message"
          size="20"
          message="Отзывы"
          @click="$emit('to-feedbacks', item.cid)"
        />
        <icon-tooltip
          :disabled="['editing'].includes(item.status)"
          name="marker"
          size="18"
          message="Просмотр данных геолокации"
          @click="$emit('to-gps', item.cid)"
        />
        <icon-tooltip
          :disabled="!['editing', 'waiting_for_offer'].includes(item.status)"
          name="pencil"
          size="18"
          message="Редактирование"
          @click="$emit('edit', item.cid)"
        />
        <icon-tooltip
          name="contentCopy"
          size="18"
          message="Копирование"
          @click="$emit('copy-order', item.cid)"
        />
        <icon-tooltip
          :disabled="!['accepted', 'in_process'].includes(item.status)"
          name="xFile"
          size="18"
          message="Отмена заказа"
          @click="$emit('cancel-order', item.cid)"
        />
        <icon-tooltip
          :disabled="!['editing', 'waiting_for_offer'].includes(item.status)"
          name="deleteIcon"
          size="18"
          message="Удаление заказа"
          icon-class="mr-0"
          @click="$emit('delete', item.cid)"
        />
      </div>
    </div>
    <div class="item__content">
      <div class="item__block">
        <span class="item__label">Дата и время погрузки</span>
        <span class="item__value">{{ departureTime }}</span>
      </div>
      <div class="item__block">
        <span class="item__label">Маршрут</span>
        <span class="item__value">
          {{ item.source }} —{{ item.complex_route ? ' ... —' : '' }} {{ item.destination }}
        </span>
      </div>
      <div class="item__block">
        <span class="item__label">Характеристика груза</span>
        <span class="item__value">{{ item.name }}</span>
      </div>
      <div class="item__block" @click="$emit('to-offers', item.cid)">
        <span class="item__label">
          {{ ['waiting_for_offer', 'editing'].includes(item.status) ? 'Предложения' : 'Стоимость' }}
        </span>
        <span class="item__value">
          <template v-if="item.status === 'waiting_for_offer'">
            <span class="mr-1">{{ bestOffer }}</span>
            <icon v-if="isExpress" name="lightning" size="20" class="lightning mr-1" />
            <span v-if="item.total_offers" class="item__counter">
              {{ item.total_offers }}
              <span v-if="item.new_offers">{{ `(+${item.new_offers})` }}</span>
            </span>
          </template>
          <template v-else-if="item.status === 'editing'">-</template>
          <template v-else>
            {{ offer }}
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import set from 'date-fns/set';
import lightFormat from 'date-fns/lightFormat';
import { getUTCDate, isDateNullish } from 'common/helpers/dateHelper';
import IconTooltip from '@/components/common/IconTooltip';
import getOrderStatus from 'common/components/Orders/getOrderStatus';

export default {
  name: 'OrderListItem',
  components: {
    IconTooltip
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isOperator: Boolean
  },
  computed: {
    ...mapGetters('login', ['isShipper']),
    status() {
      return getOrderStatus(this.item, this.isShipper);
    },
    category() {
      return this.isOperator
        ? this.$store.state.clients.info.category
        : this.$store.state.login.category;
    },
    getResultDispute() {
      if (this.item.who_won_dispute) {
        if (this.item.who_won_dispute === 'neutral') return 'neutral';
        if (this.item.who_won_dispute === this.category) return 'won_dispute';
        return 'lost_dispute';
      }
      return null;
    },
    departureTime() {
      const {
        begin_datetime: begin,
        end_datetime: end,
        begin_end_offset: offset,
        loading_date: loadindDate
      } = this.item;
      if (!offset) return '';
      const endDate = getUTCDate(end);
      const hours = endDate.getHours() + offset;
      const formatDate = endDate.getSeconds() ? 'dd.MM.yy' : 'dd.MM.yy, HH:mm';
      const isEqual = begin === end;
      const localDate = !isDateNullish(loadindDate)
        ? set(getUTCDate(loadindDate), { hours })
        : set(endDate, { hours });
      if (isEqual || !isDateNullish(loadindDate)) return lightFormat(localDate, formatDate);
      return `${lightFormat(getUTCDate(begin), 'dd.MM.yy')} - ${lightFormat(
        localDate,
        formatDate
      )}`;
    },
    isBlitzBidding() {
      return this.item.bidding === 'blitz';
    },
    isExpress() {
      return this.item.best_offer?.express;
    },
    bestOffer() {
      const offer = this.item.best_offer || {};

      if (!Object.keys(offer).length) {
        return '--';
      }

      const nds = this.item.best_offer.nds ? 'с НДС' : 'без НДС';
      return `Мин. ${this.item.best_offer.cost?.toLocaleString('ru-RU')} р. ${nds}`;
    },
    offer() {
      if (!this.item.final_cost) return '—';
      const nds = this.item.nds ? 'с НДС' : 'без НДС';
      return `${this.item.final_cost.toLocaleString('ru-RU')} р. ${nds}`;
    },
    isGreen() {
      return this.item.new_offers && this.item.status === 'waiting_for_offer';
    }
  },
  methods: {
    showIconDispute(item) {
      return (
        ['on_dispute', 'dispute_is_done'].includes(item.status) ||
        (item.status === 'canceled' &&
          !isDateNullish(item.dispute_open_deadline) &&
          item.who_canceled !== this.category)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';
.icon.mr-0 {
  margin: 0 0 0 -4px;
}
.item {
  box-shadow: 0px 0px 5px 0px rgba(17, 17, 17, 0.16);
  padding: 0 12px;
  margin: 0 -12px 16px;
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 16px 0px rgba(17, 17, 17, 0.16);
  }

  &__counter {
    display: inline-flex;
    span {
      color: $color-main;
    }
  }
  &__id {
    margin-right: 6px;
    min-width: 70px;
  }
  &__header {
    display: flex;
    align-items: center;
    height: 44px;
  }
  &__action {
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;
  }
  &__content {
    display: flex;
  }
  &__status {
    display: flex;
    .icon {
      margin: -2px 0 0 8px;
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    padding: 0 24px 0 0;
    &:nth-child(1) {
      width: calc(100 / 12 * 100% * 2);
    }
    &:nth-child(2) {
      width: calc(100 / 12 * 100% * 4);
    }
    &:nth-child(3) {
      width: calc(100 / 12 * 100% * 3);
    }
    &:nth-child(4) {
      text-align: right;
      padding: 0;
      width: calc(100 / 12 * 100% * 3);
      .item__value {
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
  &__label {
    font-size: 12px;
    line-height: 16px;
    color: $gray;
  }
  &__value {
    display: inline-flex;
    font-size: 14px;
    min-height: 44px;
    line-height: 1.3;
    padding: 12px 0 6px 0;
  }
}

.lightning.icon,
/deep/.fire.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /deep/ svg {
    width: 100%;
    height: 100%;
  }
}
/deep/ .fire.icon,
/deep/ .lost_dispute.icon {
  color: $color-danger;
}

.lightning.icon,
/deep/ .on_dispute.icon {
  color: $color-warning;
}
/deep/ .canceled.icon {
  color: $color-main;
}
/deep/ .won_dispute.icon {
  color: $color-success;
}
/deep/.neutral.icon {
  color: $gray-darken;
}
@media screen and (max-width: breakpoint(lg)) {
  .item {
    margin: 0 0 16px;
    &__content {
      flex-wrap: wrap;
    }
    &__block:nth-child(1),
    &__block:nth-child(2),
    &__block:nth-child(3),
    &__block:nth-child(4) {
      width: 50%;
    }
    &__block:nth-child(2) {
      text-align: right;
      padding: 0;
      .item__value {
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .item {
    margin: 0 0 16px 0;
    &__header {
      align-items: flex-start;
      flex-wrap: wrap;
      height: auto;
      margin-bottom: 8px;
    }
    &__id {
      display: inline-flex;
      align-items: center;
      padding-top: 8px;
      order: 1;
      min-width: unset;
    }
    &__action {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-wrap: wrap;
      order: 2;
      max-width: 180px;
      width: 100%;
      margin: 6px 0 8px auto;
      /deep/.icon {
        margin: 8px 12px !important;
      }
    }
    &__status {
      display: inline-block;
      padding: 0 24px 0 0;
      width: 100%;
      order: 3;
      .icon {
        position: relative;
        top: 3px;
        margin: 0;
      }
    }
    &__block {
      padding: 0;
    }
    &__block:nth-child(1),
    &__block:nth-child(2),
    &__block:nth-child(3),
    &__block:nth-child(4) {
      width: 100%;
      text-align: left;
      .item__value {
        align-items: center;
        justify-content: flex-start;
      }
    }
    &__value {
      display: inline-flex;
      align-items: center;
      min-height: 44px;
      line-height: 1.2;
    }
  }
}
</style>
