import { BaseApi } from './BaseApi';

/* app topics */
export const TRANSPORT_VEHICLE_SAVE = 'transport/vehicle/save';
export const TRANSPORT_WAGON_SAVE = 'transport/wagon/save';
export const TRANSPORT_VERIFICATION = 'transport/vehicle/verification';
export const TRANSPORT_GET = 'transport/get';
export const TRANSPORT_VEHICLE_REMOVE = 'transport/vehicle/remove';
export const TRANSPORT_WAGON_REMOVE = 'transport/wagon/remove';
export const TRANSPORT_VEHICLES_LIST = 'transport/vehicles';
export const TRANSPORT_WAGONS_LIST = 'transport/wagons';
export const TRANSPORT_VERSION = 'transport/version';
export const TRANSPORT_SCHEME = 'transport/scheme';

class TransportApi extends BaseApi {
  transportVehicleSave({ json }) {
    return this.mqttController({ topic: TRANSPORT_VEHICLE_SAVE, json });
  }

  transportWagonSave({ json }) {
    return this.mqttController({ topic: TRANSPORT_WAGON_SAVE, json });
  }

  transportVerification({ json }) {
    return this.mqttController({ topic: TRANSPORT_VERIFICATION, json });
  }

  transportGet({ json }) {
    return this.mqttController({ topic: TRANSPORT_GET, json });
  }

  transportGetVehiclesList() {
    return this.mqttController({ topic: TRANSPORT_VEHICLES_LIST });
  }

  transportGetWagonsList() {
    return this.mqttController({ topic: TRANSPORT_WAGONS_LIST });
  }

  transportVehicleRemove({ json }) {
    return this.mqttController({ topic: TRANSPORT_VEHICLE_REMOVE, json });
  }

  transportWagonRemove({ json }) {
    return this.mqttController({ topic: TRANSPORT_WAGON_REMOVE, json });
  }

  transportGetVersion() {
    return this.mqttController({ topic: TRANSPORT_VERSION });
  }

  transportGetScheme({ json }) {
    return this.mqttController({ topic: TRANSPORT_SCHEME, json });
  }

  async transportGetVersionWithScheme() {
    try {
      const { version } = await this.transportGetVersion();
      const { scheme } = await this.transportGetScheme({ json: { version } });
      return scheme;
    } catch (error) {
      console.error(`Ошибка в запросе ${TRANSPORT_VERSION} или в ${TRANSPORT_SCHEME}`, error);
    }
  }
}

const createTransportApi = () => {
  let instance = null;

  return dispatch => {
    if (!instance) {
      instance = new TransportApi(dispatch);
    }

    return instance;
  };
};

export const transportApi = createTransportApi();
