<template>
  <div class="content_managment">
    <Tabs :tabs="tabs" :active="activeTab" @change="changeTab" />
  </div>
</template>

<script>
import Tabs from '@/components/Layout/Tabs';
import Tooltips from './Tooltips/Tooltips';
import TabFAQ from './TabFAQ';

export default {
  components: {
    Tabs
  },
  data() {
    return {
      tabs: [
        { name: 'menu', title: 'Меню', disabled: true },
        { name: 'pages', title: 'Страницы', disabled: true },
        { name: 'help', title: 'Подсказки', component: Tooltips },
        { name: 'faq', title: 'FAQ', component: TabFAQ }
      ],
      activeTab: this.$route.params.stage || 'faq'
    };
  },
  methods: {
    changeTab(stage) {
      this.$router.push({ name: 'ContentManagementStage', params: { stage } });
    }
  }
};
</script>

<style lang="scss">
.content_managment {
  width: 100%;
  height: 100%;
}
</style>
