<template>
  <div class="chat-reply">
    <a v-if="isMobile" href="tel:88007775830" class="phone-icon">
      <icon name="phone" />
    </a>
    <custom-input
      v-model="message"
      :label="isMobile ? '' : 'Введите текст вашего сообщения'"
      :placeholder="isMobile ? 'Введите текст вашего сообщения' : ' '"
      :input-type="isMobile ? 'textarea' : 'input'"
      class="chat-reply__input"
      auto-grow
      hide-details
      rows="1"
      @keyup.enter="send"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <custom-button v-if="isMobile" class="send-icon" icon @click="send">
      <icon name="send" />
    </custom-button>
    <div v-if="!isMobile" class="chat-reply__footer">
      <slot />
      <custom-button
        :loading="loading('chat/message')"
        class="send-button"
        round
        large
        @click="send"
      >
        Отправить
      </custom-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CustomInput from 'common/components/CustomInput.vue';

export default {
  components: { CustomInput },
  data: () => ({ message: '', isMobile: false }),
  computed: {
    ...mapGetters('common', ['loading'])
  },
  mounted() {
    this.getMobile();
    const ro = new ResizeObserver(this.getMobile);
    ro.observe(document.documentElement);
  },
  methods: {
    send() {
      if (this.message.trim() && !this.loading('chat/message')) {
        this.$emit('send-message', this.message);
        this.message = '';
      }
    },
    getMobile() {
      this.isMobile =
        window.matchMedia('(max-width: 600px)').matches ||
        (window.matchMedia('(max-width: 960px)').matches &&
          window.matchMedia('(orientation: landscape)').matches);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.chat-reply {
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  padding: 24px 12px 0;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: breakpoint(sm)),
    screen and (max-width: breakpoint(md)) and (orientation: landscape) {
    padding: 1px 12px;
    flex-direction: row;
    align-items: center;

    .phone-icon {
      width: 24px;
      height: 24px;
      margin: 0 12px 0 7px;
    }

    .send-icon .icon /deep/ svg {
      fill: $color-main;
    }

    .v-btn.v-btn--icon:hover:after {
      background-color: transparent;
    }
  }

  &__input {
    @media screen and (max-width: breakpoint(sm)),
      screen and (max-width: breakpoint(md)) and (orientation: landscape) {
      padding-top: 0;
      /deep/ .v-input__slot::before {
        border-color: transparent !important;
      }
    }
  }

  &__footer {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .send-button {
    margin: 0;
  }
}
</style>
