<template>
  <v-dialog v-model="showForm" class="dispute-form" persistent max-width="500px">
    <v-toolbar dark="dark" color="primary">
      <v-toolbar-title>
        Завершить диспут
      </v-toolbar-title>
      <v-spacer />
      <custom-button icon color="white" class="verification-window__close" @click="$emit('close')">
        <icon name="close" />
      </custom-button>
    </v-toolbar>
    <div class="dispute-form__main">
      <custom-input
        v-model="text"
        input-type="textarea"
        rows="1"
        label="Введите текст сообщения для диспута"
      />
      <span class="label">Завершить диспут в пользу:</span>
      <v-radio-group v-model="winner">
        <v-radio :ripple="false" :label="`Инициатора (${initiatorName})`" value="initiator" />
        <v-radio :ripple="false" :label="`Ответчика (${responderName})`" value="responder" />
        <v-radio :ripple="false" value="neutral" label="Нейтрально" />
      </v-radio-group>
      <div v-if="winner === 'initiator'">
        <p class="header-feedback">Генерация отзыва</p>
        <span class="label">Причины отмены</span>
        <v-checkbox
          v-for="(reason, i) in currentReasons"
          :key="i"
          v-model="reasons"
          :label="reason.label"
          :value="reason.value"
          class="reason"
          multiple
        />
        <custom-input
          v-model="comment"
          input-type="textarea"
          rows="1"
          label="Введите текст для отзыва"
        />
      </div>
      <custom-button :disabled="!text" color="primary" round large @click="finish">
        Завершить
      </custom-button>
      <custom-button flat @click="$emit('close')">Отменить</custom-button>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'DisputeFinishModal',
  props: {
    showForm: Boolean
  },
  data: () => ({
    text: '',
    winner: null,
    reasons: [],
    comment: '',
    initiatorName: '',
    responderName: '',
    initiatorType: ''
  }),
  computed: {
    ...mapState({
      info: state => state.orders.orderInfo.info,
      orderCancelReasons: state => state.settings.orderCancelReasons
    }),
    currentReasons() {
      return this.orderCancelReasons[this.initiatorType];
    }
  },
  async mounted() {
    const data = await this.createController({
      topic: 'disputes/get_form_for_finish',
      json: { order_cid: this.info.cid }
    });
    this.initiatorName = data.initiator_name;
    this.responderName = data.responder_name;
    this.initiatorType = data.initiator_type;
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions(['addSnackbar']),
    async finish() {
      const topic = 'disputes/finish_dispute';
      const json = {
        order_cid: this.info.cid,
        reasons: this.reasons,
        replica: this.text,
        comment: this.comment,
        winner: this.winner
      };
      try {
        await this.createController({ topic, json });
        this.$emit('close');
        this.addSnackbar({ message: 'Диспут успешно завершен', type: 'success' });
      } catch (error) {
        this.addSnackbar({ message: 'Ошибка завершения диспута', type: 'error' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.dispute-form {
  &__main {
    background: white;
    padding: 12px;
  }
}
.header-feedback {
  font-size: 18px;
  color: $gray;
  margin: 10px 0 5px;
}
.label {
  font-size: 12px;
  color: $gray;
}

.reason {
  margin: 0;
}
</style>
