<template>
  <div class="trucker_ts_content">
    <div class="trucker_ts-form">
      <div v-if="type === 'train'" class="train">
        <div class="status" :style="{ color: statuses.head.color }">
          {{ statuses.head.title }}
        </div>
        <div class="train__title">
          {{ fields.types[values_head.type] }}
        </div>
      </div>
      <v-layout v-for="(column, index) in columns" :key="index + 10" row="row" wrap="wrap">
        <v-flex
          v-for="(field, fieldIndex) in column"
          v-show="field"
          :key="fieldIndex + 10"
          :xs3="!field.size || field.size === 3"
          :xs4="field.size === 4"
          :xs6="field.size === 6"
          :xs12="field.size === 12"
          :class="field.class"
        >
          <div v-if="field.caption" class="trucker_ts-caption">
            {{ field.caption }}
          </div>
          <div v-else class="trucker_ts-field">
            <div v-if="field.component" class="trucker_ts-value">
              <i>{{ field.label }}</i>
              <component :is="field.component" :value="field.value" />
            </div>
            <div v-else-if="field.show !== false" class="trucker_ts-value">
              <div v-if="field.prev_img" class="trucker_ts-value__img">
                <field-carcass-image :value="field.prev_img" />
              </div>
              <div
                v-if="field.options"
                v-html-safe="field.value"
                class="ui-kit__input options_area"
              />
              <v-text-field
                v-else-if="field.value"
                class="ui-kit__input"
                :label="field.label"
                :value="field.value"
                :readonly="true"
              />
              <v-text-field
                v-else
                class="ui-kit__input empty_field"
                :label="field.label"
                value="Не задано"
                :readonly="true"
              />
            </div>
          </div>
        </v-flex>
      </v-layout>
      <div v-if="type === 'train'" class="status" :style="{ color: statuses.tail.color }">
        {{ statuses.tail.title }}
      </div>
      <div v-if="type === 'train'" class="trailer__title">
        {{ fields.types[values_tail.type] }}
      </div>
      <div v-if="type === 'train'">
        <v-layout v-for="(column, index) in columns_tail" :key="index" row="row" wrap="wrap">
          <v-flex
            v-for="(field, fieldIndex) in column"
            v-show="field"
            :key="fieldIndex"
            :xs3="!field.size || field.size === 3"
            :xs4="field.size === 4"
            :xs6="field.size === 6"
            :xs12="field.size === 12"
            :class="field.class"
          >
            <div v-if="field.caption" class="trucker_ts-caption">
              {{ field.caption }}
            </div>
            <div v-else class="trucker_ts-field">
              <div v-if="field.component" class="trucker_ts-value">
                <i>{{ field.label }}</i>
                <component :is="field.component" :value="field.value" />
              </div>
              <div v-else-if="field.show !== false" class="trucker_ts-value">
                <div v-if="field.prev_img" class="trucker_ts-value__img">
                  <field-carcass-image :value="field.prev_img" />
                </div>
                <v-text-field
                  class="ui-kit__input"
                  :label="field.label"
                  :readonly="true"
                  :value="field.value"
                  :rules="[v => !!v || 'Это поле обязательно для заполнения']"
                />
              </div>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import utils from '@/helpers/utils';
import FieldCarcassImage from './FieldCarcassImage.vue';
import ImageSliderSmall from '../../common/ImageSliderSmall.vue';

export default {
  components: {
    ImageSliderSmall,
    FieldCarcassImage
  },
  props: {
    valuesHead: {
      type: Object,
      default() {
        return {};
      }
    },
    valuesTail: {
      type: Object,
      default() {
        return {};
      }
    },
    fields: {
      type: Object,
      default() {
        return {};
      }
    },
    type: {
      type: String,
      default() {
        return 'auto';
      }
    },
    title: {
      type: String,
      default() {
        return 'Car';
      }
    },
    photos: {
      type: Array,
      default() {
        return [];
      }
    },
    statuses: {
      type: Object,
      default() {
        return {
          head: {},
          tail: {}
        };
      }
    }
  },
  data() {
    return {
      dialogDenied: false,
      deniedText: ''
    };
  },
  computed: {
    ...mapState('login', ['cid']),
    ...mapState('clients', ['info']),
    defaultURL() {
      return this.$store.state.defaultURL;
    },
    columns() {
      const vehicle = {
        typeTS: this.fields.typeTS.find(item => item.value === this.values_head.typeTS) || {},
        carcass: this.fields.carcasses.find(item => item.id === this.values_head.carcass) || {},
        captions: {
          characteristics: 'Характеристики кузова',
          options: 'Имеющиеся опции',
          lifting: 'Допустимые способы погрузки/разгрузки'
        },
        characteristics: [],
        options: [],
        lifting: []
      };
      if (vehicle.carcass.id) {
        vehicle.carcass.characteristics.forEach(propValue => {
          const prop = this.fields.characteristics.find(item => item.id === propValue);
          if (prop && (this.values_head.properties[prop.id] || prop.carrier.required)) {
            vehicle.characteristics.push({
              label: `${prop.carrier.name}, ${prop.unit_measure}`,
              value: this.values_head.properties[prop.id] || false,
              size: 4
            });
          }
        });

        ['options', 'lifting'].forEach(type => {
          let text = '';
          vehicle.carcass[type].forEach(propValue => {
            const prop = this.fields[type].find(item => item.id === propValue);
            if (prop && this.values_head.properties[prop.id]) {
              // если это не первая опция ставим запятую после предыдущей
              if (text) {
                text += ', ';
              }
              text += prop.name;
              if (prop.characteristics && prop.characteristics.length) {
                text += ' (';
                prop.characteristics.forEach((item, index) => {
                  const optChar = this.fields.options_chars.find(value => value.id === item);
                  if (optChar) {
                    text += `${optChar.carrier.name}: `;
                    text += this.values_head.properties[optChar.id]
                      ? `${this.values_head.properties[optChar.id]}${optChar.unit_measure}`
                      : '<span>Не задано</span>';
                    text += index < prop.characteristics.length - 1 ? ', ' : ')';
                  }
                });
              }
            }
          });
          // добавит заголовок
          if (text) {
            vehicle[type].push({ caption: vehicle.captions[type], size: 12 });
            vehicle[type].push({ options: true, value: text, size: 12 });
          }
        });
      }

      const images = {
        ts: this.photos.filter(item => item.type === 'ts').map(item => this.getUrl(item)),
        sts: this.photos.filter(item => item.type === 'sts').map(item => this.getUrl(item)),
        tsdoc: this.photos.filter(item => item.type === 'tsdoc').map(item => this.getUrl(item))
      };
      const photo = [
        {
          label: 'Фото транспортного средства',
          value: images.ts,
          component: ImageSliderSmall,
          size: 12,
          class: 'photos'
        }
      ];
      const fields = [
        { label: 'Тип права пользования ТС', value: vehicle.typeTS.text || 'Нет значения' }
      ];
      if (vehicle.typeTS.value !== 'proprietary') {
        fields.push({ label: 'Действует до', value: moment(this.values_head.date).format('L') });
        fields.push({
          label: 'Скан-копия/фото докум.',
          value: images.tsdoc,
          component: ImageSliderSmall,
          class: 'photos'
        });
      }
      let firstRow = [
        {
          label: 'Вид транспортного средства',
          value: utils.transportTypes[this.values_head.type] || 'Неопределен'
        }
      ];
      if (this.values_head.usedOnlyTrain) {
        firstRow = [...firstRow, { label: ' ', value: 'Используется только в составе автопоезда' }];
      }
      let data = [
        [
          { label: 'Марка и модель', value: this.values_head.model },
          { label: 'Регистрационный знак', value: this.values_head.number },
          { label: 'Год выпуска', value: this.values_head.yearManuf },
          {
            label: 'Скан-копия/фото СТС',
            value: images.sts,
            component: ImageSliderSmall,
            class: 'photos'
          }
        ],
        [...fields]
      ];
      if (this.type !== 'train' && this.values_head.type !== 'tractor') {
        data.push(
          [
            {
              label: 'Кузов',
              value: this.getCarcName('values_head'),
              size: 6,
              prev_img: { id: this.values_head.carcass }
            }
          ],
          [{ caption: vehicle.captions.characteristics, size: 12 }],
          [...vehicle.characteristics],
          [...vehicle.options],
          [...vehicle.lifting]
        );
      }
      if (!images.ts.length) {
        if (this.type === 'train') {
          return data;
        }
        return [[...firstRow], ...data];
      }
      if (this.type === 'train') {
        data = [[...firstRow], ...data, photo];
      } else {
        data = [[...firstRow], photo, ...data];
      }
      return data;
    },
    columns_tail() {
      const images = {
        sts: this.photos.filter(item => item.type === 'sts').map(item => this.getUrl(item)),
        tsdoc: this.photos.filter(item => item.type === 'tsdoc').map(item => this.getUrl(item))
      };
      const vehicle = {
        typeTS: this.fields.typeTS.find(item => item.value === this.values_tail.typeTS) || {},
        carcass_head:
          this.fields.carcasses.find(item => item.id === this.values_head.carcass) || {},
        carcass_tail:
          this.fields.carcasses.find(item => item.id === this.values_tail.carcass) || {},
        captions: {
          characteristics: 'Характеристики кузова',
          options: 'Имеющиеся опции',
          lifting: 'Допустимые способы погрузки/разгрузки'
        },
        characteristics: [],
        options: [],
        lifting: []
      };
      if (vehicle.carcass_head.id || vehicle.carcass_tail.id) {
        const viewModel = {
          chr1: 'plus',
          chr2: 'plus',
          chr3: 'plus',
          chr4: 'and',
          chr5: 'and',
          chr6: 'and',
          chr7: 'plus',
          chr8: 'plus',
          chr9: 'plus',
          chr10: 'plus',
          chr11: 'plus',
          chr12: 'and'
        };
        const characteristicsList = _.uniq([
          ...(vehicle.carcass_head.characteristics || []),
          ...(vehicle.carcass_tail.characteristics || [])
        ]);
        characteristicsList.forEach(propValue => {
          const prop = this.fields.characteristics.find(item => item.id === propValue);
          if (
            prop &&
            (this.values_head.properties[prop.id] ||
              this.values_tail.properties[prop.id] ||
              prop.carrier.required)
          ) {
            if (viewModel[prop.id] === 'plus') {
              vehicle.characteristics.push({
                label: `${prop.carrier.name}, ${prop.unit_measure}`,
                value: `${(this.values_head.properties[prop.id] || 0) +
                  (this.values_tail.properties[prop.id] || 0)} (${this.values_head.properties[
                  prop.id
                ] || 0} + ${this.values_tail.properties[prop.id] || 0})`,
                size: 4
              });
            } else {
              vehicle.characteristics.push({
                label: `${prop.carrier.name}, ${prop.unit_measure}`,
                value: `${this.values_head.properties[prop.id] || 0} | ${this.values_tail
                  .properties[prop.id] || 0}`,
                size: 4
              });
            }
          }
        });

        const optionsName = [];
        [vehicle.carcass_head.options || [], vehicle.carcass_tail.options || []].forEach(
          (options, index) => {
            const list = [];
            options.forEach(optValue => {
              const option = this.fields.options.find(item => item.id === optValue);
              if (
                option &&
                ((this.values_head.properties[option.id] && index === 0) ||
                  (this.values_tail.properties[option.id] && index === 1))
              ) {
                list.push(option.name);
              }
            });
            if (list.length > 0) {
              optionsName.push(list.join(', '));
            }
          }
        );
        if (optionsName.length > 0) {
          vehicle.options.push({ caption: vehicle.captions.options, size: 12 });
          vehicle.options.push({ label: '', value: optionsName.join(' | '), size: 12 });
        }

        const liftingName = [];
        [vehicle.carcass_tail.lifting || [], vehicle.carcass_head.lifting || []].forEach(
          (liftingType, index) => {
            const lifting = [];
            liftingType.forEach(liftValue => {
              const lift = this.fields.lifting.find(item => item.id === liftValue);
              if (
                lift &&
                ((this.values_head.properties[lift.id] && index === 0) ||
                  (this.values_tail.properties[lift.id] && index === 1))
              ) {
                lifting.push(lift.name);
              }
              if (lifting.length > 0) {
                liftingName.push(lifting.join(', '));
              }
            });
          }
        );
        if (vehicle.lifting.length > 0) {
          vehicle.lifting.push({ caption: vehicle.captions.lifting, size: 12 });
          vehicle.lifting.push({ label: '', value: liftingName.join(' | '), size: 12 });
        }
      }
      const fields = [
        { label: 'Тип права пользования ТС', value: vehicle.typeTS.text || 'Нет значения' }
      ];
      if (vehicle.typeTS.value !== 'proprietary') {
        fields.push({ label: 'Действует до', value: moment(this.values_tail.date).format('L') });
        fields.push({
          label: 'Скан-копия/фото докум.',
          value: images.tsdoc,
          component: ImageSliderSmall,
          class: 'photos'
        });
      }
      return [
        [
          { label: 'Марка и модель', value: this.values_tail.model },
          { label: 'Регистрационный знак', value: this.values_tail.number },
          { label: 'Год выпуска', value: this.values_tail.yearManuf },
          {
            label: 'Скан-копия/фото СТС',
            value: images.sts,
            component: ImageSliderSmall,
            class: 'photos'
          }
        ],
        fields,
        [
          {
            label: 'Кузов',
            value: this.getCarcName('values_tail'),
            size: 6,
            prev_img: { id: this.values_tail.carcass }
          }
        ],
        [{ caption: vehicle.captions.characteristics, size: 12 }],
        [...vehicle.characteristics],
        [...vehicle.options],
        [...vehicle.lifting]
      ];
    }
  },
  methods: {
    typeTitle(type) {
      return this.fields.types[type];
    },
    getUrl(photo) {
      return `${this.defaultURL}/files/${this.cid}/${this.info.cid}/${photo.medium}`;
    },
    getCarcName(type) {
      const carc = this.fields.carcasses.find(item => item.id === this[type].carcass);
      return carc ? carc.name : 'Неопределен';
    }
  }
};
</script>
<style lang="scss">
@import '~@/assets/scss/variables';
.trucker_ts_content {
  height: 100%;
  .status {
    float: right;
  }
  .trucker_ts-form {
    background-color: white;
    width: 100%;
    overflow: auto;
    height: 100%;
    padding: 30px;

    .train__title,
    .trailer__title {
      font-size: 25px;
      padding: 10px;
    }
    .trucker_ts-caption {
      font-size: 16px;
      padding: 15px 0;
      font-weight: 500;
    }
    .trucker_ts-field {
      margin: 10px 5px;
      &.photos {
        .trucker_ts-value {
          border: 1px solid transparent;
        }
      }
      .trucker_ts-label {
        font-size: 12px;
        min-height: 18px;
        color: $gray;
      }
      .trucker_ts-value {
        font-size: 16px;
        padding: 0;
        i {
          display: block;
          margin-bottom: 3px;
          font-style: normal;
          color: #8b8b8b;
          line-height: 11px;
          font-size: 12px;
        }
        .empty_field {
          input {
            color: red;
          }
        }
        .options_area {
          font-size: 15px;
          border-bottom: 1px solid #949494;
          padding-bottom: 4px;
          span {
            color: red;
          }
        }
        .trucker_ts-value__img {
          padding-top: 20px;
          float: left;
          padding-right: 10px;
        }
        .input-group input:disabled {
          color: black;
        }
        .input-group__details:before {
          background-color: rgba(0, 0, 0, 0.42);
        }
      }
    }
  }
}
</style>
