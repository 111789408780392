<template>
  <div class="autotrain-part">
    <v-layout row wrap class="autotrain-panel__row">
      <v-flex sm2 xs12 tag="h4" class="ui-kit__heading">
        {{ typeTitle }}
      </v-flex>
      <v-flex :class="['autotrain-panel__item__status', verificationStatus.class]" sm10 xs12>
        {{ verificationStatus.text }} {{ verificationStatus.date }}
      </v-flex>
      <v-flex lg2 sm6 xs12>
        <custom-input
          :value="data.vehicle.brand_and_model"
          :disabled="disabledFields"
          label="Марка и модель "
        />
      </v-flex>
      <v-flex lg2 sm6 xs12>
        <custom-input
          :value="data.vehicle.registration_plate"
          :disabled="disabledFields"
          label="Регистрационный знак"
        />
      </v-flex>
      <v-flex lg2 sm6 xs12>
        <custom-input :value="releaseDate" :disabled="disabledFields" label="Год выпуска" />
      </v-flex>
      <v-flex column wrap sm6 xs12>
        <div class="form-autotrain__photolist__label">
          Скан копия / Фото СТС
        </div>
        <div class="autotrain-panel__content__pics">
          <swiper
            v-if="disabledFields"
            :images="currentImages.sts"
            :limit="3"
            height="40px"
            max-width="40px"
            @image-click="open($event, 'sts')"
          />
          <image-uploader
            v-else
            :uploaded-photos="currentImages.sts"
            :additional-payload="{ type: 'sts' }"
            dropzone-class="customdrop dropzone"
            @uploaded="addPhoto(Object.assign($event, { type: 'sts' }))"
            @removed-file="deletePhoto({ src: $event, type: 'sts' })"
          />
        </div>
      </v-flex>
    </v-layout>

    <v-layout row wrap class="autotrain-panel__row">
      <v-flex lg4 sm6 xs12>
        <custom-input
          :value="getTSType"
          :disabled="disabledFields"
          label="Тип права пользования элемента "
        />
      </v-flex>

      <v-flex v-if="data.vehicle.type_right_use !== 'proprietary'" lg2 sm6 xs12>
        <custom-input :value="validDate" :disabled="disabledFields" label="Действует до" />
      </v-flex>

      <v-flex v-if="data.vehicle.type_right_use !== 'proprietary'" column sm6 xs12>
        <div class="form-autotrain__photolist__label">
          Скан копия / Фото докум
        </div>
        <div class="autotrain-panel__content__pics">
          <swiper
            v-if="disabledFields"
            :images="currentImages.tsdoc"
            :limit="3"
            height="40px"
            max-width="40px"
            @image-click="open($event, 'tsdoc')"
          />
          <image-uploader
            v-else
            :uploaded-photos="currentImages.tsdoc"
            :additional-payload="{ type: 'tsdoc' }"
            dropzone-class="customdrop dropzone"
            @uploaded="addPhoto(Object.assign($event, { type: 'tsdoc' }))"
            @removed-file="deletePhoto({ src: $event, type: 'tsdoc' })"
          />
        </div>
      </v-flex>
    </v-layout>
    <swiper-dialog
      v-if="isShow"
      :images="swiperDialogImages"
      :slide-index="slideIndex"
      @close="isShow = false"
    />
  </div>
</template>

<script>
// TODO Refactor (delete unused functional)
import { getUUID } from 'common/helpers';
import moment from 'common/helpers/date';
import { mapState } from 'vuex';
import imageUploader from 'common/components/imageUploader.vue';
import { getVerificationStatus } from 'common/utils';
import { swiperDialog, swiper } from 'common/components/swiper';

export default {
  components: { imageUploader, swiperDialog, swiper },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Array,
      default: () => []
    },
    isOperator: Boolean
  },
  data() {
    return {
      uploadedPhotos: [],
      isShow: false,
      slideIndex: 0,
      currentImages: {
        ts: [],
        sts: [],
        tsdoc: []
      },
      swiperDialogImages: null,
      disabledFields: true
    };
  },
  computed: {
    ...mapState({
      cid: state => state.login.cid,
      defaultURL: state => state.defaultURL
    }),
    releaseDate() {
      return moment(this.data.vehicle.release_year).format('YYYY');
    },
    validDate() {
      return moment(this.data.vehicle.valid_until).format('DD.MM.YYYY');
    },
    getTSType() {
      switch (this.data.vehicle.type_right_use) {
        case 'proprietary': {
          return 'Право собственности';
        }
        case 'leasing': {
          return 'Лизинг';
        }
        case 'lease-contract': {
          return 'Договор аренды';
        }
        default:
          return '';
      }
    },
    typeTitle() {
      switch (this.data.vehicle.vehicle_type) {
        case 'general':
          return 'Одиночное ТС';
        case 'tractor':
          return 'Тягач';
        case 'trailer':
          return 'Прицеп';
        case 'semi-trailer':
          return 'Полуприцеп';
        default:
          return '';
      }
    },
    verificationStatus() {
      return getVerificationStatus(this.data.vehicle);
    }
  },
  created() {
    if (this.isOperator) {
      const cidClients = this.$store.state.clients.info.cid;
      this.routeSrc = `${this.defaultURL}/files/${this.cid}/${cidClients}`;
    } else this.routeSrc = `${this.defaultURL}/files/${this.cid}`;

    this.data.photos.forEach(element => {
      const mappedPhoto = {
        ...element,
        src: `${this.routeSrc}/${element.tiny}`,
        id: `img-${getUUID()}`
      };
      this.currentImages[element.type].push(mappedPhoto);
    });
  },
  methods: {
    open(index, type) {
      this.slideIndex = index;
      this.swiperDialogImages = this.currentImages[type].map(_ => {
        _.src = `${this.routeSrc}/${_.file}`;
        return _;
      });
      this.isShow = true;
    },
    deletePhoto({ src, type }) {
      this.currentImages[type] = this.currentImages[type].filter(_ => _.src !== src);
    },
    addPhoto({ photo, id, src, type }) {
      this.currentImages[type].push({ ...photo, id, src });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.autotrain-part {
  padding: 0 12px;
}

.ml-30 {
  margin-left: 30px;
}

.ui-kit__heading {
  margin: 19px 0;
}

.container.grid-list-lg .layout .flex {
  padding: 0 12px;
}

.form-autotrain {
  &__photoblock {
    display: flex;
    .upload {
      margin-left: 5px;
      .dropzone {
        margin-top: 22px;
      }
    }
  }
  &__photolist {
    position: relative;
    display: flex;
    list-style: none;
    margin: 0;
    &__pic {
      margin-right: 9px;
      img {
        max-height: 40px;
      }
    }
    &__label {
      white-space: nowrap;
      font-size: 12px;
      color: $gray;
      line-height: 14px;
      margin-top: 0;
      margin-bottom: 1px;
    }
  }
  &__photolist.smaller {
    .form-autotrain__photolist__pic {
      margin-right: 9px;
      img {
        max-height: 40px;
        cursor: pointer;
      }
    }
    .form-autotrain__photolist__label {
      top: 2px;
    }
  }
  &__save {
    margin-left: 0;
    margin-top: 15px;
  }
  &__cancel {
    margin-top: 15px;
  }
}

.autotrain-panel {
  margin-top: 40px;
  &__photolabel {
    display: block;
    font-size: 12px;
    color: $gray;
    margin: 0 0 6px 0;
  }
  &__row {
    margin: auto -12px !important;
  }
  &__content {
    padding: 0 12px;
    &__pics {
      @media only screen and (max-width: breakpoint(sm)) {
        margin-bottom: 12px;
      }
    }
    ul {
      padding: 0;
    }
  }
  &__head {
    display: flex;
    align-items: center;
    height: 44px;
    background-color: $gray-lightest;
    border-bottom: 1px solid #eceff3;
    padding-left: 12px;
    &:first-child {
      border-top: 1px solid #eceff3;
    }
  }
  &__item {
    &__label {
      color: $gray-darken;
      font-size: 14px;
      font-weight: 600;
      flex-basis: 58.24%;
    }

    &__status {
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;

      @media only screen and (max-width: breakpoint(sm)) {
        margin-bottom: 16px;
      }
    }
    &__buttons {
      margin-left: auto;
    }
    &__toggle {
      margin-left: 0;
    }
    &__edit,
    &__delete {
      margin: 0 5px 0 0;
      .material-icons {
        color: $color-main;
        font-size: 19px;
      }
    }
  }
}

@media only screen and (max-width: breakpoint(sm)) {
  .ui-kit__heading {
    margin: 19px 0 0 0;
  }
}
</style>
