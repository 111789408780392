<template>
  <div v-if="orders.length">
    <view-window v-if="isWindowShowed" :cid="selectedOrderCid" @close="isWindowShowed = false" />
    <FindOrderView
      v-else-if="isShowOrderView"
      @close="isShowOrderView = false"
      @getCid="setSelecOrderCid"
    />
    <template v-else>
      <FindOrderItem
        v-for="(order, index) in sortedOrdersList"
        :key="index"
        :item="order"
        is-operator
        @click.native="onClick(order)"
      />
    </template>
  </div>
  <stub v-else message="Нет поисков" />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Stub from 'common/components/Stub';
import FindOrderItem from 'common/components/Orders/FindOrderItem';
import ViewWindow from '@/components/common/ViewWindow';
import { ADM_SEARCH_ORDER_LIST } from 'common/api/SearchOrderApi';
import FindOrderView from './FindOrderView';

export default {
  name: 'FindOrdersTabAdmin',
  components: {
    FindOrderItem,
    Stub,
    ViewWindow,
    FindOrderView
  },
  data: () => ({
    activeSearch: null,
    isWindowShowed: false,
    isShowOrderView: false,
    selectedOrderCid: null
  }),
  computed: {
    ...mapState('ordersFind', ['orders']),
    ...mapGetters('ordersFind', ['isInactiveOrder']),
    ...mapGetters('common', ['loading']),
    isLoading() {
      return this.loading(ADM_SEARCH_ORDER_LIST);
    },
    sortedOrdersList() {
      const list = this.orders;
      return list.sort(_ => (this.isInactiveOrder(_.id) ? 1 : -1));
    }
  },
  created() {
    this.getOrdersList();
  },
  methods: {
    ...mapActions('ordersFind', ['getOrdersList', 'getSerachOrdersList', 'setCurrentOrderFind']),
    ...mapActions('orders', ['getScheme']),
    setSelecOrderCid(cid) {
      this.isShowOrderView = false;
      this.isWindowShowed = true;
      this.selectedOrderCid = cid;
    },
    async onClick(order) {
      await this.getScheme();
      this.isShowOrderView = true;
      this.setCurrentOrderFind(order.id);
      this.getSerachOrdersList(order.cid);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.item {
  padding: 16px 12px 0;
  &__actions {
    margin: 0;
  }
}
</style>
