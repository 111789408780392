<template>
  <div class="carcasses">
    <CarcassesDialog
      :show-form="showForm"
      @close="close"
      @publish="publishScheme"
      @delete="deleteScheme"
    />
    <custom-button
      :disabled="!isSuper"
      flat
      color="primary"
      class="ml-3"
      @click="showAddForm = true"
    >
      <v-icon>add_circle_outline</v-icon>
      Добавить
    </custom-button>
    <CarcassesAddForm
      :show-add-form="showAddForm"
      @close="showAddForm = false"
      @submit="addNewScheme"
    />
    <div v-if="isLoading" class="text-center">
      <v-progress-circular indeterminate="indeterminate" color="primary" />
    </div>
    <template v-else>
      <div v-for="(scheme, j) in [draft, current, archive]" :key="j" class="carcasses__category">
        <div class="carcasses__category__title">
          {{ getTitle(scheme) }}
        </div>
        <div class="carcasses__category__content">
          <div
            v-for="(item, i) in scheme"
            :key="i"
            class="carcasses__category__content__item"
            @click="openScheme(item.id)"
          >
            {{ item.version }} {{ item.comment }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import CarcassesDialog from './carcasses/CarcassesDialog';
import CarcassesAddForm from './carcasses/CarcassesAddForm';

export default {
  name: 'Carcasses',
  components: {
    CarcassesDialog,
    CarcassesAddForm
  },
  data() {
    return {
      showForm: false,
      showAddForm: false
    };
  },
  computed: {
    ...mapState('carcasses', ['versions']),
    ...mapGetters('common', ['loading']),
    ...mapState('login', ['isSuper']),
    isLoading() {
      return (
        this.loading('adm_carcasses/get_version') ||
        this.loading('adm_carcasses/new') ||
        this.loading('adm_carcasses/delete')
      );
    },
    current() {
      return this.versions.filter(_ => _.type === 'current');
    },
    archive() {
      return this.versions.filter(_ => _.type === 'archive');
    },
    draft() {
      return this.versions.filter(_ => _.type === 'draft');
    }
  },
  created() {
    this.getSchemes();
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions('carcasses', ['getScheme', 'getSchemes', 'publishScheme', 'deleteScheme']),
    ...mapMutations('carcasses', ['setSettings']),
    getTitle(scheme) {
      const isArchive = scheme.find(_ => _.type === 'archive') ? 'Архив' : '';
      const isCurrent = scheme.find(_ => _.type === 'current') ? 'Текущая схема кузовов' : '';
      const isDraft = scheme.find(_ => _.type === 'draft') ? 'Черновики схем' : '';
      return isArchive || isCurrent || isDraft;
    },
    openScheme(id) {
      this.getScheme(id);
      this.showForm = true;
    },
    async addNewScheme({ version, comment }) {
      const topic = 'adm_carcasses/new';
      const json = {
        version,
        comment
      };
      try {
        this.showAddForm = false;
        await this.createController({ topic, json });
        await this.getSchemes();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    close() {
      this.setSettings();
      this.showForm = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.carcasses {
  .carcasses__category {
    .carcasses__category__title {
      font-size: 24px;
      font-weight: 400;
      margin: 20px 15px;
    }
    .carcasses__category__content {
      .carcasses__category__content__item {
        cursor: pointer;
        padding: 10px 20px;
        border-top: 1px solid gray;
        &:hover {
          background-color: rgba(242, 242, 242, 1);
        }
      }
    }
  }
}
</style>
