import { render, staticRenderFns } from "./Feedbacks.vue?vue&type=template&id=4fe9afd9&scoped=true&"
import script from "./Feedbacks.vue?vue&type=script&lang=js&"
export * from "./Feedbacks.vue?vue&type=script&lang=js&"
import style0 from "./Feedbacks.vue?vue&type=style&index=0&id=4fe9afd9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../cargo-newadmin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe9afd9",
  null
  
)

export default component.exports