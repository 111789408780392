<template>
  <div class="tooltips">
    <custom-button
      class="tooltips__add"
      flat
      color="primary"
      :disabled="showAddForm"
      @click="addItem"
    >
      <v-icon>add_circle_outline</v-icon>
      Добавить
    </custom-button>
    <div class="tooltips__row">
      <v-select
        v-model="currentGroup"
        label="Раздел"
        :items="['Все', ...hintsGroups]"
        placeholder=" "
      />
      <v-radio-group v-model="currentCategory" row>
        <v-radio v-for="(category, i) in categories" :key="i" :label="category" :value="category" />
      </v-radio-group>
    </div>
    <TooltipsForm
      :show-form="showAddForm"
      :groups="hintsGroups"
      :categories="categories"
      @close="closeForm"
      @submit="submitForm"
    />
    <TooltipsTable
      :current-category="currentCategory"
      :current-group="currentGroup"
      :current-key="currentHint.key"
      :hints="hints"
      @edit="editItem"
    >
      <slot>
        <TooltipsForm
          :show-form="showEditForm"
          :groups="hintsGroups"
          :current-hint="currentHint"
          :categories="categories"
          @close="closeForm"
          @delete="deleteHint"
          @submit="submitForm"
        />
      </slot>
    </TooltipsTable>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TooltipsForm from './TooltipsForm';
import TooltipsTable from './TooltipsTable';

export default {
  name: 'Tooltips',
  components: {
    TooltipsForm,
    TooltipsTable
  },
  data() {
    return {
      showAddForm: false,
      showEditForm: false,
      hints: [],
      currentHint: {},
      currentCategory: 'Общая',
      currentGroup: 'Все'
    };
  },
  created() {
    this.getAllTooltips();
    this.hintsGroups = [
      'Работа с заказами',
      'Рейтинги и отзывы',
      'Расчеты с сервисом',
      'Уведомления',
      'Бонусы',
      'Профиль',
      'Транспортные средства',
      'Водители',
      'Помощь'
    ];
    this.categories = ['Общая', 'Грузовладелец', 'Грузоперевозчик'];
  },
  methods: {
    ...mapActions('common', ['createController']),
    addItem() {
      this.currentHint = {};
      this.showAddForm = true;
      this.showEditForm = false;
    },
    closeForm() {
      this.currentHint = {};
      this.showEditForm = false;
      this.showAddForm = false;
    },
    editItem(item) {
      this.currentHint = item;
      this.showEditForm = true;
      this.showAddForm = false;
    },
    async deleteHint(key) {
      const topic = 'hinter/dell_hint';
      const json = { key };
      try {
        await this.createController({ topic, json });
        await this.getAllTooltips();
        this.closeForm();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async getAllTooltips() {
      const topic = 'hinter/get_all_hints';
      try {
        const { hints } = await this.createController({ topic });
        this.hints = Object.keys(hints).map(_ => ({
          key: _,
          ...hints[_]
        }));
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async submitForm({ key, value, group, info, type }) {
      const isEdit = !!Object.keys(this.currentHint).length;
      const topic = isEdit ? 'hinter/change_hint' : 'hinter/new_hint';
      const json = {
        key,
        value,
        group,
        info,
        type
      };
      try {
        await this.createController({ topic, json });
        await this.getAllTooltips();
        this.closeForm();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltips {
  position: relative;
  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
    /deep/ .v-input {
      max-width: 45%;
    }
  }
  /deep/ .v-input--selection-controls.v-input .v-label {
    margin-top: 0;
  }
  /deep/ .v-input--selection-controls {
    margin-top: 10px;
  }
  /deep/ .v-input.v-input--selection-controls.v-input--radio-group {
    display: inline-flex;
    flex: 0 0 auto;
  }
}
</style>
