// TODO - нужен рефактор компонента
<template>
  <v-dialog
    v-if="isVisibleModal"
    :value="isVisibleModal"
    content-class="client_view"
    fullscreen="fullscreen"
    hide-overlay="hide-overlay"
    persistent
    transition="dialog-bottom-transition"
  >
    <div class="client_view__window">
      <v-toolbar dark="dark" color="primary">
        <v-toolbar-title v-if="info.category">{{ info.title }}</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <custom-button v-if="info" icon color="white" :disabled="true" @click="showWarningDialog">
            <v-icon>error_outline</v-icon>
          </custom-button>
          <custom-button v-if="info" icon color="white" :disabled="true" @click="showBanDialog">
            <v-icon>lock</v-icon>
          </custom-button>
          <custom-button icon color="white" @click="closeDialog">
            <v-icon>close</v-icon>
          </custom-button>
        </v-toolbar-items>
      </v-toolbar>
      <div class="client_view__content">
        <v-list class="client_view__left" dense="dense">
          <v-list-tile
            v-for="item in menu"
            :key="item.name"
            class="client_view__menu"
            :class="{ primary: item.name === current_tab, disabled: item.disabled }"
            @click="openTab(item)"
          >
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action v-if="item.badge && parseInt(counts[item.name])">
              <div class="badge" :class="{ badge__red_bg: item.name === 'alerts' }">
                {{ counts[item.name] }}
              </div>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
        <div class="client_view__right">
          <component :is="tab.component" v-if="tab" />
        </div>
      </div>
    </div>
    <v-dialog v-model="dialogDenied" class="ui-kit__dialog" persistent max-width="500px">
      <div class="ui-kit__dialog__header">
        <div class="ui-kit__dialog__title">
          Заблокировать
        </div>
        <v-spacer />
        <custom-button
          class="ui-kit__dialog__close"
          fab="fab"
          small="small"
          color="primary"
          @click.stop="dialogDenied = false"
        >
          <v-icon dark="dark">
            close
          </v-icon>
        </custom-button>
      </div>
      <div class="ui-kit__dialog__content">
        <v-textarea v-model="deniedText" class="ui-kit__input" label="Причина блокировки" />
        <div class="block-btns">
          <custom-button color="primary" large="large" @click="deniedVerification">
            Отказать
          </custom-button>
          <custom-button
            large="large"
            outline="outline"
            color="primary"
            @click.stop="dialogDenied = false"
          >
            Оменить
          </custom-button>
        </div>
      </div>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import profile from './Profile/Profile.vue';
import truckerProfile from './Profile/ProfileTrucker.vue';
import transports from './Transports/Transports.vue';
import transport from './Transport';
import drivers from './Drivers/Drivers.vue';
import contractors from './Contractors/Contractors.vue';
import orders from './Orders/OrdersTab.vue';
import ratings from './Ratings/RatingSection.vue';
import support from './Support/Support.vue';
import notifications from './Notifications/Notifications.vue';
import safety from './Safety/Safety.vue';
import payments from './Payments/Payments.vue';
import bonuses from './Bonuses/Bonuses.vue';
import actions from './Actions/Actions.vue';

export default {
  name: 'ClientInfo',
  data() {
    return {
      isVisibleModal: false,
      dialogDenied: false,
      deniedText: ''
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('clients', ['info', 'counts']),
    isLoading() {
      return this.loading('admin/endusers/info/get');
    },
    menu() {
      return [
        {
          name: 'profile',
          component: profile,
          title: 'Профиль',
          types: ['shipper', 'carrier']
        },
        {
          name: 'profile',
          component: truckerProfile,
          title: 'Профиль',
          types: ['trucker']
        },
        {
          name: 'transports',
          component: transports,
          title: 'Транспортные средства',
          types: ['carrier']
        },
        {
          name: 'drivers',
          component: drivers,
          title: 'Водители',
          types: ['carrier']
        },
        {
          name: 'carrier',
          component: contractors,
          title: 'Перевозчик',
          types: ['trucker']
        },
        {
          name: 'orders',
          component: orders,
          title: 'Заказы',
          types: ['shipper', 'carrier', 'trucker'],
          badge: true
        },
        {
          name: 'transport',
          component: transport,
          title: 'Текущее транспортное средство',
          types: ['trucker'],
          disabled: true
        },
        {
          name: 'ratings',
          component: ratings,
          title: 'Рейтинги, отзывы, диспуты',
          types: ['shipper', 'carrier'],
          badge: true
        },
        {
          name: 'support',
          component: support,
          title: 'Обращения в поддержку',
          types: ['shipper', 'carrier', 'trucker']
        },
        {
          name: 'notifications',
          component: notifications,
          title: 'Уведомления',
          types: ['shipper', 'carrier', 'trucker'],
          badge: true
        },
        {
          name: 'safety',
          component: safety,
          title: 'Оценка надежности',
          disabled: this.info.entity === 'personal_entity',
          types: ['shipper', 'carrier']
        },
        {
          name: 'payments',
          component: payments,
          title: 'Расчеты с сервисом',
          types: ['shipper', 'carrier']
        },
        {
          name: 'bonuses',
          component: bonuses,
          title: 'Бонусы',
          types: ['shipper', 'carrier']
        },
        {
          name: 'actions',
          component: actions,
          title: 'Действия сотрудников сервиса',
          disabled: true,
          types: ['shipper', 'carrier', 'trucker']
        }
      ].filter(item => item.types.indexOf(this.info.category || 'shipper') >= 0);
    },
    tab() {
      return this.menu.find(item => item.name === this.current_tab);
    },
    current_tab() {
      return this.$route.params.stage || 'profile';
    },
    enduser_id() {
      return this.$route.params.enduser || false;
    },
    enduser_category() {
      return this.$route.params.category || false;
    }
  },
  async created() {
    try {
      await this.getInfo({
        enduser: this.enduser_id,
        category: this.enduser_category
      });

      this.isVisibleModal = true;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    ...mapActions('clients', ['getInfo']),
    openTab(stage) {
      if (stage.disabled) {
        return;
      }
      this.$router.push({ name: 'ClientInfoStage', params: { stage: stage.name } });
    },
    closeDialog() {
      this.$router.push({ name: 'Clients' });
    },
    showWarningDialog() {},
    showBanDialog() {
      this.dialogDenied = true;
    },
    deniedVerification() {}
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';
.client_view {
  &.v-dialog--fullscreen {
    width: calc(100% - 310px);
    overflow-y: hidden;
  }
  .client_view__window {
    height: 100%;
    background: $white;
    display: flex;
    flex-direction: column;

    .client_view__content {
      display: flex;
      flex: 1;
      max-height: calc(100% - 52px);

      .client_view__left {
        border-right: 1px solid $color-main;
        overflow-y: auto;

        .client_view__menu {
          &.disabled {
            color: lightgray;
          }
          &.primary {
            color: $white;
          }
        }
      }
      .client_view__right {
        position: relative;
        flex: 1;
      }
    }
  }
}
</style>
