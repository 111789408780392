<template>
  <tabs class="clients" :tabs="tabs" :active="currentTab" @change="changeTab">
    <template>
      <div class="clients__content">
        <div class="clients__info">
          <div class="clients__field">
            <v-text-field
              v-model="filter.search"
              class="clients__input"
              label="Найти"
              @input="filterItems"
            />
            <v-icon class="clients__info__search">
              search
            </v-icon>
          </div>
          <div class="clients__checkbox">
            <v-checkbox
              v-model="filter.urgent"
              label="Только требующие реакции"
              @change="filterItems"
            />
            <v-checkbox
              v-model="filter.blocked"
              label="Только заблокированные"
              @change="filterItems"
            />
          </div>
        </div>
        <div v-if="isLoading && filter.page === 1" class="progress text-xs-center">
          <v-progress-circular indeterminate color="primary" />
        </div>
        <TableList
          v-else
          class="clients__table"
          :values="items"
          :columns="columns"
          :loading="isLoading && filter.page > 1"
          @clickItem="openWindow"
          @scrolled-bottom="changePage"
        >
          <template slot="icon" slot-scope="{ row }">
            <i v-if="row.urgent" class="clients__icon material-icons">error_outline</i>
          </template>
        </TableList>
      </div>
      <client-info v-if="isClientInfo" />
    </template>
  </tabs>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Tabs from '../Layout/Tabs.vue';
import TableList from '../Layout/TableList.vue';
import ClientInfo from './ClientInfo.vue';

export default {
  components: {
    Tabs,
    TableList,
    ClientInfo
  },
  data() {
    return {
      filter: {
        type: this.$route.params.tab || 'all',
        search: '',
        page: 1,
        limit: 50,
        order: 'name',
        urgent: false,
        blocked: false
      },
      currentTab: this.$route.params.tab || 'all',
      tabs: [
        { name: 'all', title: 'Все' },
        { name: 'shipper', title: 'Грузовладельцы' },
        { name: 'carrier', title: 'Перевозчики' },
        { name: 'trucker', title: 'Водители' }
      ],
      columns: [
        {
          title: 'Наименование',
          name: 'name',
          width: '30',
          alignLeft: true
        },
        {
          title: ' ',
          name: 'icon',
          width: '10',
          slot: true
        },
        { title: 'Тип аккаунта', name: 'type_title', width: '10' },
        { title: 'Дата регистрации', name: 'date', width: '15' },
        { title: 'Заказов', name: 'total_orders', default: '-' },
        { title: 'Отзывов', name: 'total_feedbacks', default: '-' },
        { title: 'Диспутов', name: 'total_disputes', default: '-' },
        { title: 'Посл. сотрудник', name: 'last_staffed', default: '-' }
      ]
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('clients', ['items']),
    isClientInfo() {
      return !!this.$route.params.enduser;
    },
    isLoading() {
      return this.loading('admin/endusers/lookup');
    }
  },
  created() {
    this.load();
  },
  methods: {
    ...mapActions('clients', ['sendSearch']),
    openWindow(item) {
      this.$router.push({ name: 'ClientInfo', params: item });
    },
    changeTab(type) {
      this.filter.page = 1;
      this.filter.type = type;
      this.load();
      this.$router.push({ name: 'ClientsTab', params: { tab: type } });
    },
    changePage(count) {
      this.filter.page = count;
      this.load();
    },
    async load() {
      const category = this.filter.type;
      const json = {
        category,
        step: this.filter.page,
        search_term: this.filter.search.trim(),
        blocked: this.filter.blocked,
        urgent: this.filter.urgent
      };
      if (this.filter.page > 1) await this.sendSearch({ json, type: 'current' });
      else await this.sendSearch({ json, type: 'new' });
    },
    filterItems() {
      if (this.isSearch) {
        clearTimeout(this.isSearch);
      }
      this.isSearch = setTimeout(() => {
        this.filter.page = 1;
        this.load();
        this.isSearch = null;
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
.clients {
  .verify {
    color: #009933;
  }
  .wait {
    color: #ffa75c;
  }
  .verify_error {
    color: $color-error;
  }
  .unverified {
    color: $color-error;
  }
  .clients__content {
    height: 100%;
    .clients__info {
      height: 70px;
      display: flex;
      .clients__field {
        display: flex;
        width: 30%;
        margin-right: 10px;
      }
      .clients__checkbox {
        display: flex;
        flex: 1;
        margin-left: 10%;
      }
      .clients__input input {
        padding-right: 25px;
      }
      .clients__info__search {
        height: 20px;
        margin: 23px 10px 0 -25px;
        cursor: pointer;
        &:hover {
          color: $color-main;
        }
      }
    }
    .clients__name {
      display: flex;
      .clients__value {
        flex: 1;
      }
      .clients__icon {
        color: red;
      }
    }
    .clients__table {
      height: calc(100% - 70px);
    }
  }
}
.verify {
  color: #009933;
}
.wait {
  color: #ffa75c;
}
.verify_error {
  color: $color-error;
}
.unverified {
  color: $color-error;
}
</style>
