<template>
  <div class="container-svetofor">
    <div v-if="organizationData.safety" class="svetofor__factors-wrapper">
      <div class="svetofor__factors">
        <icon
          :class="['svetofor__factors__label', organizationData.safety]"
          name="verifiedUser"
          size="24"
        />
        <div class="svetofor__factors__text">
          <h3 :class="organizationData.safety" class="ui-kit__heading">
            {{ safetyTitle }}
          </h3>
          <span>По данным информационного сервиса Контур.Фокус</span>
        </div>
      </div>
      <slot name="button" />
    </div>
    <div v-else class="text-center pa-3">
      <v-progress-circular :size="40" color="primary" indeterminate />
    </div>
    <div
      v-for="(items, type) in mappedSemaphores"
      :key="type"
      :class="{ svetofor__expansion: type === 'recomendations' }"
    >
      <div>
        <div
          v-if="type === 'recomendations'"
          class="svetofor__expansion__head"
          @click="toggleExpansion"
        >
          <h3 class="ui-kit__heading">Рекомендации от "Контур.Фокус"</h3>
          <icon :name="showRecomendations ? 'chevronUp' : 'chevronDown'" size="24" />
        </div>
        <h3 v-else class="ui-kit__heading">
          {{ TYPE_TITLE[type] }}
        </h3>
        <div
          v-for="(item, i) in items"
          v-show="type === 'recomendations' ? showRecomendations : true"
          :key="i"
          :class="{
            svetofor__expansion__content: type === 'recomendations',
            svetofor__item: type !== 'recomendations'
          }"
          class="svetofor__item"
        >
          <icon
            v-if="type !== 'recomendations'"
            :class="['svetofor__item__label', type]"
            name="verifiedUser"
            size="24"
          />
          <div class="svetofor__item__text">
            <h4 v-for="(block, m) in item.block" :key="`${m}-s`" class="ui-kit__heading">
              {{ block }}
            </h4>
            <p v-for="(point, z) in item.points" :key="z">
              {{ point }}
            </p>
            <ul v-if="item.hyphens.length">
              <li v-for="(hyphen, h) in item.hyphens" :key="h">- {{ hyphen }}</li>
            </ul>
            <p v-for="(includeBlock, l) in item.include_block" :key="l">
              {{ includeBlock }}
            </p>
            <ol v-if="item.criteria_one_day_firm.length">
              <li v-for="(criterion, c) in item.criteria_one_day_firm" :key="c">
                {{ criterion }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const TYPE_TITLE = {
  red: 'Негативные факторы',
  yellow: 'Обратить внимание',
  green: 'Благоприятные факты',
  recomendations: 'Рекомендации от "Контур.Фокус"'
};
const SAFETY_TITLE = {
  red: 'Есть негативные факторы',
  yellow: 'Обратите внимание',
  green: 'Негативных факторов нет'
};

export default {
  name: 'Safety',
  props: {
    organizationData: {
      type: Object,
      default: () => ({})
    },
    semaphores: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({ showRecomendations: false }),
  computed: {
    safetyTitle() {
      return SAFETY_TITLE[this.organizationData.safety];
    },
    mappedSemaphores() {
      return Object.keys(this.semaphores).reduce((acc, curr) => {
        return this.semaphores[curr]?.length ? { ...acc, [curr]: this.semaphores[curr] } : acc;
      }, {});
    }
  },
  created() {
    this.TYPE_TITLE = TYPE_TITLE;
  },
  methods: {
    toggleExpansion() {
      this.showRecomendations = !this.showRecomendations;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.container-svetofor {
  padding: 0 12px;

  @media screen and (min-width: breakpoint(lg)) {
    padding: 0;
  }
}

h3.ui-kit__heading {
  margin: 8px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
}

.svetofor {
  &__item {
    display: flex;
    align-items: flex-start;
    margin-top: 15px;

    @media screen and (max-width: breakpoint(sm)) {
      margin-top: 12px;
    }

    &__label {
      margin-right: 10px;
      background-color: transparent !important;
      border-color: transparent !important;
    }
    &__label.red {
      color: $color-danger;
    }
    &__label.yellow {
      color: $color-warning;
    }
    &__label.green {
      color: $color-success;
    }
    &__text {
      h4 {
        margin: 0;
        line-height: 1.3;
      }
      p {
        margin: 5px 0 0 0;
        font-size: 14px;
        line-height: 1.3;
      }
      ol {
        margin: 8px 0 0 26px;
      }
      ul {
        list-style: none;
        margin: 10px 0 0 10px;
        padding: 0;
        li {
          margin: 5px 0;
          &:first-letter {
            margin-right: 5px;
          }
        }
      }
    }
  }

  &__factors {
    display: flex;
    align-items: flex-start;

    &-wrapper {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e9ecf2;

      @media screen and (max-width: breakpoint(sm)) {
        flex-direction: column;
      }
    }

    &__label {
      margin-right: 16px;
      background-color: transparent !important;
      border-color: transparent !important;
    }
    &__label.red {
      color: $color-danger;
    }
    &__label.yellow {
      color: $color-warning;
    }
    &__label.green {
      color: $color-success;
    }
    &__text {
      display: flex;
      flex-direction: column;
      h3 {
        margin: 0;
        line-height: 1.2;
        font-weight: normal;
        font-size: 20px;
        background-color: transparent !important;
        border-color: transparent !important;
      }
      h3.red {
        color: $color-danger;
      }
      h3.yellow {
        color: $color-warning;
      }
      h3.green {
        color: $color-success;
      }
      span {
        font-size: 12px;
        color: $gray;
      }
    }
  }

  &__expansion {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    padding: 5px 12px;
    margin: 20px -12px;
    &__head {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      h3 {
        margin: 0;
      }
      i {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
      }
    }
    &__content {
      ul {
        list-style: none;
        margin: 10px 0 0 10px;
        padding: 0;
        li {
          margin: 5px 0;
          &:first-letter {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>
