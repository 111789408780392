<template>
  <div class="support">
    <custom-input v-model="search" class="support__input" label="Поиск" />
    <div class="support__messages">
      <InfiniteLoading direction="top" @infinite="infiniteHandler">
        <div slot="no-more">
          Сообщений больше нет
        </div>
        <div slot="no-results">
          Нет обращений
        </div>
        <div slot="spinner">
          <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
        </div>
      </InfiniteLoading>
      <div
        v-for="(item, index) in filteredMessages"
        :key="index"
        class="support__message"
        :class="item.author.own ? 'own' : 'other'"
      >
        <div class="support__author">
          {{ getDate(item) }}
        </div>
        <div v-html-safe="item.text" class="support__text" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import moment from 'common/helpers/date';

export default {
  name: 'Support',
  components: {
    InfiniteLoading
  },
  data() {
    return {
      search: ''
    };
  },
  computed: {
    ...mapState('clientSupport', ['messages', 'messagesCount']),
    filteredMessages() {
      if (!this.search) return this.messages;

      return this.messages.map(message => ({
        ...message,
        text: message.text.replace(new RegExp(this.search, 'gi'), '<span class="found">$&</span>')
      }));
    }
  },
  beforeDestroy() {
    this.clearSupportState();
  },
  methods: {
    ...mapActions('clientSupport', ['getMessages']),
    ...mapMutations('clientSupport', ['clearSupportState']),
    async infiniteHandler($state) {
      try {
        if (this.messages.length) await this.getMessages(this.messages[0].message_id);
        else await this.getMessages();

        if (this.messagesCount) $state.loaded();
        else $state.complete();
      } catch (error) {
        $state.complete();
      }
    },
    getDate(item) {
      const date = moment(item.date).format('DD MMMM, HH:mm');
      if (!item.author.own) return `${date} ${item.author.name}`;
      return date;
    }
  }
};
</script>

<style lang="scss" scoped>
.support {
  height: 100%;
  padding: 15px 0 0 30px;
  /deep/ .found {
    background-color: yellow;
  }
  &__input {
    max-width: 20%;
    margin: 10px;
  }
  &__messages {
    height: calc(100% - 110px);
    overflow: auto;
  }
  &__message {
    width: 60%;
    margin: 20px;
    &.own {
      float: left;
      .support__author {
        text-align: left;
      }

      .support__text {
        background-color: #e9ecf2;
      }
    }
    &.other {
      float: right;
      .support__author {
        text-align: right;
      }
      .support__text {
        border-radius: 40px 0 40px 40px;
      }
    }
  }
  &__text {
    background-color: #409fe6;
    border-radius: 0 40px 40px 40px;
    padding: 15px;
    width: 100%;
    word-wrap: break-word;
  }

  &__author {
    color: #999999;
  }
}
</style>
