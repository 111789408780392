<template>
  <div v-if="!loading(routeSettings)" class="payments__settings">
    <div class="settings__title">
      Последовательность использования средств платежа
    </div>
    <div v-for="(item, index) in settings" :key="index" class="settings__row">
      {{ settingsTypes[item] }}
    </div>
    <div v-if="settings.length === 0" class="settings__row_empty">
      Нет настроек для отображения
    </div>
  </div>
  <div v-else class="loading">
    <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState('clientPayments', ['settings', 'routeSettings']),
    ...mapGetters('common', ['loading']),
    enduser_id() {
      return this.$route.params.enduser * 1;
    },
    settingsTypes() {
      return {
        bonuses: 'Бонусный счет',
        internal: 'Внутренний счет',
        cards: 'Банковская карта'
      };
    }
  },
  mounted() {
    this.getSettings(this.enduser_id);
  },
  methods: {
    ...mapActions('clientPayments', ['getSettings'])
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';
.payments__settings {
  overflow: auto;
  height: 100%;

  .settings__title {
    font-size: 25px;
    margin: 25px;
  }
  .settings__row {
    margin: 25px;
    padding: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  }
  .settings__row_empty {
    text-align: center;
    padding: 30px;
    line-height: 16px;
    font-size: 20px;
    color: $gray-light;
  }
}
</style>
