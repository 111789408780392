<template>
  <div :class="['alert', type, hasClose, { clickable }]">
    <icon v-if="hasClose" name="close" class="close" @click.stop="$emit('close', message)" />
    <icon :name="iconName" class="icon" />
    <slot>
      <span>{{ message }}</span>
    </slot>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';

const TYPES = ['danger', 'positive', 'neutral', 'dangerTransparent', 'successTransparent'];

const ICONS = {
  positive: 'checkboxMarkedCircleOutline',
  danger: 'alertCircleOutline',
  neutral: 'informationOutline',
  dangerTransparent: 'alertCircleOutline',
  successTransparent: 'alertCircleOutline'
};

export default {
  name: 'Alert',
  components: {
    Icon
  },
  props: {
    clickable: Boolean,
    message: String,
    hasClose: Boolean,
    type: {
      type: String,
      default: 'neutral',
      validator(val) {
        return TYPES.includes(val);
      }
    }
  },
  computed: {
    iconName() {
      return ICONS[this.type];
    }
  }
};
</script>

<style lang="scss" scoped>
.alert {
  width: calc(100% + 24px);
  margin-left: -12px;
  margin-right: -12px;
  padding: 12px;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
  position: relative;

  &.hasClose {
    padding-right: 32px;
  }
  .close {
    position: absolute;
    cursor: pointer;
    right: 15px;
  }

  &.danger {
    color: #f54f4a;
    background-color: #ffebee;
  }

  &.neutral {
    color: black;
    background-color: #f6f6f6;
    &.clickable {
      cursor: pointer;
      span:not(.icon) {
        color: #009ce9;
        text-decoration: underline;
      }
    }
  }

  &.positive {
    color: #65b38a;
    background-color: #e7f4e9;
  }
  &.dangerTransparent {
    color: #f54f4a;
    height: 44px;
    font-size: 14px;
    box-shadow: none;
  }
  &.successTransparent {
    color: #65b38a;
    height: 44px;
    font-size: 14px;
    box-shadow: none;
  }
}

.icon {
  fill: currentColor;
  margin-right: 10px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
</style>
