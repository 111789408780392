<template>
  <v-text-field
    v-if="inputType === 'input'"
    ref="textField"
    :class="{ masked, disabled, 'not-readable': notReadable }"
    :value="value"
    :disabled="disabled"
    v-bind="$attrs"
    :placeholder="placeholder"
    class="custom-input"
    @input="onInput"
    @keydown="checkOnMinus"
    v-on="$listeners"
  >
    <slot slot="append" name="append" />
  </v-text-field>
  <v-textarea
    v-else-if="inputType === 'textarea'"
    :class="{ disabled }"
    :value="value"
    v-bind="$attrs"
    height="44"
    auto-grow
    class="custom-input"
    v-on="$listeners"
    @input="onInput"
  />
</template>

<script>
export default {
  name: 'CustomInput',
  model: {
    prop: 'value',
    event: 'model-change'
  },
  props: {
    value: [String, Number],
    masked: Boolean,
    disabled: Boolean,
    notReadable: Boolean,
    masks: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ' '
    },
    money: {
      type: String,
      validator(val) {
        return ['integer', 'decimal'].includes(val);
      }
    },
    inputType: {
      type: String,
      default: 'input'
    },
    nameMask: Boolean
  },
  beforeDestroy() {
    this.$emit('before-destroyed');
  },
  mounted() {
    if (this.masked || this.money) {
      const Inputmask = require('inputmask');
      const self = this;
      const fields = this.$refs.textField.$el.querySelectorAll('input');
      const masks = [...this.masks];
      let mask;

      if (this.money === 'decimal') {
        mask = new Inputmask({
          undoOnEscape: false,
          alias: 'numeric',
          allowMinus: false,
          digits: 2,
          positionCaretOnClick: 'none',
          autoGroup: true,
          rightAlign: false
        });
      } else if (this.money === 'integer') {
        mask = new Inputmask({
          undoOnEscape: false,
          numericInput: true,
          mask: function() {
            return '([9][9][9]){*}';
          }
        });
      } else {
        mask = new Inputmask({
          undoOnEscape: false,
          mask: function() {
            return masks.length ? masks : '+7 (999) 999-99-99';
          }
        });
      }

      fields.forEach(field => {
        mask.mask(field);
      });
    }
  },
  methods: {
    checkOnMinus(e) {
      if (this.money && [189, 109, 69].includes(e.keyCode)) e.preventDefault();
    },
    onInput(val) {
      let value = val;
      if (this.nameMask) {
        value = val
          .split(' ')
          .map(_ => {
            const trimmed = _.trim();
            return trimmed ? trimmed[0].toUpperCase() + trimmed.slice(1) : '';
          })
          .join(' ');
      }
      this.$emit('model-change', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.custom-input {
  padding: 18px 0 0 0;
  margin: 0;
  /deep/input:not([type='textarea']) {
    height: 44px;
    font-size: 14px;
    max-height: none;
    padding: 8px 0 12px;
    max-height: none;
  }
  /deep/.v-input__slot {
    margin-bottom: 2px;
  }
  &.code-input /deep/input {
    font-size: 22px;
  }

  &.v-textarea /deep/ .v-label {
    top: 0;
  }
  &.v-textarea /deep/ .v-input__slot {
    height: auto !important;
    min-height: 44px;
  }
}

.custom-input.disabled {
  pointer-events: none;
  font-size: 14px;
  color: #111111;
  /deep/.v-input__control .v-input__slot:before {
    border-image: none;
  }

  &.not-readable input {
    color: rgba(0, 0, 0, 0.38) !important;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.38) !important;
  }
}

.custom-input /deep/.v-input__append-inner {
  display: inline-flex;
  align-items: center;
  margin-top: -4px;
  align-self: auto;
  min-width: 28px;
  min-height: 24px;
  padding: 0;

  .icon {
    color: $color-main;
    font-size: 20px;
  }
}
.custom-input /deep/.v-label--active {
  transform: translateY(-23.5px) scale(0.75);
}
.custom-input /deep/.v-input__control > .v-input__slot:before,
.custom-input /deep/.v-input__control > .v-input__slot:after {
  bottom: 2px;
}

.custom-input.v-input--is-focused,
.custom-input:hover {
  /deep/.theme--light.v-label:not(.error--text) {
    color: $color-main !important;
  }
  /deep/.v-input__control .v-input__slot:after {
    transform: scale(1);
  }
}
.custom-input:not(.error--text) /deep/.v-input__control .v-input__slot:after {
  border-color: $color-main;
}

.custom-input.v-text-field /deep/.v-input__control > .v-input__slot:before,
.custom-input.v-text-field /deep/.v-input__control > .v-input__slot:hover:before {
  border-color: $gray-light;
}

.custom-input.theme--light.v-input--is-disabled:not(.not-readable) /deep/input,
.custom-input.theme--light.v-input--is-disabled:not(.not-readable) /deep/textarea,
.custom-input.theme--light.v-input:not(.v-input--is-disabled) /deep/input,
.custom-input.theme--light.v-input:not(.v-input--is-disabled) /deep/textarea {
  color: #111111 !important;
  opacity: 1 !important;
  -webkit-text-fill-color: #111111;
  &::placeholder {
    -webkit-text-fill-color: #b9b9b9;
    color: #b9b9b9 !important;
    opacity: 1;
  }

  &:-moz-placeholder {
    -webkit-text-fill-color: #b9b9b9;
    color: #b9b9b9 !important;
    opacity: 1;
  }

  &::-webkit-input-placeholder {
    -webkit-text-fill-color: #b9b9b9;
    color: #b9b9b9 !important;
    opacity: 1;
  }

  &::-ms-input-placeholder {
    -webkit-text-fill-color: #b9b9b9;
    color: #b9b9b9 !important;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    -webkit-text-fill-color: #b9b9b9;
    color: #b9b9b9 !important;
    opacity: 1;
  }
}

.custom-input /deep/.v-text-field__details .v-messages {
  min-height: 14px;
}
</style>

<style lang="scss">
/* TODO: fixed in v-autocomplete */
.v-input input {
  max-height: none;
}
/* TODO: fixed in v-select */
.v-select__slot .v-input__append-inner {
  align-self: auto;
}
.error--text {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}
</style>
