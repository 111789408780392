<template>
  <div>
    <ShipperDocumentsPinCode
      :is-operator="isOperator"
      :is-pin-view="isPinView"
      :is-shipper-fl="isShipperFl"
      :is-order-ended="isOrderEnded"
      :show-form-pin="showFormPin"
      @toggle-form="showFormPin = $event"
      @update-pin-list="getPinList"
    />

    <ShippingInvoices
      :is-operator="isOperator"
      :is-shipper-fl="isShipperFl"
      :is-pin-view="isPinView"
      :pin-list="pinList"
      :is-order-ended="isOrderEnded"
    />

    <ShipperDocumentsPinList
      v-if="!isEmptyPinList"
      :is-disabled-pin-delete="isDisabledPinDelete"
      :deleted-pin="deletedPin"
      :pin-list="pinList"
      :points-list="pointsList"
      @delete="deletePin"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

import ShipperDocumentsPinCode from 'common/views/OrderView/OrderDocuments/ShipperDocuments/ShipperDocumentsPinCode';
const ShipperDocumentsPinList = () =>
  import('common/views/OrderView/OrderDocuments/ShipperDocuments/ShipperDocumentsPinList');
import ShippingInvoices from 'common/views/OrderView/OrderDocuments/ShippingInvoices/index';

export default {
  name: 'ShipperDocuments',
  components: {
    ShipperDocumentsPinCode,
    ShipperDocumentsPinList,
    ShippingInvoices
  },
  props: {
    isOperator: Boolean,
    isPinView: Boolean,
    shipper: {
      type: Object,
      required: true,
      validator(value) {
        return !!Object.keys(value).length;
      }
    }
  },
  data() {
    return {
      deletedPin: '',
      showFormPin: false,
      pinList: [],
      isLoading: false
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('orders', {
      info: state => state.orderInfo.info,
      currentOrderCid: state => state.orderInfo.info.cid,
      pointsList: state => state.orderInfo.info.route
    }),

    isEmptyPinList() {
      return !this.pinList.length;
    },

    isShipperFl() {
      return this.shipper.yur_type === 'FL';
    },

    isOrderEnded() {
      return ['canceled', 'done', 'on_dispute', 'dispute_is_done'].includes(this.info.status);
    },

    isDisabledPinDelete() {
      return this.isOrderEnded || this.isOperator || this.isPinView;
    }
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getPinList();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions(['addSnackbar']),

    async deletePin(pin) {
      if (this.isDisabledPinDelete) {
        return;
      }

      const topic = 'order/pin/delete';
      this.deletedPin = pin;
      try {
        await this.createController({
          topic,
          json: { pin, cid: this.currentOrderCid }
        });
        this.pinList = this.pinList.filter(pinItem => pinItem.pin !== pin);
        this.addSnackbar({ message: 'PIN-код удален', type: 'success' });
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
        this.addSnackbar({ message: 'Ошибка на сервере' });
      } finally {
        this.deletedPin = null;
      }
    },

    async getPinList() {
      const topic = 'order/pin/list';

      try {
        const { pins } = await this.createController({
          topic,
          json: { cid: this.currentOrderCid }
        });
        this.pinList = pins;
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}}`, error);
      }
    }
  }
};
</script>
