<template>
  <Tabs class="tabs" :tabs="tabs" :active="`Rating`" />
</template>

<script>
import Tabs from '@/components/Layout/Tabs';
import ClientFeedback from './ClientFeedback.vue';
import dispute from '../Disputs/DisputesAdmin.vue';

export default {
  name: 'RatingSection',
  components: {
    Tabs
  },
  computed: {},
  created() {
    this.tabs = [
      {
        name: 'Rating',
        title: 'Рейтинг',
        component: ClientFeedback,
        options: { about: 'me' }
      },
      {
        name: 'myFeedback',
        title: 'Мои отзывы',
        component: ClientFeedback,
        options: { about: 'contragent' }
      },
      {
        name: 'Dispute',
        title: 'Диспуты',
        component: dispute
      }
    ];
  }
};
</script>
