<template>
  <CarcassesFormAbstraction
    ref="bodyWorksOptionsForm"
    :is-edit-form="isEditForm"
    :is-add-form="isAddForm"
    @submit="addBodyWorkOption"
    @remove="removeBodyWorkOption"
    @close="$emit('close')"
  >
    <v-flex xs12>
      <custom-input
        v-model="form.name"
        label="Название опции кузова"
        :rules="[v => !!v || 'Поле обязательно для заполнения']"
      />
    </v-flex>
    <v-flex xs12>
      <custom-input v-model="form.tooltipCarrier" label="Подсказка ГП" />
    </v-flex>
    <v-flex xs12>
      <custom-input v-model="form.tooltipSender" label="Подсказка ГВ" />
    </v-flex>
    <v-flex xs12>
      <b>Характеристики опций кузова</b>
    </v-flex>
    <v-flex xs12 class="items">
      <v-checkbox
        v-for="(item, i) in optionsCharacteristics"
        :key="i"
        v-model="form.selectedCharacteristics"
        :label="item.carrier.name"
        :value="item.id"
      />
    </v-flex>
  </CarcassesFormAbstraction>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CarcassesFormAbstraction from './CarcassesFormAbstraction';

const createForm = (info = {}) => ({
  name: info?.name ?? '',
  isDefault: info?.default ?? false,
  // eslint-disable-next-line camelcase
  tooltipCarrier: info?.comment_carrier ?? '',
  // eslint-disable-next-line camelcase
  tooltipSender: info?.comment_sender ?? '',
  selectedCharacteristics: info?.characteristics ?? '',
  id: info?.id ?? ''
});

export default {
  name: 'BodyWorksOptionsForm',
  components: {
    CarcassesFormAbstraction
  },
  props: {
    isEditForm: Boolean,
    isAddForm: Boolean,
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: createForm()
    };
  },
  computed: {
    ...mapState('carcasses', ['optionsCharacteristics', 'currentVersionId'])
  },
  watch: {
    info: {
      deep: true,
      immediate: true,
      handler(value) {
        this.form = createForm(value);
      }
    }
  },
  methods: {
    ...mapActions('common', ['createController']),
    async addBodyWorkOption() {
      const topic = 'admin/administration/carcasses/options/update';
      if (!this.$refs.bodyWorksOptionsForm.validate()) return;
      try {
        const json = {
          id: this.currentVersionId,
          record: {
            name: this.form.name,
            default: this.form.isDefault,
            comment_carrier: this.form.tooltipCarrier,
            comment_sender: this.form.tooltipSender,
            characteristics: this.form.selectedCharacteristics
          }
        };
        if (this.form.id.length) json.gid = this.form.id;
        await this.createController({ topic, json });
        this.resetForm();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async removeBodyWorkOption() {
      const topic = 'admin/administration/carcasses/options/remove';
      try {
        const json = {
          id: this.currentVersionId,
          gid: this.form.id
        };
        await this.createController({ topic, json });
        this.resetForm();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    resetForm() {
      this.$refs.bodyWorksOptionsForm.reset();
      this.$emit('close');
      this.$emit('update');
    }
  }
};
</script>
