<template>
  <div ref="listLoader" class="list-loader">
    <slot />
    <div ref="loadHandler" class="load-handler" />
    <transition name="fade-fast">
      <div v-if="showArrowUp && isScrolled" class="arrow-up">
        <icon name="chevronUpThin" size="23" @click="toTop()" />
      </div>
    </transition>
  </div>
</template>

<script>
import { debounce } from 'lodash';

const html = document.documentElement;

export default {
  name: 'ListLoader',
  props: {
    cursor: [String, Number]
  },
  data: () => ({
    observer: null,
    showArrowUp: false,
    isScrolled: false
  }),
  watch: {
    cursor(val, old) {
      this.showArrowUp = true;
      if (val !== old) this.startObserve();
    }
  },
  created() {
    this.checkScrollDebounced = debounce(this.checkScroll, 350);
  },
  mounted() {
    this.observer = new IntersectionObserver(this.callback);
    this.startObserve();
    window.addEventListener('scroll', this.checkScrollDebounced);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkScrollDebounced);
  },
  methods: {
    callback([entrie], observer) {
      if (entrie.isIntersecting) {
        observer.unobserve(this.$refs.loadHandler);
        this.$emit('load-more');
      }
    },
    startObserve() {
      this.observer.observe(this.$refs.loadHandler);
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    checkScroll() {
      this.isScrolled = html.scrollTop > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.list-loader {
  position: relative;
  margin: 0;
  @media screen and (max-width: breakpoint(sm)) {
    margin: 0;
  }
}

.arrow-up {
  position: fixed;
  bottom: 20px;
  right: 22px;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $color-main;
  background-color: #fff;
  @media screen and (max-width: breakpoint(sm)) {
    bottom: 10px;
    right: 10px;
  }
  &:hover {
    /deep/svg path {
      fill: #027fbd !important;
    }
  }
}
</style>
