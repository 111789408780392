<template>
  <v-layout
    v-if="showForm || isEdit"
    v-model="formValid"
    row
    wrap
    tag="v-form"
    class="form"
    @submit.prevent="$emit('submit')"
  >
    <v-flex xs4>
      <custom-input
        :rules="[v => !!v || 'Поле обязательно для заполнения']"
        label="Фамилия"
        :value="lastname"
        @input="
          $emit('input', {
            field: 'lastname',
            value: $event
          })
        "
      />
    </v-flex>
    <v-flex xs4>
      <custom-input
        :rules="[v => !!v || 'Поле обязательно для заполнения']"
        label="Имя"
        :value="firstname"
        @input="
          $emit('input', {
            field: 'firstname',
            value: $event
          })
        "
      />
    </v-flex>
    <v-flex xs4>
      <custom-input
        :rules="[v => !!v || 'Поле обязательно для заполнения']"
        label="Отчество"
        :value="midname"
        @input="
          $emit('input', {
            field: 'midname',
            value: $event
          })
        "
      />
    </v-flex>
    <v-flex xs4>
      <custom-input
        :rules="[v => !!v || 'Поле обязательно для заполнения']"
        label="E-mail"
        :suffix="mailSuffix"
        :value="email"
        :readonly="isEdit"
        @input="
          $emit('input', {
            field: 'email',
            value: $event
          })
        "
      />
    </v-flex>
    <v-flex xs4>
      <v-select
        :rules="[v => !!v || 'Поле обязательно для заполнения']"
        label="Группа"
        placeholder=" "
        :items="groupsList"
        item-text="name"
        item-value="name"
        return-object
        :disabled="group === 'root'"
        :value="group"
        @change="
          $emit('input', {
            field: 'group',
            value: $event
          })
        "
      />
    </v-flex>
    <v-flex xs12>
      <custom-button
        v-if="!isEdit"
        large
        :disabled="!formValid"
        color="primary"
        class="ml-0"
        @click="$emit('submit', 'add')"
      >
        Добавить
      </custom-button>
      <custom-button
        v-if="isEdit"
        :disabled="!formValid || disabledButton"
        large
        color="primary"
        class="ml-0"
        @click="$emit('submit', 'update')"
      >
        Сохранить
      </custom-button>
      <custom-button
        v-if="isEdit"
        :disabled="!formValid || disabledButton"
        large
        color="primary"
        class="ml-0"
        @click="$emit('submit', 'password')"
      >
        Новый пароль
      </custom-button>
      <custom-button
        v-if="isEdit"
        large
        color="primary"
        class="ml-0"
        :disabled="group === 'root' || !isSuper"
        @click="$emit('submit', 'delete')"
      >
        Удалить
      </custom-button>
      <custom-button large outline color="primary" class="ml-0" @click="$emit('close')">
        Отмена
      </custom-button>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UsersForm',
  props: {
    firstname: String,
    lastname: String,
    midname: String,
    email: String,
    group: String,
    gid: [Number, String],
    showForm: Boolean,
    mailSuffix: String,
    phase: String,
    isEdit: Boolean
  },
  data() {
    return {
      formValid: false
    };
  },
  computed: {
    ...mapState('users', ['groupsList']),
    ...mapState('login', ['emailUser', 'isSuper']),
    disabledButton() {
      if (this.group === 'root') {
        const isMyForm = this.emailUser === this.email + this.mailSuffix;
        return !isMyForm;
      }
      return !this.isSuper;
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}
</style>
