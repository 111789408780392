<template>
  <div class="container">
    <v-flex xs12>
      <v-checkbox
        :value="checkBirthdayPlacePasLic"
        reverse
        :false-value="false"
        :true-value="true"
        :rules="[v => !!v || 'Поле обязательно для ввода']"
        @change="
          $emit('changed', {
            field: 'checkBirthdayPlacePasLic',
            value: $event
          })
        "
      >
        <span slot="prepend">
          Совпадение места рождения по паспорту и водительскому удостоверению
        </span>
      </v-checkbox>
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        :value="checkBirthdayDatePasLic"
        :false-value="false"
        :true-value="true"
        :rules="[v => !!v || 'Поле обязательно для ввода']"
        @change="
          $emit('changed', {
            field: 'checkBirthdayDatePasLic',
            value: $event
          })
        "
      >
        <span slot="prepend">
          Совпадение даты рождения по паспорту и водительскому удостоверению
        </span>
      </v-checkbox>
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        :value="checkPhoto"
        :false-value="false"
        :true-value="true"
        :rules="[v => !!v || 'Поле обязательно для ввода']"
        @change="
          $emit('changed', {
            field: 'checkPhoto',
            value: $event
          })
        "
      >
        <span slot="prepend">
          Фотографии пользователя совпадают во всех документах
        </span>
      </v-checkbox>
    </v-flex>
  </div>
</template>

<script>
export default {
  name: 'MainCheckboxes',
  props: {
    checkBirthdayPlacePasLic: Boolean,
    checkBirthdayDatePasLic: Boolean,
    checkPhoto: Boolean
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
</style>
