<template>
  <div v-if="isLoading" class="text-xs-center pt-3">
    <v-progress-circular :size="40" color="primary" indeterminate />
  </div>
  <trucker v-else :trucker="trucker" :photos="photosTrucker" />
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Trucker from 'common/components/Trucker.vue';

export default {
  name: 'TruckerWrapper',
  components: { Trucker },
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState(['defaultURL']),
    ...mapState('login', ['cid']),
    ...mapState('orders', {
      trucker: state => state.orderInfo.trucker,
      photosTrucker: state => state.orderInfo.photosTrucker
    }),
    isLoading() {
      return this.loading('truckers/trucker/get');
    }
  },
  mounted() {
    this.getAcceptedTruckerInfo();
  },
  methods: {
    ...mapActions('orders', ['getAcceptedTruckerInfo'])
  }
};
</script>
