<template>
  <v-form v-if="showForm" v-model="validForm" class="form" @submit.prevent="submitForm">
    <v-radio-group v-model="form.tooltipCategory">
      <v-radio v-for="(category, i) in categories" :key="i" :label="category" :value="category" />
    </v-radio-group>
    <custom-input
      v-model="form.tooltipText"
      label="Текст подсказки"
      :rules="[v => !!v || 'Поле является обязательным для ввода']"
    />
    <custom-input
      v-model="form.tooltipKey"
      :readonly="isEdit"
      label="Ключ подсказки"
      :rules="[
        v => !!v || 'Поле является обязательным для ввода',
        v => /^[a-z_-]+$/i.test(v) || 'Может содержать только a-z _ -'
      ]"
    />
    <v-select
      v-model="form.tooltipGroup"
      :rules="[v => !!v || 'Поле является обязательным для ввода']"
      label="Раздел"
      :items="groups"
      placeholder=" "
    />
    <custom-input
      v-model="form.tooltipDescription"
      :rules="[v => !!v || 'Поле является обязательным для ввода']"
      label="Описание подсказки"
      input-type="textarea"
      rows="3"
    />
    <custom-button
      class="ml-0"
      color="primary"
      :disabled="!validForm"
      large
      type="submit"
      :loading="isLoading.add"
    >
      {{ isEdit ? 'Сохранить' : 'Добавить подсказку' }}
    </custom-button>
    <custom-button
      v-if="isEdit"
      large
      color="primary"
      :loading="isLoading.delete"
      @click.stop="$emit('delete', form.tooltipKey)"
    >
      Удалить
    </custom-button>
    <custom-button color="primary" large outline @click="$emit('close')">
      Отменить
    </custom-button>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';

const createForm = (data = {}) => ({
  tooltipText: data?.value ?? '',
  tooltipKey: data?.key ?? '',
  tooltipDescription: data?.info ?? '',
  tooltipGroup: data?.group ?? '',
  tooltipCategory: data?.type ?? 'Общая'
});

export default {
  name: 'TooltipsForm',
  props: {
    showForm: Boolean,
    groups: {
      type: Array,
      default: () => []
    },
    currentHint: {
      type: Object,
      default: () => ({})
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      validForm: false,
      form: createForm()
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    isLoading() {
      return {
        add: this.loading('hinter/new_hint') || this.loading('hinter/change_hint'),
        delete: this.loading('hinter/dell_hint')
      };
    },
    isEdit() {
      return !!Object.keys(this.currentHint).length;
    }
  },
  watch: {
    currentHint: {
      deep: true,
      immediate: true,
      handler(value) {
        this.form = createForm(value);
      }
    }
  },
  methods: {
    submitForm() {
      this.$emit('submit', {
        key: this.form.tooltipKey,
        value: this.form.tooltipText,
        group: this.form.tooltipGroup,
        info: this.form.tooltipDescription,
        type: this.form.tooltipCategory
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.349019607843137);
  padding: 12px 16px;
  /deep/ .v-input {
    margin-bottom: 6px;
  }
  /deep/ .v-input--selection-controls.v-input .v-label {
    margin-top: 0;
  }
}
</style>
