const state = {
  questionsList: {}
};

const actions = {
  async getQuestionsList({ dispatch, commit }) {
    const topic = 'faq/get';
    try {
      const { content } = await dispatch('common/createController', { topic }, { root: true });
      commit('setLists', content);
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  saveQuestions({ dispatch, state }) {
    dispatch(
      'common/createController',
      {
        topic: 'faq/save',
        json: {
          content: state.questionsList
        }
      },
      { root: true }
    );
  }
};

const mutations = {
  setLists(state, data) {
    state.questionsList = data;
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
