const state = {
  notifications: [],
  template: null
};

const actions = {
  async getNotificationList({ dispatch, commit }) {
    try {
      commit('setNotificationList', []);
      const response = await dispatch(
        'common/createController',
        {
          topic: 'adm_notification/new_list'
        },
        { root: true }
      );
      commit('setNotificationList', response.groups);
    } catch (error) {
      console.error(error);
    }
  },
  async getNotificationTemplate({ dispatch, commit }, json) {
    const topic = 'adm_notification/template';
    try {
      const data = await dispatch('common/createController', { topic, json }, { root: true });
      await commit('setTemplate', null);
      commit('setTemplate', data.template);
      return data;
    } catch (error) {
      console.error(error);
    }
  },
  async notificationSave({ dispatch }, json) {
    const topic = 'adm_notification/save';
    try {
      await dispatch('common/createController', { topic, json }, { root: true });
    } catch (error) {
      console.error(`Ошибка в запросе ${topic}`, error);
    }
  },
  async changeGroup({ dispatch, commit }, json) {
    try {
      const response = await dispatch(
        'common/createController',
        {
          topic: 'adm_notification/change_group',
          json
        },
        { root: true }
      );
      commit('setNotificationList', response.groups);
    } catch (error) {
      console.error(error);
    }
  }
};
const mutations = {
  setNotificationList(state, data) {
    const types = {
      adm_carrier: 'Администратор перевозчик',
      user_carrier: 'Пользователь перевозчик',
      adm_shipper: 'Администратор грузовладелец',
      user_shipper: 'Пользователь грузовладелец',
      trucker: 'Водитель'
    };
    const mappedData = { ...data };
    Object.keys(mappedData).forEach(key => {
      Object.keys(mappedData[key]).forEach(_ => {
        mappedData[key][_].receivers = mappedData[key][_].receivers.map(notification => ({
          key: notification,
          title: types[notification]
        }));
      });
    });
    state.notifications = mappedData;
  },
  setTemplate(state, data) {
    state.template = data;
  },
  setTemplateItem(state, { key, val }) {
    state.template[key] = val;
  }
};
export default {
  state,
  actions,
  mutations,
  namespaced: true
};
