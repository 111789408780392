<template>
  <div>
    <VehicleRequirements />
    <RouteData :is-operator="isOperator" />
    <Characteristics />
    <ContentBlock
      v-if="additionalServiceIsAvailable"
      subtitle-tag="h2"
      subtitle="Дополнительные услуги"
    >
      <lineWithDots
        v-for="(value, key) in additionalService"
        :key="key"
        :title="getAdditionalName(value)"
        :description="formatToString(value)"
        width-title="500"
      />
    </ContentBlock>
    <ContentBlock v-if="info.comment" subtitle-tag="h2" subtitle="Примечание">
      <div>{{ info.comment }}</div>
    </ContentBlock>
    <ContentBlock v-if="isShipper && contactLeadCid" subtitle-tag="h2" subtitle="Контакты">
      <v-select
        ref="contacts"
        v-model="contactLeadCid"
        :menu-props="{ nudgeBottom: 48 }"
        :items="fullConfirmedUsersList"
        class="select"
        item-value="cid"
        disabled
        placeholder=" "
      >
        <template v-for="slot in ['item', 'selection']" :slot="slot" slot-scope="{ item }">
          <div :key="slot" class="select__item">
            <span class="select__text">{{ getItemsText(item) }}</span>
          </div>
        </template>
      </v-select>
      <custom-button
        v-if="isContactLeadDisabled"
        :disabled="isDisabled"
        :loading="isContactLeadLoading"
        class="edit-button"
        flat
        icon
        color="primary"
        @click="toggleOpen"
      >
        <icon name="pencil" size="20" />
      </custom-button>
      <custom-button
        v-else
        :disabled="isDisabled"
        :loading="isContactLeadLoading"
        class="edit-button"
        flat
        icon
        color="primary"
        @click="toggleClose"
      >
        <icon name="checkSimple" size="20" />
      </custom-button>
    </ContentBlock>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { plural } from '@/components/helpers/index.js';
import VehicleRequirements from './OrderView/OrderInfo/VehicleRequirements.vue';
import RouteData from './OrderView/OrderInfo/RouteData.vue';
import Characteristics from './OrderView/OrderInfo/Characteristics.vue';
import ContentBlock from 'common/components/Orders/ContentBlock.vue';
import lineWithDots from 'common/components/Orders/lineWithDots.vue';
import { isBefore } from 'date-fns';

export default {
  name: 'OrderInfo',
  components: {
    ContentBlock,
    VehicleRequirements,
    RouteData,
    Characteristics,
    lineWithDots
  },

  props: {
    isOperator: Boolean
  },

  data() {
    return {
      isContactLeadLoading: false,
      isContactLeadDisabled: true,
      contactLeadCid: ''
    };
  },

  computed: {
    ...mapState('orders', {
      info: state => state.orderInfo.info
    }),
    ...mapGetters('errors', ['getError']),
    ...mapGetters('login', ['isShipper']),
    ...mapState('login', ['isAdmin']),
    ...mapGetters('users', ['fullConfirmedUsersList']),

    isDisabled() {
      if (!this.isAdmin) {
        return !this.isAdmin;
      }

      const isCanOpenDispute =
        this.info.status === 'canceled' &&
        isBefore(new Date(), new Date(this.info.dispute_open_deadline));

      return (
        !(
          ['accepted', 'on_dispute', 'in_process'].includes(this.info.status) || isCanOpenDispute
        ) || this.isContactLeadLoading
      );
    },

    additionalServiceIsAvailable() {
      return this.info.additional_service?.length;
    },

    additionalService() {
      const [additionalService = {}] = this.info.additional_service;
      return additionalService;
    }
  },

  created() {
    this.contactLeadCid = this.info.ccontact_user_id;
  },

  methods: {
    ...mapActions('orders', ['getOrder']),
    ...mapActions('common', ['createController']),
    ...mapActions(['addSnackbar']),
    formatToString(value) {
      const elevatorMap = {
        nonexistent: 'отсутствует',
        freight: 'грузовой',
        passenger: 'пассажирский'
      };

      if (!value) {
        return 'не задано';
      }

      if (typeof value === 'number') {
        return `${plural(value, 'человек', 'человека', 'человек')}`;
      }

      if (
        value?.movers_number !== null &&
        value?.floor_number !== null &&
        elevatorMap[value.elevator] !== null
      ) {
        return `количество грузчиков ${value?.movers_number}, этаж ${value?.floor_number}, лифт ${
          elevatorMap[value.elevator]
        }`;
      }

      return 'не задано';
    },

    getAdditionalName(value) {
      if (!value?.name) {
        return 'Количество мест для сопровождающих';
      }

      return value.name;
    },

    getItemsText({ firstName, lastName, name, lastname, phone, email } = {}) {
      return `${lastName || lastname} ${firstName || name} ${phone}, ${email}`;
    },

    async changeContact() {
      this.isContactLeadLoading = true;
      const topic = 'order/change';
      const json = {
        corder_id: this.info.cid,
        ccontact_user_id: this.contactLeadCid
      };
      try {
        await this.createController({ topic, json });
        await this.getOrder();
        this.addSnackbar({ message: 'Изменения сохранены', type: 'success' });
      } catch ({ status: { reason } }) {
        this.addSnackbar({ message: this.getError({ error: reason }), type: 'error' });
      } finally {
        this.isContactLeadLoading = false;
      }
    },

    toggleOpen() {
      this.$refs.contacts.isMenuActive = true;
      this.isContactLeadDisabled = false;
    },

    async toggleClose() {
      if (this.contactLeadCid !== this.info.ccontact_user_id) {
        await this.changeContact();
      }
      this.$refs.contacts.isMenuActive = false;
      this.isContactLeadDisabled = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

/deep/.content-block__content {
  padding: 0 12px;
}

.edit-button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.select {
  padding: 0 !important;
  &__item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: breakpoint(sm)) {
      flex-direction: column;
    }
  }
  &__text {
    color: #111111;
    @media screen and (max-width: breakpoint(sm)) {
      margin: 12px 0 6px;
    }
  }
  &__status {
    color: $color-verified;
    padding: 0 12px 0 0;
    @media screen and (max-width: breakpoint(sm)) {
      padding: 0 12px 12px 0;
    }
  }
  /deep/.v-input__icon {
    margin-right: 40px;
  }
  &:not(.v-select--is-menu-active) /deep/.v-input__icon {
    opacity: 0;
  }
}
</style>
