<template>
  <v-container class="trucker">
    <v-layout wrap class="header negative-margins">
      <v-flex xs12 sm6 class="header-info">
        <img
          :src="`${defaultURL}/files/${cid}/${trucker.cid}/${userAvatarPhoto.tiny}`"
          alt=""
          class="header-info__avatar"
          @click="openDialog([userAvatarPhoto], 0)"
        />
        <div class="header-info__info">
          <p class="trucker-name">{{ trucker.name }} {{ trucker.lastname }}</p>
          <p :class="verificationStatus.class" class="verify-info">
            {{ verificationStatus.text }} {{ verificationStatus.date }}
          </p>
        </div>
      </v-flex>
      <v-flex xs12 sm6 class="header-phone">
        {{ phoneMask }} {{ trucker.email ? `, ${trucker.email}` : '' }}
      </v-flex>
    </v-layout>
    <div class="info-trucker">
      <h3 class="ui-kit__heading">
        Паспортные данные водителя
      </h3>
      <v-layout row wrap class="negative-margins">
        <v-flex xs6 sm6 md1>
          <custom-input :value="trucker.passport_series" disabled label="Серия" />
        </v-flex>
        <v-flex xs6 sm6 md2>
          <custom-input :value="trucker.passport_number" disabled label="Номер" />
        </v-flex>
        <v-flex xs6 sm6 md2>
          <custom-input :value="passportDate" disabled label="Дата выдачи" />
        </v-flex>
        <v-flex xs6 sm6 md2>
          <custom-input :value="passportBirthday" disabled label="Дата рождения" />
        </v-flex>
        <v-flex xs12 sm6 md2>
          <div>
            <ul class="trucker-photolist smaller">
              <li class="trucker-photolist__label">
                Скан копия / Фото докум.
              </li>
              <li
                v-for="(item, index) in userPassportPhotos"
                :key="index"
                class="trucker-photolist__pic"
              >
                <img
                  :src="`${defaultURL}/files/${cid}/${trucker.cid}/${item.tiny}`"
                  @click="openDialog(userPassportPhotos, index)"
                />
              </li>
            </ul>
          </div>
        </v-flex>
      </v-layout>
      <h3 class="ui-kit__heading">
        Водительское удостоверение
      </h3>
      <v-layout row wrap class="negative-margins">
        <v-flex xs6 sm6 md1>
          <custom-input :value="trucker.license_series" disabled label="Серия" />
        </v-flex>
        <v-flex xs6 sm6 md2>
          <custom-input :value="trucker.license_number" disabled label="Номер" />
        </v-flex>
        <v-flex xs6 sm6 md2>
          <custom-input :value="licenseDate" disabled label="Дата выдачи" />
        </v-flex>
        <v-flex xs6 sm6 md2>
          <div>
            <ul class="trucker-photolist smaller">
              <li class="trucker-photolist__label">
                Скан копия / Фото докум.
              </li>
              <li
                v-for="(item, index) in userLicensePhotos"
                :key="index"
                class="trucker-photolist__pic"
              >
                <img
                  :src="`${defaultURL}/files/${cid}/${trucker.cid}/${item.tiny}`"
                  @click="openDialog(userLicensePhotos, index)"
                />
              </li>
            </ul>
          </div>
        </v-flex>
      </v-layout>
    </div>
    <swiper-dialog
      v-if="isOpenDialog"
      :url-array="swiperDialogImages"
      :slide-index="slideIndex"
      @close="isOpenDialog = false"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { getUTCDate } from 'common/helpers/dateHelper';
import lightFormat from 'date-fns/lightFormat';
import { getVerificationStatus } from 'common/utils';
import { swiperDialog } from 'common/components/swiper';

export default {
  name: 'TruckerInfo',
  components: { swiperDialog },
  props: {
    trucker: {
      type: Object,
      default: () => ({})
    },
    photos: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    isOpenDialog: false,
    swiperDialogImages: null,
    slideIndex: 0
  }),
  computed: {
    ...mapState({
      cid: state => state.login.cid,
      defaultURL: state => state.defaultURL
    }),
    passportDate() {
      return lightFormat(getUTCDate(this.trucker.passport_date), 'dd.MM.yyyy');
    },
    licenseDate() {
      return lightFormat(getUTCDate(this.trucker.license_date), 'dd.MM.yyyy');
    },
    passportBirthday() {
      return lightFormat(getUTCDate(this.trucker.passport_birthday), 'dd.MM.yyyy');
    },
    phoneMask() {
      return this.trucker.phone
        ? this.trucker.phone.replace(
            /\(?(\d{1})\)?[- ]?(\d{3})[- ]?(\d{3})[- ]?(\d{2})/g,
            '$1 ($2) $3-$4-'
          )
        : '';
    },
    verificationStatus() {
      return getVerificationStatus(this.trucker);
    },
    userAvatarPhoto() {
      return this.photos.find(_ => _.type === 'my_photo') || {};
    },
    userPassportPhotos() {
      return (
        this.photos.filter(_ => ['passport_registration', 'passport_photo'].includes(_.type)) || []
      );
    },
    userLicensePhotos() {
      return (
        this.photos.filter(_ => ['driver_license_photo', 'driver_license_back'].includes(_.type)) ||
        []
      );
    }
  },
  methods: {
    openDialog(array, index) {
      const imagesArray = [];
      array.forEach(element => {
        imagesArray.push(
          `${this.defaultURL}/files/${this.cid}/${this.trucker.cid}/${element.file}`
        );
      });
      this.swiperDialogImages = imagesArray;
      this.isOpenDialog = true;
      this.slideIndex = index;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

img {
  cursor: pointer;
}

.header {
  padding-bottom: 16px;
  border-bottom: 1px solid $gray-lighten;

  &-info {
    display: flex;
    align-items: center;

    &__avatar {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      border: 1px solid $gray-lighten;
      object-fit: cover;
      display: block;
      flex-shrink: 0 !important;
      margin-right: 10px;
    }

    .verify-info {
      font-size: 12px;
      margin-bottom: 0;
      font-weight: normal;
    }
  }

  &-phone {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: breakpoint(sm)) {
      justify-content: flex-start;
      margin-left: 54px;
    }
  }

  .trucker-name,
  .header-phone {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
  }
}

.trucker {
  padding: 0 12px;
  &-photolist {
    position: relative;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 20px 0 0 0;
    &__pic {
      margin-right: 9px;
      img {
        height: 40px;
      }
    }
    &__label {
      position: absolute;
      top: 0;
      white-space: nowrap;
      font-size: 12px;
      color: $gray;
    }
  }
  &-photolist.smaller {
    .trucker-photolist__pic {
      margin-right: 9px;
      margin-top: 2px;
      img {
        height: 40px;
      }
    }
    .trucker-photolist__label {
      top: -3px;
    }
  }
  &__save {
    margin-left: 0;
    margin-top: 15px;
  }
  &__cancel {
    margin-top: 15px;
  }
}

.ui-kit__heading {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  .material-icons {
    color: $color-main;
    margin-left: 20px;
  }
}
</style>
