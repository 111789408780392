import { setField } from 'common/store/helpers';

const state = {
  groupsList: [],
  usersList: []
};
const actions = {
  async getGroupsList({ commit, dispatch }) {
    const topic = 'admin/administration/access_groups/list';
    try {
      const { list } = await dispatch('common/createController', { topic }, { root: true });
      commit('setField', { field: 'groupsList', value: list });
    } catch (error) {
      console.error(`Ошибка в запросе ${topic}`, error);
    }
  },
  async getUsersList({ commit, dispatch }) {
    const topic = 'admin/administration/users/list';
    try {
      const { list } = await dispatch('common/createController', { topic }, { root: true });
      commit('setField', { field: 'usersList', value: list });
    } catch (error) {
      console.error(`Ошибка в запросе ${topic}`, error);
    }
  }
};
const mutations = {
  setField
};
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
