<template>
  <v-container grid-list-xl fluid>
    <div v-for="(group, nameGroup, index) in notifications" :key="nameGroup" class="notifications">
      <div class="notifications__header">
        <custom-input
          v-if="isEditGroup && index === currentIndex"
          v-model="editedNameGroup"
          label="Наименование группы"
          placeholder=""
        />
        <h2 v-else class="notifications__title" @click="toggleGroup(nameGroup)">
          {{ nameGroup }}
        </h2>
        <div class="notifications__actions">
          <custom-button
            v-if="isEditGroup && index === currentIndex"
            icon
            color="primary"
            class="notifications__edit"
            @click.stop="toggleEditGroup({ index, value: nameGroup, state: false })"
          >
            <v-icon>check</v-icon>
          </custom-button>
          <custom-button
            v-else
            icon
            color="primary"
            class="notifications__edit"
            :disabled="!isSuper"
            @click.stop="toggleEditGroup({ index, value: nameGroup, state: true })"
          >
            <v-icon>edit</v-icon>
          </custom-button>
        </div>
      </div>
      <template v-if="currentGroup === nameGroup">
        <div v-for="(item, p) in group" :key="p" class="notifications__item">
          <div class="notifications__label">
            <p class="notifications__label__text" @click="openEditForm(item)">
              {{ item.title }}
            </p>
            <custom-button
              flat
              icon
              color="primary"
              class="edit-input"
              :disabled="!isSuper"
              @click="openModalChangeGroup({ number: item.number, title: item.title })"
            >
              <v-icon>edit</v-icon>
            </custom-button>
          </div>
          <notification-form
            v-if="current && current.number === item.number"
            :notification="current"
            @close="current = null"
          />
          <div
            v-if="modalChangeGroup && currentNumber === item.number"
            class="notifications__block"
          >
            <div class="notifications__block__header">Группа для назначения</div>
            <div class="notifications__block__content">
              <custom-input v-model="currentTitle" label="Название уведомления" />
              <v-select
                v-model="selectedGroup"
                :disabled="toNewGroup"
                :items="groups"
                label="Выбрать группу уведомлений"
                placeholder=" "
                :menu-props="{ nudgeBottom: 48 }"
              />
              <v-checkbox v-model="toNewGroup" label="Создать новую группу" />
              <custom-input
                v-if="toNewGroup"
                v-model="newGroupName"
                label="Введите имя новый группы"
              />
              <div>
                <custom-button :loading="isLoading" color="primary" large @click="saveChangeGroup">
                  Применить
                </custom-button>
                <custom-button color="primary" large @click="modalChangeGroup = false">
                  Отмена
                </custom-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import NotificationForm from './notification/NotificationForm';

export default {
  name: 'Notifications',
  components: {
    NotificationForm
  },
  data() {
    return {
      selectedGroup: null,
      currentGroup: null,
      currentNumber: null,
      currentTitle: null,
      modalChangeGroup: false,
      current: null,
      isEditGroup: false,
      currentIndex: null,
      editedNameGroup: null,
      toNewGroup: false,
      newGroupName: null
    };
  },
  computed: {
    ...mapState({
      notifications: state => state.adminNotifications.notifications,
      template: state => state.adminNotifications.template,
      isSuper: state => state.login.isSuper
    }),
    ...mapGetters('common', ['loading']),
    isLoading() {
      return this.loading('adm_notification/change_group');
    },
    groups() {
      return Object.keys(this.notifications);
    }
  },
  created() {
    this.getNotificationList();
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions('adminNotifications', [
      'getNotificationList',
      'getNotificationTemplate',
      'changeGroup'
    ]),
    toggleEditGroup({ index, value, state }) {
      this.currentIndex = index;
      this.isEditGroup = state;
      if (state) this.editedNameGroup = value;
      if (!state && this.editedNameGroup !== value)
        this.changeGroupName({ old: value, updated: this.editedNameGroup });
    },
    toggleGroup(nameGroup) {
      this.selectedGroup = nameGroup;
      this.currentGroup = nameGroup !== this.currentGroup && nameGroup;
    },
    async changeGroupName({ old, updated }) {
      const topic = 'adm_notification/change_group_name';
      const json = {
        old_group_name: old,
        new_group_name: updated
      };

      try {
        await this.createController({ topic, json });
        this.getNotificationList();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    openEditForm(item) {
      this.current = item;
      this.currentNumber = null;
      this.currentTitle = null;
      this.modalChangeGroup = false;
    },
    openModalChangeGroup({ number, title }) {
      this.current = null;
      this.currentNumber = number;
      this.currentTitle = title;
      this.modalChangeGroup = true;
    },
    async saveChangeGroup() {
      const json = {
        number: this.currentNumber,
        group: this.toNewGroup ? this.newGroupName : this.selectedGroup,
        title: this.currentTitle
      };
      await this.changeGroup(json);
      this.selectedGroup = this.currentGroup;
      if (this.toNewGroup) this.getNotificationList();
      this.toNewGroup = false;
      this.newGroupName = null;
      this.modalChangeGroup = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
.container {
  position: relative;
}
.v-input--selection-controls {
  margin: 0;
  padding-top: 25px;
}
.edit-input {
  margin: 0 10px 0 0;
}
.add-group {
  margin-left: 0;
}
.notifications {
  position: relative;
  &:first-child {
    .notifications__header {
      border-top: 1px solid rgba(204, 204, 204, 1);
    }
  }
  &__actions {
    margin: 0 8px 0 auto;
  }
  &__edit {
    margin: 0;
  }
  &__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(204, 204, 204, 1);
    padding: 0 0 0 10px;
  }
  &__title {
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    margin: 0;
    line-height: 44px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  &__block {
    &__header {
      display: flex;
      line-height: 1;
      align-items: center;
      padding: 0 22px;
      height: 44px;
      font-weight: bold;
      font-size: 18px;
      color: #fff;
      background-color: $color-main;
    }
    &__content {
      padding: 20px 22px 22px 22px;
      background-color: #fff;
      /deep/ .v-input--selection-controls {
        padding-top: 0;
      }
      /deep/ .v-input--selection-controls.v-input .v-label {
        margin-top: 0;
      }
    }
  }
  &__item:first-child {
    .notifications__label {
      border-top: 1px solid rgba(204, 204, 204, 1);
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
  }
  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-size: 14px;
    line-height: 42px;
    border-bottom: 1px solid rgba(204, 204, 204, 1);
    padding-left: 10px;

    cursor: pointer;
    &:hover {
      background-color: rgba(242, 242, 242, 1);
    }
    &__text {
      width: 100%;
      margin-bottom: 0;
      font-weight: normal;
    }
  }
  &__list {
    padding: 0;
    margin: 24px 0 0 26px;
    display: flex;
    flex-direction: column;
    list-style: none;
    &__item {
      font-size: 14px;
      line-height: 42px;
      padding-left: 20px;
      cursor: pointer;
      border-top: 1px solid rgba(204, 204, 204, 1);
      &:hover {
        background-color: rgba(242, 242, 242, 1);
      }
      &:last-child {
        border-bottom: 1px solid rgba(204, 204, 204, 1);
      }
    }
  }
}
</style>
