<template>
  <v-container grid-list-xl fluid class="groups">
    <custom-button :disabled="!isSuper" flat color="primary" class="ml-0" @click="showForm = true">
      <v-icon>add_circle_outline</v-icon>
      Добавить группу
    </custom-button>
    <GroupsForm
      :show-form="showForm"
      :sections-group-list="sectionsGroupList"
      :editable-item="editableItem"
      :list-name-groups="listNameGroups"
      @submit="submit"
      @close="closeForm"
      @delete="deleteGroup"
    />
    <GroupsTable :groups="groupsList" @edit="edit" />
    <v-snackbar v-model="snackbar" top color="error" :timeout="5000">
      Данную группу не возможно удалить. К ней подключены пользователи.
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import GroupsForm from './groups/GroupsForm';
import GroupsTable from './groups/GroupsTable';

export default {
  name: 'Groups',
  components: {
    GroupsTable,
    GroupsForm
  },
  data() {
    return {
      showForm: false,
      sectionsGroupList: [],
      editableItem: {},
      snackbar: false
    };
  },
  computed: {
    ...mapState('login', ['isSuper']),
    ...mapState('users', ['groupsList']),
    isEdit() {
      return !!Object.keys(this.editableItem).length;
    },
    listNameGroups() {
      return this.groupsList.map(item => item.name);
    }
  },
  created() {
    this.getGroupSections();
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions('users', ['getGroupsList']),
    async getGroupSections() {
      const topic = 'admin/administration/access_groups/sections/list';
      try {
        const { list } = await this.createController({ topic });
        this.sectionsGroupList = list;
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async submit(formData) {
      const json = this.mappedFormData(formData);
      const topic = `admin/administration/access_groups/${this.isEdit ? 'update' : 'add'}`;
      try {
        await this.createController({ topic, json });
        await this.getGroupsList();
        this.editableItem = {};
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      } finally {
        this.showForm = false;
      }
    },
    edit(gid) {
      this.editableItem = this.groupsList.find(_ => _.gid === gid);
      this.showForm = true;
    },
    async deleteGroup(gid) {
      const topic = 'admin/administration/access_groups/delete';
      const json = { gid };
      try {
        await this.createController({ topic, json });
        await this.getGroupsList();
        this.editableItem = {};
      } catch (error) {
        if (error.status.reason === 'E_IN_USE') {
          this.snackbar = true;
          return;
        }
        console.error(`Ошибка в запросе ${topic}`, error);
      } finally {
        this.showForm = false;
      }
    },
    closeForm() {
      this.showForm = false;
      this.editableItem = {};
    },
    mappedFormData(formData) {
      const { modify, view } = formData;
      const sections = this.sectionsGroupList.map(item => {
        const includesView = view.includes(`${item.name}_1`);
        const includesModify = modify.includes(`${item.name}_2`);
        let permission;
        if (includesView && includesModify) {
          permission = 3;
        } else if (!includesView && !includesModify) {
          permission = 0;
        } else if (includesView && !includesModify) {
          permission = 1;
        } else if (!includesView && includesModify) {
          permission = 2;
        }
        return {
          name: item.name,
          sid: item.sid,
          permission
        };
      });
      const json = { group: formData.name, sections };
      if (this.isEdit) json.gid = formData.gid;
      return json;
    }
  }
};
</script>
