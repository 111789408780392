<template>
  <div
    :class="{ item, suggestion: item.new }"
    @click="$emit('click', { order: item, route: 'ORDERS_ORDER' })"
  >
    <v-layout row wrap class="item__header">
      <v-flex lg1 order-lg1 md2 xs12 tag="b" class="item__id">№{{ item.id }}</v-flex>
      <v-flex xs12 lg4 order-md3 order-lg2 tag="b" class="item__status">
        {{ status }}
        <icon v-if="item.new" name="newIcon" size="21" />
      </v-flex>
      <v-flex class="item__action" order-lg2 order-xl3>
        <custom-button flat icon class="item__icon-button" @click.stop>
          {{ yurType[item.yur_type] }}
        </custom-button>
        <custom-button
          v-if="item.yur_type != 'FL'"
          :color="safety[item.safety]"
          flat
          icon
          class="item__icon-button"
          @click.stop="$emit('click', { order: item, route: 'ORDERS_ORDER_SAFETY' })"
        >
          <icon name="verifiedUser" size="24" />
        </custom-button>
        <custom-button
          flat
          icon
          color="success"
          class="item__icon-button"
          @click.stop="$emit('click', { order: item, route: 'ORDERS_ORDER_RATING' })"
        >
          <rating-count :rating="item.rating" />
        </custom-button>
        <custom-button
          flat
          icon
          color="primary"
          class="item__icon-button"
          @click.stop="$emit('toggleFavoriteOrder', item)"
        >
          <icon :name="item.favor ? 'star' : 'starOutline'" size="24" />
        </custom-button>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="item__content">
      <v-flex lg2 md6 xs12 class="item__block ">
        <span class="item__label">Дата и время погрузки</span>
        <span class="item__value">{{ departureTime }}</span>
      </v-flex>
      <v-flex lg5 md6 xs12 class="item__block">
        <span class="item__label">Маршрут</span>
        <span class="item__value">
          {{ item.source }} - {{ item.complex_route ? '... -' : '' }} {{ item.destination }}
        </span>
      </v-flex>
      <v-flex lg2 md6 xs12 class="item__block">
        <span class="item__label">Лучшее предложение</span>
        <span class="item__value">
          {{ isBestOffer ? bestOffer : '-' }}
          <icon
            v-if="isBestOffer && item.best_offer.express"
            name="lightning"
            size="20"
            class="lightning"
          />
        </span>
      </v-flex>
      <v-flex lg3 md6 xs12 class="item__block align-right">
        <span class="item__label">
          Стоимость перевозки {{ isBlitzBidding ? '(блиц-цена)' : '(ориентировочно)' }}
        </span>
        <span class="item__value">
          {{ getCost }}
          <icon v-if="isBlitzBidding" name="fire" size="21" class="fire" />
        </span>
      </v-flex>
      <v-flex lg7 xs12 class="item__block">
        <span class="item__label">Требования к транспортному средству</span>
        <span class="item__value paragraph">
          {{ getRequirements(item) }}, Перевозка попутным грузом:
          {{ item.corouting ? `да` : `нет` }}.
        </span>
      </v-flex>
      <v-flex lg5 xs12 class="item__block align-right">
        <span class="item__label">Характеристика груза</span>
        <span class="item__value">{{ item.name }}</span>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import lightFormat from 'date-fns/lightFormat';
import set from 'date-fns/set';
import { getUTCDate } from 'common/helpers/dateHelper';
import RatingCount from 'common/components/RatingCount';

export default {
  name: 'OfferOrderItem',
  components: {
    RatingCount
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState('transportData', {
      scheme: state => state.scheme
    }),
    ...mapGetters('orders', ['getListProperties', 'getListCharacteristics', 'getListCarcasses']),
    departureTime() {
      const { begin_datetime: begin, end_datetime: end, begin_end_offset: offset } = this.item;
      const isEqual = begin === end;
      const setDateWithOffset = date => {
        const utcDate = getUTCDate(date);
        const hours = utcDate.getHours() + offset;
        return set(utcDate, { hours });
      };
      if (isEqual) return lightFormat(setDateWithOffset(end), 'dd.MM.yy, HH:mm');

      return `${lightFormat(setDateWithOffset(begin), 'dd.MM.yy')} - ${lightFormat(
        setDateWithOffset(end),
        'dd.MM.yy, HH:mm'
      )}`;
    },
    getCost() {
      return this.item.cost ? `${this.item.cost.toLocaleString('ru')} руб.` : 'Не задано';
    },
    status() {
      const { offers_deadline: deadlineDate } = this.item;
      const date = lightFormat(new Date(deadlineDate), 'dd.MM.yyyy в HH:mm');
      return `Прием предложений до ${date}`;
    },
    isBlitzBidding() {
      return this.item.bidding === 'blitz';
    },
    isBestOffer() {
      return !!Object.keys(this.item.best_offer).length;
    },
    bestOffer() {
      const nds = this.item.best_offer.nds ? 'с НДС' : 'без НДС';
      return `${this.item.best_offer.cost?.toLocaleString('ru-RU')} руб. ${nds}`;
    }
  },
  created() {
    this.safety = {
      green: 'success',
      red: 'danger',
      yellow: 'warning'
    };
    this.yurType = {
      FL: 'ФЛ',
      IP: 'ИП',
      UL: 'ЮЛ'
    };
  },
  methods: {
    getRequirements(item) {
      return [
        this.getListCarcasses(item.carcasses),
        this.getListCharacteristics(item.properties),
        this.getListProperties({ field: 'options', properties: item.properties }),
        this.getListProperties({ field: 'lifting', properties: item.properties })
      ]
        .filter(_ => !!_)
        .join(', ');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';
.item {
  box-shadow: 0px 0px 5px 0px rgba(17, 17, 17, 0.15);
  margin: 0 0 16px 0;
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 16px 0px rgba(17, 17, 17, 0.16);
  }

  @media only screen and (max-width: breakpoint(md)) {
    margin: 0 -8px 16px;
  }
  @media only screen and (max-width: breakpoint(sm)) {
    margin: 0 0 16px;
  }
  &__counter {
    display: inline-flex;
    span {
      color: $color-main;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    min-height: 44px;
    overflow: hidden;
    @media only screen and (max-width: breakpoint(lg)) {
      line-height: 28px;
      padding: 8px 0;
    }
    @media only screen and (max-width: breakpoint(md)) {
      position: relative;
      .item__action {
        position: absolute;
        top: 6px;
        right: 0;
      }
    }
  }
  &__icon-button {
    margin: 0;
  }
  &__action {
    display: flex;
    justify-content: flex-end;
    margin: 0 -12px 0 0;
  }
  &__content {
    display: flex;
  }
  &__status {
    display: flex;
    align-items: center;
    .icon {
      margin: 4px 0 0 8px;
      &.dispute {
        margin-top: 0;
      }
    }
    @media only screen and (max-width: breakpoint(sm)) {
      // max-width: calc(100% - 136px);
      align-items: flex-start;
      .icon {
        margin: 0px 0 0 8px;
      }
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
  }
  &__label {
    font-size: 12px;
    line-height: 16px;
    color: $gray;
  }
  &__value {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 44px;
    &.paragraph {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      line-height: 22px;
      margin: 11px 0;
    }
  }

  .align-right {
    text-align: right;
    @media only screen and (max-width: breakpoint(lg)) {
      text-align: left;
    }
    .item__value {
      align-items: center;
      justify-content: flex-end;
      @media only screen and (max-width: breakpoint(lg)) {
        justify-content: flex-start;
      }
    }
  }
}

.lightning,
.fire {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /deep/ svg {
    width: 100%;
    height: 100%;
  }
}
.fire {
  color: $color-danger;
}
.lightning {
  color: $color-warning;
}
</style>
