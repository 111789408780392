<template>
  <div class="content-block">
    <h2 v-if="title" class="content-block__heading content-block__heading--title">
      {{ title }}
      <TooltipAnswer v-if="titleTooltip" :message="titleTooltip" />
    </h2>
    <div
      :class="[
        'content-block-container',
        { 'not-expanded': !isExpandedState, 'without-shadow': withoutShadow }
      ]"
    >
      <div
        v-if="expandable || subtitle"
        :class="['content-block-container__header', { clickable: expandable }]"
        @click="onHeaderClick"
      >
        <component
          :is="subtitleTag"
          v-if="subtitle"
          class="content-block__heading content-block__heading--subtitle"
        >
          {{ subtitle }}
          <TooltipAnswer v-if="subtitleTooltip" :message="subtitleTooltip" />
        </component>
        <v-spacer />
        <slot name="buttons" />
        <custom-button v-if="expandable" class="expanded-button" icon>
          <icon :name="isExpandedState ? 'chevronUp' : 'chevronDown'" />
        </custom-button>
      </div>
      <div v-if="isExpandedState" :class="['content-block__content', { 'with-margin': subtitle }]">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import TooltipAnswer from 'common/components/TooltipAnswer.vue';

export default {
  name: 'ContentBlock',
  components: {
    TooltipAnswer
  },
  props: {
    title: String,
    subtitle: String,
    subtitleTooltip: String,
    titleTooltip: String,
    expandable: Boolean,
    isExpanded: Boolean,
    withoutShadow: Boolean,
    subtitleTag: {
      type: String,
      default: 'h3'
    }
  },
  data() {
    return { isExpandedState: !this.expandable || this.isExpanded };
  },
  watch: {
    isExpanded(value) {
      this.isExpandedState = !this.expandable || value;
    }
  },
  methods: {
    onHeaderClick() {
      if (this.expandable) this.isExpandedState = !this.isExpandedState;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.content-block {
  margin: 0 -12px 16px;

  &__heading {
    display: flex;
    align-items: center;
    min-height: 44px;
    &--title {
      margin: 16px 12px;
      font-weight: normal;

      /deep/ .icon {
        margin-top: 1px;
      }
    }

    &--subtitle {
      margin: 0;
      padding: 0 0 0 12px;

      /deep/ .icon {
        margin-top: 0;
      }
    }
  }

  &-container {
    box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.15);
    padding: 16px 0;
    position: relative;
    &.without-shadow {
      box-shadow: unset;
    }
    &.not-expanded {
      padding: 0;
      .content-block-container__header {
        margin-top: 0;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: -16px;

      &.clickable {
        cursor: pointer;
      }
    }
  }

  &__content {
    height: 100%;

    &.with-margin {
      margin-top: 8px;
    }
  }
}

.expanded-button {
  margin: 0 8px 0 0;
}

/deep/ .tooltip {
  line-height: 0;
}
@media only screen and (max-width: breakpoint(md)) {
  .content-block {
    margin: 0 -4px 16px -4px;

    &-container {
      margin: 0 4px;
    }
  }
}

@media only screen and (max-width: breakpoint(sm)) {
  .content-block__heading--subtitle {
    margin-top: 8px;
  }
  .content-block__heading--title {
    margin: 16px;
  }
}
</style>
