<template>
  <v-container v-if="isFL && agent.type === 'shipper'" class="carrier-alert">
    <alert :message="messageAlert" type="neutral" />
  </v-container>
  <v-container v-else class="carrier-info">
    <v-layout wrap>
      <v-flex xs12 class="header">
        <img
          v-if="avatarUrl"
          :src="avatarUrl"
          alt="avatar"
          class="avatar avatar--pointer"
          @click="openDialog(0, 'avatar')"
        />
        <icon v-else :name="`${agent.type}StubIcon`" size="44" class="mr10px" />
        <div class="agent-info">
          <div class="name">{{ name }}</div>
          <div class="verify-info">{{ verifyInfo }}</div>
        </div>
      </v-flex>
    </v-layout>
    <v-layout v-if="isFL" wrap class="content">
      <v-flex xs12 md3>
        <custom-input :value="agent.inn" label="ИНН" disabled />
      </v-flex>
      <v-flex xs12>
        <h3 class="info-title">Паспортные данные</h3>
      </v-flex>
      <v-flex xs12 md1>
        <custom-input :value="agent.passport_serie" label="Серия" disabled />
      </v-flex>
      <v-flex xs12 md2>
        <custom-input :value="agent.passport_number" label="Номер" disabled />
      </v-flex>
      <v-flex xs12 md2>
        <custom-input :value="passportDate" label="Дата выдачи" disabled />
      </v-flex>
      <v-flex xs12 md2>
        <custom-input :value="birthdayDate" label="Дата рождения" disabled />
      </v-flex>
      <v-flex xs12 md5>
        <div class="photos-label">Скан-копия/фото докум.</div>
        <img
          v-for="(image, index) in flPhotos"
          :key="image.cid"
          :src="image.src"
          class="fl-image"
          height="40"
          alt=""
          @click="openDialog(index)"
        />
      </v-flex>
    </v-layout>
    <v-layout v-else class="content" row wrap>
      <v-flex xs12>
        <h3 class="info-title">О компании</h3>
      </v-flex>
      <v-flex xs12 md3>
        <custom-input :value="agent.ogrn" label="ОГРН" disabled />
      </v-flex>
      <v-flex xs12 md3>
        <custom-input :value="innKpp" label="ИНН / КПП" disabled />
      </v-flex>
      <v-flex xs12 md3>
        <custom-input :value="yurType" label="Категория" disabled />
      </v-flex>
      <v-flex xs12 md3>
        <custom-input :value="agent.entity_status" label="Статус организации" disabled />
      </v-flex>
      <v-flex xs12 md6>
        <custom-input
          :value="legalAddress"
          :label="isIP ? 'Адрес регистрации' : 'Юридический адрес организации'"
          disabled
        />
      </v-flex>
      <v-flex v-if="agent.ceo" xs12 md6>
        <custom-input :value="agent.ceo" label="ФИО руководителя" disabled />
      </v-flex>
      <v-flex xs12 md6>
        <custom-input :value="postAddress" label="Адрес для корреспонденции" disabled />
      </v-flex>
    </v-layout>
    <swiper-dialog
      v-if="isDialogOpen"
      :images="swiperPhotos"
      :slide-index="slideIndex"
      @close="isDialogOpen = false"
    />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getUTCDate } from 'common/helpers/dateHelper';
import lightFormat from 'date-fns/lightFormat';
import { swiperDialog } from 'common/components/swiper';
import Alert from 'common/components/Alert';

export default {
  name: 'OrderViewCarrierInfo',
  components: { swiperDialog, Alert },
  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    agent: {
      type: Object,
      default: () => ({})
    },
    photos: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    isDialogOpen: false,
    slideIndex: 0,
    swiperPhotos: []
  }),
  computed: {
    ...mapState(['defaultURL']),
    ...mapState('login', ['cid']),
    isFL() {
      return this.agent.yur_type === 'FL';
    },
    isIP() {
      return this.agent.yur_type === 'IP';
    },
    avatarUrl() {
      const type = this.isFL ? 'my_photo' : 'logo';
      const url = this.photos.find(_ => _.type === type)?.file;
      const agentCidOrder = `c${this.agent.type}_id`;
      return url
        ? `${this.defaultURL}/files/${this.cid}/${this.order[agentCidOrder]}/${url}`
        : null;
    },
    verifyInfo() {
      const text = this.isFL ? 'Верифицирован до' : 'Дата образования:';
      const date = this.isFL ? this.agent.verif_until : this.agent.foundation_date;
      return date ? `${text} ${lightFormat(getUTCDate(date), 'dd.MM.yyyy')}` : '';
    },
    name() {
      const nds = `${this.agent.nds ? 'является' : 'не является'} плательщиком НДС`;
      return this.isFL ? this.agent.name : `${this.agent.name} (${nds})`;
    },
    innKpp() {
      return this.agent.kpp ? `${this.agent.inn} / ${this.agent.kpp}` : this.agent.inn;
    },
    yurType() {
      if (this.agent.yur_type === 'UL') return 'Юридическое лицо';
      if (this.agent.yur_type === 'IP') return 'Индивидуальный предприниматель';
      return '';
    },
    legalAddress() {
      if (!this.agent.legal_zip && !this.agent.legal_address) return 'Не указан';
      return `${this.agent.legal_zip}, ${this.agent.legal_address}`;
    },
    postAddress() {
      if (!this.agent.post_zip && !this.agent.post_address) return 'Не указан';
      return `${this.agent.post_zip}, ${this.agent.post_address}`;
    },
    passportDate() {
      return lightFormat(getUTCDate(this.agent.passport_date), 'dd.MM.yyyy');
    },
    birthdayDate() {
      return lightFormat(getUTCDate(this.agent.birthday_date), 'dd.MM.yyyy');
    },
    flPhotos() {
      return this.photos
        .filter(_ => _.type !== 'my_photo')
        .map(_ => ({
          ..._,
          src: `${this.defaultURL}/files/${this.cid}/${this.order.ccarrier_id}/${_.file}`
        }));
    }
  },
  created() {
    this.messageAlert = 'Грузовладелец - физическое лицо с контактными данными, указанными выше';
  },
  methods: {
    ...mapActions('common', ['createController']),
    openDialog(index, type = 'passport') {
      this.swiperPhotos = type === 'passport' ? this.flPhotos : [{ src: this.avatarUrl }];
      this.slideIndex = index;
      this.isDialogOpen = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';
.carrier-alert {
  padding: 0 12px 12px;
}

.header {
  border-bottom: 1px solid $gray-lighten;
  padding-bottom: 16px !important;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  @media screen and (max-width: breakpoint(sm)) {
    padding: 8px 12px 12px 12px !important;
  }

  .avatar {
    width: 44px;
    height: 44px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid $gray-lighten;
    margin-right: 10px;

    &--pointer {
      cursor: pointer;
    }
  }

  .mr10px {
    margin-right: 10px;
  }

  .name {
    font-size: 16px;
    font-weight: 500;
  }

  .verify-info {
    color: $color-success;
    font-size: 12px;
    margin-bottom: 0;
    font-weight: normal;
  }
}

.info-title {
  margin: 0 0 12px 0;
}

.fl-image {
  cursor: pointer;
  margin-right: 10px;
}

.photos-label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.38);
  margin-bottom: 4px;
}
</style>
