<template>
  <div
    v-if="loading('admin/endusers/trucker/profile/details/get') || !truckerProfile.trucker"
    class="loading"
  >
    <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
  </div>
  <div v-else class="truckerProfile">
    <div
      class="trucker_profile__status"
      :style="{ color: utils.verifyColors[truckerProfile.trucker.verif_status] }"
    >
      {{ status }}
    </div>
    <div class="trucker_profile__avatar">
      <img :src="photos.avatar" alt="Avatar" />
    </div>
    <div class="trucker_profile__row">
      <div class="trucker_profile__row__cell width_25">
        <v-text-field
          label="Фамилия"
          :value="truckerProfile.trucker.lname"
          placeholder=" "
          :readonly="true"
        />
      </div>
      <div class="trucker_profile__row__cell width_25">
        <v-text-field
          label="Имя"
          :value="truckerProfile.trucker.fname"
          placeholder=" "
          :readonly="true"
        />
      </div>
      <div class="trucker_profile__row__cell width_25">
        <v-text-field
          label="Отчество"
          :value="truckerProfile.trucker.mname"
          placeholder=" "
          :readonly="true"
        />
      </div>
      <div class="trucker_profile__row__cell width_25">
        <v-text-field
          label="Телефон"
          :value="truckerProfile.trucker.phone"
          placeholder=" "
          :readonly="true"
        />
      </div>
    </div>
    <div class="trucker_profile__title">
      Паспорт
    </div>
    <div class="trucker_profile__row">
      <div class="trucker_profile__row__cell width_10">
        <v-text-field
          label="Серия"
          :value="truckerProfile.trucker.passport_series"
          placeholder=" "
          :readonly="true"
        />
      </div>
      <div class="trucker_profile__row__cell width_10">
        <v-text-field
          label="Номер"
          :value="truckerProfile.trucker.passport_number"
          placeholder=" "
          :readonly="true"
        />
      </div>
      <div class="trucker_profile__row__cell width_20">
        <v-text-field
          label="Дата выдачи"
          :value="getDate('passport_date')"
          placeholder=" "
          :readonly="true"
        />
      </div>
      <div class="trucker_profile__row__cell width_20">
        <v-text-field
          label="Дата рождения"
          :value="getDate('passport_birthday')"
          placeholder=" "
          :readonly="true"
        />
      </div>
    </div>
    <div class="trucker_profile__row">
      <div
        v-for="(title, key) in passport_titles"
        v-show="photos[key].length !== 0"
        :key="key"
        class="trucker_profile__row__cell width_20"
      >
        <span class="photo_title">{{ title }}</span>
        <image-slider-small :value="photos[key]" />
      </div>
    </div>
    <div class="trucker_profile__title">
      Водительское удостоверение
    </div>
    <div class="trucker_profile__row">
      <div class="trucker_profile__row__cell width_10">
        <v-text-field
          label="Серия"
          :value="truckerProfile.trucker.license_series"
          placeholder=" "
          :readonly="true"
        />
      </div>
      <div class="trucker_profile__row__cell width_20">
        <v-text-field
          label="Номер"
          :value="truckerProfile.trucker.license_number"
          placeholder=" "
          :readonly="true"
        />
      </div>
      <div class="trucker_profile__row__cell width_20">
        <v-text-field
          label="Дата выдачи"
          :value="getDate('license_date')"
          placeholder=" "
          :readonly="true"
        />
      </div>
    </div>
    <div class="trucker_profile__row">
      <div v-if="photos['license'].length !== 0" class="trucker_profile__row__cell width_20">
        <span class="photo_title">Фото документа</span>
        <image-slider-small :value="photos['license']" />
      </div>
    </div>
    <v-btn
      :disabled="truckerProfile.trucker.verification_status !== 'verified' || !isSuper"
      color="primary"
      large="large"
      @click="verificationDone"
    >
      Отказать в верификации
    </v-btn>
    <v-dialog v-model="dialogDenied" class="ui-kit__dialog" persistent max-width="500px">
      <div class="ui-kit__dialog__header">
        <div class="ui-kit__dialog__title">
          Отказать в верификации
        </div>
        <v-spacer />
        <v-btn
          class="ui-kit__dialog__close"
          fab="fab"
          small="small"
          color="primary"
          @click.stop="dialogDenied = false"
        >
          <v-icon dark="dark">
            close
          </v-icon>
        </v-btn>
      </div>
      <div class="ui-kit__dialog__content">
        <v-textarea v-model="deniedText" class="ui-kit__input" label="Причина отказа" />
        <div class="block-btns">
          <v-btn color="primary" large="large" @click="deniedVerification">
            Отказать
          </v-btn>
          <v-btn large="large" outline="outline" color="primary" @click.stop="dialogDenied = false">
            Оменить
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import utils from '@/helpers/utils';
import ImageSliderSmall from '../../common/ImageSliderSmall';

export default {
  components: {
    ImageSliderSmall
  },
  data() {
    return {
      passport_titles: {
        passport: 'Разворот с фото',
        registration: 'Разворот с регистрацией',
        numbers: 'Разворот с 18-ой и 19-ой страницами'
      },
      dialogDenied: false,
      deniedText: '',
      utils
    };
  },
  computed: {
    ...mapState('login', ['cid', 'isSuper']),
    ...mapGetters('common', ['loading']),
    ...mapState('clientsProfile', ['truckerProfile']),
    ...mapState('clients', ['info']),
    status() {
      const verifStatus = this.truckerProfile.trucker.verif_status;
      let dateVerif = '';
      if (verifStatus === 'denied') {
        dateVerif = 'verif_date';
      } else if (verifStatus === 'verified') {
        dateVerif = 'verif_until';
      }
      return utils.statuses[verifStatus].replace('%date%', this.getDate(dateVerif));
    },
    defaultURL() {
      return this.$store.state.defaultURL;
    },
    enduser_id() {
      return parseInt(this.$route.params.enduser);
    },
    photos() {
      return {
        avatar: this.truckerProfile.photos
          .filter(item => item.type === 'my_photo')
          .map(item => this.getUrl(item)),
        passport: this.truckerProfile.photos
          .filter(item => item.type === 'passport_photo')
          .map(item => this.getUrl(item)),
        registration: this.truckerProfile.photos
          .filter(item => item.type === 'passport_registration')
          .map(item => this.getUrl(item)),
        numbers: this.truckerProfile.photos
          .filter(item => item.type === 'passport_previous_number')
          .map(item => this.getUrl(item)),
        license: this.truckerProfile.photos
          .filter(item => ['driver_license_photo', 'driver_license_back'].indexOf(item.type) !== -1)
          .map(item => this.getUrl(item))
      };
    }
  },
  mounted() {
    this.getTruckerProfile(this.enduser_id);
  },
  methods: {
    ...mapActions('clientsProfile', ['getTruckerProfile', 'truckerVerifyDenial']),
    getUrl(photo) {
      return `${this.defaultURL}/files/${this.cid}/${this.truckerProfile.trucker.cid}/${photo.medium}`;
    },
    /**
     * @return {string}
     */
    getDate(key) {
      return moment(this.truckerProfile.trucker[key]).format('DD.MM.YYYY');
    },
    verificationDone() {
      this.dialogDenied = true;
    },
    async deniedVerification() {
      await this.truckerVerifyDenial({
        enduser: this.info.id,
        comment: this.deniedText
      });
      this.dialogDenied = false;
      this.getTruckerProfile(this.enduser_id);
    }
  }
};
</script>
<style lang="scss">
.truckerProfile {
  padding: 15px;
  height: 100%;
  overflow-y: auto;

  .trucker_profile__avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 15px;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .trucker_profile__title {
    font-size: 24px;
    padding: 20px 0;
  }
  .trucker_profile__status {
    float: right;
  }
  .trucker_profile__row {
    .trucker_profile__row__cell {
      padding: 15px 5px;
      display: inline-block;
      span {
        display: block;
        margin-bottom: 3px;
        font-style: normal;
        color: #8b8b8b;
        line-height: 11px;
        font-size: 12px;
      }
    }
  }
}
</style>
