<template>
  <div class="transports_tab">
    <TableList
      v-if="isViewTableList"
      :loading="loading('admin/endusers/contractor/transport_means/means/list')"
      :values="list"
      :columns="columns"
      :show-columns="false"
      @clickItem="openWindow"
    >
      <template slot="verify" slot-scope="{ row }">
        <div :class="[row.verifyClass]">
          {{ row.verify }}
        </div>
      </template>
      <template slot="empty">
        <div>Нет транспортных средств для отображения</div>
      </template>
    </TableList>
    <div v-else>
      <v-toolbar dark="dark" color="primary">
        <v-toolbar-title>{{ current.model }} {{ current.number }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          color="primary"
          large="large"
          :disabled="!current.isVerified || !isSuper"
          @click="dialogDenied = true"
        >
          Отказать в верификации
        </v-btn>
        <v-toolbar-items>
          <v-btn icon="icon" dark="dark" @click="isViewTableList = true">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <TransportInfo />
      <v-dialog v-model="dialogDenied" class="ui-kit__dialog" persistent max-width="500px">
        <div class="ui-kit__dialog__header">
          <div class="ui-kit__dialog__title">
            Отказать в верификации
          </div>
          <v-spacer />
          <v-btn
            class="ui-kit__dialog__close"
            fab="fab"
            small="small"
            color="primary"
            @click.stop="dialogDenied = false"
          >
            <v-icon dark="dark">
              close
            </v-icon>
          </v-btn>
        </div>
        <div class="ui-kit__dialog__content">
          <v-textarea v-model="deniedText" class="ui-kit__input" label="Причина отказа" />
          <div class="block-btns">
            <v-btn color="primary" large="large" @click="deniedVerification">
              Отказать
            </v-btn>
            <v-btn
              large="large"
              outline="outline"
              color="primary"
              @click.stop="dialogDenied = false"
            >
              Оменить
            </v-btn>
          </div>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import TransportInfo from 'common/components/transport/TransportInfo.vue';
import TableList from '../../Layout/TableList.vue';

export default {
  components: {
    TableList,
    TransportInfo
  },
  data() {
    return {
      isViewTableList: true,
      dialogDenied: false,
      deniedText: ''
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('clientsTransports', ['list', 'scheme']),
    ...mapState('transportData', ['current']),
    ...mapState('login', ['isSuper']),
    enduser_id() {
      return this.$route.params.enduser || false;
    }
  },
  created() {
    this.columns = [
      {
        title: ' ',
        name: 'name',
        width: '70',
        alignLeft: true
      },
      {
        title: ' ',
        name: 'verify',
        width: '30',
        slot: true,
        alignLeft: true
      }
    ];
    this.getTransports({
      enduser: this.enduser_id
    });
  },
  methods: {
    ...mapActions('clientsTransports', ['getTransports', 'verifyDenial']),
    ...mapActions('transportData', ['getTransport']),
    ...mapActions('verifications', ['getScheme']),
    async openWindow(car) {
      await this.getTransport(car.id);
      this.isViewTableList = false;
    },

    async deniedVerification() {
      await this.verifyDenial({
        comment: this.deniedText
      });
      this.dialogDenied = false;
      this.isViewTableList = true;
      this.deniedText = '';
      this.getTransports({
        enduser: this.enduser_id
      });
    }
  }
};
</script>

<style lang="scss">
.transports_tab {
  height: 100%;
}
</style>
