<template>
  <div>
    <CarrierDocumentsPayments v-if="isCarrierNotFl" :shipper="shipper" :carrier="carrier" />
    <ShippingInvoices :is-shipper-fl="isShipperFl" :is-pin-view="isPinView" />
  </div>
</template>

<script>
const CarrierDocumentsPayments = () =>
  import('common/views/OrderView/OrderDocuments/CarrierDocuments/CarrierDocumentsPayments');
import ShippingInvoices from 'common/views/OrderView/OrderDocuments/ShippingInvoices/index';

export default {
  name: 'CarrierDocuments',
  components: {
    ShippingInvoices,
    CarrierDocumentsPayments
  },
  props: {
    isPinView: Boolean,
    shipper: {
      type: Object,
      required: true,
      validator(value) {
        return !!Object.keys(value).length;
      }
    },
    carrier: {
      type: Object,
      required: true,
      validator(value) {
        return !!Object.keys(value).length;
      }
    }
  },
  computed: {
    isShipperFl() {
      return this.shipper.yur_type === 'FL';
    },

    isCarrierNotFl() {
      return this.carrier.yur_type !== 'FL';
    }
  }
};
</script>
