const baseURL = `${process.env.VUE_APP_WEB_PROTOCOL}://${process.env.VUE_APP_WEB_HOST}${(process.env
  .VUE_APP_WEB_PORT &&
  `:${process.env.VUE_APP_WEB_PORT}`) ||
  ''}`;
const fetch = require('node-fetch');

const instance = {
  get: (url, absolute = false) => fetch(`${absolute ? '' : baseURL}${url}`),
  post: (url, body, absolute = false) =>
    fetch(`${absolute ? '' : baseURL}${url}`, {
      method: 'POST',
      body
    })
};

const proxy = new Proxy(instance, {
  get(target, propKey) {
    const origMethod = target[propKey];
    return async function(...args) {
      const response = await origMethod.apply(this, args);
      const json = await response.json();
      const { status: { result, reason } = {} } = json;
      if (result === 'rejected') throw new Error(reason);
      return json;
    };
  }
});

export default proxy;
