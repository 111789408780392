<template>
  <div :class="['item', { isAdmin: isOperator }]" @click="onClick">
    <div class="item__actions">
      <div class="item__info">
        <b class="item__info__create-date">#{{ value.order_id || value.id }}</b>
        <b class="item__info__title hidden-xs-only">{{ status }}</b>
        <div class="item__info__icon">
          <icon-tooltip
            v-if="value.bidding === 'blitz'"
            name="fire"
            size="18"
            icon-class="fire"
            message="Заказ с блиц-ценой"
          />
          <icon-tooltip
            v-if="!myOffer && showIconDispute(value)"
            :icon-class="`dispute ${value.dispute_status} ${getResultDispute}`"
            name="hat"
            size="18"
            message="Диспут"
            @click="$emit('to-dispute', value.cid)"
          />
        </div>
      </div>
      <div class="item__actions__buttons">
        <slot />
      </div>
    </div>

    <v-layout row wrap>
      <v-flex xs12 class="mobile-title">
        <b class="hidden-sm-and-up">{{ status }}</b>
      </v-flex>
      <v-flex md2 sm6 xs12>
        <div class="item__field">
          <span class="item__field__label">
            Дата и время погрузки
          </span>
          <p class="item__field__text">{{ departureTime }}</p>
        </div>
      </v-flex>
      <v-flex md3 sm6 xs12>
        <div class="item__field">
          <span class="item__field__label">
            Маршрут
          </span>
          <p class="item__field__text">
            {{ value.source }} - {{ value.complex_route ? '... -' : '' }} {{ value.destination }}
          </p>
        </div>
      </v-flex>
      <v-flex md3 sm6 xs12>
        <div class="item__field">
          <span class="item__field__label">
            {{ myOffer ? 'Лучшее предложение, руб.' : 'Стоимость, руб.' }}
          </span>
          <p class="item__field__with-icon">
            <span class="item__field__text">{{ costValue }}</span>
            <icon
              v-if="value.best_offer.express && myOffer"
              name="lightning"
              class="lightning"
              size="22"
            />
          </p>
        </div>
      </v-flex>
      <v-flex md3 sm4 xs12>
        <div class="item__field">
          <span class="item__field__label">
            Транспортное средство
          </span>
          <p class="item__field__text item__field__text--max-width">
            {{ transportName }}
          </p>
        </div>
      </v-flex>
      <v-flex md1 sm2 xs12>
        <div class="item__field">
          <span class="item__field__label">
            Водитель
          </span>
          <p class="item__field__text">
            {{ value.trucker }}
          </p>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import lightFormat from 'date-fns/lightFormat';
import getOrderStatus from 'common/components/Orders/getOrderStatus';
import IconTooltip from '@/components/common/IconTooltip';
import ROUTES from '@/utils/routes';
import { TYPES } from '@/utils/transportEnums';
import { getDepartureTime, isDateNullish } from 'common/helpers/dateHelper';

export default {
  name: 'CarrierActivityItem',
  components: { IconTooltip },
  props: {
    isOperator: Boolean,
    suggestion: Boolean,
    myOffer: Boolean,
    value: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState('login', ['category']),
    getResultDispute() {
      if (this.value.who_won_dispute) {
        if (this.value.who_won_dispute === 'neutral') return 'neutral';
        if (this.value.who_won_dispute === this.category) return 'won_dispute';
        return 'lost_dispute';
      }
      return null;
    },
    transportName() {
      return this.value.transport
        .map(el => `${TYPES[el.vehicle_type]} ${el.name}, ${el.registration_plate}`)
        .join(', ');
    },
    costValue() {
      const value = this.myOffer ? this.value.best_offer : this.value;
      const cost = this.myOffer ? value.cost : value.final_cost;
      if (!cost) return '';
      return `${cost.toLocaleString('ru-RU')} ${value.nds ? 'с' : 'без'} НДС ${
        this.value.best_offer_is_yours ? '(Ваше)' : ''
      }`;
    },
    status() {
      return getOrderStatus(this.value);
    },
    departureTime() {
      return getDepartureTime(this.value);
    }
  },
  methods: {
    ...mapMutations('orders', ['setOrderInfoField']),
    onClick() {
      const id = this.value.order_id || this.value.id;
      const cid = this.value.corder_id || this.value.cid;
      this.setOrderInfoField({ field: 'currentOrderCid', value: cid });
      this.$router
        .push({
          name: this.myOffer ? ROUTES.ORDERS_ORDER_CONDITION : ROUTES.ORDERS_ORDER,
          query: { id }
        })
        .catch(() => {});
    },
    showIconDispute(item) {
      return (
        ['on_dispute', 'dispute_is_done'].includes(item.status) ||
        (item.status === 'canceled' &&
          !isDateNullish(item.dispute_open_deadline) &&
          item.who_canceled !== this.category)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.item {
  margin: 16px -12px 0;
  padding: 8px 0 0;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 16px 0px rgba(17, 17, 17, 0.16);
  }

  &__field {
    &__label {
      display: block;
      margin: 0 0 12px 0;
      font-size: 12px;
      color: #8b8b8b;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &__text {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &--max-width {
        max-width: 175px;
      }
    }
    &__with-icon {
      display: flex;
    }
  }
  &__info {
    display: flex;
    &__title {
      margin-left: 20px;
    }
    &__icon {
      display: flex;
      .icon {
        margin: 0 5px;
      }
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    min-height: 30px;
    margin: 0 0 0 12px;
    align-items: center;

    &__buttons {
      padding-right: 12px;
      display: flex;
      align-items: center;

      /deep/.custom-button {
        margin: 0 !important;
      }
    }
  }
}

.isAdmin {
  padding: 8px 12px 0;
  .item__actions {
    margin: 0;
  }
}
.mobile-title {
  margin: 8px 0;
  padding: 12px 0;
}
.delete-btn {
  margin-top: -8px;
}

.layout .flex:last-child > div {
  float: right;
  p,
  span {
    text-align: right;
  }
}
/deep/ .fire,
/deep/ .lost_dispute {
  color: $color-danger !important;
}
/deep/.lightning,
/deep/ .on_dispute {
  color: $color-warning !important;
}
/deep/ .canceled {
  color: $color-main !important;
}
/deep/ .won_dispute {
  color: $color-success !important;
}
/deep/.neutral {
  color: $gray-darken !important;
}
@media screen and (max-width: breakpoint(lg)) {
  .item {
    margin: 16px 0 0;
  }
}
@media screen and (max-width: breakpoint(sm)) {
  .item {
    &__info {
      justify-content: space-between;
    }
    &__field {
      &.right {
        p,
        span {
          text-align: left;
        }
      }
      &__text {
        white-space: normal;
        &--max-width {
          max-width: auto;
        }
      }
    }
  }
  .layout .flex:last-child > div {
    float: left;
    p,
    span {
      text-align: left;
    }
  }
}
</style>
