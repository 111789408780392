<template>
  <div v-if="isLoading" class="spinner">
    <v-progress-circular :size="40" color="primary" indeterminate />
  </div>
  <v-container v-else class="ts">
    <v-layout v-for="(item, i) in shortDescription" :key="i" class="ts__name" wrap>
      <v-flex md6 lg4>
        <lineWithDots width-title="200" :title="item.type" :description="item.description" />
      </v-flex>
      <v-flex md6 lg8 class="ts__status" :class="item.verification.class">
        {{ item.verification.text }} {{ item.verification.date }}
      </v-flex>
      <slot :item="getFullData(item.cid)" />
    </v-layout>
    <ts-chr v-if="isVehicle" :data="{ vehicle, properties, photos }" class="mt16px" />
    <auto-train-chr v-else :data="{ tail, properties, wagon }" for-offer class="mt16px" />
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getUTCDate } from 'common/helpers/dateHelper';
import format from 'date-fns/format';
import lineWithDots from 'common/components/Orders/lineWithDots.vue';
import { TYPES, SHORTRIGHTUSE } from '@/utils/transportEnums';
import AutoTrainChr from 'common/components/transport/AutoTrainChr.vue';
import TsChr from './TsChr.vue';
import { getVerificationStatus } from 'common/utils';

import { transportApi } from 'common/api/TransportApi';

export default {
  name: 'OfferItemTS',
  components: {
    lineWithDots,
    AutoTrainChr,
    TsChr
  },
  props: {
    offer: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    shortDescription: [],
    vehicle: {},
    vehicles: {},
    properties: {},
    wagon: {},
    tail: {},
    photos: [],
    isVehicle: null,
    isLoading: false
  }),
  computed: {
    ...mapState({
      scheme: state => state.transportData.scheme,
      cid: state => state.login.cid,
      defaultURL: state => state.defaultURL
    }),
    ...mapGetters('common', ['loading'])
  },
  async created() {
    this.isLoading = true;
    try {
      const json = { cid: this.offer.ctransport_id };
      const { type, ...data } = await transportApi(this.$store.dispatch).transportGet({ json });
      const isVehicle = type === 'vehicle';
      this.isVehicle = isVehicle;
      if (isVehicle) {
        const { vehicle, properties, photos } = data;
        this.vehicle = vehicle;
        this.photos = this.photoProcessor(photos, 'vehicle');
        this.properties = properties.reduce(
          (acc, property) => ({ ...acc, [property.name]: property.value }),
          {}
        );
        this.shortDescription.push(this.getData());
      } else {
        const { vehicles, properties, wagon } = data;
        this.vehicles = vehicles;
        this.wagon = wagon;
        this.tail = vehicles.tail;
        this.properties = properties;
        this.wagon.photos = this.photoProcessor(this.wagon.photos, 'wagon');
        this.vehicles.head.photos = this.photoProcessor(this.vehicles.head.photos, 'wagon');
        this.vehicles.tail.photos = this.photoProcessor(this.vehicles.tail.photos, 'wagon');
        this.shortDescription.push(this.getData('head'));
        this.shortDescription.push(this.getData('tail'));
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('common', ['createController']),

    photoProcessor(array, field) {
      return (
        array?.map(item => ({
          ...item,
          smallSrc: `${this.defaultURL}/files/${this.cid}/${this[field].cparent_id}/${item.small}`,
          tinySrc: `${this.defaultURL}/files/${this.cid}/${this[field].cparent_id}/${item.tiny}`,
          src: `${this.defaultURL}/files/${this.cid}/${this[field].cparent_id}/${item.file}`
        })) ?? []
      );
    },

    getData(field) {
      const str = this.isVehicle ? this.vehicle : this.vehicles[field].vehicle;
      return {
        type: TYPES[str.vehicle_type],
        description: `${str.brand_and_model}, ${format(getUTCDate(str.release_year), 'yyyy')}, ${
          SHORTRIGHTUSE.find(el => el.value === str.type_right_use)?.text
        }`,
        verification: getVerificationStatus(str),
        cid: str.cid
      };
    },

    getFullData(cid) {
      if (this.isVehicle) return { ...this.vehicle, photos: this.photos };
      const key = Object.keys(this.vehicles).find(_ => this.vehicles[_].vehicle.cid === cid);
      return { ...this.vehicles[key].vehicle, photos: this.vehicles[key].photos };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.ts {
  padding: 0 0 24px 0;

  &__name {
    align-items: baseline;
  }
  &__status {
    @media only screen and (max-width: breakpoint(md)) {
      text-align: right;
    }
  }
}

.mt16px {
  margin-top: 16px !important;
}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
