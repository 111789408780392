<template>
  <div v-if="isLoading" class="loading">
    <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
  </div>
  <div v-else class="tab_invite">
    <div class="tab_invite__title bold">
      Бонусы суммируются с бонусами, начисленными вам в рамках других акций сервиса!
    </div>
    <div class="tab_invite__box">
      <div class="box__row">
        <div class="box__row__text">
          Вы можете распространять вашу реферальную ссылку посредством соцсетей, отправить ее вашему
          другу по email или скопировать ссылку в буфер обмена и отправить ее любым мессенджером,
          установленном на вашем устройстве.
        </div>
      </div>
      <div class="box__row">
        <img width="70" height="70" :src="qr_link" />
        <v-text-field v-model="link" class="text-field" placeholder=" " />
        <v-btn
          v-clipboard:copy="link"
          v-clipboard:success="onCopy"
          v-clipboard:error="onCopyError"
          class="copy"
          icon=""
          flat=""
          color="news"
        >
          <v-icon>file_copy</v-icon>
        </v-btn>
      </div>
      <div class="box__row">
        <div class="box__row__text">
          Поделитесь ссылкой с друзьями и получайте бонусные балы
        </div>
      </div>
    </div>
    <div class="tab_invite__box">
      <div class="box__row">
        <div class="box__row__text">
          Ваши друзья также могут пройти регистрацию с использованием вашего индивидуального
          промокода.
        </div>
      </div>
      <div class="box__row">
        <img width="70" height="70" :src="qr_promo" />
        <v-text-field v-model="promo" class="text-field" placeholder=" " />
        <v-btn
          v-clipboard:copy="promo"
          v-clipboard:success="onCopy"
          v-clipboard:error="onCopyError"
          class="copy"
          icon=""
          flat=""
          color="news"
        >
          <v-icon>file_copy</v-icon>
        </v-btn>
      </div>
    </div>
    <v-snackbar v-model="snackbar" bottom="" left="" :timeout="2000">
      {{ snackbarMessage }}
      <v-btn color="news" flat="" @click="snackbar = false">
        Закрыть
      </v-btn>
    </v-snackbar>
    <InviteStatistics />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import QR from 'qrcode';
import InviteStatistics from './InviteStatistics';

export default {
  name: 'Invite',
  components: {
    InviteStatistics
  },
  data() {
    return {
      snackbar: false,
      snackbarMessage: '',
      qr_link: '',
      qr_promo: '',
      promo: ''
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),

    isLoading() {
      return this.loading(this.getPromoUrl);
    },

    enduser_id() {
      return parseInt(this.$route.params.enduser);
    },

    link() {
      return `${process.env.VUE_APP_APP_URL}carriers/?ref=${this.promo}`;
    }
  },
  created() {
    this.getPromoUrl = 'adm_bonuses/referrals/promo';
  },
  async mounted() {
    await this.getPromo(this.enduser_id);
    this.generateQR();
  },
  methods: {
    ...mapActions('common', ['createController']),

    async getPromo(enduser) {
      const topic = this.getPromoUrl;
      try {
        const { promo } = await this.createController({ topic, json: { id: enduser } });
        this.promo = promo;
      } catch (e) {
        console.error(`Ошибка в запросе ${topic}`);
      }
    },

    async generateQR() {
      const dataLink = await QR.toDataURL(this.link);
      this.qr_link = dataLink;
      const dataPromo = await QR.toDataURL(this.promo);
      this.qr_promo = dataPromo;
    },

    onCopy(e) {
      this.snackbar = true;
      this.snackbarMessage = `Скопировано: ${e.text}`;
    },

    onCopyError() {
      this.snackbar = true;
      this.snackbarMessage = 'Ошибка: не скопировано!';
    }
  }
};
</script>

<style lang="scss">
.tab_invite {
  padding: 0 30px;

  .tab_invite__title {
    padding: 20px 0;
  }

  .tab_invite__box {
    margin-bottom: 15px;
    padding: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);

    .box__row {
      display: flex;
      align-items: center;
      .text-field {
        width: 40%;
        max-width: 40%;
      }
      .copy {
        color: #1867c0;
      }
      .box__row__text {
        &.count {
          margin-left: auto;
        }
        &.bold {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
