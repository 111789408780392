import { setField } from 'common/store/helpers';
import moment from 'common/helpers/date';
import { YEAR_NULL } from 'common/helpers/dateEnums';
import { getUUID } from 'common/helpers';

import { transportApi, TRANSPORT_VERSION, TRANSPORT_SCHEME } from 'common/api/TransportApi';

function createValues() {
  return {
    cid: 'new',
    type: '',
    date: null,
    model: '',
    number: '',
    yearManuf: '',
    typeTS: null,
    properties: {},

    menu: false,
    carcass: ''
  };
}

/* eslint no-shadow: 0 */
function setValues(values) {
  const currentValues = {};
  const year = moment.utc(values.vehicle.release_year).year();
  const date = values.vehicle.valid_until;
  currentValues.id = values.vehicle.id;
  currentValues.cid = values.vehicle.cid;
  currentValues.type = values.vehicle.vehicle_type;
  currentValues.carcass = values.vehicle.carcass;
  currentValues.typeTS = values.vehicle.type_right_use;
  currentValues.model = values.vehicle.brand_and_model;
  currentValues.number = values.vehicle.registration_plate;
  currentValues.yearManuf = year !== YEAR_NULL ? year : '';
  currentValues.date = moment(date).year() !== YEAR_NULL ? date : '';
  const properties = {};
  values.properties.forEach(item => {
    properties[item.name] = item.value;
  });
  currentValues.properties = properties; // для того, чтобы checkbox отрабатывали правильно
  currentValues.isVerified = values.vehicle.verif_status === 'verified';
  return currentValues;
}

const state = {
  scheme: {},
  current: null,
  missingData: [],
  images: {}
};

const getters = {
  currentImages(state) {
    return (state.current && state.images[state.current.cid]) || {};
  }
};

const mutations = {
  setField
};

const actions = {
  async getScheme({ commit, state }) {
    if (Object.keys(state.scheme).length) {
      return;
    }

    try {
      const { settings } = await transportApi(this.dispatch).transportGetVersionWithScheme();

      commit('setField', { field: 'scheme', value: settings });
    } catch (error) {
      console.error(`Ошибка в запросе ${TRANSPORT_VERSION} или в ${TRANSPORT_SCHEME}`, error);
    }
  },

  async getTransport({ dispatch, commit }, id) {
    commit('setField', { field: 'current', value: createValues() });
    commit('setField', { field: 'images', value: {} });
    if (id === 'new') return;
    const params = {
      topic: 'admin/endusers/contractor/transport_means/means/details/get',
      json: { id }
    };
    const {
      photos,
      properties,
      vehicle,
      missing_data: missingData
    } = await dispatch('common/createController', params, { root: true });
    commit('setField', { field: 'current', value: setValues({ properties, vehicle }) });
    const errors = Object.keys(missingData).reduce(
      (acc, curr) => [...acc, ...missingData[curr]],
      []
    );
    commit('setField', { field: 'missingData', value: errors });
    await dispatch('deleteCurrentPhotos');
    await dispatch('setTransportImages', photos);
  },
  deleteCurrentPhotos({ state, commit, getters: { tsCid } }) {
    const images = { ...state.images };
    delete images[tsCid];
    commit('setField', { field: 'images', value: images });
  },
  setTransportImages(
    { state, commit, rootGetters: { 'common/cid': cid }, rootState: { defaultURL, clients } },
    images = []
  ) {
    const allImages = { ...state.images };
    if (!allImages[state.current.cid]) {
      allImages[state.current.cid] = {};
    }
    const currentImages = allImages[state.current.cid];
    images.forEach(({ type, file, src, photo, tiny }) => {
      const id = `${state.current.cid}_${type}_${getUUID()}`;
      const imgSrc = src || `${defaultURL}/files/${cid}/${clients.info.cid}/${file}`;
      const tinySrc = `${defaultURL}/files/${cid}/${clients.info.cid}/${tiny}`;
      const image = [
        {
          src: imgSrc,
          id,
          file,
          photo,
          tiny: tinySrc
        }
      ];
      currentImages[type] = currentImages[type] ? currentImages[type].concat(image) : image;
    });
    if (!images[0] || !images[0].photo) {
      images.forEach(element => {
        currentImages[element.type].forEach(el => {
          if (el.file === element.file) el.photo = element;
        });
      });
    }
    commit('setField', { field: 'images', value: allImages });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
