<template>
  <v-form v-if="showAddForm" v-model="isValid" class="form">
    <v-container grid-list-lg="grid-list-lg">
      <v-layout row="row" wrap="wrap">
        <v-flex xs12>
          <custom-input
            v-model="version"
            :rules="[v => !!v || 'Поле обязательно для заполнения']"
            label="Версия схемы"
          />
        </v-flex>
        <v-flex xs12>
          <custom-input v-model="comment" label="Комментарий" />
        </v-flex>
        <v-flex xs12>
          <custom-button
            class="ml-0"
            color="primary"
            large
            :disabled="!isValid"
            @click="
              $emit('submit', {
                version,
                comment
              })
            "
          >
            Добавить
          </custom-button>
          <custom-button color="primary" large outline @click="$emit('close')">
            Отменить
          </custom-button>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: 'CarcassesAddForm',
  props: {
    showAddForm: Boolean
  },
  data() {
    return {
      isValid: '',
      version: '',
      comment: ''
    };
  }
};
</script>

<style lang="scss" scoped>
.form {
  margin: 0 auto;
  width: calc(100% - 32px);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.349019607843137);
  z-index: 1;
  background-color: #fff;
}
</style>
