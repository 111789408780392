<template>
  <div class="passport">
    <v-tabs class="passport__tabs">
      <v-tab href="#passport" @click="changeTab('passport')">
        Паспорт
      </v-tab>
      <v-tab v-if="vehicle_type === 'truckers_db'" href="#license" @click="changeTab('license')">
        Водительское удостоверение
      </v-tab>
      <v-tab-item id="passport">
        <div v-if="activeTab.passport && photos.passport.length" class="viewer-wrapper">
          <ImageSlider :images="photos.passport" />
        </div>
        <div v-else>
          <div>Нет загруженных фотографий</div>
        </div>
      </v-tab-item>
      <v-tab-item v-if="vehicle_type === 'truckers_db'" id="license">
        <div v-if="activeTab.license && photos.license.length" class="viewer-wrapper">
          <ImageSlider :images="photos.license" />
        </div>
        <div v-else>
          <div>Нет загруженных фотографий</div>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ImageSlider from '../../common/ImageSlider.vue';

export default {
  components: {
    ImageSlider
  },
  data() {
    return {
      activeTab: {
        passport: true,
        license: false
      }
    };
  },
  computed: {
    ...mapState('verifications', ['info']),
    ...mapState('login', ['cid']),
    defaultURL() {
      return this.$store.state.defaultURL;
    },
    vehicle_type() {
      return this.$route.params.type || '';
    },
    photos() {
      const list = {};
      if (this.info) {
        this.info.photos.forEach(photo => {
          if (!list[photo.type]) {
            list[photo.type] = [];
          }
          list[photo.type].push(this.getPhotoPath(photo));
        });
      }
      return {
        passport: [
          ...(list.passport_photo || []),
          ...(list.passport_registration || []),
          ...(list.passport_previous_number || [])
        ],
        license: [...(list.driver_license_photo || []), ...(list.driver_license_back || [])]
      };
    }
  },
  methods: {
    changeTab(tab) {
      Object.keys(this.activeTab).forEach(currentTab => {
        this.activeTab[currentTab] = currentTab === tab;
      });
    },
    getPhotoPath(item) {
      return `${this.defaultURL}/files/${this.cid}/${this.info.cid}/${item.file}`;
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss">
.passport {
  height: 100%;
  .passport__tabs {
    height: 100%;
    .v-tabs__bar {
      height: 48px;
    }
  }
}
</style>
