<template>
  <div v-if="loading('admin/endusers/profile/address/get')" class="loading">
    <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
  </div>
  <div v-else class="tab_address">
    <div class="title">
      Адрес для корреспонденции
      <div class="tab_address__row">
        <v-checkbox
          v-model="isAddressSame"
          class="checkbox-address"
          :readonly="true"
          :label="`Адрес для корреспонденции совпадает с юридическим адресом`"
        />
      </div>
      <div class="tab_address__row">
        <div class="tab_address__cell__index">
          <v-text-field
            label="Индекс"
            :value="address.actual_zip"
            placeholder=" "
            :readonly="true"
          />
        </div>
        <div class="tab_address__cell__address">
          <v-text-field
            label="Адрес"
            :value="address.actual_address"
            placeholder=" "
            :readonly="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'ProfileTabAddress',
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('clientsProfile', ['address']),
    isAddressSame() {
      return !!this.address.same_address;
    }
  },
  mounted() {
    this.getAddress();
  },
  methods: {
    ...mapActions('clientsProfile', ['getAddress'])
  }
};
</script>
<style lang="scss">
.tab_address {
  .tab_address__row {
    display: flex;
    font-weight: 400;

    .tab_address__cell__index {
      padding: 15px 5px;
      max-width: 30%;
    }
    .tab_address__cell__address {
      padding: 15px 5px;
      width: 70%;
    }
  }
}
</style>
