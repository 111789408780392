<template>
  <div v-if="showForm" class="dialog">
    <v-toolbar dark="dark" color="primary">
      <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
      <v-spacer />
      <custom-button icon color="white" @click="$emit('close')">
        <v-icon>close</v-icon>
      </custom-button>
    </v-toolbar>
    <Tabs class="dialog__tabs" :tabs="tabsList" active="BodyWorks" />
    <div class="dialog__action">
      <template v-if="isDraft">
        <custom-button
          color="primary"
          large
          disabled
          @click="
            () => {
              $emit('publish');
              $emit('close');
            }
          "
        >
          Опубликовать
        </custom-button>
        <custom-button
          color="primary"
          large
          :disabled="!isSuper"
          @click="
            () => {
              $emit('delete');
              $emit('close');
            }
          "
        >
          Удалить
        </custom-button>
      </template>
      <custom-button color="primary" large @click="$emit('close')">
        Закрыть
      </custom-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Tabs from '../../../Layout/Tabs';
import BodyWorks from './BodyWorks';
import BodyWorksGroups from './BodyWorksGroups';
import BodyWorksOptions from './BodyWorksOptions';
import BodyWorksChr from './BodyWorksChr';
import BodyWorksOptionsChr from './BodyWorksOptionsChr';
import LoadUnloadMethods from './LoadUnloadMethods';

export default {
  name: 'CarcassesDialog',
  components: {
    Tabs
  },
  props: {
    showForm: Boolean
  },
  data() {
    return {
      tabsList: [
        { name: 'BodyWorks', component: BodyWorks, title: 'Кузова' },
        { name: 'BodyWorksGroups', component: BodyWorksGroups, title: 'Группы кузовов' },
        { name: 'BodyWorksChr', component: BodyWorksChr, title: 'Характеристики кузовов' },
        { name: 'BodyWorksOptions', component: BodyWorksOptions, title: 'Опции кузовов' },
        {
          name: 'BodyWorksOptionsChr',
          component: BodyWorksOptionsChr,
          title: 'Характеристики опций кузова'
        },
        {
          name: 'LoadUnloadMethods',
          component: LoadUnloadMethods,
          title: 'Способы погрузки/разгрузки'
        }
      ]
    };
  },
  computed: {
    ...mapState('login', ['isSuper']),
    ...mapGetters('carcasses', ['getCurrentType', 'getCurrentVersion']),
    dialogTitle() {
      if (this.getCurrentType === 'draft')
        return `Черновик кузовов ${this.getCurrentVersion} версии`;
      if (this.getCurrentType === 'archive')
        return `Архивный список кузовов ${this.getCurrentVersion} версии`;
      if (this.getCurrentType === 'current') return 'Текущий список кузовов';
      return '';
    },
    isDraft() {
      return this.getCurrentType === 'draft';
    }
  }
};
</script>

<style lang="scss">
.dialog {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-bottom: 6px;
  min-height: 680px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.349019607843137);
  &__action {
    margin-top: auto;
  }
  &__content {
    padding: 12px 24px;
  }
}
</style>
