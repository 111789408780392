<template>
  <div>
    <ShippingInvoicesHeader
      v-if="!cantViewTnList"
      :is-operator="isOperator"
      :is-shipper="isShipper"
      :disabled-add-tn="disabledAddTn"
      @toggle-form="showWaybillForm = $event"
    />

    <ContentBlock v-if="showWaybillForm">
      <WayBillForm
        :is-shipper-fl="isShipperFl"
        :is-pin-view="isPinView"
        :pin-list="pinList"
        new-tn
        rows="2"
        @save-tn="saveTn"
        @close="showWaybillForm = false"
      />
    </ContentBlock>

    <div v-if="isLoading" class="text-xs-center pt-3">
      <v-progress-circular :size="40" color="primary" indeterminate />
    </div>

    <template v-else>
      <Stub v-if="cantViewTnList" message="Нет документов для отображения по этому заказу" />

      <Stub v-else-if="isEmptyTnList" message="Нет накладных для отображения" />

      <template v-else>
        <ShippingInvoicesList
          :is-shipper="isShipper"
          :is-order-ended="isOrderEnded"
          :is-shipper-fl="isShipperFl"
          :tn-list="tnList"
          :edited-tn-number="editedTnNumber"
          :delete-element="deleteElement"
          @download="downloadTn"
          @delete="deleteTn"
          @save="saveTn"
          @change-tn-number="editedTnNumber = $event"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';

const Stub = () => import('common/components/Stub');
const WayBillForm = () => import('common/views/OrderView/OrderView/WayBillForm');
const ContentBlock = () => import('common/components/Orders/ContentBlock');
const ShippingInvoicesHeader = () =>
  import('common/views/OrderView/OrderDocuments/ShippingInvoices/ShippingInvoicesHeader');
const ShippingInvoicesList = () =>
  import('common/views/OrderView/OrderDocuments/ShippingInvoices/ShippingInvoicesList');

import {
  getHrsMints,
  createDatetimeWithOffset,
  formattingDate,
  getUTCDate
} from 'common/helpers/dateHelper';
import lightFormat from 'date-fns/lightFormat';
import { tnFl, tnUl } from 'common/helpers/pdf';
import { TYPES } from '@/utils/transportEnums';
import { listPaymentEventForDoc, paymentTypeItemsForDoc } from 'common/components/Orders/enums.js';

import { transportApi } from 'common/api/TransportApi';

export default {
  name: 'ShippingInvoices',
  components: {
    ContentBlock,
    WayBillForm,
    Stub,
    ShippingInvoicesHeader,
    ShippingInvoicesList
  },
  props: {
    isOperator: Boolean,
    isShipperFl: Boolean,
    isPinView: Boolean,
    isOrderEnded: Boolean,
    pinList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showWaybillForm: false,
      isLoading: false,
      editedTnNumber: null,
      deleteElement: null,
      vehicle: {}
    };
  },
  computed: {
    ...mapState('orders', {
      currentOrderCid: state => state.orderInfo.info.cid
    }),
    ...mapState('orders', {
      tnList: state => state.orderInfo.tnList,
      pointsList: state => state.orderInfo.info.route,
      info: state => state.orderInfo.info,
      trucker: state => state.orderInfo.trucker,
      shipper: state => state.orderInfo.shipper,
      carrier: state => state.orderInfo.carrier
    }),
    ...mapGetters('login', ['isShipper']),
    ...mapGetters('orders', ['getListProperties', 'getListCharacteristics', 'getListCarcasses']),

    isEmptyTnList() {
      return !this.tnList.map(_ => _?.documents || []).flat().length;
    },

    cantViewTnList() {
      return !this.isShipper && this.isShipperFl && this.isEmptyTnList;
    },

    disabledAddTn() {
      return this.showWaybillForm || this.disabledButton || this.isOrderEnded || this.isLoading;
    },

    disabledButton() {
      function combinationPoints(n) {
        return n === 1 ? n : n + combinationPoints(n - 1);
      }
      const allCombination = combinationPoints(this.info.route.length - 1);
      const haveCombination = this.tnList.reduce((acc, curr) => acc + curr.documents.length, 0);

      const isEmptyCombinations = haveCombination === allCombination;
      if (this.isPinView) {
        /* Дизейблим пока не будет список пинов в режиме просмотра заказа через параметр pin */
        if (!this.pinList.length) {
          return !this.pinList.length;
        }
        /*  Находим point который соответсвует текущему пину*/
        const pin = sessionStorage.getItem('pin');
        const { point } = this.pinList.find(_ => _.pin === pin);
        const documents = this.tnList.map(_ => _.documents)?.flat() || [];
        /* Отнимаем от маршрутов текущий point */
        const routesCount = this.pointsList.length - point;
        /* Смотрим сколько уже создано ТН-ов по этому point-у */
        const tnCreatedFromPin = this.tnList.find(_ => _.point === point)?.documents?.flat() || [];
        return routesCount === tnCreatedFromPin.length || isEmptyCombinations;
      } else {
        return isEmptyCombinations;
      }
    }
  },
  created() {
    this.getTnListHandler();
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions(['addSnackbar']),
    ...mapActions('orders', ['getTnList']),
    ...mapMutations('orders', ['setOrderInfoField']),

    async getTnListHandler() {
      try {
        this.isLoading = true;
        await this.getTnList();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async downloadTn({ numberPoint, tn }) {
      const { point: sourcePoint } = this.info.route[tn.source_point.point - 1];
      const { point: destinationPoint } = this.info.route[tn.destination_point.point - 1];
      const getPointsDate = field => {
        const [hours, minutes] = getHrsMints(tn[field].time);
        const { gmtOffset } = tn[field];
        const formattedDate = lightFormat(
          createDatetimeWithOffset({ date: tn[field].datetime, offset: gmtOffset }),
          'dd.MM.yyyy, HH:mm'
        );
        return formattedDate;
      };

      const getPointOrderDate = field => {
        const { point: tnPointNumber } = tn[field];
        const { point } = this.pointsList[tnPointNumber - 1];
        const [hours, minutes] = getHrsMints(point.time);
        const { gmtOffset } = point;
        const formattedDate = lightFormat(
          createDatetimeWithOffset({ date: point.datetime, offset: gmtOffset }),
          'dd.MM.yyyy, HH:mm'
        );
        return formattedDate;
      };

      let content = {
        number: this.info.id,
        date: formattingDate(this.info.accept_datetime),
        shipper: this.getDataTn(this.shipper),
        carrier: this.getDataTn(this.carrier),
        nameCargo: tn.name || '\n',
        numberPlaces: tn.loaded_places,
        volumePlaces: tn.volume_of_loaded_places,
        weightPlaces: tn.weight_of_loaded_places,
        cost: tn.cost,
        sourcePoint: tn.source_point,
        destinationPoint: tn.destination_point,
        sourceDate: getPointsDate('source_point'),
        sourcePointOrderDate: getPointOrderDate('source_point'),
        destinationDate: getPointsDate('destination_point'),
        trucker: {
          ...this.trucker
        }
      };
      if (this.isShipperFl) {
        await this.getAcceptedTransportInfo();

        content = {
          ...content,
          paymentInfo: this.getInfoPayment(),
          corouting: this.info.corouting ? `да` : `нет`,
          finalCost: `${this.info.final_cost.toLocaleString('ru')} руб`,
          description: tn.description || '\n',
          transport: this.getTransportInfoFl(),
          addService: this.getAddService()
        };
      } else {
        await this.getAcceptedTransportInfo();

        content = {
          ...content,
          waybill_information: tn.waybill_information,
          freightDocuments: tn.list_of_freight_documents || '\n',
          numberSeals: tn.number_of_seals,
          transportCharacteristic: this.getCharacteristicTS(),
          contactCarrierPhone: this.info.contact_carrier_phone,
          contactCarrierName: this.info.contact_carrier_name,
          transport: this.getTransportInfoUl()
        };
      }
      const pdf = this.isShipperFl ? tnFl(content) : tnUl(content);
      pdf.download(`Транспортная накладная - ${numberPoint}_${this.info.id}.pdf`);
    },

    getTransportInfoUl() {
      if (this.vehicle.type === 'autotrain') {
        const head = `${TYPES[this.vehicle.head.vehicle.vehicle_type]}, ${
          this.vehicle.head.vehicle.brand_and_model
        }, ${lightFormat(getUTCDate(this.vehicle.head.vehicle.release_year), 'yyyy')}`;
        const tail = `${TYPES[this.vehicle.tail.vehicle.vehicle_type]}, ${
          this.vehicle.tail.vehicle.brand_and_model
        }, ${lightFormat(getUTCDate(this.vehicle.tail.vehicle.release_year), 'yyyy')}`;
        return {
          name: `${head}/${tail}`,
          number: `${this.vehicle.head.vehicle.registration_plate}/${this.vehicle.tail.vehicle.registration_plate}`
        };
      }
      return {
        name: `${TYPES[this.vehicle.vehicle_type]}, ${this.vehicle.brand_and_model}, ${lightFormat(
          getUTCDate(this.vehicle.release_year),
          'yyyy'
        )}`,
        number: this.vehicle.registration_plate
      };
    },

    getDataTn(data) {
      const info = {
        name: data.name,
        phone: data.phone,
        yurAddress: '',
        infoForSignatures: `${data.name}, ${data.phone}`,
        isFL: data.yur_type === 'FL',
        contactLead: `${this.info.contact_carrier_name}, ${this.info.contact_carrier_phone}`
      };
      if (data.yur_type === 'IP') {
        info.name = data.name;
        info.phone = data.phone;
        info.yurAddress = `${data.legal_address}`;
        info.infoForSignatures = `${data.name}`;
      } else if (data.yur_type === 'UL') {
        info.name = data.name;
        info.phone = data.phone;
        info.yurAddress = `${data.name}, ${data.legal_address}`;
        info.infoForSignatures = `${data.name}, ${data.ceo}`;
      }
      return { ...info };
    },

    getCharacteristicTS() {
      return [
        this.getListCarcasses(this.info.carcasses),
        this.getListCharacteristics(this.info.properties),
        this.getListProperties({ field: 'options', properties: this.info.properties }),
        this.getListProperties({ field: 'lifting', properties: this.info.properties })
      ]
        .filter(_ => !!_)
        .join(', ');
    },

    getAddService() {
      const data = {
        source: 'нет',
        destination: 'нет'
      };

      if (!this.info.additional_service?.length) {
        return { ...data };
      }

      const [additionalService = {}] = this.info.additional_service;
      const {
        departure_loading_operations: departureService,
        destination_loading_operations: loadingService
      } = additionalService;

      data.source = (departureService.movers_number && 'да') || 'нет';
      data.destination = (loadingService.movers_number && 'да') || 'нет';
      return { ...data };
    },

    getInfoPayment() {
      const paymentEvent = listPaymentEventForDoc.find(
        item => item.value === this.info.payment_event
      )?.text;
      const paymentType = this.info.payment_type
        .map(item => paymentTypeItemsForDoc.find(el => el.value === item)?.label)
        .join(', ');
      if (this.info.final_prepaid) {
        const prepaymentType = this.info.prepayment_type
          .map(item => paymentTypeItemsForDoc.find(el => el.value === item)?.label)
          .join(', ');
        return {
          cost: this.info.final_cost,
          paymentType: `предоплата - ${prepaymentType}, остаток платежа - ${paymentType}`,
          paymentEvent: `предоплата ${this.info.final_prepaid.toLocaleString(
            'ru'
          )}, ${paymentEvent} - ${this.info.final_cost - this.info.final_prepaid}`
        };
      }
      return {
        cost: this.info.final_cost,
        paymentType,
        paymentEvent
      };
    },

    getTransportInfoFl() {
      if (this.vehicle.type === 'autotrain') {
        const head = `${TYPES[this.vehicle.head.vehicle.vehicle_type]}, ${
          this.vehicle.head.vehicle.brand_and_model
        }, ${lightFormat(getUTCDate(this.vehicle.head.vehicle.release_year), 'yyyy')}`;
        const tail = `${TYPES[this.vehicle.tail.vehicle.vehicle_type]}, ${
          this.vehicle.tail.vehicle.brand_and_model
        }, ${lightFormat(getUTCDate(this.vehicle.tail.vehicle.release_year), 'yyyy')}`;
        return {
          name: `${head}/${tail}`,
          number: `${this.vehicle.head.vehicle.registration_plate}/${this.vehicle.tail.vehicle.registration_plate}`
        };
      }
      return {
        name: `${TYPES[this.vehicle.vehicle_type]}, ${this.vehicle.brand_and_model}, ${lightFormat(
          getUTCDate(this.vehicle.release_year),
          'yyyy'
        )}`,
        number: this.vehicle.registration_plate
      };
    },

    async getAcceptedTransportInfo() {
      const json = { cid: this.info.ctransport_id };
      try {
        const { type = '', vehicles = {}, vehicle = {} } = await transportApi(
          this.$store.dispatch
        ).transportGet({ json });
        const tsInfo = type === 'autotrain' ? { ...vehicles } : { ...vehicle };
        this.vehicle = {
          ...tsInfo,
          type
        };
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },

    async deleteTn(point, tn) {
      try {
        this.deleteElement = tn;
        const topic = 'order/tn/delete';
        await this.createController({
          topic,
          json: { cid: this.currentOrderCid, point, tn }
        });
        const newTnList = [...this.tnList];
        const index = this.tnList.findIndex(item => item.point === point);
        const newDocumentsPoint = this.tnList[index].documents.filter(tnItem => tnItem.tn !== tn);
        newTnList[index].documents = [...newDocumentsPoint];
        this.setOrderInfoField({ field: 'tnList', value: newTnList });
        this.addSnackbar({ message: 'Транспортная накладная удалена', type: 'success' });
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
        this.addSnackbar({ message: 'Ошибка на сервере' });
      } finally {
        this.deleteElement = null;
      }
    },

    async saveTn() {
      this.showWaybillForm = false;
      this.editedTnNumber = null;
      await this.getTnListHandler();
    }
  }
};
</script>
