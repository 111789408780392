import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import ru from 'vuetify/es5/locale/ru';

Vue.use(Vuetify, {
  theme: {
    primary: '#009ce9',
    secondary: '#027fbd',
    accent: '#82B1FF',
    error: '#f54f4a',
    info: '#000000',
    success: '#65b38a',
    warning: '#ff9f57',
    news: '#03a9f4',
    white: '#ffffff'
  },
  iconfont: 'md',
  lang: {
    locales: { ru },
    current: 'ru'
  }
});
