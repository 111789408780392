<template>
  <Tooltip :tooltip="message">
    <icon name="helpCircle" class="icon" :size="size" />
  </Tooltip>
</template>

<script>
import Tooltip from 'common/components/Tooltip';
export default {
  name: 'TooltipAnswer',
  components: {
    Tooltip
  },
  props: {
    message: String,
    size: {
      type: [String, Number],
      default: 18
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.icon {
  color: $color-main;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin: 0 14px 0 12px;
  flex-shrink: 0;
  cursor: pointer;
}
</style>
