<template>
  <ContentBlock subtitle-tag="h2" subtitle="Характеристики груза">
    <span>{{ info.name }}</span>
    <lineWithDots :description="info.indivisible ? 'нет' : 'да'" title="Делимость груза" />
    <div v-if="info.photos && info.photos.length" class="mt-3">
      <swiper
        :images="info.photos"
        :limit="3"
        height="200px"
        max-width="300px"
        url-field="smallSrc"
        @image-click="open"
      />
      <swiper-dialog
        v-if="isShow"
        :images="info.photos"
        :slide-index="slideIndex"
        @close="isShow = false"
      />
    </div>
  </ContentBlock>
</template>

<script>
import { mapState } from 'vuex';
import { swiperDialog, swiper } from 'common/components/swiper';
import ContentBlock from 'common/components/Orders/ContentBlock.vue';
import lineWithDots from 'common/components/Orders/lineWithDots.vue';

export default {
  name: 'Characteristics',
  components: {
    ContentBlock,
    swiperDialog,
    swiper,
    lineWithDots
  },
  data() {
    return {
      isShow: false,
      slideIndex: 0
    };
  },
  computed: {
    ...mapState('orders', {
      info: state => state.orderInfo.info
    })
  },
  methods: {
    open(index) {
      this.isShow = true;
      this.slideIndex = index;
    }
  }
};
</script>
