<template>
  <Tabs class="stage_profile" :tabs="tabs" :active="currentTab" @change="currentTab = $event" />
</template>

<script>
import { mapState } from 'vuex';
import Tabs from '../../Layout/Tabs';
import ProfileTabUsers from './ProfileTabUsers';
import ProfileTabOrganization from './ProfileTabOrganization';
import ProfileTabAddress from './ProfileTabAddress';
import ProfileTabCards from './ProfileTabCards';
import ProfileTabPersonal from './ProfileTabPersonal';
import ProfileRegion from './ProfileRegion';

export default {
  name: 'Profile',
  components: {
    Tabs
  },
  data() {
    return {
      items: [],
      currentTab: 'ProfileTabUsers'
    };
  },
  computed: {
    ...mapState('clients', ['info'])
  },
  created() {
    const tabs = [
      {
        name: 'ProfileTabUsers',
        component: ProfileTabUsers,
        title: 'Пользователи'
      },
      {
        name: 'ProfileTabPersonal',
        component: ProfileTabPersonal,
        show: this.info.yur_type === 'FL' && this.info.category !== 'shipper',
        title: 'Данные ФЛ'
      },
      {
        name: 'ProfileTabOrganization',
        component: ProfileTabOrganization,
        show: this.info.yur_type !== 'FL',
        title: 'Организация'
      },
      {
        name: 'ProfileTabAddress',
        component: ProfileTabAddress,
        title: 'Адрес',
        show: this.info.yur_type !== 'FL'
      },
      {
        name: 'ProfileTabCards',
        component: ProfileTabCards,
        title: this.info.yur_type !== 'FL' ? 'Счета и карты' : 'Банковские карты'
      },
      {
        name: 'ProfileRegion',
        component: ProfileRegion,
        title: 'Регионы',
        show: this.info.category === 'carrier'
      }
    ];
    this.tabs = tabs.filter(_ => _.show !== false);
  }
};
</script>
