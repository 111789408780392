<template>
  <div>
    <view-window
      v-if="isWindowShowed"
      :dispute="activeDispute"
      to-tab="Dispute"
      @close="isWindowShowed = false"
    />
    <dispute v-else is-operator @click="onClick" />
  </div>
</template>

<script>
import dispute from 'common/views/OrderView/Disputes.vue';
import ViewWindow from '@/components/common/ViewWindow';
import { mapActions } from 'vuex';

export default {
  name: 'DisputesAdmin',
  components: {
    dispute,
    ViewWindow
  },
  data() {
    return {
      isWindowShowed: false,
      activeDispute: {}
    };
  },
  methods: {
    ...mapActions('orders', ['getOrder', 'getScheme']),
    async onClick(activeDispute) {
      await this.getScheme();
      await this.getOrder({ json: { cid: activeDispute.corder_id } });
      this.activeDispute = activeDispute;
      this.isWindowShowed = true;
    }
  }
};
</script>

<style scoped>
/deep/ .disputes {
  padding: 0 12px;
}
</style>
