<template>
  <!-- TODO refactor it -->
  <v-container grid-list-lg class="transport-form">
    <v-layout v-for="(column, index) in columns" :key="index" row wrap>
      <v-flex
        v-for="(field, fieldIndex) in column"
        :key="fieldIndex"
        :sm3="!field.size || field.size === 3"
        :sm4="field.size === 4"
        :sm6="field.size === 6"
        :sm12="field.size === 12"
        :class="field.class"
        xs12
      >
        <div
          v-if="field.caption && field.show !== false"
          :class="{
            'pa-special': field.caption !== 'Характеристики кузова',
            'pb-13px': field.caption === 'Кузов'
          }"
          class="transport-caption"
        >
          {{ field.caption }}
        </div>
        <div v-else class="transport-field">
          <div
            v-if="field.component"
            :class="{ 'm-phoroTS': field.label === 'Фото транспортного средства' }"
            class="transport-value"
          >
            <i
              v-if="checkFieldOnEmpty(field)"
              :class="{
                'text-scan_sts': field.label === 'Скан/фото СТС',
                'text-scan_doc': field.label === 'Скан/фото документа',
                'text-photo_ts': field.label === 'Фото транспортного средства'
              }"
            >
              {{ field.label }}
            </i>
            <component :is="field.component" :value="field.value" />
          </div>
          <div v-else-if="field.show !== false" class="transport-value">
            <div v-if="field.html" v-html-safe="field.html" class="transport-info-html-content" />
            <custom-input
              v-else
              :class="{ 'with-label': field.label }"
              :label="field.label"
              :value="field.value || field.value === 0 ? field.value : 'Не задано'"
              disabled
            />
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'common/helpers/date';
import imageUploader from 'common/components/imageUploader.vue';
import { TYPES } from '@/utils/transportEnums';
import FieldCarcass from 'common/components/transport/FieldCarcass.vue';
import FieldVehiclePhotos from 'common/components/transport/FieldVehiclePhotos.vue';

export default {
  name: 'TransportInfo',
  components: {
    imageUploader,
    FieldCarcass
  },
  computed: {
    ...mapState('transportData', ['scheme', 'current', 'missingData']),
    ...mapGetters('transportData', ['currentImages']),
    columns() {
      if (!this.current.cid || this.current.cid === 'new') return [];
      const vehicle = {
        typeTS: this.fields.typeTS.find(item => item.value === this.current.typeTS) || {},
        carcass: this.fields.carcasses.find(item => item.id === this.current.carcass) || null,
        captions: {
          characteristics: 'Характеристики кузова',
          options: 'Имеющиеся опции',
          lifting: 'Допустимые способы погрузки/разгрузки'
        },
        characteristics: [],
        options: [],
        lifting: [],
        charOptions: []
      };

      const carcass = vehicle.carcass || {
        characteristics: [],
        options: [],
        lifting: []
      };

      carcass.characteristics.forEach(propValue => {
        const prop = this.fields.characteristics.find(item => item.id === propValue);
        const value = this.current.properties[prop.id];
        if (prop) {
          vehicle.characteristics.push({
            label: `${prop.carrier.name}, ${prop.unit_measure}`,
            value: value || value === 0 ? value : '',
            size: 4,
            class: this.getErrorClass(prop.id)
          });
        }
      });
      ['options', 'lifting'].forEach(type => {
        carcass[type].forEach(propValue => {
          const prop = this.fields[type].find(item => item.id === propValue);
          if (prop && this.current.properties[prop.id]) {
            vehicle[type].push({
              label: '',
              value: prop.name,
              id: prop.id,
              characteristics: prop.characteristics
            });
          }
        });
      });

      vehicle.charOptions =
        (vehicle.options.length &&
          vehicle.options
            .map(opt => this.fields.options.find(_ => _.id === opt.id))
            .reduce((acc, curr) => [...acc, ...curr.characteristics], [])
            .map(opt => this.fields.options_chars.find(_ => _.id === opt))
            .map(_ => ({
              id: _.id,
              label: _.carrier.name,
              value: this.current.properties[_.id] || 'не задано',
              class: this.getErrorClass(_.id),
              measure: this.current.properties[_.id] ? ` ${_.unit_measure}` : ''
            }))) ||
        [];

      ['options', 'lifting'].forEach(type => {
        const html = vehicle[type].reduce((acc, curr) => {
          if (curr.characteristics) {
            curr.characteristics = curr.characteristics
              .map(_ => vehicle.charOptions.find(char => char.id === _))
              .reduce(
                (acc2, curr2) =>
                  `${acc2 ? `${acc2}, ` : ''}${curr2.label}: <span class="${curr2.class}">${
                    curr2.value
                  }${curr2.measure}</span>`,
                ''
              );
          }
          return `${acc ? `${acc}, ` : ''}${curr.value}${
            curr.characteristics ? ` (${curr.characteristics})` : ''
          }`;
        }, '');
        vehicle[type] = vehicle[type].length
          ? [
              {
                label: '',
                value: '',
                html,
                size: 12
              }
            ]
          : [];
        if (vehicle[type].length === 0) {
          const typeStartWith = type.slice(0, 3);
          vehicle[type].push({
            label: '',
            value: '',
            size: 4,
            id: typeStartWith,
            class: this.getCommonError(typeStartWith)
          });
        }
        vehicle[type].unshift({ caption: vehicle.captions[type], size: 12 });
      });

      const images = this.currentImages || { ts: [], sts: [], tsdoc: [] };

      const firstPart = [
        [{ label: 'Вид транспортного средства', value: this.fields.types[this.current.type] }],
        [
          {
            label: 'Фото транспортного средства',
            value: images.ts,
            component: images.ts?.length ? FieldVehiclePhotos : null,
            size: 12,
            class: 'photos',
            show: this.current.type === 'general'
          }
        ],
        [
          { label: 'Марка и модель', value: this.current.model },
          { label: 'Регистрационный знак', value: this.current.number },
          {
            label: 'Год выпуска',
            value: this.current.yearManuf,
            class: this.getErrorClass('release_year')
          },
          {
            label: 'Скан/фото СТС',
            value: images.sts,
            component: images.sts?.length ? FieldVehiclePhotos : null,
            class: images.sts?.length ? 'photos' : this.getErrorClass('sts')
          }
        ],
        [
          {
            label: 'Тип права пользования ТС',
            value: vehicle.typeTS.text,
            class: this.getErrorClass('type_right_use')
          },
          {
            label: vehicle.typeTS.text !== 'Право собственности' ? 'Действует до' : '',
            value:
              vehicle.typeTS.text !== 'Право собственности'
                ? this.current.date && moment(this.current.date).format('DD.MM.YYYY')
                : '',
            class: this.getErrorClass('valid_until'),
            show: vehicle.typeTS.text !== 'Право собственности'
          },
          {
            label: vehicle.typeTS.text !== 'Право собственности' ? 'Скан/фото документа' : '',
            value: vehicle.typeTS.text !== 'Право собственности' ? images.tsdoc : [],
            component: images.tsdoc?.length ? FieldVehiclePhotos : null,
            class: images.tsdoc?.length ? 'photos' : this.getErrorClass('tsdoc'),
            show: vehicle.typeTS.text !== 'Право собственности'
          }
        ]
      ];

      const secondPart = [
        [
          {
            caption: 'Кузов',
            size: 12,
            value: this.current.type !== 'tractor'
          }
        ],
        [
          {
            label: '',
            value: vehicle.carcass &&
              this.current.type !== 'tractor' && { ...vehicle.carcass, comment_carrier: '' },
            component: vehicle.carcass ? FieldCarcass : null,
            size: 4,
            class: vehicle.carcass ? 'carcass' : this.getErrorClass('carcass')
          }
        ],
        [
          {
            caption: vehicle.captions.characteristics,
            size: 12,
            value: this.current.type !== 'tractor',
            show: vehicle.characteristics.length > 0
          }
        ],
        [...vehicle.characteristics],
        [...vehicle.options],
        [...vehicle.lifting]
      ];

      return this.current.type === 'tractor' ? firstPart : [...firstPart, ...secondPart];
    }
  },
  created() {
    this.fields = {
      ...this.scheme,
      types: TYPES,
      typeTS: [
        { text: 'Право собственности', value: 'proprietary' },
        { text: 'Лизинг', value: 'leasing' },
        { text: 'Договор аренды', value: 'lease-contract' }
      ]
    };
  },
  methods: {
    change() {
      this.$emit('change', this.current);
    },
    checkFieldOnEmpty(field) {
      if (Array.isArray(field.value)) return field.value.length;
    },
    getErrorClass(type) {
      return this.missingData.includes(type) ? 'has-error' : '';
    },
    getCommonError(startString) {
      return this.missingData.find(_ => _.startsWith(startString)) ? 'has-error' : '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.text-scan_sts {
  transform: translateX(-15px) scale(0.75);
}

.text-photo_ts {
  transform: translateX(-28px) scale(0.75);
}

.text-scan_doc {
  transform: translateX(-21px) scale(0.75);
}

.transport-form {
  padding-top: 0;
  padding-bottom: 90px;
  @media only screen and (max-width: breakpoint(sm)) {
    padding-bottom: 15px;
  }
  .photos {
    .transport-field {
      margin-bottom: 11px;

      .transport-value {
        border-color: transparent;
        flex-direction: column;
        align-items: flex-start !important;
        margin: 10px 0 0;

        i {
          margin: 2px 0 !important;
          line-height: 14px;
        }
      }
      .m-phoroTS {
        margin: 10px 0 25px 0;
      }
    }
  }
  .carcass {
    margin-bottom: 5px;

    .transport-value {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }
  }
  .transport-caption {
    font-size: 16px;
    padding: 18px 0;
    font-weight: 500;
  }

  .pa-special {
    padding-top: 18px;
    padding-bottom: 5px;
  }

  .pb-13px {
    padding-bottom: 13px;
  }

  .has-error .transport-value /deep/ .custom-input.v-input input {
    color: $color-error;
    -webkit-text-fill-color: $color-error;
  }
  .transport-field {
    margin: 10px 0;

    .transport-label {
      font-size: 12px;
      min-height: 18px;
      color: $gray;
    }
    .transport-value {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 0;

      .with-label {
        margin-top: 20px;
      }

      /deep/ .v-text-field {
        padding-top: 0;
      }

      /deep/ .v-text-field__details {
        display: none;
      }

      /deep/ i {
        display: inline-block;
        margin-bottom: 5px;
        font-style: normal;
        color: $gray;
        font-size: 16px;
      }
      &__name {
        display: inline-block;
        font-size: 14px;
        line-height: 1;
        margin: 0 0 0 10px;
      }
      /deep/ .input-group input:disabled {
        color: black;
      }
      /deep/ .input-group__details:before {
        background-color: rgba(0, 0, 0, 0.42);
      }
    }
  }
}

.transport-info-html-content {
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  width: 100%;
  padding: 10px 0;
  margin-bottom: 4px;
}
</style>
