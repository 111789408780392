<template>
  <div class="wrapper">
    <div class="wrapper__title" :class="{ 'is-open': isOpen }" @click="$emit('open', index)">
      <!-- eslint-disable-next-line vue/html-self-closing -->
      <span class="wrapper__title-circle"></span>
      {{ `Контактному лицу в точке маршрута: ${cityName}` }}
    </div>
    <div v-if="isOpen">
      <v-layout wrap>
        <v-flex xs12>
          <v-radio-group v-model="typeToPin">
            <v-radio :ripple="false" label="Из заказа" value="fromOrder" />
            <v-radio :ripple="false" label="Другому" value="toAnother" />
          </v-radio-group>
        </v-flex>
        <v-flex xs12 md6>
          <custom-input v-model="pinData.fio" :disabled="isFromOrder" label="Имя и фамилия" />
        </v-flex>
        <v-flex xs12 sm6 md3>
          <custom-input
            v-model="pinData.email"
            :rules="emailRules"
            label="E-mail"
            placeholder="xxxx@xx.xx"
          />
        </v-flex>
        <v-flex xs12 sm6 md3>
          <custom-input
            v-model="pinData.phone"
            :rules="phoneRules"
            :disabled="isFromOrder"
            label="Телефон"
            placeholder="+7 (ХХХ) ХХХ-ХХ-ХХ"
            masked
          />
        </v-flex>
      </v-layout>
      <alert
        v-if="showAlert"
        message="Недостаточно данных для отправки PIN-кода, задайте email или номер телефона"
        class="alert mb-3"
        type="danger"
      />
    </div>
  </div>
</template>

<script>
import { getFullAddress } from 'common/utils';
import Alert from 'common/components/Alert';
import { isCorrectPhone, isCorrectEmail } from 'common/helpers';
export default {
  name: 'ShipperDocumentsFormPin',
  components: {
    Alert
  },
  props: {
    infoPoint: {
      type: Object,
      default: () => ({})
    },
    isOpen: Boolean,
    showAlert: Boolean,
    index: Number
  },
  data() {
    return {
      typeToPin: 'fromOrder',
      pinData: {
        fio: '',
        email: '',
        phone: ''
      }
    };
  },
  computed: {
    isError() {
      return (
        !this.phoneOrEmailIsFilled ||
        !isCorrectEmail(this.pinData.email) ||
        !isCorrectPhone(this.pinData.phone)
      );
    },

    isFromOrder() {
      return this.typeToPin === 'fromOrder';
    },

    cityName() {
      return getFullAddress(this.infoPoint);
    },

    stringifiedPinData() {
      return JSON.stringify(this.pinData);
    },

    phoneOrEmailIsFilled() {
      return !!this.pinData.phone || !!this.pinData.email;
    }
  },
  watch: {
    typeToPin() {
      this.setFields();
    },

    stringifiedPinData() {
      this.$emit('update-data-pin', { ...this.pinData, error: this.isError });
    },

    isError(val) {
      if (!val) this.$emit('no-errors');
    },

    isOpen(val) {
      if (val) {
        this.$emit('update-data-pin', { ...this.pinData, error: this.isError });
        this.$emit('no-errors');
      }
    }
  },
  created() {
    this.setFields();

    this.emailRules = [v => isCorrectEmail(this.pinData.email) || 'E-mail некорректен'];
    this.phoneRules = [v => isCorrectPhone(this.pinData.phone) || 'Телефон некорректен'];
  },
  methods: {
    setFields() {
      if (this.isFromOrder) {
        this.pinData.fio = this.infoPoint?.contacts.name ?? '';
        this.pinData.phone = this.infoPoint?.contacts.phone ?? '';
      } else {
        this.pinData.fio = '';
        this.pinData.phone = '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.alert {
  width: calc(100% - 24px);
  margin: 6px 12px;
}

.wrapper {
  padding: 0 12px;
  &.hidden {
    display: none;
  }
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;
    &-circle {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 24px;
      max-width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid $gray-light;
      margin-right: 8px;
    }
    &.is-open .wrapper__title-circle:before {
      display: inline-block;
      content: '';
      margin: auto;
      min-width: 10px;
      max-width: 10px;
      height: 10px;
      border-radius: 50%;
      opacity: 1;
      background-color: $gray-darken;
    }
  }
}
/deep/ .v-input--radio-group__input {
  margin: 0 -12px;
  width: calc(100% + 24px);
  display: flex;
  flex-direction: row;
}
/deep/ .v-input--selection-controls {
  margin-top: 0;
}
.v-radio {
  max-width: 20%;
  margin: 0 !important;
  width: 100%;
  padding: 0 12px;
}

@media only screen and (max-width: breakpoint(md)) {
  .v-radio {
    max-width: 30%;
  }
}

@media screen and (max-width: breakpoint(sm)) {
  .v-radio {
    max-width: 50%;
  }
}
</style>
