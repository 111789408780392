<template>
  <div class="transport">
    <v-tabs class="transport__tabs">
      <v-tab v-if="photos.sts.length" href="#sts" @click="changeTab('sts')">
        СТС
      </v-tab>
      <v-tab v-if="photos.docs.length" href="#docs" @click="changeTab('docs')">
        Документы на право пользования
      </v-tab>
      <v-tab v-if="photos.ts.length" href="#ts" @click="changeTab('ts')">
        ТС
      </v-tab>
      <v-tab-item value="sts">
        <div v-if="activeTab.sts" class="viewer-wrapper">
          <ImageSlider v-if="photos.sts.length" :images="photos.sts" />
          <div v-else class="no-photo">
            Нет загруженных фотографий
          </div>
        </div>
      </v-tab-item>
      <v-tab-item value="docs">
        <div v-if="activeTab.docs" class="viewer-wrapper">
          <ImageSlider v-if="photos.docs.length" :images="photos.docs" />
          <div v-else class="no-photo">
            Нет загруженных фотографий
          </div>
        </div>
      </v-tab-item>
      <v-tab-item value="ts">
        <div v-if="activeTab.ts" class="viewer-wrapper">
          <ImageSlider v-if="photos.ts.length" :images="photos.ts" />
          <div v-else class="no-photo">
            Нет загруженных фотографий
          </div>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ImageSlider from '../../common/ImageSlider.vue';

export default {
  components: {
    ImageSlider
  },
  data() {
    return {
      activeTab: {
        sts: true,
        docs: false,
        ts: false
      }
    };
  },
  computed: {
    ...mapState('verifications', ['info']),
    ...mapState('login', ['cid']),
    defaultURL() {
      return this.$store.state.defaultURL;
    },
    photos() {
      return {
        sts: this.info.photos
          .filter(item => item.type === 'sts')
          .map(photo => this.getPhotoPath(photo)),
        ts: this.info.photos
          .filter(item => item.type === 'ts')
          .map(photo => this.getPhotoPath(photo)),
        docs: this.info.photos
          .filter(item => item.type === 'tsdoc')
          .map(photo => this.getPhotoPath(photo))
      };
    }
  },
  methods: {
    changeTab(tab) {
      Object.keys(this.activeTab).forEach(currentTab => {
        this.activeTab[currentTab] = currentTab === tab;
      });
    },
    getPhotoPath(photo) {
      return `${this.defaultURL}/files/${this.cid}/${this.info.cid}/${photo.file}`;
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/scss">
.transport {
  height: 100%;
  .transport__tabs {
    height: 100%;
    .v-tabs__bar {
      height: 48px;
    }
  }
}
</style>
