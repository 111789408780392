<template>
  <div class="client_drivers">
    <div class="client_drivers__title">
      Водители
    </div>
    <div class="client_drivers__table">
      <TableList
        :loading="loading(routeDrivers)"
        :values="list"
        :columns="columns"
        @clickItem="openWindow"
      >
        <template slot="verify" slot-scope="{ row }">
          <div :class="[row.verifyClass]">
            {{ row.verify }}
          </div>
        </template>
        <template slot="empty">
          <div>Нет водителей для отображения</div>
        </template>
      </TableList>
    </div>
    <div class="client_drivers__title">
      Запросы на прикрепление
    </div>
    <div class="client_drivers__table">
      <TableList
        :loading="loading(routeInvitations)"
        :values="invitations"
        :columns="columns_invitations"
      >
        <template slot="verify" slot-scope="{ row }">
          <div :class="[row.verifyClass]">
            {{ row.verify }}
          </div>
        </template>
        <template slot="empty">
          <div>Нет запросов для отображения</div>
        </template>
      </TableList>
    </div>
    <DriverInfo v-if="openInfo" :data="driverData" @verifyDenied="loadDriverList" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import TableList from '../../Layout/TableList.vue';
import DriverInfo from './DriverInfo.vue';

export default {
  components: {
    TableList,
    DriverInfo
  },
  data() {
    return {
      columns: [
        {
          title: 'ФИО',
          name: 'fullname',
          width: '30',
          alignLeft: true
        },
        {
          title: 'Дата регистрации',
          name: 'date',
          width: '30',
          alignLeft: true
        },
        {
          title: ' ',
          name: 'verify',
          width: '40',
          slot: true
        }
      ],
      columns_invitations: [
        {
          title: 'Номер',
          name: 'phone',
          width: '30',
          alignLeft: true
        },
        {
          title: 'Дата регистрации',
          name: 'date',
          width: '30',
          alignLeft: true
        },
        {
          title: ' ',
          name: 'verify',
          width: '40',
          slot: true
        }
      ],
      driverData: {}
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('clientsDrivers', ['routeDrivers', 'routeInvitations', 'list', 'invitations']),
    enduser_id() {
      return this.$route.params.enduser || false;
    },
    openInfo() {
      return !!this.$route.params.tab;
    }
  },
  watch: {
    openInfo(newVal, oldVal) {
      if (oldVal) {
        this.loadDriverList();
      }
    }
  },
  mounted() {
    this.loadDriverList();
    this.getInvitations({
      enduser: this.enduser_id
    });
  },
  methods: {
    ...mapActions('clientsDrivers', ['getDrivers', 'getInvitations']),
    openWindow(data) {
      this.driverData = data;
      if (this.driverData) {
        this.$router.push({
          name: 'ClientInfoStageTab',
          params: { stage: 'drivers', tab: 'info' }
        });
      }
    },
    loadDriverList() {
      this.getDrivers({
        enduser: this.enduser_id
      });
    }
  }
};
</script>

<style lang="scss">
.client_drivers {
  position: relative;
  height: 100%;
  padding: 10px;
  overflow-y: auto;
  .client_drivers__title {
    font-size: 24px;
    padding: 20px 0;
  }
  .v-input__slot {
    .v-text-field__slot {
      input {
        font-size: 15px;
      }
    }
  }
}
</style>
