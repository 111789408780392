<template>
  <div class="dialog">
    <v-toolbar dark="dark" color="primary">
      <v-toolbar-title>Отправка {{ deliveryType }} от {{ dateApplied }}</v-toolbar-title>
      <v-spacer />
      <custom-button icon color="white" @click="$emit('close')">
        <v-icon>close</v-icon>
      </custom-button>
    </v-toolbar>
    <div class="dialog__content">
      <table class="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Дата заявки</th>
            <th>Клиент</th>
            <th>Операция</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in info.acts" :key="i" @click.stop="currentItem = item">
            <td>{{ item.id }}</td>
            <td>{{ getDateOrder(item) }}</td>
            <td>{{ item.name }}</td>
            <td>{{ `Первичные документы за: ${getPeriodDate(item)}` }}</td>
            <td :class="item.status">{{ getStatus(item) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="dialog__action">
      <custom-button color="primary" large @click="$emit('close')">
        Закрыть
      </custom-button>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

const deliveryMap = {
  mail: 'по почте',
  diadoc: 'через ЭДО Диадок'
};

export default {
  name: 'TabDocsModal',
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    dateApplied() {
      return format(new Date(this.info.date_applied), 'dd.MM.yyyy');
    },
    deliveryType() {
      return deliveryMap[this.info.type_delivery];
    }
  },
  methods: {
    getStatus({ status }) {
      if (status === 'applied') return 'Принято в работу';
      if (status === 'done') return 'Выполнено';
      return 'Ошибка отправки';
    },
    getPeriodDate({ period }) {
      return format(new Date(period), 'LLLL yyyy', { locale: ru }).replace(/^./, str =>
        str.toUpperCase()
      );
    },
    getDateOrder({ date_order }) {
      return format(new Date(date_order), 'dd.MM.yyyy в HH:mm');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';
@import '~@/assets/scss/mixins';
@include table(30%, $hover: false);
.table {
  th:first-child,
  td:first-child {
    width: 4%;
  }
  th:last-child,
  td:last-child {
    width: 15%;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 12%;
  }
}
.dialog {
  &__content {
    max-height: 545px;
    overflow-y: auto;
  }
}

.applied {
  color: $color-main;
}
.done {
  color: $color-success;
}
.not_auth {
  color: $color-error;
  background: transparent !important;
  border-bottom: 1px solid #cccccc !important;
}
</style>
