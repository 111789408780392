<template>
  <div v-if="isLoading" class="text-xs-center pt-3">
    <v-progress-circular :size="40" color="primary" indeterminate />
  </div>
  <div v-else class="carrier">
    <information-tabs :tabs-data="tabsData" :set-component="setComponent" :active="component">
      <rating-count slot="rating" :rating="tabsData.rating" />
    </information-tabs>
    <keep-alive>
      <component :is="component" disable-todo-list-height :order="order" v-bind="advancedData" />
    </keep-alive>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Safety from 'common/components/Orders/Safety.vue';
import InformationTabs from './OrderView/InformationTabs.vue';
import Info from './OrderView/Carrier/Info.vue';
import Rating from 'common/components/Ratings.vue';
import RatingCount from 'common/components/RatingCount';

export default {
  name: 'OrderViewShipper',
  components: {
    InformationTabs,
    Info,
    Safety,
    Rating,
    RatingCount
  },
  data: () => ({
    component: 'Info',
    isLoading: false,
    tabsData: {},
    advancedData: {}
  }),
  computed: {
    ...mapState(['defaultURL']),
    ...mapState('login', ['cid']),
    ...mapState('orders', {
      order: state => state.orderInfo.info
    })
  },
  async mounted() {
    this.isLoading = true;
    try {
      const { agent, photos } = await this.createController({
        topic: 'profile/organization/get_user_info',
        json: { cid: this.order.ccontact_user_id }
      });
      this.tabsData = { ...agent, ...this.order };
      this.advancedData = { agent, photos, cshipperId: this.order.cshipper_id };
    } catch (error) {
      console.error('Ошибка получения данных для табов');
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('common', ['createController']),
    setComponent(name) {
      this.component = name;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .rating {
  padding: 0 12px !important;
}

/deep/ .ts-icons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
</style>
