<template>
  <Tabs class="tabs" :tabs="tabsList" :active="isShipper ? 'ListOrders' : 'FindOffer'" />
</template>

<script>
import { mapGetters } from 'vuex';
import { ADM_SEARCH_ORDER_LIST } from 'common/api/SearchOrderApi';

import Tabs from '@/components/Layout/Tabs';
import OrdersShipper from './OrdersTab/OrdersShipper';
import OrdersCarrier from './OrdersTab/OrdersCarrier';
import FindOrders from './FindOrdersTab/FindOrdersTab';
import Offers from './OffersTab/OffersTab';
import Addresses from './AddressesTab/AddressesTab';

export default {
  name: 'OrdersTab',
  components: {
    Tabs
  },
  computed: {
    ...mapGetters('clients', ['isShipper'])
  },
  created() {
    this.tabsList = this.isShipper
      ? [
          {
            name: 'ListOrders',
            title: 'Заказы',
            topic: 'order/list',
            component: OrdersShipper
          },
          {
            name: 'Addresses',
            title: 'Адреса',
            topic: 'admin/my_addresses/list',
            component: Addresses
          }
        ]
      : [
          {
            name: 'FindOffer',
            title: 'Поиск заказа',
            topic: ADM_SEARCH_ORDER_LIST,
            component: FindOrders
          },
          {
            name: 'Offers',
            title: 'Предложения',
            topic: 'admin/order/offers/my',
            component: Offers
          },
          {
            name: 'ListOrders',
            title: 'Заказы',
            topic: 'order/list',
            component: OrdersCarrier
          }
        ];
  }
};
</script>

<style scoped>
/deep/ .tabs__content {
  height: calc(100% - 75px);
}
</style>
