import { render, staticRenderFns } from "./СarryingIcon.vue?vue&type=template&id=1f2ef26c&scoped=true&functional=true&"
var script = {}
import style0 from "./СarryingIcon.vue?vue&type=style&index=0&id=1f2ef26c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../cargo-newadmin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "1f2ef26c",
  null
  
)

export default component.exports