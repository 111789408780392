<template>
  <viewer ref="viewer" class="viewer" :options="options" :images="images" @inited="inited">
    <img
      v-for="(img, index) in images"
      :key="index"
      class="image"
      :src="img"
      :data-source="img"
      :alt="img.split('/cars/').pop()"
    />
  </viewer>
</template>
<script>
export default {
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      options: {
        inline: true,
        button: false,
        navbar: true,
        title: false,
        toolbar: true,
        tooltip: false,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: false,
        transition: false,
        fullscreen: false,
        keyboard: false,
        url: 'data-source'
      }
    };
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    },
    view() {
      if (this.form.view >= 0 && this.form.view < this.images.length) {
        this.$viewer.view(this.form.view);
      }
    },
    zoom(value) {
      this.$viewer.zoom(value || this.form.zoom);
    },
    rotate(value) {
      this.$viewer.rotate(value || this.form.rotate);
    },
    scaleX(value) {
      if (value) {
        this.$viewer.scaleX(value);
      } else {
        this.form.scaleX = -this.form.scaleX;
        this.$viewer.scaleX(this.form.scaleX);
      }
    },
    scaleY(value) {
      if (value) {
        this.$viewer.scaleY(value);
      } else {
        this.form.scaleY = -this.form.scaleY;
        this.$viewer.scaleY(this.form.scaleY);
      }
    },
    move(x, y) {
      this.$viewer.move(x, y);
    },
    prev() {
      this.$viewer.prev();
    },
    next() {
      this.$viewer.next();
    },
    play() {
      this.$viewer.play();
    },
    stop() {
      this.$viewer.stop();
    },
    show() {
      this.$viewer.show();
    },
    tooltip() {
      this.$viewer.tooltip();
    },
    reset() {
      this.$viewer.reset();
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/scss">
.v-window {
  height: calc(100% - 48px);

  .v-window__container {
    height: 100%;

    .v-window-item {
      height: 100%;

      .viewer-container {
        width: auto;
        height: auto;
        background-color: #2b2b2b;

        .viewer-play.viewer-large {
          display: none;
        }
      }
    }
  }
}
.viewer-wrapper {
  position: relative;
  background: #333;
  height: 100%;
}
.methods {
  margin-bottom: 1em;
  flex-wrap: wrap;
  & > * {
    margin-right: 0.75rem;
  }
}
.options-panel {
  .panel-block {
    padding: 0;
    .checkbox {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0.5em 0.75em;
    }
  }
}
.viewer {
  height: 100%;
  > .image {
    display: none;
  }
  .images {
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
    .image-wrapper {
      display: inline-block;
      width: calc(33% - 20px);
      margin: 5px 5px 0 5px;
      .image {
        width: 100%;
        cursor: pointer;
        display: inline-block;
      }
    }
  }
}
</style>
