<template>
  <div v-if="isLoading" class="preloader preloader--center">
    <v-progress-circular :size="40" color="primary" indeterminate />
  </div>
  <div v-else class="dispute">
    <template v-if="!replicas.length">
      <h2 class="ui-kit__heading mb-3">Открытие диспута</h2>
      <dispute-message-form @submit="createMessage" />
    </template>
    <alert v-else-if="feedback.status === 'finalized'">
      <span>Завершен {{ closeDate }} {{ whoWin }}</span>
    </alert>
    <alert v-else>
      <span>Открыт: {{ startDate }}</span>
      <span class="ml-4">Завершится: {{ endDate }} (осталось {{ remainedTime }})</span>
      <span class="ml-4">Ожидание {{ whoseTurnString }} до {{ turnDate }}</span>
    </alert>
    <div v-for="(replica, i) in replicas" :key="i" class="replica">
      <div class="replica-header">
        <b>
          {{ replica.name }}
          <span>{{ typeUser(replica.user_category) }}</span>
        </b>
        <span>{{ replica.created }}</span>
      </div>
      <div class="replica-text">
        {{ replica.text }}
      </div>
      <div class="replica-images">
        <img
          v-for="(image, index) in replica.images"
          :key="index"
          :src="image.tinySrc"
          alt=""
          class="replica-image"
          @click="openDialog(replica.images, index)"
        />
      </div>
    </div>
    <div v-if="!isOperator && isYourTurn && replicas.length" class="replica">
      <div class="replica-header">
        <b>Вы отвечаете</b>
        <span class="color-red">Требуется ответить до {{ turnDate }}</span>
      </div>
      <dispute-message-form button-text="Отправить" @submit="createMessage" />
    </div>
    <dispute-operator-form
      v-if="isOperator && feedback.whose_turn_category === 'operator' && !isOpenedTab"
      :dispute="feedback"
      @success="getDisputes"
    />
    <swiper-dialog
      v-if="isDialogOpen"
      :images="swiperDialogImages"
      :slide-index="slideIndex"
      @close="isDialogOpen = false"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import lightFormat from 'date-fns/lightFormat';
import Alert from 'common/components/Alert.vue';
import { swiperDialog } from 'common/components/swiper';
import DisputeMessageForm from './OrderView/Dispute/DisputeMessageForm.vue';
import DisputeOperatorForm from './OrderView/Dispute/DisputeOperatorForm.vue';
import { getRemainedTime } from 'common/helpers/dateHelper';

let interval;

const getTurnSide = isShipper => ({
  shipper: isShipper ? 'вашего ответа' : 'ответа грузовладельца',
  carrier: isShipper ? 'ответа перевозчика' : 'вашего ответа',
  operator: 'ответа оператора'
});

const getTypeWin = isShipper => ({
  shipper: isShipper ? 'в вашу пользу' : 'в пользу контрагента',
  carrier: isShipper ? 'в пользу контрагента' : 'в вашу пользу',
  neutral: 'нейтрально'
});

const typesUser = {
  shipper: '(ГВ)',
  carrier: '(ГП)'
};
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.state.orders.orderInfo.info.status === 'canceled')
        vm.setOrderInfoField({ field: 'info', value: { ...vm.info, status: 'on_dispute' } });
    });
  },
  beforeRouteLeave(to, from, next) {
    if (!this.replicas.length)
      this.setOrderInfoField({ field: 'info', value: { ...this.info, status: 'canceled' } });
    next();
  },
  name: 'OrderViewDispute',
  components: {
    DisputeMessageForm,
    DisputeOperatorForm,
    Alert,
    swiperDialog
  },
  props: {
    isOperator: Boolean,
    isOpenedTab: Boolean
  },
  data: () => ({
    replicas: [],
    feedback: {},
    isLoading: false,
    startDate: '',
    endDate: '',
    closeDate: '',
    turnDate: '',
    isDialogOpen: false,
    swiperDialogImages: [],
    remainedTime: null,
    slideIndex: 0
  }),
  computed: {
    ...mapState({
      cid: state => state.login.cid,
      defaultURL: state => state.defaultURL,
      info: state => state.orders.orderInfo.info,
      category: state => state.login.category
    }),
    ...mapGetters('login', ['isShipper']),
    ...mapGetters('errors', ['getError']),
    alertMessage() {
      return 123;
    },
    isYourTurn() {
      return this.feedback.whose_turn_category === (this.isShipper ? 'shipper' : 'carrier');
    },
    whoseTurnString() {
      return getTurnSide(this.isShipper)[this.feedback.whose_turn_category];
    },
    whoWin() {
      return getTypeWin(this.isShipper)[this.feedback.who_won];
    }
  },

  async created() {
    await this.getDisputes();
    this.remainedTime = this.getTime();
    interval = setInterval(() => {
      this.remainedTime = this.getTime();
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(interval);
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions(['addSnackbar']),
    ...mapMutations('orders', ['setOrderInfoField']),
    typeUser(userCategory) {
      return typesUser[userCategory] || '';
    },
    getTime() {
      const { days, hours, minutes } = getRemainedTime(
        new Date(this.feedback.dispute_end),
        Date.now()
      );
      return [days, hours, minutes].filter(_ => _).join(' ');
    },
    async createMessage(formData) {
      const topic = 'disputes/do_user_replica';
      try {
        const json = {
          ...formData,
          order_cid: this.info.cid
        };
        await this.createController({ topic, json });
        this.getDisputes();
      } catch (err) {
        this.addSnackbar({ message: this.getError({ error: err.status.reason }), type: 'error' });
      }
    },
    async getDisputes() {
      this.isLoading = true;
      const topic = 'disputes/open';
      try {
        const json = { order_cid: this.info.cid };
        const { feedback } = await this.createController({ topic, json });
        const format = 'dd.MM.yyyy в HH:mm';
        const getCid = replica => {
          if (this.category === replica.user_category) return this.cid;
          return `${this.cid}/${replica.cparent_id}`;
        };
        this.feedback = feedback;
        this.replicas = feedback.replicas.map(_ => ({
          ..._,
          created: lightFormat(new Date(_.date), format),
          images: _.files.map(item => ({
            tinySrc: `${this.defaultURL}/files/${getCid(_)}/${item.tiny}`,
            src: `${this.defaultURL}/files/${getCid(_)}/${item.file}`
          }))
        }));
        this.startDate = lightFormat(new Date(feedback.dispute_open), format);
        this.endDate = lightFormat(new Date(feedback.dispute_end), format);
        this.closeDate = lightFormat(new Date(feedback.dispute_close), format);
        this.turnDate = lightFormat(new Date(feedback.dispute_turn_end), format);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    openDialog(images, index) {
      this.swiperDialogImages = images;
      this.slideIndex = index;
      this.isDialogOpen = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.replica {
  box-shadow: $shadow-around;
  padding: 8px 12px;
  margin: 16px -12px 0;
  @media screen and (max-width: breakpoint(md)) {
    padding: 8px 12px;
    margin: 16px 0;
  }

  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-text {
    margin: 12px 0;
  }

  .color-red {
    color: $color-danger;
  }

  &-image {
    height: 40px;
    margin-right: 12px;
    cursor: pointer;
  }
}
</style>
