<template>
  <div v-if="isLoading" class="text-xs-center pt-3">
    <v-progress-circular :size="40" color="primary" indeterminate />
  </div>
  <v-layout v-else wrap class="safety">
    <v-flex xs12>
      <safety :organization-data="organizationData" :semaphores="semaphores">
        <custom-button slot="button" round large class="update-btn" @click="showDialog = true">
          Обновить
          <span>Последнее обновление: {{ organizationData.safetyDate }}</span>
        </custom-button>
      </safety>
    </v-flex>
    <dialog-window :show="showDialog" hide-footer @close="showDialog = false">
      <p>Вы заказали обновление оценки надежности контрагента.</p>
      <p class="cost">
        Стоимость услуги:
        <span>{{ priceReliability.value }} {{ priceReliability.measure }}.</span>
      </p>
      <div>
        <custom-button
          :loading="isUpdateLoading"
          class="ml-0"
          round
          large
          color="primary"
          @click="updateSafety"
        >
          Продолжить
        </custom-button>
        <custom-button round flat @click="showDialog = false">
          Отмена
        </custom-button>
      </div>
    </dialog-window>
  </v-layout>
</template>

<script>
import axios from 'common/helpers/httpService';
import { mapActions } from 'vuex';
import lightFormat from 'date-fns/lightFormat';
import Safety from 'common/components/Safety';
import DialogWindow from 'common/components/Dialog';

export default {
  name: 'OfferItemSafety',
  components: { Safety, DialogWindow },
  props: {
    offer: {
      type: Object,
      default: () => ({})
    },
    order: {
      type: Object,
      default: () => ({})
    },
    cshipperId: String
  },
  data: () => ({
    organizationData: {},
    semaphores: {},
    priceReliability: 0,
    showDialog: false,
    isLoading: false,
    isUpdateLoading: false
  }),
  computed: {
    cid() {
      return this.cshipperId || this.offer.ccarrier_id || this.order.ccarrier_id;
    }
  },
  async mounted() {
    this.isLoading = true;
    try {
      this.responseProcessing(await this.getSafetyInfo(this.cid));
      this.isLoading = false;
      const {
        prices: { price_reliability: priceReliability }
      } = await axios.get('/tariffs');
      this.priceReliability = priceReliability;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('orders', ['getSafetyInfo']),
    ...mapActions('safety', ['updateData']),
    ...mapActions(['addSnackbar']),
    async updateSafety() {
      this.isUpdateLoading = true;
      try {
        const { semaphores, agent } = await this.updateData(this.cid);
        this.responseProcessing({ semaphores, ...agent });
        this.addSnackbar({ message: 'Данные обновлены', type: 'success' });
      } catch (error) {
        // TODO Make info window with error
        this.addSnackbar({ message: 'Ошибка обновления данных', type: 'error' });
      } finally {
        this.showDialog = false;
        this.isUpdateLoading = false;
      }
    },
    responseProcessing({ semaphores, safety, safety_date: safetyDate }) {
      this.organizationData = {
        safety,
        safetyDate: lightFormat(new Date(safetyDate), 'dd.MM.yyyy')
      };
      this.semaphores = {
        red: semaphores.semaphore_red,
        green: semaphores.semaphore_green,
        yellow: semaphores.semaphore_yellow,
        recomendations: semaphores.semaphore_recommendations
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';
.container-svetofor {
  padding: 0;
}
.update-btn {
  margin: 0;

  @media screen and (max-width: breakpoint(sm)) {
    margin-top: 12px;
  }

  .btn__content {
    display: flex;
    flex-direction: column;
  }
  span {
    display: block;
    color: #fff;
    font-size: 10px;
    line-height: 1;
  }

  /deep/.v-btn__content {
    display: flex;
    flex-direction: column !important;
  }
}

.cost {
  font-size: 16px;
  font-weight: 500;

  span {
    color: $color-success;
  }
}
</style>
