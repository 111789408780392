<template>
  <div>
    <v-layout wrap>
      <v-flex xs12 sm4>
        <custom-input label="Тип кузова" :value="typeCarcass" disabled />
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex v-for="item in listCharacteristics" :key="item.id" xs12 sm4>
        <custom-input
          :label="item.carrier.name + ', ' + item.unit_measure + '.'"
          :value="data.properties[item.id]"
          disabled
        />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <custom-input label="Опции" :value="getlist('options')" disabled />
      </v-flex>
      <v-flex xs12>
        <custom-input label="Способы погрузки/разгрузки" :value="getlist('lifting')" disabled />
      </v-flex>
      <v-flex v-if="tsPhotos.length" xs12 class="mt-3">
        <swiper
          :images="tsPhotos"
          :limit="3"
          height="200px"
          max-width="300px"
          url-field="smallSrc"
          @image-click="open"
        />
      </v-flex>
    </v-layout>
    <swiper-dialog
      v-if="isShow"
      :images="tsPhotos"
      :slide-index="slideIndex"
      @close="isShow = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { swiperDialog, swiper } from 'common/components/swiper';

export default {
  name: 'TsChr',
  components: {
    swiper,
    swiperDialog
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    isShow: false,
    slideIndex: 0
  }),
  computed: {
    ...mapGetters('orders', ['getSchemeField']),
    listCharacteristics() {
      return this.getSchemeField('characteristics').filter(item => this.data.properties[item.id]);
    },
    typeCarcass() {
      return this.getSchemeField('carcasses').find(item => item.id === this.data.vehicle.carcass)
        ?.name;
    },
    tsPhotos() {
      return this.data.photos.filter(_ => _.type === 'ts');
    }
  },
  methods: {
    getlist(field) {
      return this.getSchemeField(field)
        .filter(el => this.data.properties[el.id])
        .map(el => el.name)
        .join(', ');
    },
    open(index) {
      this.isShow = true;
      this.slideIndex = index;
    }
  }
};
</script>
