<template>
  <div>
    <transport :offer="{ ctransport_id: order.ctransport_id }">
      <template slot-scope="{ item }">
        <v-flex xs12 md2 class="mt-3">
          <custom-input :value="item.brand_and_model" label="Марка и модель" disabled />
        </v-flex>
        <v-flex xs12 md2>
          <custom-input :value="item.registration_plate" label="Регистрационный знак" disabled />
        </v-flex>
        <v-flex xs12 md2>
          <custom-input :value="item.release_year.substring(0, 4)" label="Год выпуска" disabled />
        </v-flex>
        <v-flex xs12 md6>
          <img
            v-for="(image, i) in getImages(item, 'sts')"
            :key="image.cid"
            :src="image.src"
            class="ts-image"
            alt=""
            @click="openDialog(item, 'sts', i)"
          />
        </v-flex>
        <v-flex xs12 md4>
          <custom-input :value="getTSType(item)" label="Тип права пользования" disabled />
        </v-flex>
        <v-flex v-if="item.type_right_use !== 'proprietary'" xs12 md8>
          <img
            v-for="(image, i) in getImages(item, 'tsdoc')"
            :key="image.cid"
            :src="image.src"
            class="ts-image"
            alt=""
            @click="openDialog(item, 'tsdoc', i)"
          />
        </v-flex>
      </template>
    </transport>
    <swiper-dialog
      v-if="isDialogOpen"
      :images="swiperDialogImages"
      :slide-index="slideIndex"
      @close="isDialogOpen = false"
    />
  </div>
</template>

<script>
import lightFormat from 'date-fns/lightFormat';
import { getUTCDate } from 'common/helpers/dateHelper';
import { DATE_INFINITE } from 'common/helpers/dateEnums';
import { swiperDialog } from 'common/components/swiper';
import Transport from 'common/components/Orders/TS';

const useTypes = {
  leasing: 'Лизинг',
  proprietary: 'Право собственности',
  'lease-contract': 'Договор аренды'
};

export default {
  name: 'TSWrapper',
  components: { Transport, swiperDialog },
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    isDialogOpen: false,
    swiperDialogImages: [],
    slideIndex: 0
  }),
  methods: {
    getTSType(item) {
      const type = useTypes[item.type_right_use];
      const date =
        item.valid_until !== DATE_INFINITE
          ? `до ${lightFormat(getUTCDate(item.valid_until), 'dd.MM.yyyy')}`
          : '';
      return `${type} ${date}`;
    },
    getImages(item, type) {
      return item.photos.filter(_ => _.type === type);
    },
    openDialog(item, type, index) {
      this.swiperDialogImages = this.getImages(item, type);
      this.slideIndex = index;
      this.isDialogOpen = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.ts-image {
  width: 40px;
  height: 40px;
  cursor: pointer;
  object-fit: cover;
}
</style>
