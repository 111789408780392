<template>
  <div v-if="!loading('admin/endusers/contractor/profile/loadregions/list')" class="profile_region">
    <div class="profile_region__title">
      Предпочтительные регионы загрузки
    </div>
    <div v-for="(item, index) in region_list" :key="index" class="profile_region__row">
      {{ item.name }}
    </div>
    <div v-if="region_list.length === 0" class="settings__row">
      Нет регионов для отображения
    </div>
  </div>
  <div v-else class="loading">
    <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'ProfileRegion',
  data() {
    return {};
  },
  computed: {
    ...mapState('clientsProfile', ['region_list']),
    ...mapGetters('common', ['loading']),
    enduser_id() {
      return parseInt(this.$route.params.enduser);
    }
  },
  mounted() {
    this.getRegionList(this.enduser_id);
  },
  methods: {
    ...mapActions('clientsProfile', ['getRegionList'])
  }
};
</script>

<style lang="scss">
.profile_region {
  height: 100%;
  overflow: auto;
  .profile_region__title {
    font-size: 25px;
    margin: 25px;
  }
  .profile_region__row {
    margin: 25px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}
</style>
