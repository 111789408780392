<template>
  <div class="documents">
    <div v-if="isLoading" class="text-xs-center pt-3">
      <v-progress-circular :size="40" color="primary" indeterminate />
    </div>

    <template v-else>
      <ShipperDocuments
        v-if="isShipper"
        :shipper="shipper"
        :is-operator="isOperator"
        :is-pin-view="isPinView"
      />

      <CarrierDocuments v-else :is-pin-view="isPinView" :shipper="shipper" :carrier="carrier" />

      <!-- Показываем блок если это ГП или если это ГВ у которого заказчик ГП ЮЛ -->
      <DocumentsList v-if="isShipper || !isShipperFl" :shipper="shipper" :carrier="carrier" />
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import DocumentsList from 'common/views/OrderView/OrderDocuments/DocumentsList';
import ShipperDocuments from 'common/views/OrderView/OrderDocuments/ShipperDocuments/index';
import CarrierDocuments from 'common/views/OrderView/OrderDocuments/CarrierDocuments/index';

export default {
  name: 'OfferViewDocuments',
  components: {
    DocumentsList,
    ShipperDocuments,
    CarrierDocuments
  },
  props: {
    isOperator: Boolean,
    isPinView: Boolean
  },
  data: () => ({
    isLoading: false
  }),
  computed: {
    ...mapGetters('login', ['isShipper']),
    ...mapState('orders', {
      info: state => state.orderInfo.info,
      shipper: state => state.orderInfo.shipper,
      carrier: state => state.orderInfo.carrier
    }),

    isShipperFl() {
      return this.shipper.yur_type === 'FL';
    }
  },
  async created() {
    this.isLoading = true;
    try {
      await Promise.all([
        this.getInfo('shipper'),
        this.getInfo('carrier'),
        this.getAcceptedTruckerInfo()
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('orders', [, 'getAcceptedTruckerInfo', 'getInfo'])
  }
};
</script>
