import lightFormat from 'date-fns/lightFormat';

const getOrderStatus = (date, isShipper) => ({
  editing: 'Не размещен',
  waiting_for_offer: `Прием предложений до ${date}`,
  accepted: `Подтвержден ${date}`,
  in_process: `Подтвержден ${date}`,
  canceled_by_carrier: `Отменен ${!isShipper ? 'вами' : 'перевозчиком'}  ${date}`,
  canceled_by_shipper: `Отменен ${isShipper ? 'вами' : 'грузовладельцем'} ${date}`,
  canceled_by_service: `Отменен сервисом ${date}`,
  done: `Завершен ${date}`,
  on_dispute: 'Отменен',
  dispute_is_done: 'Отменен'
});

export default (item, isShipper) => {
  const {
    accept_datetime: acceptDate,
    status_date: statusDate,
    order_status: orderStatus,
    offers_deadline: deadlineDate,
    cancel_datetime: cancelDate,
    gps_until_date: doneDate,
    status,
    who_canceled: whoCanceled
  } = item;
  const statusName = orderStatus || status;
  const isStatusCanceled = ['canceled', 'on_dispute', 'dispute_is_done'].includes(statusName);
  const fullStatus = isStatusCanceled ? `canceled_by_${whoCanceled}` : statusName;
  let date;
  if (isStatusCanceled) {
    date = new Date(cancelDate);
  } else if (statusName === 'accepted' || statusName === 'in_process') {
    date = acceptDate ? new Date(acceptDate) : '';
  }
  // waiting for backend
  else if (statusName === 'done') {
    date = new Date(doneDate);
  } else if (statusDate || deadlineDate) {
    date = new Date(statusDate || deadlineDate);
  }
  const dateString = date ? lightFormat(date, 'dd.MM.yyyy в HH:mm') : '';
  return getOrderStatus(dateString, isShipper)[fullStatus] ?? '';
};
