<template>
  <v-navigation-drawer class="menu" clipped="clipped">
    <v-list class="menu__list" dense="dense">
      <v-list-tile
        v-for="item in sections"
        :key="item.title"
        :to="item.path"
        class="menu__item"
        :class="{ disabled: item.disabled }"
      >
        <v-list-tile-action>
          <v-icon>{{ item.path.icon }}</v-icon>
        </v-list-tile-action>
        <v-list-tile-content v-if="isShowMenu">
          <v-list-tile-title>{{ item.title }}</v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action v-if="item.path.name === 'Disputs' && menuCounts[item.path.name]">
          <div class="badge badge__red">
            {{ counts.Active_disputs }}
          </div>
        </v-list-tile-action>
        <v-list-tile-action v-if="isShowMenu && item.path.badge && menuCounts[item.path.name]">
          <div class="badge">
            {{ menuCounts[item.path.name] }}
          </div>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
    <div v-if="isShowMenu" class="menu__footer">
      <slot />
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    isShowMenu: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      drawer: null,
      counts: {}
    };
  },
  computed: {
    ...mapState({
      sections: state => state.login.sections
    }),
    menuCounts() {
      return this.counts;
    }
  },
  mounted() {
    this.getMenuCounts();
  },
  methods: {
    ...mapActions('login', ['getCounts']),
    async getMenuCounts() {
      this.counts = await this.getCounts();
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';
.menu {
  .menu__list {
    height: calc(100% - 40px);
    overflow: hidden;

    .menu__item {
      height: 48px;
      &.disabled {
        color: lightgray;
      }
    }
  }
  .menu__footer span {
    padding: 0 10px;
  }
}
</style>
