<template>
  <div class="tabs">
    <div class="tabs__list">
      <v-tabs v-model="currentTab" show-arrows>
        <v-tabs-slider color="primary" />
        <div v-for="(tab, index) in tabs" :key="index" class="tabs__list__tab">
          <v-tab :key="tab.name" :disabled="tab.disabled" @click="change(index, tab.disabled)">
            {{ tab.title }}
          </v-tab>
        </div>
      </v-tabs>
    </div>
    <div class="tabs__content">
      <div v-if="isLoading" class="text-center">
        <v-progress-circular indeterminate="indeterminate" color="primary" />
      </div>
      <component
        :is="current.component"
        v-if="current && current.component && !current.disabled"
        v-bind="current.options || {}"
        v-on="listener"
      />
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  model: {
    prop: 'active',
    event: 'change'
  },
  props: {
    active: String,
    tabs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentTab: this.tabs.indexOf(this.tabs.find(_ => _.name === this.active)) || 0
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    current() {
      return this.tabs[this.currentTab];
    },
    listener() {
      return this.current.event ? { [this.current.event]: this.sendEvent } : {};
    },
    isLoading() {
      return this.current?.topic && this.loading(this.current.topic);
    }
  },
  watch: {
    tabs() {
      this.currentTab = this.tabs.indexOf(this.tabs.find(_ => _.name === this.active)) || 0;
    }
  },
  methods: {
    change(index, disabled) {
      if (disabled) return;
      this.currentTab = index;
      this.$emit('change', this.tabs[this.currentTab].name);
    },
    sendEvent() {
      this.$emit(this.current.event);
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';
.tabs {
  height: 100%;
  .tabs__list {
    height: 60px;

    .tabs__list__tab {
      height: 100%;
      .v-tabs__div {
        text-transform: none;
      }

      .tabs__list__tab__disabled {
        color: $gray-light;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .v-tabs__bar {
      background: transparent;
      border-bottom: 1px solid $color-main;
    }
  }
  .tabs__content {
    height: calc(100% - 60px);
    padding: 0 10px;
    overflow: auto;
    position: relative;
  }
}
</style>
