<template>
  <div class="container">
    <v-flex xs12>
      <h2>
        <b>Водительское удостоверение</b>
      </h2>
    </v-flex>
    <v-flex xs5>
      <custom-input
        :value="licenseSeries"
        label="Серия"
        :rules="[
          v => !!v || 'Поле обязательно для ввода',
          v => v.length === 4 || 'Серия должна быть из 4 символов'
        ]"
        @input="
          $emit('changed', {
            field: 'licenseSeries',
            value: $event.toUpperCase()
          })
        "
      />
    </v-flex>
    <v-flex xs5>
      <custom-input
        :value="licenseNumber"
        label="Номер"
        :rules="[
          v => !!v || 'Поле обязательно для ввода',
          v => v.length === 6 || 'Номер должен быть из 6 символов'
        ]"
        @input="
          $emit('changed', {
            field: 'licenseNumber',
            value: $event
          })
        "
      />
    </v-flex>
    <v-flex xs2 d-flex align-center>
      <custom-button
        v-clipboard:copy="`${licenseSeries} ${licenseNumber}`"
        v-clipboard:success="e => $emit('copy', e)"
        v-clipboard:error="e => $emit('copy-error', e)"
        icon
        color="primary"
      >
        <v-icon>file_copy</v-icon>
      </custom-button>
    </v-flex>
    <v-flex xs6>
      <datepicker
        :value="licenseDate"
        label="Дата выдачи"
        :max-date="maxLicenseDate"
        :rules="[v => !!v || 'Поле обязательно для ввода']"
        @input="
          $emit('changed', {
            field: 'licenseDate',
            value: $event
          })
        "
      >
        <template slot="icon">
          <custom-button
            v-clipboard:copy="copyDate"
            v-clipboard:success="onSuccess"
            v-clipboard:error="e => $emit('copy-error', e)"
            icon
            color="primary"
            @click.stop="() => false"
          >
            <v-icon>file_copy</v-icon>
          </custom-button>
        </template>
      </datepicker>
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        :value="checkLicenseGibdd"
        :false-value="false"
        :true-value="true"
        :rules="[v => !!v || 'Поле обязательно для ввода']"
        @change="
          $emit('changed', {
            field: 'checkLicenseGibdd',
            value: $event
          })
        "
      >
        <template slot="prepend">
          <a href="https://гибдд.рф/check/driver" target="_blank">
            Проверка на сайте ГИБДД
          </a>
        </template>
      </v-checkbox>
    </v-flex>
  </div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'common/components/form/Datepicker.vue';

export default {
  name: 'License',
  components: {
    Datepicker
  },
  props: {
    licenseSeries: String,
    licenseNumber: [String, Number],
    licenseDate: String,
    checkLicenseGibdd: Boolean
  },
  computed: {
    maxLicenseDate() {
      return moment(new Date()).format('YYYY-MM-DD');
    },
    copyDate() {
      return moment(new Date(this.licenseDate)).format('DD.MM.YY');
    }
  },
  methods: {
    onSuccess(e) {
      e.text = this.licenseDate;
      this.$emit('copy', e);
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
</style>
