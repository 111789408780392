<template>
  <BodyWorksContentAbstraction
    :list="characteristics"
    :current-id="currentItem.id"
    :is-add-form="isAddForm"
    is-relative-table
    @add="addItem"
    @edit="editItem"
    @toggle-order="toggleOrder"
  >
    <template v-slot:head>
      <th>Название характеристики для ГВ</th>
      <th>Название характеристики для ГП</th>
      <th>Точность</th>
      <th>Ед. изм.</th>
      <th>Тип</th>
      <th />
    </template>
    <template v-slot:body="{ item }">
      <td>{{ item.shipper.name }}</td>
      <td>{{ item.carrier.name }}</td>
      <td>{{ item.quantity }}</td>
      <td>{{ item.unit_measure }}</td>
      <td>{{ item.type }}</td>
    </template>
    <template v-slot:form>
      <BodyWorksChrForm
        :is-add-form="isAddForm"
        :is-edit-form="isEditForm"
        :info="currentItem"
        @close="closeForm"
        @update="getScheme"
      />
    </template>
  </BodyWorksContentAbstraction>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BodyWorksContentAbstraction from './BodyWorksContentAbstraction';
import BodyWorksChrForm from './forms/BodyWorksChrForm';

export default {
  name: 'BodyWorksChr',
  components: {
    BodyWorksContentAbstraction,
    BodyWorksChrForm
  },
  data() {
    return {
      isAddForm: false,
      isEditForm: false,
      currentItem: {}
    };
  },
  computed: {
    ...mapState('carcasses', ['characteristics', 'currentVersionId'])
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions('carcasses', ['getScheme']),
    async toggleOrder({ gid, direction }) {
      const topic = 'admin/administration/carcasses/properties/pull';
      const json = { gid, direction, id: this.currentVersionId };
      try {
        await this.createController({ topic, json });
        this.getScheme();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    closeForm() {
      this.isAddForm = false;
      this.isEditForm = false;
      this.currentItem = {};
    },
    addItem() {
      this.currentItem = {};
      this.isAddForm = true;
    },
    editItem(item) {
      this.isAddForm = false;
      this.isEditForm = true;
      this.currentItem = item;
    }
  }
};
</script>
