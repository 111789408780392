<template>
  <div v-if="isLoading" class="preloader preloader--center">
    <v-progress-circular :size="40" color="primary" indeterminate />
  </div>

  <div v-else-if="info.status === 'done'">
    <h2 class="mt-0 mb-4 mobile-pl">Мой отзыв</h2>
    <FeedbackBlock v-if="myFeedback.cid" :feedback="myFeedback" />
    <template v-else>
      <content-block class="feedback">
        <v-form v-model="isValid">
          <div class="feedback__header">
            <icon
              :class="['feedback__icon', { 'positive-color': evaluation === 'positive' }]"
              name="positiveEmoticon"
              @click="evaluation = 'positive'"
            />
            <icon
              :class="['feedback__icon', { 'neutral-color': evaluation === 'neutral' }]"
              name="neutralEmoticon"
              @click="evaluation = 'neutral'"
            />
            <icon
              :class="['feedback__icon', { 'negative-color': evaluation === 'negative' }]"
              name="negativeEmoticon"
              @click="evaluation = 'negative'"
            />
            <span class="feedback__label">
              Оцените {{ isShipper ? 'первозчика' : 'грузовладельца' }}
            </span>
          </div>
          <custom-input
            v-model="text"
            :rules="[v => !!v || 'Текст отзыва является обязательным']"
            type="textarea"
            label="Введите текст отзыва"
            placeholder=" "
            class="feedback__input"
          />
        </v-form>
      </content-block>
      <custom-button
        :disabled="!isValid || !evaluation"
        color="primary"
        class="ml-0"
        round
        large
        @click.stop="submitFeedback"
      >
        Сохранить
      </custom-button>
    </template>
    <template v-if="contragentFeedback.cid">
      <h2 class="mt-3 mb-4 mobile-pl">Отзыв обо мне</h2>
      <FeedbackBlock :feedback="contragentFeedback" />
    </template>
  </div>
  <div v-else>
    <template v-if="myFeedback.cid">
      <h2 class="mt-0 mb-4 mobile-pl">Мой отзыв</h2>
      <FeedbackBlock :feedback="myFeedback" />
      <template v-if="myFeedback.status !== 'finalized'">
        <alert
          v-if="!!myFeedback.replicas.length"
          :message="disputeMessage"
          type="neutral"
          clickable
          @click.native="$router.push(disputeRoute)"
        />
        <alert v-else :message="disputeMessage" type="neutral" />
      </template>
    </template>

    <template v-if="contragentFeedback.cid">
      <h2 class="mt-3 mb-4 mobile-pl">Отзыв обо мне</h2>
      <FeedbackBlock :feedback="contragentFeedback" />
      <template v-if="contragentFeedback.status !== 'finalized'">
        <alert
          v-if="!!contragentFeedback.replicas.length"
          :message="disputeMessage"
          type="neutral"
          clickable
          class="mb12px"
          @click.native="$router.push(disputeRoute)"
        />
        <alert v-else :message="disputeMessage" type="neutral" class="mb12px" />
        <custom-button
          v-if="!contragentFeedback.replicas.length"
          :to="disputeRoute"
          :disabled="!isCanOpenDispute"
          class="ml-0"
          round
          large
        >
          Открыть диспут
        </custom-button>
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import lightFormat from 'date-fns/lightFormat';
import isBefore from 'date-fns/isBefore';
import Alert from 'common/components/Alert';
import ROUTES from '@/utils/routes';
import FeedbackBlock from 'common/components/Orders/FeedbackBlock';
import ContentBlock from 'common/components/Orders/ContentBlock.vue';

export default {
  name: 'OrderViewFeedbacks',
  components: {
    Alert,
    FeedbackBlock,
    ContentBlock
  },
  props: {
    isOperator: Boolean
  },
  data() {
    return {
      isValid: false,
      evaluation: '',
      text: '',
      contragentFeedback: {},
      myFeedback: {},
      isLoading: false,
      disputeMessage: ''
    };
  },
  computed: {
    ...mapState('orders', {
      info: state => state.orderInfo.info
    }),
    ...mapState('settings', ['orderCancelReasons']),
    ...mapGetters('login', ['isShipper']),
    isCanOpenDispute() {
      return isBefore(new Date(), new Date(this.info.dispute_open_deadline));
    }
  },
  created() {
    this.getFeedbacks();
    this.disputeRoute = { name: ROUTES.ORDERS_ORDER_DISPUTE };
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapMutations('orders', ['setOrderInfoField']),
    async getFeedbacks() {
      this.isLoading = true;
      try {
        this.isOperator ? await this.getForOperator() : await this.getForUser();
        if (this.contragentFeedback.cid) this.getDisputeMessage(this.contragentFeedback);
        if (this.myFeedback.cid) this.getDisputeMessage(this.myFeedback, true);
      } catch (error) {
        console.error(
          `Ошибка в запросе ${
            isOperator
              ? 'feedbacks/feedbacks_by_order_for_operator'
              : 'feedbacks/feedbacks_by_order'
          }`,
          error
        );
      } finally {
        this.isLoading = false;
      }
    },
    async getForUser() {
      const {
        my_feedback: myFeedback,
        contragent_feedback: contragentFeedback
      } = await this.createController({
        topic: 'feedbacks/feedbacks_by_order',
        json: { order_cid: this.info.cid }
      });
      this.contragentFeedback = contragentFeedback;
      this.myFeedback = myFeedback;
    },
    async getForOperator() {
      const { shipper_feedback, carrier_feedback } = await this.createController({
        topic: 'feedbacks/feedbacks_by_order_for_operator',
        json: { order_cid: this.info.cid }
      });
      if (this.isShipper) {
        this.contragentFeedback = carrier_feedback;
        this.myFeedback = shipper_feedback;
      } else {
        this.contragentFeedback = shipper_feedback;
        this.myFeedback = carrier_feedback;
      }
    },
    async submitFeedback() {
      const { text, evaluation } = this;
      const topic = 'feedbacks/leave';
      const json = { order_cid: this.info.cid, text, evaluation };
      try {
        await this.createController({ topic, json });
        this.getFeedbacks();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    getDisputeMessage(feedback, myFeedback) {
      if (feedback.replicas.length && new Date() > new Date(feedback.dispute_close))
        this.disputeMessage = `Диспут открыт ${lightFormat(
          new Date(feedback.dispute_open),
          'dd.MM.yyyy в HH:mm'
        )}`;
      else if (new Date() < new Date(feedback.dispute_close))
        this.disputeMessage = `Диспут закрыт ${lightFormat(
          new Date(feedback.dispute_cancel),
          'dd.MM.yyyy в HH:mm'
        )}`;
      else
        this.disputeMessage = `${
          myFeedback ? 'Ваш контрагент может' : 'Вы можете'
        } открыть диспут до ${lightFormat(
          new Date(feedback.dispute_open_end),
          'dd.MM.yyyy в HH:mm'
        )}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';
/deep/ .content-block-container {
  padding: 10px 12px 2px 12px;
}

.positive-color {
  color: $color-success !important;
}
.neutral-color {
  color: $color-warning !important;
}
.negative-color {
  color: $color-danger !important;
}

.mb12px {
  margin-bottom: 12px;
}
.feedback {
  margin-bottom: 12px;
  &__label {
    display: inline-block;
    margin: 0 0 0 8px;
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  &__icon {
    transition: color 0.25s ease-in-out;
    color: $gray;
    cursor: pointer;
    &:hover {
      color: $color-main;
    }
  }
}

@media screen and (max-width: breakpoint(md)) {
  .feedback {
    &__save-button {
      margin-left: 12px;
    }
    &__icon {
      margin-right: 8px;
    }
    &__label {
      margin: 0;
    }
  }
  .mobile-pl {
    padding-left: 12px;
  }
}
</style>
