import { setField } from 'common/store/helpers';
import { isAfter } from 'date-fns';
import { searchOrderApi } from 'common/api/SearchOrderApi';

const state = {
  orders: [],
  searchOrders: [],
  currentOrderFindID: null,
  favor: false
};

const getters = {
  currentOrder: state => id => state.orders.find(_ => Number(_.id) === Number(id)),

  isInactiveOrder: state => id => {
    const order = state.orders.find(_ => _.id === id);
    if (!order) return null;
    const currentDate = new Date();
    const endDate = new Date(order.date_end);
    return isAfter(currentDate, endDate);
  }
};

const actions = {
  async getOrdersList({ commit, rootState }) {
    const json = { id: rootState.clients.info.id };

    const { list } = await searchOrderApi(this.dispatch).getAdminSearchOrderList({ json });

    commit('setField', { field: 'orders', value: list });
  },

  setCurrentOrderFind({ commit }, value) {
    commit('setField', { field: 'currentOrderFindID', value });
  },

  async getSerachOrdersList({
    commit,
    getters: { currentOrder },
    state: { currentOrderFindID, favor }
  }) {
    const { cid } = currentOrder(currentOrderFindID);
    const json = { cid, favor };

    const { orders } = await searchOrderApi(this.dispatch).getSearchOrderOrdersList({ json });

    commit('setField', { field: 'searchOrders', value: orders });
  },

  toggleFavors({ commit, dispatch, state: { favor } }) {
    commit('setField', { field: 'favor', value: !favor });
    dispatch('getSerachOrdersList');
  }
};

const mutations = {
  setField
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
