import Vue from 'vue';
import Router from 'vue-router';
import Clients from '../components/Clients';
import Verifications from '../components/Verifications';
import Disputs from '../components/Disputs';
import Support from '../components/Support';
import ContentManagement from '../components/ContentManagement';
import Marketing from '../components/Marketing';
import CountingDocs from '../components/CountingDocs';
import Administration from '../components/Administration';
import HistoryChanges from '../components/HistoryChanges';
import store from '../store';

Vue.use(Router);

const title = 'Cargo-Admin';

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: 'clients/'
    },
    {
      path: '/clients',
      name: 'Clients',
      component: Clients,
      meta: {
        title: 'Клиенты'
      },
      children: [
        {
          path: 'info/:category/:enduser',
          name: 'ClientInfo',
          meta: {
            title: 'Информация'
          },
          children: [
            {
              path: ':stage',
              name: 'ClientInfoStage'
            },
            {
              path: ':stage/:tab',
              name: 'ClientInfoStageTab'
            }
          ]
        },
        {
          path: ':tab',
          name: 'ClientsTab'
        }
      ]
    },
    {
      path: '/verifications',
      name: 'Verifications',
      component: Verifications,
      meta: {
        title: 'Верификация'
      },
      children: [
        {
          path: 'info/:type/:vehicle_id',
          name: 'VerificationInfo',
          meta: {
            title: 'Информация'
          }
        }
      ]
    },
    {
      path: '/disputs',
      name: 'Disputs',
      component: Disputs,
      meta: {
        title: 'Диспуты'
      }
    },
    {
      path: '/support',
      name: 'Support',
      component: Support,
      meta: {
        title: 'Поддержка'
      }
    },
    {
      path: '/content-management',
      name: 'ContentManagement',
      component: ContentManagement,
      meta: {
        title: 'Контент-Менеджмент'
      },
      children: [
        {
          path: ':stage/',
          name: 'ContentManagementStage'
        },
        {
          path: ':stage/:tab',
          name: 'ContentManagementTabs'
        }
      ]
    },
    {
      path: '/marketing',
      name: 'Marketing',
      component: Marketing,
      meta: {
        title: 'Маркетинг'
      },
      children: [
        {
          path: ':stage/',
          name: 'MarketingStage'
        }
      ]
    },
    {
      path: '/counting-docs',
      name: 'CountingDocs',
      component: CountingDocs,
      meta: {
        title: 'Бухгалтерия'
      },
      children: [
        {
          path: ':stage/',
          name: 'CountingDocsStage'
        },
        {
          path: ':stage/:tab',
          name: 'CountingDocsTab'
        }
      ]
    },
    {
      path: '/administration',
      name: 'Administration',
      component: Administration,
      meta: {
        title: 'Администрирование'
      }
    },
    {
      path: '/history-changes',
      name: 'HistoryChanges',
      component: HistoryChanges,
      meta: {
        title: 'История изменений'
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  console.log({ log: 'ROUTER' }, to);
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} | ${title}`;
  }
  const { sections } = store.state.login;
  if (!sections.length) return next();
  if (sections.find(_ => _.path.name === to.matched[0].name)) next();
  else next('/');
});

export default router;
