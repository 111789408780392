<template>
  <div class="light-map">
    <yandex-map
      v-if="yandexGeoApiKey"
      :coords="coordinates"
      :controls="[]"
      :settings="{ apiKey: yandexGeoApiKey }"
      :options="{ suppressMapOpenBlock: true }"
      @map-was-initialized="onMapInit"
    >
      <ymap-marker
        v-for="marker in markers"
        :key="marker.id"
        :marker-id="marker.id"
        :coords="marker.coords"
        :options="marker.options"
        :marker-type="marker.type"
      />
    </yandex-map>
    <div v-if="isLoading" class="loading">
      <v-progress-circular v-if="isLoading" size="50" color="primary" indeterminate />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { yandexMap, ymapMarker } from 'vue-yandex-maps/src';

let oldBounds = null;

export default {
  name: 'LightMap',
  components: {
    yandexMap,
    ymapMarker
  },
  props: {
    coordinates: Array,
    markers: Array,
    routePoints: Array
  },
  data: () => ({
    map: null,
    multiRoute: null,
    isLoading: false
  }),
  computed: {
    ...mapState('settings', ['yandexGeoApiKey'])
  },
  beforeDestroy() {
    if (this.map) this.map.destroy();
  },
  methods: {
    async onMapInit(mapEvent) {
      this.map = mapEvent;
      this.createMultiRoute();

      const routeButton = new ymaps.control.Button({
        data: { content: 'Показать текущее положение' },
        options: {
          selectOnClick: true,
          maxWidth: 200,
          layout: ymaps.templateLayoutFactory.createClass(
            '<div class="yandex-map-button">$[data.content]</div>'
          )
        },
        state: { selected: true }
      });

      routeButton.events.add('select', () => {
        oldBounds = this.map.getBounds();
        this.map.setBounds(this.multiRoute.getBounds());
      });

      routeButton.events.add('deselect', () => {
        this.map.setBounds(oldBounds);
      });

      routeButton.events.add('click', () => {
        if (routeButton.isSelected()) routeButton.data.set({ content: 'Показать весь маршрут' });
        else routeButton.data.set({ content: 'Показать текущее положение' });
      });

      this.multiRoute.model.events.add('requestsend', () => this.setLoading(true));
      this.multiRoute.model.events.add('requestsuccess', () => {
        this.setLoading(false);
        this.map.controls.add(routeButton);
        oldBounds = this.map.getBounds();
        this.map.setBounds(this.multiRoute.getBounds());
      });
      this.multiRoute.model.events.add('requestfail', () => this.setLoading(false));
    },
    createMultiRoute() {
      if (this.multiRoute) return this.multiRoute.model.setReferencePoints(this.routePoints);
      this.multiRoute = new ymaps.multiRouter.MultiRoute(
        {
          referencePoints: this.routePoints,
          params: {
            results: 1
          }
        },
        {
          wayPointVisible: false,
          viaPointVisible: false,
          routeActiveStrokeColor: '999999'
        }
      );
      this.map.geoObjects.add(this.multiRoute);
    },
    setLoading(val) {
      this.isLoading = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.ymap-container {
  height: 700px;
}

.light-map {
  position: relative;

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 1;
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }
}
</style>

<style lang="scss">
@import '~common/assets/styles/variables.scss';

.yandex-map-button {
  color: $color-main;
  padding: 12px;
  border: 1px solid $color-main;
  border-radius: 12px;
  background-color: #fff;
  cursor: pointer;
}
</style>
