/* eslint camelcase: 0 */

import axios from 'common/helpers/httpService';
export default {
  state: {
    beginningHourOfSupport: null,
    bonusPrivelege: null,
    bonusReferral: null,
    bonusReferrer: null,
    endingHourOfSupport: null,
    privelegeDeadline: null,
    startDateOfService: null,
    truckerAppAndroid: null,
    deallocationTimeVerification: null,
    limitForOffersInKm: null,
    hoursZone1: null,
    hoursZone2: null,
    workOnSite: false,
    orderCancelReasons: {},
    urAddress: '',
    urZip: '',
    supportEmail: '',
    supportPhones: [],
    yandexGeoApiKey: ''
  },
  actions: {
    async getSettings({ commit, dispatch }) {
      try {
        const baseURL = `${process.env.VUE_APP_WEB_PROTOCOL}://${
          process.env.VUE_APP_WEB_HOST
        }${(process.env.VUE_APP_WEB_PORT && `:${process.env.VUE_APP_WEB_PORT}`) || ''}`;
        const { settings } = await axios.get(`${baseURL}/api/user_settings`, true);
        commit('setSettings', settings);
      } catch (error) {
        dispatch(
          'addSnackbar',
          { message: 'Ошибка получения настроек приложения', type: 'error' },
          { root: true }
        );
      }
    }
  },
  mutations: {
    setSettings(
      state,
      {
        beginning_hour_of_support,
        bonus_privelege,
        bonus_referral,
        bonus_referrer,
        ending_hour_of_support,
        privelege_deadline,
        start_date_of_service,
        trucker_app_android,
        deallocation_time_of_verification,
        limit_for_offers_in_km,
        min_hours_between_offers_deadline_and_departure_zone1,
        min_hours_between_offers_deadline_and_departure_zone2,
        order_cancel_reasons,
        green_rating,
        work_on_site,
        ur_address,
        ur_zip,
        support_email,
        support_phones,
        yandex_geo_api_key
      }
    ) {
      state.beginningHourOfSupport = beginning_hour_of_support;
      state.bonusPrivelege = bonus_privelege;
      state.bonusReferral = bonus_referral;
      state.bonusReferrer = bonus_referrer;
      state.endingHourOfSupport = ending_hour_of_support;
      state.privelegeDeadline = privelege_deadline;
      state.startDateOfService = start_date_of_service;
      state.truckerAppAndroid = trucker_app_android;
      state.deallocationTimeVerification = deallocation_time_of_verification;
      state.limitForOffersInKm = limit_for_offers_in_km;
      state.hoursZone1 = min_hours_between_offers_deadline_and_departure_zone1;
      state.hoursZone2 = min_hours_between_offers_deadline_and_departure_zone2;
      state.orderCancelReasons = order_cancel_reasons;
      state.greenRating = green_rating;
      state.workOnSite = work_on_site;
      state.urAddress = ur_address;
      state.urZip = ur_zip;
      state.supportEmail = support_email;
      state.supportPhones = support_phones;
      state.yandexGeoApiKey = yandex_geo_api_key;
    }
  },

  namespaced: true
};
