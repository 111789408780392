<template>
  <div v-if="isLoading" class="text-center">
    <v-progress-circular indeterminate="indeterminate" color="primary" />
  </div>
  <div v-else class="items">
    <div class="tabs">
      <v-tabs v-model="currentTabIndex">
        <v-tab v-for="tab in filteredTabs" :key="tab">
          {{ tab }}
        </v-tab>
      </v-tabs>
      <div v-if="currentTabIndex === 0" class="information">
        {{ fields.information }}
      </div>
      <div v-if="currentTabIndex === 1">
        <custom-input
          id="web"
          ref="web"
          v-model="fields.web"
          input-type="textarea"
          rows="3"
          @blur="saveCursor"
        />
      </div>
      <div v-if="currentTabIndex === 2">
        <custom-input
          id="emailSubject"
          ref="emailSubject"
          v-model="fields.emailSubject"
          label="Заголовок уведомления"
          input-type="textarea"
          rows="1"
          @blur="saveCursor"
        />
        <custom-input
          id="emailText"
          ref="emailText"
          v-model="fields.emailText"
          input-type="textarea"
          rows="3"
          @blur="saveCursor"
        />
      </div>
      <div v-if="currentTabIndex === 3">
        <custom-input
          id="sms"
          ref="sms"
          v-model="fields.sms"
          input-type="textarea"
          rows="3"
          @blur="saveCursor"
        />
      </div>
      <div v-if="isDriver && currentTabIndex === 4">
        <custom-input
          id="push"
          ref="push"
          v-model="fields.push"
          input-type="textarea"
          rows="3"
          @blur="saveCursor"
        />
      </div>
      <div class="actions">
        <custom-button :disabled="!isSuper" color="primary" large @click="submit">
          Сохранить
        </custom-button>
        <custom-button round flat color="primary" @click="$emit('close')">
          Отмена
        </custom-button>
      </div>
    </div>
    <div v-if="currentTabIndex !== 0" class="tags">
      <h3 class="ui-kit__heading">
        Простые тэги
      </h3>
      <div class="tags__row">
        <v-btn
          v-for="(tag, key, i) in fields.tags"
          :key="i"
          class="tags__button"
          round="round"
          flat="flat"
          @click="onClick(tag.value)"
        >
          <v-icon style="font-size: 12px; margin-right: 10px;">
            local_offer
          </v-icon>
          {{ tag.name }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { insertTag } from '@/helpers/';

const tabs = ['Общая информация', 'Web', 'E-mail', 'SMS', 'Push'];

const createFields = info => ({
  information: info?.information ?? '',
  web: info?.web ?? '',
  emailSubject: info?.email_subject ?? '',
  emailText: info?.email_text ?? '',
  sms: info?.sms ?? '',
  tags: info?.tags ?? {},
  push: info?.push ?? ''
});

export default {
  name: 'NotificationFormTab',
  props: {
    currentTab: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      fields: createFields(),
      currentTabIndex: 0,
      currentCursorElement: ''
    };
  },
  computed: {
    ...mapState('adminNotifications', ['template']),
    ...mapState('login', ['isSuper']),
    ...mapGetters('common', ['loading']),
    isDriver() {
      return this.currentTab.key === 'trucker';
    },
    isLoading() {
      return this.loading('adm_notification/template');
    },
    filteredTabs() {
      return tabs.filter(tab => this.isDriver || tab !== 'Push');
    }
  },
  watch: {
    template() {
      this.fields = createFields(this.template);
    }
  },
  created() {
    this.tabs = tabs;
  },
  methods: {
    ...mapActions('adminNotifications', ['notificationSave']),
    saveCursor(e) {
      this.currentCursorElement = e.srcElement.id;
    },
    onClick(val) {
      if (!this.$refs[this.currentCursorElement]) return;
      const textarea = this.$refs[this.currentCursorElement].$el.querySelector('textarea');
      this.fields[this.currentCursorElement] = insertTag(textarea, val);
      textarea.focus();
    },
    async submit() {
      const template = {
        email_subject: this.fields.emailSubject,
        email_text: this.fields.emailText,
        information: this.fields.information,
        sms: this.fields.sms,
        tags: this.fields.tags,
        web: this.fields.web,
        push: this.fields.push
      };
      await this.notificationSave({
        number: this.currentTab.number,
        template,
        type: this.currentTab.key
      });
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
.items {
  display: flex;
  flex-grow: 1;
}
.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}
.tabs {
  display: flex;
  flex-direction: column;
  min-width: 50%;
  flex-grow: 1;
  height: inherit;
  padding-bottom: 8px;
}
.actions {
  margin: auto 0 0 0;
  align-self: flex-start;
  .custom-button {
    margin-bottom: 0;
  }
}
.tags {
  flex-grow: 1;
  min-width: 430px;
  padding: 0 0 0 20px;
  .tags__row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &__button {
    padding: 0 20px;
    font-size: 8px;
    background-color: $gray;
    /deep/ .v-btn__content {
      color: #fff;
      font-size: 12px;
    }
  }
  span {
    min-width: 85px;
    max-width: 85px;
    width: 85px;
    font-weight: bold;
    font-size: 16px;
    color: $color-main;
  }
}
.information {
  padding-top: 16px;
}
</style>
