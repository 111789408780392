import { render, staticRenderFns } from "./CarcassesAddForm.vue?vue&type=template&id=0fc1452f&scoped=true&"
import script from "./CarcassesAddForm.vue?vue&type=script&lang=js&"
export * from "./CarcassesAddForm.vue?vue&type=script&lang=js&"
import style0 from "./CarcassesAddForm.vue?vue&type=style&index=0&id=0fc1452f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fc1452f",
  null
  
)

export default component.exports