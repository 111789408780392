import { render, staticRenderFns } from "./TransportInfo.vue?vue&type=template&id=96ed8e32&scoped=true&"
import script from "./TransportInfo.vue?vue&type=script&lang=js&"
export * from "./TransportInfo.vue?vue&type=script&lang=js&"
import style0 from "./TransportInfo.vue?vue&type=style&index=0&id=96ed8e32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../cargo-newadmin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96ed8e32",
  null
  
)

export default component.exports