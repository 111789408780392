import { setField } from 'common/store/helpers';
import { getCityName } from 'common/utils';
import { transportApi } from 'common/api/TransportApi';

const state = {
  currentOrderCid: null,
  info: {},
  offersList: [],
  tnList: [],
  offerInfo: {},
  offerOwnerId: '',
  isEditMyOffer: false,
  trucker: {},
  vehicle: {},
  photosTrucker: [],
  shipper: {},
  carrier: {}
};

const getters = {
  editedOffer: state => state.offersList.find(_ => _.user_id === state.offerOwnerId),
  truckersList: state => state.offerInfo?.truckers ?? [],
  transportList: state =>
    state.offerInfo?.transport?.map(_ => {
      if (_.type === 'general')
        return {
          ..._,
          name: `Одиночное ТС ${_.name}`
        };
      return {
        ..._
      };
    }) ?? [],
  getListProperties: (state, getters) => ({ field, properties = state.info.properties || [] }) =>
    getters
      .getSchemeField(field)
      .filter(item => properties.find(el => el.name === item.id))
      .map(item => {
        const values = [];
        if (item.characteristics && item.characteristics.length) {
          item.characteristics.forEach(chr => {
            const property = properties.find(_ => chr === _.name);

            if (!property) {
              return;
            }

            const { value } = property;
            const {
              unit_measure: measure,
              carrier: { name }
            } = getters.getSchemeField('options_chars').find(_ => _.id === chr);
            values.push(`${name} - ${value} ${measure}`);
          });
        }
        return values.length ? `${item.name} (${values.join(', ')})` : `${item.name}`;
      })
      .join(', '),
  getListCarcasses: (state, getters) => (carcasses = state.info.carcasses || []) =>
    getters
      .getSchemeField('carcasses')
      .filter(item => carcasses.includes(item.id))
      .map(item => item.name)
      .join(', '),
  getListCharacteristics: (state, getters) => (characteristics = state.info.properties || []) =>
    getters
      .getSchemeField('characteristics')
      .filter(item => characteristics.find(el => el.name === item.id))
      .map(item => {
        const res = characteristics.find(el => el.name === item.id);
        return `${item.carrier.name} от ${res.value} ${item.unit_measure}`;
      })
      .join(', '),
  getFullRoute: state => state.info?.route?.map(item => getCityName(item.point))?.join(' - ') || '',
  routePoints: state => state.info?.route?.map(_ => _.point.coordinates) || [],
  firstAddress: state => {
    const [first = {}] = state.info?.route || [];
    return first?.point || {};
  },
  isSimpleRoute: state => state.info?.route?.length < 3,
  isRangeDate: state =>
    // eslint-disable-next-line camelcase
    new Date(state.info?.begin_datetime).toString() !==
    // eslint-disable-next-line camelcase
    new Date(state.info?.end_datetime).toString()
};

const actions = {
  async getInfo({ dispatch, commit, state }, field) {
    const topic = 'profile/organization/get_documents';
    try {
      const { agent, accounts } = await dispatch(
        'common/createController',
        { topic, json: { cid: state.info[`c${field}_id`] } },
        { root: true }
      );
      commit('setOrderInfoField', { field, value: { ...agent, accounts } });
    } catch (error) {
      console.error(`Ошибка в запросе ${topic}`, error);
    }
  },
  async getOrder(
    { dispatch, commit, rootState, state, getters },
    { json, topic = 'order/get' } = {}
  ) {
    const jsonData = json || { cid: state.currentOrderCid };
    try {
      const { order } = await dispatch(
        'common/createController',
        { topic, json: jsonData },
        { root: true }
      );

      await dispatch('transportData/getScheme', null, { root: true });

      commit('setOrderInfoField', { field: 'currentOrderCid', value: order.cid });
      const photos =
        order.cargo_photos?.map(item => ({
          ...item,
          smallSrc: `${rootState.defaultURL}/files/${rootState.login.cid}/${order.cshipper_id}/${item.small}`,
          src: `${rootState.defaultURL}/files/${rootState.login.cid}/${order.cshipper_id}/${item.file}`
        })) ?? [];
      const route = order.route.map(_ => {
        return {
          point: {
            ..._.point,
            liftNames: _.point.lift
              .map(item =>
                getters
                  .getSchemeField('lifting')
                  ?.find(el => el.id === item)
                  ?.name.toLowerCase()
              )
              .join(', ')
          }
        };
      });
      commit('setOrderInfoField', { field: 'info', value: { ...order, photos, route } });
      return order;
    } catch ({ status: { reason } }) {
      return { error: reason };
    }
  },
  async getOffersList({ commit, dispatch, state }, reset = false) {
    const topic = 'order/offers/list';
    const json = { cid: state.currentOrderCid, reset_new_offers: reset };
    try {
      const { list } = await dispatch('common/createController', { topic, json }, { root: true });
      commit('setOrderInfoField', { field: 'offersList', value: list });
    } catch (error) {
      console.error(`Ошибка в запросе ${topic}`, error);
    }
  },
  async getSafetyInfo({ dispatch }, cid) {
    const topic = 'organization/get_safety_info';
    return dispatch('common/createController', { topic, json: { cid } }, { root: true });
  },
  async getTnList({ commit, dispatch, state }) {
    const topic = 'order/tn/list';
    const json = { cid: state.currentOrderCid };
    try {
      const response = await dispatch('common/createController', { topic, json }, { root: true });
      commit('setOrderInfoField', { field: 'tnList', value: response.tn_list });
    } catch (error) {
      console.error(`Ошибка в запросе ${topic}`, error);
    }
  },
  async getPreOfferInfo({ commit, dispatch, state }, { bidding }) {
    /* Получение ТС, Водителей и общей информации при оформлении предложения/блица */
    const topic = 'order/offers/prepare';
    const json = {
      cid: state.currentOrderCid,
      bidding
    };
    try {
      const { info, transport, truckers } = await dispatch(
        'common/createController',
        { topic, json },
        { root: true }
      );
      commit('setOrderInfoField', { field: 'offerInfo', value: { info, transport, truckers } });
    } catch (error) {
      if (error.status.reason === 'E_FL_NO_CAN_TAKE_BLITZ') {
        throw [error.status.reason];
      }
      if (error.errors) {
        throw error.errors;
      }
    }
  },

  async getTransportFromCid({ dispatch }, cid) {
    const json = { cid };
    const { vehicle, type, wagon } = await transportApi(this.dispatch).transportGet({ json });
    if (vehicle) return { ...vehicle, type };
    return { ...wagon, type };
  },

  async getTruckerFromCid({ dispatch }, cid) {
    const topic = 'truckers/trucker/get';
    const json = { cid };
    const { trucker } = await dispatch('common/createController', { topic, json }, { root: true });
    return trucker;
  },

  async getTruckersAndTransportsInfo({ commit, state, dispatch }, { tsCid, truckerCid }) {
    /* Получение ТС, Водителей и общей информации для оформленного предложения */
    const topic = 'order/fitting_trucker_and_transport';
    const json = { cid: state.currentOrderCid };
    try {
      const { info, transport, truckers } = await dispatch(
        'common/createController',
        { topic, json },
        { root: true }
      );

      /* Если статус ТС-а или Водителя не Верифицирован, то получаем его по cid и добавляем в общий список */
      const isPresentTs = transport.find(({ cid }) => cid === tsCid);
      const isPresentTrucker = truckers.find(({ cid }) => cid === truckerCid);

      if (!isPresentTs) {
        const transportInfo = await dispatch('getTransportFromCid', tsCid);
        transport.push(transportInfo);
      }

      if (!isPresentTrucker) {
        const trucker = await dispatch('getTruckerFromCid', truckerCid);
        truckers.push(trucker);
      }

      commit('setOrderInfoField', { field: 'offerInfo', value: { info, transport, truckers } });
    } catch (error) {
      console.error(`Ошибка в запросе ${topic}`, error);
    }
  },

  async getAcceptedTruckerInfo({ commit, dispatch, state }) {
    const topic = 'truckers/trucker/get';
    const json = { cid: state.info.ctrucker_id };
    try {
      const { trucker, photos } = await dispatch(
        'common/createController',
        { topic, json },
        { root: true }
      );
      commit('setOrderInfoField', { field: 'trucker', value: trucker });
      commit('setOrderInfoField', { field: 'photosTrucker', value: photos });
    } catch (error) {
      console.error(`Ошибка в запросе ${topic}`, error);
    }
  }
};

const mutations = {
  setOrderInfoField: setField
};

export default {
  state,
  getters,
  actions,
  mutations
};
