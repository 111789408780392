<template>
  <div v-if="isLoading || loadingComponent" class="text-center">
    <v-progress-circular indeterminate="indeterminate" color="primary" />
  </div>
  <div v-else class="view-window">
    <v-toolbar dark="dark" color="primary">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer />
      <custom-button v-if="isShowButton" flat class="switch-button outline" @click="toggleType">
        Посмотреть у {{ isShipper ? 'перевозчика' : 'грузовладельца' }}
      </custom-button>
      <custom-button
        icon="icon"
        color="white"
        class="verification-window__close"
        @click="$emit('close')"
      >
        <v-icon>close</v-icon>
      </custom-button>
    </v-toolbar>
    <div class="view-window__main">
      <Tabs
        v-if="isShipper"
        key="tab-shipper"
        v-model="activeTab"
        :tabs="shipperTabs"
        @close="$emit('close')"
      />
      <Tabs
        v-else
        key="tab-carrier"
        v-model="activeTab"
        :tabs="carrierTabs"
        @close="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { TRANSPORT_VERSION, TRANSPORT_SCHEME } from 'common/api/TransportApi';

import Info from 'common/views/OrderView/Info';
import Offers from 'common/views/OrderView/Offers';
import Carrier from 'common/views/OrderView/Carrier';
import Documents from 'common/views/OrderView/Documents';
import GPS from 'common/views/OrderView/GPS';
import Feedbacks from 'common/views/OrderView/Feedbacks';
import Dispute from 'common/views/OrderView/Dispute';
import Shipper from 'common/views/OrderView/Shipper';
import DriverTS from 'common/views/OrderView/DriverTS';
import Tabs from '@/components/Layout/Tabs';
import Safety from 'common/components/Orders/Safety';
import Ratings from 'common/components/Ratings';

const shipperTabs = (status, isOpenedTab) => [
  {
    title: 'Информация',
    name: 'Info',
    component: Info
  },
  {
    title: 'Предложения',
    name: 'Offers',
    disabled: ['editing'].includes(status),
    component: Offers,
    options: { isOperator: true }
  },
  {
    title: 'Перевозчик',
    name: 'Carrier',
    disabled: ['editing', 'waiting_for_offer', 'accepting'].includes(status),
    component: Carrier
  },
  {
    title: 'Документы',
    name: 'Documents',
    disabled: ['editing', 'waiting_for_offer', 'accepting'].includes(status),
    component: Documents,
    options: { isOperator: true }
  },
  {
    title: 'GPS',
    name: 'GPS',
    disabled: ['editing', 'waiting_for_offer', 'accepting'].includes(status),
    component: GPS,
    options: { isOperator: true }
  },
  {
    title: 'Отзывы',
    name: 'Feedbacks',
    disabled: !['done', 'canceled', 'on_dispute', 'dispute_is_done'].includes(status),
    component: Feedbacks,
    options: { isOperator: true }
  },
  {
    title: 'Диспут',
    name: 'Dispute',
    disabled: !['on_dispute', 'dispute_is_done'].includes(status),
    component: Dispute,
    options: { isOperator: true, isOpenedTab }
  }
];
const carrierTabs = info => {
  const previewTabs = [
    {
      title: 'Информация',
      name: 'Info',
      component: Info
    },
    {
      title: 'Стоимость и предложения',
      name: 'Offers',
      topic: 'profile/organization/get_documents',
      options: { isOperator: true },
      component: Offers
    },
    {
      title: 'Рейтинг',
      name: 'Rating',
      topic: 'get_contragent_feedbacks',
      options: { order: info },
      component: Ratings
    }
  ];
  if (info.yur_type !== 'FL')
    previewTabs.push({
      title: 'Оценка надежности',
      name: 'Safety',
      options: {
        cshipperId: info.cshipper_id
      },
      component: Safety
    });
  const acceptedTabs = [
    {
      title: 'Информация',
      name: 'Info',
      component: Info
    },
    {
      title: 'Стоимость',
      name: 'Offers',
      topic: 'profile/organization/get_documents',
      component: Offers
    },
    {
      title: 'Грузовладелец',
      name: 'Shipper',
      component: Shipper
    },
    {
      title: 'Исполнители и ТС',
      name: 'DriverTS',
      component: DriverTS,
      options: { isOperator: true }
    },
    {
      title: 'Документы',
      name: 'Documents',
      component: Documents,
      options: { isOperator: true }
    },
    {
      title: 'GPS',
      name: 'GPS',
      component: GPS,
      options: { isOperator: true }
    },
    {
      title: 'Отзывы',
      name: 'Feedbacks',
      component: Feedbacks,
      options: { isOperator: true },
      disabled: !['done', 'canceled', 'on_dispute', 'dispute_is_done'].includes(info.status)
    },
    {
      title: 'Диспут',
      name: 'Dispute',
      component: Dispute,
      options: { isOperator: true, isOpenedTab: info.isOpenedTab },
      disabled: !['on_dispute', 'dispute_is_done'].includes(info.status)
    }
  ];
  return ['waiting_for_offer'].includes(info.status) ? previewTabs : acceptedTabs;
};

export default {
  name: 'ViewWindow',
  components: {
    Tabs
  },
  props: {
    dispute: {
      type: Object,
      default: () => ({})
    },
    // TODO subcribes for status disputes
    isOpenedTab: Boolean,
    toTab: {
      type: String,
      default: 'Info'
    },
    cid: String
  },
  data: () => ({
    activeTab: null,
    loadingComponent: false
  }),
  computed: {
    ...mapState('orders', {
      info: state => state.orderInfo.info
    }),
    ...mapGetters('login', ['isShipper']),
    ...mapGetters('common', ['loading']),
    shipperTabs() {
      return shipperTabs(this.info.status, this.isOpenedTab);
    },
    carrierTabs() {
      return carrierTabs({ ...this.info, isOpenedTab: this.isOpenedTab });
    },
    isShowButton() {
      return !['editing', 'waiting_for_offer'].includes(this.info.status);
    },
    isLoading() {
      return (
        this.loading('order/get') ||
        this.loading(TRANSPORT_VERSION) ||
        this.loading(TRANSPORT_SCHEME)
      );
    },
    title() {
      if (this.toTab === 'Dispute') {
        const isShipperInit = this.dispute.from_category === 'shipper';
        const shipperName = isShipperInit ? this.dispute.from_name : this.dispute.to_name;
        const carrierName = isShipperInit ? this.dispute.to_name : this.dispute.from_name;
        const type = this.isShipper
          ? `ГВ - ${isShipperInit ? 'ответчик' : 'инициатор'}`
          : `ГП - ${isShipperInit ? 'инициатор' : 'ответчик'}`;
        const name = this.isShipper ? shipperName : carrierName;
        return `Заказ #${this.info.id} ${name} - ${type}`;
      }
      return `Заказ  #${this.info.id}`;
    }
  },
  async created() {
    try {
      this.loadingComponent = true;
      this.activeTab = this.toTab;
      await this.getScheme();
      const json = { cid: this.cid };
      if (this.cid) await this.getOrder({ json });
    } catch (e) {
      console.log(e);
    } finally {
      this.loadingComponent = false;
    }
  },

  methods: {
    ...mapMutations('login', ['setField']),
    ...mapActions('orders', ['getOrder', 'getScheme']),
    toggleType() {
      const value = this.isShipper ? 'carrier' : 'shipper';
      this.setField({ field: 'previewType', value });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-window {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1;

  &__main {
    background-color: white;
  }

  /deep/ .tabs__content > div:first-child {
    padding: 0 12px 12px;
  }

  /deep/ .layout .flex {
    padding: 0 12px;
  }

  /deep/ .container {
    padding: 0;
  }

  .switch-button {
    color: white !important;
  }
}
</style>
