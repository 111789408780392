<template>
  <span>
    <swiper
      :images="value"
      :limit="limit"
      url-field="tiny"
      height="40px"
      max-width="40px"
      @image-click="open"
    />
    <swiper-dialog
      v-if="isShow"
      :images="value"
      :slide-index="slideIndex"
      @close="isShow = false"
    />
  </span>
</template>
<script>
import { swiperDialog, swiper } from 'common/components/swiper';

export default {
  components: {
    swiper,
    swiperDialog
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      }
    },
    limit: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      isShow: false,
      slideIndex: 0
    };
  },
  methods: {
    open(index) {
      this.isShow = true;
      this.slideIndex = index;
    }
  }
};
</script>
