<template>
  <div class="wrapper-card">
    <div class="wrapper-card__header">
      <div class="wrapper-card__header__text">
        <slot />
      </div>
      <div class="wrapper-card__header__buttons">
        <slot name="buttons" />
      </div>
    </div>
    <div v-if="isEdit" class="wrapper-card__content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemCard',
  props: {
    isEdit: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.wrapper-card {
  margin: 0 -12px 16px;
  border-top: 1px solid $gray-lighten;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 16px 0px rgba(17, 17, 17, 0.16);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 1;
    min-height: 44px;
    margin-bottom: 0 -12px 16px;
    &__text {
      flex-grow: 1;
    }
    &__buttons {
      flex-shrink: 0;
    }
  }
  &__content {
    margin-top: 12px;
  }
}
@media only screen and (max-width: breakpoint(md)) {
  .wrapper-card {
    margin: 0 0 16px;
  }
}
@media only screen and (max-width: breakpoint(sm)) {
  .wrapper-card {
    margin: 0 0 16px 0;

    &__header {
      margin: 0;
      padding: 3px 0;
      align-items: inherit;
    }
  }
}
</style>
