import { render, staticRenderFns } from "./lineWithDots.vue?vue&type=template&id=4e912a2f&scoped=true&functional=true&"
var script = {}
import style0 from "./lineWithDots.vue?vue&type=style&index=0&id=4e912a2f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../cargo-newadmin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "4e912a2f",
  null
  
)

export default component.exports