import { getStatus } from '@/helpers';
import utils from '@/helpers/utils';
import { setField } from 'common/store/helpers';

const state = {
  list: [],
  invites_list: []
};

const actions = {
  async getContractors({ dispatch, commit }, enduser) {
    commit('setField', { field: 'list', value: [] });
    commit('setField', { field: 'invites_list', value: [] });
    const topic = 'admin/endusers/trucker/attachment/get';
    try {
      const data = await dispatch(
        'common/createController',
        { topic, json: { enduser } },
        { root: true }
      );
      if (typeof data.attached === 'object' && Object.keys(data.attached).length !== 0) {
        data.attached.rules = data.attached.right_refusing_order
          ? 'Право отмены заказа'
          : 'Без права отмены заказа';
        data.attached.date = data.attached.attached_date;
        commit('setField', { field: 'list', value: [data.attached] });
      }
      const inviations = [];
      data.invitations.forEach(item => {
        if (item.status !== 'attached') {
          inviations.push(item);
        }
      });
      commit('setField', { field: 'invites_list', value: inviations });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async getContractor({ dispatch, commit }, json) {
    commit('clientsProfile/setOrganization', {}, { root: true });
    const topic = 'admin/endusers/trucker/attachment/contractor/details/get';
    try {
      const data = await dispatch('common/createController', { topic, json }, { root: true });
      commit('clientsProfile/setOrganization', data, { root: true });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async getDriverAuthorizeTS({ dispatch, commit }, enduser) {
    commit('clientsDrivers/setTsType', [], { root: true });
    const topic = 'admin/endusers/trucker/attachment/contractor/details/vehicles/authorized/get';
    try {
      const data = await dispatch(
        'common/createController',
        { topic, json: { enduser } },
        { root: true }
      );
      commit('clientsDrivers/setTsType', data.type, { root: true });
      const list = data.list
        .filter(item => item.id && item.name && item.verif_status && item.verif_until)
        .map(item => ({
          ...item,
          verify: getStatus(utils.statuses, {
            status: item.verif_status,
            until: item.verif_until,
            date: item.verif_date
          }),
          verifyColor: utils.verifyColors[item.verif_status] || utils.verifyColors.default
        }));
      commit('clientsDrivers/setTSList', list, { root: true });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  }
};

const mutations = {
  setField
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
