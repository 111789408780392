<template>
  <div v-if="isLoading" class="preloader preloader--center">
    <v-progress-circular :size="40" color="primary" indeterminate />
  </div>
  <v-container v-else class="disputes">
    <list-loader :cursor="feed.cursor" @load-more="getDisputes">
      <v-layout
        v-for="dispute in disputes"
        :key="dispute.cid"
        wrap
        class="dispute"
        @click="onClick(dispute.corder_id)"
      >
        <v-flex xs12 class="mb-3">
          <b>{{ dispute.fromName }} - {{ dispute.toName }}</b>
        </v-flex>
        <v-flex xs12 md2>
          <div class="label">Диспут открыт</div>
          {{ dispute.openDate }}
        </v-flex>
        <v-flex xs12 md3>
          <div class="label">Решение будет принято не позднее</div>
          {{ dispute.endDate }}
        </v-flex>
        <v-flex xs12 md7 class="status">
          <div class="label">Статус</div>
          <div class="status-content">
            {{ dispute.statusString }}
            <icon
              :class="['status-icon', dispute.status, dispute.dispute_status]"
              name="hat"
              size="18"
            />
          </div>
        </v-flex>
      </v-layout>
    </list-loader>
    <Stub v-if="!disputes.length" message="Нет диспутов с вашим участием" />
  </v-container>
</template>

<script>
import lightFormat from 'date-fns/lightFormat';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import ROUTES from '@/utils/routes';
import { getDisputeStatus } from 'common/helpers/dispute';
import Stub from 'common/components/Stub';
import ListLoader from 'common/components/ListLoader';

const format = 'dd.MM.yy, HH:mm';

export default {
  name: 'Disputes',
  components: { Stub, ListLoader },
  props: {
    isOperator: Boolean
  },
  data: () => ({
    disputes: [],
    isLoading: false,
    feed: {}
  }),
  computed: {
    ...mapState('login', ['cid']),
    ...mapGetters('login', ['isShipper']),
    ...mapState('clients', ['info'])
  },
  async mounted() {
    this.isLoading = true;
    await this.getDisputes();
    this.isLoading = false;
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions('transportData', ['getScheme']),
    ...mapMutations('orders', ['setOrderInfoField']),
    async onClick(cid) {
      // TODO Think about getting scheme
      if (this.isOperator) {
        const activeDispute = this.disputes.find(_ => _.corder_id === cid);
        this.$emit('click', activeDispute);
      } else {
        await this.getScheme();
        this.setOrderInfoField({ field: 'currentOrderCid', value: cid });
        this.$router.push({ name: ROUTES.ORDERS_ORDER_DISPUTE }).catch(() => {});
      }
    },
    async getDisputes() {
      const topic = this.isOperator ? 'disputes/adm_get_disputes' : 'disputes/get_disputes';
      let json = {};
      if (this.isOperator) json.agent_cid = this.info.cid;
      if (this.feed.cursor) json.feed = this.feed;
      try {
        const { disputes, feed } = await this.createController({ topic, json });
        this.feed = feed;
        const mappedDisputes = disputes
          .map(_ => ({
            ..._,
            fromName: this.cid === _.cfrom_user_id ? 'Вы' : _.from_name,
            toName: this.cid === _.cto_user_id ? 'Вы' : _.to_name,
            openDate: lightFormat(new Date(_.dispute_open), format),
            endDate: lightFormat(new Date(_.dispute_end), format),
            statusString: getDisputeStatus({
              dispute: _,
              isShipper: this.isShipper,
              personal: true
            })
          }))
          .sort((a, b) => new Date(b.dispute_open) - new Date(a.dispute_open));
        this.disputes = [...this.disputes, ...mappedDisputes];
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.dispute {
  box-shadow: $shadow-around;
  padding: 12px;
  margin: 16px -12px 0;
  cursor: pointer;

  .label {
    font-size: 12px;
    color: $gray;
    margin-bottom: 6px;
  }

  .status {
    text-align: right;

    &-icon {
      margin-left: 10px;
      position: relative;
      top: 4px;

      &.started,
      &.proceeding {
        color: $color-warning;
      }

      &.finalized_you_won {
        color: $color-success;
      }

      &.finalized_you_lost {
        color: $color-danger;
      }
    }
  }
  @media screen and (max-width: breakpoint(md)) {
    margin: 16px 0 0 0;
  }
  @media screen and (max-width: breakpoint(sm)) {
    .status {
      text-align: left;
    }
    .flex:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
</style>
