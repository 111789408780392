import { setField } from 'common/store/helpers';

const state = {
  feedbacks: [],
  rating: null,
  totalOrders: null,
  totalFeedbacks: null,
  feed: null,
  cursor: null,
  options: {
    about: 'me',
    evaluation: 'all',
    isOperator: false
  }
};

const actions = {
  async getFeedbacks({ state, commit, dispatch, rootState }, options = {}) {
    if (
      options?.evaluation !== state.options?.evaluation ||
      options?.about !== state.options?.about
    )
      commit('clearFeedbacks');
    if (Object.keys(options).length) commit('setField', { field: 'options', value: options });
    const { evaluation, isOperator, about } = state.options;
    const topic = isOperator
      ? 'feedbacks/get_feedbacks_for_operator'
      : 'feedbacks/get_feedbacks_about';
    const json = {
      about,
      evaluation
    };
    if (state.feed) json.feed = state.feed;
    if (isOperator) json.agent_cid = rootState.clients.info.cid;
    try {
      const response = await dispatch('common/createController', { topic, json }, { root: true });
      let { feedbacks, feed, count } = response[`feedbacks_about_${about}`];
      commit('setField', {
        field: 'feedbacks',
        value: [...state.feedbacks, ...feedbacks]
      });
      commit('setField', { field: 'feed', value: feed });
      commit('setField', { field: 'cursor', value: feed.cursor });
      if (isOperator && about === 'me') {
        commit('setRating', {
          rating: response.rating,
          totalFeedbacks: count,
          totalOrders: response.order_count
        });
      }
    } catch (error) {
      console.error(`Ошибка получения данных ${error}`);
    }
  },
  async getFeedbacksAgent({ state, commit, dispatch, rootState }, options = {}) {
    if (options.evaluation !== state.options.evaluation) commit('clearFeedbacks');
    if (Object.keys(options).length) commit('setField', { field: 'options', value: options });
    const { evaluation, agent_cid } = state.options;
    const topic = 'feedbacks/get_contragent_feedbacks';
    const json = {
      agent_cid,
      evaluation
    };
    if (state.feed) json.feed = state.feed;
    try {
      const {
        rating,
        feedbacks: { feedbacks, feed, count },
        orders_count
      } = await dispatch('common/createController', { topic, json }, { root: true });
      commit('setField', { field: 'feed', value: feed });
      commit('setField', {
        field: 'feedbacks',
        value: [...state.feedbacks, ...feedbacks]
      });
      commit('setRating', { rating, totalFeedbacks: count, totalOrders: orders_count });
    } catch (error) {
      console.error(`Ошибка получения данных ${error}`);
    }
  },
  setRating({ commit }, data) {
    commit('setRating', data);
  }
};

const mutations = {
  setField,
  clearFeedbacks(state) {
    state.feed = null;
    state.cursor = null;
    state.feedbacks = [];
  },
  setRating(state, { rating, totalFeedbacks, totalOrders }) {
    state.rating = rating;
    state.totalOrders = totalOrders;
    state.totalFeedbacks = totalFeedbacks;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
