<template>
  <div
    v-if="
      loading('admin/endusers/profile/organization/get') ||
        !organization.org_info ||
        !utils.safety.colors
    "
  >
    <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
  </div>
  <div v-else class="organization">
    <div class="organization__title">
      {{ showTrucker ? 'Перевозчик' : 'Данные' }}
    </div>
    <div class="organization__table">
      <div class="organization__table__column">
        <div class="organization__column__row">
          <div class="organization__column__cell">
            <v-text-field
              :label="info.name.label"
              :value="info.name.value"
              placeholder=" "
              :readonly="true"
            />
          </div>
        </div>
        <div class="organization__column__row">
          <div class="organization__column__cell">
            <v-text-field
              :label="info.ogrn.label"
              :value="info.ogrn.value"
              placeholder=" "
              :readonly="true"
            />
          </div>
          <div class="organization__column__cell">
            <v-text-field
              :label="info.inn.label"
              :value="info.inn.value"
              placeholder=" "
              :readonly="true"
            />
          </div>
        </div>
        <div
          v-if="organization.org_info.entity !== 'entrepreneur'"
          class="organization__column__row"
        >
          <div class="organization__column__cell">
            <v-text-field
              label="Юридический адрес организации"
              :value="organization.org_info.legal_address"
              placeholder=" "
              :readonly="true"
            />
          </div>
        </div>
        <div class="organization__column__row">
          <div class="organization__column__cell">
            <v-icon :style="{ color: utils.safety.colors[organization.org_info.safety] }">
              verified_user
            </v-icon>
            <v-text-field
              label="Оценка надежности"
              :value="utils.safety.statuses[organization.org_info.safety]"
              placeholder=" "
              :readonly="true"
            />
          </div>
          <div class="organization__column__cell">
            <v-text-field
              label="Дата получения оценки надежности"
              :value="OrganizationDate('safety_date')"
              placeholder=" "
              :readonly="true"
            />
          </div>
        </div>
      </div>
      <div class="organization__table__column">
        <div class="organization__column__row">
          <div class="organization__column__cell">
            <v-text-field
              label="Дата образования"
              :value="OrganizationDate('foundation_date')"
              placeholder=" "
              :readonly="true"
            />
          </div>
          <div class="organization__column__cell">
            <v-text-field
              label="Статус организации"
              :value="organization.org_info.entity_status"
              placeholder=" "
              :readonly="true"
            />
          </div>
        </div>
        <div class="organization__column__row">
          <div class="organization__column__cell">
            <v-text-field label="Категория" :value="Category" placeholder=" " :readonly="true" />
          </div>
          <div class="organization__column__cell" />
        </div>
        <div
          v-if="organization.org_info.entity !== 'entrepreneur'"
          class="organization__column__row"
        >
          <div class="organization__column__cell">
            <v-text-field
              label="ФИО руководителя"
              :value="organization.org_info.ceo"
              placeholder=" "
              :readonly="true"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="organization.org_info.entity === 'entrepreneur'" class="organization__title">
      Адрес регистрации
    </div>
    <div v-if="organization.org_info.entity === 'entrepreneur'" class="organization__table">
      <div class="organization__column__row">
        <div class="organization__legal_zip">
          <v-text-field
            label="Индекс"
            :value="organization.org_info.legal_zip"
            placeholder=" "
            :readonly="true"
          />
        </div>
        <div class="organization__legal_address">
          <v-text-field
            label="Адрес"
            :value="organization.org_info.legal_address"
            placeholder=" "
            :readonly="true"
          />
        </div>
      </div>
    </div>
    <div class="organization__title">
      Является ли организация плательщиком НДС?
    </div>
    {{ organization.org_info.nds_fee_payer ? 'Да' : 'Нет' }}
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'common/helpers/date';
import utils from '@/helpers/utils';

export default {
  name: 'ProfileTabOrganization',
  props: {
    showTrucker: Boolean
  },
  data: () => ({
    utils
  }),
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('clientsProfile', ['organization']),
    Category() {
      const categories = {
        persoal_entity: 'Физическое лицо',
        entrepreneur: 'Индивидуальный предприниматель',
        legal_entity: 'Юридическое лицо'
      };
      return categories[this.organization.org_info.entity];
    },
    info() {
      return {
        name: {
          label:
            this.organization.org_info.entity === 'legal_entity'
              ? 'Наименование Организации'
              : 'Наименование',
          value: this.organization.org_info.fullname
        },
        ogrn: {
          label: this.organization.org_info.entity === 'legal_entity' ? 'ОГРН' : 'ОГРНИП',
          value:
            this.organization.org_info.entity === 'legal_entity'
              ? this.organization.org_info.ogrn
              : this.organization.org_info.ogrnip
        },
        inn: {
          label: 'ИНН / КПП',
          value:
            this.organization.org_info.entity === 'legal_entity'
              ? `${this.organization.org_info.inn} / ${this.organization.org_info.kpp}`
              : this.organization.org_info.inn
        }
      };
    },
    semaphoreStatus() {
      const statuses = {
        yellow: 'Обратите внимание',
        red: 'Есть негативные факторы',
        green: 'Негативных факторов нет'
      };
      return statuses[this.organization.org_info.safety];
    },
    semaphoreColor() {
      const colors = {
        yellow: 'orange',
        red: 'red',
        green: 'green'
      };
      return colors[this.organization.org_info.safety];
    }
  },
  mounted() {
    if (!this.showTrucker) {
      this.getOrganization();
    }
  },
  methods: {
    ...mapActions('clientsProfile', ['getOrganization']),
    /**
     * @return {string}
     */
    OrganizationDate(key) {
      return moment(this.organization.org_info[key]).format('L');
    }
  }
};
</script>
<style lang="scss">
.organization {
  .organization__title {
    font-size: 24px;
    padding: 20px 0;
  }

  .organization__table {
    display: flex;

    .organization__column__row {
      display: flex;
      width: 100%;

      .organization__legal_zip {
        max-width: 30%;
        display: inline-block;
        padding: 15px 5px;
      }

      .organization__legal_address {
        padding: 15px 5px;
        display: inline-block;
        width: 70%;
      }
      .organization__column__cell {
        display: flex;
        padding: 15px 5px;
        width: 100%;
      }
    }
    .organization__table__column {
      width: 50%;
    }
  }
}
</style>
