import account from '!svg-inline-loader?classPrefix!common/assets/img/svg/account.svg';
import accountMultiple from '!svg-inline-loader?classPrefix!common/assets/img/svg/account-multiple.svg';
import addCircleOutline from '!svg-inline-loader?classPrefix!common/assets/img/svg/plus-circle-outline.svg';
import alertCircleOutline from '!svg-inline-loader?classPrefix!@mdi/svg/svg/alert-circle-outline.svg';
import arrowLeft from '!svg-inline-loader?classPrefix!common/assets/img/svg/arrow-left.svg';
import arrowRight from '!svg-inline-loader?classPrefix!common/assets/img/svg/arrow-right.svg';
import autoTs from '!svg-inline-loader?classPrefix!common/assets/img/svg/auto-ts.svg';
import bell from '!svg-inline-loader?classPrefix!common/assets/img/svg/bell.svg';
import bortTs from '!svg-inline-loader?classPrefix!common/assets/img/svg/bort-ts.svg';
import calendar from '!svg-inline-loader?classPrefix!@mdi/svg/svg/calendar.svg';
import carrierStubIcon from '!svg-inline-loader?classPrefix!common/assets/img/svg/carrier-stub-icon.svg';
import cartPlus from '!svg-inline-loader?classPrefix!common/assets/img/svg/cart-plus.svg';
import check from '!svg-inline-loader?classPrefix!common/assets/img/svg/check.svg';
import checkGradient from '!svg-inline-loader?classPrefix!common/assets/img/svg/check-gradient.svg';
import checkSimple from '!svg-inline-loader?classPrefix!common/assets/img/svg/checkSimple.svg';
import chevronUpThin from '!svg-inline-loader?classPrefix!common/assets/img/svg/chevronUp.svg';
import checkboxMarkedCircleOutline from '!svg-inline-loader?classPrefix!@mdi/svg/svg/checkbox-marked-circle-outline.svg';
import chevronDown from '!svg-inline-loader?classPrefix!@mdi/svg/svg/chevron-down.svg';
import chevronLeft from '!svg-inline-loader?classPrefix!@mdi/svg/svg/chevron-left.svg';
import chevronRight from '!svg-inline-loader?classPrefix!@mdi/svg/svg/chevron-right.svg';
import chevronUp from '!svg-inline-loader?classPrefix!@mdi/svg/svg/chevron-up.svg';
import circleGradient from '!svg-inline-loader?classPrefix!common/assets/img/svg/circle-gradient.svg';
import close from '!svg-inline-loader?classPrefix!@mdi/svg/svg/close.svg';
import containerTs from '!svg-inline-loader?classPrefix!common/assets/img/svg/container-ts.svg';
import contentCopy from '!svg-inline-loader?classPrefix!@mdi/svg/svg/content-copy.svg';
import creditCardOutline from '!svg-inline-loader?classPrefix!common/assets/img/svg/credit-card-outline.svg';
import databaseMinus from '!svg-inline-loader?classPrefix!@mdi/svg/svg/database-minus.svg';
import databasePlus from '!svg-inline-loader?classPrefix!@mdi/svg/svg/database-plus.svg';
import defaultCarcIcon from '!svg-inline-loader?classPrefix!common/assets/img/svg/default-carc-icon.svg';
import deleteIcon from '!svg-inline-loader?classPrefix!@mdi/svg/svg/delete.svg';
import dotsVertical from '!svg-inline-loader?classPrefix!@mdi/svg/svg/dots-vertical.svg';
import eye from '!svg-inline-loader?classPrefix!@mdi/svg/svg/eye.svg';
import eyeOff from '!svg-inline-loader?classPrefix!@mdi/svg/svg/eye-off.svg';
import fileSend from '!svg-inline-loader?classPrefix!common/assets/img/svg/file-send.svg';
import fire from '!svg-inline-loader?classPrefix!common/assets/img/svg/fire.svg';
import forum from '!svg-inline-loader?classPrefix!common/assets/img/svg/forum.svg';
import hat from '!svg-inline-loader?classPrefix!common/assets/img/svg/hat.svg';
import helpCircle from '!svg-inline-loader?classPrefix!@mdi/svg/svg/help-circle.svg';
import informationOutline from '!svg-inline-loader?classPrefix!@mdi/svg/svg/information-outline.svg';
import letter from '!svg-inline-loader?classPrefix!common/assets/img/svg/letter.svg';
import lightning from '!svg-inline-loader?classPrefix!common/assets/img/svg/lightning.svg';
import list from '!svg-inline-loader?classPrefix!common/assets/img/svg/owners-icon_9.svg';
import lockReset from '!svg-inline-loader?classPrefix!@mdi/svg/svg/lock-reset.svg';
import logo from '!svg-inline-loader?classPrefix!common/assets/img/logo.svg';
import map from '!svg-inline-loader?classPrefix!common/assets/img/svg/map.svg';
import marker from '!svg-inline-loader?classPrefix!common/assets/img/svg/marker.svg';
import markerGradient from '!svg-inline-loader?classPrefix!common/assets/img/svg/marker-gradient.svg';
import menu from '!svg-inline-loader?classPrefix!common/assets/img/svg/menu.svg';
import message from '!svg-inline-loader?classPrefix!@mdi/svg/svg/message.svg';
import messageWithLines from '!svg-inline-loader?classPrefix!common/assets/img/svg/message-with-lines.svg';
import messages from '!svg-inline-loader?classPrefix!common/assets/img/svg/messages.svg';
import minusCircleOutline from '!svg-inline-loader?classPrefix!common/assets/img/svg/minus-circle-outline.svg';
import negativeEmoticon from '!svg-inline-loader?classPrefix!@mdi/svg/svg/emoticon-sad-outline.svg';
import neutralEmoticon from '!svg-inline-loader?classPrefix!@mdi/svg/svg/emoticon-neutral-outline.svg';
import newIcon from '!svg-inline-loader?classPrefix!common/assets/img/svg/new.svg';
import orderDown from '!svg-inline-loader?classPrefix!common/assets/img/svg/order-down.svg';
import orderUp from '!svg-inline-loader?classPrefix!common/assets/img/svg/order-up.svg';
import pdf from '!svg-inline-loader?classPrefix!common/assets/img/svg/pdf.svg';
import pencil from '!svg-inline-loader?classPrefix!@mdi/svg/svg/pencil.svg';
import phone from '!svg-inline-loader?classPrefix!@mdi/svg/svg/phone.svg';
import platformTs from '!svg-inline-loader?classPrefix!common/assets/img/svg/platform-ts.svg';
import positiveEmoticon from '!svg-inline-loader?classPrefix!@mdi/svg/svg/emoticon-happy-outline.svg';
import refrigeratorTs from '!svg-inline-loader?classPrefix!common/assets/img/svg/refrigerator-ts.svg';
import rouble from '!svg-inline-loader?classPrefix!common/assets/img/svg/rouble.svg';
import roubleGradient from '!svg-inline-loader?classPrefix!common/assets/img/svg/rouble-gradient.svg';
import roundedCheckGradient from '!svg-inline-loader?classPrefix!common/assets/img/svg/rounded-check-gradient.svg';
import save from '!svg-inline-loader?classPrefix!@mdi/svg/svg/content-save.svg';
import send from '!svg-inline-loader?classPrefix!@mdi/svg/svg/send.svg';
import shipperStubIcon from '!svg-inline-loader?classPrefix!common/assets/img/svg/shipper-stub-icon.svg';
import sms from '!svg-inline-loader?classPrefix!common/assets/img/svg/sms.svg';
import smsInActive from '!svg-inline-loader?classPrefix!common/assets/img/svg/inactive_sms.svg';
import star from '!svg-inline-loader?classPrefix!@mdi/svg/svg/star.svg';
import starOutline from '!svg-inline-loader?classPrefix!@mdi/svg/svg/star-outline.svg';
import tarifsAuto from '!svg-inline-loader?classPrefix!common/assets/img/svg/tarifs_1.svg';
import tarifsCloud from '!svg-inline-loader?classPrefix!common/assets/img/svg/tarifs_2.svg';
import timer from '!svg-inline-loader?classPrefix!@mdi/svg/svg/timer-outline.svg';
import toArchive from '!svg-inline-loader?classPrefix!common/assets/img/svg/to-archive.svg';
import truck from '!svg-inline-loader?classPrefix!common/assets/img/svg/truck.svg';
import truckGradient from '!svg-inline-loader?classPrefix!common/assets/img/svg/truck-gradient.svg';
import vanAwningTs from '!svg-inline-loader?classPrefix!common/assets/img/svg/van-awning-ts.svg';
import vanIsotermTs from '!svg-inline-loader?classPrefix!common/assets/img/svg/van-isoterm-ts.svg';
import vanTs from '!svg-inline-loader?classPrefix!common/assets/img/svg/van-ts.svg';
import verifiedUser from '!svg-inline-loader?classPrefix!common/assets/img/svg/shield-check.svg';
import walletGiftcard from '!svg-inline-loader?classPrefix!common/assets/img/svg/wallet-giftcard.svg';
import xFile from '!svg-inline-loader?classPrefix!@mdi/svg/svg/file-remove-outline.svg';
import arrowUp from '!svg-inline-loader?classPrefix!@mdi/svg/svg/arrow-up-thick.svg';

export default {
  account,
  accountMultiple,
  addCircleOutline,
  alertCircleOutline,
  arrowLeft,
  arrowRight,
  autoTs,
  bell,
  bortTs,
  calendar,
  carrierStubIcon,
  cartPlus,
  check,
  checkGradient,
  checkSimple,
  checkboxMarkedCircleOutline,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  circleGradient,
  close,
  containerTs,
  contentCopy,
  creditCardOutline,
  databaseMinus,
  databasePlus,
  defaultCarcIcon,
  deleteIcon,
  dotsVertical,
  eye,
  eyeOff,
  fileSend,
  fire,
  forum,
  hat,
  helpCircle,
  informationOutline,
  letter,
  lightning,
  list,
  lockReset,
  logo,
  map,
  marker,
  markerGradient,
  menu,
  message,
  messageWithLines,
  messages,
  minusCircleOutline,
  negativeEmoticon,
  neutralEmoticon,
  newIcon,
  orderDown,
  orderUp,
  pdf,
  pencil,
  phone,
  platformTs,
  positiveEmoticon,
  refrigeratorTs,
  rouble,
  roubleGradient,
  roundedCheckGradient,
  save,
  send,
  shipperStubIcon,
  sms,
  smsInActive,
  star,
  starOutline,
  tarifsAuto,
  tarifsCloud,
  timer,
  toArchive,
  truck,
  truckGradient,
  vanAwningTs,
  vanIsotermTs,
  vanTs,
  verifiedUser,
  walletGiftcard,
  xFile,
  arrowUp,
  chevronUpThin
};
