<template>
  <div class="drivers_personal">
    <div v-if="trucker && trucker.trucker" class="drivers_info">
      <div class="drivers_info__row">
        <div class="row__avatar">
          <img
            v-if="!driverPhoto.avatar.length"
            src="../../../assets/img/empty-avatar.jpg"
            width="50"
            height="auto"
          />
          <img v-else :src="driverPhoto.avatar[0]" width="50" height="auto" />
        </div>
        <div class="row__text">
          <div class="initials">
            {{ trucker.fullname }}
          </div>
          <div class="verification" :style="{ color: trucker.status_color }">
            {{ trucker.status }}
          </div>
        </div>
        <div class="row__contacts">
          <div class="number">
            {{ trucker.phone }}
          </div>
          <div v-if="trucker.email" class="email">,&nbsp;{{ trucker.email }}</div>
        </div>
      </div>
      <div class="drivers_info__title">
        Паспортные данные водителя
      </div>
      <div class="drivers_info__row">
        <v-text-field
          class="info__row__field info__row__field__serie"
          label="Серия"
          :value="trucker.passport_series"
          placeholder=" "
        />
        <v-text-field
          class="info__row__field info__row__field__number"
          label="Номер"
          :value="trucker.passport_number"
          placeholder=" "
        />
        <v-text-field
          class="info__row__field info__row__field__date"
          label="Дата выдачи"
          :value="trucker.passport_date"
          placeholder=" "
        />
        <v-text-field
          class="info__row__field info__row__field__date"
          label="Дата рождения"
          :value="trucker.passport_birthday"
          placeholder=" "
        />
        <div class="drivers_info__row__viewer">
          <i>Скан-копия/Фото докум.</i>
          <image-slider-small :value="driverPhoto.passport" />
        </div>
      </div>
      <div class="drivers_info__title">
        Водительское удостоверение
      </div>
      <div class="drivers_info__row">
        <v-text-field
          class="info__row__field info__row__field__serie"
          label="Серия"
          :value="trucker.license_series"
          placeholder=" "
        />
        <v-text-field
          class="info__row__field info__row__field__number"
          label="Номер"
          :value="trucker.license_number"
          placeholder=" "
        />
        <v-text-field
          class="info__row__field info__row__field__date"
          label="Дата выдачи"
          :value="trucker.license_date"
          placeholder=" "
        />
        <div class="drivers_info__row__viewer">
          <i>Скан-копия/Фото докум.</i>
          <image-slider-small :value="driverPhoto.license" />
        </div>
      </div>
      <div class="drivers_info__title">
        Право отмены заказа
      </div>
      <div class="drivers_info__row">
        {{ trucker.right_refusing_order ? 'Да' : 'Нет' }}
      </div>
      <div class="drivers_info__btn">
        <v-btn
          class="ui-kit__button ui-kit__button-large ui-kit__button-lower ui-kit__button-default"
          :disabled="isDisabled(trucker.verification_status)"
          color="primary"
          large="large"
          @click="verificationDone"
        >
          Отказать в верификации
        </v-btn>
        <v-btn
          class="ui-kit__button ui-kit__button-outline ui-kit__button-lower ui-kit__button-default"
          large="large"
          outline="outline"
          color="primary"
          @click="closeWindow"
        >
          Отменить
        </v-btn>
      </div>
    </div>
    <div v-else class="loading">
      <v-progress-circular
        v-if="loading(routeDriver)"
        indeterminate="indeterminate"
        color="primary"
        :size="20"
      />
    </div>
    <v-dialog v-model="dialogDenied" class="ui-kit__dialog" persistent max-width="500px">
      <div class="ui-kit__dialog__header">
        <div class="ui-kit__dialog__title">
          Отказать в верификации
        </div>
        <v-spacer />
        <v-btn
          class="ui-kit__dialog__close"
          fab="fab"
          small="small"
          color="primary"
          @click.stop="dialogDenied = false"
        >
          <v-icon dark="dark">
            close
          </v-icon>
        </v-btn>
      </div>
      <div class="ui-kit__dialog__content">
        <v-textarea v-model="deniedText" class="ui-kit__input" label="Причина отказа" />
        <div class="block-btns">
          <v-btn
            class="ui-kit__button ui-kit__button-large ui-kit__button-lower ui-kit__button-default"
            color="primary"
            large="large"
            @click="deniedVerification"
          >
            Отказать
          </v-btn>
          <v-btn
            class="
              ui-kit__button
              ui-kit__button-outline
              ui-kit__button-lower
              ui-kit__button-default
            "
            large="large"
            outline="outline"
            color="primary"
            @click.stop="dialogDenied = false"
          >
            Оменить
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ImageSliderSmall from '../../common/ImageSliderSmall';

export default {
  components: {
    ImageSliderSmall
  },
  props: {
    truckerId: Number
  },
  data() {
    return {
      dialogDenied: false,
      deniedText: ''
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('clients', ['info']),
    ...mapState('clientsDrivers', ['trucker', 'photos', 'routeDriver']),
    ...mapState(['defaultURL']),
    ...mapState('login', ['cid', 'isSuper']),
    driverPhoto() {
      const list = {};
      if (this.photos) {
        this.photos.forEach(photo => {
          if (!list[photo.type]) {
            list[photo.type] = [];
          }
          list[photo.type].push(this.getPhotoPath(photo));
        });
      }
      return {
        avatar: list.my_photo || [],
        passport: [
          ...(list.passport_photo || []),
          ...(list.passport_registration || []),
          ...(list.passport_previous_number || [])
        ],
        license: [...(list.driver_license_photo || []), ...(list.driver_license_back || [])]
      };
    }
  },
  mounted() {
    this.getDriver({
      trucker: this.truckerId
    });
  },
  methods: {
    ...mapActions('clientsDrivers', ['getDriver', 'verifyDenial']),
    isDisabled(status) {
      return !this.isSuper || status !== 'verified';
    },
    getPhotoPath(photo) {
      return `${this.defaultURL}/files/${this.cid}/${this.trucker.cid}/${photo.medium}`;
    },
    closeWindow() {
      this.$router.push({ name: 'ClientInfoStage', params: { stage: 'drivers' } });
    },
    verificationDone() {
      this.dialogDenied = true;
    },
    async deniedVerification() {
      await this.verifyDenial({
        enduser: this.trucker.id,
        comment: this.deniedText
      });
      this.dialogDenied = false;
      this.$emit('verifyDenied');
      this.closeWindow();
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';
.drivers_personal {
  position: relative;
  height: 100%;

  .drivers_info {
    padding: 30px;
    overflow: auto;
    height: calc(100% - 60px);

    .drivers_info__btn {
      margin-top: 150px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .drivers_info__title {
      font-size: 25px;
      padding: 20px 0;
    }
    .drivers_info__row {
      display: flex;
      font-size: 15px;

      .drivers_info__row__viewer {
        flex-basis: 25%;
        flex-grow: 0;
        max-width: 25%;
        i {
          display: block;
          line-height: 12px;
          font-size: 12px;
          font-style: normal;
          color: $gray;
        }
      }
      .row__avatar {
        margin-right: 20px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .row__text {
        .initials {
          font-weight: bold;
          font-size: 15px;
        }
        .verification {
          line-height: initial;
          font-size: 12px;
        }
      }
      .row__contacts {
        margin-left: auto;
        display: flex;
        font-size: 15px;
        font-weight: bold;
        .number,
        .email {
          display: flex;
          align-items: center;
        }
      }

      .info__row__field {
        max-width: 15%;
        margin: 0 10px;
        &.info__row__field__serie {
          max-width: 5%;
        }
      }
    }
  }
}
</style>
