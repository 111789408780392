import { BaseApi } from './BaseApi';

/* app topics */
export const SEARCH_ORDER_LIST = 'search_order/list';
export const SEARCH_ORDER_SAVE = 'search_order/save';
export const SEARCH_ORDER_DELETE = 'search_order/delete';
export const SEARCH_ORDER_ORDERS_LIST = 'search_order/orders/get';
export const SEARCH_ORDER_READ = 'search_order/order/read';
export const SEARCH_ORDER_FAVOR = 'search_order/order/favor';
export const SEARCH_ORDER_UNFAVOR = 'search_order/order/unfavor';

/* admin topics */
export const ADM_SEARCH_ORDER_LIST = 'adm_search_order/list';

/* subcribe topics */
export const SUBSCRIBE_SEARCH_ORDER_ORDER_COUNTS = 'upd/search_orders/orders_count';

class SearchOrderApi extends BaseApi {
  getSearchOrderList() {
    return this.mqttController({ topic: SEARCH_ORDER_LIST });
  }

  saveSearchOrder({ json }) {
    return this.mqttController({ topic: SEARCH_ORDER_SAVE, json });
  }

  deleteSearchOrder({ json }) {
    return this.mqttController({ topic: SEARCH_ORDER_DELETE, json });
  }

  getSearchOrderOrdersList({ json }) {
    return this.mqttController({ topic: SEARCH_ORDER_ORDERS_LIST, json });
  }

  makeSearchOrderReaded({ json }) {
    return this.mqttController({ topic: SEARCH_ORDER_READ, json });
  }

  makeSearchOrderFavorite({ json }) {
    return this.mqttController({ topic: SEARCH_ORDER_FAVOR, json });
  }

  makeSearchOrderUnfavorite({ json }) {
    return this.mqttController({ topic: SEARCH_ORDER_UNFAVOR, json });
  }

  getAdminSearchOrderList({ json }) {
    return this.mqttController({ topic: ADM_SEARCH_ORDER_LIST, json });
  }

  subscribeSearchOrderCount(callback) {
    this.mqttSubscribe({ topic: SUBSCRIBE_SEARCH_ORDER_ORDER_COUNTS, callback });
  }

  unsubscribeSearchOrderCount() {
    this.mqttUnsubscribe({ topic: SUBSCRIBE_SEARCH_ORDER_ORDER_COUNTS });
  }
}

const createSearchOrderApi = () => {
  let instance = null;

  return dispatch => {
    if (!instance) {
      instance = new SearchOrderApi(dispatch);
    }

    return instance;
  };
};

export const searchOrderApi = createSearchOrderApi();
