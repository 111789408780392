<template>
  <div class="source_docs">
    <div class="docs__title">
      Подписка на оригиналы первичных документов
    </div>
    <div class="docs__row">
      <div class="docs__row__field">
        <v-text-field label="Периодичность" :value="item.period" placeholder=" " :edited="false" />
      </div>
      <div class="docs__row__field">
        <v-text-field
          label="Способ доставки"
          :value="item.delivery_method"
          placeholder=" "
          :edited="false"
        />
      </div>
      <div class="docs__row__field">
        <v-text-field
          label="Срок действия"
          :value="item.live_time"
          placeholder=" "
          :edited="false"
        />
      </div>
    </div>
    <div class="docs__title">
      Просмотр первичных документов
    </div>
    <div class="docs__row">
      <div class="row__table">
        <TableList :columns="columns" :values="list">
          <template slot="status" slot-scope="{ row }">
            <div :class="[row.status.status_class]">
              {{ row.status.title }}
            </div>
          </template>
        </TableList>
      </div>
    </div>
  </div>
</template>

<script>
import TableList from '../../Layout/TableList.vue';

export default {
  components: {
    TableList
  },
  data() {
    // TODO тестовые данные
    return {
      item: {
        period: 'Ежеквартально',
        delivery_method: 'почта России',
        live_time: '22.01.2017 - 31.12.2017'
      },
      columns: [
        {
          title: 'Дата',
          name: 'date',
          width: '10',
          alignLeft: true
        },
        {
          title: 'Содержание',
          name: 'text',
          width: '80',
          alignLeft: true
        },
        {
          title: 'Статус',
          name: 'status',
          width: '10',
          slot: true
        }
      ],
      list: [
        {
          date: '24.04.17',
          text: 'Первичные документы за 1-й квартал 2017 г.',
          status: {
            title: 'В обработке',
            status_class: 'wait'
          }
        },
        {
          date: '24.04.17',
          text: 'Первичные документы за 4-й квартал 2016 г.',
          status: {
            title: 'Отправлены',
            status_class: 'wait'
          }
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.source_docs {
  overflow: auto;
  height: 100%;

  .docs__title {
    font-size: 25px;
    padding: 20px 0;
  }
  .docs__row {
    position: relative;
    display: flex;

    .docs__row__field {
      width: 25%;
      margin: 10px;
    }

    .row__table {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
