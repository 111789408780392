<template>
  <BodyWorksContentAbstraction
    :list="lifting"
    :current-id="currentItem.id"
    :is-add-form="isAddForm"
    @add="addItem"
    @edit="editItem"
    @toggle-order="toggleOrder"
  >
    <template v-slot:head>
      <th>Название способа погрузки / разгрузки</th>
    </template>
    <template v-slot:body="{ item }">
      <td>{{ item.name }}</td>
    </template>
    <template v-slot:form>
      <LoadUnloadMethodsForm
        :is-edit-form="isEditForm"
        :is-add-form="isAddForm"
        :info="currentItem"
        @close="closeForm"
        @update="getScheme"
      />
    </template>
  </BodyWorksContentAbstraction>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BodyWorksContentAbstraction from './BodyWorksContentAbstraction';
import LoadUnloadMethodsForm from './forms/LoadUnloadMethodsForm';

export default {
  name: 'LoadUnloadMethods',
  components: {
    BodyWorksContentAbstraction,
    LoadUnloadMethodsForm
  },
  data() {
    return {
      isAddForm: false,
      isEditForm: false,
      currentItem: {}
    };
  },
  computed: {
    ...mapState('carcasses', ['lifting', 'currentVersionId'])
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions('carcasses', ['getScheme']),
    closeForm() {
      this.isAddForm = false;
      this.isEditForm = false;
      this.currentItem = {};
    },
    addItem() {
      this.currentItem = {};
      this.isAddForm = true;
    },
    editItem(item) {
      this.isAddForm = false;
      this.isEditForm = true;
      this.currentItem = item;
    },
    async toggleOrder({ gid, direction }) {
      const topic = 'admin/administration/carcasses/loading_unloading_methods/pull';
      const json = { gid, direction, id: this.currentVersionId };
      try {
        await this.createController({ topic, json });
        this.getScheme();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    }
  }
};
</script>
