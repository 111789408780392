<template>
  <transition name="small-fade">
    <div v-if="show" class="wrapper">
      <div :style="{ width: `${maxWidth}px` }" class="dialog">
        <header class="dialog__header">
          <div v-if="title" class="dialog__title">{{ title }}</div>
          <plain-button icon class="dialog__close" color="white" @click.stop="$emit('close')">
            <icon name="close" class="close-icon" />
          </plain-button>
        </header>
        <div :class="{ pb24px: hideFooter }" class="dialog__content">
          <slot>
            <p class="dialog__message">{{ message }}</p>
          </slot>
        </div>
        <footer v-show="!hideFooter" class="dialog__footer">
          <slot name="footer">
            <plain-button
              :loading="isLoading"
              round
              large
              color="primary"
              class="default-button"
              @click="$emit('accept')"
            >
              {{ buttonTitle }}
            </plain-button>
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import PlainButton from './PlainButton.vue';
import Icon from './Icon.vue';

export default {
  name: 'Dialog',
  components: {
    PlainButton,
    Icon
  },
  props: {
    show: Boolean,
    message: String,
    isLoading: Boolean,
    title: {
      type: String,
      default: 'Внимание!'
    },
    maxWidth: {
      type: String || Number,
      default: '408'
    },
    buttonTitle: {
      type: String,
      default: 'Всё понятно'
    },
    hideFooter: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.wrapper {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  &__header {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    padding: 6px 24px;
    background-color: $color-main;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    margin: 8px 0;
  }

  &__close {
    position: absolute !important;
    right: 13px;
    top: 0;
    bottom: 0;
    margin: auto;

    /deep/ > span {
      display: inline-block;
      line-height: 1;
    }
  }

  &__content {
    padding: 24px 24px 0;
    background-color: #fff;
    border-bottom: 1px solid transparent;
  }

  &__footer {
    padding: 0 24px 24px;
    background-color: #fff;
  }

  &__message {
    padding-bottom: 16px;
    text-align: center;
  }
}

.close-icon {
  color: #fff;
}

.pb24px {
  padding-bottom: 24px;
}

@media screen and (max-width: breakpoint(sm)) {
  .dialog {
    background: white;
    margin: 0;
    max-width: 100% !important;
    width: 100%;
    height: 100%;
    max-height: 100% !important;

    &__header {
      padding: 6px 16px;
    }

    &__content {
      padding: 24px 16px 0;
    }

    &__footer {
      padding: 0 16px 24px;
    }
  }
}

.small-fade-enter-active,
.small-fade-leave-active {
  transition: opacity 0.2s ease;
}

.small-fade-enter,
.small-fade-leave-to {
  opacity: 0;
}

.default-button {
  margin: 0 auto;
  display: block;
}
</style>
