const titles = {
  vehicles: 'ТС',
  truckers_db: 'Водитель',
  agents: 'Перевозчик',

  contractor: 'Перевозчик',
  shipper: 'Грузовладелец',
  trucker: 'Водитель',

  FL: 'ФЛ',
  IP: 'ИП',
  UL: 'ЮЛ',

  personal_entity: 'ФЛ',
  entrepreneur: 'ИП',
  legal_entity: 'ЮЛ'
};
const colors = {
  incomplete_data: 'unverified',
  unverified: 'unverified',
  waiting_for_verification: 'wait',
  waiting: 'wait',
  denied: 'verify_error',
  changed_by_user: 'unverified',
  verified: 'verify',
  is_on_verification: 'wait',
  default: 'verify_error'
};
const verifyColors = {
  incomplete_data: 'red',
  unverified: 'red',
  waiting_for_verification: 'orange',
  waiting: 'orange',
  denied: 'red',
  changed_by_user: 'red',
  verified: 'green',
  is_on_verification: 'blue',
  default: 'red'
};
const safety = {
  categories: ['red', 'yellow', 'green', 'recommendations'],
  colors: {
    yellow: 'rgb(255, 191, 0)',
    green: 'rgb(73, 170, 113)',
    red: 'rgb(251, 79, 79)'
  },
  titles: {
    red: 'Негативные факторы',
    yellow: 'Требуется внимание',
    green: 'Нет негативных факторов',
    recommendations: 'Рекоммендации от "Контур.Фокус"'
  },
  statuses: {
    red: 'Есть негативные факторы',
    yellow: 'Обратите внимание',
    green: 'Негативных факторов нет'
  },
  types: {
    block: '',
    points: '',
    hyphens: '',
    include_block: '',
    criteria_one_day_firm: ''
  }
};
const statuses = {
  incomplete_data: 'Неполные данные',
  unverified: 'Не верифицирован',
  waiting_for_verification: 'Ожидает верификации',
  waiting: 'Ожидается подтверждение',
  denied: 'Отказ в верификации %date%',
  changed_by_user: 'Изменен пользователем',
  verified: 'Верифицирован до %date%',
  is_on_verification: 'На верификации',
  default: 'Не верифицирован'
};
const yurs = {
  legal_entity: 'UL',
  personal_entity: 'FL',
  entrepreneur: 'IP'
};
const useTypes = {
  leasing: 'Лизинг',
  proprietary: 'Право собственности',
  'lease-contract': 'Договор аренды'
};
const transportTypes = {
  mover: 'Тягач',
  tractor: 'Тягач',
  general: 'Одиночное ТС',
  trailer: 'Прицеп',
  semitrailer: 'Полуприцеп',
  'semi-trailer': 'Полуприцеп'
};

export default {
  titles,
  colors,
  verifyColors,
  safety,
  statuses,
  yurs,
  useTypes,
  transportTypes
};
