<template>
  <div class="trucker_info modal">
    <div class="trucker_info__toolbar">
      <v-toolbar dark="dark" color="primary">
        <v-toolbar-title v-if="title">
          {{ title }}
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon="icon" dark="dark" @click="closeWindow">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </div>
    <div class="trucker_info__content">
      <ProfileTabPersonal v-if="type === 'personal_entity'" :show-trucker="true" />
      <ProfileTabOrganization v-else :show-trucker="true" />
      <div v-if="loadTS" class="content__ts">
        <div class="content__ts__title large">
          Права, предоставленные перевозчиком
        </div>
        <div class="content__ts__title">
          Право отмены заказа
        </div>
        {{ organization.right_refusing_order ? 'Да' : 'Нет' }}
        <div class="content__ts__title">
          Право поиска заказа для ТС
        </div>
        <DriverTransportInfoTab />
      </div>
    </div>
    <div>
      <v-btn color="primary" large="large" @click="closeWindow">
        Закрыть
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ProfileTabOrganization from '../Profile/ProfileTabOrganization';
import ProfileTabPersonal from '../Profile/ProfileTabPersonal';
import DriverTransportInfoTab from '../Drivers/DriverTransportInfoTab';

export default {
  name: 'ContractorInfo',
  components: {
    DriverTransportInfoTab,
    ProfileTabPersonal,
    ProfileTabOrganization
  },
  props: { loadTS: Boolean },
  data() {
    return {};
  },
  computed: {
    ...mapState('clientsProfile', ['organization']),
    type() {
      return this.organization.org_info.entity;
    },
    title() {
      return `${this.organization.org_info.fullname}`;
    }
  },
  methods: {
    closeWindow() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">
.trucker_info {
  .trucker_info__content {
    height: calc(100% - 108px);
    overflow-y: auto;
    padding: 20px;

    .content__ts {
      .content__ts__title {
        font-size: 18px;
        margin: 25px 0;
        font-weight: bold;

        &.large {
          font-size: 24px;
          font-weight: normal;
          margin: 40px 0;
        }
      }
    }
  }
}
</style>
