import utils from '@/helpers/utils';
import { getStatus } from '@/helpers';
import { setField } from 'common/store/helpers';

const state = {
  list: [],
  freightTransportList: [],
  transport: null,
  scheme: {},
  trucker_ts: {}
};

const actions = {
  async getTransports({ dispatch, commit }, { enduser }) {
    commit('setField', { field: 'list', value: [] });
    const topic = 'admin/endusers/contractor/transport_means/means/list';
    try {
      const data = await dispatch(
        'common/createController',
        { topic, json: { enduser } },
        { root: true }
      );
      const value = data.list.map(item => ({
        ...item,
        name: `${utils.transportTypes[item.vehicle_type]} ${item.make_and_model} ${
          item.registration_plate
        }`,
        verify: getStatus(utils.statuses, {
          status: item.verification_status,
          until: item.verification_expiration_date,
          date: item.verification_date
        }),
        verifyClass: utils.colors[item.verification_status] || utils.colors.default
      }));
      commit('setField', { field: 'list', value });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async getTrainList({ dispatch, commit }, json) {
    commit('setField', { field: 'freightTransportList', value: [] });
    const topic = 'admin/endusers/contractor/transport_means/freight_transport/list';
    try {
      const data = await dispatch('common/createController', { topic, json }, { root: true });
      const value = data.list.map(item => ({
        ...item,
        name: `${utils.transportTypes[item.head_type]} ${item.head_make_and_model} ${
          item.head_registration_plate
        }, ${utils.transportTypes[item.tail_type]} ${item.tail_make_and_model} ${
          item.tail_registration_plate
        }`,
        verify_auto_train: getStatus(
          utils.statuses,
          {
            status: item.verif_status,
            until: item.verif_until
          },
          true
        ),
        verify_head: getStatus(
          utils.statuses,
          {
            status: item.head_verification_status,
            until: item.head_verification_expiration_date
          },
          true
        ),
        verify_tail: getStatus(
          utils.statuses,
          {
            status: item.tail_verification_status,
            until: item.tail_verification_expiration_date
          },
          true
        ),
        verifyClass: utils.colors[item.verif_status] || utils.colors.default
      }));
      commit('setField', { field: 'freightTransportList', value });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async verifyDenial({ dispatch, commit, rootState }, { comment }) {
    const topic = 'admin/endusers/contractor/transport_means/means/deny';
    const json = {
      enduser: rootState.clients.info.id,
      vehicle: rootState.transportData.current.id,
      comment
    };
    try {
      await dispatch('common/createController', { topic, json }, { root: true });
    } catch (e) {
      commit('errorPush', 'Ошибка запроса на сервер', { root: true });
      console.error(`Ошибка в запросе ${topic}`);
    }
  }
};

const mutations = {
  setField
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
