<template>
  <div>
    <view-window
      v-if="isWindowShowed"
      :dispute="activeDispute"
      to-tab="Feedbacks"
      @close="isWindowShowed = false"
    />
    <ratings v-else :key="about" is-operator :about="about" @click="onClick" />
  </div>
</template>

<script>
import ViewWindow from '@/components/common/ViewWindow';
import { mapActions } from 'vuex';
import Ratings from 'common/components/Ratings';

export default {
  name: 'ClientFeedback',
  components: {
    Ratings,
    ViewWindow
  },
  props: {
    about: String
  },
  data() {
    return {
      isWindowShowed: false,
      activeDispute: {}
    };
  },
  methods: {
    ...mapActions('orders', ['getOrder', 'getScheme']),
    async onClick(cid) {
      await this.getScheme();
      await this.getOrder({ json: { cid } });
      this.isWindowShowed = true;
    }
  }
};
</script>

<style scoped>
/deep/ .rating {
  padding: 0 12px;
  margin-bottom: 15px;
}
</style>
