import dt from 'common/helpers/date';
import { setField } from 'common/store/helpers';

const mapMessage = ({ message, state }) => ({
  author: state.currentChat.authors.find(
    author => author.cuser_id === message.cuser_id || author.user_id === message.user_id
  ),
  id: message.message_id,
  type: message.user_type,
  text: message.text,
  date: dt(message.date),
  datetime: message.date
});

const state = {
  chatList: {
    own: {},
    free: {}
  },
  currentChat: {
    chat_id: null,
    authors: [],
    messages: [],
    info: {}
  },
  counterNewMessages: 0,
  badgeCounts: {}
};

const getters = {
  messages(state) {
    return state.currentChat.messages || [];
  },
  info(state) {
    return state.currentChat.info || {};
  },
  chatList(state) {
    return {
      own: Object.values(state.chatList.own),
      free: Object.values(state.chatList.free)
    };
  }
};

const actions = {
  async initOperator({ dispatch }) {
    await Promise.all([
      dispatch('getChatList', { type: 'free' }),
      dispatch('getChatList', { type: 'own' })
    ]);
    dispatch('subscribeNewChat');
    dispatch('subscribeChatTaken');
    dispatch('subscribeChatClosed');
  },

  async joinChat({ dispatch, commit, state: { chatList, currentChat } }, { chat_id }) {
    try {
      if (currentChat.chat_id === chat_id) {
        // запрет на открытие уже открытого чата
        return Promise.resolve();
      }
      if (currentChat.chat_id) {
        commit('clearChat');
      }
      let chat = chatList.free[chat_id];
      if (chat) {
        await dispatch(
          'common/createController',
          { topic: 'chat/join', json: { chat_id } },
          { root: true }
        );
      } else {
        chat = chatList.own[chat_id];
      }
      if (!chat) {
        throw new Error('no chat');
      }
      const info = await dispatch(
        'common/createController',
        { topic: 'chat/info', json: { chat_id } },
        { root: true }
      );
      state.badgeCounts[chat.chat_id] = 0;
      chat.badge_count = 0;
      commit('chatInfo', {
        ...info.owner,
        ownerId: info.owner.owner_id,
        ...chat
      });
      dispatch('getChatList', { type: 'free' });
      dispatch('getChatList', { type: 'own' });
      await dispatch('subscribeIncoming');
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  },

  async finishChat({ state: { currentChat }, dispatch, commit }) {
    if (currentChat.chat_id) {
      try {
        await dispatch(
          'common/createController',
          { topic: 'chat/close', json: { chat_id: currentChat.chat_id } },
          { root: true }
        );
      } catch (error) {
        console.error(error);
      } finally {
        commit('clearChat');
        dispatch('getChatList', { type: 'free' });
        dispatch('getChatList', { type: 'own' });
      }
    }
  },

  async sendMessage({ dispatch, commit, state: { currentChat } }, text) {
    try {
      if (!currentChat.chat_id) {
        return Promise.reject(new Error('error sendMessage'));
      }
      const data = await dispatch(
        'common/createController',
        {
          topic: 'chat/message',
          json: { chat_id: currentChat.chat_id, text, timestamp: Date.now() }
        },
        { root: true }
      );
      data.messages.forEach(message => {
        commit('addMessage', { message });
      });
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  },

  async getChatList({ commit, dispatch }, { type }) {
    try {
      const data = await dispatch(
        'common/createController',
        { topic: type === 'own' ? 'chat/list/taken' : 'chat/list' },
        { root: true }
      );
      if (data.chats) {
        commit('clearList', { type });
        data.chats.forEach(chat => {
          commit('addNewChat', { type, chat });
        });
      }
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getHistory({ commit, dispatch, state }, { chat_id, from = 0, limit = 0 } = {}) {
    try {
      const json = {};
      if (from !== 0 || limit !== 0) {
        json.from = from;
        json.limit = limit;
      }
      if (chat_id) {
        json.chat_id = chat_id;
      }
      const data = await dispatch(
        'common/createController',
        { topic: 'chat/history', json },
        { root: true }
      );
      const messages = data.messages
        .sort((a, b) => a.message_id - b.message_id)
        .filter(msg => !state.currentChat.messages.find(m => m.id === msg.message_id));
      commit('setAuthors', { authors: data.authors });
      if (messages.length > 0) {
        commit('addHistory', { messages });
      }
      return messages;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  subscribeIncoming({ dispatch, commit, rootState, state }) {
    dispatch(
      'common/subscribe',
      {
        topic: 'chat/incoming',
        callback(msg) {
          if (msg.chat_id === state.currentChat.chat_id) {
            commit('addMessage', {
              message: msg,
              path: rootState.route ? rootState.route.path : ''
            });
          } else if (msg.chat_id) {
            state.badgeCounts[msg.chat_id]++;
            state.chatList.own[msg.chat_id].badge_count++;
          }
        }
      },
      { root: true }
    );
  },

  subscribeNewChat({ dispatch }) {
    dispatch(
      'common/subscribe',
      {
        topic: 'chat/new',
        withCid: false,
        callback() {
          dispatch('getChatList', { type: 'free' });
          dispatch('getChatList', { type: 'own' });
        }
      },
      { root: true }
    );
  },

  subscribeChatTaken({ dispatch }) {
    dispatch(
      'common/subscribe',
      {
        topic: 'chat/taken',
        withCid: false,
        callback() {
          dispatch('getChatList', { type: 'free' });
          dispatch('getChatList', { type: 'own' });
        }
      },
      { root: true }
    );
  },

  subscribeChatClosed({ dispatch, commit, state }) {
    dispatch(
      'common/subscribe',
      {
        topic: 'chat/closed',
        callback: async () => {
          await dispatch('getChatList', { type: 'free' });
          await dispatch('getChatList', { type: 'own' });
          if (state.currentChat.chat_id && !state.chatList.own[state.currentChat.chat_id]) {
            commit('clearChat');
          }
        }
      },
      { root: true }
    );
  }
};

const mutations = {
  addNewChat(state, { type, chat }) {
    if (!chat || !state.chatList[type]) {
      return;
    }
    const badgeCount = state.badgeCounts[chat.chat_id] || 0;
    state.chatList[type][chat.chat_id] = {
      ...chat,
      date: dt(chat.start_date),
      badge_count: badgeCount
    };
    state.chatList[type] = { ...state.chatList[type] };
  },

  chatInfo(state, chat) {
    state.currentChat.chat_id = chat.chat_id;
    state.currentChat.info = chat;
  },

  clearList(state, { type }) {
    state.chatList[type] = {};
  },

  clearChat(state) {
    state.currentChat.authors = [];
    state.currentChat.messages = [];
    state.currentChat.info = {};
    state.currentChat.chat_id = null;
  },

  setAuthors(state, { authors }) {
    state.currentChat.authors = [...authors];
  },

  addSystemMessage(state, message) {
    state.currentChat.messages = [...state.currentChat.messages, message];
  },

  addMessage(state, { message, path }) {
    if (message.user_type === 'operators' && path !== '/help/support') state.counterNewMessages++;
    state.currentChat.messages = [...state.currentChat.messages, mapMessage({ message, state })];
  },

  addHistory(state, { messages }) {
    const mappedMessages = messages.map(msg => mapMessage({ message: msg, state }));
    if (mappedMessages.length > 0) {
      state.currentChat.messages = [...mappedMessages, ...state.currentChat.messages];
    }
  },

  setField
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
