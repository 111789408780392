import { render, staticRenderFns } from "./DisputeOperatorForm.vue?vue&type=template&id=0d10a034&scoped=true&"
import script from "./DisputeOperatorForm.vue?vue&type=script&lang=js&"
export * from "./DisputeOperatorForm.vue?vue&type=script&lang=js&"
import style0 from "./DisputeOperatorForm.vue?vue&type=style&index=0&id=0d10a034&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../cargo-newadmin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d10a034",
  null
  
)

export default component.exports