import { setField } from 'common/store/helpers';

const state = {
  notifications: [],
  count: 0,
  settings: {},
  limit: 10,
  type: 'current'
};

const actions = {
  async getNotifications({ dispatch, commit, rootState, state }, page = 1) {
    const { enduser } = rootState.clients.info;
    const { limit, type } = state;
    const topic = 'admin/endusers/notifications/list';
    const json = {
      type,
      page,
      enduser,
      limit
    };
    try {
      const {
        list: { notifications, count }
      } = await dispatch('common/createController', { topic, json }, { root: true });
      commit('setNotifications', { notifications, count });
    } catch (error) {
      console.error(`Ошибка в запросе ${topic}`, error);
    }
  },
  async changeType({ dispatch, commit }, value) {
    if (value === 'settings') return;
    await commit('setField', { field: 'type', value });
    await dispatch('getNotifications');
  },
  async getSettings({ dispatch, commit, rootState }) {
    const { enduser } = rootState.clients.info;
    const topic = 'admin/endusers/notifications/settings/get';
    const json = { enduser };
    try {
      const { settings } = await dispatch(
        'common/createController',
        { topic, json },
        { root: true }
      );
      commit('setField', { field: 'settings', value: settings });
    } catch (error) {
      console.error(`Ошибка в запросе ${topic}`, error);
    }
  }
};
const mutations = {
  setNotifications(state, { notifications, count }) {
    state.notifications = notifications;
    state.count = count;
  },
  setField
};
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
