import Vue from 'vue';

Vue.directive('scroll', {
  inserted(el, binding) {
    const handle = evt => {
      if (binding.value(evt, el)) {
        el.removeEventListener('scroll', handle);
      }
    };
    el.addEventListener('scroll', handle);
  }
});
