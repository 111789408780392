<template>
  <div class="carrier">
    <div class="carrier__status">
      <h1>Транспортное средство</h1>
      <span :style="{ color: getVerifyColor, marginTop: '20px' }">{{ verify_title }}</span>
    </div>
    <div class="carrier__split">
      <div class="carrier__row">
        <v-text-field :value="getTypeTs" readonly="readonly" />
      </div>
    </div>
    <div class="carrier__row">
      <v-text-field v-model="dataFromServer.vehicle.vin" :readonly="isVinRead" label="VIN" />
      <v-btn v-if="vinEdit" class="edit" icon="" flat="" color="news" @click="vinToggle">
        <v-icon>{{ isVinRead ? 'edit' : 'check' }}</v-icon>
      </v-btn>
      <v-btn
        v-clipboard:copy="dataFromServer.vehicle.vin"
        v-clipboard:success="onCopy"
        v-clipboard:error="onCopyError"
        class="copy-btn"
        icon=""
        flat=""
        color="news"
      >
        <v-icon>file_copy</v-icon>
      </v-btn>
      <div v-if="vinEdit" v-html-safe="vinCounter" class="vin_counter" />
      <v-checkbox
        v-if="dataFromServer.vehicle.vin.length !== 17"
        class="checkbox"
        :input-value="false"
        :value="false"
        disabled="disabled"
      />
      <v-checkbox
        v-else
        v-model="dataFromServer.vehicle.check_vin"
        class="checkbox"
        :disabled="!isVinRead"
      />
    </div>
    <div class="carrier__row">
      <v-text-field
        readonly="readonly"
        label="Марка и модель"
        :value="dataFromServer.vehicle.brand_and_model"
      />
      <v-checkbox v-model="dataFromServer.vehicle.check_brand_and_model" class="checkbox" />
    </div>
    <div class="carrier__row">
      <v-text-field
        readonly="readonly"
        label="Регистрационный знак"
        :value="dataFromServer.vehicle.registration_plate"
      />
      <v-btn
        v-clipboard:copy="dataFromServer.vehicle.registration_plate"
        v-clipboard:success="onCopy"
        v-clipboard:error="onCopyError"
        class="copy-btn"
        icon=""
        flat=""
        color="news"
      >
        <v-icon>file_copy</v-icon>
      </v-btn>
      <v-checkbox v-model="dataFromServer.vehicle.check_registration_plate" class="checkbox" />
    </div>
    <div class="carrier__split">
      <div class="carrier__row">
        <v-text-field readonly="readonly" label="Год выпуска" :value="releaseYear" />
        <v-checkbox v-model="dataFromServer.vehicle.check_release_year" class="checkbox" />
      </div>
      <div class="carrier__row">
        <a href="https://гибдд.рф/check/auto" target="_blank">Проверка ТС в ГИБДД</a>
        <v-checkbox v-model="dataFromServer.vehicle.check_gibdd" class="checkbox" />
      </div>
    </div>
    <div class="carrier__split">
      <div class="carrier__row">
        <v-text-field readonly="readonly" label="Тип права пользования ТС" :value="rightToUse" />
        <v-checkbox v-model="dataFromServer.vehicle.check_type_right_use" class="checkbox" />
      </div>
      <div class="carrier__row">
        <v-text-field
          v-if="dataFromServer.vehicle.type_right_use !== 'proprietary'"
          readonly="readonly"
          :disabled="validUntil.disabled"
          label="Действует до"
          :value="validUntil.date"
          :class="{ field_error: validUntil.disabled }"
        />
        <v-checkbox
          v-if="dataFromServer.vehicle.type_right_use !== 'proprietary'"
          v-model="dataFromServer.vehicle.check_valid_until"
          class="checkbox"
          :disabled="validUntil.disabled"
        />
      </div>
    </div>
    <div class="carrier__row">
      <custom-input
        readonly="readonly"
        label="Наименование (ФИО) перевозчика"
        :value="dataFromServer.name"
        input-type="textarea"
        rows="1"
      />
    </div>
    <div class="carrier__row">
      <custom-input
        readonly="readonly"
        label="Юр. адрес (адрес регистрации) перевозчика"
        :value="dataFromServer.legal_address"
        input-type="textarea"
        rows="1"
      />
    </div>
    <div v-if="dataFromServer.vehicle.type_right_use === 'proprietary'" class="carrier__row">
      <p>Совпадение наименования перевозчика с наименованием собственника в СТС</p>
      <v-checkbox
        v-model="dataFromServer.vehicle.check_name_carier_holder"
        class="checkbox ml-auto"
      />
    </div>
    <div v-if="dataFromServer.vehicle.type_right_use !== 'proprietary'" class="carrier__row">
      <p>Совпадение наименования собственника в СТС и документах на право пользования</p>
      <v-checkbox
        v-model="dataFromServer.vehicle.check_name_holder_docs"
        class="checkbox ml-auto"
      />
    </div>
    <div v-if="dataFromServer.vehicle.type_right_use !== 'proprietary'" class="carrier__row">
      <p>Совпадение наименования перевозчика в аккаунте и документах на право пользования</p>
      <v-checkbox
        v-model="dataFromServer.vehicle.check_name_carier_docs"
        class="checkbox ml-auto"
      />
    </div>
    <h1
      v-if="
        dataFromServer.vehicle.vehicle_type !== 'mover' &&
          dataFromServer.vehicle.vehicle_type !== 'tractor'
      "
    >
      Кузов
    </h1>
    <div
      v-if="
        dataFromServer.vehicle.vehicle_type !== 'mover' &&
          dataFromServer.vehicle.vehicle_type !== 'tractor'
      "
      class="carrier__row"
    >
      <v-text-field readonly="readonly" :value="getCarcass()" />
      <v-checkbox v-model="dataFromServer.vehicle.check_wagon_type" class="checkbox" />
    </div>
    <template v-if="charsArray.length">
      <h2 class="carrier__header">
        Характеристики кузова
      </h2>
      <div class="carrier__split wrap">
        <div class="carrier__row">
          <v-text-field
            v-for="(input, i) in charsArray"
            :key="i"
            readonly="readonly"
            :value="input.value"
            :label="input.carrier.name + ', ' + input.unit_measure"
          />
        </div>
      </div>
    </template>
    <template v-if="optCharsArray.length">
      <h2 class="carrier__header">
        Имеющиеся опции
      </h2>
      <div v-for="(item, i) in optCharsArray" :key="i" class="carrier__split wrap">
        <b class="block">{{ item.name }}</b>
        <template v-if="item.characteristics.length">
          <div class="carrier__row">
            <v-text-field
              v-for="(input, k) in item.characteristics"
              :key="k"
              readonly="readonly"
              :value="input.carrier.value"
              :label="input.carrier.name + ', ' + input.unit_measure"
            />
          </div>
        </template>
      </div>
    </template>
    <template v-if="liftingChars.length">
      <h2 class="carrier__header">
        Допустимые способы погрузки/разгрузки
      </h2>
      <div class="carrier__split wrap">
        <div class="carrier__row">
          <v-text-field
            v-for="(input, i) in liftingChars"
            :key="i"
            readonly="readonly"
            :value="input.name"
          />
        </div>
      </div>
    </template>
    <v-snackbar v-model="snackbar" bottom="" left="" :timeout="2000">
      {{ snackbarMessage }}
      <v-btn color="news" flat="" @click="snackbar = false">
        Закрыть
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import utils from '@/helpers/utils';

export default {
  props: {
    dataFromServer: Object,
    charsArray: Array,
    optCharsArray: Array,
    liftingChars: Array
  },
  data() {
    return {
      snackbar: false,
      snackbarMessage: '',
      isVinRead: true,
      oldVin: ''
    };
  },
  computed: {
    ...mapState('verifications', ['scheme']),
    ...mapState({}),
    vinEdit() {
      return !this.dataFromServer.vehicle.has_been_verif;
    },
    vinCounter() {
      const vinLength = this.dataFromServer.vehicle.vin.length;
      const text = `${vinLength}/17`;
      return vinLength === 17 ? '' : `<span>${text}</span>`;
    },
    releaseYear() {
      return new Date(this.dataFromServer.vehicle.release_year).getFullYear().toString();
    },
    validUntil() {
      const date = moment(this.dataFromServer.vehicle.valid_until).format('DD.MM.YYYY');
      return {
        date,
        disabled: moment(date, 'DD.MM.YYYY').isBefore(new Date(), 'day')
      };
    },
    verify_title() {
      return utils.statuses[this.dataFromServer.vehicle.verif_status];
    },
    getVerifyColor() {
      return utils.colors[this.dataFromServer.vehicle.verif_status];
    },
    rightToUse() {
      return utils.useTypes[this.dataFromServer.vehicle.type_right_use];
    },
    getTypeTs() {
      return utils.transportTypes[this.dataFromServer.vehicle.vehicle_type];
    }
  },
  mounted() {
    this.oldVin = this.dataFromServer.vehicle.vin || '';
  },
  methods: {
    ...mapActions('verifications', ['saveVin']),
    onCopy(e) {
      this.snackbar = true;
      this.snackbarMessage = `${'Скопировано: '}${e.text}`;
    },
    onCopyError() {
      this.snackbar = true;
      this.snackbarMessage = 'Ошибка: не скопировано!';
    },
    getCarcass() {
      return this.scheme.carcasses.find(item => item.id === this.dataFromServer.vehicle.carcass)
        .name;
    },
    async vinToggle() {
      const { vin } = this.dataFromServer.vehicle;
      if (this.isVinRead || vin.length !== 17) {
        this.isVinRead = false;
      } else if (vin !== this.oldVin && vin.length === 17) {
        const cid = this.dataFromServer.vehicle.cid || this.dataFromServer.cid;
        try {
          await this.saveVin({ vin, cid });
          this.oldVin = vin;
          this.isVinRead = true;
        } catch (e) {
          console.error(e);
        }
      } else this.isVinRead = true;
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';
.vehicle_window {
  .carrier {
    .block {
      display: block;
      width: 100%;
      flex-basis: 100%;
      margin-bottom: 20px;
    }
    &__split {
      display: flex;
      .carrier__row:first-child {
        margin-right: 20px;
      }
    }
    &__row {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      .vin_counter {
        font-size: 12px;
        position: absolute;
        right: 150px;
        span {
          color: red;
        }
      }
    }
    &__split.wrap {
      flex-wrap: wrap;
      margin-bottom: 0;
      .carrier__row {
        flex-wrap: wrap;
        width: 100%;
        margin-right: 0;
        .v-input {
          width: calc(50% - 10px);
          max-width: calc(50% - 10px);
        }
        .v-input:nth-child(even) {
          margin: 0 0 0 20px;
        }
      }
    }
    .carrier__header {
      font-weight: bold;
    }
    &__status {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      img {
        max-width: 110px;
        min-width: 110px;
        height: 110px;
        border-radius: 50%;
        margin-right: 20px;
      }
      span {
        align-self: flex-start;
        margin: 12px 0 0 auto;
      }
    }
    .not-verified {
      color: $color-danger;
    }
    .verified {
      color: $color-success;
    }
    .checkbox {
      max-width: 30px;
      margin-left: 15px;
    }
    .checkbox.ml-auto {
      margin-left: auto;
    }

    .copy-btn {
      color: $color-main;
      margin: 0;
      position: absolute;
      top: 6px;
      right: 50px;
      .material-icons {
        color: $color-main;
      }
    }

    .edit {
      color: $color-main;
      margin: 0;
      position: absolute;
      top: 6px;
      right: 100px;
      .material-icons {
        color: $color-main;
      }
    }
  }
}
</style>
