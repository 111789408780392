<template>
  <div class="truckers">
    <div class="truckers__row avatar">
      <img v-if="AvatarURL" class="avatar__img" alt="Avatar" :src="AvatarURL" />
      <img v-else class="avatar__img" alt="Avatar" src="../../../assets/img/empty-avatar.jpg" />
      <v-checkbox v-model="info.check_photo" class="avatar__checkbox" />
      <span :style="{ color: getVerifyColor }">{{ verifyStatus }}</span>
    </div>
    <div class="truckers__row">
      <div class="passport__title">
        Паспорт
      </div>
    </div>
    <div class="truckers__row">
      <v-text-field v-model="info.lastname" label="Фамилия" readonly="readonly" />
      <v-btn
        v-clipboard:copy="info.lastname"
        v-clipboard:success="onCopy"
        v-clipboard:error="onCopyError"
        class="row__copy"
        icon="icon"
        flat="flat"
        color="news"
      >
        <v-icon>file_copy</v-icon>
      </v-btn>
      <v-checkbox v-model="info.check_name" class="row__checkbox" />
    </div>
    <div class="truckers__row">
      <v-text-field v-model="info.name" label="Имя" readonly="readonly" />
      <v-btn
        v-clipboard:copy="info.name"
        v-clipboard:success="onCopy"
        v-clipboard:error="onCopyError"
        class="row__copy"
        icon="icon"
        flat="flat"
        color="news"
      >
        <v-icon>file_copy</v-icon>
      </v-btn>
      <v-checkbox v-model="info.check_lastname" class="row__checkbox" />
    </div>
    <div class="truckers__row">
      <v-text-field v-model="info.patronymic" label="Отчество" readonly="readonly" />
      <v-btn
        v-clipboard:copy="info.patronymic"
        v-clipboard:success="onCopy"
        v-clipboard:error="onCopyError"
        class="row__copy"
        icon="icon"
        flat="flat"
        color="news"
      >
        <v-icon>file_copy</v-icon>
      </v-btn>
      <v-checkbox v-model="info.check_patronymic" class="row__checkbox" />
    </div>
    <div class="truckers__row">
      <v-text-field v-model="info.passport_series" label="Серия" readonly="readonly" />
      <v-btn
        v-clipboard:copy="info.passport_series"
        v-clipboard:success="onCopy"
        v-clipboard:error="onCopyError"
        class="row__copy"
        icon="icon"
        flat="flat"
        color="news"
      >
        <v-icon>file_copy</v-icon>
      </v-btn>
      <v-checkbox v-model="info.check_passport_serie" class="row__checkbox" />
      <v-text-field v-model="info.passport_number" label="Номер" readonly="readonly" />
      <v-btn
        v-clipboard:copy="info.passport_number"
        v-clipboard:success="onCopy"
        v-clipboard:error="onCopyError"
        class="row__copy"
        icon="icon"
        flat="flat"
        color="news"
      >
        <v-icon>file_copy</v-icon>
      </v-btn>
      <v-checkbox v-model="info.check_passport_number" class="row__checkbox" />
    </div>
    <div class="truckers__row">
      <v-text-field
        v-model="info.passport_date"
        label="Дата выдачи"
        readonly="readonly"
        :disabled="isOverdue"
        :class="{ field_error: isOverdue }"
      />
      <v-btn
        v-clipboard:copy="info.passport_date"
        v-clipboard:success="onCopy"
        v-clipboard:error="onCopyError"
        class="row__copy"
        icon="icon"
        flat="flat"
        color="news"
      >
        <v-icon>file_copy</v-icon>
      </v-btn>
      <v-checkbox v-model="info.check_passport_date" class="row__checkbox" :disabled="isOverdue" />
      <v-text-field v-model="info.birthday_date" label="Дата рождения" readonly="readonly" />
      <v-btn
        v-clipboard:copy="info.birthday_date"
        v-clipboard:success="onCopy"
        v-clipboard:error="onCopyError"
        class="row__copy"
        icon="icon"
        flat="flat"
        color="news"
      >
        <v-icon>file_copy</v-icon>
      </v-btn>
      <v-checkbox v-model="info.check_birthday_date" class="row__checkbox" />
    </div>
    <div class="truckers__row">
      <div class="row__link row__link--fms" @click="openLink('fms')">
        Проверка на сайте ФМС
      </div>
      <v-checkbox v-model="info.check_passport_fms" class="row__checkbox" />
    </div>
    <div class="truckers__row">
      <div class="passport__title">
        Водительское удостоверение
      </div>
    </div>
    <div class="truckers__row">
      <v-text-field v-model="info.license_series" label="Серия" readonly="readonly" />
      <v-checkbox v-model="info.check_license_serie" class="row__checkbox" />
      <v-text-field v-model="info.license_number" label="Номер" readonly="readonly" />
      <v-btn
        v-clipboard:copy="info.license_series + info.license_number"
        v-clipboard:success="onCopy"
        v-clipboard:error="onCopyError"
        class="row__copy"
        icon="icon"
        flat="flat"
        color="news"
      >
        <v-icon>file_copy</v-icon>
      </v-btn>
      <v-checkbox v-model="info.check_license_number" class="row__checkbox" />
    </div>
    <div class="truckers__row">
      <div class="truckers__row__field">
        <v-text-field
          v-model="info.license_date"
          label="Дата выдачи"
          readonly="readonly"
          :disabled="info.overdue.license"
          :class="{ field_error: info.overdue.license }"
        />
        <v-btn
          v-clipboard:copy="info.license_date"
          v-clipboard:success="onCopy"
          v-clipboard:error="onCopyError"
          class="row__copy"
          icon="icon"
          flat="flat"
          color="news"
        >
          <v-icon>file_copy</v-icon>
        </v-btn>
        <v-checkbox
          v-model="info.check_license_date"
          class="row__checkbox"
          :disabled="info.overdue.license"
        />
      </div>
    </div>
    <div class="truckers__row">
      <div class="row__link" @click="openLink('gibdd')">
        Проверка на сайте ГИБДД
      </div>
      <v-checkbox v-model="info.check_license_gibdd" class="row__checkbox" />
    </div>
    <div class="truckers__row">
      Совпадение места рождения по паспорту и водительскому удостоверению
      <v-checkbox v-model="info.check_birthday_place_pas_lic" class="row__checkbox" />
    </div>
    <div class="truckers__row">
      Совпадение даты рождения по паспорту и водительскому удостоверению
      <v-checkbox v-model="info.check_birthday_date_pas_lic" class="row__checkbox" />
    </div>
    <v-snackbar v-model="snackbar" bottom="" left="" :timeout="2000">
      {{ snackbarMessage }}
      <v-btn color="news" flat="" @click="snackbar = false">
        Закрыть
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import utils from '@/helpers/utils';

export default {
  props: {
    info: Object
  },
  data() {
    return {
      disabledFileds: true,
      snackbar: false,
      snackbarMessage: ''
    };
  },
  computed: {
    ...mapState('login', ['cid']),
    isOverdue() {
      return (
        moment(this.info.passport_date).isAfter(this.info.verif_date, 'day') ||
        this.info.overdue.passport
      );
    },
    defaultURL() {
      return this.$store.state.defaultURL;
    },
    /**
     * @return {string}
     */
    AvatarURL() {
      const avatar = this.info.photos.find(item => item.type === 'my_photo');
      if (!avatar) {
        return '';
      }
      return `${this.defaultURL}/files/${this.cid}/${this.info.cid}/${avatar.file}`;
    },
    verifyStatus() {
      return utils.statuses[this.info.verif_status] || 'Неопределен';
    },
    getVerifyColor() {
      return utils.colors[this.info.verif_status];
    }
  },
  methods: {
    onCopy(e) {
      this.snackbar = true;
      this.snackbarMessage = `${'Скопировано: '}${e.text}`;
    },
    onCopyError() {
      this.snackbar = true;
      this.snackbarMessage = 'Ошибка: не скопировано!';
    },
    openLink(type) {
      const urls = {
        fms: 'http://services.fms.gov.ru/info-service.htm?sid=2000',
        gibdd: 'https://гибдд.рф/check/driver'
      };
      window.open(urls[type]);
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';
.truckers {
  padding: 15px;
  .truckers__row {
    display: flex;

    .truckers__row__field {
      max-width: 50%;
      display: flex;
    }
    .row__link {
      color: $color-main;
      text-decoration: underline;
      cursor: pointer;
      padding: 15px 21px 0 0;

      &--fms {
        padding-right: 37px;
      }
    }
    .row__copy {
      color: $color-main;
    }
    .row__checkbox {
      max-width: 20px;
      margin: 10px 10px 0 0;
      .v-icon {
        color: $color-main;
      }
    }
    .passport__title {
      padding: 20px 0;
      font-weight: bold;
      font-size: 18px;
    }
  }

  .avatar {
    .avatar__img {
      margin-right: 20px;
      max-width: 200px;
      max-height: 200px;
    }
    .avatar__checkbox {
      padding-top: 40px;
      margin: 0;
      .v-icon {
        color: $color-main;
      }
    }
  }
}
</style>
