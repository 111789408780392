export function extractCookies() {
  return (
    document?.cookie
      ?.split('; ')
      .map(_ => _.split('='))
      .reduce((acc, curr) => ({ ...acc, [curr[0]]: curr[1] }), {}) ?? {}
  );
}

export function setCookie({ name, value, domain, expires = new Date('2022') }) {
  if (typeof document !== undefined) {
    document.cookie =
      `${name}=${value};path=/;expires=${expires}` + (domain ? `;domain=${domain}` : '');
  }
}
