<template>
  <Tabs :tabs="tabs" :active="currentTab" @change="changeType($event)" />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Tabs from '@/components/Layout/Tabs';
import TabNotifications from './NotificationsTabs/TabNotifications';
import TabSettings from './NotificationsTabs/TabSettings';

export default {
  components: {
    Tabs
  },
  data() {
    return {
      currentTab: this.$route.params.tab || 'current'
    };
  },
  computed: {
    ...mapState('clients', ['info']),
    stage() {
      return this.$route.params.stage;
    },
    tabs() {
      return [
        {
          name: 'current',
          component: TabNotifications,
          title: 'Текущие'
        },
        {
          name: 'archived',
          component: TabNotifications,
          title: 'Архив',
          show: this.info.category !== 'trucker'
        },
        {
          name: 'settings',
          component: TabSettings,
          title: 'Настройки',
          show: this.info.category !== 'trucker'
        }
      ];
    }
  },
  methods: {
    ...mapActions('clientsNotifications', ['changeType'])
  }
};
</script>
