var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('v-alert',{staticClass:"title",attrs:{"border":"left","tile":"","close-text":"Close Alert","color":"blue","dismissible":"","light":""},model:{value:(_vm.showMessageTimer),callback:function ($$v) {_vm.showMessageTimer=$$v},expression:"showMessageTimer"}},[_vm._v(" Время на верификацию истекло. Карточка автоматически закрыта ")]),_c('div',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.bottomScroll),expression:"bottomScroll"}],staticClass:"table_content",class:{ table_pagination: _vm.isShowPagination }},[(_vm.values.length)?_c('table',[_vm._t("header"),(_vm.showColumns)?_c('tr',{staticClass:"table_columns"},_vm._l((_vm.columns),function(column,i){return _c('th',{key:(i + "_table_columns"),staticClass:"table_column",class:[
            ("width" + (column.width || '-auto')),
            { alignLeft: column.alignLeft, alignRight: column.alignRight }
          ]},[_vm._v(" "+_vm._s(column.title || column.name)+" ")])}),0):_vm._e(),_c('tbody',{staticClass:"table_body"},[_vm._l((_vm.values),function(row,index){return [(row.header)?_c('tr',{key:(index + "_table_body"),on:{"click":function($event){return _vm.clickItem(row)}}},[_c('td',{attrs:{"colspan":_vm.columns.length}},[_vm._t("header",null,{"row":row})],2)]):_vm._e(),_c('tr',{key:(index + "_table_row"),staticClass:"table_row",on:{"click":function($event){return _vm.clickItem(row)}}},_vm._l((_vm.columns),function(column,i){return _c('td',{key:(i + "_table_row_column"),staticClass:"table_column",class:[("width" + (column.width || '-auto'))]},[_c('div',{staticClass:"table_value",class:{
                  alignLeft: column.alignLeft,
                  alignRight: column.alignRight,
                  nowrap: column.nowrap
                }},[(!column.slot)?_c('div',[_vm._v(" "+_vm._s(_vm.findValue(row, column))+" ")]):_vm._t(column.name,null,{"row":row})],2)])}),0),(row.footer)?_c('tr',{key:index,on:{"click":function($event){return _vm.clickItem(row)}}},[_c('td',{attrs:{"colspan":_vm.columns.length}},[_vm._t("footer",null,{"row":row})],2)]):_vm._e()]})],2)],2):_vm._e(),(!_vm.loading && !_vm.values.length)?_c('div',{staticClass:"table_empty"},[_vm._t("empty")],2):_vm._e(),(_vm.loading)?_c('div',{staticClass:"table_loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"indeterminate","color":"primary","size":20}})],1):_vm._e(),_vm._t("footer")],2),(_vm.isShowPagination)?_c('div',{staticClass:"table_pagination"},[_c('v-pagination',{attrs:{"length":_vm.pages,"total-visible":_vm.pagination.visible || 20,"value":_vm.pagination.page},on:{"input":_vm.changePage}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }