<template>
  <v-form v-model="isValid" @submit.prevent="$emit('submit', formData)">
    <custom-input
      v-model="text"
      :rules="[v => !!v || 'Поле обязательно для заполнения']"
      label="Изложите вашу версию событий"
      type="textarea"
      rows="1"
    />
    <image-uploader
      :uploaded-photos="uploadedPhotos"
      text-label="Добавить документ или фото груза"
      @uploaded="uploadedPhotos.push($event)"
      @removed-file="deletePhoto"
    />
    <custom-button :disabled="!isValid" class="ml-0" round large type="submit">
      {{ buttonText }}
    </custom-button>
  </v-form>
</template>

<script>
import imageUploader from 'common/components/imageUploader.vue';

export default {
  name: 'DisputeMessageForm',
  components: { imageUploader },
  props: {
    buttonText: {
      type: String,
      default: 'Открыть'
    }
  },
  data: () => ({
    uploadedPhotos: [],
    text: '',
    isValid: false
  }),
  computed: {
    formData() {
      return {
        files: this.uploadedPhotos.map(_ => _.photo),
        text: this.text
      };
    }
  },
  methods: {
    deletePhoto(src) {
      this.uploadedPhotos = this.uploadedPhotos.filter(_ => _.src !== src);
    }
  }
};
</script>
