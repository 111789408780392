<template>
  <v-dialog
    :value="showMap"
    transition="none"
    content-class="map-dialog"
    max-width="840px"
    :fullscreen="isMobile"
  >
    <v-toolbar dark dense flat color="primary">
      <v-toolbar-title>
        {{ readOnly ? 'Маршрут на карте' : 'Укажите точку маршрута' }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn class="close-button" icon flat @click="$emit('close')">
          <icon name="close" />
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <yandex-map
      v-if="yandexGeoApiKey"
      :coords="coords"
      :controls="['trafficControl']"
      :settings="{ apiKey: yandexGeoApiKey }"
      disable-pan
      @click="onClick"
      @boundschange="onBoundchange"
      @map-was-initialized="onMapInit"
    >
      <ymap-marker
        v-if="!readOnly"
        marker-id="123"
        :coords="coords"
        :icon="{ layout: 'islands#blueStretchyIcon', content: text }"
      />
    </yandex-map>
    <div v-if="isLoading" class="loading">
      <span>Идет построение маршрута...</span>
    </div>
    <custom-button
      v-if="!readOnly"
      :full-width="isMobile"
      round
      large
      color="primary"
      class="submit-button"
      @click="$emit('select', fullData)"
    >
      Выбрать
    </custom-button>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { yandexMap, ymapMarker } from 'vue-yandex-maps/src';
import { getFullAddress } from 'common/utils';

export default {
  name: 'GeocoderMap',
  components: { yandexMap, ymapMarker },
  props: {
    coordinates: {
      type: Array,
      default: () => [55.7558, 37.6173]
    },
    routePoints: Array,
    showMap: Boolean,
    getAddress: Function,
    bounds: Array,
    addressText: String,
    readOnly: Boolean
  },
  data() {
    return {
      text: this.addressText,
      coords: this.coordinates,
      address: {},
      map: null,
      multiRoute: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('settings', ['yandexGeoApiKey']),
    fullData() {
      const { text, coords: coordinates, address } = this;
      return { text, coordinates, address };
    }
  },
  watch: {
    coords: {
      immediate: true,
      async handler(val) {
        if (this.readOnly || val.some(Number.isNaN)) return;
        const { addressArray } = await this.getAddress(val);
        this.address = addressArray;
        if (!addressArray) return;

        const withProvince = true;
        this.text = getFullAddress({ address: addressArray }, withProvince);
      }
    },
    coordinates(val) {
      this.coords = val;
    },
    routePoints(val, old) {
      const getString = _ => _?.reduce((a, b) => (b ? a.concat(b) : a), []).join();
      if (getString(val) === getString(old)) return;
      this.multiRoute.model.setReferencePoints(val);
    },
    bounds(val) {
      this.map.setBounds(val);
    },
    showMap(val) {
      if (val) this.coords = this.coordinates;
    }
  },
  beforeDestroy() {
    if (this.map) this.map.destroy();
  },
  methods: {
    ...mapActions('orderEdit', ['updateRouteDistances']),
    onClick(e) {
      if (this.readOnly) return;
      this.coords = e.get('coords');
    },
    onBoundchange(e) {
      const {
        originalEvent: { newZoom }
      } = e;
      if (!newZoom && this.bounds?.length) this.map.setBounds(this.bounds);
      if (this.readOnly) this.map.setBounds(this.multiRoute.getBounds());
    },
    async onMapInit(mapEvent) {
      this.map = mapEvent;
      this.createMultiRoute();

      const trafficButton = new ymaps.control.Button({
        data: { content: 'Учитывать пробки' },
        options: { selectOnClick: true, maxWidth: 200 }
      });

      trafficButton.events.add('select', () => {
        this.multiRoute.model.setParams({ avoidTrafficJams: true }, true);
        this.updateRouteDistances(this.multiRoute);
      });

      trafficButton.events.add('deselect', () => {
        this.multiRoute.model.setParams({ avoidTrafficJams: false }, true);
        this.updateRouteDistances(this.multiRoute);
      });

      const routeButton = new ymaps.control.Button({
        data: { content: 'Показать весь маршрут' },
        options: { selectOnClick: true, maxWidth: 200 }
      });

      routeButton.events.add('select', () => {
        this.map.oldBounds = this.map.getBounds();
        this.map.setBounds(this.multiRoute.getBounds());
      });

      routeButton.events.add('deselect', () => {
        this.map.setBounds(this.map.oldBounds);
      });

      this.multiRoute.model.events.add('requestsend', () => this.setLoading(true));
      this.multiRoute.model.events.add('requestsuccess', () => {
        this.setLoading(false);
        const ctrls = this.map.controls;
        if (ctrls.indexOf(trafficButton) === -1) this.map.controls.add(trafficButton);
        if (!this.readOnly) {
          if (ctrls.indexOf(routeButton) === -1) this.map.controls.add(routeButton);
        }
      });
      this.multiRoute.model.events.add('requestfail', () => this.setLoading(false));
    },
    createMultiRoute() {
      if (this.multiRoute) return this.multiRoute.model.setReferencePoints(this.routePoints);
      this.multiRoute = new ymaps.multiRouter.MultiRoute(
        {
          referencePoints: this.routePoints,
          params: {
            results: 2
          }
        },
        {
          wayPointVisible: false,
          viaPointVisible: false
        }
      );
      if (!this.readOnly)
        this.multiRoute.events.add('update', () => this.updateRouteDistances(this.multiRoute));
      this.map.geoObjects.add(this.multiRoute);
    },
    setLoading(val) {
      this.isLoading = val;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

/deep/ .map-dialog {
  background-color: white;
  padding: 16px;
  position: relative;
}
/deep/ .v-toolbar {
  margin: -16px -16px 16px !important;
  width: calc(100% + 32px);
}
.ymap-container {
  height: 600px;
  width: 100%;

  @media screen and (max-width: breakpoint(sm)) {
    height: calc(100% - 16px);
    margin: -16px -16px 0 -16px;
    width: calc(100% + 32px);
  }
}
.close-button {
  position: relative;
  right: -16px;
}
.map-wrapper {
  padding: 24px;
  background: white;

  @media screen and (max-width: breakpoint(sm)) {
    padding: 0;
  }
}
.submit-button {
  margin: 16px 0 0;

  @media screen and (max-width: breakpoint(sm)) {
    width: 100%;
  }
}
.loading {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin: 20px auto;

  span {
    background: white;
    padding: 16px;
  }
}
</style>
