<template>
  <div class="disputes-list">
    <h2 v-if="type === 'monitored' || type === 'devoid_and_my'">{{ disputesType }} диспуты</h2>
    <view-window
      v-if="isWindowShowed"
      :cid="selectedOrderCid"
      to-tab="Dispute"
      :is-opened-tab="type === 'opened'"
      :dispute="activeDispute"
      @close="onClose"
    />
    <table v-else class="table">
      <thead>
        <tr>
          <th>Инициатор</th>
          <th>Ответчик</th>
          <th>Начало диспута</th>
          <th>Сотрудник</th>
          <th>Статус</th>
          <th>ID Заказа</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="dispute in disputes" :key="dispute.cid" @click="onClick(dispute.corder_id)">
          <td>{{ dispute.to_name }}</td>
          <td>{{ dispute.from_name }}</td>
          <td>{{ dispute.disputeStart }}</td>
          <td>{{ dispute.operator_name }}</td>
          <td>{{ dispute.disputeStatus }}</td>
          <td>{{ dispute.order_id }}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="pagesCount >= 2" class="text-md-center mt-5">
      <v-pagination
        v-model="page"
        circle
        :length="pagesCount"
        :total-visible="7"
        @input="getList"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import lightFormat from 'date-fns/lightFormat';
import { getDisputeStatus } from 'common/helpers/dispute';
import ViewWindow from '@/components/common/ViewWindow';

const LIMIT = 10;

export default {
  name: 'DisputesList',
  components: { ViewWindow },
  props: {
    type: String
  },
  data: () => ({
    disputes: [],
    activeDispute: {},
    selectedOrderCid: null,
    isWindowShowed: false,
    page: 1,
    count: 0
  }),
  computed: {
    ...mapGetters('login', ['isShipper']),
    disputesType() {
      return this.type === 'monitored' ? 'Разобранные' : 'Мои и неразобранные';
    },
    pagesCount() {
      return Math.ceil(this.count / LIMIT);
    }
  },
  watch: {
    type: {
      immediate: true,
      handler() {
        this.getList();
        this.isWindowShowed = false;
      }
    }
  },
  methods: {
    ...mapActions('common', ['createController']),
    async getList() {
      const topic = 'disputes/get_disputes_for_operator';
      const json = { type: this.type, page: this.page, limit: LIMIT };
      const { disputes, count } = await this.createController({ topic, json });
      this.count = count;
      this.disputes = disputes.map(_ => ({
        ..._,
        disputeStart: lightFormat(new Date(_.dispute_open), 'dd.MM.yyyy в HH:mm'),
        disputeStatus: getDisputeStatus({ dispute: _, isShipper: this.isShipper })
      }));
    },
    onClick(cid) {
      this.selectedOrderCid = cid;
      this.activeDispute = this.disputes.find(_ => _.corder_id === cid);
      this.isWindowShowed = true;
    },
    onClose() {
      this.isWindowShowed = false;
      this.getList();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';
@include table(20%, $hover: true);

.disputes-list {
  position: relative;
}

.small {
  width: 10% !important;
}
</style>
