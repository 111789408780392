export default [
  {
    region: 37,
    fullname: 'Москва и Московская область',
    okato: '46',
    offname: 'Москва и Московская область',
    regioncode: '50',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 30,
    fullname: 'Алтайский край',
    okato: '01',
    offname: 'Алтайский',
    regioncode: '22',
    shortname: 'край',
    longname: 'край'
  },
  {
    region: 63,
    fullname: 'Амурская область',
    okato: '10',
    offname: 'Амурская',
    regioncode: '28',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 44,
    fullname: 'Архангельская область',
    okato: '11',
    offname: 'Архангельская',
    regioncode: '29',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 1,
    fullname: 'Астраханская область',
    okato: '12',
    offname: 'Астраханская',
    regioncode: '30',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 46,
    fullname: 'Байконур город',
    okato: '55',
    offname: 'Байконур',
    regioncode: '99',
    shortname: 'г',
    longname: 'город'
  },
  {
    region: 6,
    fullname: 'Белгородская область',
    okato: '14',
    offname: 'Белгородская',
    regioncode: '31',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 31,
    fullname: 'Брянская область',
    okato: '15',
    offname: 'Брянская',
    regioncode: '32',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 66,
    fullname: 'Владимирская область',
    okato: '17',
    offname: 'Владимирская',
    regioncode: '33',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 20,
    fullname: 'Волгоградская область',
    okato: '18',
    offname: 'Волгоградская',
    regioncode: '34',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 67,
    fullname: 'Вологодская область',
    okato: '19',
    offname: 'Вологодская',
    regioncode: '35',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 82,
    fullname: 'Воронежская область',
    okato: '20',
    offname: 'Воронежская',
    regioncode: '36',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 8,
    fullname: 'Еврейская автономная область',
    okato: '99',
    offname: 'Еврейская',
    regioncode: '79',
    shortname: 'Аобл',
    longname: 'автономная область'
  },
  {
    region: 42,
    fullname: 'Забайкальский край',
    okato: '76',
    offname: 'Забайкальский',
    regioncode: '75',
    shortname: 'край',
    longname: 'край'
  },
  {
    region: 71,
    fullname: 'Ивановская область',
    okato: '24',
    offname: 'Ивановская',
    regioncode: '37',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 77,
    fullname: 'Иркутская область',
    okato: '25',
    offname: 'Иркутская',
    regioncode: '38',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 56,
    fullname: 'Кабардино-Балкарская республика',
    okato: '83',
    offname: 'Кабардино-Балкарская',
    regioncode: '07',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 76,
    fullname: 'Калининградская область',
    okato: '27',
    offname: 'Калининградская',
    regioncode: '39',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 36,
    fullname: 'Калужская область',
    okato: '29',
    offname: 'Калужская',
    regioncode: '40',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 14,
    fullname: 'Камчатский край',
    okato: '30',
    offname: 'Камчатский',
    regioncode: '41',
    shortname: 'край',
    longname: 'край'
  },
  {
    region: 29,
    fullname: 'Карачаево-Черкесская республика',
    okato: '91',
    offname: 'Карачаево-Черкесская',
    regioncode: '09',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 54,
    fullname: 'Кемеровская область',
    okato: '32',
    offname: 'Кемеровская',
    regioncode: '42',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 39,
    fullname: 'Кировская область',
    okato: '33',
    offname: 'Кировская',
    regioncode: '43',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 52,
    fullname: 'Костромская область',
    okato: '34',
    offname: 'Костромская',
    regioncode: '44',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 16,
    fullname: 'Краснодарский край',
    okato: '03',
    offname: 'Краснодарский',
    regioncode: '23',
    shortname: 'край',
    longname: 'край'
  },
  {
    region: 13,
    fullname: 'Красноярский край',
    okato: '04',
    offname: 'Красноярский',
    regioncode: '24',
    shortname: 'край',
    longname: 'край'
  },
  {
    region: 12,
    fullname: 'Курганская область',
    okato: '37',
    offname: 'Курганская',
    regioncode: '45',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 7,
    fullname: 'Курская область',
    okato: '38',
    offname: 'Курская',
    regioncode: '46',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 40,
    fullname: 'Липецкая область',
    okato: '42',
    offname: 'Липецкая',
    regioncode: '48',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 51,
    fullname: 'Магаданская область',
    okato: '44',
    offname: 'Магаданская',
    regioncode: '49',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 28,
    fullname: 'Мурманская область',
    okato: '47',
    offname: 'Мурманская',
    regioncode: '51',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 60,
    fullname: 'Ненецкий автономный округ',
    okato: '111/118',
    offname: 'Ненецкий',
    regioncode: '83',
    shortname: 'АО',
    longname: 'автономный округ'
  },
  {
    region: 43,
    fullname: 'Нижегородская область',
    okato: '22',
    offname: 'Нижегородская',
    regioncode: '52',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 78,
    fullname: 'Новгородская область',
    okato: '49',
    offname: 'Новгородская',
    regioncode: '53',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 11,
    fullname: 'Новосибирская область',
    okato: '50',
    offname: 'Новосибирская',
    regioncode: '54',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 73,
    fullname: 'Омская область',
    okato: '52',
    offname: 'Омская',
    regioncode: '55',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 55,
    fullname: 'Оренбургская область',
    okato: '53',
    offname: 'Оренбургская',
    regioncode: '56',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 17,
    fullname: 'Орловская область',
    okato: '54',
    offname: 'Орловская',
    regioncode: '57',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 32,
    fullname: 'Пензенская область',
    okato: '56',
    offname: 'Пензенская',
    regioncode: '58',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 57,
    fullname: 'Пермский край',
    okato: '57',
    offname: 'Пермский',
    regioncode: '59',
    shortname: 'край',
    longname: 'край'
  },
  {
    region: 81,
    fullname: 'Приморский край',
    okato: '05',
    offname: 'Приморский',
    regioncode: '25',
    shortname: 'край',
    longname: 'край'
  },
  {
    region: 70,
    fullname: 'Псковская область',
    okato: '58',
    offname: 'Псковская',
    regioncode: '60',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 5,
    fullname: 'Республика Адыгея',
    okato: '79',
    offname: 'Адыгея',
    regioncode: '01',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 80,
    fullname: 'Республика Алтай',
    okato: '84',
    offname: 'Алтай',
    regioncode: '04',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 65,
    fullname: 'Республика Башкортостан',
    okato: '80',
    offname: 'Башкортостан',
    regioncode: '02',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 61,
    fullname: 'Республика Бурятия',
    okato: '81',
    offname: 'Бурятия',
    regioncode: '03',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 10,
    fullname: 'Республика Дагестан',
    okato: '82',
    offname: 'Дагестан',
    regioncode: '05',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 22,
    fullname: 'Республика Ингушетия',
    okato: '26',
    offname: 'Ингушетия',
    regioncode: '06',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 9,
    fullname: 'Республика Калмыкия',
    okato: '85',
    offname: 'Калмыкия',
    regioncode: '08',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 25,
    fullname: 'Республика Карелия',
    okato: '86',
    offname: 'Карелия',
    regioncode: '10',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 64,
    fullname: 'Республика Коми',
    okato: '87',
    offname: 'Коми',
    regioncode: '11',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 49,
    fullname: 'Республика Марий Эл',
    okato: '88',
    offname: 'Марий Эл',
    regioncode: '12',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 47,
    fullname: 'Республика Мордовия',
    okato: '89',
    offname: 'Мордовия',
    regioncode: '13',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 2,
    fullname: 'Республика Саха (Якутия)',
    okato: '98',
    offname: 'Саха /Якутия/',
    regioncode: '14',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 53,
    fullname: 'Республика Северная Осетия — Алания',
    okato: '90',
    offname: 'Северная Осетия - Алания',
    regioncode: '15',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 74,
    fullname: 'Республика Татарстан',
    okato: '92',
    offname: 'Татарстан',
    regioncode: '16',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 41,
    fullname: 'Республика Тыва',
    okato: '93',
    offname: 'Тыва',
    regioncode: '17',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 15,
    fullname: 'Республика Хакасия',
    okato: '95',
    offname: 'Хакасия',
    regioncode: '19',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 75,
    fullname: 'Ростовская область',
    okato: '60',
    offname: 'Ростовская',
    regioncode: '61',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 38,
    fullname: 'Рязанская область',
    okato: '61',
    offname: 'Рязанская',
    regioncode: '62',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 23,
    fullname: 'Самарская область',
    okato: '36',
    offname: 'Самарская',
    regioncode: '63',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 59,
    fullname: 'Санкт-Петербург и Ленинградская область',
    okato: '41',
    offname: 'Санкт-Петербург и Ленинградская область',
    regioncode: '47',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 50,
    fullname: 'Саратовская область',
    okato: '63',
    offname: 'Саратовская',
    regioncode: '64',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 18,
    fullname: 'Сахалинская область',
    okato: '64',
    offname: 'Сахалинская',
    regioncode: '65',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 27,
    fullname: 'Свердловская область',
    okato: '65',
    offname: 'Свердловская',
    regioncode: '66',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 19,
    fullname: 'Севастополь и Крым',
    okato: '35',
    offname: 'Севастополь и Крым',
    regioncode: '91',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 3,
    fullname: 'Смоленская область',
    okato: '66',
    offname: 'Смоленская',
    regioncode: '67',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 69,
    fullname: 'Ставропольский край',
    okato: '07',
    offname: 'Ставропольский',
    regioncode: '26',
    shortname: 'край',
    longname: 'край'
  },
  {
    region: 33,
    fullname: 'Тамбовская область',
    okato: '68',
    offname: 'Тамбовская',
    regioncode: '68',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 34,
    fullname: 'Тверская область',
    okato: '28',
    offname: 'Тверская',
    regioncode: '69',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 68,
    fullname: 'Томская область',
    okato: '69',
    offname: 'Томская',
    regioncode: '70',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 58,
    fullname: 'Тульская область',
    okato: '70',
    offname: 'Тульская',
    regioncode: '71',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 79,
    fullname: 'Тюменская область',
    okato: '71',
    offname: 'Тюменская',
    regioncode: '72',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 21,
    fullname: 'Удмуртская республика',
    okato: '94',
    offname: 'Удмуртская',
    regioncode: '18',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 35,
    fullname: 'Ульяновская область',
    okato: '73',
    offname: 'Ульяновская',
    regioncode: '73',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 4,
    fullname: 'Хабаровский край',
    okato: '08',
    offname: 'Хабаровский',
    regioncode: '27',
    shortname: 'край',
    longname: 'край'
  },
  {
    region: 26,
    fullname: 'Ханты-Мансийский автономный округ - Югра',
    okato: '7110/718',
    offname: 'Ханты-Мансийский Автономный округ - Югра',
    regioncode: '86',
    shortname: 'АО',
    longname: 'автономный округ'
  },
  {
    region: 72,
    fullname: 'Челябинская область',
    okato: '75',
    offname: 'Челябинская',
    regioncode: '74',
    shortname: 'обл',
    longname: 'область'
  },
  {
    region: 83,
    fullname: 'Чеченская республика',
    okato: '96',
    offname: 'Чеченская',
    regioncode: '20',
    shortname: 'Респ',
    longname: 'республика'
  },
  {
    region: 24,
    fullname: 'Чувашская республика',
    okato: '97',
    offname: 'Чувашская Республика -',
    regioncode: '21',
    shortname: 'Чувашия',
    longname: 'республика'
  },
  {
    region: 45,
    fullname: 'Чукотский',
    okato: '77',
    offname: 'Чукотский',
    regioncode: '87',
    shortname: 'АО',
    longname: 'автономный округ'
  },
  {
    region: 62,
    fullname: 'Ямало-Ненецкий автономный округ',
    okato: '7114/719',
    offname: 'Ямало-Ненецкий',
    regioncode: '89',
    shortname: 'АО',
    longname: 'автономный округ'
  },
  {
    region: 48,
    fullname: 'Ярославская область',
    okato: '78',
    offname: 'Ярославская',
    regioncode: '76',
    shortname: 'обл',
    longname: 'область'
  }
];
