import moment from 'common/helpers/date';
import utils from '@/helpers/utils';
import { setField } from 'common/store/helpers';

const state = {
  counts: {},
  items: [],
  info: {},
  orders: [],
  total: 0,
  phoneList: []
};

const actions = {
  async getStats({ dispatch, commit, state }, { enduser }) {
    commit('setField', { field: 'counts', value: {} });
    enduser = enduser || state.info.enduser;
    const topic = 'admin/endusers/stats/get';
    try {
      const data = await dispatch(
        'common/createController',
        { topic, json: { enduser } },
        { root: true }
      );
      const countsData = {
        orders: data.total_orders,
        ratings: data.total_feedbacks,
        active_disputs: data.active_disputes,
        disputs: data.total_disputes,
        notifications: data.total_notifications,
        alerts: data.total_warnings
      };
      commit('setField', { field: 'counts', value: countsData });
    } catch (error) {
      console.error(error);
    }
  },
  async getInfo({ dispatch, commit }, { enduser }) {
    commit('setField', { field: 'info', value: {} });
    try {
      const data = await dispatch(
        'common/createController',
        { topic: 'admin/endusers/info/get', json: { enduser } },
        { root: true }
      );
      dispatch('getStats', { enduser });
      commit('login/setField', { field: 'previewType', value: data.category }, { root: true });
      const name =
        utils.yurs[data.entity] === 'FL'
          ? data.fullname
          : `${data.category === 'trucker' ? data.fullname : data.name}`;
      commit('setField', {
        field: 'info',
        value: {
          ...data,
          yur_type: utils.yurs[data.entity] || '',
          type_title: `${utils.titles[data.category] || ''} ${utils.titles[data.entity] || ''}`,
          title: `${name.trim() || ''}, ${utils.titles[data.category] || ''} ${(utils.yurs[
            data.entity
          ] !== 'IP' &&
            utils.titles[utils.yurs[data.entity]]) ||
            ''}`
        }
      });
      return data;
    } catch (error) {
      commit('errorPush', 'Ошибка при выгрузке с сервера', { root: true });
      console.error(error);
    }
  },
  async sendSearch({ dispatch, commit }, { json, type }) {
    const { list } = await dispatch(
      'common/createController',
      { topic: 'admin/endusers/lookup', json },
      { root: true }
    );
    const mappedList = list.map(item => ({
      ...item,
      date: moment(item.registration_date).format('YYYY-MM-DD HH:mm'),
      yur_type: utils.yurs[item.entity] || '',
      type_title: `${utils.titles[item.category] || ''} ${utils.titles[item.entity] || ''}`,
      title: `${item.fullname || ''}, ${utils.titles[item.category] || ''} ${utils.titles[
        item.yur_type
      ] || ''}`
    }));
    commit('setItems', { items: mappedList, type });
  }
};
const mutations = {
  setField,
  setItems(state, { items, type }) {
    if (type === 'new') state.items = items;
    if (type === 'current') state.items = [...state.items, ...items];
  }
};

const getters = {
  isShipper: state => state.info.category === 'shipper'
};
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
