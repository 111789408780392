<template>
  <div class="stage_truckers">
    <div class="stage_truckers__title">
      Прикреплен
    </div>
    <div class="stage_truckers__table">
      <TableList
        :values="list"
        :show-columns="false"
        :loading="loading('admin/endusers/trucker/attachment/get')"
        :columns="columns_contractor"
        @clickItem="openWindow($event, true)"
      >
        <template slot="ts_count" slot-scope="{ row }">
          <div>
            Авторизованные ТС -
            {{ authorized(row.authorized_vehicles_type, row.authorized_vehicles_count) }}
          </div>
        </template>
        <template slot="name" slot-scope="{ row }">
          <div class="bold">
            {{ row.agent_name }}
          </div>
        </template>
        <template slot="date" slot-scope="{ row }">
          <div>{{ getDate(row.date) }}</div>
        </template>
        <template slot="empty">
          Не прикреплен
        </template>
      </TableList>
    </div>
    <div class="stage_truckers__title">
      Запросы на прикрепление
    </div>
    <div class="stage_truckers__table">
      <TableList
        :values="invites_list"
        :show-columns="false"
        :loading="loading('admin/endusers/trucker/attachment/get')"
        :columns="columns_invite"
        @clickItem="openWindow"
      >
        <template slot="name" slot-scope="{ row }">
          <div class="bold">
            {{ row.name }}
          </div>
        </template>
        <template slot="empty">
          Нет запросов на прикрепление
        </template>
      </TableList>
      <ContractorInfo v-if="showModal" :load-t-s="ts_load" @close="closeWindow" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import moment from 'moment';
import TableList from '../../Layout/TableList';
import ContractorInfo from './ContractorInfo';

export default {
  components: {
    ContractorInfo,
    TableList
  },
  data() {
    return {
      columns_contractor: [
        {
          title: ' ',
          name: 'name',
          width: '40',
          slot: true,
          alignLeft: true
        },
        {
          title: ' ',
          name: 'rules',
          width: '25',
          alignLeft: true
        },
        {
          title: ' ',
          name: 'ts_count',
          width: '25',
          slot: true,
          alignLeft: true
        },
        {
          title: ' ',
          name: 'date',
          width: '10',
          slot: true,
          alignLeft: true
        }
      ],
      columns_invite: [
        {
          title: ' ',
          name: 'name',
          width: '90',
          alignLeft: true,
          slot: true
        },
        {
          title: ' ',
          name: 'date',
          width: '10',
          alignLeft: true
        }
      ],
      showModal: false,
      ts_load: false
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('clientsContractors', ['list', 'invites_list']),
    enduser_id() {
      return this.$route.params.enduser || false;
    }
  },
  mounted() {
    this.getContractors(this.enduser_id);
  },
  methods: {
    getDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },
    ...mapActions('clientsContractors', [
      'getContractors',
      'getContractor',
      'getDriverAuthorizeTS'
    ]),
    async openWindow(item, loadTS) {
      const id = item.id || item.agent;
      await this.getContractor({ enduser: this.enduser_id, contractor: id });
      if (loadTS) {
        await this.getDriverAuthorizeTS(this.enduser_id);
        this.ts_load = true;
      }
      this.showModal = true;
    },
    closeWindow() {
      this.showModal = false;
    },
    authorized(type, count) {
      switch (type) {
        case 'list':
          return count;
        case 'nothing':
          return 'нет';
        case 'all':
          return 'все';
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss">
.stage_truckers {
  position: relative;
  padding: 15px;
  height: 100%;
  .stage_truckers__title {
    font-size: 25px;
    padding: 20px 0;
  }
}
</style>
