import { setCookie } from 'common/helpers/cookieHelper';

const rootHost = process.env.VUE_APP_ROOT_HOST;

export const setUserLoggedIn = (token, fullData = {}) => {
  setCookie({ name: 'sessionKey', value: token, domain: rootHost });
  setCookie({ name: 'loginData', value: JSON.stringify(fullData), domain: rootHost });
  localStorage.setItem('sessionKey', token);
};

export const setUserLoggedOut = () => {
  setCookie({ name: 'sessionKey', value: '', domain: rootHost });
  setCookie({ name: 'loginData', value: '', domain: rootHost });
  localStorage.removeItem('sessionKey');
};
