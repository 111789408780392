import { render, staticRenderFns } from "./PseudoInput.vue?vue&type=template&id=5a478d39&scoped=true&functional=true&"
var script = {}
import style0 from "./PseudoInput.vue?vue&type=style&index=0&id=5a478d39&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../cargo-newadmin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "5a478d39",
  null
  
)

export default component.exports