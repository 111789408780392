<template>
  <v-container
    v-model="validForm"
    grid-list-lg
    class="truckers"
    tag="v-form"
    @submit.prevent="$emit('submit')"
  >
    <VerificationWindowAbstraction
      title="Верификация водителя"
      :is-disabled-verif-button="!validForm || isErrors"
      @close="closeModal"
      @denied-verification="deniedVerification"
      @submit="submitVerification"
    >
      <template slot="content">
        <v-layout row wrap>
          <v-flex xs12>
            <img class="avatar" alt="Avatar" :src="driverPhoto" />
          </v-flex>
          <PhotosQuality
            :is-correct-avatar="isCorrectAvatar"
            :is-correct-turn-passport-photo="isCorrectTurnPassportPhoto"
            :is-correct-turn-passport-registration-photo="isCorrectTurnPassportRegistrationPhoto"
            :is-correct-driver-license-photo="isCorrectDriverLicensePhoto"
            :is-correct-turn-driver-license-photo="isCorrectTurnDriverLicensePhoto"
            @changed="setChangedValue"
          />
          <template v-if="allPhotosIsCorrect">
            <Passport
              :name="name"
              :patronymic="patronymic"
              :lastname="lastname"
              :check-patronymic="checkPatronymic"
              :passport-series="passportSeries"
              :passport-number="passportNumber"
              :passport-date="passportDate"
              :birthday-date="birthdayDate"
              :check-passport-fms="checkPassportFms"
              :is-disabled-birthday="isDisabledBirthday"
              @changed="setChangedValue"
              @copy="copied"
              @copy-error="copyError"
            />
            <License
              :license-series="licenseSeries"
              :license-number="licenseNumber"
              :license-date="licenseDate"
              :check-license-gibdd="checkLicenseGibdd"
              @changed="setChangedValue"
              @copy="copied"
              @copy-error="copyError"
            />
            <MainCheckboxes
              :check-birthday-place-pas-lic="checkBirthdayPlacePasLic"
              :check-birthday-date-pas-lic="checkBirthdayDatePasLic"
              :check-photo="checkPhoto"
              @changed="setChangedValue"
            />
          </template>
        </v-layout>
      </template>

      <TruckerImages slot="slider" :photos="response.photos" :cid="response.cid" />
      <Alert
        v-for="(alert, i) in alerts"
        :key="i"
        slot="alert"
        :message="errorsMap()[alert] || errorsMap()['default']"
        type="danger"
        has-close
        @close="closeAlert"
      />
    </VerificationWindowAbstraction>
    <v-snackbar v-model="snackbar" bottom="" left="" :timeout="2000">
      {{ snackbarMessage }}
      <v-btn color="news" flat="" @click="snackbar = false">
        Закрыть
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { DATE_NULL } from 'common/helpers/dateEnums';
import Alert from 'common/components/Alert';
import defaultImage from '@/assets/img/empty-avatar.jpg';

import VerificationWindowAbstraction from './VerificationWindowAbstraction';
import PhotosQuality from './Trucker/PhotosQuality';
import Passport from './Trucker/Passport';
import License from './Trucker/License';
import MainCheckboxes from './Trucker/MainCheckboxes';
import TruckerImages from './Trucker/TruckerImages';

export default {
  name: 'VerificationWindowTrucker',
  components: {
    VerificationWindowAbstraction,
    PhotosQuality,
    Passport,
    License,
    MainCheckboxes,
    Alert,
    TruckerImages
  },
  props: {
    parentId: String
  },
  data() {
    return {
      snackbar: false,
      snackbarMessage: '',

      defaultImage,

      isCorrectAvatar: false,
      isCorrectTurnPassportPhoto: false,
      isCorrectTurnPassportRegistrationPhoto: false,
      isCorrectDriverLicensePhoto: false,
      isCorrectTurnDriverLicensePhoto: false,

      name: '',
      patronymic: '',
      lastname: '',
      checkPatronymic: false,
      passportSeries: '',
      passportNumber: '',
      passportDate: '',
      birthdayDate: '',
      checkPassportFms: false,
      licenseSeries: '',
      licenseNumber: '',
      licenseDate: '',
      checkLicenseGibdd: false,

      checkBirthdayPlacePasLic: false,
      checkBirthdayDatePasLic: false,
      checkPhoto: false,

      response: {},

      driverPhoto: defaultImage,

      validForm: false,

      alerts: []
    };
  },
  computed: {
    ...mapState({
      defaultURL: state => state.defaultURL,
      cid: state => state.login.cid,
      colors: state => state.colors
    }),
    isErrors() {
      return !!this.alerts.length;
    },
    allPhotosIsCorrect() {
      return [
        this.isCorrectAvatar,
        this.isCorrectTurnPassportPhoto,
        this.isCorrectTurnPassportRegistrationPhoto,
        this.isCorrectDriverLicensePhoto,
        this.isCorrectTurnDriverLicensePhoto
      ].every(value => value === true);
    },
    isDisabledBirthday() {
      return this.response.has_been_verif;
    }
  },
  watch: {
    checkPatronymic(val) {
      if (val) this.patronymic = '';
    }
  },
  created() {
    this.getTruckerInfo();
  },
  methods: {
    closeModal() {
      this.verifyCancel(this.response.cid);
      this.$emit('close');
    },
    ...mapActions('common', ['createController']),
    ...mapActions('verifications', ['verifyCancel']),
    closeAlert(message) {
      const keys = Object.keys(this.errorsMap());
      const field = keys.find(key => this.errorsMap()[key] === message);
      this.alerts = this.alerts.filter(_ => _ !== field);
    },
    errorsMap() {
      return {
        birthday_date: 'Неверная дата рождения',
        passport_date: 'Неверная дата выдачи паспорта',
        license_date: 'Неверная дата выдачи водительского удостоверения',
        not_assigned_verif: 'Верификация не закреплена за вами',
        default: 'Для подтверждения верификационных данных необходмо заполнить обязательные поля'
      };
    },
    async setCheckmark() {
      const topic = 'admin/verification/checkmark';
      const json = {
        cid: this.response.cid,
        check_passport_fms: this.checkPassportFms,
        check_license_gibdd: this.checkLicenseGibdd,
        check_birthday_place_pas_lic: this.checkBirthdayPlacePasLic,
        check_birthday_date_pas_lic: this.checkBirthdayDatePasLic,
        check_photo: this.checkPhoto
      };
      try {
        await this.createController({ topic, json });
      } catch (error) {
        this.alerts.push('not_assigned_verif');
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async getTruckerInfo() {
      const topic = 'admin/verification/choose';
      const json = { cparent_id: this.parentId };
      try {
        this.response = await this.createController({ topic, json });
        if (this.response.has_been_verif)
          this.birthdayDate = moment(this.response.passport_birthday).format('YYYY-MM-DD');
        this.setAvatar();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async deniedVerification(comment) {
      const topic = 'admin/verification/denial';
      const json = {
        cid: this.response.cid,
        comment,
        ...this.profilePayload()
      };
      try {
        await this.createController({ topic, json });
        this.$emit('close');
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async submitVerification() {
      const topic = 'admin/verification/approve';
      const json = {
        cid: this.response.cid,
        ...this.profilePayload()
      };
      try {
        await this.setCheckmark();
        await this.createController({ topic, json });
        this.$emit('close');
      } catch (error) {
        this.alerts.push(...error.missing_data);
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    setAvatar() {
      const avatarURL = this.response.photos.find(_ => _.type === 'my_photo').medium;
      this.driverPhoto = `${this.defaultURL}/files/${this.cid}/${this.response.cid}/${avatarURL}`;
    },
    setChangedValue({ field, value }) {
      this.alerts = [];
      this[field] = value;
    },
    copied(e) {
      this.snackbar = true;
      const isDate = !!e.trigger.previousSibling?.classList.contains('calendar');
      this.snackbarMessage = isDate
        ? `Скопировано: ${moment(new Date(e.text)).format('DD.MM.YYYY')}`
        : `Скопировано: ${e.text}`;
    },
    copyError(e) {
      this.snackbar = true;
      this.snackbarMessage = `Не скопировано: ${e.text}`;
    },
    profilePayload() {
      return {
        profile: {
          name: this.name,
          lastname: this.lastname,
          patronymic: this.patronymic,
          without_patronymic: this.checkPatronymic,
          passport_series: this.passportSeries,
          passport_number: this.passportNumber,
          passport_date: moment(new Date(this.passportDate)).toISOString() || DATE_NULL,
          passport_birthday: moment(new Date(this.birthdayDate)).toISOString() || DATE_NULL,
          license_series: this.licenseSeries,
          license_number: this.licenseNumber,
          license_date: moment(new Date(this.licenseDate)).toISOString() || DATE_NULL,
          photo_avatar: this.isCorrectAvatar,
          photo_passport_registration: this.isCorrectTurnPassportRegistrationPhoto,
          photo_passport_photo: this.isCorrectTurnPassportPhoto,
          photo_driver_license_photo: this.isCorrectDriverLicensePhoto,
          photo_driver_license_back: this.isCorrectTurnDriverLicensePhoto
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';
.truckers {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  margin: 0;
  max-width: unset;
  padding: 0;
  height: 100%;
  &__row {
    display: flex;

    &__row__field {
      max-width: 50%;
      display: flex;
    }
    .row__link {
      color: $color-main;
      text-decoration: underline;
      cursor: pointer;
      padding: 15px 21px 0 0;

      &--fms {
        padding-right: 37px;
      }
    }
    .row__copy {
      color: $color-main;
    }
    .row__checkbox {
      max-width: 20px;
      margin: 10px 10px 0 0;
      .v-icon {
        color: $color-main;
      }
    }
  }

  .status {
    position: absolute;
    top: 10px;
    right: 30px;
  }

  .avatar {
    margin-right: 20px;
    width: auto;
    height: 200px;
    border-radius: 50%;
  }

  /deep/.v-input--checkbox {
    margin: 0;
    /deep/.v-input__slot {
      margin: 0;
    }
    /deep/.v-input__prepend-outer {
      margin-top: 5px;
    }
  }
  /deep/.custom-input .v-input--checkbox .v-input__control,
  /deep/.custom-input .v-input--checkbox .v-input__slot {
    height: 100%;
  }
  /deep/.layout {
    flex-grow: 0;
  }

  /deep/.alert {
    margin: auto auto 0 auto;
    width: calc(100% - 16px);
  }
}
</style>
