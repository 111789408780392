const state = {
  groups: []
};

const actions = {
  async getGroups({ dispatch, commit }) {
    try {
      const { groups } = await dispatch(
        'common/createController',
        {
          topic: 'adm_circular/get_groups'
        },
        { root: true }
      );
      const newGroups = Object.keys(groups).map(_ => ({
        name: _,
        title: groups[_]
      }));
      commit('setGroups', newGroups);
    } catch (error) {
      console.error('Ошибка в запросе adm_circular/get_groups');
    }
  }
};

const mutations = {
  setGroups(state, data) {
    state.groups = data;
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
