import orderInfo from './orderInfo';
import gps from './gps';
import { transportApi, TRANSPORT_VERSION, TRANSPORT_SCHEME } from 'common/api/TransportApi';

const state = {
  scheme: {}
};

const getters = {
  getSchemeField: state => field => state.scheme[field],
  contactInfo: state => [state.orderInfo.offerInfo.user]
};

const actions = {
  async getScheme({ dispatch, commit, state }) {
    if (Object.keys(state.scheme).length) {
      return;
    }

    try {
      const { settings } = await transportApi(this.dispatch).transportGetVersionWithScheme();

      commit('setScheme', settings);
    } catch (error) {
      console.error(`Ошибка в запросе ${TRANSPORT_VERSION} или в ${TRANSPORT_SCHEME}`, error);
    }
  }
};

const mutations = {
  setScheme(state, scheme) {
    state.scheme = scheme;
  }
};

export default {
  namespaced: true,
  modules: {
    orderInfo,
    gps
  },
  state,
  getters,
  actions,
  mutations
};
