<template>
  <div v-if="info.balance" class="counting">
    <div class="counting__row">
      <div class="counting__row__field">
        <v-text-field
          label="Период c"
          :value="input.from"
          readonly="readonly"
          @focus="datePickerToggle('from')"
        />
        <v-icon class="row__date_picker" @click="datePickerToggle('from')">
          date_range
        </v-icon>
        <div class="counting__row__date_picker">
          <v-date-picker
            v-if="openPicker.from"
            v-model="picker.from"
            :landscape="true"
            :max="pickerPeriod.from.max"
            :reactive="true"
          />
        </div>
      </div>
      <div class="counting__row__field">
        <v-text-field
          label="По"
          :value="input.to"
          readonly="readonly"
          @focus="datePickerToggle('to')"
        />
        <v-icon class="row__date_picker" @click="datePickerToggle('to')">
          date_range
        </v-icon>
        <div class="counting__row__date_picker to">
          <v-date-picker
            v-if="openPicker.to"
            v-model="picker.to"
            :landscape="true"
            :reactive="true"
            :min="pickerPeriod.to.min"
            :max="pickerPeriod.to.max"
          />
        </div>
      </div>
      <div class="counting__row__field">
        <div class="payment_options">
          <div class="payment_options__title">
            Платежи
          </div>
          <select v-model="category" class="payment_options__select" @change="getData">
            <option
              v-for="(categoryItem, index) in categories"
              :key="index"
              :value="categoryItem.value"
            >
              {{ categoryItem.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="counting__row__field">
        <div class="payment_options">
          <div class="payment_options__title">
            Операции
          </div>
          <select v-model="operation" class="payment_options__select" @change="getData">
            <option
              v-for="(operationItem, index) in operations"
              :key="index"
              :value="operationItem.value"
            >
              {{ operationItem.title }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="counting__row">
      <div class="counting__row__field">
        <v-text-field
          :value="info.balance.balance_from || 0"
          label="Сальдо на начало периода"
          readonly="readonly"
        />
      </div>
      <div class="counting__row__field">
        <v-text-field
          :value="info.balance.balance_to || 0"
          label="Сальдо на конец периода"
          readonly="readonly"
        />
      </div>
    </div>
    <div class="counting__row">
      <div class="counting__row__field">
        <v-text-field
          :value="info.turnovers.deposit || 0"
          label="Приход за период"
          readonly="readonly"
        />
      </div>
      <div class="counting__row__field">
        <v-text-field
          :value="info.turnovers.withdraw || 0"
          label="Расход за период"
          readonly="readonly"
        />
      </div>
      <div class="counting__row__field">
        <v-text-field
          :value="info.turnovers.deposit_with_filter || 0"
          label="Приход за период с учетом фильтров"
          readonly="readonly"
        />
      </div>
      <div class="counting__row__field">
        <v-text-field
          :value="info.turnovers.withdraw_with_filter || 0"
          label="Расход за период с учетом фильтров"
          readonly="readonly"
        />
      </div>
    </div>
    <div class="row__table">
      <TableList :columns="columns" :values="list" @clickItem="openWindow">
        <template slot="amount" slot-scope="{ row }">
          <div v-if="row.amount < 0" class="debet">
            {{ row.amount }}
          </div>
          <div v-else class="credit">
            {{ row.amount }}
          </div>
        </template>
      </TableList>
    </div>
    <div v-if="dialogDenied" class="counting__modal">
      <v-toolbar dark="dark" color="primary">
        <v-toolbar-title>{{ item.date }}</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon="icon" dark="dark" @click="dialogDenied = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="modal__content">
        <div class="content__row">
          <div class="row__field width_70">
            <v-text-field v-model="item.purpose" label="Назначение платежа" readonly="readonly" />
          </div>
          <div class="row__field width_30">
            <v-text-field v-model="item.amount" label="Сумма" readonly="readonly" />
          </div>
        </div>
        <div class="content__row">
          <div class="row__field width_20">
            <v-text-field v-model="item.inn" label="ИНН контрагента" readonly="readonly" />
          </div>
          <div class="row__field width_20">
            <v-text-field
              v-model="item.category"
              label="Категория контрагента"
              readonly="readonly"
            />
          </div>
          <div class="row__field width_30">
            <v-text-field
              v-model="item.name_by_bank"
              label="Контрагент по данным банка"
              readonly="readonly"
            />
          </div>
          <div class="row__field width_30">
            <v-text-field
              v-model="item.name"
              label="Контрагент по данным сервиса"
              readonly="readonly"
            />
          </div>
        </div>
        <div class="content__row">
          <div class="row__field width_20">
            <v-text-field v-model="item.type" label="Тип операции" readonly="readonly" />
          </div>
          <div class="row__field width_20">
            <v-text-field
              v-model="item.number"
              label="№ операции по выписке клиента"
              readonly="readonly"
            />
          </div>
          <div class="row__field width_20">
            <v-text-field v-model="item.phone" label="Телефон регистрации" readonly="readonly" />
          </div>
        </div>
        <div class="content__row">
          <v-btn large="large" color="primary" @click="dialogDenied = false">
            Закрыть
          </v-btn>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="loading">
    <v-progress-circular
      v-if="loading('adm_buch/statement')"
      indeterminate="indeterminate"
      color="primary"
      :size="20"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';
import TableList from '../Layout/TableList.vue';

export default {
  components: {
    TableList
  },
  data() {
    return {
      openPicker: {
        from: false,
        to: false
      },
      picker: {
        from: moment()
          .subtract('days', 7)
          .format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      },
      input: {
        from: moment()
          .subtract('days', 7)
          .format('DD.MM.YYYY'),
        to: moment().format('DD.MM.YYYY')
      },
      categories: [
        {
          title: 'Все',
          value: 'all'
        },
        {
          title: 'Входящие',
          value: 'Debet'
        },
        {
          title: 'Исходящие',
          value: 'Credit'
        }
      ],
      category: 'all',
      operations: [
        {
          title: 'Все',
          value: 'all'
        },
        {
          title: 'Возврат',
          value: 'refund'
        },
        {
          title: 'Пополнение',
          value: 'refill'
        },
        {
          title: 'Верификация',
          value: 'verification'
        },
        {
          title: 'Прочее',
          value: 'other'
        }
      ],
      operation: 'all',
      balanceBegin: '',
      balanceEnd: '',
      coming: '',
      expense: '',
      comingWithFilter: '',
      expenseWithFilter: '',
      columns: [
        {
          title: 'Дата платежа',
          name: 'date',
          width: '10',
          alignLeft: true
        },
        {
          title: 'Назначение платежа',
          name: 'purpose',
          width: '40',
          alignLeft: true
        },
        {
          title: 'Сумма, руб.',
          name: 'amount',
          width: '10',
          alignLeft: true,
          slot: true
        },
        {
          title: 'Наименование контрагента',
          name: 'name',
          width: '30',
          alignLeft: true
        },
        {
          title: 'Операция',
          name: 'type',
          width: '10',
          alignLeft: true
        }
      ],
      dialogDenied: false,
      deniedText: ''
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('counting', ['info']),
    list() {
      return this.info.data;
    },
    pickerPeriod() {
      return {
        from: {
          max: moment(this.picker.to).format('YYYY-MM-DD')
        },
        to: {
          min: moment(this.picker.from).format('YYYY-MM-DD'),
          max: moment().format('YYYY-MM-DD')
        }
      };
    }
  },
  watch: {
    'picker.from': {
      handler(val) {
        this.updateInput(val, 'from');
      }
    },
    'picker.to': {
      handler(val) {
        this.updateInput(val, 'to');
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions('counting', ['getInfo']),
    updateInput(val, type) {
      this.input[type] = moment(val).format('DD.MM.YYYY');
      if (this.input.from && this.input.to) {
        this.getData();
        return;
      }
      this.openPicker[type] = false;
    },
    datePickerToggle(type) {
      if (type === 'to') {
        this.openPicker.from = false;
      } else {
        this.openPicker.to = false;
      }
      this.openPicker[type] = !this.openPicker[type];
    },
    openWindow(data) {
      this.dialogDenied = !this.dialogDenied;
      this.item = data;
    },
    getData() {
      this.openPicker = {
        from: false,
        to: false
      };
      const json = {
        from: this.picker.from
          ? new Date(this.picker.from)
          : new Date(new Date() - 180 * 24 * 60 * 60 * 1000),
        to: this.picker.to ? new Date(this.picker.to) : new Date()
      };
      if (this.operation !== 'all') {
        json.type_operation = this.operation;
      }
      if (this.category !== 'all') {
        json.category = this.category;
      }
      this.getInfo(json);
    }
  }
};
</script>

<style lang="scss">
.counting {
  height: 100%;
  padding: 10px;

  .counting__modal {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    position: absolute;
    height: auto;
    width: 100%;
    background-color: #fafafa;
    top: 0;
    left: 0;

    .modal__content {
      padding: 20px;
      .content__row {
        display: flex;

        .row__field {
          margin: 0 10px;
        }
      }
    }

    .content__row {
      display: flex;
    }
  }
  .row__table {
    width: 100%;
    height: calc(100% - 210px);
    overflow: auto;

    .credit {
      text-align: left;
      color: green;
    }
    .debet {
      text-align: right;
      color: red;
    }
  }
  .counting__row {
    height: 70px;
    position: relative;
    display: flex;

    .counting__row__field {
      display: flex;
      margin-left: 20px;
      position: relative;
      width: 100%;

      .counting__row__date_picker {
        position: absolute;
        z-index: 3;
        top: 50px;
      }
    }
  }
}
</style>
