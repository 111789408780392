<template>
  <div class="notify">
    <div class="notify__search">
      <custom-input v-model="searchValue" label="Найти" />
    </div>
    <div v-if="isLoading" class="text-center">
      <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
    </div>
    <template v-else>
      <div v-for="(item, index) in filteredList()" :key="index" class="notify__row">
        <div class="row__date">
          {{ getDate(item.date) }}
        </div>
        <div class="row__title">#{{ item.number }}</div>
        <div class="row__text">
          {{ item.text }}
        </div>
      </div>
      <div v-if="pagesCount >= 2" class="text-md-center mb-3">
        <v-pagination
          v-model="page"
          :length="pagesCount"
          :total-visible="7"
          @input="getNotifications"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'common/helpers/date';

export default {
  name: 'TabCurrentNotifications',
  data() {
    return {
      searchValue: '',
      page: 1
    };
  },
  computed: {
    ...mapState('clientsNotifications', ['notifications', 'count', 'limit']),
    ...mapState('clients', ['info', 'counts']),
    ...mapGetters('common', ['loading']),
    isLoading() {
      return this.loading('admin/endusers/notifications/list');
    },
    pagesCount() {
      return Math.ceil(this.count / this.limit);
    }
  },
  created() {
    this.getNotifications();
  },
  methods: {
    ...mapActions('clientsNotifications', ['getNotifications']),
    getDate(date) {
      return moment(date).format('DD.MM.YYYY в HH:mm');
    },
    filteredList() {
      if (!this.searchValue) return this.notifications;
      return this.notifications.filter(
        item =>
          item.text.includes(this.searchValue) ||
          item.number.toString().includes(this.searchValue) ||
          item.date.includes(this.searchValue)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.notify {
  height: 100%;
  overflow-y: auto;

  .notify__search {
    padding-left: 10px;
    width: 30%;
    display: flex;
  }
  .notify__row {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 20px 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);

    .row__title {
      padding: 5px;
      font-weight: bold;
    }
    .row__date {
      padding: 5px;
      float: right;
    }
  }
}
</style>
