import lightFormat from 'date-fns/lightFormat';
import { getUTCDate } from 'common/helpers/dateHelper';
import { YEAR_INFINITE } from 'common/helpers/dateEnums';

const statusMap = {
  unverified: 'Не верифицирован',
  is_on_verification: 'На верификации',
  denied: 'Отказ в верификации',
  waiting_for_verification: 'Ожидает верификации',
  incomplete_data: 'Неполные данные'
};

export function getVerificationStatus(item) {
  const status = item.verif_status;
  let verifiedText = '';
  let verifiedDate = '';
  if (item.verif_until) {
    const tempDate = getUTCDate(item.verif_until);
    const date =
      tempDate.getFullYear() !== YEAR_INFINITE ? lightFormat(tempDate, 'dd.MM.yyyy') : '';
    verifiedText = `Верифицирован ${tempDate.getFullYear() !== YEAR_INFINITE ? 'до' : ''}`;
    verifiedDate =
      item.verif_status === 'verified'
        ? date
        : lightFormat(getUTCDate(item.verif_date), 'dd.MM.yyyy');
  }
  return {
    class: status,
    date: ['verified', 'denied'].includes(item.verif_status) ? verifiedDate : '',
    text: status === 'verified' ? verifiedText : statusMap[status]
  };
}

const getAddressIndex = ({ address, withProvince = false }) => {
  const addressesKinds = address.map(({ kind = '' }) => kind).filter(_ => Boolean(_));
  const isLocality = addressesKinds.includes('locality');

  if (isLocality && !withProvince) {
    return address.findIndex(_ => _.kind === 'locality');
  } else {
    const index = address.findIndex((_, i) => {
      const next = i + 1;
      return _.kind === 'province' && address[next]?.kind !== 'province';
    });

    return !!address[index + 1] && !withProvince ? index + 1 : index;
  }
};

export function getCityName(point) {
  const { address } = point;
  const index = getAddressIndex({ address });

  return address[index]?.name || '';
}

export function getCityAndProvince(point) {
  const { address } = point;
  const provinceIndex = getAddressIndex({ address, withProvince: true });
  const province = address[provinceIndex]?.name || '';
  const city = getCityName(point);

  return province === city ? city : `${province}, ${city}`;
}

export function getFullAddress(point, withProvince = false) {
  const { address } = point;
  const index = getAddressIndex({ address, withProvince });

  const names = address
    .filter((_, i) => i >= index)
    .map(_ => _?.name || '')
    .filter(_ => Boolean(_));

  return [...new Set(names)].join(', ');
}
