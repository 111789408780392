<template>
  <div v-if="offersList.length" class="orders">
    <view-window v-if="isWindowShowed" :cid="selectedOrderCid" @close="isWindowShowed = false" />
    <template v-else>
      <CarrierActivityItem
        v-for="(order, index) in offersList"
        :key="order.id"
        is-operator
        :value="order"
        my-offer
        :class="{ 'mt-0': index === 0 }"
        @click.native="onClick(order.corder_id)"
      />
    </template>
  </div>
  <stub v-else message="Нет предложений" />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Stub from 'common/components/Stub';
import ViewWindow from '@/components/common/ViewWindow';
import CarrierActivityItem from 'common/components/Orders/CarrierActivityItem';

export default {
  name: 'OffersTab',
  components: {
    Stub,
    ViewWindow,
    CarrierActivityItem
  },
  data() {
    return {
      selectedOrderCid: null,
      isWindowShowed: null
    };
  },
  computed: {
    ...mapState('clientOrders', ['offersList'])
  },
  created() {
    this.getOffersList();
  },
  methods: {
    ...mapActions('clientOrders', ['getOffersList']),
    onClick(cid) {
      this.selectedOrderCid = cid;
      this.isWindowShowed = true;
    }
  }
};
</script>

<style lang="scss">
.offers_tab {
  .offers_tab__box {
    padding: 10px;
    margin: 20px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);

    .box__row {
      display: flex;

      .box__row__field {
        margin: 0 10px;
        span {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
