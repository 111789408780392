var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stage_truckers"},[_c('div',{staticClass:"stage_truckers__title"},[_vm._v(" Прикреплен ")]),_c('div',{staticClass:"stage_truckers__table"},[_c('TableList',{attrs:{"values":_vm.list,"show-columns":false,"loading":_vm.loading('admin/endusers/trucker/attachment/get'),"columns":_vm.columns_contractor},on:{"clickItem":function($event){return _vm.openWindow($event, true)}},scopedSlots:_vm._u([{key:"ts_count",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" Авторизованные ТС - "+_vm._s(_vm.authorized(row.authorized_vehicles_type, row.authorized_vehicles_count))+" ")])]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"bold"},[_vm._v(" "+_vm._s(row.agent_name)+" ")])]}},{key:"date",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.getDate(row.date)))])]}}])},[_c('template',{slot:"empty"},[_vm._v(" Не прикреплен ")])],2)],1),_c('div',{staticClass:"stage_truckers__title"},[_vm._v(" Запросы на прикрепление ")]),_c('div',{staticClass:"stage_truckers__table"},[_c('TableList',{attrs:{"values":_vm.invites_list,"show-columns":false,"loading":_vm.loading('admin/endusers/trucker/attachment/get'),"columns":_vm.columns_invite},on:{"clickItem":_vm.openWindow},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"bold"},[_vm._v(" "+_vm._s(row.name)+" ")])]}}])},[_c('template',{slot:"empty"},[_vm._v(" Нет запросов на прикрепление ")])],2),(_vm.showModal)?_c('ContractorInfo',{attrs:{"load-t-s":_vm.ts_load},on:{"close":_vm.closeWindow}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }