<template>
  <CarcassesFormAbstraction
    ref="bodyWorkFormChr"
    :is-edit-form="isEditForm"
    :is-add-form="isAddForm"
    @submit="addBodyWorkChr"
    @remove="removeBodyWorkChr"
    @close="$emit('close')"
  >
    <v-flex xs9>
      <custom-input
        v-model="form.senderChrName"
        label="Название характеристики для ГВ"
        :rules="[v => !!v || 'Поле обязательно для заполнения']"
      />
    </v-flex>
    <v-flex xs3>
      <v-checkbox v-model="form.isRequiredSenderChrName" label="Обязательно для ГВ" />
    </v-flex>
    <v-flex xs12>
      <custom-input v-model="form.tooltipSenderChr" label="Подсказка для ГВ" />
    </v-flex>
    <v-flex xs9>
      <custom-input
        v-model="form.carrierChrName"
        label="Название характеристики для ГП"
        :rules="[v => !!v || 'Поле обязательно для заполнения']"
      />
    </v-flex>
    <v-flex xs3>
      <v-checkbox v-model="form.isRequiredCarrierChrName" label="Обязательно для ГП" />
    </v-flex>
    <v-flex xs12>
      <custom-input v-model="form.tooltipCarrierChr" label="Подсказка для ГП" />
    </v-flex>
    <v-flex xs4>
      <custom-input
        v-model="form.accuracy"
        label="Точность"
        :rules="[v => !!v || 'Поле обязательно для заполнения']"
      />
    </v-flex>
    <v-flex xs4>
      <custom-input
        v-model="form.unitMeasure"
        label="Ед. изм."
        :rules="[v => !!v || 'Поле обязательно для заполнения']"
      />
    </v-flex>
    <v-flex xs4>
      <custom-input
        v-model="form.type"
        label="Тип"
        :rules="[v => !!v || 'Поле обязательно для заполнения']"
      />
    </v-flex>
  </CarcassesFormAbstraction>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CarcassesFormAbstraction from './CarcassesFormAbstraction';

const createForm = (info = {}) => ({
  senderChrName: info?.shipper?.name ?? '',
  isRequiredSenderChrName: info?.shipper?.required ?? false,
  tooltipSenderChr: info?.shipper?.comment ?? '',
  carrierChrName: info?.carrier?.name ?? '',
  isRequiredCarrierChrName: info?.carrier?.required ?? false,
  tooltipCarrierChr: info?.carrier?.comment ?? '',
  accuracy: info?.quantity ?? '',
  // eslint-disable-next-line camelcase
  unitMeasure: info?.unit_measure ?? '',
  type: info?.type ?? '',
  id: info?.id ?? ''
});

export default {
  name: 'BodyWorksChrForm',
  components: {
    CarcassesFormAbstraction
  },
  props: {
    isEditForm: Boolean,
    isAddForm: Boolean,
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: createForm()
    };
  },
  computed: {
    ...mapState('carcasses', ['currentVersionId'])
  },
  watch: {
    info: {
      deep: true,
      immediate: true,
      handler(value) {
        this.form = createForm(value);
      }
    }
  },
  methods: {
    ...mapActions('common', ['createController']),
    async addBodyWorkChr() {
      const topic = 'admin/administration/carcasses/properties/update';
      if (!this.$refs.bodyWorkFormChr.validate()) return;
      try {
        const json = {
          id: this.currentVersionId,
          record: {
            carrier: {
              name: this.form.carrierChrName,
              required: this.form.isRequiredCarrierChrName,
              comment: this.form.tooltipCarrierChr
            },
            shipper: {
              name: this.form.senderChrName,
              required: this.form.isRequiredSenderChrName,
              comment: this.form.tooltipSenderChr
            },
            quantity: Number(this.form.accuracy),
            unit_measure: this.form.unitMeasure
          }
        };
        if (this.form.id.length) json.gid = this.form.id;
        await this.createController({ topic, json });
        this.resetForm();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async removeBodyWorkChr() {
      const topic = 'admin/administration/carcasses/properties/remove';
      try {
        const json = {
          id: this.currentVersionId,
          gid: this.form.id
        };
        await this.createController({ topic, json });
        this.resetForm();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    resetForm() {
      this.$refs.bodyWorkFormChr.reset();
      this.$emit('close');
      this.$emit('update');
    }
  }
};
</script>
