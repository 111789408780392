<template>
  <div class="faq_tab">
    <Tabs :tabs="tabs" :active="activeTab" @change="changeTab" />
    <div class="faq_tab__btn" @click="dialogDenied = true">
      <v-icon class="tab__btn__icon">
        add_circle_outline
      </v-icon>
      <div class="btn__text">
        Добавить
      </div>
    </div>
    <div class="faq_tab__table">
      <TableList :values="list" :columns="columns">
        <template slot="buttons" slot-scope="{ row }">
          <div class="table__swap_buttons">
            <v-icon class="swap_button" @click="swapBottom(row.number)">
              vertical_align_bottom
            </v-icon>
            <v-icon class="swap_button" @click="swapTop(row.number)">
              vertical_align_top
            </v-icon>
          </div>
        </template>
        <template slot="question" slot-scope="{ row }">
          <div class="tab__table__question" @click="openWindow(row)">
            {{ row.question }}
          </div>
        </template>
      </TableList>
    </div>
    <div v-if="dialogDenied" class="editor">
      <v-text-field v-model="item.question" label="Вопрос" />
      <div class="editor__area">
        <div class="area__title">
          Ответ на вопрос
        </div>
        <vue-editor
          id="editor"
          v-model="item.answer"
          :editor-toolbar="toolbarOptions"
          use-custom-image-handler="useCustomImageHandler"
          @imageAdded="handleImageAdded"
        />
      </div>
      <v-btn
        class="ui-kit__button ui-kit__button-large ui-kit__button-lower ui-kit__button-default"
        color="primary"
        large="large"
        @click="changeQuestion('save')"
      >
        {{ item.number === undefined ? 'Добавить' : 'Сохранить' }}
      </v-btn>
      <v-btn
        v-if="item.number !== undefined"
        class="ui-kit__button ui-kit__button-large ui-kit__button-lower ui-kit__button-default"
        color="primary"
        large="large"
        @click="changeQuestion('delete')"
      >
        Удалить
      </v-btn>
      <v-btn
        class="ui-kit__button ui-kit__button-outline ui-kit__button-lower ui-kit__button-default"
        large="large"
        outline="outline"
        color="primary"
        @click.stop="closeWindow"
      >
        Отменить
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { VueEditor } from 'vue2-editor';
import axios from 'axios';
import TableList from '../Layout/TableList.vue';
import Tabs from '../Layout/Tabs.vue';

export default {
  name: 'TabFAQ',
  components: {
    Tabs,
    TableList,
    VueEditor
  },
  data() {
    return {
      tabs: [
        { name: 'shipperUL', title: 'Грузовладельцы ЮЛ или ИП' },
        { name: 'shipperFL', title: 'Грузовладельцы ФЛ' },
        { name: 'carrierUL', title: 'Перевозчики ЮЛ или ИП' },
        { name: 'carrierFL', title: 'Перевозчики ФЛ' },
        { name: 'truckers', title: 'Водители (мобильное приложение)' }
      ],
      columns: [
        {
          name: 'question',
          title: 'Список вопросов',
          width: 90,
          alignLeft: true,
          slot: true
        },
        {
          name: 'buttons',
          title: ' ',
          width: 10,
          slot: true
        }
      ],
      item: {
        question: '',
        answer: ''
      },
      dialogDenied: false
    };
  },
  computed: {
    ...mapState({
      cid: state => state.login.cid,
      defaultURL: state => state.defaultURL
    }),
    ...mapGetters('common', ['loading']),
    ...mapState('contentManagement', ['questionsList']),
    list() {
      return this.questionsList[this.activeTab] || [];
    },
    activeTab() {
      return this.$route.params.tab || 'shipperUL';
    }
  },
  created() {
    this.toolbarOptions = [
      [{ header: [false, 1, 2, 3] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }],
      ['link', 'image', 'video'],
      ['clean']
    ];
  },
  mounted() {
    this.getQuestionsList();
  },
  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      const formData = new FormData();
      formData.append('cid', this.cid);
      formData.append('type', 'global');
      formData.append('file', file);
      axios({
        url: `${this.defaultURL}/upload`,
        method: 'POST',
        data: formData
      })
        .then(result => {
          if (result.data && result.data.photos) {
            const url = `/files/common/${result.data.photos[0].file_id}`;
            Editor.insertEmbed(cursorLocation, 'image', url);
            resetUploader();
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    ...mapActions('contentManagement', ['getQuestionsList', 'saveQuestions']),
    changeTab(tab) {
      this.$router.push({ name: 'ContentManagementTabs', params: { stage: 'faq', tab } });
    },
    openWindow(data) {
      this.item = data;
      this.dialogDenied = true;
    },
    closeWindow() {
      this.item = {
        question: '',
        answer: ''
      };
      this.dialogDenied = false;
    },
    changeQuestion(type) {
      const index = this.list.indexOf(this.item);
      if (index !== -1) {
        if (type === 'delete') {
          this.questionsList[this.activeTab].splice(index, 1);
        } else {
          this.questionsList[this.activeTab][index] = this.item;
        }
      } else {
        this.item.number = this.questionsList[this.activeTab].length;
        this.questionsList[this.activeTab].push(this.item);
      }
      this.saveQuestions();
      this.closeWindow();
    },
    swapBottom(index) {
      if (index < this.list.length - 1) {
        const item = this.questionsList[this.activeTab][index];
        item.number++;
        this.questionsList[this.activeTab][index] = this.questionsList[this.activeTab][index + 1];
        this.questionsList[this.activeTab][index].number--;
        this.questionsList[this.activeTab][index + 1] = item;
        this.questionsList[this.activeTab] = [...this.questionsList[this.activeTab]];
        this.saveQuestions();
      }
    },
    swapTop(index) {
      if (index) {
        const item = this.questionsList[this.activeTab][index];
        item.number--;
        this.questionsList[this.activeTab][index] = this.questionsList[this.activeTab][index - 1];
        this.questionsList[this.activeTab][index].number++;
        this.questionsList[this.activeTab][index - 1] = item;
        this.questionsList[this.activeTab] = [...this.questionsList[this.activeTab]];
        this.saveQuestions();
      }
    }
  }
};
</script>

<style lang="scss">
.ql-snow .ql-editor {
  font-family: 'Roboto';
  font-size: 14px;
  padding: 16px 28px;
  ul {
    padding-left: 24px;
    li {
      list-style-type: disc;
      padding-left: 0 !important;
      font-size: 14px;
      &:before {
        display: none !important;
      }
    }
  }
  img {
    max-width: 100%;
    width: auto;
    max-height: 500px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 16px;
    margin-bottom: 8px;
    color: #111111;
  }

  h1 {
    font-weight: 400;
    font-size: 28px;
    line-height: 44px;
  }

  h2 {
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
  }

  h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }

  h5 {
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
  }

  h6 {
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
  }

  p {
    line-height: 1.5;
    font-size: 14px;
    color: #111111;
  }
}

.ui-kit__dialog__title {
  line-height: 34px !important;
}
.faq_tab {
  position: relative;

  .editor {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    position: absolute;
    width: 100%;
    z-index: 2;
    background-color: #fafafa;
    top: 5px;
    padding: 15px;

    .editor__area {
      .area__title {
        display: inline-block;
        &:hover {
          color: #169bd5;
        }
      }
    }
  }
  .faq_tab__btn {
    display: flex;
    padding: 10px;
    width: 120px;
    cursor: pointer;
    color: #169bd5;

    .tab__btn__icon {
      color: #169bd5;
    }
    .btn__text {
      padding-left: 5px;
    }
  }
  .faq_tab__table {
    padding: 15px;

    .tab__table__question {
      height: 100%;
    }
    .table__swap_buttons {
      display: flex;

      .swap_button {
        margin-left: 20px;
        border-radius: 50%;
        padding: 5px;

        &:hover {
          background-color: #169bd5;
          color: white;
        }
      }
    }
  }
}
</style>
