<template>
  <alert :message="message" />
</template>

<script>
import { mapState } from 'vuex';
import lightFormat from 'date-fns/lightFormat';
import { getRemainedTime } from 'common/helpers/dateHelper';
import Alert from 'common/components/Alert.vue';

let interval;

export default {
  name: 'DeadlineTimer',
  components: {
    Alert
  },
  data: () => ({
    remainedTime: null
  }),
  computed: {
    ...mapState('orders', {
      info: state => state.orderInfo.info
    }),
    message() {
      return this.info.offers_deadline
        ? `Дата и время завершения приема предложений от перевозчиков: ${lightFormat(
            new Date(this.info.offers_deadline),
            'dd.MM.yyyy в HH:mm'
          )} (осталось ${this.remainedTime})`
        : '';
    }
  },
  created() {
    this.remainedTime = this.getTime();
    interval = setInterval(() => {
      this.remainedTime = this.getTime();
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(interval);
  },
  methods: {
    getTime() {
      const { days, hours, minutes } = getRemainedTime(
        new Date(this.info.offers_deadline),
        Date.now()
      );
      return [days, hours, minutes].filter(_ => _).join(' ');
    }
  }
};
</script>
