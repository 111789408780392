<template>
  <v-btn v-bind="$attrs" class="custom-button" v-on="$listeners">
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'CustomButton'
};
</script>

<style lang="scss" scoped>
.custom-button.v-btn:not(.gradient) {
  transition: all 0.2s !important;
  transition-property: color, background-color, opacity, border-color !important;
}

.custom-button.theme--light.v-btn.v-btn--disabled {
  /deep/.v-btn__loading {
    color: #009ce9 !important;
  }
}

.custom-button.v-btn.v-btn--disabled.has-tooltip {
  pointer-events: all;
}

.custom-button.v-btn.v-btn--disabled {
  cursor: not-allowed;
}

.custom-button.v-btn:not(.gradient):not(.outline) {
  color: #009ce9 !important;
  font-weight: normal;
  box-shadow: none;

  &.counter {
    /deep/.v-btn__content {
      display: flex;
      flex-direction: column;
    }
    /deep/.counter__label {
      display: block;
      color: #fff;
      font-size: 10px;
      line-height: 1;
    }
  }
  &[heavy] {
    font-weight: 500;
  }
  &[link] {
    height: auto;
  }
  /deep/.v-ripple__container {
    display: none !important;
  }
  &.v-btn--large:not(.v-btn--outline) {
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25) !important;
  }
  &.v-btn--small {
    height: 36px;
  }
  /deep/.v-btn__content {
    font-size: 14px;
    text-transform: none;
  }
  &:not(.v-btn--icon) {
    /deep/.icon {
      font-size: 15px;
      margin-right: 6px;
    }
  }
  &.v-btn--small:not(.v-btn--floating),
  &.v-btn--large {
    color: #fff !important;
    min-width: 168px;
    &:not(.v-btn--outline) {
      /deep/.v-btn__content {
        color: #fff;
      }
    }
  }
  &[color='grey'],
  &.v-btn--icon {
    color: #888888 !important;
    /deep/.icon {
      color: #888888 !important;
    }
    /deep/.v-btn__content {
      color: #111111 !important;
    }
    &:active:not(.v-btn--icon) {
      /deep/.icon {
        color: #009ce9 !important;
      }
    }
  }
  &[color='black'].v-btn--icon {
    color: #111111 !important;
    /deep/.icon {
      color: #111111 !important;
    }
    /deep/.v-btn__content {
      color: #111111 !important;
    }
    &:active:not(.v-btn--icon) {
      /deep/.icon {
        color: #009ce9 !important;
      }
    }
  }
  &[color='white'] {
    color: #ffffff !important;
    /deep/.icon {
      color: #ffffff !important;
    }
    /deep/.v-btn__content {
      color: #fff !important;
    }
    &:active:not(.v-btn--icon) {
      /deep/.icon {
        color: #ffffff !important;
      }
    }
  }
  &[color='primary'].v-btn--icon {
    color: #009ce9 !important;
    /deep/.icon {
      color: #009ce9 !important;
    }
    /deep/.v-btn__content {
      color: #009ce9 !important;
    }
    &:active:not(.v-btn--icon) {
      /deep/.icon {
        color: #009ce9 !important;
      }
    }
  }
  &.v-btn--round.theme--light:not(.v-btn--icon):not(.v-btn--flat):not(.v-btn--outline) {
    background-color: #009ce9 !important;
    border-color: #009ce9 !important;
    &:hover {
      background-color: #027fbd !important;
      border-color: #027fbd !important;
    }
    &:active {
      box-shadow: none;
      background-color: #009ce9 !important;
      border-color: #009ce9 !important;
    }
    &[color='success'] {
      background-color: #49aa71 !important;
      border-color: #49aa71 !important;
      &:hover {
        background-color: #1eb282 !important;
        border-color: #1eb282 !important;
      }
      &:active {
        box-shadow: none;
        background-color: #49aa71 !important;
        border-color: #49aa71 !important;
      }
    }
  }
  &.v-btn--round.theme--light.v-btn--outline,
  &.v-btn--large.theme--light.v-btn--outline {
    background-color: transparent !important;
    color: #009ce9 !important;
    border-color: #009ce9 !important;
    &:hover {
      background-color: #027fbd !important;
      border-color: #027fbd !important;
      /deep/.v-btn__content,
      /deep/.icon {
        color: #fff !important;
      }
    }
    &:active {
      box-shadow: none;
      background-color: transparent !important;
      border-color: #009ce9 !important;
      /deep/.v-btn__content,
      /deep/.icon {
        color: #009ce9 !important;
      }
    }
  }
  &.v-btn--flat {
    padding: 0;
    min-width: unset;
    &:hover:not([color='black']):not(.v-btn--icon) {
      /deep/.v-btn__content,
      /deep/.icon {
        color: #027fbd !important;
      }
    }
    &:active:not([color='black']):not(.v-btn--icon) {
      /deep/.v-btn__content,
      /deep/.icon {
        color: #009ce9 !important;
      }
    }
  }
  &.v-btn--floating:not(.v-btn--icon):not(.v-btn--flat):not(.v-btn--outline) {
    width: 44px;
    height: 44px;
    border-radius: unset;
    background-color: #009ce9 !important;
    /deep/.icon {
      margin: 0;
      font-size: 24px;
      color: #fff !important;
    }
    /deep/.v-btn__content {
      width: 32px;
      max-width: 32px;
      height: 32px;
      border-radius: 50%;
    }
    &:hover {
      /deep/.v-btn__content {
        background-color: #027fbd;
      }
    }
    &:active {
      box-shadow: none;
    }
  }
  &.v-btn.v-btn--disabled {
    color: #7fcdf4 !important;
    /deep/.icon {
      color: #7fcdf4 !important;
    }
    /deep/.v-btn__content {
      color: #7fcdf4 !important;
    }
    &.v-btn--large.v-btn--loader {
      /deep/.v-btn__loading {
        color: #fff !important;
      }
    }
    &.v-btn--outline.theme--light {
      border-color: #7fcdf4 !important;
    }
    &[color='black'],
    &[color='grey'],
    &.v-btn--icon {
      color: #b9b9b9 !important;
      /deep/.icon {
        color: #b9b9b9 !important;
      }
      /deep/.v-btn__content {
        color: #b9b9b9 !important;
      }
    }
    &.v-btn--floating:not(.v-btn--icon):not(.v-btn--flat):not(.v-btn--outline) {
      /deep/.icon {
        color: #7fcdf4 !important;
      }
    }
  }
  &.v-btn--icon {
    &[color='danger'] {
      /deep/.v-btn__content,
      /deep/.icon {
        color: #fb4f4f !important;
      }
    }
    &[color='warning'] {
      /deep/.v-btn__content,
      /deep/.icon {
        color: #ffbf00 !important;
      }
    }
    &[color='success'] {
      /deep/.v-btn__content,
      /deep/.icon {
        color: #49aa71 !important;
      }
    }
    background-color: transparent !important;
    border-color: transparent !important;
  }
  /deep/.v-badge__badge {
    background-color: #fb4f4f !important;
    min-width: 17px;
    width: auto;
    height: 17px;
    font-size: 10px;
    right: -10px;
    padding: 0 5px;
  }
}
</style>
