import Vue from 'vue';
import CustomButton from 'common/components/CustomButton.vue';
import CustomInput from 'common/components/CustomInput.vue';
import Icon from 'common/components/Icon.vue';
import store from './store';
import App from './components/App.vue';
import router from './router';

import './plugins/vuetify';
import './plugins/scroll';
import './plugins/clipboard';
import './plugins/wysiwyg';
import './plugins/viewer';
import './plugins/html-secure';

Vue.config.productionTip = false;
Vue.component('custom-button', CustomButton);
Vue.component('custom-input', CustomInput);
Vue.component('icon', Icon);

export const bus = new Vue();
/* eslint-disable no-new */
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');
