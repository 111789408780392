<template>
  <div class="tariffs">
    <div class="tariffs__column">
      <v-text-field
        ref="priceTransport"
        v-model.number="item.limit"
        class="ui-kit__input"
        :disabled="!show || lastEl"
        label="Стоимость первозки, руб."
        placeholder=" "
        :rules="show ? number : []"
        @keyup="checkOnValid('priceTransport')"
      />
    </div>
    <div class="tariffs__column">
      <v-text-field
        ref="commission"
        v-model.number="item.percent"
        class="ui-kit__input"
        :disabled="!show"
        label="Комиссия сервиса, %"
        placeholder=" "
        :rules="show ? number : []"
        @keyup="checkOnValid('commission')"
      />
    </div>
    <v-btn
      v-show="!show"
      :disabled="!isSuper"
      class="ui-kit__button"
      icon=""
      flat=""
      color="news"
      @click="show = !show"
    >
      <v-icon>edit</v-icon>
    </v-btn>
    <v-btn
      v-show="show"
      class="ui-kit__button"
      icon=""
      flat=""
      color="news"
      :disabled="!(valid.priceTransport && valid.commission)"
      @click="changeItem"
    >
      <v-icon>check</v-icon>
    </v-btn>
    <v-btn
      v-show="!lastEl"
      :disabled="!isSuper"
      class="ui-kit__button"
      icon
      flat
      color="news"
      @click="addItem"
    >
      <v-icon>add_circle_outline</v-icon>
    </v-btn>
    <v-btn
      v-show="!lastEl"
      class="ui-kit__button"
      :disabled="firstEl || !isSuper"
      icon=""
      flat=""
      color="news"
      @click="removeItem"
    >
      <v-icon>remove_circle_outline</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    tariffs: Array,
    firstEl: Boolean,
    lastEl: Boolean,
    isSuper: Boolean
  },
  data() {
    return {
      show: false,
      number: [v => /^[1-9][0-9]*([.][0-9]{2}|)$/.test(v) || 'только числа'],
      valid: {
        priceTransport: true,
        commission: true
      }
    };
  },
  methods: {
    changeItem() {
      this.show = !this.show;
      this.$emit('changeTariffs');
    },
    removeItem() {
      this.$emit('removeTariffs');
    },
    addItem() {
      if (this.tariffs.length <= 5) {
        this.tariffs.splice(-1, 0, {
          active: false,
          limit: '',
          percent: ''
        });
      }
    },
    checkOnValid(field) {
      this.valid[field] = this.$refs[field].valid;
    }
  }
};
</script>

<style lang="scss" scoped>
.tariffs {
  .tariffs__column {
    position: relative;
    width: 33.3333%;
    margin-right: 15px;

    .tariffs__column__btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: 5px 0 0 0;
    }
  }
}

.service-row {
  display: flex;
}
</style>
