<template>
  <v-container class="notification-form">
    <v-layout class="notification-form__content">
      <v-tabs v-model="currentTabIndex" show-arrows>
        <v-tabs-slider color="primary" />
        <v-tab v-for="(tab, i) in notification.receivers" :key="i">
          {{ tab.title }}
        </v-tab>
      </v-tabs>
      <notification-form-tab :current-tab="currentTab" @close="$emit('close')" />
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import NotificationFormTab from './NotificationFormTab.vue';

export default {
  name: 'NotificationForm',
  components: {
    NotificationFormTab
  },
  props: {
    notification: Object
  },
  data() {
    return {
      currentTabIndex: 0,
      currentTab: {}
    };
  },
  watch: {
    currentTabIndex: {
      immediate: true,
      handler(index) {
        this.getTemplateHandler(index);
      }
    },
    notification: {
      handler() {
        this.getTemplateHandler();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('adminNotifications', ['getNotificationTemplate']),
    async getTemplateHandler(index = 0) {
      const { key } = this.notification.receivers[index];
      const { number } = await this.getNotificationTemplate({
        number: this.notification.number,
        type: key
      });
      this.currentTab = {
        ...this.notification.receivers[this.currentTabIndex],
        number
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.notification-form {
  position: relative !important;
  top: 0;
  z-index: 1;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  padding: 0;
  background-color: white;
  margin: 0;
  &__content {
    min-height: 500px;
  }

  /deep/ .v-toolbar__content {
    padding: 16px !important;
  }

  &__content {
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
  }
}
.left {
  position: relative;
  left: -50px;
}
</style>
