<template>
  <div v-if="ordersList.length" class="orders">
    <view-window v-if="isWindowShowed" :cid="selectedOrderCid" @close="isWindowShowed = false" />
    <template v-else>
      <v-select v-model="filteredValue" :items="filterItems" />
      <list-loader :cursor="cursor" @load-more="loadMore">
        <CarrierActivityItem
          v-for="(order, index) in filteredList"
          :key="order.id"
          is-operator
          :value="order"
          :class="{ 'mt-0': index === 0 }"
          @click.native="onClick(order.cid)"
        >
          <icon
            name="message"
            size="18"
            message="Отзывы"
            :class="[{ disabled: ['accepted'].includes(order.status) }]"
          />
          <icon name="marker" size="18" message="GPS" />
          <icon
            name="xFile"
            size="18"
            message="Отменить заказ"
            :class="[{ disabled: !['accepted', 'in_process'].includes(order.status) }]"
          />
        </CarrierActivityItem>
      </list-loader>
    </template>
  </div>
  <stub v-else message="Нет заказов" />
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Stub from 'common/components/Stub';
import ViewWindow from '@/components/common/ViewWindow';
import CarrierActivityItem from 'common/components/Orders/CarrierActivityItem';
import ListLoader from 'common/components/ListLoader';

const filterItems = [
  {
    text: 'Все заказы',
    value: null
  },
  {
    text: 'Подтвержденные',
    value: ['accepted', 'in_process']
  },
  {
    text: 'Отмененные',
    value: ['canceled', 'on_dispute', 'dispute_is_done']
  },
  {
    text: 'Завершенные',
    value: ['done']
  }
];

export default {
  name: 'OrdersCarrier',
  components: {
    Stub,
    CarrierActivityItem,
    ViewWindow,
    ListLoader
  },
  data() {
    return {
      filteredValue: null,
      selectedOrderCid: null,
      isWindowShowed: null
    };
  },
  computed: {
    ...mapState('clientOrders', ['ordersList', 'cursor']),
    filteredList() {
      return this.filteredValue
        ? this.ordersList?.filter(_ => this.filteredValue.includes(_.status))
        : this.ordersList;
    }
  },
  created() {
    this.filterItems = filterItems;
    this.getOrdersList();
  },
  beforeDestroy() {
    this.clearFeed();
  },
  methods: {
    ...mapActions('clientOrders', ['getOrdersList']),
    ...mapMutations('clientOrders', ['clearFeed']),
    onClick(cid) {
      this.selectedOrderCid = cid;
      this.isWindowShowed = true;
    },
    async loadMore() {
      this.getOrdersList(this.cursor);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.orders {
  /deep/ .v-select {
    max-width: 168px !important;
    padding: 0 !important;
  }
  .icon {
    margin-left: 7px;
    fill: $color-main;
  }
}

.disabled {
  fill: $gray-light !important;
}
</style>
