<template>
  <div v-if="loaded" class="transport">
    <TsInfo
      type="train"
      :values_head="values_head"
      :statuses="statuses"
      :photos="photos"
      :values_tail="values_tail"
      :title="title"
      :fields="fields"
    />
  </div>
  <div v-else class="loading">
    <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions, mapState } from 'vuex';
import utils from '@/helpers/utils';
import TsInfo from '../Transports/TruckerTS';

export default {
  name: 'Transport',
  components: {
    TsInfo
  },
  data() {
    return {
      statuses: {
        tail: {},
        head: {}
      },
      photos: [],
      values_head: {},
      values_tail: {},
      fields: {
        types: utils.transportTypes,
        typeTS: [
          { text: 'Право собственности', value: 'proprietary' },
          { text: 'Лизинг', value: 'leasing' },
          { text: 'Договор аренды', value: 'lease-contract' }
        ]
      },
      title: '',
      loaded: false
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('verifications', ['scheme']),
    ...mapState('clientsTransports', ['trucker_ts']),
    enduser_id() {
      return parseInt(this.$route.params.enduser);
    }
  },
  async mounted() {
    await this.setData();
    await this.getScheme();
    await this.setFields();
  },
  methods: {
    ...mapActions('verifications', ['getScheme']),
    ...mapActions('clientsTransports', ['getTransport']),
    async setData() {
      const item = this.trucker_ts;
      this.photos = item.photos;
      this.statuses = {
        head: {
          title: item.head.verify,
          color: utils.verifyColors[item.head.verification_status]
        },
        tail: {
          title: item.tail.verify,
          color: utils.verifyColors[item.tail.verification_status]
        }
      };
      this.title = `${item.head.name} - ${item.head.verify}`;
      item.tail.properties = item.properties;

      const head = await this.getTransport({
        vehicle: item.head.vehicle
      });

      this.setValues(item.head, head.properties);
      this.setValuesTail(item.tail);
      this.loaded = true;
    },
    setValues(values, properties) {
      this.values_head.cid = values.cid;
      this.values_head.type = values.vehicle_type;
      this.values_head.carcass = values.carcass;
      this.values_head.typeTS = values.type_right_use;
      this.values_head.model = values.make_and_model;
      this.values_head.number = values.registration_plate;
      this.values_head.usedOnlyTrain = values.only_in_wagon;
      this.values_head.yearManuf = moment(values.release_year).year();
      this.values_head.date = moment(values.valid_until).format('YYYY-MM-DD');
      this.values_head.properties = properties;
      const propertiesList = {};
      properties.forEach(item => {
        propertiesList[item.name] = item.value;
      });
      this.values_head.properties = propertiesList; // для того, чтобы checkbox отрабатывали правильно
    },
    setValuesTail(values) {
      this.values_tail.cid = values.cid;
      this.values_tail.type = values.vehicle_type;
      this.values_tail.carcass = values.carcass;
      this.values_tail.typeTS = values.type_right_use;
      this.values_tail.model = values.make_and_model;
      this.values_tail.number = values.registration_plate;
      this.values_tail.yearManuf = moment(values.release_year).year();
      this.values_tail.date = moment(values.valid_until).format('YYYY-MM-DD');
      const properties = {};
      values.properties.forEach(item => {
        properties[item.name] = item.value;
      });
      this.values_tail.properties = properties; // для того, чтобы checkbox отрабатывали правильно
    },
    setFields() {
      Object.keys(this.scheme).forEach(key => {
        this.fields[key] = this.scheme[key];
      });
    }
  }
};
</script>

<style scoped>
.transport {
  position: relative;
}
</style>
