<template>
  <div class="preview">
    <div v-if="isLoaded" class="progress">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <div
      v-bind="$attrs"
      :class="{ loaded: isLoaded }"
      :style="{ backgroundImage: `url(${src})` }"
      class="preview-container"
      v-on="$listeners"
    >
      <i class="material-icons delete-button" @click.stop.prevent="$emit('delete-image')">
        delete
      </i>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    src: String,
    isLoaded: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.preview {
  position: relative;
  height: 40px;
  margin: 0 12px 12px 0;

  &-container {
    display: flex;
    color: $color-main;
    width: 50px;
    height: 40px;
    background-size: cover;
    background-position: center;
    .delete-button {
      cursor: pointer;
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: hsla(0, 0, 100, 0.7);
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 0.2s;
    }

    &:hover {
      .delete-button {
        opacity: 1;
      }
    }
  }
}
.progress {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loaded {
  opacity: 0.5;
}
</style>
