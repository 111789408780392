<template>
  <Tabs class="stage_payments" :tabs="tabs" :active="currentTab" @change="changeTab">
    <template />
  </Tabs>
</template>

<script>
import Tabs from '../../Layout/Tabs.vue';
import CashMoveTab from './CashMoveTab.vue';
import SourceDocumentsTab from './SourceDocumentsTab.vue';
import SettingsTab from './SettingsTab.vue';

export default {
  components: {
    Tabs
  },
  data() {
    return {
      items: [],
      currentTab: this.$route.params.tab || 'transactions',
      tabs: [
        { name: 'transactions', title: 'Движение денежных средств', component: CashMoveTab },
        { name: 'settings', title: 'Настройки', component: SettingsTab },
        {
          name: 'documents',
          title: 'Первичные документы',
          component: SourceDocumentsTab,
          disabled: true
        }
      ]
    };
  },
  computed: {
    stage() {
      return this.$route.params.stage;
    }
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      this.$router.push({ name: 'ClientInfoStageTab', params: { stage: this.stage, tab } });
    }
  }
};
</script>
