import isAfter from 'date-fns/isAfter';
import lightFormat from 'date-fns/lightFormat';
import set from 'date-fns/set';
import { getUTCDate, isDateNullish, createDatetimeWithOffset } from 'common/helpers/dateHelper';
import { setField } from 'common/store/helpers';
import { getCityName } from 'common/utils';

const state = {
  gpsStart: null,
  gpsEnd: null,
  route: [],
  lastCoordinate: {},
  coordinates: [],
  routeInfo: {}
};

const getters = {
  gpsPoints(state) {
    return state.coordinates.map(_ => [_.latitude, _.longitude]);
  },
  isGPSEmpty(state) {
    return isDateNullish(state.routeInfo.gps_start_date);
  }
};

const actions = {
  async getGPSInfo({ commit, dispatch, rootState }) {
    try {
      const topic = 'tracker/get_route_info';
      const json = { order_cid: rootState.orders.orderInfo.currentOrderCid };
      const { route_info: routeInfo } = await dispatch(
        'common/createController',
        { topic, json },
        { root: true }
      );
      commit('setRouteInfo', { routeInfo, route: rootState.orders.orderInfo.info.route });
    } catch (error) {
      console.error(error);
    }
  },
  async getLastCoordinate({ commit, dispatch, rootState }) {
    try {
      const topic = 'tracker/get_last_coordinate';
      const json = { order_cid: rootState.orders.orderInfo.currentOrderCid };
      const { coordinate } = await dispatch(
        'common/createController',
        { topic, json },
        { root: true }
      );
      commit('setGPSField', { field: 'lastCoordinate', value: coordinate });
    } catch (error) {
      console.error(error);
    }
  },
  async getCoordinates({ commit, dispatch, rootState }) {
    try {
      const topic = 'tracker/get_coordinates';
      const json = { order_cid: rootState.orders.orderInfo.currentOrderCid };
      const { coordinates } = await dispatch(
        'common/createController',
        { topic, json },
        { root: true }
      );
      commit('setGPSField', { field: 'coordinates', value: coordinates });
    } catch (error) {
      console.error(error);
    }
  },
  prolongateGPS({ dispatch, rootState }, { hours = 0, minutes = 0 }) {
    const topic = 'tracker/prolong_tracking';
    const json = {
      order_cid: rootState.orders.orderInfo.currentOrderCid,
      prolong_value: 'minutes',
      prolong_time: Number(hours) * 60 + Number(minutes)
    };
    return dispatch('common/createController', { topic, json }, { root: true });
  }
};

const mutations = {
  setGPSField: setField,
  setRouteInfo(state, { routeInfo: data, route }) {
    state.gpsStart = lightFormat(new Date(data.gps_start_date), 'dd.MM.yyyy в HH:mm');
    state.gpsEnd = lightFormat(new Date(data.gps_until_date), 'dd.MM.yyyy в HH:mm');
    state.route = data.points.map(_ => {
      const { point } = route[_.number - 1];
      const getArrivedDate = date => {
        if (!date) return null;

        const arrivedDate = createDatetimeWithOffset({ date, offset: point.gmtOffset });
        return lightFormat(arrivedDate, 'dd.MM.yyyy в HH:mm');
      };
      return {
        ..._,
        name: getCityName(point),
        datetime: lightFormat(
          createDatetimeWithOffset({ date: point.datetime, offset: point.gmtOffset }),
          'dd.MM.yyyy в HH:mm'
        ),
        arrived: getArrivedDate(_.arrived_date),
        isAfter: isAfter(
          createDatetimeWithOffset({ date: _.arrived_date, offset: point.gmtOffset }),
          createDatetimeWithOffset({ date: point.datetime, offset: point.gmtOffset })
        )
      };
    });
    state.routeInfo = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
