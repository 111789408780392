import { setField } from 'common/store/helpers';

const state = {
  messages: [],
  messagesCount: 0
};

const actions = {
  async getMessages({ dispatch, commit, rootState }, from = null) {
    const { enduser } = rootState.clients.info;
    const topic = 'admin/endusers/support';
    const json = { enduser };
    if (from) json.from = from;
    try {
      const { authors, messages } = await dispatch(
        'common/createController',
        { topic, json },
        { root: true }
      );
      const mappedMessages = messages.reverse().map(message => ({
        ...message,
        author: authors.find(_ => _.user_id === message.user_id)
      }));
      commit('setMessages', mappedMessages);
      commit('setField', { field: 'messagesCount', value: messages.length });
    } catch (error) {
      console.error(`Ошибка в запросе ${topic}`, error);
    }
  }
};
const mutations = {
  setMessages(state, messages) {
    state.messages = [...messages, ...state.messages];
  },
  clearSupportState(state) {
    state.messages = [];
    state.messagesCount = 0;
  },
  setField
};
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
