<template>
  <div ref="swiper" class="swiper-previews">
    <img
      v-for="(item, index) in limitedImages"
      :key="item[urlField]"
      :src="item[urlField]"
      class="swiper-previews__item"
      alt=""
      @click="$emit('image-click', index)"
    />
    <div
      v-if="hiddenLength"
      class="swiper-previews__hidden-length"
      :style="{ width: height }"
      @click="$emit('image-click', 0)"
    >
      <span class="text">+{{ hiddenLength }}</span>
      <span class="background" :style="{ backgroundImage: `url(${images[limit][urlField]})` }" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwiperPreviews',
  props: {
    images: {
      type: Array,
      default: () => []
    },
    height: {
      type: String,
      default: '100px'
    },
    urlField: {
      type: String,
      default: 'src'
    },
    limit: {
      type: Number,
      default: 0
    },
    maxWidth: {
      type: String,
      default: '180px'
    },
    maxHeight: {
      type: String,
      default: 'none'
    }
  },
  computed: {
    limitedImages() {
      return this.limit ? this.images.slice(0, this.limit) : this.images;
    },
    hiddenLength() {
      return this.images.length - this.limitedImages.length;
    }
  },
  mounted() {
    const el = this.$refs.swiper;
    el.style.setProperty('--max-width', this.maxWidth);
    el.style.setProperty('--height', this.height);
    el.style.setProperty('--max-height', this.maxHeight);
  }
};
</script>

<style lang="scss" scoped>
.swiper-container {
  overflow: visible;
}
.swiper-previews {
  --max-width: 180px;
  --max-height: none;
  --height: 100px;

  margin: 0;
  height: var(--height);
  max-height: var(--max-height);
  display: flex;

  &__hidden-length {
    height: 100%;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    & > .background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      margin: -20px;
      background-size: cover;
      filter: blur(10px);
    }

    & > .text {
      position: relative;
      z-index: 1;
      mix-blend-mode: difference;
    }
  }

  &__item {
    width: auto;
    overflow: hidden;
    max-width: var(--max-width);
    cursor: pointer;
    height: 100%;
    margin-right: 10px;
  }
}
</style>
