<template>
  <div class="image-uploader">
    <div v-if="textLabel" class="text-label">{{ textLabel }}</div>
    <label v-if="dropzoneShowed" ref="dropzone" :for="inputId" class="image-uploader__dropzone">
      <span v-if="!previews.length" class="image-uploader__dropzone-placeholder">
        Перетащите изображения в эту область
        <br />
        <br />
        <i class="material-icons image-uploader-label__upload-btn">
          file_upload
        </i>
      </span>
      <preview
        v-for="{ src, isLoaded } in previews"
        :key="src"
        :is-loaded="isLoaded"
        :src="src"
        @delete-image="$emit('removed-file', src)"
      />
    </label>
    <div class="image-uploader__wrapper">
      <template v-if="!dropzoneShowed">
        <preview
          v-for="{ src, isLoaded } in previews"
          :key="src"
          :is-loaded="isLoaded"
          :src="src"
          @delete-image="$emit('removed-file', src)"
        />
      </template>
      <label
        v-if="!maxLength || maxLength !== previews.length"
        ref="inputLabel"
        :for="inputId"
        :class="{ 'with--description': !!additionalDescription }"
        class="image-uploader-label"
      >
        <i class="material-icons image-uploader-label__upload-btn">
          file_upload
        </i>
        {{ title }}
        <span class="image-uploader__description">
          {{ additionalDescription }}
        </span>
      </label>
    </div>
    <input
      :id="inputId"
      ref="fileInput"
      type="file"
      hidden
      @change="handleFile($event.target.files[0])"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getUUID } from 'common/helpers';
import axios from 'common/helpers/httpService';
import Preview from './preview.vue';

export default {
  components: { Preview },
  props: {
    additionalPayload: {
      type: Object,
      default: () => ({})
    },
    additionalDescription: String,
    uploadedPhotos: {
      type: Array,
      default: () => []
    },
    showDropzone: Boolean,
    title: {
      type: String,
      default: 'Добавить'
    },
    maxLength: {
      type: Number,
      default: 0
    },
    multipage: Boolean,
    textLabel: String
  },
  data: () => ({
    previews: [],
    inputId: `${getUUID()}-file`,
    dropzoneActive: false
  }),
  computed: {
    ...mapState({
      cid: state => state.login.cid,
      client: state => state.connections.client,
      defaultURL: state => state.defaultURL
    }),
    dropzoneShowed() {
      return this.showDropzone && this.dropzoneActive;
    }
  },
  watch: {
    uploadedPhotos(val) {
      this.previews = [...val];
    },
    dropzoneShowed(val) {
      if (val) {
        this.$nextTick(() => {
          const { dropzone } = this.$refs;
          ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(
            ev =>
              dropzone.addEventListener(ev, e => {
                e.preventDefault();
                e.stopPropagation();
              })
          );

          ['dragover', 'dragenter'].forEach(ev =>
            dropzone.addEventListener(ev, () => dropzone.classList.add('is-dragover'))
          );
          ['dragleave', 'dragend', 'drop'].forEach(ev =>
            dropzone.addEventListener(ev, () => dropzone.classList.remove('is-dragover'))
          );

          dropzone.addEventListener('drop', e => {
            Array(...e.dataTransfer.files).forEach(this.handleFile);
          });
        });
      }
    }
  },
  mounted() {
    if (this.uploadedPhotos.length) {
      this.previews = [...this.uploadedPhotos];
    }

    if (this.showDropzone) {
      ['dragover', 'dragenter'].forEach(ev =>
        this.$refs.inputLabel.addEventListener(ev, () => {
          this.dropzoneActive = true;
        })
      );
    }
  },
  methods: {
    ...mapActions(['addSnackbar']),
    handleFile(origin) {
      const id = `img-${getUUID()}`;
      this.submitFile({ file: origin, id });
      this.previews.push({
        id,
        isLoaded: true
      });
      this.$refs.fileInput.value = '';
    },
    async submitFile({ file, id }) {
      const formData = new FormData();
      formData.append('cid', this.cid);
      if (this.multipage) formData.append('multipage', true);
      formData.append('inputfile', file);
      try {
        const res = await axios.post('/upload', formData);
        const { photos } = res;
        photos.forEach(uploadedPhoto => {
          if (uploadedPhoto.is_multipage)
            this.addSnackbar({
              message:
                'Вы попытались загрузить многостраничный PDF файл, поэтому загружена только первая его страница',
              type: 'error'
            });
          const currentPreview = this.previews.find(preview => preview.id === id) || {};
          const photo = {
            ...this.additionalPayload,
            filename: uploadedPhoto.filename,
            file: uploadedPhoto.file_id,
            medium: uploadedPhoto.medium_id,
            small: uploadedPhoto.small_id,
            tiny: uploadedPhoto.tiny_id
          };

          const src = `${this.defaultURL}/files/${this.cid}/${photo.tiny}`;
          const newId = getUUID();
          this.$emit('uploaded', { photo, src, id: currentPreview.id || newId });
          this.previews = currentPreview.id
            ? this.previews.map(preview =>
                preview.id === id ? { ...preview, isLoaded: false } : preview
              )
            : [...this.previews, { id: newId, isLoaded: false }];
          this.dropzoneActive = false;
        });
      } catch (err) {
        this.addSnackbar({
          message:
            'Файл больше 15 мегабайт или неверный формат файла (допустимые форматы - jpeg, png, pdf, tiff)',
          type: 'error'
        });
        this.previews = this.previews.filter(_ => _.id !== id);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.image-uploader {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  position: relative;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &-label {
    display: flex;
    align-items: center;
    color: $color-main;
    font-size: 14px;
    margin: 0 0 12px;
    cursor: pointer;
    &.with--description {
      align-items: flex-start;
    }

    &__upload-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 44px;
      max-width: 44px;
      height: 44px;
      background-color: #fafafa;
      border-radius: 50%;
      margin: 0 12px 0 0;
      color: $gray-dark;
    }
  }

  &__dropzone {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: white;
    letter-spacing: 0.2px;
    color: #777;
    transition: background-color 0.2s linear;
    height: 205px;
    padding: 40px;
    border: dashed 2px $gray-light;

    &-placeholder {
      text-align: center;
      margin: auto;
    }

    &.is-dragover {
      background: #ddd;
    }
  }

  .with-description {
    position: relative;
    align-items: flex-start;
  }
  &__description {
    font-size: 12px;
    color: #b9b9b9;
    position: relative;
    left: -64px;
    bottom: -24px;
  }
}

.text-label {
  font-size: 12px;
  color: $gray;
  margin-bottom: 8px;
}
</style>
