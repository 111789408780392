<template>
  <content-block class="feedback" @click.native="$emit('click', feedback.corder_id)">
    <div class="feedback__header">
      <icon :name="`${feedback.evaluation}Emoticon`" :class="`${feedback.evaluation}-color`" />
      <span class="feedback__header-title">
        <b v-if="feedback.from_name">{{ feedback.from_name }}</b>
        <span v-if="feedback.hidden" class="hidden-message">
          <icon name="informationOutline" size="20" />
          Этот отзыв будет скрыт от других пользователей сервиса
        </span>
        <v-spacer />
        <span>{{ formatingDate }}</span>
      </span>
    </div>
    <div v-if="feedback.auto" class="feedback__text">
      <p>
        Отзыв автоматически сгенерирован сервисом
        {{
          feedback.from_operator
            ? 'по результатам рассмотрения диспута'
            : ` в результате отмены заказа ${whoFault}`
        }}.
      </p>
      <p v-if="feedback.reasons.length">
        Указанные причины отмены -
        <b>{{ reasons }}</b>
      </p>
      <p v-if="feedback.text">
        Дополнительный комментарий -
        <b>{{ feedback.text }}</b>
      </p>
    </div>
    <div v-else class="feedback__text">{{ feedback.text }}</div>
  </content-block>
</template>

<script>
import ContentBlock from 'common/components/Orders/ContentBlock';
import lightFormat from 'date-fns/lightFormat';
import { mapState } from 'vuex';

export default {
  name: 'FeedbackBlock',
  components: {
    ContentBlock
  },
  props: {
    feedback: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState('settings', ['orderCancelReasons']),
    ...mapState('login', ['category']),
    formatingDate() {
      return lightFormat(new Date(this.feedback.date), 'dd.MM.yyyy в HH:mm');
    },
    reasons() {
      return this.orderCancelReasons[this.feedback.from_category]
        .filter(_ => this.feedback.reasons.includes(_.value))
        .map(_ => _.label)
        .join(', ');
    },
    whoFault() {
      return !this.feedback.reasons.length && !this.feedback.replicas.length
        ? 'без вины контрагента'
        : 'по вине контрагента';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';
.feedback {
  &__header {
    display: flex;
    .icon {
      margin-right: 8px;
    }
    &-title {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }
  }
  &__text {
    margin: 12px 0 0;
    padding: 0 0 14px 0;
    /deep/ p {
      margin-bottom: 0;
    }
  }
}

.positive-color {
  color: $color-success !important;
}
.neutral-color {
  color: $color-warning !important;
}
.negative-color {
  color: $color-danger !important;
}

.hidden-message {
  display: flex;
  align-items: center;
  color: $gray;
  margin-left: 12px;
}

@media screen and (max-width: breakpoint(sm)) {
  .feedback {
    &__header {
      justify-content: flex-start;
      &-title {
        flex-direction: column;
        align-items: end;
        flex-grow: 1;
        b,
        span {
          margin: 2px 0;
        }
      }
    }
    &__text {
      margin: 8px 0 0;
    }
  }
}
</style>
