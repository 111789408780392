<template>
  <div v-if="listAddresses.length" class="addresses">
    <div v-for="(item, index) in listAddresses" :key="index" class="addresses__address">
      <span class="addresses__name">{{ item.name }},</span>
      {{ item.point.text }}, {{ item.contact.name }}, {{ item.contact.phone }}
    </div>
  </div>
  <stub v-else message="Нет адресов" />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Stub from 'common/components/Stub';

export default {
  name: 'AddressesTab',
  components: {
    Stub
  },
  computed: {
    ...mapState('clientOrders', ['listAddresses'])
  },
  created() {
    this.getAddresses();
  },
  methods: {
    ...mapActions('clientOrders', ['getAddresses'])
  }
};
</script>

<style lang="scss">
.addresses {
  .addresses__address {
    display: flex;
    margin: 15px 0;
    padding: 0 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 40px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);

    .addresses__name {
      font-weight: bold;
      margin-right: 5px;
    }
  }
}
</style>
