<template>
  <div class="administration">
    <v-tabs slot="extension" color="transparent">
      <template v-for="(tab, index) in tabs">
        <v-tab :key="index" :href="`#${tab.name}`">
          {{ tab.title }}
        </v-tab>
        <v-tab-item :key="index" class="scroll" :value="tab.name">
          <component :is="tab.component" v-if="tab.component" />
          <h1 v-else>
            {{ tab.title }}
          </h1>
        </v-tab-item>
      </template>
    </v-tabs>
  </div>
</template>

<script>
import Notifications from './AdministrationsTabs/Notifications.vue';
import Users from './AdministrationsTabs/Users.vue';
import Groups from './AdministrationsTabs/Groups.vue';
import Settings from './AdministrationsTabs/Settings.vue';
import ServicePrice from './AdministrationsTabs/ServicePrice.vue';
import Carcasses from './AdministrationsTabs/Carcasses.vue';

export default {
  name: 'Administration',
  components: {
    Notifications,
    Users,
    Groups,
    Settings,
    ServicePrice
  },
  data() {
    return {
      tabs: [
        { name: 'users', title: 'Пользователи', component: Users },
        { name: 'access-groups', title: 'Группы доступа', component: Groups },
        { name: 'settings', title: 'Настройки', component: Settings },
        { name: 'notifications', title: 'Уведомления', component: Notifications },
        { name: 'service-price', title: 'Стоимость услуг', component: ServicePrice },
        { name: 'bodywork-ts', title: 'Кузова ТС', component: Carcasses },
        { name: 'insurance', title: 'Страхование', component: false }
      ]
    };
  }
};
</script>

<style lang="scss">
.administration {
  .v-window .v-window__container .v-window-item.scroll {
    overflow: auto;
    height: calc(100vh - 120px);
  }
  .v-tabs__items {
    min-height: 650px;
  }
}
</style>
