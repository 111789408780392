<template>
  <v-container v-if="isAddForm || isEditForm" grid-list-lg class="form">
    <v-layout row="row" wrap="wrap">
      <slot />
      <v-flex xs12="xs12">
        <custom-button
          :disabled="!isSuper"
          class="ml-0"
          color="primary"
          large
          @click="$emit('submit')"
        >
          {{ isEditForm ? 'Сохранить' : 'Добавить' }}
        </custom-button>
        <custom-button
          v-if="isEditForm"
          class="ml-0"
          color="primary"
          large="large"
          :disabled="!isSuper"
          @click="$emit('remove')"
        >
          Удалить
        </custom-button>
        <custom-button color="primary" large outline @click="$emit('close')">
          Отменить
        </custom-button>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CarcassesFormAbstraction',
  props: {
    isEditForm: Boolean,
    isAddForm: Boolean
  },
  computed: {
    ...mapState('login', ['isSuper'])
  }
};
</script>

<style lang="scss" scoped>
.form {
  margin: 0 auto;
  padding: 24px 12px;
  width: calc(100% - 2px);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.349019607843137);
  z-index: 1;
  background-color: #fff;
  &__multiple {
    /deep/.v-select__selection {
      display: inline-block;
      margin: 2px 8px 2px 0;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  /deep/.v-input__append-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 28px;
    margin: auto 0 auto auto;
  }
}
/deep/.items {
  display: flex;
  flex-wrap: wrap;
  max-height: 480px;
  overflow-y: auto;
  /deep/.v-input--checkbox {
    max-width: 50%;
    width: 100%;
    margin: 0;
  }
}
</style>
