<template>
  <div v-if="loading('admin/endusers/profile/cards_and_banking/get')" class="loading">
    <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
  </div>
  <div v-else class="tab_cards">
    <div v-if="cards.bank_account_numbers" class="tab_cards__title">
      Банковские счета
    </div>
    <div class="tab_cards__container">
      <div v-for="(item, index) in cards.bank_account_numbers" :key="index" class="tab_cards__row">
        <div class="tab_cards__row__status">
          <div class="tab_cards__verified__status" :class="item.status">
            {{ getStatus(item.status) }}
          </div>
          <div class="tab_cards__default__status">
            {{ item.main ? '| По умолчанию' : '' }}
          </div>
        </div>
        <div class="tab_cards__cell">
          <v-text-field label="Номер р/с" :value="item.account_number" placeholder=" " />
        </div>
        <div class="tab_cards__cell">
          <v-text-field label="БИК" :value="item.bik" placeholder=" " />
        </div>
        <div class="tab_cards__cell">
          <v-text-field label="Банк" :value="item.bank" placeholder=" " />
        </div>
        <div class="tab_cards__cell">
          <v-text-field
            label="Номер корр. счета"
            :value="item.corr_account_number"
            placeholder=" "
          />
        </div>
      </div>
      <div
        v-if="cards.bank_account_numbers && !cards.bank_account_numbers.length"
        class="tabs__container__empty"
      >
        Нет добавленных счетов
      </div>
    </div>
    <div v-if="cards.bank_cards" class="tab_cards__title">
      Банковские карты
    </div>
    <div class="tab_cards__container">
      <div v-for="(item, index) in cards.bank_cards" :key="index" class="tab_cards__row">
        <div class="tab_cards__cell__card">
          {{ item.type }}
        </div>
        <div class="tab_cards__cell__card">
          {{ item.number }}
        </div>
        <div class="tab_cards__cell__card">годна до {{ item.expiration }}</div>
        <div class="tab_cards__cell__card card__main">
          {{ item.main ? 'По умолчанию' : '' }}
        </div>
      </div>
      <div v-if="cards.bank_cards && !cards.bank_cards.length" class="tabs__container__empty">
        Нет добавленных карт
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

const statuses = {
  verified: 'Верифицирован',
  unverified: 'Не верифицирован',
  verifying: 'На верификации'
};

export default {
  name: 'ProfileTabCards',
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('clientsProfile', ['cards'])
  },
  mounted() {
    this.getCards();
  },
  methods: {
    ...mapActions('clientsProfile', ['getCards']),
    getStatus(status) {
      return statuses[status];
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';
.tab_cards {
  .tab_cards__title {
    font-size: 24px;
    margin: 10px;
  }

  .tab_cards__container {
    margin: 20px 0;
    padding: 20px 0;

    .tabs__container__empty {
      text-align: center;
      padding: 30px;
      line-height: 16px;
      font-size: 20px;
      color: $gray-light;
    }

    .tab_cards__row {
      display: block;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      margin-bottom: 20px;
      padding: 10px;

      .tab_cards__row__status {
        font-weight: bold;

        .tab_cards__verified__status {
          display: inline-block;
          padding-right: 5px;
        }

        .tab_cards__default__status {
          display: inline-block;
        }
      }

      & .verified {
        color: green;
      }
      & .verifying {
        color: #1867c0;
      }
      & .unverified {
        color: red;
      }

      .tab_cards__cell {
        display: inline-block;
        width: 25%;
        padding: 15px 5px;
      }

      .tab_cards__cell__card {
        display: inline-block;
        padding: 5px 20px;
        width: 15%;

        &.card__main {
          font-weight: bold;
          float: right;
        }
      }
    }
  }
}
</style>
