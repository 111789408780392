import { setField } from 'common/store/helpers';
import moment from 'common/helpers/date';
import utils from '@/helpers/utils';

import { TRANSPORT_VERSION, TRANSPORT_SCHEME } from 'common/api/TransportApi';

const state = {
  scheme: null,
  version: null,
  items: [],
  info: null,
  lastInsertedItems: []
};

const actions = {
  async getItems({ dispatch, commit, rootGetters: { 'common/loading': loading } }) {
    if (loading('admin/verification/get_list')) {
      return;
    }
    commit('setField', { field: 'items', value: [] });
    const topic = 'admin/verification/get_list';
    try {
      const data = await dispatch('common/createController', { topic }, { root: true });
      const items = [...data.operator_list, ...data.common_list].map(item => ({
        ...item,
        type: `${utils.titles[item.parent_table] || ''}`,
        status: `${utils.statuses[item.status] || ''}`,
        date: moment(item.date).format('DD.MM.YYYY, HH:mm')
      }));
      commit('setField', { field: 'items', value: items });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  // TODO Pizdec
  getVersion({ dispatch, commit }) {
    if (state.version) {
      return Promise.resolve(state.version);
    }
    return dispatch('common/createController', { topic: TRANSPORT_VERSION }, { root: true })
      .then(data => {
        if (data.status.result === 'accepted') {
          commit('setField', { field: 'version', value: data.version });
          return data.version;
        }
        return false;
      })
      .catch(() => {
        commit('errorPush', 'Ошибка при выгрузке с сервера', { root: true });
      });
  },
  // TODO Pizdec
  // TODO: тут даже трогать ничего не стал, просто ...
  async getScheme({ dispatch, commit }) {
    if (state.scheme) {
      return Promise.resolve(state.scheme);
    }
    const version = await dispatch('getVersion', {});
    return dispatch(
      'common/createController',
      {
        topic: TRANSPORT_SCHEME,
        json: {
          version
        }
      },
      { root: true }
    )
      .then(data => {
        if (data.status.result === 'accepted') {
          const scheme = data.scheme.settings;

          scheme.ids = {};
          [
            'characteristics',
            'lifting',
            'carcasses',
            'options',
            'options_chars',
            'type_carcasses'
          ].forEach(type => {
            scheme.ids[type] = {};
            scheme[type].forEach(item => {
              scheme.ids[type][item.id] = item;
            });
          });
          commit('setField', { field: 'scheme', value: scheme });

          return scheme;
        }
        return false;
      })
      .catch(() => {
        commit('errorPush', 'Ошибка при выгрузке с сервера', { root: true });
      });
  },
  async getInfo({ dispatch, commit }, vehicleId) {
    commit('setField', { field: 'info', value: null });
    const topic = 'admin/verification/choose';
    try {
      const data = await dispatch(
        'common/createController',
        { topic, json: { cparent_id: vehicleId } },
        { root: true }
      );
      let scheme = null;
      if (data.vehicle) {
        scheme = await dispatch('getScheme');
        if (scheme) {
          data.carcass = scheme.ids[data.vehicle.carcass] || false;
          data.props = {
            lifting: [],
            options: [],
            options_chars: [],
            characteristics: []
          };
          const optionsNames = {
            characteristics: 'characteristics',
            // options_chars: 'options',
            lifting: 'lifting'
            // options: 'options',
          };
          data.properties.forEach(item => {
            Object.keys(optionsNames).forEach(type => {
              if (scheme.ids[type][item.name]) {
                data.props[optionsNames[type]].push({
                  ...item,
                  ...scheme.ids[type][item.name],
                  value: item.value
                });
              }
            });
            const elementOpt = scheme.ids.options[item.name];
            if (elementOpt) {
              elementOpt.characteristics = elementOpt.characteristics
                .map(option => {
                  const optChar = scheme.options_chars.find(chr => chr.id === option);
                  if (optChar) {
                    const prop = data.properties.find(propItem => propItem.name === optChar.id);
                    if (prop) {
                      optChar.carrier.value = prop.value;
                    }
                    return optChar;
                  }
                  return false;
                })
                .filter(option => !!option);
              data.props.options_chars.push(elementOpt);
            }
          });
        } else {
          commit('errorPush', 'Ошибка при выгрузке с сервера', { root: true });
        }
      }
      if (data.type !== 'vehicle') {
        let minPassportDate;
        const maxLicenseDate = moment(data.license_date).add(10, 'years');
        const age = moment().diff(moment(data.birthday_date), 'years', true);
        if (age > 45) {
          minPassportDate = moment(data.birthday_date).add(45, 'years');
        } else if (age > 20) {
          minPassportDate = moment(data.birthday_date).add(20, 'years');
        }
        data.overdue = {
          passport:
            !!minPassportDate &&
            moment(minPassportDate, 'DD.MM.YYYY').isAfter(data.passport_date, 'day'),
          license:
            moment(maxLicenseDate, 'DD.MM.YYYY').isBefore(new Date(), 'day') ||
            moment(maxLicenseDate, 'DD.MM.YYYY').isAfter(moment().add(10, 'years'), 'days')
        };
        data.birthday_date = moment(data.birthday_date).format('DD.MM.YYYY');
        data.passport_date = moment(data.passport_date).format('DD.MM.YYYY');
        data.license_date = moment(data.license_date).format('DD.MM.YYYY');
      }
      commit('setField', { field: 'info', value: data });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
      commit('errorPush', 'Ошибка при выгрузке с сервера', { root: true });
    }
  },
  async denialVerification({ dispatch, commit }, { info, deniedText }) {
    const json = {
      cid: info.vehicle ? info.vehicle.cid : info.cid,
      comment: deniedText
    };
    const topic = 'admin/verification/denial';
    try {
      const data = await dispatch('common/createController', { topic, json }, { root: true });
      dispatch('getItems');
      commit('setField', { field: 'version', value: data.version });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async verifyCancel({ state, dispatch, commit }, cid) {
    const topic = 'admin/verification/deallocate';
    const vehicle = state.items.find(_ => _.cparent_id === cid);
    if (vehicle && vehicle.status === 'На верификации') {
      const vehicles = state.items.filter(_ => _.cparent_id !== cid);
      commit('setField', { field: 'items', value: vehicles });
    }
    const json = {
      cid,
      comment: 'close dialog'
    };
    try {
      await dispatch('common/createController', { topic, json }, { root: true });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
      commit('errorPush', 'Ошибка при выгрузке с сервера', { root: true });
    }
  },
  async checkVerify({ dispatch, commit }, { info, isSave }) {
    const topic = 'admin/verification/checkmark';
    let json;
    if (info.type === 'agent') {
      json = {
        cid: info.cid,
        check_photo: info.check_photo,
        check_inn: info.check_inn,
        check_lastname: info.check_lastname,
        check_name: info.check_name,
        check_birthday_date: info.check_birthday_date,
        check_passport_date: info.check_passport_date,
        check_passport_serie: info.check_passport_serie,
        check_passport_number: info.check_passport_number,
        check_passport_fms: info.check_passport_fms,
        check_passport_tax: info.check_passport_tax
      };
    } else if (info.type === 'vehicle') {
      json = {
        cid: info.vehicle.cid,
        check_vin: info.vehicle.check_vin,
        check_brand_and_model: info.vehicle.check_brand_and_model,
        check_registration_plate: info.vehicle.check_registration_plate,
        check_release_year: info.vehicle.check_release_year,
        check_type_right_use: info.vehicle.check_type_right_use,
        check_valid_until: info.vehicle.check_valid_until,
        check_wagon_type: info.vehicle.check_wagon_type,
        check_gibdd: info.vehicle.check_gibdd,
        check_name_carier_holder: info.vehicle.check_name_carier_holder,
        check_name_holder_docs: info.vehicle.check_name_holder_docs,
        check_name_carier_docs: info.vehicle.check_name_carier_docs
      };
      if (info.vehicle.vehicle_type === 'tractor') {
        json.check_wagon_type = true; // TODO don't forget it! it's not right
      }
      if (info.vehicle.type_right_use === 'proprietary') {
        json.check_valid_until = true; // TODO don't forget it! it's not right
        json.check_name_holder_docs = true; // TODO don't forget it! it's not right
        json.check_name_carier_docs = true; // TODO don't forget it! it's not right
      } else {
        json.check_name_carier_holder = true; // TODO don't forget it! it's not right
      }
      if (info.vehicle.vehicle_type === 'mover' || info.vehicle.vehicle_type === 'tractor') {
        json.check_wagon_type = true; // TODO don't forget it! it's not right
      }
    } else if (info.type === 'truckers') {
      json = {
        cid: info.cid,
        check_photo: info.check_photo,
        check_lastname: info.check_lastname,
        check_name: info.check_name,
        check_patronymic: info.check_patronymic,
        check_birthday_date: info.check_birthday_date,
        check_passport_serie: info.check_passport_serie,
        check_passport_number: info.check_passport_number,
        check_passport_date: info.check_passport_date,
        check_passport_fms: info.check_passport_fms,
        check_license_serie: info.check_license_serie,
        check_license_number: info.check_license_number,
        check_license_date: info.check_license_date,
        check_license_gibdd: info.check_license_gibdd,
        check_birthday_place_pas_lic: info.check_birthday_place_pas_lic,
        check_birthday_date_pas_lic: info.check_birthday_date_pas_lic
      };
    }
    try {
      await dispatch('common/createController', { topic, json }, { root: true });
      if (!isSave) dispatch('afterVerify', info);
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
      commit('errorPush', 'Ошибка при выгрузке с сервера', { root: true });
    }
  },
  async afterVerify({ dispatch, commit }, info) {
    const topic = 'admin/verification/approve';
    const cid = info.vehicle ? info.vehicle.cid : info.cid;
    try {
      await dispatch('common/createController', { topic, json: { cid } }, { root: true });
      dispatch('getItems');
    } catch (e) {
      commit('errorPush', 'Ошибка при выгрузке с сервера', { root: true });
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async saveVin({ dispatch, commit }, { cid, vin }) {
    const topic = 'admin/verification/store_vin';
    try {
      await dispatch('common/createController', { topic, json: { cid, vin } }, { root: true });
    } catch (e) {
      commit('errorPush', 'Ошибка ввода VIN-номера', { root: true });
      throw new Error(`Ошибка в запросе ${topic}`, e);
    }
  },
  subscribeVerification({ dispatch, commit }) {
    dispatch(
      'common/subscribe',
      {
        topic: 'upd/admin/verification',
        callback(response) {
          const itemsToAdd = [];
          const itemsToDelete = [];
          response.forEach(item => {
            if (item.type === 'delete') itemsToDelete.push(item.parent_id);
            else {
              itemsToAdd.push({
                ...item,
                ...item.data,
                type: `${utils.titles[item.data.parent_table] || ''}`,
                status: `${utils.statuses[item.data.status] || ''}`,
                date: moment(item.data.date).format('DD.MM.YYYY, HH:mm')
              });
            }
          });
          commit('setItemsFromSubscribe', itemsToAdd);
          commit('deleteItemsFromSubscribe', itemsToDelete);
        }
      },
      {
        root: true
      }
    );
  }
};
const mutations = {
  setItemsFromSubscribe(state, additionalItems) {
    const items = [
      ...state.items.filter(_ => !additionalItems.find(item => item.parent_id === _.parent_id)),
      ...additionalItems
    ];
    if (additionalItems.length) state.lastInsertedItems = [...additionalItems];
    state.items = items;
  },
  deleteItemsFromSubscribe(state, itemsToDelete) {
    const items = [...state.items].filter(item => !itemsToDelete.includes(item.parent_id));
    state.items = items;
  },
  setField
};
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
