<template>
  <!-- TODO: need refactor this component -->
  <div class="settings">
    <v-expansion-panel>
      <v-expansion-panel-content v-for="(group, index) in groups" :key="index">
        <template slot="header">
          <div class="header" @click="openGroup(group)">
            {{ group.settings_group }}
          </div>
        </template>
        <div v-if="isLoading" class="spinner">
          <v-progress-circular :size="30" color="primary" indeterminate />
        </div>
        <div v-for="(item, key) in list" v-else :key="item.id" class="setting">
          <v-text-field
            v-model="item.id"
            class="small"
            label="ID"
            placeholder=" "
            readonly="readonly"
          />
          <v-text-field
            v-model="item.comment"
            label="Название"
            placeholder=" "
            :readonly="item.readonly"
          />
          <v-text-field
            v-model="item.type"
            class="small"
            label="Тип значения"
            placeholder=" "
            readonly="readonly"
          />
          <v-text-field
            v-if="item.type === 'string'"
            v-model="item.value"
            label="Значение"
            placeholder=" "
            :readonly="item.readonly"
            @blur="saveString($event, key)"
          />
          <v-text-field
            v-else-if="item.type === 'integer'"
            v-model.number="item.value"
            label="Значение"
            placeholder=" "
            :readonly="item.readonly"
            type="number"
            :class="{ field_error: item.error }"
          />
          <v-text-field
            v-else-if="item.type === 'float'"
            v-model.number="item.value"
            label="Значение"
            placeholder=" "
            :readonly="item.readonly"
            type="number"
            :class="{ field_error: item.error }"
          />
          <v-text-field
            v-else-if="item.type === 'json'"
            v-model="item.value"
            label="Значение"
            placeholder=" "
            :readonly="item.readonly"
            :class="{ field_error: item.error }"
          />
          <div v-else-if="item.type === 'bool'" class="text__field">
            Значение
            <div class="text__field__checkbox">
              <v-checkbox
                v-model="item.value"
                :label="item.value.toString()"
                :disabled="item.readonly"
              />
            </div>
          </div>
          <div v-else-if="item.type === 'date'" class="settings__date_picker">
            <v-text-field label="Значение" :value="item.value" readonly="readonly" />
            <div v-if="!item.readonly" class="date_picker">
              <v-date-picker v-model="item.value" :landscape="true" :reactive="true" />
            </div>
          </div>
          <v-icon v-if="item.readonly" :disabled="!isSuper" class="icon" @click="editSetting(key)">
            create
          </v-icon>
          <v-icon v-else class="icon" @click="sendSetting(key)">
            done
          </v-icon>
          <div v-if="item.error" class="setting__error">
            Неверное значение
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      currentIndex: null,
      currentInputs: null,
      openedGroup: '',
      menu: false,
      datepicker: false,
      isLoading: false
    };
  },
  computed: {
    ...mapState('adminSettings', ['list', 'groups']),
    ...mapState('login', ['isSuper'])
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions('adminSettings', ['getSettings', 'getGroupSettings', 'setSetting']),
    getData() {
      this.getSettings();
    },
    async openGroup(data) {
      if (this.openedGroup.id === data.id) {
        return;
      }
      this.isLoading = true;
      await this.getGroupSettings(data);
      this.openedGroup = data;
      this.isLoading = false;
    },
    editSetting(key) {
      this.list[key].readonly = false;
    },
    sendSetting(key) {
      const item = this.list[key];
      switch (item.type) {
        case 'string':
          if (item.value) {
            item.value = item.value.trim();
          } else {
            item.readonly = true;
            return;
          }
          break;
        case 'integer':
          item.value = parseInt(item.value, 10);
          break;
        default:
      }
      item.readonly = true;
      this.setSetting(item);
    },
    saveString(e, key) {
      this.list[key].value = e.target.value;
    }
  }
};
</script>

<style lang="scss">
.settings {
  padding-top: 20px;

  .v-expansion-panel__header {
    padding: 0;
    position: relative;

    .header {
      padding: 12px 24px;
      position: absolute;
      width: 100%;
      z-index: 3;
    }
    .v-expansion-panel__header__icon {
      position: absolute;
      z-index: 2;
      right: 10px;
    }
  }
  .setting {
    position: relative;
    display: flex;
    padding: 10px 20px;
    .icon {
      margin: 25px 10px;
      border-radius: 5px;
      &:hover {
        cursor: pointer;

        &:active {
          background-color: lightgray;
        }
      }
    }
    .v-input,
    .text__field,
    .settings__date_picker {
      align-self: flex-start;
      width: calc(50% - 140px);
      margin: 0 10px;

      &.small {
        margin: 0;
        max-width: 100px;
        min-width: 100px;
      }
    }
    .text__field {
      min-height: 45px;
      font-size: 12px;
      line-height: 1;
      border-bottom: 1px solid rgba(0, 0, 0, 0.54);
      color: rgba(0, 0, 0, 0.54);
      .text__field__text {
        padding-top: 3px;
        font-size: 16px;
        line-height: 1.5;
        height: 100%;
        color: black;
        outline: none;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .text__field__checkbox {
        height: 30px;
        overflow: hidden;
      }
    }
    .settings__date_picker {
      .v-input {
        width: 100%;
        margin: 0;
      }
      .date_picker {
        position: absolute;
        z-index: 4;
        top: 55px;
      }
    }
    .setting__error {
      position: absolute;
      color: red;
      bottom: 15px;
      right: calc(50% - 210px);
      font-size: 13px;
    }
  }

  .spinner {
    text-align: center;
    padding: 10px 0;
  }
}
</style>
