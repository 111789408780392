import moment from 'common/helpers/date';
import { setField } from 'common/store/helpers';

const state = {
  balance_payments: {},
  balance_bonuses: {},
  balance_card: {},
  settings: [],
  list: []
};

const actions = {
  async getList({ dispatch, commit }, json) {
    commit('setField', { field: 'list', value: [] });
    const topic = 'admin/endusers/contractor/payments/list';
    try {
      const data = await dispatch('common/createController', { topic, json }, { root: true });
      data.operations = data.operations.map(item => {
        item.date = moment(item.date).format('DD.MM.YYYY в HH:mm');
        item.movements = item.movements.map(move => {
          move.date = moment(move.date).format('DD.MM.YYYY в HH:mm');
          return {
            ...move
          };
        });
        return {
          ...item,
          cost: item.sum < 0 ? Math.abs(item.sum) : 0,
          coming: item.sum > 0 ? item.sum : 0
        };
      });
      commit('setField', { field: 'list', value: data });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async getBalanceCard({ dispatch, commit }, enduser) {
    commit('setField', { field: 'balance_card', value: '' });
    const topic = 'admin/endusers/contractor/payments/card_pending_transactions/remains/get';
    try {
      const { blocked } = await dispatch(
        'common/createController',
        { topic, json: { enduser } },
        { root: true }
      );
      commit('setField', { field: 'balance_card', value: blocked });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async getBalance({ dispatch, commit }, { enduser, type }) {
    const topic = `admin/endusers/contractor/${type}/balance/get`;
    try {
      const { balance } = await dispatch(
        'common/createController',
        { topic, json: { enduser } },
        { root: true }
      );
      commit('setBalance', { balance, type });
    } catch (e) {
      console.error(`Ошибка в запросе ${topic}`);
    }
  },
  async getSettings({ dispatch, commit }, enduser) {
    commit('setField', { field: 'settings', value: [] });
    const topic = 'admin/endusers/contractor/payments/settings';
    try {
      const { order } = await dispatch(
        'common/createController',
        { topic, json: { enduser } },
        { root: true }
      );
      commit('setField', { field: 'settings', value: order || [] });
    } catch (error) {
      console.error(error);
    }
  }
};

const mutations = {
  setField,
  setBalance(state, { balance, type }) {
    state[`balance_${type}`] = balance;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
