<template>
  <div v-if="loading('admin/endusers/profile/users/get')" class="loading">
    <v-progress-circular indeterminate="indeterminate" color="primary" :size="20" />
  </div>
  <div v-else class="profile_users">
    <div class="profile_users__content">
      <div class="profile__title">
        Администратор аккаунта
      </div>
      <div v-if="info" class="profile_users__fields">
        <v-checkbox v-model="checkEmail" :readonly="true" label="Email Подтвержден" />
      </div>
      <div v-if="info" class="profile_users__fields">
        <v-text-field
          class="profile_users__field"
          label="Имя"
          :value="info.fname"
          placeholder=" "
          readonly="readonly"
        />
        <v-text-field
          class="profile_users__field"
          label="Фамилия"
          :value="info.lname"
          placeholder=" "
          readonly="readonly"
        />
        <v-text-field
          class="profile_users__field"
          label="E-mail"
          :value="info.email"
          placeholder=" "
          readonly="readonly"
        />
        <v-text-field
          class="profile_users__field"
          label="Телефон"
          :value="info.phone"
          placeholder=" "
          readonly="readonly"
        />
      </div>
      <div v-if="items.length" class="profile__title">
        Пользователи аккаунта
      </div>
      <v-expansion-panel v-if="items" class="profile_users__list">
        <v-expansion-panel-content v-for="(user, index) in items" :key="index">
          <div slot="header" class="profile_users__row">
            <span class="profile_users__label">{{ user.fname }} {{ user.lname }}</span>
            <span
              class="profile_users__status"
              :class="{
                accepted: user.is_confirmed,
                wait: !user.is_confirmed,
                rejection: !user.is_confirmed && user.phase === 'attached'
              }"
            >
              {{ getStatus(user) }}
            </span>
          </div>
          <div class="profile_users__fields">
            <v-text-field
              class="profile_users__field"
              label="Имя"
              :value="user.fname"
              placeholder=" "
              readonly="readonly"
            />
            <v-text-field
              class="profile_users__field"
              label="Фамилия"
              :value="user.lname"
              placeholder=" "
              readonly="readonly"
            />
            <v-text-field
              class="profile_users__field"
              label="E-mail"
              :value="user.email"
              placeholder=" "
              readonly="readonly"
            />
            <v-text-field
              class="profile_users__field"
              label="Телефон"
              :value="user.phone"
              placeholder=" "
              readonly="readonly"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'ProfileTabUsers',
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapState('clientsProfile', ['items', 'info', 'total']),
    checkEmail() {
      return !!this.info.email_confirmed;
    },
    enduser_id() {
      return this.$route.params.enduser || false;
    }
  },
  mounted() {
    this.getItems({ enduser_id: this.enduser_id });
  },
  methods: {
    ...mapActions('clientsProfile', ['getItems']),
    getStatus(user) {
      const notConfirmedStatus = user.phase !== 'attached' ? 'Ожидается подтверждение' : 'Отказ';
      return user.is_confirmed ? 'Подтвержден' : notConfirmedStatus;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';
.profile_users {
  &__row {
    display: flex;
  }
  &__label {
    min-width: 200px;
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__status {
    margin: 0 0 0 300px;
    &.wait {
      color: $color-warning;
    }
    &.accepted {
      color: $color-verified;
    }
    &.rejection {
      color: $color-danger;
    }
  }

  .profile__title {
    font-size: 24px;
    padding: 20px 10px;
  }

  .profile_users__fields {
    display: flex;
    padding: 10px;

    .profile_users__field {
      padding: 2px 10px 2px 0;
    }
  }
}
</style>
