<template>
  <div>
    <deadline-timer v-if="!isOfferAccepted" class="timer" />
    <cargo-owner-conditions :order="info" :is-operator="isOperator" />
    <content-block v-if="offers.length" :subtitle="subtitle" subtitle-tag="h2" class="offers">
      <offer-item
        v-for="offer in offers"
        :key="offer.cid"
        :offer="offer"
        :order="info"
        :is-active="activeOffer === offer.cid"
        :is-accepted="isOfferAccepted"
        :is-operator="isOperator"
        @click="activeOffer = $event"
        @submit="acceptOffer"
      />
    </content-block>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import ContentBlock from 'common/components/Orders/ContentBlock';
import CargoOwnerConditions from './OrderView/CargoOwnerConditions.vue';
import DeadlineTimer from './OrderView/DeadlineTimer.vue';
import OfferItem from './OrderView/OfferItem.vue';

export default {
  name: 'OrderViewOffers',
  components: {
    DeadlineTimer,
    ContentBlock,
    OfferItem,
    CargoOwnerConditions
  },
  props: {
    isOperator: Boolean
  },
  data: () => ({
    activeOffer: null
  }),
  computed: {
    ...mapState('orders', {
      info: state => state.orderInfo.info,
      offers: state => state.orderInfo.offersList
    }),
    ...mapGetters('login', ['isShipper']),

    isOfferAccepted() {
      return !['waiting_for_offer'].includes(this.info.status);
    },

    subtitle() {
      if (!this.isShipper) {
        return 'Ваше предложение';
      }

      return this.isOfferAccepted ? 'Предложение перевозчика' : 'Предложения перевозчиков';
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getOffersList();
      if (!vm.isOfferAccepted && !vm.isOperator) vm.newOffersSubscribe();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isOfferAccepted && !this.isOperator) {
      this.newOffersUnsubscribe();
      // TODO: потом когда будет новый топик для сброса статуса new у предложений - выпилить.
      this.getOffersList(true);
    }
    next();
  },
  methods: {
    ...mapActions('orders', [
      'getOffersList',
      'acceptOffer',
      'newOffersSubscribe',
      'newOffersUnsubscribe'
    ])
  }
};
</script>

<style lang="scss" scoped>
.timer {
  margin-bottom: 12px;
}

.offers {
  margin-top: 20px;
}
</style>
