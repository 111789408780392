<template>
  <div class="content_management">
    <Tabs :tabs="tabs" :active="activeTab" @change="changeTab" />
  </div>
</template>

<script>
import Tabs from '../Layout/Tabs.vue';
import Counting from './TabCounting.vue';
import Payments from './TabPayments.vue';
import Docs from './TabDocs.vue';

export default {
  components: {
    Tabs
  },
  data() {
    return {
      tabs: [
        { name: 'counting', title: 'Бухгалтерия', component: Counting },
        { name: 'payments', title: 'Заявки на платеж', component: Payments },
        { name: 'docs', title: 'Документы', component: Docs },
        { name: 'reports', title: 'Отчеты', disabled: true }
      ],
      activeTab: this.$route.params.stage || 'counting'
    };
  },
  methods: {
    changeTab(stage) {
      this.$router.push({ name: 'CountingDocsStage', params: { stage } });
    }
  }
};
</script>

<style lang="scss">
.content_management {
  position: relative;
  height: 100%;
}
</style>
