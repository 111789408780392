<template>
  <div class="service-price">
    <h2 class="ui-kit__heading">
      Обновление оценки надежности
    </h2>
    <div class="column">
      <v-text-field
        ref="priceValue"
        v-model="priceValue"
        class="ui-kit__input"
        :disabled="disabled.priceValue"
        :label="priceLabel"
        placeholder=" "
        :rules="!disabled.priceValue ? number : []"
        @keyup="checkOnValid('priceValue')"
      />
      <v-btn
        v-show="disabled.priceValue"
        class="ui-kit__button column__btn"
        icon=""
        flat=""
        color="news"
        :disabled="!isSuper"
        @click="disabled.priceValue = false"
      >
        <v-icon>edit</v-icon>
      </v-btn>
      <v-btn
        v-show="!disabled.priceValue"
        class="ui-kit__button column__btn"
        icon=""
        flat=""
        color="news"
        :disabled="!valid.priceValue"
        @click="changePriceUpdate('priceValue')"
      >
        <v-icon>check</v-icon>
      </v-btn>
    </div>
    <h2 class="ui-kit__heading">
      Заказ первичной документации
    </h2>
    <div class="column">
      <v-text-field
        ref="orderValue"
        v-model="orderValue"
        class="ui-kit__input"
        :disabled="disabled.orderValue"
        :label="orderLabel"
        placeholder=" "
        :rules="!disabled.orderValue ? number : []"
        @keyup="checkOnValid('orderValue')"
      />
      <v-btn
        v-show="disabled.orderValue"
        class="ui-kit__button column__btn"
        icon=""
        flat=""
        color="news"
        :disabled="!isSuper"
        @click="disabled.orderValue = false"
      >
        <v-icon>edit</v-icon>
      </v-btn>
      <v-btn
        v-show="!disabled.orderValue"
        class="ui-kit__button column__btn"
        icon=""
        flat=""
        color="news"
        :disabled="!valid.orderValue"
        @click="changePriceUpdate('orderValue')"
      >
        <v-icon>check</v-icon>
      </v-btn>
    </div>
    <h2 class="ui-kit__heading">
      Основные услуги
    </h2>
    <div v-for="(item, i) in tariffs" :key="i">
      <tariffs
        :item="item"
        :last-el="i + 1 === tariffs.length"
        :tariffs.sync="tariffs"
        :first-el="i === 0"
        :is-super="isSuper"
        @changeTariffs="changeTariffs(i)"
        @removeTariffs="removeTariffs(i)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Tariffs from './Tariffs';

export default {
  components: {
    Tariffs
  },
  data() {
    return {
      priceValue: '',
      priceLabel: '',
      orderValue: '',
      orderLabel: '',
      currentService: null,
      tariffs: [],
      currentActiveItem: null,
      number: [v => /^[1-9][0-9]*([.][0-9]{2}|)$/.test(v) || 'только числа'],

      disabled: {
        priceValue: true,
        orderValue: true
      },

      valid: {
        priceValue: true,
        orderValue: true
      }
    };
  },
  computed: {
    ...mapState({
      prices: state => state.administration.prices,
      isSuper: state => state.login.isSuper
    })
  },
  async mounted() {
    await this.getPrices();
    this.priceValue = this.prices.price_reliability.value;
    this.priceLabel = `${this.prices.price_reliability.comment}, ${this.prices.price_reliability.measure}`;
    this.orderValue = this.prices.price_primary_doc.value;
    this.orderLabel = `${this.prices.price_primary_doc.comment}, ${this.prices.price_primary_doc.measure}`;
    this.tariffs = this.prices.tariffs.map(tariff => ({ ...tariff }));
  },
  methods: {
    ...mapActions('administration', ['getPrices', 'updatePricesData', 'updateTariffs']),

    checkOnValid(field) {
      this.valid[field] = this.$refs[field].valid;
    },
    async changePriceUpdate(field) {
      const fieldJson = field === 'priceValue' ? 'price_reliability' : 'price_primary_doc';

      if (parseFloat(this[field]) === this.prices[fieldJson].value) {
        this.disabled[field] = true;
        return;
      }
      await this.updatePricesData({ field: fieldJson, value: this[field] });
      this.disabled[field] = true;
    },
    changeTariffs(index) {
      if (
        this.prices.tariffs[index] &&
        this.tariffs[index].limit === this.prices.tariffs[index].limit &&
        this.tariffs[index].percent === this.prices.tariffs[index].percent
      ) {
        return;
      }
      this.updateTariffs({ tariffs: this.tariffs });
    },
    removeTariffs(index) {
      this.tariffs.splice(index, 1);
      this.updateTariffs({ tariffs: this.tariffs });
    }
  }
};
</script>

<style lang="scss" scoped>
.service-price {
  .column {
    position: relative;
    width: 33.3333%;
    margin-right: 15px;

    &__btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: 5px 0 0 0;
    }
  }
}
</style>
