<template>
  <v-container
    v-model="formValid"
    row
    wrap
    grid-list-xl
    tag="v-form"
    class="form"
    @submit.prevent="$emit('submit')"
  >
    <v-toolbar dark="dark" color="primary">
      <v-toolbar-title>
        Отправка уведомления
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <custom-button icon color="white" @click="$emit('close')">
          <v-icon>close</v-icon>
        </custom-button>
      </v-toolbar-items>
    </v-toolbar>
    <v-layout class="form__content">
      <v-flex xs6 class="pb-0">
        <custom-input
          :value="title"
          label="Тема уведомления"
          :disabled="isArchived"
          :rules="[v => !!v || 'Поле обязательно для заполнения']"
          @input="saveValue($event, 'title')"
        />
        <v-select
          v-model="selectedGroup"
          label="Группа рассылки"
          :items="groups"
          item-text="title"
          item-value="name"
          return-object
          :disabled="isArchived"
          :rules="[v => !!v || 'Поле обязательно для заполнения']"
          @change="change"
        />
        <v-checkbox
          v-model="sendEmail"
          :disabled="isArchived"
          class="checkbox"
          label="Отправка по e-mail"
          @change="change($event, 'textEmail')"
        />
        <custom-input
          v-show="sendEmail"
          :value="emailSubject"
          input-type="textarea"
          rows="1"
          label="Тема уведомления  на Email"
          :disabled="isArchived"
          :rules="sendEmail ? [v => !!v || 'Поле обязательно для заполнения'] : []"
          @input="saveValue($event, 'emailSubject')"
        />
        <custom-input
          v-show="sendEmail"
          id="textEmail"
          ref="textEmail"
          :value="textEmail"
          input-type="textarea"
          rows="3"
          label="Текст уведомления Email"
          :disabled="isArchived"
          :rules="sendEmail ? [v => !!v || 'Поле обязательно для заполнения'] : []"
          @input="saveValue($event, 'textEmail')"
          @blur="saveCursor"
        />
        <v-checkbox
          v-model="sendSms"
          :disabled="isArchived"
          class="checkbox"
          label="Отправка по СМС"
          @change="change($event, 'textSms')"
        />
        <custom-input
          v-show="sendSms"
          id="textSms"
          ref="textSms"
          :value="textSms"
          input-type="textarea"
          rows="3"
          label="Текст уведомления Sms"
          :disabled="isArchived"
          :rules="sendSms ? [v => !!v || 'Поле обязательно для заполнения'] : []"
          @input="saveValue($event, 'textSms')"
          @blur="saveCursor"
        />
        <v-checkbox
          v-model="sendWeb"
          :disabled="isArchived"
          class="checkbox"
          label="Отправка web"
          @change="change($event, 'textWeb')"
        />
        <custom-input
          v-show="sendWeb"
          id="textWeb"
          ref="textWeb"
          :value="textWeb"
          input-type="textarea"
          rows="3"
          label="Текст уведомления Web"
          :disabled="isArchived"
          :rules="sendWeb ? [v => !!v || 'Поле обязательно для заполнения'] : []"
          @input="saveValue($event, 'textWeb')"
          @blur="saveCursor"
        />
        <v-checkbox
          v-model="sendPush"
          :disabled="isArchived"
          class="checkbox"
          label="Отправка push"
          @change="change($event, 'textPush')"
        />
        <custom-input
          v-show="sendPush"
          id="textPush"
          ref="textPush"
          :value="textPush"
          input-type="textarea"
          rows="3"
          label="Текст уведомления Push"
          :disabled="isArchived"
          :rules="sendPush ? [v => !!v || 'Поле обязательно для заполнения'] : []"
          @input="saveValue($event, 'textPush')"
          @blur="saveCursor"
        />
        <datepicker
          v-model="date"
          label="Дата отправки"
          :min-date="new Date().toISOString().substr(0, 10)"
          :rules="[v => !!v || 'Поле обязательно для заполнения']"
          :disabled="isArchived"
          @input="change"
        />
      </v-flex>
      <v-flex xs6 class="tags pb-0">
        <div class="tags__title">
          Список тегов
        </div>
        <v-btn
          v-for="tag in tags"
          :key="tag.value"
          class="tags__button"
          round="round"
          flat="flat"
          color="black"
          @click="onClick(tag.value)"
        >
          <v-icon style="font-size: 12px; margin-right: 10px;">
            local_offer
          </v-icon>
          {{ tag.name }}
        </v-btn>
      </v-flex>
      <v-flex xs12 wrap class="pa-0">
        <custom-button
          v-if="!isArchived"
          color="primary"
          class="outline"
          :disabled="!formValid || !isChanged"
          :loading="loading('adm_circular/save')"
          @click="saveData"
        >
          Сохранить
        </custom-button>
        <custom-button
          v-if="!isArchived && circular.id"
          color="primary"
          class="outline"
          :loading="loading('adm_circular/archive')"
          @click="$emit('action', 'archive')"
        >
          Удалить
        </custom-button>
        <custom-button
          v-if="!isArchived && circular.id"
          color="primary"
          class="outline"
          :loading="loading('adm_circular/archive')"
          :disabled="isChanged"
          @click="$emit('action', 'start')"
        >
          Отправить
        </custom-button>
        <custom-button
          v-if="isArchived && circular.id"
          color="primary"
          class="outline"
          :loading="loading('adm_circular/freeze')"
          :disabled="(isDayDispatch && started) || circular.freezed"
          @click="$emit('action', 'freeze')"
        >
          Отмена отправки
        </custom-button>
        <custom-button color="primary" class="outline" @click="$emit('close')">
          Закрыть
        </custom-button>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Datepicker from 'common/components/form/Datepicker.vue';
import moment from 'common/helpers/date';
import { insertTag } from '@/helpers/';

export default {
  name: 'CircularForm',
  components: {
    Datepicker
  },
  props: {
    circular: Object,
    groups: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedGroup: '',
      formValid: false,
      title: '',
      isArchived: false,
      isChanged: false,
      sendSms: false,
      sendEmail: false,
      sendWeb: false,
      sendPush: false,
      date: null,
      emailSubject: '',
      textEmail: '',
      textSms: '',
      textWeb: '',
      textPush: '',
      currentCursorElement: ''
    };
  },
  computed: {
    ...mapGetters('common', ['loading']),
    isLoading() {
      return this.loading('adm_circular/save');
    },
    isDayDispatch() {
      return new Date(this.date) < new Date();
    }
  },
  watch: {
    circular: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.emailSubject = val.parametrs.email_subject;
        this.textEmail = val.parametrs.email_text;
        this.textSms = val.parametrs.sms;
        this.textWeb = val.parametrs.web;
        this.textPush = val.parametrs.push;
        this.tags = val.parametrs.tags;
        this.title = val.title;
        this.selectedGroup = val.group;
        this.isArchived = val.archived;
        this.date = moment(val.sending_date).format('YYYY-MM-DD') || '';
        if (!val.id) return;
        this.sendSms = val.send_by_sms;
        this.sendEmail = val.send_by_email;
        this.sendWeb = val.send_by_web;
        this.sendPush = val.send_by_push;
        this.started = val.started;
      }
    }
  },
  methods: {
    saveCursor(e) {
      this.currentCursorElement = e.srcElement.id;
    },
    onClick(val) {
      if (!this.$refs[this.currentCursorElement]) return;
      const textarea = this.$refs[this.currentCursorElement].$el.querySelector('textarea');
      this[this.currentCursorElement] = insertTag(textarea, val);
      textarea.focus();
      this.isChanged = true;
    },
    saveData() {
      const editedCircular = {
        parametrs: {
          email_text: this.textEmail,
          email_subject: this.emailSubject,
          sms: this.textSms,
          web: this.textWeb,
          push: this.textPush,
          tags: this.tags
        },
        recipient_group: this.selectedGroup.name,
        title: this.title,
        send_by_sms: this.sendSms,
        send_by_email: this.sendEmail,
        send_by_web: this.sendWeb,
        send_by_push: this.sendPush,
        sending_date: this.date,
        started: this.started
      };
      if (this.circular.id) editedCircular.id = this.circular.id;
      this.$emit('save', editedCircular);
    },
    change(e, field) {
      this.isChanged = true;
      if (field && !e) this[field] = '';
    },
    saveValue(val, field) {
      this.isChanged = true;
      this[field] = val;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.form {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  padding: 0;
  position: absolute;
  top: 0;
  background: white;

  /deep/ .v-toolbar__content {
    padding: 16px !important;
  }

  &__content {
    padding: 16px;
    flex-wrap: wrap;
  }
}
.tags {
  &__title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &__button {
    padding: 0 20px;
    font-size: 8px;
    background-color: $gray;
    /deep/ .v-btn__content {
      color: #fff;
      font-size: 12px;
    }
  }
  span {
    min-width: 85px;
    max-width: 85px;
    width: 85px;
    font-weight: bold;
    font-size: 16px;
    color: $color-main;
  }
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
