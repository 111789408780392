import axios from 'axios';
import { setField } from 'common/store/helpers';
import { setUserLoggedIn, setUserLoggedOut } from 'common/service/loginService';

const state = {
  authorized: false,
  phone: '',
  emailUser: '',
  mqttPassword: '',
  cid: '',
  roles: [],
  sections: [],
  counts: {},
  isSuper: null,
  showChat: null,
  previewType: 'shipper'
};

const getters = {
  isShipper: state => state.previewType === 'shipper'
};

const actions = {
  async login({ dispatch, rootState: { defaultURL } }, { email, password }) {
    try {
      const { data } = await axios.post(
        `${defaultURL}/account/login`,
        `login=${email}&password=${password}&app=operators`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': defaultURL
          }
        }
      );
      await dispatch('processLogin', data);
    } catch (error) {
      console.error('Ошибка логина');
    }
  },
  async loginWithToken({ dispatch, rootState: { defaultURL } }, token) {
    try {
      const { data } = await axios.post(
        `${defaultURL}/account/login`,
        `cookie=${token}&app=operators`
      );
      await dispatch('processLogin', data);
    } catch (error) {
      console.error('Ошибка логина по токену');
    }
  },
  async processLogin({ commit, dispatch }, data) {
    if (data.status.result === 'rejected') {
      commit('logout');
      return Promise.reject(data);
    }
    commit('login', data);
    const client = await dispatch('connections/createClient', {}, { root: true });
    client.on('close', () => {
      console.log('close conn');
      commit('logout');
    });
    commit('authorize');
    return data;
  },
  logout({ commit }) {
    commit('logout');
    commit('common/logout', {}, { root: true });

    // сервер после завершения сессии перестает возвращать данные
    window.location.reload();
  },
  // TODO Pizdec
  async getCounts({ dispatch }) {
    const Clients = await new Promise(resolve => {
      dispatch(
        'common/createController',
        {
          topic: 'admin/endusers/count'
        },
        { root: true }
      ).then(data => {
        if (data.status.result === 'accepted') {
          resolve(data.count);
        } else {
          resolve(0);
        }
      });
    });
    return {
      // TODO надо вернуть count разделов в эти переменные и он будет отображаться
      Clients,
      Verifications: 0,
      Disputs: 0,
      ContentManagement: 0,
      Marketing: 0,
      Active_disputs: 0
    };
  }
};
const routes = {
  1: { name: 'Clients', badge: true, icon: 'people' },
  2: { name: 'Verifications', badge: true, icon: 'verified_user' },
  3: { name: 'Disputs', badge: true, icon: 'compare_arrows' },
  4: { name: 'Support', icon: 'contact_support', disabled: true },
  5: { name: 'ContentManagement', badge: true, icon: 'burst_mode' },
  6: { name: 'Marketing', badge: true, icon: 'timeline' },
  7: { name: 'CountingDocs', icon: 'poll' },
  8: { name: 'Administration', icon: 'create' },
  9: { name: 'HistoryChanges', icon: 'list', disabled: true }
};

const mutations = {
  login(state, response) {
    console.log('login', response);
    setUserLoggedIn(response.cookie);
    state.phone = response.mqtt_login;
    state.emailUser = response.email;
    state.mqttPassword = response.mqtt_password;
    state.cid = response.cid;
    state.roles = response.roles;
    state.isSuper = response.access.is_super;
    state.sections =
      response.access?.sections
        .filter(section => section.permission !== 0 && section.sid !== 4)
        .map(section => ({
          path: routes[section.sid],
          title: section.name,
          permission: section.permission,
          disabled: routes[section.sid].disabled
        })) ?? [];
    state.showChat = response.access?.sections?.find(section => section.sid === 4)?.permission ?? 0;
  },
  authorize(state) {
    state.authorized = true;
  },
  logout(state) {
    setUserLoggedOut();
    state.authorized = false;
    state.phone = '';
    state.mqttPassword = '';
    state.cid = '';
    state.roles = [];
    state.sections = [];
  },
  setCounts(state, data) {
    state.counts = data;
  },
  setField
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
