import Vue from 'vue';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';

Vue.use(Viewer);
Viewer.setDefaults({
  zIndexInline: 2
});
