<template>
  <div class="datepicker">
    <v-menu
      ref="menu"
      v-model="showMenu"
      :close-on-content-click="false"
      :nudge-right="0"
      :nudge-top="20"
      :disabled="disabled"
      lazy
      transition="scale-transition"
      offset-y
      full-width
      max-width="290"
      class="menu"
    >
      <custom-input
        slot="activator"
        :value="formatedDatePicker"
        :label="label"
        :error="error"
        :error-messages="errorMessage"
        :rules="rules"
        :disabled="disabled"
        required
        readonly
        @click.prevent.stop="showMenu = !showMenu"
      >
        <template slot="append">
          <icon name="calendar" size="16" class="calendar" />
          <slot name="icon" />
        </template>
      </custom-input>
      <v-date-picker
        ref="picker"
        :value="pickerValue || value"
        :max="maxDate"
        :min="minDate"
        locale="ru-RU"
        required
        @change="onChange"
      />
    </v-menu>
  </div>
</template>

<script>
import moment from 'common/helpers/date';
import CustomInput from 'common/components/CustomInput';

export default {
  name: 'Datepicker',
  props: {
    value: String,
    pickerValue: null,
    label: String,
    format: {
      type: String,
      default: 'DD.MM.YYYY'
    },
    error: Boolean,
    rules: {
      type: Array,
      default: () => []
    },
    maxDate: {
      type: String,
      default: '2118-01-01'
    },
    minDate: {
      type: String,
      default: '1930-01-01'
    },
    disabled: Boolean,
    errorMessage: String
  },
  data: () => ({ showMenu: false }),
  computed: {
    formatedDatePicker() {
      return this.value ? moment.utc(this.value).format(this.format) : '';
    }
  },
  methods: {
    onChange(e) {
      this.showMenu = false;
      this.$emit('input', e);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.datepicker {
  position: relative;
  display: flex;
  align-items: center;
}

.calendar {
  color: $color-main;
}

.menu {
  flex-grow: 1;
}

.icons {
  position: absolute;
  right: 0;
  display: flex;
}
</style>
