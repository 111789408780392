<template>
  <CarcassesFormAbstraction
    ref="bodyWorkForm"
    :is-edit-form="isEditForm"
    :is-add-form="isAddForm"
    @submit="addBodyWork"
    @remove="removeBodyWork"
    @close="$emit('close')"
  >
    <v-flex xs12>
      <custom-input
        v-model="form.name"
        label="Название кузова"
        :rules="[v => !!v || 'Поле обязательно для заполнения']"
        required
      />
    </v-flex>
    <v-flex xs12>
      <custom-input v-model="form.hintCarrier" label="Подсказка ГП" />
    </v-flex>
    <v-flex xs12>
      <custom-input v-model="form.hintOwner" label="Подсказка ГВ" />
    </v-flex>
    <v-flex xs4>
      <v-select
        v-model="form.selectedCharacteristics"
        class="form__multiple"
        multiple="multiple"
        :items="mappedCharacteristics()"
        item-text="full_name"
        item-value="id"
        label="Характеристики"
        placeholder=" "
      />
    </v-flex>
    <v-flex xs4>
      <v-select
        v-model="form.selectedOptions"
        class="form__multiple"
        multiple="multiple"
        :items="options"
        item-text="name"
        item-value="id"
        label="Опции"
        placeholder=" "
      />
    </v-flex>
    <v-flex xs4>
      <v-select
        v-model="form.selectedLiftings"
        class="form__multiple"
        multiple="multiple"
        :items="lifting"
        item-text="name"
        item-value="id"
        label="Способы погрузки/разгрузки"
        placeholder=" "
      />
    </v-flex>
  </CarcassesFormAbstraction>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CarcassesFormAbstraction from './CarcassesFormAbstraction';

const createForm = (info = {}) => ({
  name: info?.name ?? '',
  // eslint-disable-next-line camelcase
  hintCarrier: info?.comment_carrier ?? '',
  // eslint-disable-next-line camelcase
  hintOwner: info?.comment_sender ?? '',
  selectedCharacteristics: info?.characteristics ?? [],
  selectedLiftings: info?.lifting ?? [],
  selectedOptions: info?.options ?? [],
  id: info?.id ?? ''
});

export default {
  name: 'BodyWorksForm',
  components: {
    CarcassesFormAbstraction
  },
  props: {
    isEditForm: Boolean,
    isAddForm: Boolean,
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: createForm()
    };
  },
  computed: {
    ...mapState('carcasses', [
      'currentVersionId',
      'characteristics',
      'optionsCharacteristics',
      'options',
      'lifting'
    ])
  },
  watch: {
    info: {
      deep: true,
      immediate: true,
      handler(value) {
        this.form = createForm(value);
      }
    }
  },
  methods: {
    ...mapActions('common', ['createController']),
    mappedCharacteristics() {
      return [...this.characteristics, ...this.optionsCharacteristics].map(_ => {
        _.full_name = `${_.carrier.name}(${_.unit_measure})`;
        return _;
      });
    },
    async addBodyWork() {
      const topic = 'admin/administration/carcasses/types/update';
      if (!this.$refs.bodyWorkForm.validate()) return;
      try {
        const json = {
          id: this.currentVersionId,
          record: {
            name: this.form.name,
            characteristics: this.form.selectedCharacteristics,
            options: this.form.selectedOptions,
            lifting: this.form.selectedLiftings,
            comment_carrier: this.form.hintCarrier,
            comment_sender: this.form.hintOwner
          }
        };
        if (this.form.id.length) json.gid = this.form.id;
        await this.createController({ topic, json });
        this.resetForm();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    async removeBodyWork() {
      const topic = 'admin/administration/carcasses/types/remove';
      try {
        const json = {
          id: this.currentVersionId,
          gid: this.form.id
        };
        await this.createController({ topic, json });
        this.resetForm();
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
      }
    },
    resetForm() {
      this.$refs.bodyWorkForm.reset();
      this.$emit('close');
      this.$emit('update');
    }
  }
};
</script>
