<template>
  <div class="chat_list">
    <div v-if="!filteredItems.own.length && !filteredItems.free.length" class="chat_list__empty">
      Нет активных обращений
    </div>
    <div
      v-for="item in filteredItems.own"
      :key="'own' + item.chat_id"
      class="chat_list__item"
      @click="openChat(item)"
    >
      <v-badge class="item__badge" left="left">
        <template v-if="info.chat_id !== item.chat_id && item.badge_count !== 0" slot="badge">
          <span class="item__badge__count">{{ item.badge_count }}</span>
        </template>
        <v-icon color="blue">
          notifications
        </v-icon>
      </v-badge>
      <div class="chat_list__content">
        <div class="chat_list__name">
          {{ item.owner ? item.owner.name : '[Без имени]' }}
        </div>
        <div class="chat_list__date">
          {{ item.date.format('YYYY-MM-DD, HH:mm') }}
        </div>
      </div>
    </div>
    <div v-if="filteredItems.own.length && filteredItems.free.length" class="chat_list__line" />
    <div
      v-for="item in filteredItems.free"
      :key="'free' + item.chat_id"
      class="chat_list__item"
      @click="openChat(item)"
    >
      <v-icon>notifications</v-icon>
      <div class="chat_list__content">
        <div class="chat_list__name">
          {{ item.owner ? item.owner.name : '[Без имени]' }}
        </div>
        <div class="chat_list__date">
          {{ item.date.format('YYYY-MM-DD, HH:mm') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('support', ['info']),
    filteredItems() {
      return {
        own: this.items.own.slice(0).sort((a, b) => (a.date < b.date ? 1 : -1)),
        free: this.items.free.slice(0).sort((a, b) => (a.date > b.date ? 1 : -1))
      };
    }
  },
  methods: {
    openChat(chat) {
      this.$emit('select-chat', { chat_id: chat.chat_id });
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';
.chat_list {
  .chat_list__title {
    line-height: 20px;
  }
  .chat_list__line {
    border-top: 1px solid $color-disabled;
  }
  .chat_list__item {
    display: flex;
    cursor: pointer;

    .item__badge {
      align-self: center;
      .v-badge__badge {
        left: 15px;
        font-size: 12px;
        width: 15px;
        height: 15px;
        line-height: 12px;
        background-color: red !important;
      }
    }

    &:hover {
      color: $color-main;
    }
    .chat_list__content {
      padding: 10px;
      .chat_list__name {
        font-size: 16px;
        line-height: 18px;
      }
      .chat_list__date {
        font-size: 12px;
        line-height: 10px;
      }
    }
  }
  .chat_list__empty {
    font-size: 16px;
    font-weight: bold;
    color: $color-disabled;
    text-align: center;
    height: 100%;
    padding: 30vh 0 0 0;
  }
}
</style>
